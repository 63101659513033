import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import useChannel from '../services/hooks/useChannel'
import useChannels from '../services/hooks/useChannels'

export function ChannelPicker({
  className,
  hideLabel,
  ignoreIds,
  name,
  onChange,
  value,
}) {
  const { t } = useTranslation('media-library/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const filters = { 'ignore-ids': !isEmpty(ignoreIds) ? ignoreIds : null }

  const { channels } = useChannels({
    search: debouncedSearchTerm,
    fetchOnSearch: false,
    filters,
  })
  const { channel } = useChannel(value)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`space-y-4 ${className}`}>
      <SearchDropdown
        label={hideLabel ? '' : t('channel')}
        placeholder={t('typeToSearch')}
        name={name}
        value={value}
        selectedLabel={channel?.title}
        onChange={onChange}
        onSearch={onSearch}
      >
        {channels?.map(channel => (
          <SearchDropdown.Option
            key={channel.id}
            label={channel.title}
            name={name}
            value={channel.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
ChannelPicker.propTypes = {
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
}
ChannelPicker.defaultProps = {
  className: '',
}

export default function ChannelPickerController({
  className,
  hideLabel,
  ignoreIds,
  name,
  onChange,
  shouldUnregister,
}) {
  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <ChannelPicker
          className={className}
          hideLabel={hideLabel}
          ignoreIds={ignoreIds}
          name={name}
          onChange={onFieldChange(field)}
          value={field.value}
        />
      )}
    />
  )
}
ChannelPickerController.propTypes = {
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  name: PropTypes.string,
  onChange: PropTypes.func,
  shouldUnregister: PropTypes.bool,
}
ChannelPickerController.defaultProps = {
  className: '',
}
