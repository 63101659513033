import PropTypes from 'prop-types'
import React from 'react'

import HelpPopover from '@ui/feedback/HelpPopover'

export default function Details({ children, className }) {
  return <div className={`flex flex-col gap-2 ${className}`}>{children}</div>
}
Details.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
}
Details.defaultProps = {
  className: '',
}
Details.Item = DetailsItem
Details.Item.displayName = 'Details.Item'

const variantClasses = {
  basic: {
    label: 'text-gray-800',
    wrap: 'hover:border-gray-300',
  },
  light: {
    label: 'text-gray-400',
    wrap: 'hover:border-gray-200',
  },
  success: {
    label: 'text-success-600',
    wrap: 'hover:border-success-200',
  },
  danger: {
    label: 'text-danger-600',
    wrap: 'hover:border-danger-200',
  },
  warn: {
    label: 'text-warn-600',
    wrap: 'hover:border-warn-200',
  },
  info: {
    label: 'text-info-600',
    wrap: 'hover:border-info-200',
  },
  primary: {
    label: 'text-primary-600',
    wrap: 'hover:border-primary-200',
  },
}

export function DetailsItem({
  className = '',
  label,
  help,
  semicolon = true,
  value,
  variant = 'primary',
  vertical = false,
}) {
  const variantClass = variantClasses[variant] || variantClasses.primary

  const verticalClass = vertical
    ? 'flex-col gap-1 justify-center'
    : 'flex-row flex-wrap gap-4 items-baseline justify-between'

  return (
    <div
      className={`-mx-3 flex border border-transparent rounded-lg px-3 py-2 transition-all ease-in-out duration-300 ${variantClass.wrap} ${verticalClass} ${className}`}
    >
      <span className="flex flex-row gap-1">
        {label && (
          <span className={`font-bold ${variantClass.label}`}>
            {label}
            {semicolon && ':'}
          </span>
        )}
        {help && <HelpPopover>{help}</HelpPopover>}
      </span>
      <div className={`font-medium ${vertical ? '' : 'break-all'}`}>
        {value}
      </div>
    </div>
  )
}
DetailsItem.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  help: PropTypes.node,
  semicolon: PropTypes.bool,
  value: PropTypes.node,
  variant: PropTypes.oneOf([
    'basic',
    'success',
    'error',
    'warn',
    'info',
    'primary',
  ]),
  vertical: PropTypes.bool,
}
