import { useFormContext } from 'react-hook-form'

import useSite from '@modules/web/services/hooks/useSite'
import { isObject, merge } from '@utils/objects'

/**
 * Get the field name for a button
 * @param {string} field - The field name
 * @returns {string} The field name
 * @example
 * getButtonField('roundness') // => 'design[components][button][roundness]
 */
export function getButtonField(field = '') {
  if (!field) return ''
  return `design.components.button.${field}`
}

/**
 * Get the field name for a button variant
 * @param {string} variant - The variant name
 * @param {string} field - The field name
 * @param {string} breakpoint - The breakpoint name
 * @returns {string} The field name
 * @example
 * getVariantField('primary', 'roundness', 'xs') // => 'design[components][button][variants][primary][roundness][xs]'
 */
export function getVariantField(variant = '', field = '', breakpoint = '') {
  if (!variant || !field) return ''

  const variants = getButtonField('variants')

  return `${variants}.${variant}.${field}${breakpoint ? `.${breakpoint}` : ''}`
}

/**
 * Get current form values for a button variant
 * @param {string} variant - The variant name
 * @param {object} options - The options
 * @param {boolean} options.disabled - Whether the request is disabled
 * @returns {object} The variant values
 */
function useVariantFormValues(variant, { disabled = false } = {}) {
  const { watch } = useFormContext()

  if (disabled) return undefined

  return watch(getButtonField(`variants.${variant}`))
}

/**
 * Get current form settings for a button variant
 * @param {string} variant - The variant name
 * @returns {object} The variant settings
 */
export function useButtonFormSettings(variant) {
  const isBase = variant === 'base'
  const baseSettings = useVariantFormValues('base')
  const variantSettings = useVariantFormValues(variant, { disabled: isBase })

  return {
    settings: isBase ? baseSettings : merge(baseSettings, variantSettings),
    isVariantSet: isBase ? isObject(baseSettings) : isObject(variantSettings),
  }
}

/**
 * Get current site settings for a button variant
 * @param {string} variant - The variant name
 * @returns {object} The variant settings
 */
export default function useButtonSiteSettings(variant = 'base') {
  const { site } = useSite()
  const { variants } = site?.design?.components?.button || {}
  return isObject(variants) ? variants[variant] : {}
}
