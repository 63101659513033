import React from 'react'

import EntitiesPermissions from './components/EntitiesPermissions'
import ChurchContactPersons from './components/blocks/churches/ChurchContactPersons'
import ChurchDetail from './components/blocks/churches/ChurchDetail'
import ChurchDirections from './components/blocks/churches/ChurchDirections'
import ChurchFinder from './components/blocks/churches/ChurchFinder'
import ChurchHeader from './components/blocks/churches/ChurchHeader'
import ChurchImage from './components/blocks/churches/ChurchImage'
import ChurchLocation from './components/blocks/churches/ChurchLocation'
import ChurchSermons from './components/blocks/churches/ChurchSermons'
import ChurchServices from './components/blocks/churches/ChurchServices'
import EntityPicker from './components/entities/EntityPicker'
import EntityTypePicker from './components/entities/EntityTypePicker'
import acmsImportTask from './tasks/acms-import'
import orgmastImportTask from './tasks/orgmast-import'

const Router = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/entities/*': { Component: Router },
      },
    },
    permissions: {
      entities: {
        name: 'entities',
        label: 'entities/public:entities',
        icon: 'university',
        PermissionsComponent: EntitiesPermissions,
        models: [
          {
            name: 'entity',
          },
          {
            name: 'subentities',
          },
          {
            name: 'services',
          },
        ],
      },
      networks: {
        name: 'networks',
        label: 'entities/public:networks',
        icon: 'chart-network',
        models: [
          {
            name: 'networks',
            permissions: ['read', 'create', 'update', 'disable', 'delete'],
          },
        ],
      },
    },
    blocksResolver: {
      ChurchFinder,
      ChurchDetail,
      ChurchHeader,
      ChurchImage,
      ChurchLocation,
      ChurchSermons,
      ChurchServices,
      ChurchContactPersons,
      ChurchDirections,
    },
    blocksToolbars: [
      {
        name: 'churches',
        label: 'entities/public:churches',
        items: [
          ChurchFinder,
          ChurchDetail,
          ChurchHeader,
          ChurchImage,
          ChurchLocation,
          ChurchServices,
          ChurchSermons,
          ChurchContactPersons,
          ChurchDirections,
        ],
      },
    ],
    resources: {
      Entity: {
        type: 'model',
        name: 'Entity',
        label: 'entities/public:entities',
        labelSingular: 'entities/public:entity',
        ResourcePicker: EntityPicker,
        ResourceTypePicker: EntityTypePicker,
        fields: {
          title: {
            label: 'entities/public:title',
            type: 'string',
          },
          description: {
            label: 'entities/public:description',
            type: 'string',
          },
          image: {
            label: 'entities/public:image',
            type: 'image',
          },
          location: {
            label: 'entities/public:location',
            type: 'string',
          },
        },
      },
    },

    tasks: {
      'orgmast-import': orgmastImportTask,
      'acms-import': acmsImportTask,
    },
  }
}
