import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import InheritSelectPlaceholder from '@modules/web/components/ContentEditor/shared/InheritSelectPlaceholder'
import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

const variants = {
  sharingHope: 'sharingHope',
  greatControversy: 'greatControversy',
}

export default function VariantSelect() {
  const { t } = useTranslation('web/content-editor')

  return (
    <BlockField name="variant">
      {({ fieldProps, value, inheritFrom, inheritValue, onDataChange }) => (
        <SelectField
          {...fieldProps}
          label={t('variant')}
          value={value}
          onChange={e => onDataChange(e.target.value)}
        >
          <InheritSelectPlaceholder
            labelKey="variant"
            value={value}
            inheritValue={inheritValue}
            inheritFrom={inheritFrom}
          />
          {value && <SelectOption label={t('clearSelection')} value={null} />}
          <SelectPlaceholder label={t('-')} />
          {Object.values(variants).map(value => (
            <SelectOption label={value} value={value} key={value} />
          ))}
        </SelectField>
      )}
    </BlockField>
  )
}
