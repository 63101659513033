import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

import { entitiesApiUrl } from '../EntityServices'

const defaultOptions = {
  id: null,
  enabled: true,
  includeAncestors: false,
  includeContactPersons: false,
  fromParams: true,
}

export default function useEntity(options = defaultOptions) {
  const { id, enabled, includeAncestors, includeContactPersons, fromParams } =
    Object.assign({}, defaultOptions, options)

  const params = useParams()

  const entityId = id || (fromParams ? params.entityId : null)

  const { data, error, isLoading } = useQuery(
    ['entities', entityId, includeAncestors, includeContactPersons],
    () =>
      getFetch(`${entitiesApiUrl}/${entityId}`, {
        includeAncestors,
        includeContactPersons,
      }),
    { enabled: enabled && !!entityId }
  )

  return { entity: data, error, loading: isLoading }
}
