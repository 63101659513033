import PropTypes from 'prop-types'
import React from 'react'

import Popover from '@ui/feedback/Popover'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function HelpPopover({ className, title, message, children }) {
  return (
    <Popover>
      <Popover.Trigger asChild>
        <Icon
          name="question-circle"
          className={`text-primary-500 ${className}`}
        />
      </Popover.Trigger>
      <Popover.Content>
        <div className="space-y-2">
          {title && <h4 className="font-semibold text-gray-700">{title}</h4>}
          {message && <p className="text-gray-700">{message}</p>}
          <div>{children}</div>
        </div>
      </Popover.Content>
    </Popover>
  )
}
HelpPopover.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
}
HelpPopover.defaultProps = {
  className: '',
}
