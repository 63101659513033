import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useEvent from '@modules/events/services/hooks/useEvent'
import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

import { EventPicker } from '../event/EventPicker'
import LinkingFields from './shared/LinkingFields'

const config = {
  name: 'EventRegistration',
  label: 'events/public:eventRegistration',
  type: 'plugin',
  icon: 'ticket',
  component: <EventRegistration />,
}

export default function EventRegistration({ dynamic, id }) {
  const { t } = useTranslation('events/public')
  const { event } = useEvent(dynamic ? null : id)

  return (
    <Content icon={config.icon} title={t('eventRegistration')} locked={dynamic}>
      <div className="flex flex-col gap-2 p-4">
        <h3 className="font-semibold">
          {event ? event.title : '[Event title]'}
        </h3>
        <p>[Registration form]</p>
      </div>
    </Content>
  )
}

EventRegistration.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
EventRegistration.toolbarItem = config

function EventRegistrationSettings() {
  const { t } = useTranslation('events/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const [showPicker, setShowPicker] = useState(!data.dynamic)

  const onEventIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('eventRegistration')}
      help={t('eventRegistrationHelp')}
    >
      <div className="space-y-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        <div className="space-y-2">
          {showPicker && (
            <EventPicker onChange={onEventIdChange} value={data.id} />
          )}
        </div>
        <LinkingFields
          showListPagePicker={false}
          detailPageHelp={t('registrationDetailsPageHelp')}
        />

        <ColorSelect name="headerBgColor" label={t('headerBgColor')} />
        <ColorSelect name="organizerBgColor" label={t('organizerBgColor')} />

        <Checkbox
          name="showCategory"
          label={t('showCategory')}
          onChange={value => {
            actions.setProp(props => (props.showCategory = value))
          }}
          value={data.showCategory}
        />
        <Checkbox
          name="showContact"
          label={t('showContact')}
          onChange={value => {
            actions.setProp(props => (props.showContact = value))
          }}
          value={data.showContact}
        />
        <Checkbox
          name="showAudience"
          label={t('showAudience')}
          onChange={value => {
            actions.setProp(props => (props.showAudience = value))
          }}
          value={data.showAudience}
        />
        <Checkbox
          name="showDeadline"
          label={t('showDeadline')}
          onChange={value => {
            actions.setProp(props => (props.showDeadline = value))
          }}
          value={data.showDeadline}
        />
        <Checkbox
          name="showOrganizer"
          label={t('showOrganizer')}
          onChange={value => {
            actions.setProp(props => (props.showOrganizer = value))
          }}
          value={data.showOrganizer}
        />
      </div>
    </SettingsWrap>
  )
}

EventRegistration.craft = {
  displayName: 'EventRegistration',
  props: {
    id: undefined,
    dynamic: false,
    showCategory: true,
    showContact: true,
    showAudience: true,
    showDeadline: true,
    showOrganizer: true,
  },
  custom: {
    type: config.type,
    resources: ['Event', 'eventRegistration'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventRegistrationSettings,
  },
}
