import PropTypes from 'prop-types'
import React, { useState } from 'react'

import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function Sides({
  icons,
  keys,
  showIndividual: initialShowIndividual,
  renderAll,
  renderSide,
}) {
  const [showIndividual, setShowIndividual] = useState(initialShowIndividual)

  return (
    <div className="flex flex-col gap-2 w-full">
      {renderAll({ showIndividual, setShowIndividual })}
      {showIndividual && (
        <div className="grid grid-cols-2 gap-2">
          {keys.map(side => (
            <Box key={side} space="sm" align="center" horizontal>
              <Icon
                name={icons[side]?.icon}
                className={icons[side]?.className}
              />
              {renderSide({ side })}
            </Box>
          ))}
        </div>
      )}
    </div>
  )
}

Sides.propTypes = {
  icons: PropTypes.object,
  keys: PropTypes.arrayOf(PropTypes.string),
  renderAll: PropTypes.func,
  renderSide: PropTypes.func,
  showIndividual: PropTypes.bool,
}
