import { useCallback, useMemo, useRef, useState } from 'react'

import {
  arrow,
  autoUpdate,
  flip,
  offset,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

const staticSides = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const arrowBorders = {
  top: 'border-r border-b',
  right: 'border-l border-b',
  bottom: 'border-l border-t',
  left: 'border-r border-t',
}

export function usePopover({
  dismissOptions,
  hideArrow = false,
  initialOpen = false,
  modal = false,
  offset: offsetProp,
  onOpenChange: setControlledOpen,
  open: controlledOpen,
  placement = 'bottom-start',
}) {
  const arrowRef = useRef(null)

  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        crossAxis: Boolean(placement?.includes('-')),
        fallbackAxisSideDirection: 'end',
        padding: 8,
      }),
      offset(offsetProp ? offsetProp : hideArrow ? 4 : 16),
      shift(),
      arrow({ element: arrowRef }),
    ],
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, dismissOptions)
  const role = useRole(context)

  // Merge all the interactions into prop getters
  const interactions = useInteractions([click, dismiss, role])

  const placementSide = placement?.split('-')?.[0] ?? 'top'
  const staticSide = staticSides[placementSide]
  const arrowBorderClass = arrowBorders[placementSide]

  const close = useCallback(() => setOpen(false), [setOpen])

  return useMemo(
    () => ({
      hideArrow,
      arrowRef,
      open,
      setOpen,
      close,
      ...interactions,
      ...data,
      modal,
      staticSide,
      arrowBorderClass,
    }),
    [
      arrowBorderClass,
      close,
      data,
      hideArrow,
      interactions,
      modal,
      open,
      setOpen,
      staticSide,
    ]
  )
}
