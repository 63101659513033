import { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import unset from 'lodash/unset'

import { getKeysFromPaths } from '../helpers/getKeysFromPaths'
import { useBlockData } from './useBlockData'
import useFieldBreakpoint from './useFieldBreakpoint'
import useSetDataAtBreakpoint from './useSetDataAtBreakpoint'

export function useSidesBlockData(paths) {
  const keys = getKeysFromPaths(paths)

  const { breakpoint, setBreakpoint, showControls, setShowControls } =
    useFieldBreakpoint()

  const { actions } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const setDataAtBreakpoint = useSetDataAtBreakpoint(breakpoint)

  const top = useBlockData(paths.top, {
    breakpointOverride: breakpoint,
    responsive: true,
  })
  const right = useBlockData(paths.right, {
    breakpointOverride: breakpoint,
    responsive: true,
  })
  const bottom = useBlockData(paths.bottom, {
    breakpointOverride: breakpoint,
    responsive: true,
  })
  const left = useBlockData(paths.left, {
    breakpointOverride: breakpoint,
    responsive: true,
  })

  const dataValues = useMemo(
    () => ({
      ...(top.dataValue ?? {}),
      ...(right.dataValue ?? {}),
      ...(bottom.dataValue ?? {}),
      ...(left.dataValue ?? {}),
    }),
    [bottom.dataValue, left.dataValue, right.dataValue, top.dataValue]
  )

  const hasResponsiveValues = useMemo(
    () => Object.keys(dataValues).length > 1,
    [dataValues]
  )

  const isMixed = useMemo(() => {
    return ![top, right, bottom, left].every(
      side =>
        side.valueAtBreakpoint?.value === top.valueAtBreakpoint?.value &&
        side.valueAtBreakpoint?.inherit === top.valueAtBreakpoint?.inherit
    )
  }, [bottom, left, right, top])

  const onChangeAll = useCallback(
    path => event => {
      const newValue = event.target.value

      keys.forEach(k => {
        if (!newValue) {
          actions.setProp(props => unset(props, `${path}.${k}.${breakpoint}`))
        } else {
          setDataAtBreakpoint(`${path}.${k}`, newValue)
        }
      })
    },
    [actions, breakpoint, keys, setDataAtBreakpoint]
  )

  const onChange = useCallback(
    path => event => {
      const newValue = event.target.value
      if (!newValue) {
        actions.setProp(props => unset(props, `${path}.${breakpoint}`))
      } else {
        setDataAtBreakpoint(path, newValue)
      }
    },
    [actions, breakpoint, setDataAtBreakpoint]
  )

  return {
    dataValues,
    hasResponsiveValues,
    breakpoint,
    setBreakpoint,
    showControls,
    setShowControls,
    isMixed,
    top,
    right,
    bottom,
    left,
    onChangeAll,
    onChange,
  }
}

export default useSidesBlockData
