import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Element, useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useChannel from '@modules/media-library/services/hooks/useChannel'
import useShow from '@modules/media-library/services/hooks/useShow'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import Toolbar from '@modules/web/components/ContentEditor/shared/Toolbar'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import { ChannelPicker } from '../ChannelPicker'
import { ShowPicker } from '../ShowPicker'

const config = {
  name: 'EpisodeRepeater',
  label: 'media-library/public:episodeRepeater',
  type: 'plugin',
  icon: 'list',
  component: <Element is={EpisodeRepeater} canvas />,
  allowed: ['Box', 'Grid', 'SimpleText', 'Image', 'Button', 'Player'],
}

export default function EpisodeRepeater({ children }) {
  const { t } = useTranslation('media-library/public')

  return (
    <Content icon={config.icon} title={t('episodeRepeater')}>
      <>{children}</>
    </Content>
  )
}
EpisodeRepeater.propTypes = {
  children: PropTypes.node,
}
EpisodeRepeater.toolbarItem = config

function EpisodeRepeaterSettings() {
  const design = useDesignContext()
  const { t } = useTranslation(['media-library/public', design.namespace])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)
  const [showSortOrder, setShowSortOrder] = useState(!!data.sortField)
  const { resources } = usePageResources()
  const { channel: staticChannel } = useChannel(data.channelId)
  const { show: staticShow } = useShow(data.showId)

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const channel = data.dynamic ? resources?.Channel : staticChannel
  const show = data.dynamic ? resources?.Show : staticShow

  return (
    <SettingsWrap title={t('episodeRepeater')} help={t('episodeRepeaterHelp')}>
      <Box space="xl">
        <Checkbox
          label={t('dynamicEpisode')}
          help={t('dynamicEpisodeHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {showPicker && (
          <>
            <ChannelPicker
              name="channelId"
              value={data.channelId}
              onChange={value => {
                actions.setProp(props => (props.channelId = value))
              }}
            />
            {data.channelId && (
              <ShowPicker
                name="showId"
                channelId={data.channelId}
                value={data.showId}
                onChange={value => {
                  actions.setProp(props => (props.showId = value))
                }}
              />
            )}
          </>
        )}
        {channel && !show && (
          <Message className="text-sm" type="warn">
            {channel.title}
          </Message>
        )}
        {show && (
          <Message className="text-sm" type="warn">
            {show.title}
          </Message>
        )}
        <Select
          label={t('sortField')}
          help={t('episodesListSortFieldHelp')}
          value={data.sortField}
          onChange={e => {
            const sortField = e.target.value
            setShowSortOrder(!!sortField)
            actions.setProp(props => (props.sortField = sortField))
          }}
          showClear
        >
          <Select.Placeholder label="-" />
          <SelectOption
            value="publishedAt"
            label={t('sortByPublicationDate')}
          />
          <SelectOption value="playsPerDay" label={t('sortByPlaysPerDay')} />
          <SelectOption value="plays" label={t('sortByPlays')} />
          <SelectOption value="rating" label={t('sortByRating')} />
        </Select>
        {showSortOrder && (
          <Select
            label={t('sortOrder')}
            help={t('sortOrderHelp')}
            value={data.sortOrder}
            onChange={onFieldUpdate('sortOrder')}
          >
            <SelectOption value="asc" label={t('ascending')} />
            <SelectOption value="desc" label={t('descending')} />
          </Select>
        )}
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.limit}
          type="number"
        />
        <Input
          label={t('skip')}
          help={t('skipHelp')}
          onChange={onFieldUpdate('skip', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.skip}
          type="number"
        />
        <Divider />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

EpisodeRepeater.craft = {
  displayName: 'EpisodeRepeater',
  props: {
    dynamic: false,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    limit: 8,
    skip: 0,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    allowedChildren: () => <Toolbar allowed={config.allowed} />,
    settings: EpisodeRepeaterSettings,
  },
}
