import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

export default function SortFields({ singular }) {
  const { t } = useTranslation('web/content-editor')
  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <>
      <Select
        label={t('sortField')}
        help={t('sortFieldHelp')}
        value={data.sortField}
        onChange={onFieldUpdate('sortField')}
      >
        <SelectOption value="title" label={t('title')} />
        <SelectOption value="publishedAt" label={t('publishedDate')} />
        <SelectOption value="createdAt" label={t('createdDate')} />
        <SelectOption value="updatedAt" label={t('updatedDate')} />
      </Select>

      <Select
        label={t('sortOrder')}
        help={t('sortOrderHelp', { count: singular ? 1 : 0 })}
        value={data.sortOrder}
        onChange={onFieldUpdate('sortOrder')}
      >
        <SelectOption value="asc" label={t('ascending')} />
        <SelectOption value="desc" label={t('descending')} />
      </Select>
    </>
  )
}

SortFields.propTypes = {
  singular: PropTypes.bool,
}
