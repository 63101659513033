import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import BlockField from '../BlockField'
import { useButtonVariantsOptions } from './hooks'

export default function BlockButtonVariantSelect({
  label,
  name = 'variant',
  defaultValue = 'base',
}) {
  const { t } = useTranslation('web/content-editor')

  const variantOptions = useButtonVariantsOptions()

  if (!variantOptions?.length > 0) return null

  return (
    <BlockField defaultValue={defaultValue} name={name}>
      {({ fieldProps, value, onDataChange }) => (
        <SelectField
          {...fieldProps}
          label={label || t('variant')}
          onChange={e => onDataChange(e.target.value)}
          value={value}
        >
          {value && <SelectOption value={null} label={t('clearSelection')} />}
          <SelectPlaceholder label="-" />
          {variantOptions.map(({ label, value }) => (
            <SelectOption label={label} value={value} key={label} />
          ))}
        </SelectField>
      )}
    </BlockField>
  )
}
BlockButtonVariantSelect.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  defaultValue: PropTypes.string,
}
