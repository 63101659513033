import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

const countriesApiUrl = '/api/countries'

const detailUrls = {
  byId: id => `${countriesApiUrl}/${id}`,
  byCode: code => `${countriesApiUrl}/code/${code}`,
}

const defaultOptions = {
  enabled: true,
}

/**
 * Fetches a country by id or locale
 * @param {object} options - Options
 * @param {string} options.id - Country id, required if locale is not provided
 * @param {string} options.code - Country code, required if id is not provided (e.g. US)
 * @param {boolean} options.enabled - Enable the query
 * @returns {object} - Country data
 */
export default function useCountry(options = {}) {
  const { id, code, enabled } = Object.assign({}, defaultOptions, options)
  const params = useParams()
  const countryId = id || params?.countryId

  const detailUrl = code ? detailUrls.byCode(code) : detailUrls.byId(countryId)

  const { data, error, isLoading } = useQuery(
    ['countries', countryId, code, enabled],
    () => getFetch(detailUrl),
    { enabled: enabled && (!!countryId || !!code) }
  )

  return { country: data, countryId, error, loading: isLoading }
}
