import React, { Suspense, useEffect } from 'react'

import { useTranslation } from 'react-i18next'
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from 'react-router-dom'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { useRenewToken } from '@modules/auth/services/AuthService'
import AdminMenuGroup from '@modules/core/components/AdminMenuGroup'
import DashboardProvider from '@modules/dashboard/context/Provider'
import EntitySwitch from '@modules/entities/components/EntitySwitch'
import EntityProvider from '@modules/entities/services/providers/EntityProvider'
import SearchProvider from '@modules/search/context/SearchProvider'
import TaskProvider from '@modules/tasks/components/Context/TaskProvider'
// NOTE: We are not using Chatwoot at the moment. But we will keep its code available for future use.
// import ChatwootWidget from '@modules/support/components/ChatwootWidget'
// import SupportButton from '@modules/support/components/SupportButton'
import PermissionsProvider from '@modules/users/components/PermissionsProvider'
import BlocksProvider from '@modules/web/components/ContentEditor/providers/BlocksProvider'
import DataSourcesProvider from '@modules/web/components/data-sources/context/DataSourcesProvider'
import Message from '@ui/data-display/Message'
import Loading from '@ui/feedback/Loading'
import { getImageUrl } from '@utils/images'

import ResourcesProvider from './ResourcesProvider'
import useFavicon from './helpers/useFavicon'
import registerModules from './modules'

const Menu = React.lazy(() => import('@ui/navigation/Menu'))
const OfflineMessage = React.lazy(() => import('@ui/feedback/OfflineMessage'))
const Main = React.lazy(() => import('@ui/templates/Main'))
const SplitScreen = React.lazy(() => import('@ui/templates/SplitScreen'))
const MainSidebarProvider = React.lazy(
  () => import('@ui/providers/MainSidebarProvider')
)
const ProfileMenu = React.lazy(
  () => import('@modules/auth/components/ProfileMenu')
)
const ImpersonationBanner = React.lazy(
  () => import('@modules/auth/components/ImpersonationBanner')
)

const {
  blocksResolver,
  blocksToolbars,
  dashboards,
  dataSources,
  menuItems,
  permissions,
  resources,
  routes,
  searchIndexes,
  tasks,
  translatableBlocks,
} = registerModules()

const availableMenuItems = menuItems
  .filter(Component => typeof Component === 'function')
  .map((Component, key) => <Component key={`menuItem-${key}`} />)

export default function AppRouter() {
  return (
    <BrowserRouter>
      <EntityProvider>
        <Router />
      </EntityProvider>
    </BrowserRouter>
  )
}

function Router() {
  const { user, loading } = useCurrentUser()
  const faviconNetwork = useFavicon()

  // We apply the favicon depending on the network.
  useEffect(() => {
    const favicon = document.querySelector('link[rel="icon"]')

    // Change the favicon
    favicon.href = getImageUrl(faviconNetwork) || '/favicon.svg'
  }, [faviconNetwork])

  if (loading) return <Loading />

  if (!loading && user) return <ProtectedRouter />

  return <PublicRouter />
}

// Pre-build public routes from modules
const publicModulesRoutes = Object.entries(routes.public).map(
  ([route, { Component }]) => (
    <Route path={route} key={route} element={<Component />} />
  )
)

function PublicRouter() {
  let location = useLocation()

  let to = '/auth/login'

  if (location.pathname !== '/auth/login') {
    const redirect = `${location.pathname}${location.search}${location.hash}`

    if (redirect !== '/') {
      to += `?${new URLSearchParams({
        redirect,
      }).toString()}`
    }
  }

  return (
    <SplitScreen>
      <Suspense fallback={<Loading />}>
        <OfflineMessage />
        <Routes>
          {publicModulesRoutes}
          <Route path="*" element={<Navigate to={to} />} />
        </Routes>
      </Suspense>
      {/* <ChatwootWidget /> */}
    </SplitScreen>
  )
}

// Pre-build private routes from modules
const privateModulesRoutes = Object.entries(routes.private).map(
  ([route, { Component, exact }]) => (
    <Route path={route} key={route} exact={exact} element={<Component />} />
  )
)

function ProtectedRouter() {
  // Renew the session token every hour to keep session alive.
  useRenewToken()

  const { t } = useTranslation()

  const { VITE_SYSTEM_PROBLEMS_WARNING } = import.meta.env
  const showSystemWarning =
    VITE_SYSTEM_PROBLEMS_WARNING?.toLowerCase() === 'true'

  return (
    <BlocksProvider
      resolver={blocksResolver}
      toolbars={blocksToolbars}
      translatable={translatableBlocks}
    >
      <ResourcesProvider resources={resources}>
        <PermissionsProvider permissions={permissions}>
          <SearchProvider search={searchIndexes}>
            <DataSourcesProvider dataSources={dataSources}>
              <DashboardProvider dashboards={dashboards}>
                <TaskProvider tasks={tasks}>
                  <MainSidebarProvider>
                    <Main
                      sidebar={<Menu>{availableMenuItems}</Menu>}
                      entityMenu={
                        <Menu>
                          <EntitySwitch />
                        </Menu>
                      }
                      stickyMenu={
                        <Menu variant="dark">
                          <AdminMenuGroup />
                          <ProfileMenu />
                          {/* <SupportButton /> */}
                        </Menu>
                      }
                      top={<ImpersonationBanner />}
                    >
                      {showSystemWarning && (
                        <div className="p-4">
                          <Message
                            title={t('systemProblem')}
                            text={t('systemProblemMessage')}
                            type="warn"
                          />
                        </div>
                      )}
                      <Suspense fallback={<Loading />}>
                        <Routes>
                          {privateModulesRoutes}
                          {/* Redirect to home if the user tries to access a non-available route */}
                          <Route
                            path="auth/login"
                            element={<Navigate to="/" />}
                          />
                          <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                        {/* <ChatwootWidget /> */}
                      </Suspense>
                    </Main>
                  </MainSidebarProvider>
                </TaskProvider>
              </DashboardProvider>
            </DataSourcesProvider>
          </SearchProvider>
        </PermissionsProvider>
      </ResourcesProvider>
    </BlocksProvider>
  )
}
