import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))

export default function CategoriesMenuGroup() {
  const { t } = useTranslation('categories')
  const location = useLocation()

  const canReadCategories = useIsUserAuthorized({
    module: 'categories',
    permission: 'read',
  })

  if (!canReadCategories) return null

  return (
    <MenuGroup
      name="categories"
      label={t('categories')}
      icon="tags"
      active={location.pathname.startsWith('/categories')}
      href="/categories"
    />
  )
}
