import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import { isEqual } from 'lodash'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useSite from '@modules/web/services/hooks/useSite'

import useEntity from '../../../services/hooks/useEntity'
import Address from './shared/Address'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'ChurchHeader',
  label: 'entities/public:ChurchHeader',
  type: 'plugin',
  icon: 'star',
  component: <ChurchHeader />,
}

const placeholderContent = {
  name: '[Church name]',
  address: {
    street: '[Church street address]',
    additionalAddress: '[Church additionalAddress]',
    zip: '[000000]',
    city: '[City]',
  },
}

export default function ChurchHeader({ id, dynamic }) {
  const { t } = useTranslation('entities/public')
  const { site } = useSite()
  const { entity } = useEntity({ id: dynamic ? site.entity : id })

  const { name, address, location } = dynamic
    ? placeholderContent
    : entity ?? {}

  const displayedLocation = dynamic ? entity?.location : location

  const [viewport, setViewport] = useState({
    longitude: 0,
    latitude: 0,
    zoom: 1,
  })

  useEffect(() => {
    if (!displayedLocation) return
    const { coordinates } = displayedLocation
    setViewport({
      longitude: coordinates[0],
      latitude: coordinates[1],
      zoom: isEqual([0, 0], coordinates) ? 0 : 16,
    })
  }, [displayedLocation])

  const locationMarker = useMemo(
    () => [
      {
        coordinates: displayedLocation?.coordinates ?? [0, 0],
      },
    ],
    [displayedLocation]
  )

  return (
    <Content icon={config.icon} title={t('ChurchHeader')} locked={dynamic}>
      <div className="flex flex-col">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row">
            <div className="w-1/2">
              <h1 className="text-3xl font-bold">{name}</h1>
              {address && <Address address={address} />}
            </div>
            <div className="w-1/2">
              <Map
                className="h-52"
                markers={locationMarker}
                viewport={viewport}
                markerIcon="church"
                onMove={setViewport}
                showControls
              />
            </div>
          </div>
        </div>
      </div>
    </Content>
  )
}
ChurchHeader.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
ChurchHeader.defaultProps = {}
ChurchHeader.toolbarItem = config

function ChurchHeaderSettings() {
  const { t } = useTranslation('entities/public')

  // const { actions, data } = useNode(node => {
  //   return {
  //     data: node.data.props,
  //   }
  // })

  // We've had to disable the manual church picking due to it not having worked before the conferences started using them in November 2023.
  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.id = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('ChurchHeader')} help={t('ChurchHeaderHelp')}>
      <div className="space-y-2">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.id}
            types={['church']}
          />
        )} */}
      </div>
    </SettingsWrap>
  )
}

ChurchHeader.craft = {
  displayName: 'ChurchHeader',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity', 'entityHeader'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchHeaderSettings,
  },
}
