import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { useDynamicResource } from '@modules/web/components/DynamicField'

import Publications from '../shared/Publications'
import config from './config'

export default function PublicationsListBlock({
  religion,
  featured,
  columns,
  displayMode,
  variant,
  limit,
  filterByDynamicResource,
  pagination,
  showImage,
  showDescription,
  showLink,
  linkLabel,
  skip,
  sortField,
  sortOrder,
}) {
  const { t } = useTranslation('publications/public')
  const dynamicResource = useDynamicResource()

  const resourceFilter = React.useMemo(
    () =>
      dynamicResource
        ? t(dynamicResource.label)
        : t('noSupportedDynamicResourceFilters'),
    [dynamicResource, t]
  )

  return (
    <Content icon={config.icon} title={t('PublicationsList')}>
      {filterByDynamicResource && (
        <div className="flex gap-2">
          <span>{t('filteredBy')}</span>
          <span className="font-medium">{resourceFilter}</span>
        </div>
      )}
      <Publications
        religion={religion}
        displayMode={displayMode}
        variant={variant}
        limit={limit}
        featured={featured}
        showLink={showLink}
        linkLabel={linkLabel}
        showImage={showImage}
        showDescription={showDescription}
        pagination={pagination}
        columns={columns}
        skip={skip}
        sortField={sortField}
        sortOrder={sortOrder}
      />
    </Content>
  )
}
PublicationsListBlock.propTypes = {
  religion: PropTypes.string,
  featured: PropTypes.bool,
  columns: PropTypes.object,
  displayMode: PropTypes.string,
  variant: PropTypes.string,
  limit: PropTypes.number,
  filterByDynamicResource: PropTypes.bool,
  pagination: PropTypes.bool,
  showImage: PropTypes.bool,
  showDescription: PropTypes.bool,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  skip: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
}
