import PropTypes from 'prop-types'
import React from 'react'

import { breakpointIcons } from '../BlockField'
import InlineResponsiveControls from '../InlineResponsiveControls'
import InlineResponsiveToggle from '../InlineResponsiveToggle'
import { useSidesBlockData } from '../useSidesBlockData'
import BorderRadiusSelectField from './Field'

export default function BlockBorderRadiusSelect({
  help,
  label,
  name = 'borderRadius',
}) {
  const {
    breakpoint,
    dataValues,
    hasResponsiveValues,
    isMixed,
    onChange,
    onChangeAll,
    setBreakpoint,
    setShowControls,
    showControls,
    top,
    right,
    bottom,
    left,
  } = useSidesBlockData({
    top: `${name}.tl`,
    right: `${name}.tr`,
    bottom: `${name}.bl`,
    left: `${name}.br`,
  })

  return (
    <BorderRadiusSelectField
      allFieldExtra={
        <InlineResponsiveControls
          breakpoint={breakpoint}
          dataValue={dataValues}
          icons={breakpointIcons}
          open={showControls}
          setBreakpoint={setBreakpoint}
        />
      }
      allLabelExtra={
        <InlineResponsiveToggle
          hasValues={hasResponsiveValues}
          open={showControls}
          setOpen={setShowControls}
        />
      }
      name={name}
      label={label}
      help={help}
      onChange={onChange}
      onChangeAll={onChangeAll}
      isMixed={isMixed}
      value={{
        tl: top?.value,
        tr: right?.value,
        bl: bottom?.value,
        br: left?.value,
      }}
      inheritValue={{
        tl: top?.inheritValue,
        tr: right?.inheritValue,
        bl: bottom?.inheritValue,
        br: left?.inheritValue,
      }}
      inheritFrom={{
        tl: top?.inheritFrom,
        tr: right?.inheritFrom,
        bl: bottom?.inheritFrom,
        br: left?.inheritFrom,
      }}
    />
  )
}

BlockBorderRadiusSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
}
