import React from 'react'

import config from './config'

const ArticleListSettings = React.lazy(() => import('./Settings'))
const ArticleListBlock = React.lazy(() => import('./Block'))

export default function ArticleList(props) {
  return <ArticleListBlock {...props} />
}

ArticleList.toolbarItem = {
  ...config,
  component: <ArticleList />,
}

ArticleList.craft = {
  displayName: 'ArticleList',
  props: {
    columns: { xs: 1, md: 3 },
    flag: null,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    displayMode: 'cards', // 'cards' or 'list'
    filterCurrentArticle: true,
    showDate: true,
    showFlags: false,
    showDescription: true,
    showImage: true,
    showLink: false,
    limit: 10,
    skip: 0,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleListSettings,
  },
}
