import PropTypes from 'prop-types'
import React from 'react'

import Tag from '@ui/data-display/Tag'

export default function AutocompleteSelectedItem({ icon, label, onRemove }) {
  return (
    <Tag
      label={label}
      icon={icon}
      variant="primary"
      onDelete={onRemove}
      size="sm"
    />
  )
}
AutocompleteSelectedItem.propTypes = {
  icon: PropTypes.node,
  label: PropTypes.node,
  onRemove: PropTypes.func,
}
