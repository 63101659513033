import React from 'react'

import config from './config'

const ArticlesShowcaseSettings = React.lazy(() => import('./Settings'))
const ArticlesShowcaseBlock = React.lazy(() => import('./Block'))

export default function ArticlesShowcase(props) {
  return <ArticlesShowcaseBlock {...props} />
}

ArticlesShowcase.toolbarItem = {
  ...config,
  component: <ArticlesShowcase />,
}

ArticlesShowcase.craft = {
  displayName: 'ArticlesShowcase',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticlesShowcaseSettings,
  },
}
