import PropTypes from 'prop-types'
import React from 'react'

import BlockField from '../BlockField'
import { AspectRatioSelectField } from './Field'

export function BlockAspectRatioSelect({
  help,
  label,
  name = 'aspectRatio',
  responsive,
}) {
  return (
    <BlockField name={name} responsive={responsive}>
      {({ fieldProps, inheritFrom, inheritValue, onDataChange, value }) => (
        <AspectRatioSelectField
          {...fieldProps}
          help={help}
          label={label}
          inheritFrom={inheritFrom}
          inheritValue={inheritValue}
          onChange={e => onDataChange(e.target.value)}
          value={value}
        />
      )}
    </BlockField>
  )
}
BlockAspectRatioSelect.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  responsive: PropTypes.bool,
}
