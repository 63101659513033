import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'

export default function CopySourceButton({
  name,
  className,
  text,
  onCustomChange,
}) {
  const { t } = useTranslation('translations')
  const { setValue } = useFormContext()

  const onCopy = useCallback(() => {
    if (onCustomChange) {
      onCustomChange(name, text)
      return
    }

    setValue(name, text, { shouldDirty: true })
  }, [name, text, setValue, onCustomChange])

  return (
    <Button
      className={className}
      onClick={onCopy}
      tooltip={t('copyAsTranslation')}
      icon="chevrons-right"
      size="sm"
    />
  )
}
CopySourceButton.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onCustomChange: PropTypes.func,
  text: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.string,
    PropTypes.object,
    PropTypes.number,
  ]).isRequired,
}
