import PropTypes from 'prop-types'
import React from 'react'

import {
  useGridColumnClass,
  useGridGapClass,
  useGridPlaceItemsClass,
} from '@modules/web/components/ContentEditor/blocks/Grid/hooks'
import { useWidthClass } from '@modules/web/components/ContentEditor/shared/WidthSelect'
import Image from '@ui/data-display/Image'
import Heading from '@ui/typography/Heading'

export default function LinkList({
  title,
  items,
  listType = 'list',
  columns,
  gap,
  placeItems,
  width,
  children,
}) {
  const columnClass = useGridColumnClass(columns)
  const gapClass = useGridGapClass(gap)
  const widthClass = useWidthClass(width)
  const placeItemsClass = useGridPlaceItemsClass(placeItems)

  const contentClasses =
    listType === 'grid'
      ? `relative grid grid-flow-row ${columnClass} ${gapClass} ${widthClass} ${placeItemsClass}`
      : 'relative space-y-2'

  return (
    <div className={'flex flex-col space-y-2'}>
      <Heading as="h4" text={title} />
      <div className={contentClasses}>
        {Array.isArray(items)
          ? items.map((item, i) => (
              <LinkItem {...item} key={`link-item-${i}`} />
            ))
          : children}
      </div>
    </div>
  )
}
LinkList.propTypes = {
  children: PropTypes.node,
  items: PropTypes.array,
  title: PropTypes.string,
  columns: PropTypes.object,
  gap: PropTypes.object,
  placeItems: PropTypes.object,
  width: PropTypes.object,
  listType: PropTypes.oneOf(['list', 'grid']),
}

function LinkItem({ label, description, url, icon }) {
  return (
    <div
      className="flex flex-col items-center space-x-2 space-y-2 rounded-lg bg-gray-100 p-2"
      title={url}
    >
      {typeof icon === 'object' && (
        <div className="mb-2 h-12 w-12">
          <Image file={icon} alt={label} />
        </div>
      )}
      <span className="flex-grow font-semibold leading-5">{label}</span>
      {description && <span>{description}</span>}
    </div>
  )
}
LinkItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  label: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
}
