import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useEpisode from '../../services/hooks/useEpisode'
import ChannelSelect from '../ChannelSelect'
import { EpisodePicker } from '../EpisodePicker'

const config = {
  name: 'EpisodeDocuments',
  label: 'media-library/public:episodeDocuments',
  type: 'plugin',
  icon: 'file-alt',
  component: <EpisodeDocuments />,
}

function getPlaceholder() {
  return {
    documents: [
      {
        file: {
          containerId: '602bd46f36432128d40d5e81',
          extension: '.pdf',
          name: '2sW1643275447283.pdf',
          originalFilename: 'We_Are_Family-inVerse-Journal-2021-Q1-L13',
          size: 225019,
          mime: 'application/pdf',
        },
        title: 'We Are Family inVerse Journal 2021 Q1 L13',
      },
    ],
  }
}

export default function EpisodeDocuments({ channelId, id, title }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { episode } = useEpisode(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _show = resources?.Show
  const _episode = resources?.Episode
    ? resources.Episode
    : episode || getPlaceholder()

  const { documents } = _episode

  return (
    <Content icon={config.icon} title={t('episodeDocuments')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          {documents.map((episodeDocument, key) => (
            <div key={key}>
              {episodeDocument.title} ({episodeDocument.file?.name})
            </div>
          ))}
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={title || t('documents')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('show')}: {_show?.title || t('dynamic')}
            </li>
            <li>
              {t('episode')}: {_episode?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
EpisodeDocuments.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
}
EpisodeDocuments.defaultProps = {}
EpisodeDocuments.toolbarItem = config

function EpisodeDocumentsSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onEpisodeIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('episodeDocuments')}
      help={t('episodeDocumentsHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          placeholder={t('documents')}
          value={data.title}
        />
        <Checkbox
          help={t('addSeasonDocumentsHelp')}
          label={t('addSeasonDocuments')}
          name="addSeasonDocuments"
          onChange={value => {
            actions.setProp(props => (props.addSeasonDocuments = value))
          }}
          value={data.addSeasonDocuments}
        />
        <Checkbox
          help={t('addShowDocumentsHelp')}
          label={t('addShowDocuments')}
          name="addShowDocuments"
          onChange={value => {
            actions.setProp(props => (props.addShowDocuments = value))
          }}
          value={data.addShowDocuments}
        />
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickEpisodeManually')}
              help={t('pickEpisodeManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <EpisodePicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onEpisodeIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

EpisodeDocuments.craft = {
  displayName: 'EpisodeDocuments',
  props: {
    channelId: undefined,
    id: undefined,
    title: '',
    addSeasonDocuments: false,
    addShowDocuments: false,
  },
  custom: {
    type: config.type,
    resources: ['episodeDocuments'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeDocumentsSettings,
  },
}
