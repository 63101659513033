import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import { useDesignContext } from '../../../DesignProvider'
import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { getWidthLabel, useDesignWidths } from './hooks'

export default function WidthSelect({
  name,
  label,
  lightLabel,
  help,
  onChange,
  value,
  inheritValue,
  inheritForm,
  ...rest
}) {
  const { t } = useTranslation('web/content-editor')
  const design = useDesignContext()
  const designWidths = useDesignWidths(design)

  return (
    <SelectField
      {...rest}
      name={name ?? 'width'}
      label={label ?? t('width')}
      help={help ?? t('widthHelp')}
      lightLabel={lightLabel}
      onChange={onChange}
      value={value}
    >
      <InheritSelectPlaceholder
        customLabel={t(getWidthLabel(inheritValue))}
        inheritValue={inheritValue}
        inheritFrom={inheritForm}
        value={value}
      />
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      {Object.keys(designWidths).map(key => (
        <SelectOption label={t(getWidthLabel(key))} value={key} key={key} />
      ))}
    </SelectField>
  )
}

WidthSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  lightLabel: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inheritValue: PropTypes.string,
  inheritForm: PropTypes.string,
}
