import React from 'react'

import LoginError from './components/blocks/LoginError'
import LoginForm from './components/blocks/LoginForm'
import RegistrationForm from './components/blocks/RegistrationForm'
import UserAccountOverview from './components/blocks/UserAccountOverview'
import UserProfile from './components/blocks/UserProfile'
import VerifyLogin from './components/blocks/VerifyLogin'

const WebAuthRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'web-auth',
    routes: {
      private: {
        '/web-auth/*': { Component: WebAuthRouter },
      },
    },
    permissions: {
      ['web-auth']: {
        name: 'web-auth',
        label: 'web-auth/public:authentication',
        icon: 'key',
        models: [
          {
            name: 'web-users',
            label: 'web-auth/public:users',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'web-user-collections',
            label: 'web-auth/public:userCollections',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },

    blocksResolver: {
      LoginForm,
      LoginError,
      RegistrationForm,
      VerifyLogin,
      UserProfile,
      UserAccountOverview,
    },

    blocksToolbars: [
      {
        name: 'authentication',
        label: 'web-auth/public:authentication',
        items: [
          RegistrationForm,
          LoginForm,
          LoginError,
          VerifyLogin,
          UserProfile,
          UserAccountOverview,
        ],
      },
    ],
  }
}
