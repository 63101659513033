import React from 'react'

import ChannelPicker from '../../components/ChannelPicker'

export default function JetstreamJetstreamTaskFields() {
  return (
    <div className="flex flex-col gap-4">
      <ChannelPicker name="settings[channel]" />
    </div>
  )
}
