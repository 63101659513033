import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Section from '@ui/data-display/Section'
import ColorPalette from '@ui/data-entry/ColorPalette'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Heading from '@ui/typography/Heading'
import { range } from '@utils/arrays'

const MAX_COLORS = 6

function isPaletteSet(colors = {}) {
  return Object.keys(colors || {}).length > 0
}

export default function ColorsForm({ site, onSubmit }) {
  const { t } = useTranslation('designs/base')
  const { colors } = site?.design || {}

  const customColors = Object.keys(colors || {}).filter(c =>
    c.startsWith('color')
  )
  const [colorCount, setColorCount] = useState(customColors.length)

  const nextColor = colorCount + 1
  const canAddColor = nextColor <= MAX_COLORS

  const onAddColor = useCallback(() => {
    if (!canAddColor) return
    setColorCount(nextColor)
  }, [canAddColor, nextColor])

  return (
    <Section title={t('colors')}>
      <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
        <Section.Body>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-8">
              <Heading as="h4">{t('customColors')}</Heading>

              {range(1, colorCount).map(i => (
                <ColorPalette
                  key={`custom-color${i}`}
                  name={`design[colors][color${i}]`}
                  label={t('customColor', { number: i })}
                />
              ))}

              <div className="flex gap-4 items-center">
                <Button
                  onClick={onAddColor}
                  label={t('addColor')}
                  icon="plus"
                  disabled={!canAddColor}
                />
                {!canAddColor && (
                  <p className="text-warn-500">
                    {t('maxColors', { max: MAX_COLORS })}
                  </p>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-8">
              <Heading as="h4">{t('systemColors')}</Heading>
              <ColorPalette
                name="design[colors][gray]"
                label={t('colorsGray')}
                help={
                  isPaletteSet(colors?.gray)
                    ? t('colorsGrayHelp')
                    : t('colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][danger]"
                label={t('colorsDanger')}
                help={
                  isPaletteSet(colors?.danger)
                    ? t('colorsDangerHelp')
                    : t('colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][success]"
                label={t('colorsSuccess')}
                help={
                  isPaletteSet(colors?.success)
                    ? t('colorsSuccessHelp')
                    : t('colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][warning]"
                label={t('colorsWarning')}
                help={
                  isPaletteSet(colors?.warning)
                    ? t('colorsWarningHelp')
                    : t('colorsNotSetHelp')
                }
              />
              <ColorPalette
                name="design[colors][info]"
                label={t('colorsInfo')}
                help={
                  isPaletteSet(colors?.info)
                    ? t('colorsInfoHelp')
                    : t('colorsNotSetHelp')
                }
              />
            </div>
          </div>
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Form>
    </Section>
  )
}
ColorsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}
