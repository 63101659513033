import PropTypes from 'prop-types'
import React from 'react'

import { useCookie } from '@ui/helpers/useStorage'

import { EntityContext } from './context'

export default function EntityProvider({ defaultEntityId, children }) {
  // Load the entity id from the cookies
  const [entityId, setEntityId] = useCookie('entityId', defaultEntityId)

  const value = React.useMemo(
    () => ({ entityId, setEntityId }),
    [entityId, setEntityId]
  )

  return (
    <EntityContext.Provider value={value}>{children}</EntityContext.Provider>
  )
}
EntityProvider.propTypes = {
  children: PropTypes.node.isRequired,
  defaultEntityId: PropTypes.string,
}
