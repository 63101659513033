import React from 'react'

import CategoryPicker from './components/CategoryPicker'
import CategoriesMenuGroup from './components/MenuGroup'
import CategoriesPermissions from './components/Permissions'
import CategoriesList from './components/blocks/CategoriesList'
import translatableBlocks from './components/blocks/translatableBlocks'

const CategoriesRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    menu: CategoriesMenuGroup,
    routes: {
      private: {
        '/categories/*': { Component: CategoriesRouter },
      },
    },

    permissions: {
      categories: {
        name: 'categories',
        label: 'categories:categories',
        icon: 'tags',
        PermissionsComponent: CategoriesPermissions,
        models: [
          {
            name: 'categories',
            label: 'categories:categories',
          },
        ],
      },
    },
    blocksResolver: {
      CategoriesList,
    },
    blocksToolbars: [
      {
        name: 'categories',
        label: 'categories:categories',
        items: [CategoriesList],
      },
    ],
    translatableBlocks,
    resources: {
      Category: {
        type: 'model',
        name: 'Category',
        label: 'categories:categories',
        labelSingular: 'categories:category',
        ResourcePicker: CategoryPicker,
        fields: {
          title: {
            label: 'title',
            type: 'string',
          },
        },
      },
    },
  }
}
