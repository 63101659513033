import PropTypes from 'prop-types'
import React from 'react'

import { SearchContext } from './context'

export default function SearchProvider({ children, search }) {
  return (
    <SearchContext.Provider value={search}>{children}</SearchContext.Provider>
  )
}

SearchProvider.propTypes = {
  children: PropTypes.node,
  search: PropTypes.object,
}
