import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import pick from 'lodash/pick'
import set from 'lodash/set'

export { get, set, pick, omit, isEmpty, isEqual }

/**
 * Removes attributes with undefined values from an object
 * @param {object} object The object to "clean-up"
 */
export function removeUndefined(object) {
  return Object.keys(object).reduce((acc, cur) => {
    if (object[cur] !== undefined) {
      acc[cur] = object[cur]
    }
    return acc
  }, {})
}

/**
 * Removes attributes with empty values from an object
 * @param {object} object The object to "clean-up"
 */
export function removeEmpty(object) {
  return Object.entries(object).reduce((acc, [key, value]) => {
    if (
      value === undefined ||
      value === null ||
      value === '' ||
      (typeof value === 'object' && isEmpty(value))
    ) {
      return acc
    }
    return { ...acc, [key]: value }
  }, {})
}

/**
 * Simple object check.
 * @param item
 * @returns {boolean}
 */
export function isObject(item) {
  return Boolean(
    item && typeof item === 'object' && !Array.isArray(item) && item !== null
  )
}

/**
 * Merge source object with target object (custom deep merge).
 * - if values are objects, merge them too.
 * - if target value is undefined, use source value.
 * - if source value is undefined, use target value.
 * - if both values are undefined, use undefined.
 *
 * @param {object} source - source object
 * @param {object} target - target object
 * @returns {object} - merged object
 */
export function merge(source, target) {
  if (!isObject(source) || !isObject(target)) return {}

  const merged = {}

  Object.keys(source).forEach(key => {
    const sourceValue = source[key]
    const targetValue = target[key]

    if (isObject(sourceValue) && isObject(targetValue)) {
      merged[key] = merge(sourceValue, targetValue)
    } else if (targetValue) {
      merged[key] = targetValue
    } else if (sourceValue) {
      merged[key] = sourceValue
    }
  })

  return merged
}
