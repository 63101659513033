import React from 'react'

import config from './config'

const CategorizedArticlesSettings = React.lazy(() => import('./Settings'))
const CategorizedArticlesBlock = React.lazy(() => import('./Block'))

export default function CategorizedArticles(props) {
  return <CategorizedArticlesBlock {...props} />
}

CategorizedArticles.toolbarItem = {
  ...config,
  component: <CategorizedArticles />,
}

CategorizedArticles.craft = {
  displayName: 'CategorizedArticles',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CategorizedArticlesSettings,
  },
}
