import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Image from '@ui/data-display/Image'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Pagination from '@ui/navigation/Pagination'
import Heading from '@ui/typography/Heading'
import Text from '@ui/typography/Text'

import useChannel from '../../services/hooks/useChannel'
import useShows from '../../services/hooks/useShows'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'ShowList',
  label: 'media-library/public:showList',
  type: 'plugin',
  icon: 'bars',
  component: <ShowList />,
}

export default function ShowList({
  channelId,
  limit,
  skip,
  sortField = 'createdAt',
  sortOrder = 'desc',
}) {
  const { t } = useTranslation('media-library/public')
  const { resources } = usePageResources()

  const sort = `${sortOrder === 'desc' ? '-' : ''}${sortField}`
  const showContent = true

  const _channelId = resources?.Channel?.id || channelId

  const { channel } = useChannel(_channelId)
  const { count, loading, shows } = useShows({
    channelId: _channelId,
    sort,
    limit,
    skip,
  })

  const displayedShows = shows?.slice(0, 18) || []
  const additionalShows = shows?.slice(18).length

  return (
    <Content icon={config.icon} title={t('showList')}>
      {_channelId ? (
        <>
          {showContent ? (
            <>
              {count > 0 ? (
                <div className="space-y-6">
                  <div className="-mx-2 flex flex-wrap">
                    {displayedShows?.map(show => (
                      <div
                        className="mx-2 mb-6 h-36 w-32 rounded border bg-gray-100"
                        key={show?.id}
                      >
                        <div>
                          <Image
                            file={show.images?.default}
                            className="rounded-t"
                            alt={show.title}
                          />
                        </div>
                        <div className="space-y-1 p-2 text-xs">
                          <div className="truncate font-semibold">
                            {show.title}
                          </div>
                        </div>
                      </div>
                    ))}
                    {additionalShows > 0 && (
                      <div className="self-center text-sm">
                        {t('moreItems', { count: additionalShows })}
                      </div>
                    )}
                  </div>
                  <Pagination page={1} pageSize={limit} total={count} />
                </div>
              ) : (
                <Text text={!loading ? t('noShows') : ' '} />
              )}
            </>
          ) : (
            <Box space="xs">
              <Heading as="h6" text={channel?.title} />
              <Text className="text-sm" text={t('showsCount', { count })} />
            </Box>
          )}
        </>
      ) : (
        <Text text={t('noChannelSelected')} />
      )}
    </Content>
  )
}
ShowList.propTypes = {
  channelId: PropTypes.string,
  limit: PropTypes.number,
  skip: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
}
ShowList.defaultProps = {}
ShowList.toolbarItem = config

function ShowListSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('showList')} help={t('showListHelp')}>
      <div className="space-y-4">
        {resources?.Channel ? (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        ) : (
          <ChannelSelect
            label={t('channel')}
            onChange={onChannelUpdate('channelId')}
            value={data.channelId}
            controlled={false}
          />
        )}
        <PagePicker
          label={t('showDetailPageId')}
          help={t('showDetailPageIdHelp')}
          value={data.showDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.showDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
          }
        />
        <Select
          label={t('sortField')}
          help={t('sortFieldHelp')}
          value={data.sortField}
          onChange={onFieldUpdate('sortField')}
        >
          <SelectOption value="title" label={t('sortByTitle')} />
          <SelectOption value="createdAt" label={t('sortByCreationDate')} />
          <SelectOption value="updatedAt" label={t('sortByUpdateDate')} />
        </Select>
        <Select
          label={t('sortOrder')}
          help={t('sortOrderHelp')}
          value={data.sortOrder}
          onChange={onFieldUpdate('sortOrder')}
        >
          <SelectOption value="asc" label={t('ascending')} />
          <SelectOption value="desc" label={t('descending')} />
        </Select>
        <Input
          label={t('perPage')}
          help={t('perPageHelp')}
          onChange={onFieldUpdate('limit', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.limit}
          type="number"
        />
        <Checkbox
          name="showDescription"
          label={t('showDescriptionInCards')}
          help={t('showDescriptionOfShowsInCardsHelp')}
          value={data.showDescription}
          onChange={checked =>
            actions.setProp(p => (p.showDescription = checked))
          }
        />
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
      </div>
    </SettingsWrap>
  )
}

ShowList.craft = {
  displayName: 'ShowList',
  props: {
    channelId: undefined,
    showDetailPageId: undefined,
    sortField: 'title',
    sortOrder: 'asc',
    limit: 16,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['shows', 'showFilters'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ShowListSettings,
  },
}
