import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import FormatDate from '@ui/helpers/FormatDate'
import Box from '@ui/layout/Box'

const config = {
  name: 'EpisodeHeader',
  label: 'media-library/public:episodeHeader',
  type: 'plugin',
  icon: 'star',
  component: <EpisodeHeader />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    abstract: '[Placeholder for abstract.]',
    category: '[category]',
    publishedAt: new Date(),
  }
}

export default function EpisodeHeader({ showAbstract }) {
  const { t } = useTranslation('media-library/public')

  const { abstract, title, category, publishedAt } = getPlaceholder()

  return (
    <Content icon={config.icon} title={t('episodeHeader')}>
      <div className="flex flex-col p-4">
        <div className="max-w-xl space-y-6">
          <h1 className="text-3xl font-bold">{title}</h1>
          {showAbstract && abstract && (
            <p className="font-serif italic text-gray-600">{abstract}</p>
          )}
          <div className="flex gap-4">
            {category && <div>category</div>}
            <div>
              <FormatDate format="P" date={publishedAt} />
            </div>
          </div>
        </div>
      </div>
    </Content>
  )
}
EpisodeHeader.propTypes = {
  showAbstract: PropTypes.bool,
}
EpisodeHeader.toolbarItem = config

function EpisodeHeaderSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t('episodeDetail')} help={t('episodeDetailHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources.Show.title}
          </Message>
        )}
        <Field label={t('fields')}>
          <Box space="xs">
            <Checkbox
              label={t('abstract')}
              onChange={value => {
                actions.setProp(props => (props.showAbstract = value))
              }}
              value={data.showAbstract}
              name="showAbstract"
            />
          </Box>
        </Field>
      </Box>
    </SettingsWrap>
  )
}

EpisodeHeader.craft = {
  displayName: 'EpisodeHeader',
  props: {
    showAbstract: true,
  },
  custom: {
    type: config.type,
    resources: ['Episode'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodeHeaderSettings,
  },
}
