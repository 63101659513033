import React from 'react'

import { useImageUpload } from '@modules/images/services/ImageServices'

import ImageUpload from '@ui/data-entry/ImageUpload'
import { useTranslation } from 'react-i18next'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { useFormContext } from 'react-hook-form'

export function HeaderIconField() {
  const design = useDesignContext()
  const { t } = useTranslation(design.namespace)
  const { onImageUpload } = useImageUpload()
  const { watch } = useFormContext()

  const setVariantWatch = watch('design[header][variant]')

  if (setVariantWatch?.startsWith('transparent')) {
    return null
  }

  return (
    <ImageUpload
      name="design[header][pageIcon]"
      label={t('pageIcon')}
      help={t('pageIconHelp')}
      onUpload={onImageUpload}
      maxSize="10mb"
      accept={['svg']}
    />
  )
}
