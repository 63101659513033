// Copies given text to clipboard
//
// Based on https://github.com/sudodoki/copy-to-clipboard
// LICENSE: https://github.com/sudodoki/copy-to-clipboard/blob/master/LICENSE
export default function copy(text = '') {
  const range = document.createRange()
  const selection = document.getSelection()
  const element = document.createElement('span')

  // Removes previos selections
  selection.removeAllRanges()

  // sets the text to be selected
  element.textContent = text

  // Set some styles
  element.style = {
    ...element.style,
    all: 'unset', // reset user styles for span element

    // prevents scrolling to the end of the page
    position: 'fixed',
    top: 0,
    clip: 'rect(0, 0, 0, 0)',

    // used to preserve spaces and line breaks
    whiteSpace: 'pre',

    // do not inherit user-select (it may be `none`)
    webkitUserSelect: 'text',
    MozUserSelect: 'text',
    msUserSelect: 'text',
    userSelect: 'text',
  }

  // Removes element to DOM
  document.body.appendChild(element)

  // Perform selection
  range.selectNode(element)
  selection.addRange(range)

  // Execute copy
  const successful = document.execCommand('copy')

  if (!successful) {
    // eslint-disable-next-line no-console
    console.error('copy command was unsuccessful')
  }

  // Removes element from DOM
  document.body.removeChild(element)
}
