import { useMemo, useRef } from 'react'

import { useDesignContext } from '@modules/web/components/DesignProvider'

import useValueAtBreakpoint from '../useValueAtBreakpoint'

// Base options:
// NOTE: (IMPORTANT) the comments after each option are set here for tailwindcss to generate the classes on build time. DO NOT REMOVE THEM!
const defaultPaddings = [
  { name: '0', numValue: '0', alias: 'none' }, // p-0 px-0 py-0 pt-0 pb-0 pl-0 pr-0
  { name: '0', numValue: '0', alias: 'zero' }, // p-0 px-0 py-0 pt-0 pb-0 pl-0 pr-0
  { name: 'px', numValue: '0.25', alias: 'pixel' }, // p-px px-px py-px pt-px pb-px pl-px pr-px
  { name: '0.5', alias: '2xs' }, // p-0.5 px-0.5 py-0.5 pt-0.5 pb-0.5 pl-0.5 pr-0.5
  { name: '1', alias: 'xs' }, // p-1 px-1 py-1 pt-1 pb-1 pl-1 pr-1
  { name: '2', alias: 'sm' }, // p-2 px-2 py-2 pt-2 pb-2 pl-2 pr-2
  { name: '3' }, // p-3 px-3 py-3 pt-3 pb-3 pl-3 pr-3
  { name: '4', alias: 'md' }, // p-4 px-4 py-4 pt-4 pb-4 pl-4 pr-4
  { name: '5' }, // p-5 px-5 py-5 pt-5 pb-5 pl-5 pr-5
  { name: '6' }, // p-6 px-6 py-6 pt-6 pb-6 pl-6 pr-6
  { name: '7' }, // p-7 px-7 py-7 pt-7 pb-7 pl-7 pr-7
  { name: '8', alias: 'lg' }, // p-8 px-8 py-8 pt-8 pb-8 pl-8 pr-8
  { name: '9' }, // p-9 px-9 py-9 pt-9 pb-9 pl-9 pr-9
  { name: '10' }, // p-10 px-10 py-10 pt-10 pb-10 pl-10 pr-10
  { name: '11' }, // p-11 px-11 py-11 pt-11 pb-11 pl-11 pr-11
  { name: '12' }, // p-12 px-12 py-12 pt-12 pb-12 pl-12 pr-12
  { name: '14' }, // p-14 px-14 py-14 pt-14 pb-14 pl-14 pr-14
  { name: '16', alias: 'xl' }, // p-16 px-16 py-16 pt-16 pb-16 pl-16 pr-16
  { name: '20' }, // p-20 px-20 py-20 pt-20 pb-20 pl-20 pr-20
  { name: '24', alias: '2xl' }, // p-24 px-24 py-24 pt-24 pb-24 pl-24 pr-24
  { name: '28' }, // p-28 px-28 py-28 pt-28 pb-28 pl-28 pr-28
  { name: '32', alias: '2xl' }, // p-32 px-32 py-32 pt-32 pb-32 pl-32 pr-32
  // { name: '36' }, // p-36 px-36 py-36 pt-36 pb-36 pl-36 pr-36
  // { name: '40' }, // p-40 px-40 py-40 pt-40 pb-40 pl-40 pr-40
  // { name: '44' }, // p-44 px-44 py-44 pt-44 pb-44 pl-44 pr-44
  // { name: '48' }, // p-48 px-48 py-48 pt-48 pb-48 pl-48 pr-48
  // { name: '52' }, // p-52 px-52 py-52 pt-52 pb-52 pl-52 pr-52
  // { name: '56' }, // p-56 px-56 py-56 pt-56 pb-56 pl-56 pr-56
  // { name: '60' }, // p-60 px-60 py-60 pt-60 pb-60 pl-60 pr-60
  // { name: '64' }, // p-64 px-64 py-64 pt-64 pb-64 pl-64 pr-64
  // { name: '72' }, // p-72 px-72 py-72 pt-72 pb-72 pl-72 pr-72
  // { name: '80' }, // p-80 px-80 py-80 pt-80 pb-80 pl-80 pr-80
  // { name: '96' }, // p-96 px-96 py-96 pt-96 pb-96 pl-96 pr-96
]

const defaultNames = defaultPaddings.map(option => option.name)

// Named option keys (for legacy custom designs)
// NOTE: When aliasNames changes, add or remove translations for the coresponding "padding_*" key in app/designs/base/translations/content-editor/en.json
const aliasNames = [
  'zero',
  'none',
  'pixel',
  '2xs',
  'xs',
  'sm',
  'md',
  'lg',
  'xl',
  '2xl',
  '3xl',
]

/**
 * Get the option from a name or alias
 * @param {string} name - The value
 * @returns {object} - the option value
 * @example
 */
export function getPaddingOption(name = '') {
  if (!name) return '' // If there is no alias, return an empty string

  // If the name is an alias
  if (aliasNames.includes(name)) {
    // search for the option with the alias and return it
    return defaultPaddings.find(option => option.alias === name)
  }

  // If the name is a default name
  if (defaultNames.includes(name)) {
    // search for the option with the name and return it
    return defaultPaddings.find(option => option.name === name)
  }

  // If there is no option with the name or alias, return undefined
  return undefined
}

/**
 * Get the padding value in px.
 * @param {string} value - The padding value
 * @returns {string} The padding value in px
 * @example
 * valueInPx('0') // -> '0px'
 * valueInPx('0.5') // -> '2px'
 * valueInPx('1') // -> '4px'
 * valueInPx('2') // -> '8px'
 */
function valueInPx(value = 0) {
  const floatValue = parseFloat(value) // Parse the value as a float
  const unitValue = 4 // 4px is the default padding unit in TailwindCSS
  return `${floatValue * unitValue}px`
}

/**
 * Hook to get the padding options.
 *
 * @returns {object[]} The options
 * @returns {string} options[].label - The label
 * @returns {string} options[].value - The value
 * @example
 * const paddings = usePaddings()
 * // -> [{ label: 'Small', value: 'sm' }, ...]
 */
function usePaddings() {
  const design = useDesignContext()
  const { name: designName, padding: designPadding } = design || {}
  const { type, options } = designPadding || {}

  const paddings = useMemo(() => {
    const groups = []

    const designGroup = {
      name: 'design',
      label: designName || 'design',
      options: defaultPaddings.filter(
        ({ name, alias }) => options?.includes(alias) || options?.includes(name)
      ),
    }

    const defaultsGroup = {
      name: 'defaults',
      label: 'defaults',
      options: defaultPaddings,
    }

    // Get the options type, if the type is 'design' and there are no options, use 'defaults' instead. This is to prevent the design group from being empty.
    const optionsType =
      type === 'design' && !options?.length ? 'defaults' : type

    // Return the groups based on type
    switch (optionsType) {
      case 'design': {
        // If the type is 'design', return only the design group
        groups.push(designGroup)
        return groups
      }
      case 'defaults': {
        // If the type is 'defaults', return only the defaults group
        groups.push(defaultsGroup)
        return groups
      }
      default: {
        // If the type is 'all', add an return both groups
        groups.push(designGroup)
        groups.push(defaultsGroup)
        return groups
      }
    }
  }, [designName, type, options])

  return paddings
}

/**
 * Hook to get the padding options.
 *
 * @param {object} [options] - Options
 * @param {string} [options.type='all'] - The type of padding to get. Can be 'all', 'design' or 'defaults'
 * @returns {object} The Options
 * @returns {object[]} options.paddingOptions - The options
 * @returns {string} options.paddingOptions[].label - The label
 * @returns {string} options.paddingOptions[].value - The value
 * @returns {object} options.selectedOption - The selected option
 * @example
 * const paddingOptions = usePaddingsOptions()
 * // -> [{ label: 'Small', value: 'sm' }, ...]
 */
export function usePaddingsOptions() {
  const paddingGroups = usePaddings()

  const selectedOptionRef = useRef()

  const paddingOptions = useMemo(() => {
    if (!paddingGroups) return {}
    return paddingGroups.map(group => {
      const { name: groupName, label, options } = group || {}

      if (!options) return []

      return {
        label,
        options: options.map(option => {
          const { name, numValue, alias } = option || {}
          const displayValue = valueInPx(numValue ?? name)
          const isDesignLabel =
            groupName === 'design' && aliasNames.includes(alias)

          return {
            displayValue,
            alias,
            value: name,
            isDesignLabel,
          }
        }, []),
      }
    })
  }, [paddingGroups])

  return { paddingOptions, selectedOption: selectedOptionRef.current }
}

// Possible padding sides
const paddingSides = ['top', 'right', 'bottom', 'left']

/**
 * Get padding classes
 * @param {object} paddingSettings - variant padding settings
 * @returns {object} - padding styles object (with className: string, style: object)
 */
export function usePaddingsClasses(settings = {}) {
  const prefixes = usePaddingsPrefixes(settings)
  const options = usePaddings()

  return useMemo(() => {
    if (!settings) {
      return {
        className: '',
        style: {},
      }
    }

    // Get the available options as a map of name => value
    const availableOptionsMap = options.reduce((acc, group) => {
      const { options } = group || {}

      if (!options) return acc

      options.forEach(option => {
        const { name } = option || {}

        acc[name] = name
      })

      return acc
    }, {})

    // Get the class names for the padding sides
    const classNames = Object.entries(settings).reduce(
      (acc, [side, sideValue]) => {
        // Only add classes for valid padding sides
        if (!paddingSides.includes(side)) return acc

        const prefix = prefixes[side] // Get the prefix for the side
        const value = availableOptionsMap[sideValue] // Get the value for the side from the available options

        // If there is a prefix and a value, add the class
        if (prefix && value) {
          // the class name is the prefix + the value, e.g. "pt-1", "px-2", "py-3", "p-4"
          acc.push(`${prefix}-${value}`)
        }

        return acc
      },
      []
    )

    return {
      className: classNames.join(' '),
      style: {},
    }
  }, [prefixes, options, settings])
}

// Supported padding prefixe
const paddingPrefixes = {
  all: 'p',
  x: 'px',
  y: 'py',
  top: 'pt',
  left: 'pl',
  bottom: 'pb',
  right: 'pr',
}

/**
 * Get padding prefixes ('p', 'px', 'py', 'pt', 'pl', 'pb', 'pr') from a padding settings object.
 *
 * @param {object} padding - padding settings
 * @returns {object} - padding prefixes object (with keys top, right, bottom, left)
 * @example
 * // with all sides set:
 * usePaddingsPrefixes({ top: 1, right: 2, bottom: 3, left: 4 })
 * // returns { top: 'pt', right: 'pr', bottom: 'pb', left: 'pl' }
 * @example
 * // with top and left set:
 * usePaddingsPrefixes({ top: 1, left: 2, })
 * // returns { top: 'py', left: 'px' }
 * @example
 * // with top set:
 * usePaddingsPrefixes({ top: 1 })
 *
 */
function usePaddingsPrefixes(padding = {}) {
  const { top, right, bottom, left } = padding

  return useMemo(() => {
    if (!(top || right || bottom || left)) return {}

    const prefixes = {}

    if (top) {
      // when other sides than top are undefined, indicate that the top value needs to set all others

      if (!left && !bottom && !right) {
        prefixes.top = paddingPrefixes.all
        return prefixes
      }

      // if both top and bottom are set, set the top prefix to "pt", otherwise to "py"
      prefixes.top = bottom ? paddingPrefixes.top : paddingPrefixes.y
    }

    // if there is a right value, set the right prefix ("pr")
    if (right) prefixes.right = paddingPrefixes.right

    // if both left and right are set, set the left prefix to "pl", otherwise to "px"
    if (left) prefixes.left = right ? paddingPrefixes.left : paddingPrefixes.x

    // if there is a bottom value, set the bottom prefix ("pb")
    if (bottom) prefixes.bottom = paddingPrefixes.bottom

    return prefixes
  }, [top, right, bottom, left])
}

/**
 * Get responsive padding classes for a block
 *
 * @param {object} padding - block padding settings
 * @returns {object} - padding styles object (with className: string, style: object)
 */
export function useBlockPaddingsClasses(padding = {}) {
  const { value: top } = useValueAtBreakpoint(padding?.top)
  const { value: right } = useValueAtBreakpoint(padding?.right)
  const { value: bottom } = useValueAtBreakpoint(padding?.bottom)
  const { value: left } = useValueAtBreakpoint(padding?.left)

  const { className } = usePaddingsClasses({ top, right, bottom, left })

  return className || ''
}
