import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import useCourse from '../../services/hooks/useCourse'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const config = {
  name: 'CourseMetaData',
  label: 'courses/public:courseMetaData',
  help: 'courses/public:courseMetaDataHelp',
  type: 'plugin',
  icon: 'cog',
  component: <CourseMetaData />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    lessons: ['', '', '', '', '', '', '', '', ''],
    students: 1234,
  }
}

export default function CourseMetaData({
  dynamic,
  courseId,
  showLessons,
  showStudents,
}) {
  const { t } = useTranslation('courses/public')

  const { resources } = usePageResources()
  const { course } = useCourse(dynamic ? null : courseId)

  const _course = resources?.Course
    ? resources.Course
    : course || getPlaceholder()

  const { lessons, students } = _course || {}

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="flex flex-col p-2">
        <div className="max-w-xl space-y-2">
          {showLessons && (
            <p className="font-serif italic text-gray-600">
              {t('lessons')}: {lessons?.length}
            </p>
          )}
          {showStudents && (
            <p className="font-serif italic text-gray-600">
              {t('students')}: {students ? students : 1234}
            </p>
          )}
        </div>
      </div>
    </Content>
  )
}
CourseMetaData.propTypes = {
  providerId: PropTypes.string,
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  showLessons: PropTypes.bool,
  showStudents: PropTypes.bool,
}
CourseMetaData.toolbarItem = config

function CourseMetaDataSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Box space="xs">
          <Checkbox
            label={t('lessons')}
            onChange={value => {
              actions.setProp(props => (props.showLessons = value))
            }}
            value={data.showLessons}
            name="showLessons"
          />
          <Checkbox
            label={t('students')}
            onChange={value => {
              actions.setProp(props => (props.showStudents = value))
            }}
            value={data.showStudents}
            name="showStudents"
          />
        </Box>
        {!resources?.Course && (
          <>
            <Divider />
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {resources?.CourseProvider ? (
                  <Message className="text-sm" type="warn">
                    {resources.CourseProvider.title}
                  </Message>
                ) : (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseMetaData.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
    lessons: true,
    students: true,
  },
  custom: {
    type: config.type,
    resources: ['courseMetaData'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseMetaDataSettings,
  },
}
