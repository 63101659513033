import PropTypes from 'prop-types'
import React from 'react'

import { DataSourcesContext } from './context'

export default function DataSourcesProvider({ children, dataSources }) {
  return (
    <DataSourcesContext.Provider value={dataSources}>
      {children}
    </DataSourcesContext.Provider>
  )
}

DataSourcesProvider.propTypes = {
  children: PropTypes.node,
  dataSources: PropTypes.object,
}
