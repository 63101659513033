import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useCreateOrUpdateSite } from '@modules/web/services/SiteServices'
import { ErrorMessage } from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import ImageUpload, { ImagePreview } from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import TextArea from '@ui/data-entry/TextArea'
import TranslationContext from '@ui/data-entry/Translation/Context'
import TranslationField from '@ui/data-entry/Translation/Field'
import TranslationHeaderSubmit from '@ui/data-entry/Translation/HeaderSubmit'
import TranslationSection from '@ui/data-entry/Translation/Section'

import design from '../index'

export function Translate({ language, source, sourceLanguage, translation }) {
  const { t } = useTranslation(['designs/adventist', 'web/appearance'])

  const { mutate, isError, isLoading, error } =
    useCreateOrUpdateSite(translation)

  const onSave = useCallback(
    data => {
      mutate(data)
    },
    [mutate]
  )

  if (!source || !translation || !language || !sourceLanguage) return null

  const formId = `site-translation-appearance-${translation?.id ?? 'new'}`

  return (
    <Form
      className="bg-white border-b isolate"
      data={translation}
      onSubmit={onSave}
      id={formId}
    >
      <TranslationHeaderSubmit
        sourceLanguage={sourceLanguage}
        language={language}
        isLoading={isLoading}
        extra={<Submit label={t('save')} icon="save" submitting={isLoading} />}
      />
      <TranslateFields
        error={error}
        isError={isError}
        language={language}
        sourceLanguage={sourceLanguage}
        source={source}
      />
    </Form>
  )
}
Translate.propTypes = {
  language: PropTypes.object,
  source: PropTypes.object,
  sourceLanguage: PropTypes.object,
  translation: PropTypes.object,
}

function TranslateFields({ error, isError, language, sourceLanguage, source }) {
  const { watch } = useFormContext()
  const { t } = useTranslation(['designs/adventist', 'web/appearance'])

  const fields = watch()

  return (
    <div className="flex flex-col gap-8 p-10">
      {isError && <ErrorMessage error={error} />}
      <TranslationContext
        sourceLang={sourceLanguage.locale}
        targetLang={language.locale}
      >
        <TranslationSection title={t('web/appearance:header')}>
          <TranslationField
            name="design[header][logo]"
            label={t('web/appearance:headerLogo')}
            help={t('designs/adventist:headerLogoHelp')}
            sourcePreview={<ImagePreview file={source?.design?.header?.logo} />}
            sourceText={source?.design?.header?.logo}
            missing={!fields?.design?.header?.logo}
            automaticTranslation={false}
            required
          >
            <ImageUpload
              name="design[header][logo]"
              label={t('web/appearance:headerLogo')}
              help={t('designs/adventist:headerLogoHelp')}
              maxSize="10mb"
              accept={design.logoFormats}
              missing={!fields?.design?.header?.logo}
            />
          </TranslationField>
          <TranslationField
            name="design[header][drawerDescription]"
            label={t('drawerDescription')}
            sourceText={source?.design?.header?.drawerDescription}
            missing={!fields?.design?.header?.drawerDescription}
            required
          >
            <TextArea
              name="design[header][drawerDescription]"
              label={t('drawerDescription')}
              help={t('drawerDescriptionHelp')}
              className="w-full"
              missing={!fields?.design?.header?.drawerDescription}
            />
          </TranslationField>
        </TranslationSection>
        <TranslationSection title={t('web/appearance:footer')}>
          <TranslationField
            name="design[footer][description]"
            label={t('web/appearance:footerDescription')}
            sourceText={source?.design?.footer?.description}
            missing={!fields?.design?.footer?.description}
            required
          >
            <Input
              name="design[footer][description]"
              label={t('web/appearance:footerDescription')}
              className="w-full"
              missing={!fields?.design?.footer?.description}
            />
          </TranslationField>
          <TranslationField
            name="design[footer][copyright]"
            label={t('web/appearance:footerCopyright')}
            sourceText={source?.design?.footer?.copyright}
            missing={!fields?.design?.footer?.copyright}
            required
          >
            <TextArea
              name="design[footer][copyright]"
              label={t('web/appearance:footerCopyright')}
              className="w-full"
              missing={!fields?.design?.footer?.copyright}
            />
          </TranslationField>
        </TranslationSection>
      </TranslationContext>
    </div>
  )
}
TranslateFields.propTypes = {
  error: PropTypes.object,
  isError: PropTypes.bool,
  language: PropTypes.object,
  sourceLanguage: PropTypes.object,
  source: PropTypes.object,
}
