import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { ErrorMessage } from '@ui/data-display/Message'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'

import { useUpdateCurrentUser } from '../services/AuthService'
import DateTimeLocaleSelect from './DateTimeLocaleSelect'

export default function DateTimeForm({ user }) {
  const { t } = useTranslation('auth')
  const { mutate, isError, isLoading, error } = useUpdateCurrentUser()

  const { preferences } = user

  const handleUpdate = data => {
    mutate({
      preferences: {
        ...user.preferences,
        ...data,
      },
    })
  }

  return (
    <Form data={preferences} onSubmit={handleUpdate}>
      <Section.Body>
        {isError && <ErrorMessage error={error} />}
        <DateTimeLocaleSelect />
      </Section.Body>
      <Section.Footer>
        <Submit
          label={t(isLoading ? 'saving' : 'update')}
          submitting={isLoading}
          reverse
        />
      </Section.Footer>
    </Form>
  )
}
DateTimeForm.propTypes = {
  user: PropTypes.object.isRequired,
}
