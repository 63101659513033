import PropTypes from 'prop-types'
import React from 'react'

export default function Divider({
  className = '',
  title,
  titleClass = '',
  horizontal = false,
}) {
  const dirClass = horizontal ? 'flex-col px-1' : 'py-1'

  return (
    <div
      className={`flex items-center justify-between ${dirClass} ${className}`}
    >
      <Line horizontal={horizontal} />
      {title && (
        <>
          <span className={`text-gray-500 font-semibold px-4 ${titleClass}`}>
            {title}
          </span>
          <Line horizontal={horizontal} />
        </>
      )}
    </div>
  )
}
Divider.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  titleClass: PropTypes.string,
  horizontal: PropTypes.bool,
}

function Line({ horizontal }) {
  const dirClass = horizontal ? 'border-r w-px' : 'border-b h-px'

  return <div className={`border-gray-300 h-px grow ${dirClass}`} />
}
Line.propTypes = {
  horizontal: PropTypes.bool,
}
