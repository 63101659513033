import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Select, { SelectField, SelectOption } from '@ui/data-entry/Select'

/**
 * The options for the published in the last select field.
 * // NOTE: The values are in days.
 */
const publishedInTheLastOptions = [
  { value: '', label: 'unlimited' },
  { value: '7', label: 'week' },
  { value: '14', label: 'twoWeeks' },
  { value: '30', label: 'month' },
  { value: '60', label: 'bimester' },
  { value: '90', label: 'quarter' },
  { value: '180', label: 'semester' },
  { value: '365', label: 'year' },
  { value: '730', label: 'twoYears' },
]

/**
 * A Select field for selecting the time frame for which the articles were published in.
 * @param {object} props The component props
 * @param {string} props.name The name of the field (defaults to 'publishedInTheLast')
 * @param {function} props.onChange The change handler
 * @param {string} props.value The selected value
 * @returns {React.ReactElement} The component
 */
export function PublishedInTheLastSelectField({
  name,
  onChange,
  value,
  ...rest
}) {
  const { t } = useTranslation('articles/public')

  return (
    <SelectField
      {...rest}
      label={t('publishedInTheLast')}
      help={t('publishedInTheLastHelp')}
      name={name || 'publishedInTheLast'}
      onChange={onChange}
      value={value}
    >
      {publishedInTheLastOptions.map(({ label, value }) => (
        <SelectOption key={value} value={value} label={t(label)} />
      ))}
    </SelectField>
  )
}
PublishedInTheLastSelectField.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}

/**
 * A Select controller for selecting the time frame for which the articles were published in.
 * @param {object} props The component props
 * @param {string} props.name The name of the field (defaults to 'publishedInTheLast')
 * @returns {React.ReactElement} The component
 */
export default function PublishedInTheLastSelectController({ name, ...props }) {
  const { t } = useTranslation('articles/public')
  return (
    <Select
      {...props}
      label={t('publishedInTheLast')}
      help={t('publishedInTheLastHelp')}
      name={name || 'publishedInTheLast'}
    >
      {publishedInTheLastOptions.map(({ label, value }) => (
        <SelectOption key={value} value={value} label={t(label)} />
      ))}
    </Select>
  )
}
PublishedInTheLastSelectController.propTypes = {
  name: PropTypes.string,
}
