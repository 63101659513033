import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import {
  AutocompleteField as Autocomplete,
  AutocompleteOption,
} from '@ui/data-entry/Autocomplete'
import { getFetch } from '@utils/fetcher'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const MAP_TOKEN = import.meta.env.VITE_MAP_TOKEN

export function GeocodeSearch({
  className,
  country,
  onChange,
  onSearch,
  value,
}) {
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')

  const { geocodeResults } = useGeocoderSearch({
    term: value,
    country,
    types: ['place', 'address', 'locality'],
  })

  const handleChange = newValue => {
    setSearchTerm('')
    onChange?.(newValue)
  }

  const handleSearch = query => {
    setSearchTerm(query)
    onSearch?.(query)
  }

  return (
    <Autocomplete
      className={className}
      placeholder={t('searchPlaceholder')}
      value={value}
      displayIcon={
        <Icon name="map-marker-alt" className="px-2 text-gray-400" />
      }
      displayValue={place => place || searchTerm}
      onChange={handleChange}
      onSearch={handleSearch}
    >
      {geocodeResults?.map(result => (
        <AutocompleteOption
          key={result.id}
          value={result}
          label={result.place_name}
        />
      ))}
    </Autocomplete>
  )
}
GeocodeSearch.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  value: PropTypes.string,
}
GeocodeSearch.defaultProps = {
  className: '',
}

function useGeocoderSearch({ term = '', types = [], country }) {
  const searchParam = encodeURIComponent(term)
  const geocodeUrl = `https://api.mapbox.com/geocoding/v5/mapbox.places/${searchParam}.json?access_token=${MAP_TOKEN}`
  const typesParam = types.join(',')

  const { data, error, isLoading, isFetching } = useQuery(
    ['geocoder', term, typesParam, country],
    () => getFetch(geocodeUrl, { types: typesParam, country }),
    { enabled: term !== '' }
  )

  return {
    geocodeResults: data?.features || [],
    error,
    isLoading,
    isFetching,
  }
}
