import React from 'react'

const FeatureFlagsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/feature-flags/*': { Component: FeatureFlagsRouter },
      },
    },
  }
}
