import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Section from '@ui/data-display/Section'
import Divider from '@ui/layout/Divider'
import Text from '@ui/typography/Text'

import Disable2FA from './Disable2FA'
import Enable2FA from './Enable2FA'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function TwoFactorAuth({ user }) {
  const { t } = useTranslation('auth')
  const [disableFormVisible, setDisableFormVisible] = useState(false)
  const [enableFormVisible, setEnableFormVisible] = useState(false)
  const { twoFactorEnabled } = user

  useEffect(() => {
    if (!twoFactorEnabled) {
      setDisableFormVisible(false)
    }
  }, [twoFactorEnabled])

  return (
    <Section.Body>
      <div className="flex flex-col gap-4 text-center">
        <div className="text-3xl text-gray-500">
          <Icon name={twoFactorEnabled ? 'lock' : 'lock-open'} />
        </div>

        <Text
          className={`font-semibold text-${
            twoFactorEnabled ? 'success' : 'warn'
          }-600`}
        >
          {t(twoFactorEnabled ? '2FAEnabled' : '2FADisabled')}
        </Text>
      </div>

      <Divider />

      {twoFactorEnabled && !enableFormVisible && (
        <>
          <Disable2FA setFormVisible={setDisableFormVisible} user={user} />
          {!disableFormVisible && <Divider />}
        </>
      )}

      {!disableFormVisible && (
        <Enable2FA setFormVisible={setEnableFormVisible} user={user} />
      )}
    </Section.Body>
  )
}
TwoFactorAuth.propTypes = {
  user: PropTypes.object.isRequired,
}
