import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import Box from '@ui/layout/Box'

const config = {
  name: 'VideoPlayer',
  label: 'media-library/public:videoPlayer',
  type: 'plugin',
  icon: 'play-circle',
  component: <VideoPlayer />,
}

export default function VideoPlayer() {
  const { t } = useTranslation('media-library/public')

  return (
    <Content className="w-full" icon={config.icon} title={t('videoPlayer')}>
      <div className="p-2">
        <div className="aspect-w-16 aspect-h-9 w-full border bg-gray-100" />
      </div>
    </Content>
  )
}
VideoPlayer.propTypes = {}
VideoPlayer.defaultProps = {}
VideoPlayer.toolbarItem = config

function VideoPlayerSettings() {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t('videoPlayer')} help={t('videoPlayerHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
      </Box>
    </SettingsWrap>
  )
}

VideoPlayer.craft = {
  displayName: 'VideoPlayer',
  props: {},
  custom: {
    type: config.type,
    resources: ['videoPlayer'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: VideoPlayerSettings,
  },
}
