import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Listbox from '@ui/data-entry/Listbox'

export function SitePageSelect({
  disabled,
  emptyDescription,
  emptyTitle,
  help,
  label,
  name = 'pageId',
  options = [],
  placeholder,
  required,
  showSite = true,
}) {
  const { t } = useTranslation('articles')

  return (
    <Listbox
      name={name}
      label={label}
      help={help}
      required={required}
      options={options}
      disabled={disabled}
      renderSelectedLabel={selectedOption => {
        if (!selectedOption)
          return <div className="text-gray-400">{placeholder}</div>
        const { label, page } = selectedOption
        return (
          <div className="flex flex-col">
            {showSite ? page.site.name : `${label} - ${page.path}`}
            {showSite && (
              <span className="text-sm text-gray-500">
                {label} - {page.path}
              </span>
            )}
          </div>
        )
      }}
      placeholder={placeholder}
    >
      <Listbox.Options>
        {options.length === 0 && (
          <Listbox.Option value="" disabled>
            <div className="flex flex-col">
              <strong>{emptyTitle || t('articleNotYetPublished')}</strong>
              <small>
                {emptyDescription || t('articleNotYetPublishedMessage')}
              </small>
            </div>
          </Listbox.Option>
        )}
        {options.map(({ value, label, disabled, page }) => {
          return (
            <Listbox.Option
              as="li"
              key={value}
              value={value}
              disabled={disabled}
              className="flex flex-col"
            >
              {({ selected }) => {
                return (
                  <>
                    {showSite ? page.site.name : `${label} - ${page.path}`}
                    {showSite && (
                      <span
                        className={`text-sm ${
                          selected ? 'text-white/80' : 'text-gray-500'
                        }`}
                      >
                        {label} - {page.path}
                      </span>
                    )}
                  </>
                )
              }}
            </Listbox.Option>
          )
        })}
      </Listbox.Options>
    </Listbox>
  )
}

SitePageSelect.propTypes = {
  disabled: PropTypes.bool,
  emptyDescription: PropTypes.string,
  emptyTitle: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showSite: PropTypes.bool,
}
