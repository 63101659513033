import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'

const config = {
  name: 'ChurchContactPersons',
  label: 'entities/public:ChurchContactPersons',
  type: 'plugin',
  icon: 'user',
  component: <ChurchContactPersons />,
}

export default function ChurchContactPersons({ dynamic }) {
  const { t } = useTranslation('entities/public')

  return (
    <Content
      className="w-full"
      icon={config.icon}
      locked={dynamic}
      title={t('ChurchContactPersons')}
    >
      <p>[Contact persons placeholder]</p>
    </Content>
  )
}
ChurchContactPersons.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
ChurchContactPersons.defaultProps = {}
ChurchContactPersons.toolbarItem = config

function ChurchContactPersonsSettings() {
  const { t } = useTranslation(['entities/public', 'web/content-editor'])

  const { bgColor } = useBlock(config.name)

  return (
    <SettingsWrap
      title={t('ChurchContactPersons')}
      help={t('ChurchContactPersonsHelp')}
    >
      <div className="space-y-4">
        {bgColor && (
          <ColorSelect
            name="backgroundColor"
            label={t('web/content-editor:backgroundColor')}
          />
        )}
      </div>
    </SettingsWrap>
  )
}

ChurchContactPersons.craft = {
  displayName: 'ChurchContactPersons',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity', 'entityServices'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchContactPersonsSettings,
  },
}
