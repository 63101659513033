import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useBlock } from '@modules/web/helpers/useBlock'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'

export default function FieldsToggleFields() {
  const { t } = useTranslation('publications/public')
  const { showLink, filterCurrentArticle } = useBlock('PublicationsList')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      {filterCurrentArticle && (
        <Checkbox
          label={t('filterCurrentArticle')}
          help={t('filterCurrentArticleHelp')}
          name="filterCurrentArticle"
          onChange={value =>
            actions.setProp(props => (props.filterCurrentArticle = value))
          }
          value={data.filterCurrentArticle}
        />
      )}
      <Checkbox
        name="showDescription"
        label={t('showDescription')}
        onChange={value =>
          actions.setProp(props => (props.showDescription = value))
        }
        value={data.showDescription}
      />
      {showLink && (
        <>
          <Checkbox
            name="showLink"
            label={t('showLink')}
            onChange={value =>
              actions.setProp(props => (props.showLink = value))
            }
            value={data.showLink}
          />
          {data.showLink && (
            <Input
              label={t('linkLabel')}
              help={t('linkLabelHelp')}
              onChange={e =>
                actions.setProp(props => (props.linkLabel = e.target.value))
              }
              value={data.linkLabel}
            />
          )}
        </>
      )}
    </>
  )
}
