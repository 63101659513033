import React from 'react'

const TasksRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/tasks/*': { Component: TasksRouter },
      },
    },
  }
}
