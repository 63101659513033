import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import { isSet } from '@utils/types'

import useValueAtBreakpoint from '../../shared/useValueAtBreakpoint'
import { allGaps, defaultGaps } from './constants'

// GRID COLUMNS:
const gridColumns = [
  '1', // grid-cols-1'
  '2', // grid-cols-2'
  '3', // grid-cols-3'
  '4', // grid-cols-4'
  '5', // grid-cols-5'
  '6', // grid-cols-6'
  '7', // grid-cols-7'
]

/**
 * Returns the list of grid column options for the current design.
 * @returns {object[]}
 * @example
 * const gridColumnOptions = useGridColumnsOptions()
 * // => [{ label: '1', value: '1' }, ...]
 */
export function useGridColumnsOptions() {
  const { t } = useTranslation('web/content-editor')

  return useMemo(() => {
    return gridColumns.map(column => ({
      label: t(`columns_${column}`),
      value: column,
    }))
  }, [t])
}

/**
 * Hook to get the grid column class name for a given number of columns.
 * @param {object} columns - The columns object for each breakpoint
 * @returns {string} - The class name
 * @example
 * const className = useGridColumnClass({ xs: 1 })
 * // -> 'grid-cols-1'
 */
export function useGridColumnClass(columns = { xs: 1 }) {
  const { value } = useValueAtBreakpoint(columns, 1) || {}

  if (!gridColumns.includes(value)) return ''

  return `grid-cols-${value}`
}

/**
 * Returns the list of grid gap options for the current design.
 * @returns {object[]}
 * @example
 * const gridGapOptions = useGridGapOptions()
 * // => [{ label: 'None', value: 'none' }, ...]
 */
export function useGridGapOptions() {
  const { t } = useTranslation('web/content-editor')

  const design = useDesignContext()
  const { type, options } = design?.gap || {}

  return useMemo(() => {
    const groups = []
    if (type === 'design' || type === 'all') {
      groups.push({
        label: design?.title || t('design'),
        options: Object.keys(allGaps)
          .filter(gap => options?.includes(gap))
          .map(value => ({
            label: `${
              allGaps[value] !== undefined ? `${t(`spacing_${value}`)} - ` : ''
            }${allGaps[value] * 4}px`,
            displayValue: ``,
            value,
          })),
      })
    }

    if (type === 'defaults' || type === 'all') {
      groups.push({
        label: t('defaultValues'),
        options: defaultGaps.map(value => ({
          label: `${value * 4}px`,
          value,
        })),
      })
    }

    return groups.map(({ label, options }) => {
      return {
        label,
        options: options.map(({ label, value }) => ({
          value,
          label,
        })),
      }
    })
  }, [design?.title, options, t, type])
}

/**
 * Hook to get the grid gap class name for a given grid gap.
 * @param {object} gap - The grid gap object for each breakpoint
 * @returns {string} - The class name
 * @example
 * const className = useGridGapClass({ xs: 'xs' })
 * // -> 'gap-1'
 */
export function useGridGapClass(gap = { xs: 'xs' }) {
  const { value } = useValueAtBreakpoint(gap, 'none') || {}

  // Check if the value is a valid grid gap value
  const gapValue = allGaps[value] || allGaps['none']

  if (!isSet(gapValue)) {
    console.warn(`Invalid grid gap value: ${value}`) // eslint-disable-line no-console
    return ''
  }

  // If the value is a name, return the class name (e.g. 'gap-1')
  return `gap-${gapValue}`
}

// GRID PLACE ITEMS:
const gridPlaceItems = [
  'start', // place-items-start',
  'center', // place-items-center',
  'end', // place-items-end',
  'stretch', // place-items-stretch',
]

/**
 * Returns the list of place items options for the current design.
 * @returns {object[]}
 * @example
 * const placeItemsOptions = useGridPlaceItemsOptions()
 * // => [{ label: 'Start', value: 'start' }, ...]
 */
export function useGridPlaceItemsOptions() {
  const { t } = useTranslation('web/content-editor')

  return useMemo(() => {
    return gridPlaceItems.map(place => ({
      value: place,
      label: t(`placeItems_${place}`),
    }))
  }, [t])
}

/**
 * Hook to get the place items class name for a given place items.
 * @param {string} placeItems - The place items value
 * @param {string} [defaultValue] - The default place items
 * @returns {string} - The class name
 * @example
 * const className = useGridPlaceItemsClass('center')
 * // -> 'place-items-center'
 */
export function useGridPlaceItemsClass(placeItems, defaultValue = 'stretch') {
  const { value } = useValueAtBreakpoint(placeItems, defaultValue) || {}

  if (!gridPlaceItems.includes(value)) return ''

  return `place-items-${value}`
}
