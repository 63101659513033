import React from 'react'

import config from './config'

const ArticleFiltersSettings = React.lazy(() => import('./Settings'))
const ArticleFiltersBlock = React.lazy(() => import('./Block'))

export default function ArticleFilters(props) {
  return <ArticleFiltersBlock {...props} />
}

ArticleFilters.toolbarItem = {
  ...config,
  component: <ArticleFilters />,
}

ArticleFilters.craft = {
  displayName: 'ArticleFilters',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleFiltersSettings,
  },
}
