import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { isFunction } from '@utils/types'

import { useRules } from '../validationHooks'
import { MultipleSelectField } from './Field'

export function MultipleSelectController({
  asText,
  className,
  disabled,
  fullWidth,
  help,
  getLabel,
  getImage,
  getExtra,
  filterOptions,
  items,
  label,
  name,
  onAddItem,
  onChange,
  onRemoveItem,
  options,
  required,
  shouldUnregister,
}) {
  const { control } = useFormContext()
  const rules = useRules({ required })

  const onFieldAdd = useCallback(
    field => item => {
      const newValue = [...field.value, item]
      field.onChange(newValue)
      if (isFunction(onChange)) {
        onChange(item, newValue)
      }
      if (isFunction(onAddItem)) {
        onAddItem(item, newValue)
      }
    },
    [onAddItem, onChange]
  )
  const onFieldRemove = useCallback(
    field => item => {
      const newValue = field.value.filter(i =>
        asText ? i !== item : i.value !== item.value
      )
      field.onChange(newValue)
      if (isFunction(onChange)) {
        onChange(item, newValue)
      }
      if (isFunction(onRemoveItem)) {
        onRemoveItem(item, newValue)
      }
    },
    [asText, onChange, onRemoveItem]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={items}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <MultipleSelectField
          className={className}
          name={name}
          label={label}
          error={fieldState.error}
          help={help}
          required={rules?.required?.value}
          asText={asText}
          disabled={disabled}
          getLabel={getLabel}
          getImage={getImage}
          getExtra={getExtra}
          filterOptions={filterOptions}
          id={name}
          fullWidth={fullWidth}
          items={field.value}
          onAddItem={onFieldAdd(field)}
          onRemoveItem={onFieldRemove(field)}
          options={options}
        />
      )}
    />
  )
}
MultipleSelectController.propTypes = {
  asText: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  getLabel: PropTypes.func,
  getImage: PropTypes.func,
  getExtra: PropTypes.func,
  filterOptions: PropTypes.func,
  help: PropTypes.string,
  items: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onAddItem: PropTypes.func,
  onChange: PropTypes.func,
  onRemoveItem: PropTypes.func,
  options: PropTypes.array,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({
      value: PropTypes.bool,
      message: PropTypes.string,
    }),
  ]),
  shouldUnregister: PropTypes.bool,
}
MultipleSelectController.defaultProps = {
  asText: false,
  className: '',
  fullWidth: true,
  items: [],
}
