import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

export default function LocationContextSelect() {
  const { t } = useTranslation('articles/public')

  return (
    <BlockField name="locationContext">
      {({ fieldProps, value, onDataChange }) => (
        <SelectField
          {...fieldProps}
          label={t('locationContext')}
          help={t('locationContextHelp')}
          value={value}
          onChange={e => onDataChange(e.target.value)}
        >
          {value && <SelectOption label={t('clearSelection')} value={null} />}
          <SelectPlaceholder label={t('-')} />
          <SelectOption label={t('locationContext_address')} value="address" />
          <SelectOption label={t('locationContext_place')} value="place" />
          <SelectOption label={t('locationContext_region')} value="region" />
          <SelectOption label={t('locationContext_country')} value="country" />
        </SelectField>
      )}
    </BlockField>
  )
}
