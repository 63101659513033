import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import ColorsForm from './ColorsForm'
import ComponentsForm from './ComponentsForm'
import FontsForm from './FontsForm'

export default function useDesignSettingsTabs() {
  const { t } = useTranslation('designs/base')

  return useMemo(
    () => [
      {
        name: 'colors',
        title: t('colors'),
        component: ColorsForm,
      },
      {
        name: 'fonts',
        title: t('fonts'),
        component: FontsForm,
        fullWidth: true,
      },
      {
        name: 'components',
        title: t('components'),
        component: ComponentsForm,
        fullWidth: true,
        noPadding: true,
      },
    ],
    [t]
  )
}
