import formatISO from 'date-fns/formatISO'

const baseUrl = '/sermons'

export function getSermonsUrl(params = '') {
  return `${baseUrl}${params}`
}

export function getAddSermonUrl(churchId, date, params = '') {
  const day = formatISO(new Date(date), {
    representation: 'date',
  })

  if (!churchId || !day) return `${baseUrl}/${params}`

  return `${baseUrl}/add/${churchId}/${day}/${params}`
}

export function getSermonUrl(sermonId, params = '') {
  return `${baseUrl}/${sermonId}/details${params}`
}

export function getEditSermonUrl(sermonId, params = '') {
  return `${baseUrl}/${sermonId}/edit${params}`
}
