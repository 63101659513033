import { range } from '@utils/arrays'

/**
 *
 * @param {object} parameters
 *
 * @returns {Array} - pages
 */
export default function usePagination({
  dividerLabel = '-',
  firstLabel = 'first',
  lastLabel = 'last',
  nextIcon = 'chevron-right',
  nextLabel = 'next',
  onNextClick,
  onPageClick,
  onPrevClick,
  page = 1,
  prevLabel = 'prev',
  prevIcon = 'chevron-left',
  setUrl = x => x,
  showFirstAndLast = false,
  showPrevAndNext = true,
  showIconArrows = true,
  surrounding = 1,
  total,
}) {
  const first = 1
  const last = total
  let lowerOffset = page - surrounding
  let higherOffset = page + surrounding
  const lowerLimit = first + surrounding
  const higherLimit = last - surrounding

  // Recalculate offsets
  lowerOffset = lowerOffset - 1 > lowerLimit ? lowerOffset : first
  higherOffset = higherOffset + 1 < higherLimit ? higherOffset : last

  // Initialize pages array
  const pages = []

  // Divider object
  const divider = { isDivider: true, label: dividerLabel }

  // Generates a page object
  function setPage(number, props = {}) {
    return {
      ...props,
      number,
      url: setUrl(number),
      isCurrent: number === page ? true : undefined,
    }
  }

  // Generates pages for given range
  function setPagesForRange(range) {
    return range.map(i => setPage(i, { onClick: onPageClick }))
  }

  // Add links and pages:

  // - First and Prev Links
  if (page > first) {
    // - Link First
    if (showFirstAndLast) {
      pages.push(setPage(first, { label: firstLabel }))
    }

    // - Link Prev
    if (showPrevAndNext) {
      pages.push(
        setPage(page - 1, {
          label: showIconArrows ? null : prevLabel,
          icon: showIconArrows ? prevIcon : null,
          isArrow: true,
          isPrev: true,
          onClick: onPrevClick,
        })
      )
    }
  }

  // - First numbered pages
  if (lowerOffset > lowerLimit) {
    const firstRange = range(first, first + surrounding)
    pages.push(setPagesForRange(firstRange))
    pages.push(divider)
  }

  // - Middle numbered pages
  const middleRange = range(lowerOffset, higherOffset)
  pages.push(setPagesForRange(middleRange))

  // - Last numbered pages
  if (higherOffset < last) {
    const lastRange = range(last - surrounding, last)
    pages.push(divider)
    pages.push(setPagesForRange(lastRange))
  }

  // - Next and Last Links
  if (page < last) {
    // - Link Next
    if (showPrevAndNext) {
      pages.push(
        setPage(page + 1, {
          label: showIconArrows ? null : nextLabel,
          icon: showIconArrows ? nextIcon : null,
          isArrow: true,
          isNext: true,
          onClick: onNextClick,
        })
      )
    }

    // - Last page
    if (showFirstAndLast) {
      pages.push(setPage(last, { label: lastLabel }))
    }
  }

  return [].concat(...pages)
}
