import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import useMenus from '@modules/web/services/hooks/useMenus'
import Button from '@ui/buttons/Button'
import Dropdown from '@ui/buttons/Dropdown'
import Empty from '@ui/data-display/Empty'
import SortableList from '@ui/data-display/SortableList'
import Modal, { ModalProvider } from '@ui/feedback/Modal'
import { reorder } from '@utils/arrays'

import { MenuPicker } from '../MenuPicker'

export function MultipleMenuPicker({ name, value = [], onChange }) {
  const { t } = useTranslation('web/menus')
  const [modal, setModal] = useState(null)
  const { menus = [] } = useMenus()

  const menuItems = useMemo(
    () =>
      value.reduce((acc, currentValue) => {
        const menu = menus.find(m => m.id === currentValue)
        return menu ? [...acc, menu] : acc
      }, []),
    [value, menus]
  )

  const onAddItem = useCallback(
    menuId => {
      onChange([...value, menuId])
    },
    [value, onChange]
  )

  const onMoveItem = useCallback(
    ({ source, destination }) => {
      onChange(reorder(value, source.index, destination.index))
    },
    [value, onChange]
  )

  const onMenuItemUpdate = useCallback(
    currentMenuId => menuId => {
      onChange(
        value.map(m => {
          if (m === currentMenuId) {
            return menuId
          }
          return m
        })
      )
    },
    [value, onChange]
  )

  const onRemoveItem = useCallback(
    menuId => {
      onChange(value.filter(m => m !== menuId))
    },
    [value, onChange]
  )

  return (
    <div className="space-y-4">
      {menuItems.length === 0 ? (
        <Empty />
      ) : (
        <SortableList
          name={name}
          items={menuItems}
          onDragEnd={onMoveItem}
          renderItem={({ id, title, ...menu }) => (
            <SortableList.Item
              key={`menu-${id}`}
              title={title}
              actions={
                <Dropdown
                  alignment="top-end"
                  button={<Button icon="ellipsis-v" size="xs" variant="flat" />}
                >
                  <Dropdown.Item
                    label={t('edit')}
                    icon="edit"
                    onClick={() => {
                      setModal({
                        menu: {
                          id,
                          title,
                          ...menu,
                        },
                        title: t('editItem'),
                        acceptLabel: t('update'),
                        onAccept: onMenuItemUpdate(id),
                      })
                    }}
                  />
                  <Dropdown.Item
                    label={t('delete')}
                    icon="trash-alt"
                    onClick={() => onRemoveItem(id)}
                    type="danger"
                  />
                </Dropdown>
              }
            />
          )}
        />
      )}

      <Button
        label={t('add')}
        onClick={() => {
          setModal({
            menu: null,
            title: t('add'),
            acceptLabel: t('add'),
            onAccept: onAddItem,
          })
        }}
        icon="plus"
        variant="primary"
        size="sm"
      />

      {modal && (
        <ModalProvider initialOpen>
          <Modal
            canClose={false}
            title={modal.title}
            acceptLabel={modal.acceptLabel}
            acceptVariant={modal.variant}
            onAccept={() => {
              modal.onAccept(modal.menu?.id)
              setModal(null)
            }}
            onCancel={() => setModal(null)}
          >
            <MenuPicker
              name="design[footer][footerMenu]"
              label={t('menu')}
              value={modal.menu?.id}
              onChange={event =>
                setModal({
                  ...modal,
                  menu: menus.find(({ id }) => id === event.target.value),
                })
              }
            />
          </Modal>
        </ModalProvider>
      )}
    </div>
  )
}

MultipleMenuPicker.propTypes = {
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
}
