import React from 'react'

import PublicationsMenuGroup from './components/PublicationsMenuGroup'
import PublicationsPermissions from './components/PublicationsPermissions'
import blocks from './components/blocks/index'

const PublicationsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'publications',
    menu: PublicationsMenuGroup,
    routes: {
      private: {
        '/publications/*': { Component: PublicationsRouter },
      },
    },
    permissions: {
      publications: {
        name: 'publications',
        label: 'publications/public:publications',
        icon: 'books',
        PermissionsComponent: PublicationsPermissions,
        models: [
          {
            name: 'publications',
            label: 'publications/public:publications',
          },
        ],
      },
    },

    blocksResolver: blocks.resolvers,
    blocksToolbars: blocks.toolbars,
    translatableBlocks: blocks.translatableBlocks,

    resources: {
      Publication: {
        type: 'model',
        name: 'Publication',
        label: 'publications/public:publications',
        labelSingular: 'publications/public:publication',
        // ResourcePicker: PublicationPicker,
        fields: {
          'title': {
            label: 'publications/public:title',
            type: 'string',
          },
          'subtitle': {
            label: 'publications/public:subtitle',
            type: 'string',
          },
          'description': {
            label: 'publications/public:description',
            type: 'string',
          },
          'body': {
            label: 'publications/public:body',
            type: 'richtext',
          },
          'cover.file': {
            label: 'publications/public:cover',
            type: 'image',
          },
          'author': {
            label: 'publications/public:author',
            type: 'string',
          },
          'publisher': {
            label: 'publications/public:publisher',
            type: 'string',
          },
          'isbn': {
            label: 'publications/public:isbn',
            type: 'string',
          },
          'pages': {
            label: 'publications/public:pages',
            type: 'string',
          },
        },
      },

      PublicationReligion: {
        type: 'model',
        name: 'PublicationReligion',
        label: 'publications/public:publicationReligions',
        labelSingular: 'publications/public:publicationReligion',
        fields: {
          title: {
            label: 'publications/public:title',
            type: 'string',
          },
          name: {
            label: 'publications/public:name',
            type: 'string',
          },
        },
      },

      PublicationCategory: {
        type: 'model',
        name: 'PublicationCategory',
        label: 'publications/public:publicationCategories',
        labelSingular: 'publications/public:publicationCategory',
        fields: {
          title: {
            label: 'publications/public:title',
            type: 'string',
          },
          name: {
            label: 'publications/public:name',
            type: 'string',
          },
        },
      },

      PublishingHouse: {
        type: 'model',
        name: 'PublishingHouse',
        label: 'publications/public:PublishingHouses',
        labelSingular: 'publications/public:publishingHouse',
        fields: {
          name: {
            label: 'publications/public:name',
            type: 'string',
          },
          email: {
            label: 'publications/public:email',
            type: 'string',
          },
          website: {
            label: 'publications/public:website',
            type: 'string',
          },
        },
      },
    },
  }
}
