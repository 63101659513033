import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useValueAtBreakpoint from '../useValueAtBreakpoint'

const availableDirections = {
  'x': 'flex-row',
  'y': 'flex-col',
  'x-reverse': 'flex-row-reverse',
  'y-reverse': 'flex-col-reverse',
}

/**
 * Returns an array of available flex directions
 * @returns {string[]}
 */
export function useFlexDirections() {
  return useMemo(() => Object.keys(availableDirections), [])
}

/**
 * Returns an array of available flex directions with labels for select options
 * @returns {string[]}
 */
export function useFlexDirectionsOptions() {
  const { t } = useTranslation('web/content-editor')
  const availableOptions = useFlexDirections()

  return availableOptions.map(key => ({
    label: t(`direction_${key}`),
    value: key,
  }))
}

/**
 * Returns a class name for a flex direction
 * @param {string} direction - Flex direction value (i.e. "x", "y", "x-reverse", "y-reverse")
 * @param {string} defaultDirection - Default flex direction
 * @returns {string} - Class name for a flex direction
 *
 * @example
 * const direction = useFlexDirectionClass({ sm: 'y', md: 'x' })
 * // direction = 'flex-col md:flex-row'
 * const direction = useFlexDirectionClass({ sm: 'x-reverse' })
 * // direction = 'flex-row-reverse'
 */
export function useFlexDirectionClass(direction, defaultDirection = 'y') {
  // get the value at the current breakpoint
  const { value } = useValueAtBreakpoint(direction, defaultDirection)

  // check if the value is valid, otherwise return the default direction
  const availableOptions = useFlexDirections()
  if (!availableOptions.includes(value)) return availableDirections.y

  // return the class name for the value
  return availableDirections[value]
}
