import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useDesign } from '@app/designs/hooks/useDesign'
import Button from '@ui/buttons/Button'
import Section from '@ui/data-display/Section'
import ColorPalette from '@ui/data-entry/ColorPalette'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Heading from '@ui/typography/Heading'

export default function ColorsForm({ site, onSubmit }) {
  const { t } = useTranslation('designs/hope')
  const design = useDesign({ design: 'hope', site })

  const formData = React.useMemo(() => {
    return {
      ...site,
      design: {
        ...site.design,
        colors: {
          ...design.colors,
          ...site.design.colors,
        },
      },
    }
  }, [design.colors, site])

  const onReset = React.useCallback(() => {
    const initialData = { ...formData }
    delete initialData.design.colors

    onSubmit(initialData)
  }, [formData, onSubmit])

  return (
    <Section title={t('colors')}>
      <Form
        data={formData}
        onSubmit={onSubmit}
        id={`${site.id}-${site.updatedAt}`}
      >
        <Section.Body>
          <div className="flex flex-col gap-16">
            <div className="flex flex-col gap-8">
              <Heading as="h4">{t('mainColors')}</Heading>

              <ColorPalette
                name={`design.colors.primary`}
                label={t('colorPrimary')}
              />
              <ColorPalette
                name={`design.colors.secondary`}
                label={t('colorSecondary')}
              />
            </div>
          </div>
        </Section.Body>
        <Section.Footer className="justify-between">
          <Button label={t('reset')} onClick={onReset} />
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Form>
    </Section>
  )
}
ColorsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}
