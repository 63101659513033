import PropTypes from 'prop-types'
import React from 'react'

import { getImageUrl } from '@utils/images'

export default function PublicationPosterItem({ publication, showImage }) {
  const imageUrl = showImage
    ? getImageUrl(publication.cover?.file, 'w:800')
    : null

  return (
    <div
      className="aspect-w-3 aspect-h-4 relative flex flex-col overflow-hidden rounded-md border border-gray-100 bg-primary-400 bg-cover bg-center shadow-md"
      style={showImage ? { backgroundImage: `url(${imageUrl})` } : undefined}
    >
      <div className="flex flex-grow flex-col justify-end rounded-md bg-gradient-to-b from-transparent via-gray-900/40 to-gray-900/90 p-6 pb-12 text-white">
        <div className="space-y-2">
          <h3 className="text-2xl font-bold">
            <a href={`#publication-${publication.id}`}>{publication.title}</a>
          </h3>
        </div>
      </div>
    </div>
  )
}
PublicationPosterItem.propTypes = {
  publication: PropTypes.object.isRequired,
  showImage: PropTypes.bool,
}
