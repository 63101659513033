import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { isValid, parse } from 'date-fns'
import parseISO from 'date-fns/parseISO'

import Button from '@ui/buttons/Button'
import ClearButton from '@ui/buttons/ClearButton'
import { Input } from '@ui/data-entry/Input'
import Popover from '@ui/feedback/Popover'
import { useDatetimeLocale } from '@ui/helpers/datetime'

import { formatDate } from '../utils'
import { DatePicker } from './DatePicker'
import { TimePicker } from './TimePicker'

export function DateTime({
  className,
  disabled,
  id,
  max,
  min,
  name,
  onChange,
  type,
  value,
  fullWidth = true,
} = {}) {
  const locale = useDatetimeLocale()

  const _value = typeof value === 'string' ? parseISO(value) : value
  const dateFormat = useMemo(
    () => (type === 'date' ? 'P' : type === 'time' ? 'p' : 'Pp'),
    [type]
  )

  const [visibleDate, setVisibleDate] = useState(
    _value ? formatDate(_value, dateFormat, { locale }) : ''
  )

  useEffect(() => {
    setVisibleDate(formatDate(_value, dateFormat, { locale }))
  }, [_value, dateFormat, locale])

  const onVisibleDateChange = useCallback(
    event => {
      const newValue = event.target.value

      if (newValue === '') {
        onChange(null)
        setVisibleDate('')
        return
      } else {
        setVisibleDate(newValue)
      }
    },
    [onChange]
  )

  const onBlur = useCallback(
    event => {
      const newValue = event.target.value

      if (newValue === '') {
        onChange(null)
        setVisibleDate('')
        return
      }

      const parsedDate = parse(newValue, dateFormat, _value ?? new Date())

      if (isValid(parsedDate)) {
        onChange(parsedDate)
      } else {
        setVisibleDate(formatDate(_value, dateFormat, { locale }))
      }
    },
    [_value, dateFormat, locale, onChange]
  )

  const onClear = useCallback(() => {
    setVisibleDate('')
    onChange(null)
  }, [onChange])

  return (
    <Popover placement="bottom-start" hideArrow>
      <Popover.Trigger asChild>
        <div className="relative flex flex-grow flex-row items-center justify-between">
          <Input
            autoComplete="off"
            className={`${className} ${type === 'date' ? 'w-48' : ''} pr-16`}
            fullWidth={fullWidth}
            id={id}
            name={name}
            type="text"
            value={visibleDate}
            onBlur={onBlur}
            onChange={onVisibleDateChange}
            disabled={disabled}
          />
          <div className="absolute right-0 flex flex-row">
            {value && !disabled && <ClearButton onClick={onClear} />}
            <Button
              className="-ml-2"
              icon="calendar-alt"
              size="sm"
              variant="flat-primary"
              disabled={disabled}
            />
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Content>
        <React.Suspense fallback={<span />}>
          {type !== 'time' && (
            <DatePicker date={_value} onChange={onChange} min={min} max={max} />
          )}
          {type !== 'date' && (
            <TimePicker date={_value} onChange={onChange} min={min} max={max} />
          )}
        </React.Suspense>
      </Popover.Content>
    </Popover>
  )
}
DateTime.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['date', 'datetime', 'time']),
  value: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  fullWidth: PropTypes.bool,
}
DateTime.defaultProps = {
  className: '',
}
