import React from 'react'

const ClientsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/clients/*': { Component: ClientsRouter },
      },
    },
  }
}
