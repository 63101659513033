import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

// The option keys used by the SideSelect component
export const borderRadiusOptions = [
  'none', // rounded-none rounded-tl-none rounded-tr-none rounded-br-none rounded-bl-none
  'sm', // rounded-sm rounded-tl-sm rounded-tr-sm rounded-br-sm rounded-bl-sm
  'base', // rounded rounded-tl rounded-tr rounded-br rounded-bl
  'md', // rounded-md rounded-tl-md rounded-tr-md rounded-br-md rounded-bl-md
  'lg', // rounded-lg rounded-tl-lg rounded-tr-lg rounded-br-lg rounded-bl-lg
  'xl', // rounded-xl rounded-tl-xl rounded-tr-xl rounded-br-xl rounded-bl-xl
  '2xl', // rounded-2xl rounded-tl-2xl rounded-tr-2xl rounded-br-2xl rounded-bl-2xl
  '3xl', // rounded-3xl rounded-tl-3xl rounded-tr-3xl rounded-br-3xl rounded-bl-3xl
  'full', // rounded-full rounded-tl-full rounded-tr-full rounded-br-full rounded-bl-full
]

export function useBorderRadiusOptions() {
  const { t } = useTranslation('web/content-editor')

  return borderRadiusOptions.map(radius => ({
    value: radius,
    label: t(`borderRadius_${radius}`),
  }))
}

export function useBorderRadiusStyles(
  radius = { tl: 'none', tr: 'none', bl: 'none', br: 'none' }
) {
  const equalSides = useMemo(
    () =>
      Object.values(radius).every(value => {
        return radius.tl === value
      }),
    [radius]
  )

  if (equalSides && borderRadiusOptions.includes(radius.tl)) {
    return {
      className: radius.tl === 'base' ? 'rounded' : `rounded-${radius.tl}`,
    }
  }

  return {
    className: Object.entries(radius).reduce((acc, [key, value]) => {
      if (borderRadiusOptions.includes(value)) {
        return `${acc} ${
          value === 'base' ? `rounded-${key}` : `rounded-${key}-${value}`
        }`
      }
      return acc
    }, ''),
  }
}
