import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Label from '@ui/data-display/Label'
import {
  AutocompleteField as Autocomplete,
  AutocompleteOption,
  AutocompleteSelectedItem,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import useOrganizers from '../../services/hooks/useOrganizers'
import useOrganizersByIds from '../../services/hooks/useOrganizersByIds'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function OrganizerPicker({
  className,
  help,
  label,
  name,
  onChange,
  required,
  multiple,
  value,
}) {
  const { t } = useTranslation('events/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { organizers: filteredOrganizers } = useOrganizers({
    search: debouncedSearchTerm,
    fetchOnSearch: true,
  })

  const { organizers: selectedOrganizers, isLoading } = useOrganizersByIds({
    ids: value ? (multiple ? value : [value]) : undefined,
    queryOptions: {
      keepPreviousData: multiple,
    },
  })

  const displayValue = useCallback(
    id => selectedOrganizers.find(o => o.id === id)?.title || searchTerm,
    [searchTerm, selectedOrganizers]
  )

  const displayMultiple = (value, onItemRemove) => {
    if (!Array.isArray(value) || !Array.isArray(selectedOrganizers)) return ''

    return selectedOrganizers.map(organizer => (
      <AutocompleteSelectedItem
        key={organizer.id}
        label={organizer.title}
        onRemove={() => onItemRemove(organizer.id)}
      />
    ))
  }

  const onOrganizerSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  const handleChange = useCallback(
    newValue => {
      setSearchTerm('')
      onChange?.(newValue)
    },
    [onChange]
  )

  return (
    <Autocomplete
      className={className}
      help={help}
      displayIcon={
        value ? <Icon name="tag" className="text-primary-500" /> : null
      }
      displayValue={displayValue}
      displayMultiple={multiple ? displayMultiple : undefined}
      label={label || t('organizer')}
      name={name}
      onChange={handleChange}
      onSearch={onOrganizerSearch}
      placeholder={isLoading ? t('loading') : t('searchPlaceholder')}
      required={required}
      value={isLoading ? undefined : value}
      multiple={multiple}
    >
      {filteredOrganizers?.map(organizer => (
        <AutocompleteOption
          label={
            <Label label={organizer.title} icon="tag" iconColor="primary" />
          }
          value={organizer.id}
          key={`organizer-${organizer.id}`}
        />
      ))}
    </Autocomplete>
  )
}
OrganizerPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
  required: PropTypes.bool,
  multiple: PropTypes.bool,
}
OrganizerPicker.defaultProps = {
  className: '',
}

export default function OrganizerPickerController({
  className,
  help,
  label,
  name,
  required,
  multiple,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <OrganizerPicker
          className={className}
          help={help}
          label={label}
          name={name}
          value={field.value}
          onChange={field.onChange}
          required={required}
          multiple={multiple}
        />
      )}
    />
  )
}
OrganizerPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
OrganizerPickerController.defaultProps = {
  className: '',
}
