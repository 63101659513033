import { useParams } from 'react-router'
import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function usePerson(id) {
  const params = useParams()

  const personId = id || params?.personId

  const { data, error, isLoading } = useQuery(['persons', personId], () =>
    getFetch(personId ? `/api/media-library/persons/${personId}` : null)
  )

  return { person: data, error, loading: isLoading }
}
