import PropTypes from 'prop-types'
import React from 'react'

import { PermissionsContext } from './context'

export default function PermissionsProvider({ children, permissions }) {
  return (
    <PermissionsContext.Provider value={permissions}>
      {children}
    </PermissionsContext.Provider>
  )
}
PermissionsProvider.propTypes = {
  children: PropTypes.node,
  permissions: PropTypes.object,
}
