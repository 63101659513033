import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

import useEvent from '../../services/hooks/useEvent'
import { EventPicker } from '../event/EventPicker'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'EventLocationMap',
  label: 'events/public:eventLocationMap',
  type: 'plugin',
  icon: 'map-marked-alt',
  component: <EventLocationMap />,
}

export default function EventLocationMap({ id, dynamic }) {
  const { t } = useTranslation('events/public')
  const { event } = useEvent(dynamic ? null : id)
  const [viewport, setViewport] = useState()

  const { mapLocation } = event ?? {}

  const markers = [
    {
      coordinates: mapLocation?.coordinates ?? [0, 0],
      center: true,
    },
  ]

  useEffect(() => {
    if (mapLocation) {
      setViewport({
        longitude: mapLocation.coordinates[0],
        latitude: mapLocation.coordinates[1],
        zoom: 14,
      })
    }
  }, [mapLocation])

  return (
    <Content
      icon={config.icon}
      title={t('eventLocationMap')}
      locked={dynamic}
      className="w-full"
    >
      {dynamic && (
        <div className="flex justify-center items-center p-4 h-80 bg-gray-100">
          [Location map]
        </div>
      )}
      {!dynamic && !!mapLocation && (
        <Map
          className="h-80"
          boundingBox={mapLocation?.boundingBox}
          markers={markers}
          markerIcon="location-dot"
          viewport={viewport}
          onMove={setViewport}
          showSearch={false}
          showControls={true}
          showGeolocate={false}
        />
      )}
    </Content>
  )
}
EventLocationMap.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
EventLocationMap.toolbarItem = config

function EventLocationMapSettings() {
  const { t } = useTranslation('events/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)

  const onEventIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('eventLocationMap')}
      help={t('eventLocationMapHelp')}
    >
      <div className="space-y-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {showPicker && (
          <EventPicker onChange={onEventIdChange} value={data.id} />
        )}
      </div>
    </SettingsWrap>
  )
}

EventLocationMap.craft = {
  displayName: 'EventLocationMap',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Event', 'eventMapLocation'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventLocationMapSettings,
  },
}
