import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isArray } from '@utils/types'

import { useSiteId } from './useSite'

export function useSubPagesById(pageId, siteId = null, params = {}) {
  const paramsSiteId = useSiteId()
  const _siteId = siteId ?? paramsSiteId

  const { data, ...rest } = useQuery(
    ['pages', 'subpages', pageId],
    () =>
      getFetch(
        _siteId ? `/api/web/sites/${_siteId}/pages/${pageId}/subpages` : null,
        params
      ),
    {
      enabled: !!(_siteId && pageId),
    }
  )

  return {
    ...rest,
    pages: data?.items,
    count: data?.count,
  }
}

export function useSubPages(page, siteId = null, params = {}) {
  return useSubPagesById(page?.id, siteId, params)
}

export function usePagesByIds(pageIds = []) {
  const paramsSiteId = useSiteId()

  const siteId = paramsSiteId

  let ids = isArray(pageIds) ? pageIds : [pageIds]

  const { data, ...rest } = useQuery(
    ['pages', siteId, ids],
    () => getFetch(`/api/web/sites/${siteId}/pages`, { pageIds: ids }),
    {
      keepPreviousData: true,
      enabled: ids.length > 0,
    }
  )

  return {
    ...rest,
    pages: data?.items,
    count: data?.count,
  }
}

export default function usePages(siteId = null, params = {}) {
  const paramsSiteId = useSiteId()

  const id = siteId ?? paramsSiteId

  const { data, error, isLoading } = useQuery(
    ['pages', 'site', id],
    () => getFetch(`/api/web/sites/${id}/pages`, params),
    {
      enabled: !!id,
    }
  )

  return {
    pages: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
  }
}
