import { useParams } from 'react-router'
import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useEvent(id) {
  const params = useParams()

  const eventId = id || params?.eventId

  const { data, error, isLoading } = useQuery(
    ['events', eventId],
    () => getFetch(`/api/events/${eventId}`),
    { enabled: !!eventId }
  )

  return { event: data, eventId, error, loading: isLoading }
}
