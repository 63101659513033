import React from 'react'

import config from './config'

const LoginErrorBlock = React.lazy(() => import('./Block'))
const LoginErrorSettings = React.lazy(() => import('./Settings'))

export default function LoginError(props) {
  return <LoginErrorBlock {...props} />
}

LoginError.toolbarItem = {
  ...config,
  component: <LoginError />,
}

LoginError.craft = {
  displayName: 'LoginError',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: LoginErrorSettings,
  },
}
