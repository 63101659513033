import PropTypes from 'prop-types'
import React from 'react'

/**
 * Select's Option
 */
export default function SelectOption({ value, label, disabled }) {
  return <option value={value} label={label} disabled={disabled} />
}
SelectOption.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}
