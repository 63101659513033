// NOTE: list extracted from https://github.com/shadiabuhilal/rtl-detect/blob/master/lib/rtl-detect.js
// See also:
// * BIDI_RTL_LANGS ref: http://en.wikipedia.org/wiki/Right-to-left#List_of_RTL_scripts
// * Table of scripts in Unicode: https://en.wikipedia.org/wiki/Script_(Unicode)#List_of_scripts_in_Unicode
export const rtlLanguages = [
  'ae' /* Avestan */,
  'ar' /* 'العربية', Arabic */,
  'arc' /* Aramaic */,
  'bcc' /* 'بلوچی مکرانی', Southern Balochi */,
  'bqi' /* 'بختياري', Bakthiari */,
  'ckb' /* 'Soranî / کوردی', Sorani */,
  'dv' /* Dhivehi */,
  'fa' /* 'فارسی', Persian */,
  'glk' /* 'گیلکی', Gilaki */,
  'he' /* 'עברית', Hebrew */,
  'ku' /* 'Kurdî / كوردی', Kurdish */,
  'mzn' /* 'مازِرونی', Mazanderani */,
  'nqo' /* N'Ko */,
  'pnb' /* 'پنجابی', Western Punjabi */,
  'ps' /* 'پښتو', Pashto, */,
  'sd' /* 'سنڌي', Sindhi */,
  'ug' /* 'Uyghurche / ئۇيغۇرچە', Uyghur */,
  'ur' /* 'اردو', Urdu */,
  'yi' /* 'ייִדיש', Yiddish */,
]

export default function isLanguageRTL(lang = 'en') {
  return rtlLanguages.includes(lang)
}
