import { useCallback, useEffect, useRef } from 'react'

import useQueryParams from './useQueryParams'

export const DEFAULT_PAGE_SIZE = 25
export const perPageOptions = [5, 10, 25, 50, 100]

const defaultParams = {
  pageSize: DEFAULT_PAGE_SIZE,
}

/**
 * Returns pagination state and functions for updating the query params.
 *
 * @param {Object} params
 * @param {number} params.pageSize
 * @returns {{onPageChange: function(number): void, onPageSizeChange: function(number): void, pageNumber: number, pageSize: number}}
 */
export default function usePaginationQuery(params = defaultParams) {
  const { queryParams, setQueryParams } = useQueryParams()
  const searchRef = useRef('')

  const options = Object.assign({}, defaultParams, params)

  const searchTerm = queryParams.get('search') || ''
  const pageNumber = parseInt(queryParams.get('pageNumber') || 1)
  const pageSize = parseInt(
    queryParams.get('pageSize') || options.pageSize || DEFAULT_PAGE_SIZE
  )

  const onPageChange = useCallback(
    number => setQueryParams('pageNumber', number),
    [setQueryParams]
  )
  const onPageSizeChange = useCallback(
    size => setQueryParams('pageSize', size),
    [setQueryParams]
  )

  useEffect(() => {
    if (searchRef.current !== searchTerm) {
      setQueryParams('pageNumber', 1)
    }
    searchRef.current = searchTerm
  }, [searchTerm, setQueryParams])

  return {
    onPageChange,
    onPageSizeChange,
    pageNumber,
    pageSize,
  }
}
