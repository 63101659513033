import { useFontFamilyStyles } from '@modules/web/components/ContentEditor/shared/FontFamilySelect/hooks'
import { useFontWeightStyles } from '@modules/web/components/ContentEditor/shared/FontWeightSelect/hooks'
import { useLetterSpacingClass } from '@modules/web/components/ContentEditor/shared/LetterSpacingSelect'
import { useLineHeightStyles } from '@modules/web/components/ContentEditor/shared/LineHeightSelect/hooks'
import { useTextAlignClass } from '@modules/web/components/ContentEditor/shared/TextAlignSelect'
import { useTextCaseStyles } from '@modules/web/components/ContentEditor/shared/TextCaseSelect/hooks'
import { useTextSizeStyles } from '@modules/web/components/ContentEditor/shared/TextSizeSelect/hooks'
import { useDesignColor } from '@modules/web/components/ContentEditor/shared/hooks'

/**
 * Get text styles from settings
 * @param {object} textSettings - text settings
 * @returns {object} - text styles object (with className: string, style: object)
 */
export default function useTextStyles(textSettings = {}) {
  const textColor = useDesignColor(textSettings?.color ?? 'gray-800')
  const textSize = useTextSizeStyles(textSettings?.size)
  const fontFamily = useFontFamilyStyles(textSettings?.family)
  const textCase = useTextCaseStyles(textSettings?.textCase)
  const fontWeight = useFontWeightStyles(textSettings?.weight)
  const textAlign = useTextAlignClass(textSettings?.align)
  const lineHeight = useLineHeightStyles(textSettings?.lineHeight)
  const letterSpacing = useLetterSpacingClass(textSettings?.letterSpacing)

  if (!textSettings) return {}

  const classNames = []
  const style = {}

  if (fontFamily?.style) {
    style.fontFamily = fontFamily.style.fontFamily
  }

  if (textSize) {
    classNames.push(textSize.className)
  }

  if (textColor) {
    style.color = textColor
  }

  if (fontWeight) {
    classNames.push(fontWeight.className)
  }

  if (textSettings?.style === 'italic') {
    classNames.push('italic')
  }

  if (textCase) {
    classNames.push(textCase.className)
  }

  if (textAlign) {
    classNames.push(textAlign)
  }

  if (lineHeight) {
    classNames.push(lineHeight.className)
  }

  if (letterSpacing) {
    classNames.push(letterSpacing)
  }

  return {
    className: classNames.join(' '),
    style,
  }
}
