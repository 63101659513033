import PropTypes from 'prop-types'
import React, { Suspense } from 'react'

import { useTranslation } from 'react-i18next'

import Loading from '@ui/feedback/Loading'

import Empty from './Empty'
import Header from './Header'
import Message from './Message'

export default function Content({ children }) {
  return <div className="flex flex-1 flex-col">{children}</div>
}
Content.propTypes = {
  children: PropTypes.node,
}
Content.defaultProps = {}

function ContentTopBar({ children }) {
  return children && <div className="border-b px-10 py-4">{children}</div>
}
ContentTopBar.propTypes = {
  children: PropTypes.node,
}
Content.TopBar = ContentTopBar
Content.TopBar.displayName = 'Content.TopBar'

function ContentHeader({
  actions,
  breadcrumbs,
  children,
  kicker,
  subtitle,
  title,
  headTitle,
}) {
  return (
    <div className="flex flex-col space-y-8 bg-white px-10 pb-10 pt-6">
      {breadcrumbs && <div>{breadcrumbs}</div>}
      <div className="flex flex-col gap-8 lg:flex-row">
        <div className="flex-1">
          {(headTitle || title) && (
            <Header
              kicker={kicker}
              subtitle={subtitle}
              title={title}
              headTitle={headTitle || title}
            />
          )}
        </div>
        {actions && <div>{actions}</div>}
      </div>
      {children}
    </div>
  )
}
ContentHeader.propTypes = {
  actions: PropTypes.node,
  breadcrumbs: PropTypes.node,
  children: PropTypes.node,
  kicker: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  headTitle: PropTypes.string,
}
Content.Header = ContentHeader
Content.Header.displayName = 'Content.Header'

function ContentBody({
  aside,
  border,
  children,
  className,
  emptyDescription,
  emptyExtra,
  emptyLabel,
  fullWidth,
  isEmpty,
  isLoading,
  padding,
}) {
  const asideClass = aside ? 'relative' : ''
  const borderClass = border ? 'border-t' : ''
  const paddingClass = padding ? 'p-10' : ''
  const fullWidthClass =
    fullWidth || isLoading ? 'w-full' : 'max-w-3xl lg:max-w-4xl'

  return (
    <div className={`flex flex-1 ${asideClass} ${borderClass} ${paddingClass}`}>
      <div className={`flex flex-1 flex-col ${fullWidthClass} ${className}`}>
        {isLoading ? (
          <Loading />
        ) : isEmpty ? (
          <Empty
            label={emptyLabel}
            description={emptyDescription}
            extra={emptyExtra}
          />
        ) : (
          children
        )}
      </div>
      {aside && <div className="sticky top-10 ml-10 self-start">{aside}</div>}
    </div>
  )
}
ContentBody.propTypes = {
  aside: PropTypes.node,
  border: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyExtra: PropTypes.node,
  emptyLabel: PropTypes.string,
  fullWidth: PropTypes.bool,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
  padding: PropTypes.bool,
}
ContentBody.defaultProps = {
  border: true,
  className: '',
  padding: true,
}
Content.Body = ContentBody
Content.Body.displayName = 'Content.Body'

function ContentBodyTable({
  children,
  className,
  emptyDescription,
  emptyExtra,
  emptyLabel,
  isEmpty,
  isLoading,
}) {
  return (
    <ContentBody
      border={isLoading ? true : false}
      className={className}
      emptyDescription={emptyDescription}
      emptyExtra={emptyExtra}
      emptyLabel={emptyLabel}
      fullWidth
      isEmpty={isEmpty}
      isLoading={isLoading}
      padding={isLoading ? true : false}
    >
      <Suspense fallback={<Loading />}>{children}</Suspense>
    </ContentBody>
  )
}
ContentBodyTable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  emptyDescription: PropTypes.string,
  emptyExtra: PropTypes.node,
  emptyLabel: PropTypes.string,
  isLoading: PropTypes.bool,
  isEmpty: PropTypes.bool,
}
ContentBodyTable.defaultProps = {
  className: '',
}
Content.Table = ContentBodyTable
Content.Table.displayName = 'Content.Table'

function ContentBodyTabs({ children, className, isLoading, padding, aside }) {
  return (
    <ContentBody
      aside={aside}
      border={isLoading ? true : false}
      className={className}
      fullWidth
      isLoading={isLoading}
      padding={padding || isLoading ? true : false}
    >
      {children}
    </ContentBody>
  )
}
ContentBodyTabs.propTypes = {
  aside: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  padding: PropTypes.bool,
}
ContentBodyTabs.defaultProps = {
  className: '',
}

Content.Tabs = ContentBodyTabs
Content.Tabs.displayName = 'Content.Tabs'

function ContentMissing({
  breadcrumbs,
  message,
  title,
  kicker,
  headerTitle,
  icon,
}) {
  const { t } = useTranslation()
  return (
    <Content>
      <Content.Header
        breadcrumbs={breadcrumbs}
        title={headerTitle || t('notFound')}
        kicker={kicker || t('notFoundKicker')}
      />
      <Content.Body>
        <Message
          title={title || t('notFound')}
          text={message || t('notFoundMessage')}
          type="danger"
          icon={icon || 'file-slash'}
        />
      </Content.Body>
    </Content>
  )
}
ContentMissing.propTypes = {
  breadcrumbs: PropTypes.node,
  title: PropTypes.string,
  message: PropTypes.node,
  kicker: PropTypes.string,
  icon: PropTypes.string,
  headerTitle: PropTypes.node,
}
Content.Missing = ContentMissing
Content.Missing.displayName = 'Content.Missing'

function ContentLoading({ breadcrumbs, title }) {
  return (
    <Content>
      <Content.Header breadcrumbs={breadcrumbs} title={title} />
      <Content.Body isLoading />
    </Content>
  )
}
ContentLoading.propTypes = {
  breadcrumbs: PropTypes.node,
  title: PropTypes.string,
}
Content.Loading = ContentLoading
Content.Loading.displayName = 'Content.Loading'
