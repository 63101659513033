import React from 'react'

import config from './config'

const UserAccountOverviewBlock = React.lazy(() => import('./Block'))
const UserAccountOverviewSettings = React.lazy(() => import('./Settings'))

export default function UserAccountOverview(props) {
  return <UserAccountOverviewBlock {...props} />
}

UserAccountOverview.toolbarItem = {
  ...config,
  component: <UserAccountOverview />,
}

UserAccountOverview.craft = {
  displayName: 'UserAccountOverview',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: UserAccountOverviewSettings,
  },
}
