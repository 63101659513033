import React from 'react'

import { useQueryClient } from 'react-query'

import { useCookie } from '@ui/helpers/useStorage'

export function useImpersonateUser() {
  const queryClient = useQueryClient()
  const [impersonationId, setImpersonationId, stopImpersonation] = useCookie(
    'impersonationId',
    null
  )

  const setImpersonationFunction = React.useCallback(
    id => {
      setImpersonationId(id)

      // Navigate to '/' to prevent unauthorized access to protected pages
      // If I use navigate to the current page, the user may have access to the page they were on before it navigates
      window.location.href = '/'

      // The query being invalidated before fully leaving the page will cause the user to be logged out.
      setTimeout(() => {
        queryClient.invalidateQueries('users')
      }, 2000) // TODO: This is a hack to invalidate the users query after the impersonation is set. This should be done in a better way. If someone has time, please fix this.
    },
    [queryClient, setImpersonationId]
  )

  const stopImpersonationFunction = React.useCallback(() => {
    stopImpersonation()
    queryClient.invalidateQueries('users')
    //TODO: When adding an impersonation permission, navigate to '/' as well since the impersonated user may not have access to the page they were on
  }, [queryClient, stopImpersonation])

  return {
    impersonationId,
    setImpersonationId: setImpersonationFunction,
    stopImpersonation: stopImpersonationFunction,
  }
}
