import { useDesignContext } from '../../../DesignProvider'

const columnsOptions = [
  '1', // grid-cols-1'
  '2', // grid-cols-2'
  '3', // grid-cols-3'
  '4', // grid-cols-4'
  '5', // grid-cols-5'
  '6', // grid-cols-6'
  '7', // grid-cols-7'
  '8', // grid-cols-8'
  '9', // grid-cols-9'
  '10', // grid-cols-10'
  '11', // grid-cols-11'
  '12', // grid-cols-12'
]

/**
 * Returns the columns that are available for the current design.
 *
 * @returns {string[]}
 * @example
 * const columns = useColumns()
 * // columns = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10']
 */
export function useColumns() {
  const design = useDesignContext()

  // If the design doesn't have columns constraints, return all columns
  if (!design.columns?.length) return columnsOptions

  // Ensures that desigh columns are strings
  const designColumns = design.columns.map(column => column.toString())

  // Filters the available columns based on the design columns
  return columnsOptions.filter(column => designColumns.includes(column))
}

/**
 * Returns the class name for the column
 * @param {number} columns - The number of columns
 * @param {number} [defaultColumns=1] - The default number of columns
 * @returns {string} The class name for the column
 * @example
 * const columns = useColumnsClass(2)
 * // columns = 'grid-cols-2'
 */
export function useColumnsClass(columns, defaultColumns = 1) {
  const availableColumns = useColumns()

  const value = columns || defaultColumns || 1

  if (!availableColumns.includes(value)) return ''

  return `grid-cols-${value}`
}
