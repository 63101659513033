// List of available text cases
export const textCases = ['normal-case', 'uppercase', 'lowercase', 'capitalize']

/**
 * Returns the class name and style object for a given text case
 *
 * @param {string} textCase - The text case
 * @returns {object} - The class name and style object
 * @example
 * const { className, style } = useTextCaseStyles('uppercase')
 * // -> { className: 'uppercase', style: {} }
 */
export function useTextCaseStyles(textCase = '') {
  const classNames = []
  const style = {}

  if (textCases.includes(textCase)) {
    classNames.push(textCase)
  }

  return {
    className: classNames.join(' '),
    style,
  }
}
