import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import useShow from '../services/hooks/useShow'
import useShows from '../services/hooks/useShows'

export function ShowPicker({
  channelId,
  className,
  help,
  hideLabel,
  ignoreIds,
  label,
  name,
  onChange,
  required,
  value,
}) {
  const { t } = useTranslation('media-library/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)
  const filters = { 'ignore-ids': !isEmpty(ignoreIds) ? ignoreIds : null }

  const { resources } = usePageResources()

  const _channelId = channelId || resources?.Channel?.id

  const { shows } = useShows({
    channelId: _channelId,
    limit: 100,
    search: debouncedSearchTerm,
    fetchOnSearch: false,
    filters,
  })
  const { show } = useShow(value)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`space-y-4 ${className}`}>
      <SearchDropdown
        label={hideLabel ? '' : label || t('show')}
        help={help}
        placeholder={t('typeToSearch')}
        name={name}
        value={value}
        selectedLabel={show?.title}
        onChange={onChange}
        onSearch={onSearch}
        required={required}
      >
        {shows?.map(show => (
          <SearchDropdown.Option
            key={show.id}
            label={show.title}
            name={name}
            value={show.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
ShowPicker.propTypes = {
  channelId: PropTypes.string,
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
}
ShowPicker.defaultProps = {
  className: '',
}

export default function ShowPickerController({
  channelId,
  className,
  help,
  hideLabel,
  ignoreIds,
  label,
  name,
  onChange,
  required,
  shouldUnregister,
}) {
  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <ShowPicker
          channelId={channelId}
          className={className}
          help={help}
          hideLabel={hideLabel}
          ignoreIds={ignoreIds}
          label={label}
          name={name}
          value={field.value}
          onChange={onFieldChange(field)}
          required={required}
        />
      )}
    />
  )
}
ShowPickerController.propTypes = {
  channelId: PropTypes.string,
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
ShowPickerController.defaultProps = {
  className: '',
}
