import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import PagePicker from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Input from '@ui/data-entry/Input'
import Select, { SelectOption } from '@ui/data-entry/Select'
import TextArea from '@ui/data-entry/TextArea'
import Box from '@ui/layout/Box'

export default function ArticlesFeedFilters({ site }) {
  const { t } = useTranslation('articles/public')

  return (
    <Box space="xl">
      <Input
        name="xml[settings][title]"
        label={t('title')}
        placeholder={site.rss?.title}
      />
      <TextArea
        name="xml[settings][description]"
        label={t('description')}
        placeholder={site.rss?.description}
      />
      <Input
        name="xml[settings][copyright]"
        label={t('copyright')}
        placeholder={site.rss?.copyright}
      />

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
        <Input
          name="xml[settings][author]"
          label={t('author')}
          placeholder={site.rss?.author}
        />
        <Input
          name="xml[settings][authorEmail]"
          label={t('authorEmail')}
          placeholder={site.rss?.authorEmail}
        />
      </div>

      <Box space="xl" horizontal>
        <Select
          className="w-full md:w-1/2 lg:w-1/3"
          name="xml[settings][sortField]"
          label={t('sortField')}
          help={t('sortFieldHelp')}
        >
          <SelectOption value="title" label={t('title')} />
          <SelectOption value="publishedAt" label={t('publishedDate')} />
          <SelectOption value="createdAt" label={t('createdDate')} />
          <SelectOption value="updatedAt" label={t('updatedDate')} />
        </Select>
        <Select
          className="w-full md:w-1/2 lg:w-1/3"
          name="xml[settings][sortOrder]"
          label={t('sortOrder')}
          help={t('sortOrderHelp')}
        >
          <SelectOption value="asc" label={t('ascending')} />
          <SelectOption value="desc" label={t('descending')} />
        </Select>
        <Input
          className="w-full md:w-1/2 lg:w-1/3"
          name="xml[settings][limit]"
          label={t('limit')}
          help={t('limitHelp')}
          type="number"
        />
      </Box>

      {Array.isArray(site.flags) && (
        <Select
          name="xml[settings][flag]"
          label={t('flag')}
          help={t('flagHelp')}
        >
          <SelectOption value="" label={t('flagShowAny')} />
          {site.flags.map(flag => (
            <SelectOption
              value={flag.id}
              label={flag.name}
              key={`flag-${site.id}-${flag.id}`}
            />
          ))}
        </Select>
      )}

      <PagePicker
        name="xml[settings][detailPageId]"
        label={t('detailPageId')}
        help={t('detailPageIdHelp')}
        condition={page =>
          page.dynamic && getDynamicResourceIdentifier(page) === 'Article'
        }
      />
    </Box>
  )
}
ArticlesFeedFilters.propTypes = {
  page: PropTypes.object.isRequired,
  site: PropTypes.object.isRequired,
}
