import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import BlockField from '../../../shared/BlockField'
import InheritSelectPlaceholder from '../../../shared/InheritSelectPlaceholder'
import { useGridColumnsOptions } from '../hooks'

export default function GridColumnsSelect() {
  const { t } = useTranslation('web/content-editor')

  const gridColumns = useGridColumnsOptions()

  return (
    <BlockField defaultValue={1} name="columns" responsive>
      {({ fieldProps, value, inheritFrom, inheritValue, onDataChange }) => (
        <SelectField
          {...fieldProps}
          label={t('gridColumns')}
          value={value}
          onChange={e => onDataChange(e.target.value)}
        >
          <InheritSelectPlaceholder
            labelKey="columns"
            value={value}
            inheritValue={inheritValue}
            inheritFrom={inheritFrom}
          />
          {value && <SelectOption label={t('clearSelection')} value={null} />}
          <SelectPlaceholder label="-" />
          {gridColumns.map(({ label, value }) => (
            <SelectOption label={label} value={value} key={label} />
          ))}
        </SelectField>
      )}
    </BlockField>
  )
}
