import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Dropdown from '@ui/buttons/FloatingDropdown'
import Toast, { useToast } from '@ui/data-display/Toast'
import { useLocalStorageQuery } from '@ui/helpers/useStorage'

export default function ImageClipboardDropdown({
  value,
  onChange,
  disabled,
  allowCopy = true,
  allowPaste = true,
}) {
  const { t } = useTranslation('ui/image-upload')
  const [clipboardImageRaw, setClipboardImageRaw] =
    useLocalStorageQuery('clipboard-image')

  const { showToast, hideToast, message, variant, isToastVisible } = useToast()

  const clipboardImage = clipboardImageRaw
    ? JSON.parse(clipboardImageRaw)
    : undefined

  const isSameClipboardImage = clipboardImage
    ? clipboardImage.name === value?.name &&
      clipboardImage.containerId === value?.containerId
    : false

  // Copy image's file object to clipboard
  const onImageCopy = React.useCallback(() => {
    setClipboardImageRaw(JSON.stringify(value))
    showToast({ message: t('copiedImageMessage'), variant: 'success' })
  }, [value, setClipboardImageRaw, showToast, t])

  // Read copied image object from clipboard
  const onImagePaste = React.useCallback(() => {
    if (typeof onChange !== 'function') return
    onChange(clipboardImage)
    showToast({ message: t('pastedImageMessage'), variant: 'success' })
  }, [onChange, clipboardImage, showToast, t])

  return (
    <>
      <Dropdown
        icon="clipboard"
        size="sm"
        tooltip={t('clipboard')}
        showArrow
        floatingClass="z-max"
      >
        {isSameClipboardImage && (
          <>
            <Dropdown.Item
              label={t('currentImageCopied')}
              icon="check"
              disabled
            />
            <Dropdown.Divider />
          </>
        )}
        {allowCopy && (
          <Dropdown.Item
            label={t('copyImage')}
            icon="clipboard"
            onClick={onImageCopy}
            disabled={disabled || isSameClipboardImage}
          />
        )}
        {allowPaste && (
          <Dropdown.Item
            label={t('pasteImage')}
            icon="clipboard"
            onClick={onImagePaste}
            disabled={!clipboardImage || isSameClipboardImage}
          />
        )}
      </Dropdown>

      <Toast
        message={message}
        icon="clipboard"
        position="top"
        variant={variant}
        visible={isToastVisible}
        onHide={hideToast}
      />
    </>
  )
}
ImageClipboardDropdown.propTypes = {
  value: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  allowCopy: PropTypes.bool,
  allowPaste: PropTypes.bool,
}
