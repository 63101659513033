import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import SitePickerController from '@modules/web/components/sites/SitePicker'
import useSite from '@modules/web/services/hooks/useSite'
import Input from '@ui/data-entry/Input'
import Select from '@ui/data-entry/Select'
import Tags from '@ui/data-entry/Tags'
import Box from '@ui/layout/Box'

import PagePicker from '../../components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '../../utils/dynamicResource'

export default function MailjetNewsletterTaskFields({ task = {} }) {
  const { t } = useTranslation('web/tasks')
  const { watch } = useFormContext()

  const siteWatch = watch('settings.site')
  const flagsWatch = watch('settings.flags')
  const flagExceptionsWatch = watch('settings.flagExceptions')

  const { site: currentSite } = useSite(siteWatch)

  const { meta } = task || {}
  const { context } = meta || {}
  const { templates, contactLists, senders } = context || {}

  const contactListOptions = useMemo(() => {
    return contactLists?.map(({ id, name, subscribers }) => ({
      value: id,
      label: `${name} (${subscribers})`,
    }))
  }, [contactLists])

  const senderOptions = useMemo(() => {
    return senders?.map(({ id, email, name }) => ({
      value: id,
      label: `${name} (${email})`,
    }))
  }, [senders])

  return (
    <Box space="xl">
      <SitePickerController
        name={`settings[site]`}
        label={t('mailjet-newsletter-site')}
        required
      />

      {Boolean(siteWatch) && (
        <>
          <PagePicker
            name="settings.articleDetailPageId"
            label={t('mailjet-newsletter-articleDetailPageId')}
            help={t('mailjet-newsletter-articleDetailPageIdHelp')}
            siteId={siteWatch}
            condition={page =>
              page.dynamic && getDynamicResourceIdentifier(page) === 'Article'
            }
          />

          <Tags
            name="settings.flags"
            label={t('mailjet-newsletter-flags')}
            help={t('mailjet-newsletter-flagsHelp')}
            suggestions={currentSite?.flags?.map(i => i.id)}
            getItemLabel={id =>
              currentSite?.flags?.find(i => i.id === id)?.name
            }
            items={flagsWatch || []}
            asText
            shouldUnregister
          />

          <Tags
            name="settings.flagExceptions"
            label={t('mailjet-newsletter-excludedFlags')}
            help={t('mailjet-newsletter-excludedFlagsHelp')}
            suggestions={currentSite?.flags?.map(i => i.id)}
            getItemLabel={id =>
              currentSite?.flags?.find(i => i.id === id)?.name
            }
            items={flagExceptionsWatch || []}
            asText
            shouldUnregister
          />
        </>
      )}

      <Box space="md" className="border-t pt-6">
        <Input
          name={`settings[campaignName]`}
          label={t('mailjet-newsletter-campaignName')}
          help={t('mailjet-newsletter-campaignNameHelp')}
          required
        />
        {templates?.length > 0 && (
          <Select
            name={`settings[template]`}
            label={t('mailjet-newsletter-template')}
            required
          >
            <Select.Placeholder
              label={t('mailjet-newsletter-selectTemplate')}
            />
            {templates?.map(({ label, value }) => (
              <Select.Option
                label={label}
                value={value}
                key={`template-${value}`}
              />
            ))}
          </Select>
        )}
        {contactListOptions?.length > 0 && (
          <Select
            name={`settings[contactList]`}
            label={t('mailjet-newsletter-contactList')}
            required
          >
            <Select.Placeholder
              label={t('mailjet-newsletter-selectContactList')}
            />
            {contactListOptions?.map(({ label, value }) => (
              <Select.Option
                label={label}
                value={value}
                key={`contactList-${value}`}
              />
            ))}
          </Select>
        )}
        {senderOptions?.length > 0 && (
          <Select
            name={`settings[sender]`}
            label={t('mailjet-newsletter-sender')}
            required
          >
            <Select.Placeholder label={t('mailjet-newsletter-selectSender')} />
            {senderOptions?.map(({ label, value }) => (
              <Select.Option
                label={label}
                value={value}
                key={`sender-${value}`}
              />
            ))}
          </Select>
        )}
        <Input
          name={`settings[senderName]`}
          label={t('mailjet-newsletter-senderName')}
          required
        />
        <Input
          name={`settings[replyEmail]`}
          label={t('mailjet-newsletter-replyEmail')}
          type="email"
          required
        />
        <Input
          name={`settings[subject]`}
          label={t('mailjet-newsletter-subject')}
          required
        />
      </Box>
    </Box>
  )
}

MailjetNewsletterTaskFields.propTypes = {
  task: PropTypes.object,
}
