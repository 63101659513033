import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

import { useSiteId } from './useSite'

export default function useMenus() {
  const siteId = useSiteId()

  // Adding the siteId to the query ID so it refreshes when siteId changes
  const { data, error, isLoading } = useQuery(
    ['menus', 'site', siteId],
    () => getFetch(`/api/web/sites/${siteId}/menus`),
    {
      enabled: siteId ? true : false,
    }
  )

  return {
    menus: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
  }
}
