import { isString } from './types'

/**
 * Get initials of name
 * @param {string} name
 */
export function initials(name, maxLength = 2) {
  return isString(name)
    ? name
        .split(' ')
        .map(namePart => namePart.charAt(0).toUpperCase())
        .slice(0, maxLength)
        .join('')
        .toUpperCase()
    : ''
}

/**
 * Pseudo-random id generator
 * @param {number} length id's length (in chars)
 * @param {array} except list of ids that should be avoided
 * @returns {string}
 */
export function uid(length = 8, except = []) {
  const id = Math.random().toString(36).slice(-length)

  // Try
  if (except.includes(id)) {
    return uid(length, except)
  }

  return id
}

/**
 * Validates an id as a mongodb id and returns a boolean
 * @param {string} id - the id to validate
 * @returns {boolean}
 */
export function validateMongoId(id) {
  if (!id) return false

  const regex = /^[0-9a-fA-F]{24}$/

  return regex.test(id)
}

/**
 * Capitalizes first letter of the string
 *
 * @param {string} str
 * @returns {string}
 */
export function capitalize(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : ''
}

/**
 * Converts a string into a slug[ish] String
 *
 * @param {String} text The string to convert in a slug
 * @param {Boolean} truncate prevents
 * @param {Number} [maxLength=120] - Maximum slug string lenght
 */
export function slugify(text, truncate = true, maxLength = 120) {
  let slug = text
    .normalize('NFKC') // Replaces special characters with their normal version
    .replace(/[°<>#%{}()|\\^~[\]`'?=:;]/g, '') // Removes invalid characters to make it a valid URL

    // Replaces the need for kebabCase,
    // Which can't be used because it deletes the spaces instead of adding dashes
    .replace(/([^\d-])(?=\d)|(\d)(?![\d-]|$)(?=[^\d-])/g, '$1$2-') // Adds a dash between a number and a character that is not a number (except -)
    .toLowerCase()

    // Cleans up the slug
    .replace(/\/+/g, '') // Removes slashes
    .replace(/\s+/g, '-') // Replaces spaces with dashes
    .replace(/[,!"§$&_+*]/g, '-') // Replaces some special characters with dashes
    .replace(/--+/g, '-') // Replaces two or more dashes with a single one
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
    .replace(/\u{2D}\u{301}/gu, '') //Replaces acute accent when it's combined with a dash, beacuse this character appears when you type an acute accent without any other character

  if (truncate) {
    slug = slug.slice(0, maxLength).replace(/-+$/, '') // Trim - from end of text
  }

  return slug
}

/**
 * Encodes a given string in HEX
 *
 * @param {string} string
 * @returns {string} encoded string
 */
export function stringToHex(string = '') {
  return string
    .split('')
    .map(c => c.charCodeAt(0).toString(16).padStart(2, '0'))
    .join('')
}

/**
 * Decodes an HEX string
 *
 * @param {string} hex
 * @returns {string} decoded hex
 */
export function hexToString(hex = '') {
  return hex
    .split(/(\w\w)/g)
    .filter(p => !!p)
    .map(c => String.fromCharCode(parseInt(c, 16)))
    .join('')
}

/**
 * Escapes all special characters in the provided string
 * @param {string} string string to sanitize.
 * @returns `string` escaped string
 */
export function escapeSpecialChars(string = '') {
  return string.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&')
}
