import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useSite from '@modules/web/services/hooks/useSite'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

const config = {
  name: 'CoursesDeleteStudentAccount',
  label: 'courses/public:deleteStudentAccount',
  help: 'courses/public:deleteStudentAccountHelp',
  type: 'plugin',
  icon: 'trash-alt',
  component: <DeleteStudentAccount />,
}

export default function DeleteStudentAccount() {
  const { t } = useTranslation(['courses/public', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const errors = []

  if (!authEnabled) {
    errors.push({ title: t('web-auth/public:authDisabledNotification') })
  }

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      errors={errors}
    >
      {authEnabled ? (
        <div className="space-y-6 p-4">
          <h1 className="text-3xl font-bold">
            {t('deleteStudentAccountTitle')}
          </h1>
          <p>{t('deleteStudentAccountDescription')}</p>
          <div>
            <Button label={t('deleteStudentAccountButton')} />
          </div>
        </div>
      ) : (
        <div className="p-2">
          <Message
            type="warn"
            text={t('web-auth/public:authDisabledNotification')}
          />
        </div>
      )}
    </Content>
  )
}
DeleteStudentAccount.propTypes = {}
DeleteStudentAccount.toolbarItem = config

function DeleteStudentAccountSettings() {
  const { t } = useTranslation(['courses/public', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Checkbox
        help={t('keepWebUserHelp')}
        label={t('keepWebUser')}
        onChange={value => {
          actions.setProp(props => (props.keepWebUser = value))
        }}
        value={data.keepWebUser}
        name="keepWebUser"
        disabled={!authEnabled}
      />
    </SettingsWrap>
  )
}

DeleteStudentAccount.craft = {
  displayName: config.name,
  props: {
    keepWebUser: false,
  },
  custom: {
    type: config.type,
    resources: ['coursesStudentProfile'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: DeleteStudentAccountSettings,
  },
}
