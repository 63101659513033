import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { supportedLanguages } from '@config/i18n'
import nativeLanguages from '@config/languages'
import Select from '@ui/data-entry/Select'

const languageOptions = supportedLanguages.map(language => {
  const nativeLanguage = nativeLanguages.find(l => l.code === language)
  if (!nativeLanguage) {
    return { value: language, label: language }
  }
  return { value: language, label: nativeLanguage.native }
})

export default function InterfaceLanguageSelect({ name = 'language' }) {
  const { t } = useTranslation('auth')

  return (
    <Select label={t('interfaceLanguage')} name={name} value="en">
      {languageOptions.map(({ value, label }) => (
        <Select.Option key={value} label={label} value={value} />
      ))}
    </Select>
  )
}
InterfaceLanguageSelect.propTypes = {
  name: PropTypes.string,
}
