import PropTypes from 'prop-types'
import React from 'react'

import Box from '@ui/layout/Box'

import { useModal } from '../hooks'

export default function ModalActions({ asChild, children }) {
  const { onClose } = useModal()

  return (
    <Box
      className={`border-t px-6 ${asChild ? '-mx-6 pt-5' : 'py-5'}`}
      horizontal
      justify="end"
      space="md"
    >
      {typeof children === 'function' ? children({ onClose }) : children}
    </Box>
  )
}
ModalActions.propTypes = {
  asChild: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),
}
