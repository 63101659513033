import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export default function PublicationDetailTranslation({ id, source }) {
  const { t } = useTranslation('publications/public')
  const fields = React.useMemo(
    () => ({
      summaryLabel: { label: t('summaryLabel') },
      typeLabel: { label: t('typeLabel') },
      authorLabel: { label: t('authorLabel') },
      publisherLabel: { label: t('publisherLabel') },
      availableLanguagesLabel: { label: t('availableLanguagesLabel') },
      availableLanguages: { label: t('availableLanguages') },
      pagesLabel: { label: t('pagesLabel') },
      categoriesLabel: { label: t('categoriesLabel') },
      downloadLabel: { label: t('downloadLabel') },
      languageLabel: { label: t('languageLabel') },
      typeTract: { label: t('typeTract') },
      typeMagazine: { label: t('typeMagazine') },
      typeBook: { label: t('typeBook') },
      pdfDigitalTooltip: { label: t('pdfDigitalTooltip') },
      pdfPrintingTooltip: { label: t('pdfPrintingTooltip') },
      epubTooltip: { label: t('epubTooltip') },
      modalButtonLabel: { label: t('modalButtonLabel') },
      promotionalMaterialsLabel: { label: t('promotionalMaterialsLabel') },
      studyGuideLabel: { label: t('studyGuideLabel') },
      readingPlanLabel: { label: t('readingPlanLabel') },
      audioLabel: { label: t('audioLabel') },
    }),
    [t]
  )

  return (
    <BlockTranslation
      id={id}
      source={source}
      fields={fields}
      i18Namespace="publications/public"
    />
  )
}
PublicationDetailTranslation.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
}
