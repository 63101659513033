import { createContext, useContext } from 'react'

export const BlocksResolverContext = createContext()
export const BlocksToolbarsContext = createContext()
export const TranslatableBlocksContext = createContext()

export function useBlocksResolver() {
  return useContext(BlocksResolverContext)
}

export function useBlocksToolbars() {
  return useContext(BlocksToolbarsContext)
}

export function useTranslatableBlocks() {
  return useContext(TranslatableBlocksContext)
}
