import { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import get from 'lodash/get'
import set from 'lodash/set'

import useSetDataAtBreakpoint from './useSetDataAtBreakpoint'
import useValueAtBreakpoint from './useValueAtBreakpoint'

export function useBlockData(
  name,
  { value, defaultValue, onChange, responsive, breakpointOverride } = {}
) {
  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const dataValue = get(data, name)

  const setDataAtBreakpoint = useSetDataAtBreakpoint(breakpointOverride)
  const valueAtBreakpoint = useValueAtBreakpoint(
    responsive
      ? typeof dataValue === 'object'
        ? dataValue
        : { xs: dataValue }
      : dataValue,
    defaultValue,
    breakpointOverride
  )

  const displayedValue =
    value ?? responsive
      ? valueAtBreakpoint?.inherit
        ? ''
        : valueAtBreakpoint?.value
      : dataValue ?? defaultValue

  const onDataChange = useCallback(
    newValue => {
      onChange
        ? onChange(newValue)
        : responsive
        ? setDataAtBreakpoint(name, newValue)
        : actions.setProp(props => set(props, name, newValue))
    },
    [actions, name, onChange, responsive, setDataAtBreakpoint]
  )

  const setData = useCallback(
    (path, value) => {
      responsive
        ? setDataAtBreakpoint(path, value)
        : actions.setProp(props => set(props, path, value))
    },
    [actions, responsive, setDataAtBreakpoint]
  )

  return {
    data,
    actions,
    dataValue,
    inheritFrom: valueAtBreakpoint?.inherit
      ? valueAtBreakpoint?.breakpoint
      : null,
    inheritValue: valueAtBreakpoint?.inherit ? valueAtBreakpoint?.value : null,
    onDataChange,
    setData,
    value: displayedValue,
    valueAtBreakpoint,
  }
}
