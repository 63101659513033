import PropTypes from 'prop-types'
import React, { lazy, useCallback, useMemo, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useCategoriesForTags from '@modules/categories/helpers/useCategoriesForTags'
import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import SortFields from '@modules/web/components/ContentEditor/blocks/shared/SortFields'
import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import ColumnsSelect from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import FlexDirectionSelect from '@modules/web/components/ContentEditor/shared/FlexDirectionSelect'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

import ArticlesPreview from './shared/ArticlesPreview'
import CategoriesFields from './shared/CategoriesFields'
import FieldsToggleFields from './shared/FieldsToggleFields'
import FlagsFields from './shared/FlagsFields'
import LimitFields from './shared/LimitFields'

const Button = lazy(() => import('@ui/buttons/Button'))

const teaserDisplayModes = [
  'list',
  'cards',
  'cards-with-list',
  'featured',
  'featured-with-list',
  'posters',
]

const config = {
  name: 'ArticleTeaser',
  label: 'articles/public:articleTeaser',
  type: 'plugin',
  icon: 'bars',
  component: <ArticleTeaser />,
}

export default function ArticleTeaser({
  categories,
  columns,
  direction,
  displayMode,
  featuredItemsCount,
  flag,
  limit,
  listPageId,
  organizations,
  showCategoryFilter,
  showDate,
  showDescription,
  showImage,
  sortField,
  sortOrder,
  skip,
  title,
}) {
  const { t } = useTranslation('articles/public')
  const { categories: categoryItems } = useCategoriesForTags({
    ids: categories,
  })

  const [categoryFilter, setCategoryFilter] = useState(null)

  const showFilters = categories?.length > 1 && showCategoryFilter

  return (
    <Content icon={config.icon} title={t('articleTeaser')}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-center justify-between gap-6 px-1">
          {title && (
            <h3 className="p-1 text-2xl font-bold uppercase">
              {listPageId ? (
                <a className="text-primary-500" href="#readmore">
                  {title}
                </a>
              ) : (
                title
              )}
            </h3>
          )}
          <div className="flex flex-row items-center justify-between gap-4">
            {showFilters ? (
              <div className="flex flex-row gap-3">
                <Button
                  onClick={() => setCategoryFilter(null)}
                  label={t('all')}
                  variant={categoryFilter ? 'flat' : 'primary'}
                  size="sm"
                />
                {categoryItems?.map(categoryItem => (
                  <Button
                    key={categoryItem.id}
                    label={categoryItem.title}
                    onClick={() => setCategoryFilter(categoryItem.id)}
                    variant={
                      categoryItem.id === categoryFilter ? 'primary' : 'flat'
                    }
                    size="sm"
                  />
                ))}
              </div>
            ) : (
              listPageId && (
                <a
                  className="text-sm uppercase text-primary-500"
                  href="#readmore"
                >
                  {t('readMore')}
                </a>
              )
            )}
          </div>
        </div>

        <ArticlesPreview
          categories={categoryFilter ? [categoryFilter] : categories}
          displayMode={displayMode}
          direction={direction}
          featuredItemsCount={featuredItemsCount}
          limit={limit}
          skip={skip}
          flag={flag}
          organizations={organizations}
          showDate={showDate}
          showDescription={showDescription}
          showImage={showImage}
          columns={columns}
          sortField={sortField}
          sortOrder={sortOrder}
        />
      </div>
    </Content>
  )
}
ArticleTeaser.propTypes = {
  categories: PropTypes.array,
  columns: PropTypes.object,
  direction: PropTypes.object,
  displayMode: PropTypes.string,
  featuredItemsCount: PropTypes.number,
  flag: PropTypes.string,
  limit: PropTypes.number,
  skip: PropTypes.number,
  listPageId: PropTypes.string,
  organizations: PropTypes.arrayOf(PropTypes.string),
  showCategoryFilter: PropTypes.bool,
  showDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
  title: PropTypes.string,
}
ArticleTeaser.toolbarItem = config

function ArticleTeaserSettings() {
  const { t } = useTranslation('articles/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('articleTeaser')} help={t('articleTeaserHelp')}>
      <div className="flex flex-col gap-4">
        <CollapsablePanel title={t('sortingAndCategories')} defaultOpen>
          <SortFields />
          <LimitFields />
          <CategoriesFields />
          <FlagsFields />
        </CollapsablePanel>

        <CollapsablePanel title={t('displayOptions')}>
          <Input
            label={t('title')}
            help={t('titleHelp')}
            onChange={onFieldUpdate('title')}
            value={data.title}
          />

          <Select
            label={t('displayMode')}
            help={t('displayModeHelp')}
            value={data.displayMode}
            onChange={e => {
              const value = e.target.value

              // clear direction and featuredItemsCount for modes that not use them
              actions.setProp(props => {
                if (['cards-with-list', 'featured-with-list'].includes(value)) {
                  props.featuredItemsCount = 1
                  props.columns = { xs: 1 }
                } else {
                  props.direction = null
                  props.featuredItemsCount = null
                }
              })

              actions.setProp(props => (props.displayMode = value))
            }}
          >
            {teaserDisplayModes.map(displayMode => (
              <SelectOption
                value={displayMode}
                label={t(`displayMode_${displayMode}`)}
                key={`displayMode-${displayMode}`}
              />
            ))}
          </Select>

          <Checkbox
            label={t('showCategoryFilter')}
            onChange={value =>
              actions.setProp(props => (props.showCategoryFilter = value))
            }
            value={data.showCategoryFilter}
          />
          {['cards-with-list', 'featured-with-list'].includes(
            data.displayMode
          ) && (
            <>
              <FlexDirectionSelect />
              <Input
                label={t('featuredItemsCount')}
                help={t('featuredItemsCountHelp')}
                type="number"
                onChange={onFieldUpdate('featuredItemsCount', value =>
                  value ? parseInt(isNaN(value) || value < 1 ? 1 : value) : 1
                )}
                value={data.featuredItemsCount}
              />
            </>
          )}

          {data.displayMode !== 'list' && <ColumnsSelect />}

          <FieldsToggleFields showDescription showImage showDate />
        </CollapsablePanel>

        <CollapsablePanel title={t('linking')}>
          <LinkingFields
            detailPagehelp={t('detailPageIdHelp')}
            resourceIdentifier="Article"
          />
        </CollapsablePanel>
      </div>
    </SettingsWrap>
  )
}

ArticleTeaser.craft = {
  displayName: 'ArticleTeaser',
  props: {
    displayMode: 'cards', // one in teaserDisplayModes
    direction: { xs: 'y' },
    flag: null,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    limit: 6,
    featuredItemsCount: 1,
    showDate: true,
    showDescription: true,
    showImage: true,
  },
  custom: {
    type: config.type,
    resources: ['articlesTeaser'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleTeaserSettings,
  },
}
/**
 * Hook to get the fields of the Article Teaser block
 * @returns
 */
function useTeaserBlockFields() {
  const { t } = useTranslation(['articles/public'])

  return useMemo(
    () => ({
      title: { label: t('title') },
    }),
    [t]
  )
}

/**
 * Component to render the fields of a Article Teaser block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function TeaserTranslation({ id, source }) {
  const fields = useTeaserBlockFields()
  return <BlockTranslation id={id} fields={fields} source={source} />
}
TeaserTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
