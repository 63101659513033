import PropTypes from 'prop-types'
import React from 'react'

export function ListboxDivider({ className }) {
  return (
    <li className={`py-1 ${className}`}>
      <hr className="border-gray-200 dark:border-gray-700" />
    </li>
  )
}
ListboxDivider.propTypes = {
  className: PropTypes.string,
}
