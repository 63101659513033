import { useMemo } from 'react'

import useEntityTypes from '../../../services/hooks/useEntityTypes'

/**
 * Returns an object with entity types as keys and icons as values
 * @returns {Object} - Object with entity types as keys and icons as values
 */
export default function useEntityIcons() {
  const { types, loading } = useEntityTypes()

  return useMemo(() => {
    if (loading) return {}

    return types.reduce((icons, entityType) => {
      icons[entityType.value] = entityType.icon
      return icons
    }, {})
  }, [types, loading])
}
