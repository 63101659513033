import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Details from '@ui/data-display/Details'
import Box from '@ui/layout/Box'

export function TaskACMSImportDetails({ task }) {
  const { t } = useTranslation(['entities/public', 'tasks'])

  return (
    <Box>
      <Details.Item
        label={t('tasks:importAll')}
        value={<p>{task.settings?.importAll ? t('yes') : t('no')}</p>}
      />
      <Details.Item
        label={t('acmsImportTaskTargetEntity')}
        value={<p>{task.meta?.context?.entity?.name ?? t('notSet')}</p>}
      />
    </Box>
  )
}

TaskACMSImportDetails.propTypes = {
  task: PropTypes.object,
}
