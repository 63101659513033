import PropTypes from 'prop-types'
import React from 'react'

import Tag from '@ui/data-display/Tag'

export default function LocationTag({
  hideIcon,
  label,
  location,
  onClear,
  size = 'md',
  variant = 'primary',
}) {
  if (!location && !label) return null

  // Extract the location data (if available)
  const { placeName, name, coordinates } = location || {}
  const [lat, lon] = coordinates || []

  return (
    <Tag
      label={label || placeName || name || `${lat}, ${lon}`} // Fallback: label prop => placeName => name => coordinates
      icon={hideIcon ? undefined : 'map-marker-alt'}
      variant={variant}
      onDelete={onClear}
      size={size}
    />
  )
}
LocationTag.propTypes = {
  hideIcon: PropTypes.bool,
  label: PropTypes.node,
  location: PropTypes.shape({
    placeName: PropTypes.string,
    name: PropTypes.string,
    coordinates: PropTypes.arrayOf(PropTypes.number),
  }),
  onClear: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf([
    'basic',
    'light',
    'primary',
    'primary-light',
    'info',
    'info-light',
    'success',
    'success-light',
    'warn',
    'warn-light',
    'danger',
    'danger-light',
  ]),
}
