import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import useSite from '@modules/web/services/hooks/useSite'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { TagsField as Tags } from '@ui/data-entry/Tags'
import Divider from '@ui/layout/Divider'
import LinkList from '@ui/navigation/LinkList'

const config = {
  name: 'ArticleFlagsList',
  label: 'articles/public:articleFlagsList',
  type: 'plugin',
  icon: 'tags',
  component: <ArticleFlagsList />,
}

export default function ArticleFlagsList({ flagExceptions = [] }) {
  const { t } = useTranslation('articles/public')

  const { site } = useSite()

  const displayedFlags = useMemo(
    () =>
      site.flags
        ?.filter(flag => !flagExceptions.includes(flag.id))
        ?.map(flag => ({ label: flag.name })),
    [site.flags, flagExceptions]
  )

  return (
    <Content icon={config.icon} title={t('articleFlagsList')}>
      <LinkList items={displayedFlags} listType="list" />
    </Content>
  )
}
ArticleFlagsList.propTypes = {
  flagExceptions: PropTypes.arrayOf(PropTypes.string),
}

ArticleFlagsList.toolbarItem = config

function ArticleFlagsListSettings() {
  const { t } = useTranslation('articles/public')
  const { site } = useSite()

  const { showListIcon } = useBlock(config.name)

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onAddFlagException = useCallback(
    flagId =>
      actions.setProp(
        data => (data.flagExceptions = [...(data.flagExceptions || []), flagId])
      ),
    [actions]
  )

  const onRemoveFlagException = useCallback(
    flagId => {
      actions.setProp(
        data =>
          (data.flagExceptions = (data.flagExceptions || []).filter(
            id => id !== flagId
          ))
      )
    },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('articleFlagsList')}
      help={t('articleFlagsListHelp')}
    >
      <div className="flex flex-col gap-4">
        <Tags
          name="flagExceptions"
          label={t('flagExceptions')}
          help={t('flagExceptionsHelp')}
          suggestions={site.flags
            .filter(flag => flag.id !== data.flag)
            .map(i => i.id)}
          getItemLabel={id => site.flags.find(i => i.id === id)?.name}
          items={data.flagExceptions || []}
          onAddItem={id => onAddFlagException(id)}
          onRemoveItem={id => onRemoveFlagException(id)}
          asText
        />
        <PagePicker
          label={t('flagPageId')}
          help={t('flagPageIdHelp')}
          value={data.flagPageId}
          onChange={pageId =>
            actions.setProp(props => (props.flagPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'flag'
          }
        />

        {showListIcon && (
          <>
            <Divider />
            <Checkbox
              name="showListIcon"
              label={t('showListIcon')}
              help={t('showListIconHelp')}
              onChange={value =>
                actions.setProp(props => (props.showListIcon = value))
              }
              value={data.showListIcon}
            />
          </>
        )}
      </div>
    </SettingsWrap>
  )
}

ArticleFlagsList.craft = {
  displayName: 'ArticleFlagsList',
  props: {
    flagExceptions: [],
    flagPageId: null,
  },
  custom: {
    type: config.type,
    resources: ['articleFlags'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleFlagsListSettings,
  },
}
