import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'

import useCourse from '../../services/hooks/useCourse'
import useCourseContent from '../../services/hooks/useCourseContent'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CourseNavigation',
  label: 'courses/public:courseNavigation',
  help: 'courses/public:courseNavigationHelp',
  type: 'plugin',
  icon: 'list-ol',
  component: <CourseNavigation />,
}

function getPlaceholder() {
  return {
    lessons: [
      { title: '[ Lesson 1 ]' },
      { title: '[ Lesson 2 ]' },
      { title: '[ Lesson 3 ]' },
      { title: '[ Lesson 4 ]' },
      { title: '[ Lesson 5 ]' },
      { title: '[ Lesson 6 ]' },
      { title: '[ Lesson 7 ]' },
      { title: '...' },
    ],
  }
}

export default function CourseNavigation({ dynamic, courseId, displayMode }) {
  const { resources } = usePageResources()
  const { course } = useCourse(dynamic ? null : courseId)

  const _course = resources?.Course ? resources.Course : course

  const { lessons, loading } = useCourseContent({
    courseId: dynamic ? null : _course ? _course?.id : null,
  })

  let _lessons = loading ? [] : lessons || getPlaceholder().lessons
  const lessonsCount = _lessons.length
  if (lessonsCount > 10) {
    _lessons = _lessons.slice(0, 10)
    _lessons.push({ title: '...' })
  }

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="w-full">
        {displayMode === 'floating' && (
          <div className="flex justify-end">
            <div className="flex justify-center items-center w-8 h-8 rounded-full bg-primary-200">
              <Icon name="list" />
            </div>
          </div>
        )}
        {displayMode === 'fixed' && (
          <div>
            {_lessons?.map((lesson, key) => {
              return (
                <div
                  className="flex items-center justify-between px-2 py-1 hover:bg-gray-100"
                  key={key}
                >
                  <div className="text-sm">{lesson.title}</div>
                  <div className="text-xs text-gray-500">
                    <Icon name="chevron-down" />
                  </div>
                </div>
              )
            })}
          </div>
        )}
      </div>
    </Content>
  )
}
CourseNavigation.propTypes = {
  dynamic: PropTypes.bool,
  providerId: PropTypes.string,
  courseId: PropTypes.string,
  displayMode: PropTypes.oneOf(['floating', 'fixed']),
}
CourseNavigation.toolbarItem = config

function CourseNavigationSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course ? (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        ) : (
          <>
            <Select
              label={t('displayMode')}
              help={t('displayModeHelp')}
              value={data.displayMode}
              onChange={e =>
                actions.setProp(props => (props.displayMode = e.target.value))
              }
            >
              <SelectOption
                value="floating"
                label={t('displayMode-floating')}
              />
              <SelectOption value="fixed" label={t('displayMode-fixed')} />
            </Select>
            <PagePicker
              label={t('lessonDetailPageId')}
              help={t('lessonDetailPageIdHelp')}
              value={data.lessonDetailPageId}
              onChange={pageId =>
                actions.setProp(props => (props.lessonDetailPageId = pageId))
              }
              condition={page =>
                page.dynamic &&
                getDynamicResourceIdentifier(page) === 'CourseLesson'
              }
            />
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {resources?.CourseProvider ? (
                  <Message className="text-sm" type="warn">
                    {resources.CourseProvider.title}
                  </Message>
                ) : (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseNavigation.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
    lessonDetailPageId: undefined,
    displayMode: 'floating',
  },
  custom: {
    type: config.type,
    resources: ['courseNavigation'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseNavigationSettings,
  },
}
