import axios from 'axios'

/**
 * Returns a Document's url (directed to the API where it can redirect to the file)
 *
 * @param {object} file
 * @param {object} entityId
 */
export function getDocumentUrl(file) {
  if (typeof file !== 'object') return ''

  const { containerId, name } = file

  return `/api/documents/download/${containerId}/${name}` // custom name is now set on upload. Should a different name be needed, it should be set on upload.
}

/**
 * Returns a Document's direct url (for example for Publications)
 *
 * @param {object} file
 */
export function getDirectDocumentUrl(file) {
  if (typeof file !== 'object') return ''

  const { containerId, name } = file || {}

  if (!containerId || !name) return '' // If the file doesn't have a containerId or name, return an empty string
  return `${import.meta.env.VITE_ZAFIR_DOCUMENTS_ORIGIN}/${containerId}/${name}`
}

/**
 * Returns a Document's direct url (for example for Publications)
 *
 * @param {object} file
 */
export function getFontCDNUrl(file) {
  if (typeof file !== 'object') return ''

  const { containerId, name } = file || {}

  if (!containerId || !name) return '' // If the file doesn't have a containerId or name, return an empty string
  return `${import.meta.env.VITE_FONTS_CDN}/${containerId}/${name}`
}

export function getFileExtension(filename) {
  // Split the filename by dot (.)
  const parts = filename.split('.')

  // Return the last part of the split array as the extension
  return parts[parts.length - 1]
}

/**
 * Returns a Document's pre-signed url (for example for a Publication's designFile)
 * A pre-signed url is a url that is valid for a limited time and can be used to download a file
 * @param {*} file
 * @returns
 */
export async function getPreSignedUrl(file) {
  if (typeof file !== 'object') return null

  const { containerId, name } = file

  // If the file doesn't have a containerId or name, return null
  if (!containerId || !name) return null

  // Get the pre-signed url from the API
  const response = await axios.get(
    `/api/documents/pre-signed-url/${containerId}/${name}`
  )

  return response.data.url || null
}

export async function generateDownloadUrl(file, downloadType) {
  if (downloadType === 'direct') {
    return getDirectDocumentUrl(file)
  }

  if (downloadType === 'signed') {
    return await getPreSignedUrl(file)
  }

  return getDocumentUrl(file)
}
