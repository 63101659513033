import PropTypes from 'prop-types'
import React from 'react'

import { TranslationSourceContext, TranslationTargetContext } from './contexts'

export default function TranslationContext({
  sourceLang,
  targetLang,
  children,
}) {
  return (
    <TranslationSourceContext.Provider value={sourceLang}>
      <TranslationTargetContext.Provider value={targetLang}>
        {children}
      </TranslationTargetContext.Provider>
    </TranslationSourceContext.Provider>
  )
}
TranslationContext.propTypes = {
  children: PropTypes.node.isRequired,
  sourceLang: PropTypes.string,
  targetLang: PropTypes.string,
}
