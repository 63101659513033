import React from 'react'

import { Select, SelectOption } from '@ui/data-entry/Select'

export function AmPmSelect(props) {
  return (
    <Select
      {...props}
      className="w-20 rounded border-gray-300 text-center focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200"
    >
      <SelectOption value="am" label="AM" />
      <SelectOption value="pm" label="PM" />
    </Select>
  )
}
