import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import UserAvatar from '@modules/core/components/UserAvatar'
import Button from '@ui/buttons/Button'
import { ErrorMessage } from '@ui/data-display/Message'
import Section from '@ui/data-display/Section'
import { ImageUpload } from '@ui/data-entry/ImageUpload'
import useToggle from '@ui/helpers/useToggle'
import Box from '@ui/layout/Box'

import { useAvatarUpload } from '../services/AuthService'

export default function AvatarUpload({ user }) {
  const { t } = useTranslation(['auth', 'users'])
  const [showUpload, toggleShowUpload] = useToggle()
  const { onAvatarUpload, isError, isLoading, error, reset } = useAvatarUpload()

  const onCancel = () => {
    toggleShowUpload()
    reset()
  }

  return (
    <Section.Body>
      {showUpload ? (
        <div className="flex flex-col gap-4">
          {isError && <ErrorMessage error={error} />}

          <ImageUpload
            accept={['jpg', 'png']}
            maxSize="5mb"
            onChange={toggleShowUpload}
            onUpload={onAvatarUpload}
          />

          <Button
            label={t(isLoading ? 'uploading' : 'cancel')}
            disabled={isLoading}
            onClick={isLoading ? null : onCancel}
          />
        </div>
      ) : (
        <Box horizontal justify="start" align="center" space="lg">
          <UserAvatar user={user} size="xl" />
          <Button label={t('changeAvatar')} onClick={toggleShowUpload} />
        </Box>
      )}
    </Section.Body>
  )
}
AvatarUpload.propTypes = {
  user: PropTypes.object.isRequired,
}
