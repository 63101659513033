import PropTypes from 'prop-types'
import React from 'react'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

export function ModelPermissionsWrap({
  children,
  className = '',
  help,
  innerClass = '',
  label,
  model,
}) {
  //  If a model is specified we check if the user is authorized to read it
  const isAuthorized = useIsUserAuthorized({
    module: model,
    permission: 'read',
  })

  // if is not authorized, we return null to hide the component
  if (model && !isAuthorized) {
    return null
  }

  return (
    <div className={`flex flex-col gap-3 ${className}`}>
      <div className="border-b pb-2 empty:hidden">
        {label && <h3 className="text-lg font-semibold">{label}</h3>}
        {help && <p className="text-sm text-gray-400">{help}</p>}
      </div>
      <div className={`flex flex-col gap-3 p-2 ${innerClass}`}>{children}</div>
    </div>
  )
}
ModelPermissionsWrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  help: PropTypes.node,
  innerClass: PropTypes.string,
  label: PropTypes.node,
  model: PropTypes.string,
}
