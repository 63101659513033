import React from 'react'
import PropTypes from 'prop-types'

import Link from '@ui/navigation/Link'
import Image from '@ui/data-display/Image'
import FormatDate from '@ui/helpers/FormatDate'

export default function EventListItem({ event }) {
  const { startsAt } = event

  return (
    <div className="flex space-x-4">
      {event.image && (
        <div className="shrink-0">
          <Image
            file={event.image?.file}
            width={192}
            className="aspect-1 w-24 rounded object-cover"
            alt={event.title}
          />
        </div>
      )}
      <div className="flex-grow space-y-0">
        <div>
          <p className="text-xs font-semibold text-gray-400">
            <FormatDate date={startsAt || event.createdAt} format="PP" />
          </p>
          <h3 className="text-lg font-bold">
            <Link href={`/events/${event.id}`}>{event.title}</Link>
          </h3>
        </div>
      </div>
    </div>
  )
}
EventListItem.propTypes = {
  event: PropTypes.object.isRequired,
  showDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  site: PropTypes.object,
}
