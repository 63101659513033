import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Image from '@ui/data-display/Image'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'

import useCourse from '../../services/hooks/useCourse'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const config = {
  name: 'CourseImage',
  label: 'courses/public:courseImage',
  help: 'courses/public:courseImageHelp',
  type: 'plugin',
  icon: 'image',
  component: <CourseImage />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    images: {
      default: '[ IMAGE ]',
    },
  }
}

export default function CourseImage({ dynamic, courseId }) {
  const { resources } = usePageResources()
  const { course } = useCourse(dynamic ? null : courseId)

  const _course = resources?.Course
    ? resources.Course
    : course || getPlaceholder()

  const { images, title } = _course || {}

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="flex flex-col p-2">
        {images?.default && (
          <>
            {typeof images.default === 'object' ? (
              <Image
                className="w-full"
                file={images.default}
                alt={title}
                width={600}
              />
            ) : typeof images.default === 'string' ? (
              images.default
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </Content>
  )
}
CourseImage.propTypes = {
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  providerId: PropTypes.string,
}
CourseImage.toolbarItem = config

function CourseImageSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        {!resources?.Course && (
          <>
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {resources?.CourseProvider ? (
                  <Message className="text-sm" type="warn">
                    {resources.CourseProvider.title}
                  </Message>
                ) : (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseImage.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['courseImage'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseImageSettings,
  },
}
