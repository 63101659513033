import { useQuery } from 'react-query'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

export default function useChannels({
  search = '',
  limit = 10,
  page = 1,
  filters = {},
  statuses = ['active', 'disabled'],
  fetchOnSearch = false,
  sort = 'title',
  sortOrder = 'asc',
  filterByEntity = true,
} = {}) {
  const queryFilter = {}
  const orFilters = []

  // This ensures the channels are filtered by the current entity, even on entity switch
  const { entity } = useCurrentEntity()

  // search
  if (search) {
    queryFilter.title = {
      $regex: escapeSpecialChars(search),
      $options: 'i',
    }
  }

  // status
  if (!isEmpty(statuses)) {
    const statusFilter = []
    if (statuses.includes('active')) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      statusFilter.push({ deleted: true })
    }
    orFilters.push(statusFilter)
  } else {
    queryFilter.deleted = false
  }

  // entities
  if (!isEmpty(filters['entities'])) {
    const entitiesFilter = Object.keys(filters['entities']).map(entityId => ({
      entity: entityId,
    }))
    orFilters.push(entitiesFilter)
  }

  // ids
  if (!isEmpty(filters['ids'])) {
    queryFilter._id = { $in: filters['ids'] }
  }

  // ignore ids
  if (!isEmpty(filters['ignore-ids'])) {
    queryFilter._id = { $nin: filters['ignore-ids'] }
  }

  // enable multiple 'or' filters
  if (orFilters.length > 0) {
    queryFilter['$and'] = orFilters.map(filter => ({ $or: filter }))
  }

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'channels',
      {
        entityId: entity?._id,
        search,
        limit,
        page,
        filters,
        sort,
        statuses,
        sortOrder,
        filterByEntity,
      },
    ],
    () =>
      getFetch('/api/media-library/channels', {
        filter: queryFilter,
        filterByEntity,
        sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
        limit: limit,
        page: page,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    channels: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
