import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useShow from '../../services/hooks/useShow'
import ChannelSelect from '../ChannelSelect'
import { ShowPicker } from '../ShowPicker'

const config = {
  name: 'RelatedShows',
  label: 'media-library/public:relatedShows',
  type: 'plugin',
  icon: 'arrows-h',
  component: <RelatedShows />,
}

function getPlaceholder() {
  return {
    relatedShows: [],
  }
}

export default function RelatedShows({ channelId, id, title }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { show } = useShow(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _show = resources?.Show ? resources.Show : show || getPlaceholder()

  const { relatedShows = [] } = _show

  return (
    <Content icon={config.icon} title={t('relatedShows')}>
      {showContent ? (
        <div className="flex flex-col p-4">
          {relatedShows.map(show => (
            <div key={show.id}>{show.title}</div>
          ))}
        </div>
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={title || t('relatedShows')} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('show')}: {_show?.title || t('dynamic')}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
RelatedShows.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
  title: PropTypes.string,
}
RelatedShows.defaultProps = {}
RelatedShows.toolbarItem = config

function RelatedShowsSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onShowIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('relatedShows')} help={t('relatedShowsHelp')}>
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          placeholder={t('relatedShows')}
          value={data.title}
        />
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.limit}
          type="number"
        />
        <Divider />
        <PagePicker
          label={t('showDetailPageId')}
          help={t('showDetailPageIdHelp')}
          value={data.showDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.showDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
          }
        />
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickShowManually')}
              help={t('pickShowManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <ShowPicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onShowIdChange}
                  value={data.id}
                />
              </Box>
            )}
          </>
        )}
        <Checkbox
          name="showDescription"
          label={t('showDescriptionInCards')}
          help={t('showDescriptionOfShowsInCardsHelp')}
          value={data.showDescription}
          onChange={checked =>
            actions.setProp(p => (p.showDescription = checked))
          }
        />
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
      </Box>
    </SettingsWrap>
  )
}

RelatedShows.craft = {
  displayName: 'RelatedShows',
  props: {
    channelId: undefined,
    id: undefined,
    title: '',
    limit: 4,
    showDetailPageId: undefined,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['relatedShows'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: RelatedShowsSettings,
  },
}
