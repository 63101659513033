import React from 'react'

import config from './config'

const LoginFormBlock = React.lazy(() => import('./Block'))
const LoginFormSettings = React.lazy(() => import('./Settings'))

export default function LoginForm(props) {
  return <LoginFormBlock {...props} />
}

LoginForm.toolbarItem = {
  ...config,
  component: <LoginForm />,
}

LoginForm.craft = {
  displayName: 'LoginForm',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: LoginFormSettings,
  },
}
