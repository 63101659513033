import React from 'react'

import PublicationsListBlock from './Block'
import PublicationsListSettings from './Settings'
import config from './config'

export default function PublicationsList(props) {
  return <PublicationsListBlock {...props} />
}

PublicationsList.toolbarItem = {
  ...config,
  component: <PublicationsList />,
}

PublicationsList.craft = {
  displayName: 'PublicationsList',
  props: {
    columns: { xs: 1, md: 3 },
    sortField: 'title',
    sortOrder: 'asc',
    variant: 'list',
    displayMode: 'cards', // 'cards' or 'posters'
    label: '',
    showDescription: true,
    showImage: true,
    showLink: false,
    limit: 10,
    skip: 0,
  },
  custom: {
    type: config.type,
    resources: ['publications'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationsListSettings,
  },
}
