import PropTypes from 'prop-types'
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import useSelectedText from '@ui/helpers/useSelectedText'
import { isFunction } from '@utils/types'

import Field from './Field'
import ImproveText from './shared/ai/components/ImproveText'
import { useRules } from './validationHooks'

/**
 * Calulates a string lenght (in chars or words)
 *
 * @param {string} value String to calculate the lenght
 * @param {boolean} countWordsif When true is going to count words instead of charachers
 * @returns
 */
function getStringLength(value, countWords = false) {
  if (value)
    return (
      countWords
        ? value.trim().replace(/\s\s+/g, ' ').split(' ').filter(Boolean)
        : value
    ).length
}

/**
 * TextArea input (standalone version)
 */
export const TextArea = React.forwardRef(
  (
    {
      autosize = true,
      disabled,
      className = '',
      countChars,
      countWords,
      fullWidth = true,
      isRTL,
      lang,
      onChange,
      value = '',
      rows,
      ...props
    },
    ref
  ) => {
    const innerRef = useRef(null)

    const { t } = useTranslation()

    const showCounter = countChars || countWords
    const [counter, setCounter] = useState(value?.length || 0)

    // It will use ref when available, otherwise it will use innerRef
    React.useImperativeHandle(ref, () => innerRef.current)

    const fullWidthClass = fullWidth ? 'w-full' : ''

    // Calculate initial value to show in counter (if enabled)
    useEffect(() => {
      if (showCounter) {
        setCounter(getStringLength(value, countWords))
      }
    }, [countWords, showCounter, value])

    // Sets field height automatically
    useLayoutEffect(() => {
      if (!autosize) return

      innerRef.current.style.display = 'block'
      innerRef.current.style.minHeight = 0
      const height = innerRef.current.scrollHeight

      innerRef.current.style.minHeight = height > 0 ? height + 'px' : 'auto'
    }, [autosize, value, innerRef])

    return (
      <div className={`relative flex flex-col gap-1 ${fullWidthClass}`}>
        <textarea
          className={`form-textarea flex flex-row items-stretch justify-between gap-2 rounded border-gray-300 placeholder-gray-300 focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200 ${className}`}
          {...props}
          disabled={disabled}
          ref={innerRef}
          rows={rows}
          onChange={onChange}
          value={value ?? ''}
          dir={isRTL ? 'rtl' : 'ltr'}
          lang={lang}
        />
        {showCounter && counter > 0 && (
          <div className="text-xs font-semibold text-gray-600">
            {countWords
              ? t('wordCount', { count: counter })
              : t('charCount', { count: counter })}
          </div>
        )}
      </div>
    )
  }
)
TextArea.propTypes = {
  autosize: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  countChars: PropTypes.bool,
  countWords: PropTypes.bool,
  fullWidth: PropTypes.bool,
  isRTL: PropTypes.bool,
  lang: PropTypes.string,
  onChange: PropTypes.func,
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  value: PropTypes.string,
}
/**
 * TextArea field
 */
export function TextAreaField({
  autosize,
  aiImproveText,
  disabled,
  className,
  countChars,
  countWords,
  error,
  fieldExtra,
  fullWidth,
  help,
  inputClass,
  isRTL,
  label,
  labelExtra,
  lang,
  missing,
  name,
  onBlur,
  onChange,
  placeholder,
  readOnly,
  required,
  rows,
  value,
}) {
  const fieldRef = useRef(null)
  const { selectedText, clearSelectedText } = useSelectedText(fieldRef)

  // Update improved text
  const onImprovedText = useCallback(
    text => {
      if (!isFunction(onChange)) return

      const newText = selectedText ? value.replace(selectedText, text) : text

      onChange(newText)
      clearSelectedText()
    },
    [value, onChange, selectedText, clearSelectedText]
  )

  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={error}
      fieldExtra={fieldExtra}
      help={help}
      labelExtra={
        aiImproveText ? (
          <ImproveText
            text={value}
            visible={!!value}
            onSuccess={onImprovedText}
            selectedText={selectedText}
            type="text"
            size="xs"
          />
        ) : (
          labelExtra
        )
      }
      missing={missing}
      required={required}
      disabled={disabled}
    >
      <TextArea
        disabled={disabled}
        name={name}
        id={name}
        className={inputClass}
        autosize={autosize}
        countChars={countChars}
        countWords={countWords}
        fullWidth={fullWidth}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        readOnly={readOnly}
        rows={rows}
        isRTL={isRTL}
        lang={lang}
        ref={fieldRef}
      />
    </Field>
  )
}
TextAreaField.propTypes = {
  aiImproveText: PropTypes.bool,
  autosize: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  countChars: PropTypes.bool,
  countWords: PropTypes.bool,
  error: PropTypes.object,
  fieldExtra: PropTypes.node,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  inputClass: PropTypes.string,
  isRTL: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lang: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  missing: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

/**
 * TextArea controller
 */
export default function TextAreaController({
  aiImproveText,
  autosize,
  disabled,
  className,
  countChars,
  countWords,
  fieldExtra,
  fullWidth,
  help,
  inputClass,
  isRTL,
  label,
  labelExtra,
  lang,
  maxLength,
  minLength,
  missing,
  name,
  onChange,
  placeholder,
  readOnly,
  required,
  rows,
  shouldUnregister,
  validate,
  value = '',
}) {
  const { control } = useFormContext()

  const rules = useRules({ maxLength, minLength, required, validate })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <TextAreaField
          aiImproveText={aiImproveText}
          className={className}
          inputClass={inputClass}
          name={name}
          label={label}
          labelExtra={labelExtra}
          fieldExtra={fieldExtra}
          error={fieldState.error}
          help={help}
          missing={missing}
          required={rules?.required?.value}
          autosize={autosize}
          disabled={disabled}
          countChars={countChars}
          countWords={countWords}
          fullWidth={fullWidth}
          value={field.value}
          onChange={e => {
            field.onChange(e)
            if (isFunction(onChange)) {
              onChange(field.value)
            }
          }}
          onBlur={field.onBlur}
          placeholder={placeholder}
          rows={rows}
          readOnly={readOnly}
          isRTL={isRTL}
          lang={lang}
        />
      )}
    />
  )
}

TextAreaController.propTypes = {
  aiImproveText: PropTypes.bool,
  autosize: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  countChars: PropTypes.bool,
  countWords: PropTypes.bool,
  fieldExtra: PropTypes.node,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  inputClass: PropTypes.string,
  isRTL: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lang: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  missing: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shouldUnregister: PropTypes.array,
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
TextAreaController.displayName = 'TextArea'
