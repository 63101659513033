import Autocomplete from './Autocomplete'
import AutocompleteController from './Controller'
import AutocompleteField from './Field'
import AutocompleteOption from './Option'
import AutocompletePlaceholder from './Placeholder'
import AutocompleteSelectedItem from './SelectedItem'

AutocompleteController.displayName = 'Autocomplete'

AutocompleteController.Option = AutocompleteOption
AutocompleteController.Option.displayName = 'Autocomplete.Option'
AutocompleteController.Placeholder = AutocompletePlaceholder
AutocompleteController.Placeholder.displayName = 'Autocomplete.Placeholder'
AutocompleteController.SelectedItem = AutocompleteSelectedItem
AutocompleteController.SelectedItem.displayName = 'Autocomplete.SelectedItem'

AutocompleteField.Option = AutocompleteOption
AutocompleteField.Option.displayName = 'Autocomplete.Option'
AutocompleteField.Placeholder = AutocompletePlaceholder
AutocompleteField.Placeholder.displayName = 'Autocomplete.Placeholder'
AutocompleteField.SelectedItem = AutocompleteSelectedItem
AutocompleteField.SelectedItem.displayName = 'Autocomplete.SelectedItem'

export {
  Autocomplete,
  AutocompleteField,
  AutocompleteOption,
  AutocompletePlaceholder,
  AutocompleteSelectedItem,
}

export default AutocompleteController
