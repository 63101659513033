import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import { ErrorMessage } from '@ui/data-display/Message'
import Section from '@ui/data-display/Section'
import Submit from '@ui/data-entry/Submit'

import { useUpdateCurrentUser } from '../services/AuthService'

export default function UserForm({ user }) {
  const { t } = useTranslation('users')
  const { mutate, isError, isLoading, error } = useUpdateCurrentUser()

  return (
    <Form data={user} onSubmit={mutate}>
      <Section.Body>
        {isError && <ErrorMessage error={error} />}

        <Input label={t('name')} name="name" maxLength={80} required />
        <Input.Email label={t('email')} name="email" required />
      </Section.Body>
      <Section.Footer>
        <Submit
          label={t(isLoading ? 'saving' : 'update')}
          submitting={isLoading}
          reverse
        />
      </Section.Footer>
    </Form>
  )
}
UserForm.propTypes = {
  user: PropTypes.object.isRequired,
}
