import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import ClearButton from '@ui/buttons/ClearButton'
import Clickable from '@ui/helpers/Clickable'
import useClickOutside from '@ui/helpers/useClickOutside'
import { isFunction } from '@utils/types'

import Field from './Field'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function SearchDropdown({
  children,
  className,
  disabled,
  fullWidth,
  label,
  error,
  help,
  name,
  onChange,
  onSearch,
  placeholder,
  required,
  selectedLabel,
  value,
}) {
  const disabledClass = disabled ? 'opacity-50' : ''
  const fullWidthClass = fullWidth ? 'w-full' : ''
  const [text, setText] = useState('')
  const { open, setOpen, nodeRef } = useClickOutside()

  const onToggleResults = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  const onInputSearch = useCallback(
    event => {
      const value = event.target.value
      setText(value)

      if (typeof onSearch === 'function') {
        onSearch(value)
      }
    },
    [onSearch]
  )

  const onItemSelect = useCallback(
    value => {
      onChange(value)
      setOpen(false)
    },
    [onChange, setOpen]
  )

  const onCancelSelect = useCallback(() => {
    onChange(null)
  }, [onChange])

  return (
    <Field
      name={name}
      label={label}
      error={error}
      help={help}
      required={required}
    >
      <div
        className={`relative flex flex-col ${fullWidthClass} ${disabledClass} ${className}`}
      >
        <div
          className={`form-input rounded border-gray-300 focus-within:border-primary-500 focus-within:ring focus-within:ring-primary-200 ${
            open && children ? `rounded-t rounded-b-none` : 'rounded'
          }`}
        >
          {value ? (
            <div className="flex w-full flex-row items-center justify-between">
              <span className="font-semibold text-primary-500">
                {selectedLabel}
              </span>
              <ClearButton onClick={onCancelSelect} />
            </div>
          ) : (
            <input
              className="w-full cursor-pointer text-base focus:outline-none"
              type="text"
              value={text}
              disabled={disabled}
              placeholder={placeholder}
              onClick={onToggleResults}
              onChange={onInputSearch}
            />
          )}
        </div>
        {open && (
          <div className="relative" ref={nodeRef}>
            <div className="absolute left-0 right-0 -top-px z-50 max-h-64 overflow-y-auto rounded-b border bg-white shadow-xl focus:outline-none">
              {React.Children.map(children, child =>
                React.cloneElement(child, {
                  ...child.props,
                  selected: child.props.value === value,
                  onClick: value => onItemSelect(value),
                  //onFocus: value => onItemFocus(value),
                })
              )}
            </div>
          </div>
        )}
      </div>
    </Field>
  )
}
SearchDropdown.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  emptyMessage: PropTypes.string,
  error: PropTypes.object,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectedLabel: PropTypes.string,
  value: PropTypes.any,
}
SearchDropdown.defaultProps = {
  className: '',
  emptyMessage: 'Empty',
  fullWidth: true,
  placeholder: 'Search...',
}

function SearchDropdownOption({
  disabled,
  icon,
  label,
  onClick,
  selected,
  value,
}) {
  const selectedClass = selected ? 'bg-primary-50' : 'hover:bg-gray-100'
  const disabledClass = disabled ? '' : 'cursor-pointer'

  return (
    <Clickable
      className={`flex items-center justify-between space-x-2 px-3 py-2 focus:bg-primary-100 focus:outline-none ${disabledClass} ${selectedClass}`}
      onClick={disabled ? undefined : () => onClick(value)}
    >
      {icon && (
        <div className="text-sm text-primary-400">
          <Icon name={icon} />
        </div>
      )}
      <div className="flex-1">{label}</div>
      {selected && (
        <div className="text-sm text-primary-500">
          <Icon name="check" />
        </div>
      )}
    </Clickable>
  )
}
SearchDropdownOption.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  selected: PropTypes.bool,
  value: PropTypes.any,
}
SearchDropdown.Option = SearchDropdownOption
SearchDropdown.Option.displayName = 'SearchDropdown.Option'

export default function SearchDropdownField({
  children,
  className,
  disabled,
  fullWidth,
  help,
  label,
  name,
  onChange,
  onSearch,
  placeholder,
  required,
  selectedLabel,
  shouldUnregister,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = useCallback(
    field => newValue => {
      field.onChange(newValue)

      if (isFunction(onChange)) {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <SearchDropdown
          name={name}
          label={label}
          error={fieldState.error}
          help={help}
          required={rules?.required?.value}
          className={className}
          disabled={disabled}
          fullWidth={fullWidth}
          id={name}
          items={field.value}
          onChange={onFieldChange(field)}
          onSearch={onSearch}
          placeholder={placeholder}
          selectedLabel={selectedLabel}
        >
          {children}
        </SearchDropdown>
      )}
    />
  )
}
SearchDropdownField.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  selectedLabel: PropTypes.string,
  shouldUnregister: PropTypes.bool,
  value: PropTypes.any,
}
SearchDropdownField.defaultProps = {
  className: '',
  fullWidth: true,
  items: [],
}
SearchDropdownField.displayName = 'SearchDropdown'

SearchDropdownField.Option = SearchDropdownOption
SearchDropdownField.Option.displayName = 'SearchDropdown.Option'
