import { useCallback } from 'react'

import { useEditor } from '@craftjs/core'

import { useClipboardContext } from '../../ClipboardProvider'

function copyNode(nodeId, query) {
  const node = query.node(nodeId).get()

  const freshNode = {
    data: {
      ...node.data,
      nodes: [],
    },
    nodes: [],
  }

  if (node.data.nodes.length > 0) {
    freshNode.nodes = node.data.nodes.map(childNode =>
      copyNode(childNode, query)
    )
  }

  return freshNode
}

export default function useCopyNode() {
  const { setNodeToPaste } = useClipboardContext()
  const { query } = useEditor()

  return useCallback(
    id => setNodeToPaste(copyNode(id, query)),
    [setNodeToPaste, query]
  )
}
