import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function ContentIcon({ className, name, title, onClick }) {
  const hasOnClick = typeof onClick === 'function'

  const onKeyDown = useCallback(
    event => {
      event.stopPropagation()
      if (event.code === 'Space') onClick()
    },
    [onClick]
  )

  return (
    <div
      className={`flex w-6 items-center justify-center text-sm transition-all duration-300 ease-in-out ${className}`}
      title={title}
      onClick={onClick}
      onKeyDown={hasOnClick ? onKeyDown : undefined}
      role={hasOnClick ? 'button' : undefined}
      tabIndex={hasOnClick ? '0' : undefined}
    >
      <Icon name={name} />
    </div>
  )
}
ContentIcon.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
}
ContentIcon.defaultProps = {
  className: '',
}
