import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { getImageUrl } from '@utils/images'

/**
 * Get image url with given format
 * @param {object} file - file object
 *
 * @returns {string} - image url
 */
function useImageFormat({
  file, // file object (with images original width and height)
  width, // image width (px)
  height, // image height (px)
  quality = 80, // image quality (0-100)
  hasAspectRatio = false, // indicates if the image has been requested with an specific aspectRatio (e.g. 16:9)
}) {
  // If no file is provided, stop here and just return an empty string
  if (!file) return ''

  // Get current Device Pixel Ratio (DPR)
  const dpr = window?.devicePixelRatio || 1

  // Calculate aspect ratio to rescale the image size accordingly (so the object-fit: cover works as expected, without stretching the image)
  const aspectRatio = hasAspectRatio && file ? file.width / file.height : 1

  // Ensure quality is between 0 and 100
  if (quality > 100) quality = 100
  if (quality < 0) quality = 0

  // Inform the user if the quality is too low
  if (quality < 50) {
    // eslint-disable-next-line no-console
    console.warn(`Image quality is too low. Received ${quality} instead.`)
  }

  // Ensure width and height are greater than 0
  if (width < 0) {
    // eslint-disable-next-line no-console
    console.warn(
      `Image width must be greater than 0. Received ${width} instead.`
    )
    width = 0
  }

  if (height < 0) {
    // eslint-disable-next-line no-console
    console.warn(
      `Image height must be greater than 0. Received ${height} instead.`
    )
    height = 0
  }

  // Concatenate all params into a string to be used as the image format
  const params = []
  if (width) params.push(`w:${width * aspectRatio * dpr}`) // Calculate width for current DPR
  if (height) params.push(`h:${height * aspectRatio * dpr}`) // Calculate height for current DPR
  if (quality) params.push(`q:${quality}`)

  return params.join(',')
}

const aspectRatios = {
  '1:1': 'aspect-1',
  '3:4': 'aspect-w-3 aspect-h-4',
  '4:3': 'aspect-w-2 aspect-h-1',
  '9:16': 'aspect-w-9 aspect-h-16',
  '16:9': 'aspect-w-16 aspect-h-9',
}

function useImageAspectRatio(aspectRatio) {
  return aspectRatio ? `${aspectRatios[aspectRatio] || ''} object-cover` : ''
}

/**
 * Generic image component. It can fetch images stored in the CMS (with the `file` param) or from external sources (with the `src` param).
 * @param {object} props - component props
 * @param {string} props.alt - image alt text (required)
 * @param {string} props.aspectRatio - image aspect ratio (e.g. 16:9, 4:3, 1:1)
 * @param {string} props.className - image class name (optional)
 * @param {object} props.file - image file object (required if `src` is not provided)
 * @param {number} props.height - image height (px)
 * @param {number} props.quality - image quality (0-100)
 * @param {string} props.src - image src (required if `file` is not provided)
 * @param {number} props.width - image width (px)
 * @returns {React.Component}
 */
export default function Image({
  alt,
  aspectRatio,
  className = '',
  file,
  height,
  quality = 80,
  showPlaceholder = false,
  src,
  width,
}) {
  const { t } = useTranslation()
  const aspectRatioClass = useImageAspectRatio(aspectRatio)

  const imageFormat = useImageFormat({
    file,
    width: parseInt(width, 10),
    height: parseInt(height, 10),
    quality: parseInt(quality, 10),
    hasAspectRatio: !!aspectRatio,
  })

  const imgSrc = file ? getImageUrl(file, imageFormat) : src

  if (!imgSrc && showPlaceholder) {
    return (
      <PlaceholderImage
        className={className}
        aspectRatio={aspectRatio}
        label={t('noImage')}
      />
    )
  }

  return (
    <img
      className={`${aspectRatioClass} ${className}`}
      src={imgSrc}
      alt={alt}
      width={width}
      height={height}
    />
  )
}
Image.propTypes = {
  alt: PropTypes.string.isRequired,
  aspectRatio: PropTypes.oneOf(Object.keys(aspectRatios)),
  className: PropTypes.string,
  file: PropTypes.object,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  quality: PropTypes.number,
  showPlaceholder: PropTypes.bool,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export function PlaceholderImage({ className = '', aspectRatio, label }) {
  const aspectRatioClass = useImageAspectRatio(aspectRatio)
  return (
    <div className={`overflow-hidden ${className}`}>
      <div
        className={`group/placeholder-image flex flex-col gap-1 w-full h-full items-center justify-center bg-gray-300 drop-shadow-sm ${aspectRatioClass}`}
      >
        <svg viewBox="0 0 470 358" className="w-2/3 h-2/3">
          <g fill="none">
            <rect width="470" height="358" rx="40" className="fill-white" />
            <path
              d="M20,296 L178,167 L264,234 L372,107 L450,167 L450,305.341168 C450,322 436.568542,335.341168 420,335.341168 L50,335.341168 C33.4314575,335.341168 20,322 20,305.341168 L20,296 L20,296 Z"
              className="fill-gray-300"
            />
            <circle cx="92" cy="112" r="40" className="fill-gray-300" />
          </g>
        </svg>
        {label && (
          <div className="absolute text-center text-gray-100 text-xs font-semibold bg-gray-600/60 rounded-full px-1.5 py-0.5 -rotate-12 transition-all ease-in-out select-none duration-200 shadow-md group-hover/placeholder-image:scale-105">
            {label}
          </div>
        )}
      </div>
    </div>
  )
}
PlaceholderImage.propTypes = {
  aspectRatio: PropTypes.oneOf(Object.keys(aspectRatios)),
  className: PropTypes.string,
  label: PropTypes.string,
}
