import { useCallback, useEffect, useRef, useState } from 'react'

const mouseEvent = 'mousedown'

/**
 * Hook to handle click outside an element, with an open state provided internally
 * @returns {object} { nodeRef, open, setOpen, toggleOpen }
 */
export default function useClickOutside() {
  const [open, setOpen] = useState(false)

  // Get reference to target element from the custom hook
  const nodeRef = useCustomClickOutside({
    onClickOutside: setOpen,
    condition: open,
  })

  // Toggles open state
  const toggleOpen = useCallback(() => {
    setOpen(!open)
  }, [open, setOpen])

  return { nodeRef, open, setOpen, toggleOpen }
}

/**
 * Custom hook to handle click outside an element, with a condition and a callback are provided
 * @param {object} params hook parameters
 * @param {boolean} params.condition condition to trigger the callback
 * @param {function} params.onClickOutside callback to trigger when clicking outside the element
 * @returns `elemRef` reference to target element
 */
export function useCustomClickOutside({ condition, onClickOutside }) {
  const elemRef = useRef()

  // Triggers onClickOutside callback when clicking outside the element
  useEffect(() => {
    const elem = elemRef.current

    // Callback to trigger when clicking outside the element
    const callback = e => {
      if (elem && !elem?.contains(e.target)) {
        onClickOutside()
        return
      }
    }

    // Add or remove event listener depending on condition
    if (condition) {
      document.addEventListener(mouseEvent, callback)
    } else {
      document.removeEventListener(mouseEvent, callback)
    }

    return () => {
      document.removeEventListener(mouseEvent, callback)
    }
  }, [condition, onClickOutside])

  return elemRef
}
