import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

export default function useRegions({
  search = '',
  statuses = ['active', 'disabled'],
  fetchOnSearch = false,
  filters = {},
  limit = 50,
  page = 1,
  pagination = false,
  sort = 'title',
  sortOrder = 'asc',
  skip = 0,
  queryOptions = {},
} = {}) {
  const queryFilter = []

  // search
  if (search) {
    queryFilter.push({
      title: {
        $regex: escapeSpecialChars(search),
        $options: 'i',
      },
    })
  }

  // status
  if (!isEmpty(statuses)) {
    const statusFilter = []
    if (statuses.includes('active')) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      statusFilter.push({ deleted: true })
    }
    queryFilter.push({ $or: statusFilter })
  } else {
    queryFilter.push({ deleted: false })
  }

  // ids
  if (!isEmpty(filters['ids'])) {
    queryFilter.push({ _id: { $in: filters['ids'] } })
  }

  // ignore ids
  if (!isEmpty(filters['ignore-ids'])) {
    queryFilter.push({ _id: { $nin: filters['ignore-ids'] } })
  }

  const { data, ...rest } = useQuery(
    [
      'event-regions',
      {
        search,
        statuses,
        limit,
        sort,
        sortOrder,
        skip,
        pagination,
        page,
        filters,
      },
    ],
    () => {
      return getFetch('/api/event-regions', {
        filter: queryFilter.length > 0 ? { $and: queryFilter } : {},
        sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
        limit,
        skip: pagination ? null : skip,
        page,
      })
    },
    {
      enabled: fetchOnSearch ? search !== '' : true,
      ...queryOptions,
    }
  )
  return {
    regions: data?.items ?? [],
    count: data?.count ?? 0,
    ...rest,
  }
}
