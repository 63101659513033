/**
 * Check if field is enabled based on the block settings. Accepts either a boolean or an array of variants.
 * If field is an array, will check whether the current variant is included in the array.
 * If an array of variants is passed, the first variant will be used as the default if no variant value is selected.
 *
 * @param {string[]|boolean} field
 * @param {string[]} variants
 * @param {string} variant
 * @returns {boolean}
 */
export function isFieldEnabled(field, variants = [], variant) {
  return Array.isArray(field) && variants.length > 0
    ? field.includes(variant ?? variants[0])
    : Boolean(field)
}

export default isFieldEnabled
