import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import WidthField from './Field'

export default function WidthSelectController({
  className,
  name,
  label,
  lightLabel,
  help,
}) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <WidthField
          className={className}
          name={name}
          label={label}
          lightLabel={lightLabel}
          help={help}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
WidthSelectController.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  lightLabel: PropTypes.bool,
  help: PropTypes.string,
}
