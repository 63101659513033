import React from 'react'

const DropdownContext = React.createContext({
  getItemProps: () => ({}),
  activeIndex: null,
  setActiveIndex: () => {},
  setHasFocusInside: () => {},
  isOpen: false,
})

export function useDropdownContext() {
  return React.useContext(DropdownContext)
}

export default DropdownContext
