import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))

export default function DashboardMenu() {
  const { t } = useTranslation('dashboard/public')
  const { pathname } = useLocation()

  const isDashboard = pathname === '/'

  return (
    <MenuGroup
      name="dashboard"
      label={t('dashboard')}
      icon="tachometer-alt"
      href="/"
      active={isDashboard}
    />
  )
}
