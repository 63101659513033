import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const variantStyles = {
  'basic': {
    wrap: 'bg-gray-200 border-transparent text-gray-600',
    wrapHover: 'hover:bg-gray-300',
    icon: 'text-gray-500',
    delete: 'text-gray-600/60',
    deleteHover: 'hover:text-gray-600',
  },
  'light': {
    wrap: 'bg-gray-100 border-gray-300 text-gray-500',
    wrapHover: 'hover:bg-gray-200',
    icon: 'text-gray-400',
    delete: 'text-gray-500/70',
    deleteHover: 'hover:text-gray-500',
  },
  'primary': {
    wrap: 'bg-primary-500 border-transparent text-primary-50',
    wrapHover: 'hover:bg-primary-600',
    icon: 'text-primary-100',
    delete: 'text-primary-50/80',
    deleteHover: 'hover:text-primary-50',
  },
  'primary-light': {
    wrap: 'bg-primary-100 border-primary-300 text-primary-600',
    wrapHover: 'hover:bg-primary-200',
    icon: 'text-primary-400',
    delete: 'text-primary-600/60',
    deleteHover: 'hover:text-primary-600',
  },
  'info': {
    wrap: 'bg-info-100 border-info-300 text-info-600',
    wrapHover: 'hover:bg-info-200',
    icon: 'text-info-500',
    delete: 'text-info-600/60',
    deleteHover: 'hover:text-info-600',
  },
  'info-light': {
    wrap: 'bg-info-50 border-info-200 text-info-400',
    wrapHover: 'hover:bg-info-100',
    icon: 'text-info-400',
    delete: 'text-info-400/80',
    deleteHover: 'hover:text-info-400',
  },
  'success': {
    wrap: 'bg-success-500 border-transparent text-success-50',
    wrapHover: 'hover:bg-success-600',
    icon: 'text-success-100',
    delete: 'text-success-50/80',
    deleteHover: 'hover:text-success-50',
  },
  'success-light': {
    wrap: 'bg-success-100 border-success-300 text-success-600',
    wrapHover: 'hover:bg-success-200',
    icon: 'text-success-500',
    delete: 'text-success-600/60',
    deleteHover: 'hover:text-success-600',
  },
  'warn': {
    wrap: 'bg-warn-500 border-transparent text-warn-50',
    wrapHover: 'hover:bg-warn-600',
    icon: 'text-warn-100',
    delete: 'text-warn-50/80',
    deleteHover: 'hover:text-warn-50',
  },
  'warn-light': {
    wrap: 'bg-warn-100 border-warn-300 text-warn-600',
    wrapHover: 'hover:bg-warn-200',
    icon: 'text-warn-500',
    delete: 'text-warn-600/60',
    deleteHover: 'hover:text-warn-600',
  },
  'danger': {
    wrap: 'bg-danger-500 border-transparent text-danger-50',
    wrapHover: 'hover:bg-danger-600',
    icon: 'text-danger-100',
    delete: 'text-danger-50/80',
    deleteHover: 'hover:text-danger-50',
  },
  'danger-light': {
    wrap: 'bg-danger-100 border-danger-300 text-danger-600',
    wrapHover: 'hover:bg-danger-200',
    icon: 'text-danger-500',
    delete: 'text-danger-600/60',
    deleteHover: 'hover:text-danger-600',
  },
}

const sizesStyles = {
  xs: {
    wrap: 'py-px px-1.5 gap-0.5 text-xs',
    wrapNoLabel: 'p-px text-xs w-5 h-5',
    label: 'font-light',
    delete: 'w-3 h-3',
  },
  sm: {
    wrap: 'py-0.5 px-2 gap-1 text-sm',
    wrapNoLabel: 'p-0.5 text-sm w-6 h-6',
    label: 'font-normal',
    delete: 'w-4 h-4',
  },
  md: {
    wrap: 'py-1 px-2 gap-2 text-sm',
    wrapNoLabel: 'p-1 text-base w-7 h-7',
    label: 'font-medium',
    delete: 'w-5 h-5',
  },
  lg: {
    wrap: 'py-1.5 px-2.5 gap-2.5 text-base',
    wrapNoLabel: 'p-1.5 text-lg w-8 h-8',
    label: 'font-medium',
    delete: 'w-6 h-6',
  },
}

export default function Tag({
  className = '',
  disabled,
  icon,
  iconSpin,
  label,
  onClick,
  onDelete,
  variant = 'basic',
  size,
}) {
  const variantClasses = variantStyles[variant] ?? variantStyles.basic
  const sizeClasses = sizesStyles[size] ?? sizesStyles.md

  const wrapClickableClass = disabled
    ? 'opacity-50'
    : onClick
      ? variantClasses.wrapHover
      : ''

  const deleteClickableClass = disabled ? '' : variantClasses.deleteHover

  const handleDelete = React.useCallback(
    e => {
      e.stopPropagation()
      onDelete?.(e)
    },
    [onDelete]
  )

  return (
    <Clickable
      className={`inline-flex items-center justify-center rounded-full border ${
        label ? sizeClasses.wrap : sizeClasses.wrapNoLabel
      } ${variantClasses.wrap} ${wrapClickableClass} ${className}`}
      disabled={disabled}
      onClick={onClick}
    >
      {icon && (
        <div
          className={`flex shrink-0 items-center justify-center overflow-hidden ${variantClasses.icon}`}
        >
          {typeof icon === 'string' ? (
            <Icon name={icon} spin={iconSpin} />
          ) : (
            icon
          )}
        </div>
      )}
      {label && (
        <span className={`shrink truncate ${sizeClasses.label}`}>{label}</span>
      )}
      {typeof onDelete === 'function' && (
        <Clickable
          className={`flex shrink-0 items-center justify-center overflow-hidden rounded-full ${sizeClasses.delete} ${variantClasses.delete} ${deleteClickableClass}`}
          disabled={disabled}
          onClick={handleDelete}
        >
          <Icon name="times" />
        </Clickable>
      )}
    </Clickable>
  )
}
Tag.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  iconSpin: PropTypes.bool,
  label: PropTypes.node,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf([
    'basic',
    'light',
    'primary',
    'primary-light',
    'info',
    'info-light',
    'success',
    'success-light',
    'warn',
    'warn-light',
    'danger',
    'danger-light',
  ]),
}
export function BooleanTag({
  icon,
  iconFalse,
  iconTrue,
  label,
  labelFalse,
  labelTrue,
  value,
  variant,
  variantFalse,
  variantTrue,
  hideIcon,
  ...props
}) {
  const { t } = useTranslation()

  return (
    <Tag
      label={label ?? (value ? labelTrue || t('yes') : labelFalse || t('no'))}
      icon={
        hideIcon
          ? undefined
          : icon ?? (value ? iconTrue || 'check' : iconFalse || 'times')
      }
      variant={
        variant ??
        (value
          ? variantTrue || 'success-light'
          : variantFalse || 'danger-light')
      }
      {...props}
    />
  )
}
BooleanTag.propTypes = {
  hideIcon: PropTypes.bool,
  icon: PropTypes.string,
  iconFalse: PropTypes.string,
  iconTrue: PropTypes.string,
  label: PropTypes.string,
  labelFalse: PropTypes.string,
  labelTrue: PropTypes.string,
  value: PropTypes.bool,
  variant: PropTypes.string,
  variantFalse: PropTypes.string,
  variantTrue: PropTypes.string,
  size: PropTypes.string,
}

/**
 *
 * @param {object} props
 * @param {object.children} props.children
 * @returns
 */
export function Tags({ children, className = '', itemClass = '' }) {
  return (
    <div className={`-m-1 flex flex-row flex-wrap ${className}`}>
      {React.Children.map(
        children,
        child =>
          child && (
            <div
              className={`${['xs', 'sm'].includes(child.props?.size) ? 'p-0.5' : 'p-1'} ${itemClass}`}
            >
              {child}
            </div>
          )
      )}
    </div>
  )
}
Tags.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  itemClass: PropTypes.string,
}
