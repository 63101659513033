import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CoursesStudentAdvisors',
  label: 'courses/public:studentAdvisors',
  type: 'plugin',
  icon: 'person-chalkboard',
  component: <StudentAdvisors />,
}

function getPlaceholder() {
  return {
    name: '[Advisor name]',
    // description: '[Placeholder description]',
    address: {
      street: '[Placeholder street]',
      city: '[Placeholder city]',
      email: '[Placeholder email]',
    },
  }
}

export default function StudentAdvisors({
  dynamic,
  messagesPageId,
  showAddress,
  showEmail,
  showMessagesLink,
}) {
  const { t } = useTranslation('courses/public')
  const { breakpoint } = useBreakpoint()

  const { name, address } = getPlaceholder()

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <Box space="xl" className="p-4">
        <h1 className="text-3xl font-bold">{name}</h1>
        <div
          className={`flex max-w-xl items-start gap-8 ${
            breakpoint === 'xs' ? 'flex-col' : 'flex-row'
          }`}
        >
          {/* <div className={breakpoint === 'xs' ? 'w-2/3' : 'w-1/4'}>
            <div className="aspect-1 rounded-full bg-gray-200"></div>
          </div> */}
          <Box space="lg">
            {/* <div>{description}</div> */}
            {showAddress && (
              <Box space="md">
                <div className="flex space-x-2">
                  <div className="w-6 text-center text-gray-500">
                    <Icon name="location-dot" />
                  </div>
                  <div className="text-sm">
                    {address.street}
                    <br />
                    {address.city}
                  </div>
                </div>
                {showEmail && (
                  <div className="flex items-center space-x-2">
                    <div className="w-6 text-center text-gray-500">
                      <Icon name="envelope" />
                    </div>
                    <div className="text-sm">{address.email}</div>
                  </div>
                )}
              </Box>
            )}
            {showMessagesLink && messagesPageId && (
              <Button label={t('writeMessage')} size="sm" variant="primary" />
            )}
          </Box>
        </div>
      </Box>
    </Content>
  )
}
StudentAdvisors.propTypes = {
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  messagesPageId: PropTypes.string,
  showAddress: PropTypes.bool,
  showEmail: PropTypes.bool,
  showMessagesLink: PropTypes.bool,
}
StudentAdvisors.toolbarItem = config

function StudentAdvisorsSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Box space="xs">
          <Checkbox
            label={t('address')}
            onChange={value => {
              actions.setProp(props => (props.showAddress = value))
            }}
            value={data.showAddress}
            name="showAddress"
          />
          <Checkbox
            label={t('email')}
            onChange={value => {
              actions.setProp(props => (props.showEmail = value))
            }}
            disabled={!data.showAddress}
            value={data.showEmail}
            name="showEmail"
          />
          <Checkbox
            label={t('messages')}
            onChange={value => {
              actions.setProp(props => (props.showMessagesLink = value))
            }}
            value={data.showMessagesLink}
            name="showMessagesLink"
          />
        </Box>
        {data.showMessagesLink && (
          <PagePicker
            label={t('messagesPageId')}
            value={data.messagesPageId}
            onChange={pageId =>
              actions.setProp(props => (props.messagesPageId = pageId))
            }
          />
        )}
      </Box>
    </SettingsWrap>
  )
}

StudentAdvisors.craft = {
  displayName: config.name,
  props: {
    messagesPageId: undefined,
    showAddress: true,
    showEmail: true,
    showMessagesLink: true,
  },
  custom: {
    type: config.type,
    resources: ['coursesStudentAdvisors'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: StudentAdvisorsSettings,
  },
}
