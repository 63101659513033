import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'

export default function PaginationFields() {
  const { t } = useTranslation('events/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const onNumberChange = useCallback(
    field => e => {
      const { value } = e.target

      actions.setProp(
        props =>
          (props[field] = parseInt(isNaN(value) || value < 0 ? 0 : value))
      )
    },
    [actions]
  )

  return (
    <>
      <Checkbox
        label={t('pagination')}
        help={t('paginationHelp')}
        onChange={value => {
          actions.setProp(props => (props.pagination = value))
        }}
        value={data.pagination}
      />
      <Input
        label={t(data.pagination ? 'monthsPerPage' : 'months')}
        help={t(data.pagination ? 'monthsPerPageHelp' : 'monthsHelp')}
        onChange={onNumberChange('months')}
        value={data.months}
        name="months"
        type="number"
      />
    </>
  )
}
