import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import TextSizeSelectField from './Field'

/**
 * Select field for font weight to be used within a Fomr component exclusively.
 *
 * @param {object} props - Component props
 * @param {string} [props.className] - Optional additional class name
 * @param {string} [props.name] - Name of the field
 * @param {string} [props.label] - Optional label of the field
 * @param {string} [props.help] - Optional help text
 * @param {boolean} [props.required] - Whether the field is required
 * @returns {ReactElement}
 */
export default function TextSizeSelectController({
  className = '',
  name,
  label,
  help,
  required,
}) {
  const { t } = useTranslation('web/content-editor')

  return (
    <Controller
      rules={{ required }}
      name={name}
      render={({ field }) => (
        <TextSizeSelectField
          name={name || 'textSize'}
          label={label || t('textSize')}
          help={help}
          className={className}
          placeholderValue="md"
          required={required}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
TextSizeSelectController.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  required: PropTypes.bool,
}
