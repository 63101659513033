import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import DesignProvider from '@modules/web/components/DesignProvider'
import Empty from '@ui/data-display/Empty'
import SplitPanel from '@ui/layout/SplitPanel'

import ComponentsMenu from './ComponentsMenu'
import FontFaceLoader from './components/shared/FontFaceLoader'
import { componentsList } from './componentsList'
import useCurrentComponent from './useCurrentComponent'

export default function ComponentsForm({ site, onSubmit }) {
  const { currentComponent } = useCurrentComponent()

  const ComponentSettingsForm = useMemo(
    () => componentsList.find(({ name }) => name === currentComponent)?.form,
    [currentComponent]
  )

  return (
    <DesignProvider>
      <SplitPanel
        className="flex-grow"
        asideMinSize={250}
        localStoreKey="components-aside-size"
        aside={
          <div className="p-4 h-full flex-grow bg-white">
            <ComponentsMenu />
          </div>
        }
      >
        <FontFaceLoader />
        <div className="p-8">
          {ComponentSettingsForm ? (
            <ComponentSettingsForm site={site} onSubmit={onSubmit} />
          ) : (
            <Empty />
          )}
        </div>
      </SplitPanel>
    </DesignProvider>
  )
}
ComponentsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}
