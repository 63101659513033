import PropTypes from 'prop-types'
import React from 'react'

import { ListboxField } from '../Listbox'
import ColorSelectOptions from './Options'
import ColorSelectedOption from './SelectedOption'
import { getSelectedOption } from './helpers'

export default function ColorSelectField({
  name,
  help,
  fieldExtra,
  label,
  labelExtra,
  lightLabel,
  showClear,
  className,
  clearLabel,
  placeholder = '-',
  options = [],
  value,
  by,
  onChange,
}) {
  return (
    <ListboxField
      by={by}
      className={className}
      name={name}
      fieldExtra={fieldExtra}
      label={label}
      labelExtra={labelExtra}
      lightLabel={lightLabel}
      help={help}
      options={options}
      onChange={onChange}
      value={value}
      renderSelectedLabel={ColorSelectedOption}
      getSelectedOption={getSelectedOption}
      placeholder={placeholder}
    >
      <ColorSelectOptions
        showClear={showClear}
        clearLabel={clearLabel}
        options={options}
        valueAsObject={Boolean(by)}
      />
    </ListboxField>
  )
}
ColorSelectField.propTypes = {
  name: PropTypes.string.isRequired,
  fieldExtra: PropTypes.node,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  className: PropTypes.string,
  clearLabel: PropTypes.string,
  showClear: PropTypes.bool,
  help: PropTypes.string,
  placeholder: PropTypes.node,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  by: PropTypes.oneOf(['value', 'color']),
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        color: PropTypes.string,
      }),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        colors: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          color: PropTypes.string,
        }),
      }),
    ])
  ),
  onChange: PropTypes.func,
}
