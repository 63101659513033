import isEmpty from 'lodash/isEmpty'

import { isSet } from '@utils/types'

function isFeatureEnabledForDesign({
  featureFlag,
  site,
  strategyOverride = {},
}) {
  const { designs = [] } = featureFlag

  // Strategy can be overridden. Useful for cases where site is not known
  if (isSet(strategyOverride.design)) {
    return strategyOverride.design
  }

  // Release strategy cannot be determined without a site
  if (!site) {
    return false
  }

  return designs.includes(site?.design?.name)
}

function isFeatureEnabledForSite({ featureFlag, site, strategyOverride = {} }) {
  const { sites = [] } = featureFlag

  // Strategy can be overridden. Useful for cases where site is not known
  if (isSet(strategyOverride.site)) {
    return strategyOverride.site
  }

  // Release strategy cannot be determined without a site
  if (!site) {
    return false
  }

  return sites.includes(site.id)
}

const strategies = {
  design: isFeatureEnabledForDesign,
  site: isFeatureEnabledForSite,
}

function isReleaseStrategyEnabled({ featureFlag, site, strategyOverride }) {
  return function (strategy) {
    return strategies[strategy]({
      featureFlag,
      site,
      strategyOverride,
    })
  }
}

export function isFeatureEnabled(
  featureFlag,
  { site, strategyOverride = {} } = {}
) {
  if (!featureFlag) {
    return false
  }

  // Feature has no release strategies, so it's always enabled
  if (isEmpty(featureFlag) || !featureFlag.releaseStrategies) {
    return true
  }

  // Check if all release strategies are enabled
  return featureFlag.releaseStrategies.every(
    isReleaseStrategyEnabled({
      featureFlag,
      site,
      strategyOverride,
    })
  )
}
