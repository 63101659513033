import { useMemo } from 'react'

export const contentTypesStyles = {
  default: {
    bg: 'bg-gray-500',
    text: 'text-gray-800',
    border: 'ring-gray-300',
    selection: 'selection:bg-gray-600',
  },
  content: {
    bg: 'bg-success-500',
    text: 'text-success-800',
    border: 'ring-success-300',
    selection: 'selection:bg-success-600',
  },
  wrapper: {
    bg: 'bg-info-500',
    text: 'text-info-800',
    border: 'ring-info-300',
    selection: 'selection:bg-info-600',
  },
  layout: {
    bg: 'bg-primary-500',
    text: 'text-primary-800',
    border: 'ring-primary-300',
    selection: 'selection:bg-primary-600',
  },
  plugin: {
    bg: 'bg-warn-500',
    text: 'text-warn-800',
    border: 'ring-warn-300',
    selection: 'selection:bg-warn-600',
  },
  withErrors: {
    bg: 'bg-danger-500',
    text: 'text-danger-800',
    border: 'ring-danger-300',
    selection: 'selection:bg-danger-600',
  },
}

export default function useContentTypeStyles(type) {
  return useMemo(
    () => contentTypesStyles[type] ?? contentTypesStyles.default,
    [type]
  )
}
