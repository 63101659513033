import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useChannel from '@modules/media-library/services/hooks/useChannel'
import useCollections from '@modules/media-library/services/hooks/useCollections'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Field from '@ui/data-entry/Field'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Heading from '@ui/typography/Heading'
import Text from '@ui/typography/Text'

import ChannelSelect from '../ChannelSelect'
import CollectionItem from '../shared/CollectionItem'

const config = {
  name: 'CollectionList',
  label: 'media-library/public:collectionList',
  type: 'plugin',
  icon: 'layer-group',
  component: <CollectionList />,
}

export default function CollectionList({ channelId, displayMode }) {
  const { t } = useTranslation('media-library/public')
  const { resources } = usePageResources()

  const showContent = true

  const _channelId = resources?.Channel?.id || channelId

  const { channel } = useChannel(_channelId)
  const { collections, count, loading } = useCollections({
    channelId: _channelId,
  })

  return (
    <Content className="w-full" icon={config.icon} title={t('collectionList')}>
      {_channelId ? (
        <>
          {showContent ? (
            <Box space="xl">
              {count > 0 ? (
                <>
                  {collections
                    ?.filter(c => c.enabled === true)
                    .filter(
                      c =>
                        (c.contentType === 'episodes' &&
                          c.episodes.length > 0) ||
                        (c.contentType === 'shows' && c.shows.length > 0)
                    )
                    .map(c => (
                      <CollectionItem
                        collection={c}
                        displayMode={displayMode}
                        key={c.id}
                      />
                    ))}
                </>
              ) : (
                <Text text={!loading ? t('noCollections') : ' '} />
              )}
            </Box>
          ) : (
            <Box space="xs">
              <Heading as="h6" text={channel?.title} />
              <Text
                className="text-sm"
                text={t('collectionsCount', { count })}
              />
            </Box>
          )}
        </>
      ) : (
        <Text text={t('noChannelSelected')} />
      )}
    </Content>
  )
}
CollectionList.propTypes = {
  channelId: PropTypes.string,
  displayMode: PropTypes.oneOf(['grid', 'carousel']),
}
CollectionList.defaultProps = {}
CollectionList.toolbarItem = config

function CollectionListSettings() {
  const { t } = useTranslation([
    'media-library/public',
    'media-library/collections',
    'web/content-editor',
  ])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('collectionList')} help={t('collectionListHelp')}>
      <Box space="xl">
        {resources?.Channel && (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        )}
        <PagePicker
          label={t('showDetailPageId')}
          help={t('showDetailPageIdHelp')}
          value={data.showDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.showDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
          }
        />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
        <PagePicker
          label={t('collectionDetailPageId')}
          help={t('collectionDetailPageIdHelp')}
          value={data.collectionDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.collectionDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Collection'
          }
        />
        {!resources?.Channel && (
          <>
            <Divider />
            <ChannelSelect
              label={t('channel')}
              onChange={onChannelUpdate('channelId')}
              value={data.channelId}
              controlled={false}
            />
          </>
        )}
        <Select
          label={t('displayMode')}
          help={t('displayModeHelp')}
          value={data.displayMode}
          onChange={e =>
            actions.setProp(props => (props.displayMode = e.target.value))
          }
        >
          <SelectOption value="grid" label={t('displayMode_grid')} />
          <SelectOption value="carousel" label={t('displayMode_carousel')} />
        </Select>
        {data.displayMode === 'carousel' && (
          <Input
            label={t('limit')}
            help={t('limitHelp')}
            onChange={onFieldUpdate('limit', value =>
              value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
            )}
            value={data.limit}
            type="number"
          />
        )}
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsPostersHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
        <Field label={t('media-library/collections:layoutPosters')}>
          <Box space="md">
            <Checkbox
              name="enableTitleInPosters"
              label={t('enableTitleInPosters')}
              help={t('enableTitleInPostersHelp')}
              value={data.enableTitleInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableTitleInPosters = checked))
              }
            />
            <Checkbox
              name="enableAbstractInPosters"
              label={t('enableAbstractInPosters')}
              help={t('enableAbstractInPostersHelp')}
              value={data.enableAbstractInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableAbstractInPosters = checked))
              }
            />
            <Checkbox
              name="enableCallToActionInPosters"
              label={t('enableCallToActionInPosters')}
              help={t('enableCallToActionInPostersHelp')}
              value={data.enableCallToActionInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableCallToActionInPosters = checked))
              }
            />
            {data.enableCallToActionInPosters && (
              <Input
                className="pl-7"
                onChange={onFieldUpdate('callToActionLabel')}
                placeholder={t('callToActionPlaceholder')}
                value={data.callToActionLabel}
              />
            )}
          </Box>
        </Field>
        <Field
          label={`${t('media-library/collections:layoutCards')} (${t('episodes')})`}
        >
          <Checkbox
            name="enableEpisodeAbstractInCards"
            label={t('showAbstract')}
            help={t('enableAbstractInCardsHelp')}
            value={data.enableEpisodeAbstractInCards}
            onChange={checked =>
              actions.setProp(p => (p.enableEpisodeAbstractInCards = checked))
            }
          />
        </Field>
        <Field
          label={`${t('media-library/collections:layoutCards')} (${t('shows')})`}
        >
          <Checkbox
            name="enableShowAbstractInCards"
            label={t('showAbstract')}
            help={t('enableAbstractInCardsHelp')}
            value={data.enableShowAbstractInCards}
            onChange={checked =>
              actions.setProp(p => (p.enableShowAbstractInCards = checked))
            }
          />
        </Field>
      </Box>
    </SettingsWrap>
  )
}

CollectionList.craft = {
  displayName: 'CollectionList',
  props: {
    channelId: undefined,
    showDetailPageId: undefined,
    episodeDetailPageId: undefined,
    collectionDetailPageId: undefined,
    displayMode: 'grid',
    limit: 20,
    enableAnimations: true,
    enableTitleInPosters: true,
    enableAbstractInPosters: true,
    enableCallToActionInPosters: true,
    callToActionLabel: undefined,
    enableEpisodeAbstractInCards: true,
    enableShowAbstractInCards: false,
  },
  custom: {
    type: config.type,
    resources: ['collections'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CollectionListSettings,
  },
}
