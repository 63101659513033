import React from 'react'

import { useTranslation } from 'react-i18next'

import EntityPicker from '@modules/entities/components/entities/EntityPicker'
import CheckboxController from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'

export function TaskACMSImportFields() {
  const { t } = useTranslation(['entities/public', 'tasks'])
  return (
    <Box space="xl">
      <CheckboxController
        name="settings.importAll"
        label={t('tasks:importAll')}
        help={t('tasks:importAllHelp')}
      />
      <EntityPicker
        name="settings.entity"
        label={t('acmsImportTaskTargetEntity')}
        help={t('acmsImportTaskEntityHelp')}
      />
    </Box>
  )
}
