import React, { useCallback, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'
import Button from '@ui/buttons/Button'
import { Dialog, DialogContent } from '@ui/feedback/FloatingDialog'

import useCurrentEntity from '../services/hooks/useCurrentEntity'
import useSwitchToEntity from '../services/hooks/useSwitchToEntity'
import { EntityPickerField as EntityPicker } from './entities/EntityPicker'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))

export default function EntitySwitch() {
  const { t } = useTranslation('entities/public')
  const navigate = useNavigate()
  const { entity: currentEntity } = useCurrentEntity()
  const { entityId, switchToEntity } = useSwitchToEntity()

  const canUpdateCurrentEntity = useIsUserAuthorized({
    module: 'entity',
    permission: 'update',
    recordId: currentEntity?.id,
  })

  const canUpdateCurrentSubentity = useIsUserAuthorized({
    module: 'subentities',
    permission: 'update',
    entity: currentEntity,
  })

  const [isOpen, setIsOpen] = useState(false)
  const [selectedEntityId, setSelectedEntityId] = useState(
    entityId ?? currentEntity?.id
  )

  /**
   * When the user selects a new entity, update the entity ID in the state
   */
  const onEntitySelection = useCallback(
    id => {
      setSelectedEntityId(id)
    },
    [setSelectedEntityId]
  )

  /**
   * Switches to the selected entity. This function is used when the user clicks on the "Switch" button. It will: set the new entity id in the headers, set the new entity id in the localStorage, close the dialog, and navigate to the home page.
   */
  const onEntitySwitch = useCallback(() => {
    // Set the new entity id in the headers
    switchToEntity(selectedEntityId)

    // Close the dialog
    setIsOpen(false)

    // Clear the selected entity id
    setSelectedEntityId(null)

    // Navigate to the home page to be sure that users aren't on a page that they can't access
    navigate('/', { replace: true })
  }, [navigate, switchToEntity, selectedEntityId])

  /**
   * Clear the entity id (headers, queryClient, and localStorage).
   * Then close the dialog and navigate to the home page.
   * This function is used to go back to the root entity.
   */
  const onEntityClear = useCallback(() => {
    // Clear the entity id in the headers
    switchToEntity(null)

    // Close the dialog
    setIsOpen(false)

    // Navigate to the home page to be sure that users aren't on a page that they can't access
    navigate('/', { replace: true })
  }, [navigate, switchToEntity])

  return (
    <MenuGroup
      name="EntitySwitch"
      label={currentEntity?.shortName || currentEntity?.name || t('loading')}
      icon="university"
    >
      {(canUpdateCurrentEntity || canUpdateCurrentSubentity) && (
        <MenuItem
          label={t('entities/public:updateCurrentEntity')}
          icon="edit"
          href="/entities/current"
        />
      )}
      <MenuItem
        label={t('entities/public:switchToEntity')}
        icon="arrows-repeat"
        onClick={() => setIsOpen(true)}
      />
      {entityId && (
        <MenuItem
          label={t('entities/public:backToDefaultEntity')}
          icon="undo"
          onClick={onEntityClear}
        />
      )}
      <Dialog open={isOpen} onOpenChange={setIsOpen}>
        <DialogContent
          title={t('entities/public:switchToEntityTitle')}
          description={t('entities/public:switchToEntityDescription')}
          boxClass="m-4 sm:max-w-sm lg:max-w-md sm:min-w-[320px]"
        >
          <div className="flex  flex-col gap-4 py-4">
            <EntityPicker
              onChange={onEntitySelection}
              value={selectedEntityId}
            />

            <div className="flex items-center justify-end gap-4 ">
              <Button
                label={t('switch')}
                icon="arrows-repeat"
                variant="primary"
                disabled={!selectedEntityId}
                onClick={onEntitySwitch}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </MenuGroup>
  )
}
