import { useEditor, useNode } from '@craftjs/core'

/**
 * Hook to get the current node context
 * @param {object} options Options
 * @param {boolean} options.draggable If the node is draggable
 * @param {boolean} options.selectable If the node is selectable
 * @returns {object} CraftJS Node contex
 */
export default function useContentNode({ draggable, selectable } = {}) {
  const { id, actions, connectors, data, parentId, selected, type } = useNode(
    ({ data, events } = {}) => ({
      data: data?.props,
      parentId: data.parent,
      type: data.custom.type,
      selected: (selectable && events.selected) || false,
    })
  )

  const { actions: editorActions, parent } = useEditor(state => {
    return {
      parent: state?.nodes?.[parentId],
    }
  })

  return {
    id,
    actions: { ...actions, delete: editorActions.delete },
    data,
    parent,
    ref: ref => connectors.connect(draggable ? connectors.drag(ref) : ref),
    selected,
    type,
  }
}
