import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'

export function DarkModeSelect() {
  const { watch } = useFormContext()
  const { t } = useTranslation('web/appearance')

  const watchDarkMode = watch('darkMode')

  return (
    <>
      <Select
        label={t('designAppearance')}
        help={t('designAppearanceHelp')}
        name="appearance"
        shouldUnregister
        required
      >
        {watchDarkMode && (
          <Select.Option label={`${t('common:clearSelection')}`} value={null} />
        )}
        <Select.Placeholder label="-" />
        <Select.Option label={t('appearance-light')} value="light" />
        <Select.Option label={t('appearance-dark')} value="dark" />
        <Select.Option label={t('appearance-auto')} value="auto" />
      </Select>
    </>
  )
}
