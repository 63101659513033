import { useDesignColor } from '../hooks'

export function useBackgroundStyles(value = {}) {
  const classNames = []
  const style = {}

  const bgColor = useDesignColor(value?.bgColor, '')

  if (bgColor) {
    style.backgroundColor = bgColor
  }

  return {
    className: classNames.join(' '),
    style,
  }
}
