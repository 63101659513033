import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'ShowsFeedList',
  label: 'media-library/public:showsFeedList',
  type: 'plugin',
  icon: 'rss',
  component: <ShowsFeedList />,
}

export default function ShowsFeedList({ channelId, title }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { channel } = useChannel(channelId)

  return (
    <Content icon={config.icon} title={t('showsFeedList')}>
      {showContent ? (
        <div className="flex flex-col p-4">RSS</div>
      ) : (
        <Box space="xs">
          <Heading
            as="h6"
            className="ml-2"
            text={title || t('showsFeedList')}
          />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {channel?.title}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
ShowsFeedList.propTypes = {
  channelId: PropTypes.string,
  title: PropTypes.string,
}
ShowsFeedList.defaultProps = {}
ShowsFeedList.toolbarItem = config

function ShowsFeedListSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('showsFeedList')} help={t('showsFeedListHelp')}>
      <Box space="xl">
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          value={data.title}
        />
        <ChannelSelect
          label={t('channel')}
          onChange={onChannelUpdate('channelId')}
          value={data.channelId}
          controlled={false}
        />
        <PagePicker
          label={t('rssFeedPageId')}
          help={t('rssFeedPageIdHelp')}
          value={data.rssFeedPageId}
          onChange={pageId =>
            actions.setProp(props => (props.rssFeedPageId = pageId))
          }
          condition={page => page.xml?.enabled}
        />
      </Box>
    </SettingsWrap>
  )
}

ShowsFeedList.craft = {
  displayName: 'ShowsFeedList',
  props: {
    channelId: undefined,
    title: '',
    rssFeedPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['showsFeedList'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ShowsFeedListSettings,
  },
}
