import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import ClearButton from '@ui/buttons/ClearButton'
import Label from '@ui/data-display/Label'
import useToggle from '@ui/helpers/useToggle'
import { slugify } from '@utils/strings'
import { isFunction } from '@utils/types'

import Field from './Field'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function Input({
  autoComplete,
  className,
  disabled,
  fullWidth,
  extra,
  icon,
  id,
  isRTL,
  lang,
  max,
  maxLength,
  min,
  minLength,
  inputRef,
  modifier,
  name,
  onBlur,
  onChange,
  onClear,
  onKeyDown,
  placeholder,
  readOnly,
  step,
  type,
  value,
}) {
  const fullWidthClass = fullWidth ? 'w-full' : ''
  const showClear = onClear && value
  const paddingClasses =
    showClear && icon ? 'pr-20' : showClear || icon ? 'pr-10' : ''

  const handleChange = useCallback(
    e => {
      if (typeof modifier === 'function') {
        e.target.value = modifier(e.target.value)
        e.currentTarget.value = modifier(e.currentTarget.value)
      }

      if (typeof onChange === 'function') {
        onChange(e)
      }
    },
    [modifier, onChange]
  )

  const renderInput = () => (
    <>
      <input
        className={`form-input rounded border-gray-300 placeholder-gray-300 focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200 ${paddingClasses} ${fullWidthClass} ${className}`}
        disabled={disabled}
        id={id}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        name={name}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        onChange={handleChange}
        readOnly={readOnly}
        ref={inputRef}
        type={type}
        step={step}
        value={
          value
            ? value
            : type === 'number' && Number.isInteger(value)
              ? value
              : ''
        }
        autoComplete={autoComplete}
        dir={isRTL ? 'rtl' : 'ltr'}
        lang={lang}
      />
      {showClear && (
        <div className={`w-10 ${icon ? '-ml-28' : '-ml-10'}`}>
          <ClearButton onClick={onClear} />
        </div>
      )}
      {icon && (
        <div className="-ml-12 h-6 w-10 border-l pl-2">
          <Icon name={icon} className="text-gray-400" />
        </div>
      )}
    </>
  )

  return (
    <div className="flex grow items-center justify-between gap-x-2">
      {extra ? (
        <div className="flex grow items-center justify-between">
          {renderInput()}
        </div>
      ) : (
        renderInput()
      )}
      {extra}
    </div>
  )
}
Input.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  extra: PropTypes.node,
  icon: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  isRTL: PropTypes.bool,
  lang: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  modifier: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onClear: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  step: PropTypes.string,
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'tel',
    'password',
    'file',
    'hidden',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
}
Input.defaultProps = {
  className: '',
  type: 'text',
  fullWidth: true,
}

export function InputField({
  autoComplete,
  className,
  disabled,
  error,
  extra,
  fieldExtra,
  fullWidth,
  help,
  icon,
  id,
  inputClass,
  inputRef,
  isRTL,
  label,
  labelExtra,
  labelPosition,
  lang,
  max,
  maxLength,
  min,
  minLength,
  missing,
  modifier,
  name,
  onBlur,
  onChange,
  onClear,
  onKeyDown,
  popover,
  placeholder,
  readOnly,
  required,
  step,
  tooltip,
  tooltipPlacement,
  type,
  value,
}) {
  return (
    <Field
      className={className}
      disabled={disabled}
      error={error}
      fieldExtra={fieldExtra}
      help={help}
      label={label}
      labelExtra={labelExtra}
      labelPosition={labelPosition}
      missing={missing}
      name={id || name}
      popover={popover}
      required={required}
      tooltip={tooltip}
      tooltipPlacement={tooltipPlacement}
    >
      <Input
        autoComplete={autoComplete}
        className={inputClass}
        disabled={disabled}
        extra={extra}
        icon={icon}
        id={id || name}
        inputRef={inputRef}
        max={max}
        maxLength={maxLength}
        min={min}
        minLength={minLength}
        modifier={modifier}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onClear={onClear}
        onKeyDown={onKeyDown}
        placeholder={placeholder}
        readOnly={readOnly}
        step={step}
        type={type}
        value={value}
        fullWidth={fullWidth}
        isRTL={isRTL}
        lang={lang}
      />
    </Field>
  )
}
InputField.propTypes = {
  autoComplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  extra: PropTypes.node,
  fieldExtra: PropTypes.node,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  inputClass: PropTypes.string,
  inputRef: PropTypes.any,
  isRTL: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  labelPosition: PropTypes.string,
  lang: PropTypes.string,
  max: PropTypes.number,
  maxLength: PropTypes.number,
  min: PropTypes.number,
  minLength: PropTypes.number,
  missing: PropTypes.bool,
  modifier: PropTypes.func,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onKeyDown: PropTypes.func,
  placeholder: PropTypes.string,
  popover: PropTypes.func,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  step: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipPlacement: PropTypes.oneOf(['top', 'left', 'right']),
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'tel',
    'file',
    'hidden',
    'password',
  ]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

export default function InputController({
  className,
  disabled,
  extra,
  fieldExtra,
  help,
  icon,
  id,
  inputClass,
  label,
  labelExtra,
  labelPosition,
  max,
  maxError,
  maxField,
  maxLength,
  min,
  minError,
  minField,
  minLength,
  missing,
  modifier,
  name,
  onBlur,
  onChange,
  onKeyDown,
  pattern,
  placeholder,
  popover,
  readOnly,
  required,
  shouldUnregister,
  showClear,
  slugField,
  slugFormatFn,
  step,
  tooltip,
  tooltipPlacement,
  type,
  validate,
  value,
  autoComplete,
  fullWidth,
  isRTL,
  lang,
}) {
  const [shake, setShake] = useState(false)
  const { control, setValue, watch } = useFormContext()

  const maxValue = parseInt(
    (maxField && type === 'number' && watch(maxField)) || max
  )
  const minValue = parseInt(
    (minField && type === 'number' && watch(minField)) || min
  )

  const rules = useRules({
    max: maxError ? { value: maxValue, message: maxError } : maxValue,
    maxLength,
    min: minError ? { value: minValue, message: minError } : minValue,
    minLength,
    pattern,
    required,
    validate,
  })

  const onFieldChange = useCallback(
    field => event => {
      const currentValue = event.currentTarget.value

      field.onChange(event)

      if (isFunction(onChange)) {
        onChange(currentValue, field)
      }

      if (slugField) {
        isFunction(slugFormatFn)
          ? setValue(slugField, slugFormatFn(currentValue)) // Format the slug
          : setValue(slugField, slugify(currentValue, false)) // Slugify the value
      }
    },
    [onChange, setValue, slugField, slugFormatFn]
  )

  const onFieldBlur = useCallback(
    field => event => {
      let currentValue = event.currentTarget.value

      function limitValue(value) {
        setShake(true)
        currentValue = value
        event.currentTarget.value = value
        setTimeout(() => {
          setShake(false)
        }, 900)
      }

      if (type === 'number') {
        if (parseInt(currentValue) > maxValue) {
          limitValue(maxValue)
        } else if (parseInt(currentValue) < minValue) {
          limitValue(minValue)
        }

        field.onChange(event)
      }

      field.onBlur(event)

      if (isFunction(onBlur)) {
        onBlur(event.currentTarget.value, field)
      }
    },
    [onBlur, maxValue, minValue, type]
  )

  const onFieldClear = useCallback(
    field => () => {
      field.onChange({ target: { value: '' } })
      if (isFunction(onChange)) {
        onChange('', field)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <InputField
          className={className}
          name={name}
          max={max}
          min={min}
          label={label}
          labelExtra={labelExtra}
          labelPosition={labelPosition}
          error={fieldState.error}
          extra={extra}
          fieldExtra={fieldExtra}
          help={help}
          required={rules?.required?.value}
          disabled={disabled}
          icon={icon}
          id={id || name}
          inputClass={`${inputClass} ${shake ? 'animate-shake' : ''}`}
          inputRef={field.ref}
          popover={popover}
          maxLength={maxLength}
          minLength={minLength}
          missing={missing}
          modifier={modifier}
          step={step}
          tooltip={tooltip}
          tooltipPlacement={tooltipPlacement}
          type={type}
          value={field.value}
          onBlur={onFieldBlur(field)}
          onClear={showClear ? onFieldClear(field) : undefined}
          onChange={onFieldChange(field)}
          onKeyDown={onKeyDown}
          placeholder={placeholder}
          readOnly={readOnly}
          autoComplete={autoComplete}
          fullWidth={fullWidth}
          isRTL={isRTL}
          lang={lang}
        />
      )}
    />
  )
}

InputController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  extra: PropTypes.node,
  fieldExtra: PropTypes.node,
  help: PropTypes.string,
  icon: PropTypes.string,
  id: PropTypes.string,
  inputClass: PropTypes.string,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  labelPosition: PropTypes.string,
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  maxError: PropTypes.string,
  maxField: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  minError: PropTypes.string,
  minField: PropTypes.string,
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  missing: PropTypes.bool,
  modifier: PropTypes.func,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  popover: PropTypes.func,
  pattern: PropTypes.shape({
    value: PropTypes.object,
    message: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  readOnly: PropTypes.bool,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  shouldUnregister: PropTypes.bool,
  showClear: PropTypes.bool,
  slugField: PropTypes.string,
  slugFormatFn: PropTypes.func,
  step: PropTypes.string,
  tooltip: PropTypes.node,
  tooltipPlacement: PropTypes.oneOf(['top', 'left', 'right']),
  type: PropTypes.oneOf([
    'text',
    'email',
    'number',
    'tel',
    'file',
    'hidden',
    'password',
  ]),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoComplete: PropTypes.string,
  isRTL: PropTypes.bool,
  lang: PropTypes.string,
}
InputController.defaultProps = {
  className: '',
  fullWidth: true,
  type: 'text',
  value: '',
}
InputController.displayName = 'Input'

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,24}$/i

export function EmailField(props) {
  const { t } = useTranslation('ui')

  return (
    <InputController
      {...props}
      autoComplete="username"
      type="email"
      pattern={{
        value: emailRegex,
        message: t('errorEmailPattern'),
      }}
    />
  )
}
EmailField.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
InputController.Email = EmailField
InputController.Email.displayName = 'Input.Email'

export function Slug({
  onChange,
  help,
  label,
  name,
  required,
  className,
  onToggle,
  edit,
  formatFn,
  ...props
}) {
  const [showSlugField, toggleShowSlugField] = useToggle()
  const { t } = useTranslation()
  const { resetField, watch } = useFormContext()

  const handleToggle = useCallback(() => {
    toggleShowSlugField()
    onToggle && onToggle(showSlugField)
  }, [onToggle, showSlugField, toggleShowSlugField])

  // Slugify as the user types
  const handleChange = (value, field) => {
    // Update the field value with the formatted value everytime the user types
    const formattedValue = isFunction(formatFn)
      ? formatFn(value)
      : slugify(value, false)
    field.onChange({ target: { value: formattedValue } })

    // If there is a change handler, call it
    if (isFunction(onChange)) {
      onChange(formattedValue, field)
    }
  }

  const handleCancel = useCallback(() => {
    resetField(name)
    handleToggle()
  }, [name, handleToggle, resetField])

  if (edit) {
    return showSlugField ? (
      <div className={`flex flex-row gap-2 ${className}`}>
        <InputController
          className="flex-1"
          help={help}
          label={label}
          name={name}
          required={required}
          onBlur={handleChange}
        />
        <div className="mt-9 flex justify-end gap-2">
          <Button
            tooltip={t('change')}
            onClick={handleToggle}
            variant="success-light"
            icon="check"
            size="md"
          />
          <Button
            tooltip={t('cancel')}
            onClick={handleCancel}
            variant="danger-light"
            icon="times"
          />
        </div>
      </div>
    ) : (
      <Field
        className={className}
        help={help}
        label={t('slug')}
        required={required}
      >
        <div className="flex items-center gap-2">
          {watch(name) ? (
            <div className="font-mono text-gray-500">{watch(name)}</div>
          ) : (
            <div className="font-mono text-sm text-warn-500">
              <Label icon="exclamation-triangle" label={t('slugMissing')} />
            </div>
          )}
          <Button
            label={t('modify')}
            onClick={handleToggle}
            size="sm"
            icon="edit"
            variant="link"
          />
        </div>
      </Field>
    )
  }

  return (
    <InputController
      {...props}
      help={help}
      label={label}
      name={name}
      required={required}
      className={className}
      onChange={handleChange}
      type="text"
      inputClass="font-mono"
    />
  )
}
Slug.propTypes = {
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  edit: PropTypes.bool,
  className: PropTypes.string,
  onToggle: PropTypes.func,
  formatFn: PropTypes.func,
}
InputController.Slug = Slug
InputController.Slug.displayName = 'Input.Slug'
