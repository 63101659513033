const imagesCDN = import.meta.env.VITE_IMAGES_CDN
const avatarsBucket = import.meta.env.VITE_BUCKET_AVATARS
const imagesBucket = import.meta.env.VITE_BUCKET_IMAGES

/**
 * Given an image path, returns the corresponding signature
 *
 * @param {string} path
 * @returns {string} signature
 */
function getImageSignature(path) {
  // Return base64 encoded path, removing no URL-friendly characters
  return window
    .btoa(path)
    .replace(/=/g, '')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
}

/**
 * Returns an Avatar's absolute url
 *
 * @param {object} user
 * @param {string} formatOptions Check here for processing options: https://docs.imgproxy.net/generating_the_url?id=processing-options
 */
export function getAvatarUrl(user, formatOptions = 'w:400') {
  if (!user) return ''
  // Ensure we get the id from the user object
  const userId = user.id || user._id
  // Build the path to the avatar
  const path = `/${formatOptions}/${avatarsBucket}/${userId}/${user.avatar}`
  // Get the signature for the path
  const signature = getImageSignature(path)

  // Return the absolute url
  return `${imagesCDN}/resize/${signature}${path}`
}

/**
 * Returns an Image's absolute url
 *
 * @param {object} file
 * @param {string} formatOptions Check here for processing options: https://docs.imgproxy.net/generating_the_url?id=processing-options
 * @param {object} entityId
 */
export function getImageUrl(file, formatOptions = 'w:800') {
  if (!file || !file.name || !file.containerId) return ''

  const path = `/${formatOptions}/${imagesBucket}/${file.containerId}/${file.name}`

  const signature = getImageSignature(path)

  return `${imagesCDN}/resize/${signature}${path}`
}

/**
 * Returns the original url for an image (for example to download it in the backend)
 *
 * @param {object} file
 */
export function getOriginalImageUrl(file) {
  if (typeof file !== 'object') return ''

  const { containerId, name } = file || {}

  if (!containerId || !name) return '' // If the file doesn't have a containerId or name, return an empty string
  return `${import.meta.env.VITE_ZAFIR_IMAGES_ORIGIN}/${containerId}/${name}`
}
