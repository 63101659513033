import { useParams } from 'react-router'
import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { useGlobalParams } from '@utils/urls'

export default function useChannel(id) {
  const params = useParams()

  const channelId = id || params?.channelId

  const { data, error, isLoading } = useQuery(['channels', channelId], () =>
    getFetch(channelId ? `/api/media-library/channels/${channelId}` : null)
  )

  return { channel: data, error, loading: isLoading }
}

export function useChannelId() {
  const { channelId } = useGlobalParams('/media-library/channels/:channelId/*')
  return channelId
}
