import PropTypes from 'prop-types'
import React from 'react'

import { usePopoverContext } from '../context'

export function PopoverArrow() {
  const {
    arrowRef,
    arrowBorderClass,
    staticSide,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
  } = usePopoverContext()
  return (
    <div
      ref={arrowRef}
      className={`absolute h-4 w-4 rotate-45 bg-white ${arrowBorderClass}`}
      style={{
        top: arrowY ?? '',
        left: arrowX ?? '',
        right: '',
        bottom: '',
        [staticSide]: '-8px',
      }}
    />
  )
}

PopoverArrow.propTypes = {
  arrowRef: PropTypes.object,
  arrowX: PropTypes.number,
  arrowY: PropTypes.number,
  arrowBorderClass: PropTypes.string,
  staticSide: PropTypes.string,
}
