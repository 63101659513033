import PropTypes from 'prop-types'
import React, { useEffect } from 'react'

import { useNode } from '@craftjs/core'
import get from 'lodash/get'
import { useTranslation } from 'react-i18next'

import useCategoriesForTags from '@modules/categories/helpers/useCategoriesForTags'
import { EntityPickerField } from '@modules/entities/components/entities/EntityPicker'
import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import usePage from '@modules/web/services/hooks/usePage'
import useSite from '@modules/web/services/hooks/useSite'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import Divider from '@ui/layout/Divider'

import { PublishedInTheLastSelectField } from '../../PublishedInTheLastSelect'
import CategoriesFields from './CategoriesFields'
import FlagsFields from './FlagsFields'
import PaginationFields from './PaginationFields'
import SortFields from './SortFields'

export function ArticlesFilterFields({
  disableCategoryField,
  disableFlagFields,
  disablePaginationFields,
  disableSortFields,
  fieldPrefix = '',
  filterCurrentArticle,
  showFilters,
  showOrganizationFilter,
  siteId,
}) {
  const { t } = useTranslation(['articles/public', 'articles'])
  const { page } = usePage()
  const { site } = useSite()
  const { resources } = usePageResources()

  const { dynamic, dynamicResource } = page ?? {}

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const isDynamicCategoryResourcEnabled = useFeatureFlagEnabled(
    'dynamic-category-resource',
    {
      site,
    }
  )

  const useDynamicCategory =
    isDynamicCategoryResourcEnabled &&
    dynamicResource?.type === 'model' &&
    dynamicResource?.name === 'Category'

  const { categories } = useCategoriesForTags({
    ids:
      useDynamicCategory && Boolean(resources?.Category)
        ? [resources?.Category?._id]
        : [],
    enabled: useDynamicCategory && Boolean(resources?.Category),
  })

  // Ensures that the selected flag is removed from flagExceptions
  useEffect(() => {
    if (
      data[`${fieldPrefix}flagExceptions`]?.includes(data[`${fieldPrefix}flag`])
    ) {
      actions.setProp(
        data =>
          (data[`${fieldPrefix}flagExceptions`] = data[
            `${fieldPrefix}flagExceptions`
          ].filter(id => id !== data[`${fieldPrefix}flag`]))
      )
    }
  }, [actions, data, fieldPrefix])

  const showFilterByDynamicResource =
    dynamic && (dynamicResource?.type === 'param' || useDynamicCategory)

  return (
    <>
      {showFilterByDynamicResource && (
        <BlockField name={`${fieldPrefix}filterByDynamicResource`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              value={value}
              label={t('filterByDynamicResource', {
                resource: `${t(`articleParam-${dynamicResource.name}`)}${useDynamicCategory && categories?.[0] ? `: ${categories?.[0]?.title}` : ''}`,
              })}
              help={t('filterByDynamicResourceHelp')}
              onChange={onDataChange}
            />
          )}
        </BlockField>
      )}
      {showFilters && (
        <BlockField name={`${fieldPrefix}showFilters`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showFilters')}
              help={t('showFiltersHelp')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {filterCurrentArticle && (
        <BlockField name={`${fieldPrefix}filterCurrentArticle`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('filterCurrentArticle')}
              help={t('filterCurrentArticleHelp')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}

      {!disableSortFields && <SortFields fieldPrefix={fieldPrefix} />}

      {!(
        useDynamicCategory && get(data, `${fieldPrefix}filterByDynamicResource`)
      ) &&
        !disableCategoryField && (
          <CategoriesFields fieldPrefix={fieldPrefix} siteId={siteId} />
        )}

      {!disableFlagFields && dynamicResource?.name !== 'flag' && (
        <FlagsFields fieldPrefix={fieldPrefix} siteId={siteId} />
      )}

      {showOrganizationFilter && (
        <BlockField name={`${fieldPrefix}organizations`}>
          {({ fieldProps, value, onDataChange }) => (
            <EntityPickerField
              {...fieldProps}
              label={t('articles:organizations')}
              help={t('filterOrganizationsHelp')}
              placeholder={t('articles:organizationPlaceholder')}
              name="organizations"
              multiple
              showValueInline={false}
              publicMode
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}

      {!disablePaginationFields && (
        <>
          <Divider />
          <PaginationFields fieldPrefix={fieldPrefix} />
        </>
      )}

      <BlockField name={`${fieldPrefix}publishedInTheLast`}>
        {({ fieldProps, value, onDataChange }) => (
          <PublishedInTheLastSelectField
            {...fieldProps}
            onChange={e => onDataChange(e.target.value)}
            value={value}
          />
        )}
      </BlockField>
    </>
  )
}

ArticlesFilterFields.propTypes = {
  disableCategoryField: PropTypes.bool,
  disableFlagFields: PropTypes.bool,
  disableSortFields: PropTypes.bool,
  disablePaginationFields: PropTypes.bool,
  fieldPrefix: PropTypes.string,
  filterCurrentArticle: PropTypes.bool,
  showFilters: PropTypes.bool,
  showOrganizationFilter: PropTypes.bool,
  siteId: PropTypes.string,
}
