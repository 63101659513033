import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import { isEmpty } from '@utils/arrays'
import { isObject } from '@utils/objects'
import { isString } from '@utils/types'

import { AutocompleteField } from '../Autocomplete'
import LocationTag from './LocationTag'

const Map = React.lazy(() => import('@ui/data-display/Map'))
const Icon = React.lazy(() => import('@ui/icons/Icon'))

const MAP_TOKEN = import.meta.env.VITE_MAP_TOKEN

export default function LocationPickerInput({
  height = 400,
  onChange,
  onResult,
  value,
  width,
  defaultZoom = 8,
  types, // address, poi, neighborhood, locality, district, place, postcode, region, country
  mapDefaultOpen = true,
}) {
  const initializedRef = React.useRef(false)
  const { t } = useTranslation()
  const { bearing, coordinates, pitch, zoom, boundingBox } = isObject(value)
    ? value
    : {}

  // Extract the place name from the value object or use the value as a string (legacy support)
  // TODO: Remove the string support when all data is migrated to the new format (object)
  const placeName = React.useMemo(
    () => (isObject(value) ? value?.placeName : isString(value) ? value : ''),
    [value]
  )

  // Extract longitude and latitude from the coordinates array (if available)
  const longitude = coordinates?.[0]
  const latitude = coordinates?.[1]

  // Set the initial states:
  // - options for the places
  const [placesOptions, setPlacesOptions] = React.useState([])
  // - map visibility
  const [mapOpen, setMapOpen] = React.useState(mapDefaultOpen)
  // - searching state
  const [searching, setSearching] = React.useState(false)
  // - viewport
  const [viewport, setViewport] = React.useState({
    boundingBox,
    bearing,
    longitude,
    latitude,
    pitch,
    zoom: zoom || defaultZoom,
  })

  const markers = React.useMemo(() => {
    if (longitude && latitude) return [{ coordinates: [longitude, latitude] }]
  }, [longitude, latitude])

  const mapStyle = React.useMemo(
    () => ({
      wrapper: { maxHeight: mapOpen ? height : 0 },
      placeholder: { height },
    }),
    [mapOpen, height]
  )

  // Handles the change of the location picker
  const handleMarkerChange = React.useCallback(
    ({ lngLat }) => {
      if (typeof onChange !== 'function') return
      const { zoom, pitch, bearing } = viewport

      onChange({
        // Keep the previous values, like placeName and name (if available)
        ...(value || {}),
        // Update marker and map values
        coordinates: [lngLat.lng, lngLat.lat],
        bearing,
        pitch,
        zoom,
      })
    },
    [onChange, viewport, value]
  )

  // Triggers the geocoding search
  const onPlaceSearch = React.useCallback(
    async value => {
      setSearching(true)
      if (!value) {
        setPlacesOptions([])
        setSearching(false)
        return
      }

      try {
        // Create the search params
        const params = new URLSearchParams({
          access_token: MAP_TOKEN,
        })
        // Add types to the search
        if (types?.length) {
          params.set('types', types.join(','))
        }

        // Fetch the places from Mapbox geocoding API (https://docs.mapbox.com/api/search/geocoding)
        const results = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${value}.json?${params.toString()}`
        )
        const { features } = await results.json()

        setPlacesOptions(features)
      } catch (error) {
        console.error('Error fetching place name', error) // eslint-disable-line no-console
      }

      setSearching(false)
    },
    [types]
  )

  // Trigger the search when the value is a string (legacy support), or it has no coordinates (migrated to the new format)
  React.useEffect(() => {
    if (initializedRef.current) return

    if (
      typeof value === 'string' || // Legacy support (delete when all data is migrated to the new format object)
      !value?.coordinates || // New format, but no coordinates
      isEmpty(value?.coordinates) // New format, but empty coordinates
    ) {
      // Trigger the search
      onPlaceSearch(typeof value === 'string' ? value : value?.placeName)
      // Flag the component as initialized
      initializedRef.current = true

      // Reset the viewport
      setViewport({})

      // If open, close the map when the value is a string to prevent seeing an empty map in Null island.
      if (mapOpen) setMapOpen(false)
    }
  }, [value, onPlaceSearch, mapOpen])

  // Chnages the place name and the viewport when the place name is selected
  const onChangePlaceName = React.useCallback(
    async feature => {
      if (typeof onChange !== 'function') return

      // If no feature is selected, clear the value
      if (!feature) {
        onChange({})
        return
      }

      const { text, place_name, center, geometry, bbox, context } = feature

      const location = {
        boundingBox: bbox, // Bounding box, useful for zooming to the area
        name: text, // Short name
        placeName: place_name, // Full name
        coordinates: geometry?.coordinates || center, // center is a fallback for the case the geometry is not available
      }

      if (context?.length) {
        const contextMap = context.reduce((acc, item) => {
          const { id, text } = item
          acc[id.split('.')[0]] = text
          return acc
        }, {})

        location.context = {
          ...(contextMap.address ? { address: contextMap.address } : {}),
          ...(contextMap.place ? { place: contextMap.place } : {}),
          ...(contextMap.region ? { region: contextMap.region } : {}),
          ...(contextMap.country ? { country: contextMap.country } : {}),
        }
      }

      onChange(location)

      setViewport({
        ...viewport,
        longitude: center[0],
        latitude: center[1],
      })
    },
    [onChange, viewport]
  )

  // Clear value, result and viewport
  const onClear = React.useCallback(() => {
    if (typeof onChange === 'function') {
      onChange(null)
    }
    if (typeof onResult === 'function') {
      onResult()
    }
    setViewport({})
  }, [onChange, onResult])

  // Toggle the map visibility
  const onMapToggle = React.useCallback(() => {
    setMapOpen(!mapOpen)
  }, [mapOpen])

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-3">
        <AutocompleteField
          className="flex-grow"
          name="search"
          displayValue={
            placeName ? (
              <LocationTag
                onClear={onClear}
                label={placeName}
                size="sm"
                hideIcon
              />
            ) : undefined
          }
          displayIcon={<Icon name="map-marker-alt" />}
          showValueInline={false}
          placeholder={
            value?.placeName
              ? `(${t('geoSearchAnotherPlaceholder')})`
              : t('geoSearchPlaceholder')
          }
          onSearch={onPlaceSearch}
          onChange={onChangePlaceName}
          loading={searching}
          loadingText={t('searching')}
          value={placeName}
        >
          {placesOptions?.map((option, index) => (
            <AutocompleteField.Option
              key={index}
              label={option.place_name}
              value={option}
            />
          ))}
        </AutocompleteField>
        <Button
          icon="map"
          onClick={onMapToggle}
          variant={mapOpen ? 'primary-light' : undefined}
          tooltip={mapOpen ? t('geoHideMap') : t('geoShowMap')}
          tooltipPlacement="top"
          tooltipSize="sm"
        />
      </div>

      <div
        className={`bg-gray-100 transition-all ease-in-out duration-300 ${mapOpen ? 'opacity-100' : 'overflow-hidden opacity-0'}`}
        style={mapStyle.wrapper}
      >
        <React.Suspense fallback={<div />}>
          {mapOpen ? (
            <Map
              boundingBox={boundingBox}
              onChange={handleMarkerChange}
              onMove={setViewport}
              markers={markers}
              showControls
              showGeolocate
              viewport={viewport}
              width={width}
              height={height}
              types={types}
            />
          ) : (
            <div style={mapStyle.placeholder} />
          )}
        </React.Suspense>
      </div>
    </div>
  )
}
LocationPickerInput.propTypes = {
  defaultZoom: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  onResult: PropTypes.func,
  mapDefaultOpen: PropTypes.bool,
  types: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
