import PropTypes from 'prop-types'
import React from 'react'

import Field from '../Field'
import Listbox from './Listbox'
import { ListboxDivider } from './ListboxDivider'
import { ListboxOption } from './ListboxOption'
import { ListboxOptions } from './ListboxOptions'

export function ListboxField({
  by,
  children,
  className,
  disabled,
  error,
  fieldExtra,
  fullWidth = true,
  help,
  label,
  labelExtra,
  lightLabel,
  name,
  onChange,
  placeholder,
  required,
  value,
  renderSelectedLabel,
  getSelectedOption,
  options,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      labelExtra={labelExtra}
      lightLabel={lightLabel}
      help={help}
      error={error}
      fieldExtra={fieldExtra}
      required={required}
      disabled={disabled}
    >
      <Listbox
        by={by}
        disabled={disabled}
        fullWidth={fullWidth}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        value={value}
        renderSelectedLabel={renderSelectedLabel}
        getSelectedOption={getSelectedOption}
        options={options}
      >
        {children}
      </Listbox>
    </Field>
  )
}

ListboxField.propTypes = {
  by: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  fieldExtra: PropTypes.node,
  fullWidth: PropTypes.bool,
  getSelectedOption: PropTypes.func,
  help: PropTypes.string,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.node,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.object,
  ]),
  renderSelectedLabel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
}

ListboxField.Options = ListboxOptions
ListboxField.Options.displayName = 'Listbox.Options'
ListboxField.Option = ListboxOption
ListboxField.Option.displayName = 'Listbox.Option'
ListboxField.Divider = ListboxDivider
ListboxField.Divider.displayName = 'Listbox.Divider'
