import React from 'react'

const SyncsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/syncs/*': { Component: SyncsRouter },
      },
    },
  }
}
