import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useSite from '@modules/web/services/hooks/useSite'
import Details from '@ui/data-display/Details'
import Box from '@ui/layout/Box'
import Text from '@ui/typography/Text'

export default function MailjetNewsletterTaskDetails({ task }) {
  const { t } = useTranslation('web/tasks')

  const {
    site: siteId,
    articleDetailPageId,
    campaignName,
    template,
    contactList: contactListId,
    sender: senderId,
    senderName,
    replyEmail,
    subject,
    flags = [],
    flagExceptions = [],
  } = task.settings ?? {}

  const { context } = task.meta
  const { contactLists, senders } = context ?? {}

  const { site } = useSite(siteId)

  const contactList = useMemo(
    () => contactLists?.find(c => c.id.toString() === contactListId),
    [contactListId, contactLists]
  )

  const sender = useMemo(
    () => senders?.find(s => s.id.toString() === senderId),
    [senderId, senders]
  )

  const includedFlags = useMemo(
    () =>
      site?.flags
        ?.filter(f => flags.includes(f.id))
        ?.map(f => f.name)
        ?.join(', '),
    [flags, site?.flags]
  )

  const excludedFlags = useMemo(
    () =>
      site?.flags
        ?.filter(f => flagExceptions.includes(f.id))
        ?.map(f => f.name)
        ?.join(', '),
    [flagExceptions, site?.flags]
  )

  return (
    <Box dividers>
      <Box>
        <Details.Item
          label={t('mailjet-newsletter-site')}
          value={<Text>{site?.title}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-articleDetailPageId')}
          value={<Text>{articleDetailPageId}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-flags')}
          value={<Text>{includedFlags?.length > 0 ? includedFlags : '-'}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-excludedFlags')}
          value={<Text>{excludedFlags?.length > 0 ? excludedFlags : '-'}</Text>}
          vertical
        />
      </Box>
      <Box>
        <Details.Item
          label={t('mailjet-newsletter-campaignName')}
          value={<Text>{campaignName}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-template')}
          value={<Text>{template}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-contactList')}
          value={
            <Text>
              {contactList?.name} ({contactList?.subscribers})
            </Text>
          }
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-sender')}
          value={
            <Text>
              {sender?.name}
              <span className="block text-sm font-normal">{sender?.email}</span>
            </Text>
          }
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-senderName')}
          value={<Text>{senderName}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-replyEmail')}
          value={<Text>{replyEmail}</Text>}
          vertical
        />
        <Details.Item
          label={t('mailjet-newsletter-subject')}
          value={<Text>{subject}</Text>}
          vertical
        />
      </Box>
    </Box>
  )
}

MailjetNewsletterTaskDetails.propTypes = {
  task: PropTypes.object,
}
