import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'

export default function FilterFields() {
  const { t } = useTranslation('publications/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      <Checkbox
        name="showFilters"
        help={t('showFiltersHelp')}
        label={t('showFilters')}
        onChange={value =>
          actions.setProp(props => (props.showFilters = value))
        }
        value={data.showFilters}
      />
      {data.showFilters && (
        <>
          <Input
            label={t('religionFilterLabel')}
            help={t('religionFilterLabelHelp')}
            name="religionFilterLabel"
            onChange={e =>
              actions.setProp(
                props => (props.religionFilterLabel = e.target.value)
              )
            }
            value={data.religionFilterLabel}
          />
          <Input
            label={t('feltNeedFilterLabel')}
            help={t('feltNeedFilterLabelHelp')}
            name="feltNeedFilterLabel"
            onChange={e =>
              actions.setProp(
                props => (props.feltNeedFilterLabel = e.target.value)
              )
            }
            value={data.feltNeedFilterLabel}
          />
          <Input
            label={t('doctrineFilterLabel')}
            help={t('doctrineFilterLabelHelp')}
            name="doctrineFilterLabel"
            onChange={e =>
              actions.setProp(
                props => (props.doctrineFilterLabel = e.target.value)
              )
            }
            value={data.doctrineFilterLabel}
          />
          <Input
            label={t('languageFilterLabel')}
            help={t('languageFilterLabelHelp')}
            name="languageFilterLabel"
            onChange={e =>
              actions.setProp(
                props => (props.languageFilterLabel = e.target.value)
              )
            }
            value={data.languageFilterLabel}
          />
          <Input
            label={t('filtersLabel')}
            help={t('filtersLabelHelp')}
            name="filtersLabel"
            onChange={e =>
              actions.setProp(props => (props.filtersLabel = e.target.value))
            }
            value={data.filtersLabel}
          />
          <Input
            label={t('filterAllLabel')}
            help={t('filterAllLabelHelp')}
            name="filterAllLabel"
            onChange={e =>
              actions.setProp(props => (props.filterAllLabel = e.target.value))
            }
            value={data.filterAllLabel}
          />
          <Input
            label={t('noResultsLabel')}
            help={t('noResultsLabelHelp')}
            name="noResultsLabel"
            value={data.noResultsLabel}
            onChange={e =>
              actions.setProp(props => (props.noResultsLabel = e.target.value))
            }
          />
        </>
      )}
    </>
  )
}
