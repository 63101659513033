import React from 'react'

import { useLocalStorage } from './useStorage'

const defaultOptions = {
  localStoreKey: '',
  enabled: false,
  minSize: 0,
  vertical: false,
  reverse: false,
  defaultSize: 0,
}

/**
 * Provides a hook to resize an element
 * @param {Object} options - The options object
 * @param {String} options.localStoreKey - The key to store the size in the local storage
 * @param {Boolean} options.enabled - Whether the resizing is enabled or not
 * @param {Number} options.minSize - The minimum width of the element
 * @param {Boolean} options.vertical - Whether to resize vertically or horizontally
 * @param {Boolean} options.reverse - Whether to reverse the resizing direction
 * @param {Number} options.defaultSize - The default size of the element
 * @returns `object` - { elementRef, dragging, onMouseDown }
 */
export default function useResizeElement(options = {}) {
  const { localStoreKey, defaultSize, enabled, minSize, vertical, reverse } =
    Object.assign({}, defaultOptions, options)

  const elementRef = React.useRef()
  const [size, setSize] = React.useState(0)
  const [separatorPosition, setSeparatorPosition] = React.useState()
  const [dragging, setDragging] = React.useState(false)

  // get existing width from the local storage
  const [storedSize, setStoredSize] = useLocalStorage(
    localStoreKey,
    defaultSize || 0
  )

  const hasLocalStoreKey =
    typeof localStoreKey === 'string' && localStoreKey.length > 0

  React.useEffect(() => {
    // if we are dragging, or we have alteady a size, don't do anything
    if (!enabled || dragging || size > 0) return

    // Otherwise, set the width from the local storage if it exists
    if (hasLocalStoreKey && storedSize) {
      setSize(storedSize < minSize ? minSize : storedSize)
      return // Stop here
    }
  }, [size, storedSize, dragging, hasLocalStoreKey, enabled, minSize])

  const onMouseDown = React.useCallback(
    e => {
      if (!enabled) return

      // Get the size of the element in pixels
      const elementSize =
        elementRef.current?.getBoundingClientRect()[
          vertical ? 'height' : 'width'
        ] || 0

      // setSize(elementSize)
      setSize(elementSize < minSize ? minSize : elementSize)

      // Start dragging
      setDragging(true)

      // Set the separator position
      const position = vertical ? e.clientY : e.clientX
      setSeparatorPosition(position)
    },
    [vertical, enabled, minSize]
  )

  const onMouseMove = React.useCallback(
    e => {
      if (dragging && size && separatorPosition) {
        const position = vertical ? e.clientY : e.clientX
        const newSize = reverse
          ? size - position + separatorPosition
          : size + position - separatorPosition

        setSeparatorPosition(position)
        setSize(newSize < minSize ? minSize : newSize)
      }
    },
    [dragging, size, separatorPosition, reverse, vertical, minSize]
  )

  const onMouseUp = React.useCallback(() => {
    setDragging(false)

    // if there is a localStoreKey store the size
    if (hasLocalStoreKey) {
      // store the size
      setStoredSize(size)
    }
  }, [size, setStoredSize, hasLocalStoreKey])

  React.useEffect(() => {
    if (!enabled) return

    document.addEventListener('mousemove', onMouseMove)
    document.addEventListener('mouseup', onMouseUp)

    return () => {
      document.removeEventListener('mousemove', onMouseMove)
      document.removeEventListener('mouseup', onMouseUp)
    }
  })

  React.useEffect(() => {
    if (elementRef.current && enabled) {
      // if there is no size, don't do anything
      if (!size || size === 0) return

      // Otherwise, get the size of the element in pixels
      const sizeInPx = `${size}px`

      // And apply the width styles to the element
      elementRef.current.style[vertical ? 'height' : 'width'] = sizeInPx
      elementRef.current.style[vertical ? 'minHeight' : 'minWidth'] = sizeInPx
    }
  }, [elementRef, size, vertical, enabled])

  return {
    elementRef,
    dragging,
    onMouseDown,
  }
}
