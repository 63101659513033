import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { useBlock } from '@modules/web/helpers/useBlock'
import Image from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'
import FormatDate from '@ui/helpers/FormatDate'

import useEvent from '../../services/hooks/useEvent'
import { EventPicker } from '../event/EventPicker'

const config = {
  name: 'EventHeader',
  label: 'events/public:eventHeader',
  type: 'plugin',
  icon: 'star',
  component: <EventHeader />,
}

function getPlaceholder() {
  return {
    image: {},
    startsAt: new Date(),
    endsAt: new Date(),
    category: '[Category]',
    location: '[Location]',
    title: '[Placeholder title]',
    description: '[Placeholder for description]',
  }
}

export default function EventHeader({ id, dynamic, showCategory }) {
  const { t } = useTranslation('events/public')
  const { event } = useEvent(dynamic ? null : id)

  const { image, startsAt, endsAt, category, location, title, description } =
    event || getPlaceholder()

  return (
    <Content icon={config.icon} title={t('eventHeader')} locked={dynamic}>
      <div className="flex flex-col p-4">
        <div className="max-w-xl space-y-6">
          {image?.file && <Image file={image.file} alt={title} width={600} />}
          <div className="space-y-1">
            <h1 className="text-3xl font-bold">{title}</h1>
            {description && <p className="text-xl">{description}</p>}
          </div>
          {showCategory && <p>{category}</p>}

          <div className="flex gap-4">
            {startsAt && (
              <span>
                <FormatDate format="P" date={startsAt} />
              </span>
            )}
            {' – '}
            {endsAt && (
              <span>
                <FormatDate format="P" date={endsAt} />
              </span>
            )}
          </div>

          <div className="flex gap-4">
            {location && <span>{location}</span>}
          </div>
        </div>
      </div>
    </Content>
  )
}
EventHeader.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  showCategory: PropTypes.bool,
}
EventHeader.defaultProps = {}
EventHeader.toolbarItem = config

function EventHeaderSettings() {
  const { t } = useTranslation('events/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)
  const design = useDesignContext()
  const { variants = [] } = useBlock(config.name)

  const onEventIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('eventHeader')} help={t('eventHeaderHelp')}>
      <div className="space-y-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            setShowPicker(!value)
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {showPicker && (
          <EventPicker onChange={onEventIdChange} value={data.id} />
        )}

        {variants.length > 0 && (
          <Select
            label={t('web/content-editor:variant')}
            onChange={e =>
              actions.setProp(value => (value.variant = e.target.value))
            }
            value={data.variant}
          >
            {data.variant && (
              <SelectOption label={t('clearSelection')} value={null} />
            )}
            <SelectPlaceholder label="-" />
            {variants.map(key => (
              <SelectOption
                value={key}
                label={t(`${design.namespace}:headerVariant-${key}`)}
                key={key}
              />
            ))}
          </Select>
        )}

        <Checkbox
          name="showCategory"
          label={t('showCategory')}
          onChange={value => {
            actions.setProp(props => (props.showCategory = value))
          }}
          value={data.showCategory}
        />
      </div>
    </SettingsWrap>
  )
}

EventHeader.craft = {
  displayName: 'EventHeader',
  props: {
    id: undefined,
    dynamic: false,
    showCategory: true,
  },
  custom: {
    type: config.type,
    resources: ['Event', 'eventHeader'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventHeaderSettings,
  },
}
