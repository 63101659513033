import React from 'react'

import { useQuery } from 'react-query'

import { useUILanguage } from '@config/i18n'
import { getFetch } from '@utils/fetcher'

function translateField(field, language, uiLanguage) {
  if (!field) return ''
  return field[language] || field[uiLanguage] || field.en || ''
}

const defaultOptions = {
  search: '',
  ids: null,
  excludedIds: [],
  types: null,
  statuses: ['active', 'disabled'],
  fields: null,
  limit: null,
  pagination: null,
  page: null,
  sort: null,
  translateFields: true,
  enabled: true,
}

/**
 * Fetches a list of categories.
 * @param {object} options - The options for the hook.
 * @param {string} options.language - The language to sort the categories in.
 * @param {string} options.search - The search term to filter the categories by.
 * @param {string[]} options.ids - The ids of specific categories to filter by.
 * @returns {object} - The hook result.
 */
export default function useCategories(options = defaultOptions) {
  const {
    enabled,
    excludedIds,
    fields,
    ids,
    keepPreviousData,
    language,
    limit,
    page,
    pagination,
    search,
    siteId,
    sort,
    statuses,
    translateFields,
    types,
  } = Object.assign({}, defaultOptions, options)

  const { language: uiLanguage } = useUILanguage()

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'categories',
      {
        language,
        search,
        ids,
        excludedIds,
        statuses,
        fields,
        limit,
        pagination,
        page,
        sort,
        enabled,
      },
    ],
    () =>
      getFetch(`/api/categories`, {
        excludedIds,
        fields,
        ids,
        language,
        limit,
        page,
        pagination,
        search,
        siteId,
        sort,
        statuses,
        types,
      }),
    {
      enabled,
      keepPreviousData,
    }
  )

  const categories = React.useMemo(() => {
    if (!data?.items) return []
    if (!translateFields) return data.items

    return data.items.map(({ title, description, ancestors, ...rest }) => ({
      ...rest,
      title: translateField(title, language, uiLanguage),
      description: translateField(description, language, uiLanguage),
      ancestors: ancestors?.map(ancestor => ({
        ...ancestor,
        title: translateField(ancestor.title, language, uiLanguage),
        description: translateField(ancestor.description, language, uiLanguage),
      })),
    }))
  }, [data?.items, language, translateFields, uiLanguage])

  return {
    categories,
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
