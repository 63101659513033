import PropTypes from 'prop-types'
import React, { useEffect, useRef } from 'react'

import { getImageUrl } from '@utils/images'

export default function SvgInline({ file, path, className = '', ...rest }) {
  const svgRef = useRef()

  useEffect(() => {
    const element = svgRef.current

    if (!element) {
      return
    }

    setInlineSvg({ element, file, path, className })
  }, [file, path, className])

  return <svg {...rest} ref={svgRef} className={className} />
}
SvgInline.propTypes = {
  as: PropTypes.string,
  file: PropTypes.object,
  path: PropTypes.string,
  className: PropTypes.string,
}

/**
 * Set SVG content to an element
 * @param {object} params - element, file, path, className
 * @param {object} params.element - target element to set the SVG content
 * @param {object} params.file - file object
 * @param {string} params.path - url/path to fetch the SVG
 * @param {string} params.className - class name to set to the element
 * @returns `Promise`
 */
async function setInlineSvg({ element, file, path, className }) {
  // Fetch SVG content
  const response = await fetch(path ?? getImageUrl(file))

  // Check if the response is an SVG mime type
  if (response.headers.get('content-type') !== 'image/svg+xml') {
    return
  }

  const svgContent = await response.text()

  // Check if the SVG content is empty
  if (!svgContent) {
    return
  }

  // Create a template element
  const template = document.createElement('template')
  template.innerHTML = svgContent

  if (element) {
    // Get the first child of the template (should be an SVG element)
    const svgElem = template.content.firstChild

    if (svgElem?.hasAttributes()) {
      // Set attributes to the target element
      const attrs = svgElem.attributes
      for (let i = attrs.length - 1; i >= 0; i--) {
        if (attrs[i].name === 'class' && !!className) {
          element.setAttribute('class', className)
        } else {
          element.setAttribute(attrs[i].name, attrs[i].value)
        }
      }
    }

    // Set SVG content to the target element
    element.innerHTML = svgElem?.innerHTML ?? ''
  }
}
