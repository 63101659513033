import { useMutation, useQueryClient } from 'react-query'

import { deleteFetch, patchFetch, postFetch } from '@utils/fetcher'

export const servicesApiUrl = '/api/entities/services'

export function useCreateService(entity) {
  const queryClient = useQueryClient()

  return useMutation(
    service => postFetch(servicesApiUrl, { ...service, entityId: entity?.id }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('entities')
      },
    }
  )
}

export function useUpdateService(service) {
  const queryClient = useQueryClient()
  const url = service ? `${servicesApiUrl}/${service.id}` : null

  return useMutation(service => patchFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useDisableService(service) {
  const queryClient = useQueryClient()
  const url = service ? `${servicesApiUrl}/${service.id}/disable` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useEnableService(service) {
  const queryClient = useQueryClient()
  const url = service ? `${servicesApiUrl}/${service.id}/enable` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useToggleService(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/${
        service.enabled ? 'disable' : 'enable'
      }`
    : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useDeleteService(service) {
  const queryClient = useQueryClient()
  const url = service ? `${servicesApiUrl}/${service.id}` : null

  return useMutation(() => deleteFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useRestoreService(service) {
  const queryClient = useQueryClient()
  const url = service ? `${servicesApiUrl}/${service.id}/restore` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useAddContactPerson(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/add-contact-persons`
    : null

  return useMutation(service => postFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useRemoveContactPerson(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/remove-contact-person`
    : null

  return useMutation(service => patchFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useDisableContactPerson(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/disable-contact-person`
    : null

  return useMutation(service => patchFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useEnableContactPerson(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/enable-contact-person`
    : null

  return useMutation(service => patchFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export function useUpdateContactPerson(service) {
  const queryClient = useQueryClient()
  const url = service
    ? `${servicesApiUrl}/${service.id}/update-contact-person`
    : null

  return useMutation(service => patchFetch(url, service), {
    onSuccess: () => {
      queryClient.invalidateQueries('entities')
    },
  })
}

export default {
  useCreateService,
  useUpdateService,
  useDisableService,
  useEnableService,
  useDeleteService,
  useRestoreService,
  useAddContactPerson,
  useRemoveContactPerson,
  useDisableContactPerson,
  useEnableContactPerson,
  useUpdateContactPerson,
}
