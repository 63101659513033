import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { escapeSpecialChars } from '@utils/strings'

export default function useCountries(
  {
    codes,
    search = '',
    statuses = ['active'],
    exceptions = [],
    fetchOnSearch = false,
    limit: limitOverride,
    page = 1,
    pagination = false,
    sort = 'name.en',
    skip = 0,
    filters = [],
  } = {},
  options = { enabled: true }
) {
  const limit = limitOverride ? limitOverride : 9999
  const { data, error, isLoading, isFetching } = useQuery(
    [
      'countries',
      {
        codes,
        search,
        statuses,
        exceptions,
        limit,
        sort,
        skip,
        pagination,
        page,
        filters,
        enabled: options.enabled,
      },
    ],
    () =>
      getFetch('/api/countries', {
        codes,
        exceptions,
        statuses: typeof statuses === 'string' ? [statuses] : statuses,
        search: escapeSpecialChars(search),
        sort,
        limit,
        skip: pagination ? null : skip,
        page,
      }),
    {
      enabled: options.enabled && (fetchOnSearch ? search !== '' : true),
      ...options,
    }
  )

  return {
    countries: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
