import { useTranslation } from 'react-i18next'

export function useRules(rules = {}) {
  const { t } = useTranslation('common')

  return {
    ...rules,
    required: rules.required
      ? {
          value: true,
          message:
            typeof required === 'string' ? rules.required : t('fieldRequired'),
        }
      : undefined,
  }
}
