import PropTypes from 'prop-types'
import React from 'react'

import { breakpointIcons } from '../BlockField'
import InlineResponsiveControls from '../InlineResponsiveControls'
import InlineResponsiveToggle from '../InlineResponsiveToggle'
import { useSidesBlockData } from '../useSidesBlockData'
import { BorderWidthSelectField } from './Field'

export function BlockBorderWidthSelect({ name = 'borderWidth', label, help }) {
  const {
    breakpoint,
    dataValues,
    hasResponsiveValues,
    isMixed,
    onChange,
    onChangeAll,
    setBreakpoint,
    setShowControls,
    showControls,
    top,
    right,
    bottom,
    left,
  } = useSidesBlockData({
    top: `${name}.t`,
    right: `${name}.r`,
    bottom: `${name}.b`,
    left: `${name}.l`,
  })

  return (
    <BorderWidthSelectField
      allFieldExtra={
        <InlineResponsiveControls
          breakpoint={breakpoint}
          dataValue={dataValues}
          icons={breakpointIcons}
          open={showControls}
          setBreakpoint={setBreakpoint}
        />
      }
      allLabelExtra={
        <InlineResponsiveToggle
          hasValues={hasResponsiveValues}
          open={showControls}
          setOpen={setShowControls}
        />
      }
      name={name}
      label={label}
      help={help}
      onChange={onChange}
      onChangeAll={onChangeAll}
      isMixed={isMixed}
      value={{
        t: top?.value,
        r: right?.value,
        b: bottom?.value,
        l: left?.value,
      }}
      inheritValue={{
        t: top?.inheritValue,
        r: right?.inheritValue,
        b: bottom?.inheritValue,
        l: left?.inheritValue,
      }}
      inheritFrom={{
        t: top?.inheritFrom,
        r: right?.inheritFrom,
        b: bottom?.inheritFrom,
        l: left?.inheritFrom,
      }}
    />
  )
}

BlockBorderWidthSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
}
