import React from 'react'

import DashboardMenu from './components/DashboardMenu'

const Router = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'dashboard',
    menu: DashboardMenu,
    routes: {
      private: {
        '/*': { Component: Router, exact: true },
      },
    },
  }
}
