import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { AspectRatioSelectField } from './Field'

export function AspectRatioSelectController({
  className = '',
  help,
  label,
  name = 'aspectRatio',
  required,
}) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <AspectRatioSelectField
          className={className}
          help={help}
          label={label}
          name={name}
          onChange={field.onChange}
          required={required}
          value={field.value}
        />
      )}
    />
  )
}
AspectRatioSelectController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}
