import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useEntityPublicData from '@modules/entities/services/hooks/useEntityPublicData'
import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteSelectedItem,
} from '@ui/data-entry/Autocomplete'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import useEntities from '../../../services/hooks/useEntities'
import EntityIcon from '../EntityIcon'
import EntityTag from '../EntityTag'

/**
 * This is controlled component used to select an entity. It requires a list of entities to be passed as a prop (`entities`), along with a callback to be called when the user selects an entity (`onChange`).
 * @param {object} props - The component props.
 * @param {boolean} props.automatedSite - Whether to include automated sites in the list or not.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the component is disabled or not.
 * @param {string[]} props.excludedIds - The IDs of the entities to exclude from the list.
 * @param {function} props.onChange - The callback to be called when the user selects an entity.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @param {string} props.placeholder - The placeholder to display in the input.
 * @param {boolean} props.publicMode - When true, it will retutn all available entities, otherwise only the allowed for the current user.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @param {string[]} props.types - The types of entities to display in the list.
 * @param {string} props.value - The value of the component.
 * @returns {React.Component} - The component.
 */
export default function EntityPickerInput({
  automatedSite,
  className = '',
  disabled,
  excludedIds,
  maxItems = 25,
  multiple,
  onChange,
  placeholder,
  publicMode,
  required,
  showValueInline,
  types,
  value,
}) {
  const { t } = useTranslation('entities/public')
  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const onSearch = React.useCallback(text => {
    setSearchTerm(text)
  }, [])

  // TODO: We need to find a way to allow filtering out automatedSites, without changing this component
  const { entities, loading: loadingEntities } = useEntities({
    search: debouncedSearchTerm || '',
    types,
    publicMode,
    automatedSite,
    fields: 'name,shortName,type',
    limit: maxItems || 25,
    excludedIds,
  })

  const entityId = typeof value === 'object' ? value?.id : value

  const { entity, loading: loadingSelectedSingle } = useEntityPublicData({
    id: entityId,
    fromParams: false,
    enabled: !multiple,
    // Exclude itself from the list of entities
  })

  const entityIds = Array.isArray(value)
    ? value.map(v => (typeof v === 'object' ? v?.id : v))
    : undefined

  const { entities: selectedEntities, loading: loadingSelectedMultiple } =
    useEntities({
      ids: entityIds,
      fields: 'name,shortName,type',
      enabled: multiple,
      publicMode,
    })

  const sortedSelectedEntities = React.useMemo(() => {
    // If there are no selected entities, return an empty array
    if (!Array.isArray(selectedEntities) || isEmpty(selectedEntities)) return []

    // Sort the selected entities by the order of the entityIds array
    return entityIds
      ? entityIds
          .map(id => selectedEntities.find(entity => entity._id === id))
          .filter(Boolean)
      : selectedEntities
  }, [selectedEntities, entityIds])

  const loading = loadingSelectedSingle || loadingSelectedMultiple

  // Call the onChange callback when the value changes, and clear the search term
  const handleOnChange = React.useCallback(
    newValue => {
      // Check if there is a value
      const hasValue = multiple
        ? Array.isArray(newValue) && newValue.length > 0
        : Boolean(newValue)

      // Clear the search term if there is a value
      if (hasValue) {
        setSearchTerm('')
      }

      // Call the onChange callback, if it exists
      if (typeof onChange === 'function') {
        onChange(newValue)
      }
    },
    [onChange, multiple]
  )

  // Clear field value when clicking on Entity Tag's clear button
  const handleClear = React.useCallback(() => {
    // Call the onChange callback, if it exists
    if (typeof onChange === 'function') {
      onChange(null)
    }
  }, [onChange])

  // Display multiple entities
  const displayMultiple = React.useCallback(
    (value, onItemRemove) => {
      if (!Array.isArray(value) || !Array.isArray(sortedSelectedEntities))
        return ''

      return sortedSelectedEntities.map(entity => (
        <AutocompleteSelectedItem
          key={entity.id}
          label={entity.name}
          onRemove={() => onItemRemove(entity.id)}
        />
      ))
    },
    [sortedSelectedEntities]
  )

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      value={value}
      displayIcon={entity ? null : <EntityIcon type={types?.[0]} />}
      displayValue={() =>
        entity ? (
          <EntityTag entity={entity} onDelete={handleClear} />
        ) : (
          searchTerm
        )
      }
      displayMultiple={multiple ? displayMultiple : undefined}
      onChange={handleOnChange}
      onSearch={onSearch}
      placeholder={placeholder || t('searchEntityPlaceholder')}
      required={required}
      loading={loading || loadingEntities}
      loadingText={t('searching')}
      showValueInline={showValueInline}
      multiple={multiple}
    >
      {entities?.map(entity => (
        <AutocompleteOption
          icon={<EntityIcon type={entity.type} />}
          key={entity.id}
          label={
            <div className="flex flex- items-center gap-2 truncate pr-2">
              {entity.name}
            </div>
          }
          sublabel={
            entity.type && (
              <span className="text-gray-400 truncate text-sm">
                {t(`type_${entity.type}`)}
              </span>
            )
          }
          value={entity.id}
        />
      ))}
    </Autocomplete>
  )
}
EntityPickerInput.propTypes = {
  automatedSite: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  excludedIds: PropTypes.arrayOf(PropTypes.string),
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  publicMode: PropTypes.bool,
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  types: PropTypes.arrayOf(PropTypes.string),
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
