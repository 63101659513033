import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Field from '@ui/data-entry/Field'

import EntityPickerInput from './Input'

/**
 * This is the field component for the EntityPicker component.
 * It wraps the EntityPickerInput component in a Field (with label, help, etc.), and adds the logic to fetch the list of entities.
 *
 * @param {object} props - The component props.
 * @param {boolean} props.automatedSite - Whether to include automated sites in the list or not.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the component is disabled or not.
 * @param {string[]} props.excludedIds - The IDs of the entities to exclude from the list.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.help - The help text to display below the component.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @param {boolean} props.multiple - Whether the field is multiple or not.
 * @param {string} props.name - The name of the component.
 * @param {function} props.onChange - The callback to be called when the user selects an entity.
 * @param {string} props.placeholder - The placeholder to display in the input.
 * @param {boolean} props.publicMode - When true, it will retutn all available entities, otherwise only the allowed for the current user.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @param {string[]} props.types - The types of entities to display in the list.
 * @param {string} props.value - The value of the component.
 * @returns {React.Component} - The component.
 */
export default function EntityPickerField({
  automatedSite,
  className,
  disabled,
  excludedIds,
  help,
  label,
  maxItems,
  multiple,
  name,
  onChange,
  placeholder,
  publicMode,
  required,
  showValueInline,
  types,
  value,
}) {
  const { t } = useTranslation('entities/public')
  return (
    <Field
      className={className}
      label={label || t('entity')}
      placeholder={t('searchPlaceholder')}
      help={help}
      required={required}
      disabled={disabled}
      name={name}
    >
      <EntityPickerInput
        automatedSite={automatedSite}
        disabled={disabled}
        help={help}
        label={label}
        maxItems={maxItems}
        multiple={multiple}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        publicMode={publicMode}
        required={required}
        types={types}
        value={value}
        showValueInline={showValueInline}
        excludedIds={excludedIds}
      />
    </Field>
  )
}
EntityPickerField.propTypes = {
  automatedSite: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  excludedIds: PropTypes.array,
  help: PropTypes.string,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  publicMode: PropTypes.bool,
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
  types: PropTypes.arrayOf(PropTypes.string),
}
