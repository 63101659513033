import PropTypes from 'prop-types'
import React from 'react'

export default function BibleVerse({ bible, passage, text }) {
  return (
    <div className="space-y-6 px-6 py-4 text-center">
      <div className="font-serif text-xl italic">{text}</div>
      <div className="space-y-1">
        {passage && <div className="text-sm">{passage}</div>}
        {bible && <div className="text-xs text-gray-500">{bible}</div>}
      </div>
    </div>
  )
}
BibleVerse.propTypes = {
  bible: PropTypes.string,
  passage: PropTypes.string,
  text: PropTypes.string,
}
