import PropTypes from 'prop-types'
import React from 'react'

import { isObject } from '@utils/objects'

import { DynamicResourceContext } from './context'

export default function DynamicResourceProvider({ children, resource }) {
  if (!resource || !isObject(resource)) return children

  return (
    <DynamicResourceContext.Provider value={resource}>
      {children}
    </DynamicResourceContext.Provider>
  )
}
DynamicResourceProvider.propTypes = {
  children: PropTypes.node,
  resource: PropTypes.object,
  resourceKey: PropTypes.string,
}
