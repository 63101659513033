import { useTranslation } from 'react-i18next'

export const textAlignments = [
  'left', // text-left
  'center', // text-center
  'right', // text-right
  'justify', // text-justify
]

export function useTextAlignOptions() {
  const { t } = useTranslation('web/content-editor')

  return textAlignments.map(value => ({
    label: t(`textAlign_${value}`),
    value,
    key: `textAlign_${value}`,
  }))
}

export function useTextAlignClass(textAlign = 'left') {
  if (!textAlignments.includes(textAlign)) {
    return 'text-left'
  }

  return `text-${textAlign}`
}
