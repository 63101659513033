import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import Button from '@ui/buttons/Button'
import ClearButton from '@ui/buttons/ClearButton'
import { Input } from '@ui/data-entry/Input'
import Popover from '@ui/feedback/Popover'
import { useDatetimeLocale } from '@ui/helpers/datetime'

import { formatDateRange, toValidRange } from '../utils'
import { DatePicker } from './DatePicker'

const defaultValue = { from: undefined, to: undefined }

export function DateRange({
  className,
  disabled,
  id,
  max,
  min,
  name,
  onChange,
  value,
}) {
  const locale = useDatetimeLocale()

  const _value = toValidRange(value ?? defaultValue)

  const [visibleDate, setVisibleDate] = useState(
    formatDateRange(_value, 'P', { locale }) ?? ''
  )

  useEffect(() => {
    setVisibleDate(formatDateRange(_value, 'P', { locale }) ?? '')
  }, [_value, locale])

  return (
    <Popover placement="bottom-start" hideArrow>
      <Popover.Trigger asChild className="w-full">
        <div className="flex flex-grow flex-row items-center justify-between">
          <Input
            autoComplete="off"
            className={`${className} pr-16`}
            id={id}
            name={name}
            type="text"
            value={visibleDate}
            disabled={disabled}
            readOnly
          />
          <div className="-ml-16 flex flex-row">
            {value && !disabled && (
              <ClearButton
                onClick={() => {
                  setVisibleDate('')
                  onChange(null)
                }}
              />
            )}
            <Button
              className="-ml-2"
              icon="calendar-alt"
              size="sm"
              variant="flat-primary"
              disabled={disabled}
            />
          </div>
        </div>
      </Popover.Trigger>
      <Popover.Content>
        <DatePicker
          date={_value}
          type="daterange"
          onChange={onChange}
          min={min}
          max={max}
        />
      </Popover.Content>
    </Popover>
  )
}
DateRange.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  max: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  min: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  name: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.shape({
    from: PropTypes.object,
    to: PropTypes.object,
  }),
}
DateRange.defaultProps = {
  className: '',
}
