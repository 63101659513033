export function getSitesUrl(params = '') {
  return `/web/sites${params}`
}

export function getListSitesUrl(params = '') {
  return getSitesUrl(`/list${params}`)
}

export function getAddSiteUrl() {
  return getSitesUrl('/add')
}

export function getDetailsSiteUrl(site, params = '') {
  return getSitesUrl(`/list/${site ? `${site.id}/details${params}` : ''}`)
}

export function getSiteUrl(site, url = '', params = '') {
  if (!site) return ''
  return getSitesUrl(
    `/${site.id}${url ? `/${url}` : ''}${params ? params : ''}`
  )
}

export function getSiteTranslationsUrl(site) {
  if (!site) return ''
  return getSiteUrl(site, 'edit?tab=translations')
}

export function getTranslateSiteUrl(site, siteTranslation, prefix = '') {
  if (!site || !siteTranslation) return ''
  return getSiteUrl(site, `${prefix}translate/${siteTranslation.id}`)
}

export function getEditSiteUrl(site) {
  return getSiteUrl(site, 'edit')
}

export function getAppearanceSiteUrl(site, params) {
  return getSiteUrl(site, 'appearance', params)
}

export function getAuthenticationSiteUrl(site, params) {
  return getSiteUrl(site, 'auth', params)
}

export function getAuthenticationSiteGroupUrl(site, group, params) {
  return getSiteUrl(site, `auth/groups/${group?._id}`, params)
}

export function getSearchSiteUrl(site, params) {
  return getSiteUrl(site, 'search', params)
}

export function getSearchIndexesSiteUrl(site, params) {
  return getSiteUrl(site, 'search/indexes', params)
}

export function getSearchIndexSiteUrl(site, searchIndex, params = '') {
  if (!searchIndex) return undefined
  return getSearchIndexesSiteUrl(site, `/${searchIndex.id}${params}`)
}

export function getDataSourcesSiteUrl(site, params) {
  return getSiteUrl(site, 'data-sources', params)
}
export function getDataSourceSiteUrl(site, dataSource, path = '', params = '') {
  if (!dataSource) return undefined
  return getDataSourcesSiteUrl(
    site,
    `/${dataSource.id}${path ? `/${path}` : ''}`,
    params
  )
}
export function getAddDataSourceSiteUrl(site) {
  return getDataSourcesSiteUrl(site, '/add')
}

export function getAnalyticsSiteUrl(site) {
  return getSiteUrl(site, 'analytics')
}

export function getRedirectsSiteUrl(site) {
  return getSiteUrl(site, 'redirects')
}

export function getAddRedirectsSiteUrl(site) {
  return getSiteUrl(site, 'redirects/add')
}

export function getDetailsRedirectsSiteUrl(site, redirect) {
  return getSiteUrl(site, `redirects/${redirect.id}/details`)
}

export function getEditRedirectsSiteUrl(site, redirect) {
  return getSiteUrl(site, `redirects/${redirect.id}/edit`)
}

// Menus
export function getMenusUrl(site) {
  return getSiteUrl(site, 'menus')
}

export function getDetailsMenuUrl(site, menu) {
  return getSiteUrl(site, `menus/${menu.id}/details`)
}

export function getEditMenuUrl(menu) {
  if (!menu) return ''
  return getSiteUrl({ id: menu.site }, `menus/${menu.id}/edit`)
}

export function getCloneUrl(site) {
  return getSitesUrl(`/clone${site ? `?siteId=${site.id}` : ''}`)
}

export function getCloneSuccessUrl() {
  return getSitesUrl(`/clone/success`)
}

export function getMenuTranslationsUrl(menu) {
  if (!menu) return ''
  return getSiteUrl({ id: menu.site }, `menus/${menu.id}/edit?tab=translations`)
}

export function getTranslateMenuUrl(menu, transitionMenu) {
  if (!menu || !transitionMenu) return ''
  return getSiteUrl(
    { id: menu.site },
    `menus/${menu.id}/translate/${transitionMenu.id}`
  )
}

export function getEditDataMenuUrl(site, menu) {
  return getSiteUrl(site, `menus/${menu.id}/edit-data`)
}

export function getAddMenuUrl(site) {
  return getSiteUrl(site, 'menus/add')
}

// Pages
export function getPagesUrl(site) {
  return getSiteUrl(site, 'pages')
}

export function getPageUrl(site, page) {
  if (!site || !page) return ''
  return getSiteUrl(site, `pages/${page.id}/details`)
}

export function getEditPageUrl(site, page) {
  if (!site || !page) return ''
  return getSiteUrl(site, `pages/${page.id}/edit`)
}

export function getPageTranslationsUrl(site, page) {
  if (!site || !page) return ''
  return getSiteUrl(site, `pages/${page.id}/translations`)
}

export function getTranslatePageUrl(site, sourcePage, page) {
  if (!site || !sourcePage || !page) return ''
  return getSiteUrl(site, `pages/${sourcePage.id}/translate/${page.id}`)
}

export function getSelectPagePresetUrl(site, page) {
  if (!site || !page) return ''
  return getSiteUrl(site, `pages/${page?.id}/select-preset`)
}

export default function siteUrls(site, page) {
  return getSiteUrl(site, page)
}
