import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * Field required indicator component
 * @param {Object} props - Component props
 * @param {String} props.className - Custom class name
 * @returns
 */
export function FieldRequired({ className }) {
  return (
    <span className={`text-xs text-danger-500 ${className}`}>
      <Icon name="asterisk" />
    </span>
  )
}
FieldRequired.propTypes = {
  className: PropTypes.string,
}
FieldRequired.defaultProps = {
  className: '',
}
