import React from 'react'

import SermonsMenuGroup from './components/SermonsMenuGroup'

const Router = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'sermons',
    menu: SermonsMenuGroup,
    routes: {
      private: {
        '/sermons/*': { Component: Router },
      },
    },
    permissions: {
      sermons: {
        name: 'sermons',
        icon: 'person-chalkboard',
        label: 'sermons/public:sermons',
        models: [
          {
            name: 'sermons',
            label: 'sermons/public:sermons',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
  }
}
