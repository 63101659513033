import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

import { useSermonsPermissions } from '../helpers/permissions'
import { getSermonsUrl } from '../helpers/urls'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))

export default function SermonsMenuGroup() {
  const { t } = useTranslation('sermons/public')
  const { pathname } = useLocation()
  const { entity } = useCurrentEntity()

  const { canReadSermons } = useSermonsPermissions()

  if (!entity || !canReadSermons) return null

  const inSermons = pathname.startsWith('/sermons')

  return (
    <MenuGroup
      name="sermons"
      label={t('sermons')}
      icon="person-chalkboard"
      href={getSermonsUrl()}
      active={inSermons}
    />
  )
}
