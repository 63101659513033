import { useState } from 'react'

export function useResponsiveField(
  defaultShowControls = false,
  defaultBreakpoint = 'xs'
) {
  const [showControls, setShowControls] = useState(defaultShowControls)
  const [breakpoint, setBreakpoint] = useState(defaultBreakpoint)

  return {
    showControls,
    setShowControls,
    breakpoint,
    setBreakpoint,
  }
}
