import PropTypes from 'prop-types'
import React from 'react'

import { getImageUrl } from '@utils/images'

export default function PublicationFeaturedItem({ publication, showImage }) {
  const imageUrl = showImage
    ? getImageUrl(publication.cover?.file, 'w:500')
    : null

  return (
    <div
      className="relative flex min-h-[250px] flex-col rounded-md border border-gray-100 bg-primary-400 bg-cover bg-center shadow-md"
      style={showImage ? { backgroundImage: `url(${imageUrl})` } : undefined}
    >
      <div className="flex flex-grow flex-col justify-center space-y-0 rounded-md bg-gradient-to-b from-transparent via-gray-900/40 to-gray-900/80 p-6 text-white">
        <div>
          <h3 className="text-xl font-bold">
            <a href={`#publication-${publication.id}`}>{publication.title}</a>
          </h3>
        </div>
      </div>
    </div>
  )
}
PublicationFeaturedItem.propTypes = {
  publication: PropTypes.object.isRequired,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
}
