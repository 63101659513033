import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

export function usePersonsPermissions() {
  const canReadPersons = useIsUserAuthorized({
    module: 'persons',
    permission: 'read',
  })
  const canCreatePersons = useIsUserAuthorized({
    module: 'persons',
    permission: 'create',
  })
  const canUpdatePersons = useIsUserAuthorized({
    module: 'persons',
    permission: 'update',
  })
  const canDeletePersons = useIsUserAuthorized({
    module: 'persons',
    permission: 'delete',
  })

  return {
    canReadPersons,
    canCreatePersons,
    canUpdatePersons,
    canDeletePersons,
  }
}
