import React from 'react'

import ArticlesFeedFilters from './components/FeedFilters'
import ArticlesFeedFiltersTranslation from './components/FeedFiltersTranslation'
import ArticlesMenuGroup from './components/MenuGroup'
import ArticlePicker from './components/Picker'
import ArticleArchive from './components/blocks/Archive'
import ArticleFilters from './components/blocks/ArticleFilters'
import ArticleImage from './components/blocks/ArticleImage'
import ArticleList from './components/blocks/ArticleList'
import ArticleMap from './components/blocks/ArticleMap'
import ArticlesShowcase from './components/blocks/ArticlesShowcase'
import CategorizedArticles from './components/blocks/CategorizedArticles'
import ArticleDetail from './components/blocks/Detail'
import ArticleFlagsList from './components/blocks/FlagsList'
import ArticleHeader from './components/blocks/Header'
import LatestArticles from './components/blocks/LatestArticles'
import RelatedArticles from './components/blocks/RelatedArticles'
import ArticleRepeater from './components/blocks/Repeater'
import ArticleTeaser from './components/blocks/Teaser'
import translatableBlocks from './components/blocks/translatableBlocks'
import LatestArticlesWidget from './components/dashboard/Latest'
import ArticlesStatsWidget from './components/dashboard/Stats'
import aclImportTask from './tasks/acl-import'
import wordpressImport from './tasks/wordpress-import'

const ArticlesRouter = React.lazy(() => import('./pages/Router'))
const ArticlesPermissions = React.lazy(() => import('./components/Permissions'))
const ArticlesDataSourceSettings = React.lazy(
  () => import('./components/data-sources/ArticlesDataSourceSettings')
)
const ArticlesIndexSettings = React.lazy(
  () => import('./components/search/ArticlesIndexSettings')
)

const commonArticleFields = {
  'title': {
    label: 'articles/public:title',
    type: 'string',
  },
  'subtitle': {
    label: 'articles/public:subtitle',
    type: 'string',
  },
  'abstract': {
    label: 'articles/public:abstract',
    type: 'string',
  },
  'image.caption': {
    label: 'articles/public:imageCaption',
    type: 'string',
  },
  'image.file': {
    label: 'articles/public:image',
    type: 'image',
  },
  'author': {
    label: 'articles/public:author',
    type: 'string',
  },
  'location': {
    label: 'articles/public:location',
    type: 'string',
  },
  'tags': {
    label: 'articles/public:tags',
    type: 'array',
  },
  'publishedAt': {
    label: 'articles/public:publishedAt',
    type: 'date',
  },
}

export default function register() {
  return {
    name: 'articles',
    menu: ArticlesMenuGroup,
    routes: {
      private: {
        '/articles/*': { Component: ArticlesRouter },
      },
    },
    dashboards: [
      {
        name: 'articles',
        label: 'articles/public:articles',
        icon: 'file-invoice',
        items: [ArticlesStatsWidget, LatestArticlesWidget],
        permissions: 'articles:read',
      },
    ],
    permissions: {
      articles: {
        name: 'articles',
        icon: 'file-invoice',
        label: 'articles/public:articles',
        PermissionsComponent: ArticlesPermissions,
        models: [
          {
            name: 'articles',
          },
        ],
      },
    },
    dataSources: {
      articles: {
        label: 'articles/public:articles',
        icon: 'file-invoice',
        settings: ArticlesDataSourceSettings,
      },
    },
    search: {
      indexes: {
        articles: {
          icon: 'file-invoice',
          label: 'articles/public:articlesSearchIndex',
          settings: ArticlesIndexSettings,
        },
      },
    },
    blocksResolver: {
      ArticleTeaser,
      ArticleDetail,
      ArticleHeader,
      ArticleList,
      ArticleImage,
      ArticleMap,
      ArticleFilters,
      ArticlesShowcase,
      ArticleRepeater,
      ArticleFlagsList,
      ArticleArchive,
      CategorizedArticles,
      LatestArticles,
      RelatedArticles,
    },
    blocksToolbars: [
      {
        name: 'articles',
        label: 'articles/public:articles',
        items: [
          ArticleDetail,
          ArticleHeader,
          ArticleList,
          ArticlesShowcase,
          ArticleFilters,
          ArticleRepeater,
          ArticleFlagsList,
          ArticleArchive,
          ArticleImage,
          ArticleMap,
          ArticleTeaser,
          LatestArticles,
          CategorizedArticles,
          RelatedArticles,
        ],
      },
    ],
    translatableBlocks,
    resources: {
      articles: {
        type: 'list',
        name: 'articles',
        itemName: 'article',
        label: 'articles/public:articles',
        labelSingular: 'articles/public:article',
        fields: {
          ...commonArticleFields,
          url: {
            label: 'articles/public:url',
            type: 'url',
          },
        },
      },
      Article: {
        type: 'model',
        name: 'Article',
        label: 'articles/public:articles',
        labelSingular: 'articles/public:article',
        ResourcePicker: ArticlePicker,
        fields: {
          ...commonArticleFields,
          body: {
            label: 'articles/public:body',
            type: 'richtext',
          },
        },
      },
      year: {
        type: 'param',
        name: 'year',
        label: 'articles/public:articleParam-year',
      },
      month: {
        type: 'param',
        name: 'month',
        label: 'articles/public:articleParam-month',
      },
      day: {
        type: 'param',
        name: 'day',
        label: 'articles/public:articleParam-day',
      },
      flag: {
        type: 'param',
        name: 'flag',
        label: 'articles/public:articleParam-flag',
      },
      articlesFeed: {
        type: 'xml',
        label: 'articles/public:articlesFeed',
        name: 'articlesFeed',
        /**
         * @deprecated use type: 'xml' instead
         */
        xml: true,
        ResourceFilters: ArticlesFeedFilters,
        ResourceFilterTranslations: ArticlesFeedFiltersTranslation,
      },
    },
    tasks: {
      'acl-import': aclImportTask,
      'wordpress-import': wordpressImport,
    },
  }
}
