import PropTypes from 'prop-types'
import React, { useCallback, useRef, useState } from 'react'

import { ModalContext } from './hooks'

export default function ModalProvider({ children, initialOpen }) {
  const [open, setOpen] = useState(initialOpen)
  const onClose = useCallback(() => setOpen(false), [])
  const onOpen = useCallback(() => setOpen(true), [])
  const focusRef = useRef(null)

  return (
    <ModalContext.Provider value={{ open, onClose, onOpen, focusRef }}>
      {children}
    </ModalContext.Provider>
  )
}
ModalProvider.propTypes = {
  children: PropTypes.node,
  initialOpen: PropTypes.bool,
}
ModalProvider.defaultProps = {
  initialOpen: false,
}
