import React, { useContext, useMemo, useState } from 'react'

import {
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react'

// DialogContext is a React context that provides the state and interactions for a Dialog.
export const DialogContext = React.createContext(null)

/**
 * useDialogContext is a hook that returns the Dialog context.
 * @returns {Object} - The Dialog context.
 */
export function useDialogContext() {
  const context = useContext(DialogContext)

  if (context == null) {
    throw new Error('Dialog components must be wrapped in <Dialog />')
  }

  return context
}

/**
 * useDialog is a hook that provides the state and interactions for a Dialog.
 * @param {Object} params - The options for the Dialog.
 * @param {boolean} params.initialOpen - If true, the Dialog will be open by default.
 * @param {boolean} params.open - If true, the Dialog will be open.
 * @param {function} params.onOpenChange - A function that will be called when the Dialog is opened or closed.
 * @returns {Object} - An object containing the Dialog state and interactions.
 */
export function useDialog({
  initialOpen = false,
  open: controlledOpen,
  onOpenChange: setControlledOpen,
}) {
  const [uncontrolledOpen, setUncontrolledOpen] = useState(initialOpen)
  const [labelId, setLabelId] = useState()
  const [descriptionId, setDescriptionId] = useState()

  const open = controlledOpen ?? uncontrolledOpen
  const setOpen = setControlledOpen ?? setUncontrolledOpen

  const data = useFloating({
    open,
    onOpenChange: setOpen,
  })

  const context = data.context

  const click = useClick(context, {
    enabled: controlledOpen == null,
  })
  const dismiss = useDismiss(context, { outsidePressEvent: 'mousedown' })
  const role = useRole(context)

  const interactions = useInteractions([click, dismiss, role])

  return useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...data,
      labelId,
      descriptionId,
      setLabelId,
      setDescriptionId,
    }),
    [open, setOpen, interactions, data, labelId, descriptionId]
  )
}
