import React from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'
import { Controller } from 'react-hook-form'

import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import useMenus from '../../services/hooks/useMenus'

export function MenuPicker({
  className,
  help,
  label,
  name,
  onChange,
  required,
  value,
}) {
  const { t } = useTranslation('menus-public')
  const { menus } = useMenus()

  return (
    <div className={`space-y-4 ${className}`}>
      <Select
        label={label || t('menu')}
        help={help}
        name={name}
        value={value}
        onChange={onChange}
        required={required}
      >
        {value && <SelectOption value={null} label={t('clearSelection')} />}
        <SelectPlaceholder label="-" />
        {menus?.map(menu => (
          <SelectOption key={menu.id} label={menu.title} value={menu.id} />
        ))}
      </Select>
    </div>
  )
}
MenuPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.string,
}
MenuPicker.defaultProps = {
  className: '',
}

export default function MenuPickerController({
  className,
  help,
  label,
  name,
  required,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <MenuPicker
          className={className}
          help={help}
          label={label}
          name={name}
          required={required}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
MenuPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
MenuPickerController.defaultProps = {
  className: '',
}
