import PropTypes from 'prop-types'
import React from 'react'

import isEmpty from 'lodash/isEmpty'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import { PlaceholderImage } from '@ui/data-display/Image'
import { getImageUrl } from '@utils/images'

import Address from './Address'
import Amenities from './Amenities'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function ChurchItem({
  church,
  onLocate,
  detailPage,
  showAmenities,
}) {
  const { t } = useTranslation('entities/public')
  if (!church || typeof church !== 'object') return null

  const { address, id, image, name, siteURL, amenities } = church || {}

  const imageUrl = image ? getImageUrl(image, 'w:500') : null

  const directionURL = getGoogleMapAddressUrl(address)

  return (
    <div key={id} className="flex flex-row justify-between gap-4 py-3">
      <div
        className="h-32 w-48 shrink-0 rounded-lg bg-cover bg-center"
        style={imageUrl ? { backgroundImage: `url(${imageUrl})` } : undefined}
        title={name}
      >
        {!image && <PlaceholderImage className="h-full rounded-lg" />}
      </div>
      <div className="grow space-y-4">
        <div className="space-y-2">
          <h3
            className={`text-lg font-bold ${
              detailPage ? 'text-primary-600 hover:underline' : 'text-gray-700'
            }`}
          >
            {name}
          </h3>
          {!isEmpty(address) && <Address address={address} />}
        </div>
        {showAmenities && <Amenities amenities={amenities} />}
        <div className="flex flex-row space-x-2">
          {typeof onLocate === 'function' && (
            <Button
              icon="map-marker-alt"
              size="xs"
              label={t('locateChurch')}
              onClick={() => onLocate(church)}
            />
          )}
          {directionURL && (
            <Button
              icon="route"
              size="xs"
              label={t('getChurchDirections')}
              href={directionURL}
            />
          )}
          {siteURL && (
            <Button
              icon="external-link-square"
              size="xs"
              label={t('visitChurchSite')}
              href={siteURL}
            />
          )}
        </div>
      </div>
      {detailPage && (
        <div className="flex items-center justify-center p-4">
          <Icon name="chevron-right" className="text-xl text-primary-500" />
        </div>
      )}
    </div>
  )
}
ChurchItem.propTypes = {
  church: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    image: PropTypes.object,
    siteURL: PropTypes.string,
    address: PropTypes.shape({
      street: PropTypes.string,
      additionalAddress: PropTypes.string,
      city: PropTypes.string,
      zip: PropTypes.string,
    }),
  }),
  onLocate: PropTypes.func,
  detailPage: PropTypes.string,
  showAmenities: PropTypes.bool,
}

function getGoogleMapAddressUrl(address) {
  if (typeof address !== 'object' || isEmpty(address)) return null

  const { street, zip, city } = address
  const place = `${street},${zip} ${city}`

  return `https://www.google.com/maps/place/${encodeURI(place)}`
}
