import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

import { entitiesApiUrl } from '../EntityServices'

export default function useEntities({
  enabled = true,
  entityId,
  search = '',
  limit = 10,
  page = 1,
  fields = '',
  filters = {},
  ids,
  types = [],
  fetchOnSearch = false,
  statuses = ['active'],
  sort = 'name',
  skip,
  automatedSite,
  excludedIds,
  publicMode,
} = {}) {
  const queryFilter = {}

  // search
  if (search) {
    queryFilter.name = {
      $regex: escapeSpecialChars(search),
      $options: 'i',
    }
  }

  // parent
  if (entityId) {
    queryFilter.parent = entityId
  }

  if (Array.isArray(ids)) {
    queryFilter._id = { $in: ids }
  }

  // status
  if (isEmpty(statuses)) {
    queryFilter.deleted = false
  } else {
    const status = []
    if (statuses.includes('active')) {
      status.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      status.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      status.push({ deleted: true })
    }
    queryFilter['$or'] = status
  }

  // types
  if (!isEmpty(types)) {
    queryFilter.type = { $in: types }
  }

  // location
  if (filters.location) {
    queryFilter.location = filters.location
  }

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'entities',
      {
        automatedSite,
        entityId,
        ids,
        search,
        limit,
        page,
        fields,
        filters,
        sort,
        skip,
        types,
        statuses,
        excludedIds,
        publicMode,
      },
    ],
    () =>
      getFetch(entitiesApiUrl, {
        filter: queryFilter,
        sort,
        limit,
        skip,
        page,
        fields,
        populateTemplateSites: automatedSite,
        excludedIds,
        publicMode,
      }),
    { enabled: fetchOnSearch ? search !== '' : enabled }
  )

  return {
    entities: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
