import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'
import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const typeStyles = {
  default: 'text-gray-700 bg-gray-200 borde-gray-300',
  white: 'text-gray-700 bg-white borde-gray-300',
  black: 'text-gray-100 bg-black borde-gray-700',
  success: 'text-success-700 bg-success-200 borde-success-300',
  info: 'text-info-700 bg-info-200 borde-info-300',
  warn: 'text-warn-700 bg-warn-200 borde-warn-300',
  danger: 'text-danger-700 bg-danger-200 borde-danger-300',
  primary: 'text-primary-700 bg-primary-200 borde-primary-300',
}

export function Tag({ image, label, onClick, selected, showRemove, type }) {
  const typeClasses = typeStyles[type] || typeStyles.default

  return (
    <div
      className={`rounded p-[2px] ring-0 ${
        selected ? 'animate-wiggle ring-2 ring-danger-500' : ''
      }`}
    >
      <Clickable
        className={`flex flex-row items-center space-x-2 rounded border px-2 py-1 ${typeClasses}`}
        onClick={onClick}
      >
        {image && <Image alt={label} src={image} />}
        <div className="whitespace-nowrap text-sm font-semibold">{label}</div>
        {showRemove && <Icon name="times" />}
      </Clickable>
    </div>
  )
}
Tag.propTypes = {
  image: PropTypes.string,
  label: PropTypes.node,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
  shaking: PropTypes.bool,
  showRemove: PropTypes.bool,
  type: PropTypes.oneOf([
    'default',
    'success',
    'info',
    'warn',
    'danger',
    'primary',
    'black',
    'white',
  ]),
}
Tag.defaultProps = {
  type: 'default',
}
