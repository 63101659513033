import React from 'react'
import PropTypes from 'prop-types'

import {
  DragDropContext,
  Droppable as RBDNDDroppable,
  Draggable as RBDNDDraggable,
} from 'react-beautiful-dnd'

import { isFunction } from '@utils/types'

// simple wrap used when dnd is disabled
function Wrap({ children, className, getClass }) {
  const wrapClass = isFunction(getClass) ? getClass() : null
  return <div className={`${wrapClass} ${className}`}>{children}</div>
}
Wrap.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  getClass: PropTypes.func,
}
Wrap.defaultProps = {
  className: '',
}

// An item that can be dragged arround
function Draggable({ cloneMode, children, id, index, getClass, ...others }) {
  return (
    <RBDNDDraggable draggableId={id} key={index} index={index} {...others}>
      {(provided, snapshot) => {
        const { isDragging } = snapshot
        const className = isFunction(getClass) ? getClass({ isDragging }) : null

        return (
          <>
            <div
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}
              style={provided.draggableProps.style}
              className={className}
            >
              {children}
            </div>
            {cloneMode && isDragging ? (
              <div className={`${className} styles.clone`}>{children}</div>
            ) : null}
          </>
        )
      }}
    </RBDNDDraggable>
  )
}

Draggable.propTypes = {
  cloneMode: PropTypes.bool,
  children: PropTypes.node,
  id: PropTypes.string,
  index: PropTypes.number,
  getClass: PropTypes.func,
}
Draggable.defaultProps = {
  cloneMode: false,
}

// An element container that can recieve dragged items
function Droppable({ children, className, id, getClass, ...others }) {
  return (
    <RBDNDDroppable droppableId={id} {...others}>
      {(provided, { isDraggingOver }) => {
        const droppableClass = isFunction(getClass)
          ? getClass({
              droppable: true,
              isDraggingOver,
            })
          : null

        return (
          <div
            className={`${droppableClass} ${className}`}
            ref={provided.innerRef}
          >
            {children}
            {provided.placeholder}
          </div>
        )
      }}
    </RBDNDDroppable>
  )
}
Droppable.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  id: PropTypes.string,
  getClass: PropTypes.func,
}
Droppable.defaultProps = {
  className: '',
}

// TODO: come back and refactor this with nested DragDropContexts when the following issue is solved: https://github.com/atlassian/react-beautiful-dnd/issues/302
function DragAndDrop({ children, enable, onDragEnd }) {
  return enable ? (
    <DragDropContext onDragEnd={onDragEnd}>
      {children({
        Draggable: Draggable,
        Droppable: Droppable,
      })}
    </DragDropContext>
  ) : (
    children({
      Draggable: Wrap,
      Droppable: Wrap,
    })
  )
}

DragAndDrop.propTypes = {
  children: PropTypes.func,
  enable: PropTypes.bool,
  onDragStart: PropTypes.func,
  onDragEnd: PropTypes.func,
}

export default DragAndDrop
