import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import InheritSelectPlaceholder from '../InheritSelectPlaceholder'

export function SideSelectField({
  className,
  extra,
  fieldExtra,
  help,
  inheritFrom,
  inheritValue,
  isMixed,
  label,
  labelExtra,
  labelKey,
  name,
  onChange,
  options,
  value,
}) {
  const { t } = useTranslation('web/content-editor')

  return (
    <SelectField
      className={className}
      extra={extra}
      fieldExtra={fieldExtra}
      help={help}
      label={label}
      labelExtra={labelExtra}
      name={name}
      onChange={onChange}
      value={value}
    >
      {!isMixed && (
        <InheritSelectPlaceholder
          inheritFrom={inheritFrom}
          inheritValue={inheritValue}
          labelKey={labelKey}
          value={value}
        />
      )}
      {isMixed && (
        <SelectPlaceholder label={t('mixed')} value="mixed" selected />
      )}
      {!isMixed && value && !inheritFrom && (
        <SelectOption label={t('clearSelection')} value={null} />
      )}
      <SelectPlaceholder label="-" value="-" />
      {options.map(({ value, label }) => {
        if (value === 'mixed') return null
        return <SelectOption value={value} label={label} key={value} />
      })}
    </SelectField>
  )
}
SideSelectField.propTypes = {
  className: PropTypes.string,
  extra: PropTypes.node,
  fieldExtra: PropTypes.node,
  help: PropTypes.string,
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  isMixed: PropTypes.bool,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({ value: PropTypes.string, label: PropTypes.string })
  ),
  value: PropTypes.string,
}
