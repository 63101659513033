import React from 'react'
import PropTypes from 'prop-types'
import Field from '@ui/data-entry/Field'
import { MultipleMenuPicker } from './MultipleMenuPicker'

export function MultipleMenuPickerField({
  name,
  className,
  label,
  help,
  error,
  required,
  disabled,
  value,
  onChange,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      help={help}
      error={error}
      required={required}
      disabled={disabled}
    >
      <MultipleMenuPicker name={name} value={value} onChange={onChange} />
    </Field>
  )
}

MultipleMenuPickerField.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
  error: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
}
