import { isObject } from 'lodash'

export function getDynamicResourceIdentifier(page) {
  if (page?.dynamicResource) {
    return isObject(page?.dynamicResource)
      ? page?.dynamicResource?.name
      : page?.dynamicResource
  }
  return null
}

export function dynamicResourceFromString(string) {
  const [type, name, value] = string.split('.')

  return { type, name, value }
}
