import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { SelectPlaceholder } from '@ui/data-entry/Select'

export default function InheritSelectPlaceholder({
  labelKey,
  customLabel,
  inheritValue,
  inheritFrom,
  value,
}) {
  const { t } = useTranslation('web/content-editor')

  if ((value && !inheritValue) || (!value && !inheritValue)) return null

  return (
    <SelectPlaceholder
      label={`${customLabel || t(`${labelKey}_${inheritValue ?? value}`)}${
        inheritValue || inheritFrom
          ? ` (${t('inheritFrom')} ${t(`breakpointDevice_${inheritFrom}`)})`
          : ''
      }`}
    />
  )
}
InheritSelectPlaceholder.propTypes = {
  labelKey: PropTypes.string,
  customLabel: PropTypes.string,
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
