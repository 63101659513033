import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'ScheduleDay',
  label: 'media-library/public:ScheduleDay',
  type: 'plugin',
  icon: 'calendar-day',
  component: <ScheduleDay />,
}

export default function ScheduleDay({ channelId }) {
  const { t } = useTranslation('media-library/public')
  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)

  const _channel = resources?.Channel ? resources.Channel : channel

  return (
    <Content icon={config.icon} title={config.name}>
      <Box space="xs">
        <Heading as="h6" className="ml-2" text={t('channel')} />
        <ul className="ml-8 list-disc text-sm">
          <li>
            {t('channel')}: {_channel ? _channel.title : t('none')}
          </li>
        </ul>
      </Box>
    </Content>
  )
}
ScheduleDay.propTypes = {
  channelId: PropTypes.string,
}
ScheduleDay.toolbarItem = config

function ScheduleDaySettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('ScheduleDay')} help={t('ScheduleDayHelp')}>
      <Box space="xl">
        <ChannelSelect
          label={t('channel')}
          onChange={onChannelUpdate('channelId')}
          value={data.channelId || resources?.Channel?.id}
          controlled={false}
        />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
        <PagePicker
          label={t('weekSchedulePageId')}
          help={t('weekSchedulePageIdHelp')}
          value={data.weekSchedulePageId}
          onChange={pageId =>
            actions.setProp(props => (props.weekSchedulePageId = pageId))
          }
        />
      </Box>
    </SettingsWrap>
  )
}

ScheduleDay.craft = {
  displayName: 'ScheduleDay',
  props: {
    channelId: undefined,
    episodeDetailPageId: undefined,
    weekSchedulePageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['channel', 'broadcast'],
    i18nNamespaces: ['media-library'],
    clientOnly: true,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ScheduleDaySettings,
  },
}
