import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CourseContent',
  label: 'courses/public:courseContent',
  help: 'courses/public:courseContentHelp',
  type: 'plugin',
  icon: 'file-alt',
  component: <CourseContent />,
}

export default function CourseContent({
  dynamic,
  showTitle,
  showContent,
  showPagination,
}) {
  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="max-w-xl space-y-6 p-4">
        {showTitle && <h1 className="text-3xl font-bold">[Content title]</h1>}
        {showContent && (
          <div className="space-y-2">
            <div className="h-3 w-1/2 bg-gray-200" />
            <div className="h-3 w-2/3 bg-gray-200" />
            <div className="h-3 w-3/4 bg-gray-200" />
            <div className="h-3 w-2/3 bg-gray-200" />
            <div className="h-3 w-4/5 bg-gray-200" />
            <div className="h-3 w-2/3 bg-gray-200" />
            <div className="h-3 w-1/2 bg-gray-200" />
            <div className="h-3 w-3/5 bg-gray-200" />
          </div>
        )}
        {showPagination && (
          <div className="flex justify-between items-center">
            <div className="flex items-center gap-1 text-gray-600">
              <Icon name="chevron-left" />
              <div>back</div>
            </div>
            <div className="flex items-center gap-1 text-gray-600">
              <div>next</div>
              <Icon name="chevron-right" />
            </div>
          </div>
        )}
      </div>
    </Content>
  )
}
CourseContent.propTypes = {
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  showTitle: PropTypes.bool,
  showContent: PropTypes.bool,
  showPagination: PropTypes.bool,
}
CourseContent.toolbarItem = config

function CourseContentSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course ? (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        ) : (
          <>
            <Box space="xs">
              <Checkbox
                label={t('title')}
                onChange={value => {
                  actions.setProp(props => (props.showTitle = value))
                }}
                value={data.showTitle}
                name="showTitle"
              />
              <Checkbox
                label={t('content')}
                onChange={value => {
                  actions.setProp(props => (props.showContent = value))
                }}
                value={data.showContent}
                name="showContent"
              />
              <Checkbox
                label={t('pagination')}
                onChange={value => {
                  actions.setProp(props => (props.showPagination = value))
                }}
                value={data.showPagination}
                name="showPagination"
              />
            </Box>
            <PagePicker
              label={t('lessonDetailPageId')}
              help={t('lessonDetailPageIdHelp')}
              value={data.lessonDetailPageId}
              onChange={pageId =>
                actions.setProp(props => (props.lessonDetailPageId = pageId))
              }
              condition={page =>
                page.dynamic &&
                getDynamicResourceIdentifier(page) === 'CourseLesson'
              }
            />
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseContent.craft = {
  displayName: config.name,
  props: {
    showTitle: true,
    showContent: true,
    showPagination: true,
    lessonDetailPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['courseContent', 'coursePagination'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseContentSettings,
  },
}
