import PropTypes from 'prop-types'
import React from 'react'

const typesStyles = {
  danger: 'bg-danger-600 text-danger-100',
  info: 'bg-info-500 text-info-100',
  success: 'bg-success-600 text-success-100',
  warn: 'bg-warn-500 text-warn-50',
}

const alignmentsStyles = {
  'top-end': 'top-0 end-0 -translate-y-1/2 translate-x-1/2',
  'bottom-end': 'bottom-0 end-0 translate-y-1/2 translate-x-1/2',
}

export default function Badge({
  align,
  children,
  count,
  dot,
  max,
  showZero,
  text,
  variant,
}) {
  const value = text ? text : max > 0 && count > max ? `${max}+` : count

  const typeClass = typesStyles[variant] ?? typesStyles.danger
  const innerClass = dot ? 'h-2 w-2 rounded-full' : 'px-2 text-xs rounded-xl'

  const positionClass = children
    ? `absolute transform ${alignmentsStyles[align]}`
    : ''

  return (
    <span className="relative inline-block">
      {children}
      {(value || showZero || dot) && (
        <span className={`block ${innerClass} ${typeClass} ${positionClass}`}>
          {!dot && <span className="font-semibold">{value}</span>}
        </span>
      )}
    </span>
  )
}

Badge.propTypes = {
  align: PropTypes.oneOf(['top-end', 'bottom-end']),
  children: PropTypes.node,
  count: PropTypes.number,
  dot: PropTypes.bool,
  max: PropTypes.number,
  showZero: PropTypes.bool,
  text: PropTypes.string,
  variant: PropTypes.oneOf(['danger', 'info', 'success', 'warn']),
}
Badge.defaultProps = {
  align: 'top-end',
  count: 0,
  max: 100,
  type: 'danger',
}
