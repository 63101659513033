import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'

import { SideSelectField, Sides } from '../SideSelect'
import { iconMap, keys } from './constants'
import { useBorderWidthOptions } from './hooks'

export function BorderWidthSelectField({
  allFieldExtra,
  allLabelExtra,
  help,
  inheritFrom,
  inheritValue,
  isMixed,
  label,
  name = 'borderWidth',
  onChange,
  onChangeAll,
  value,
}) {
  const { t } = useTranslation('web/content-editor')

  const options = useBorderWidthOptions()

  return (
    <Sides
      icons={iconMap}
      keys={keys}
      showIndividual={isMixed}
      renderAll={({ showIndividual, setShowIndividual }) => (
        <SideSelectField
          className="flex-1"
          extra={
            <Button
              icon="border-outer"
              variant={showIndividual ? 'primary' : 'secondary'}
              onClick={() => setShowIndividual(!showIndividual)}
              tooltip={t('setIndependentSides')}
            />
          }
          fieldExtra={allFieldExtra}
          name={`${name}.t`}
          label={label ?? t('borderWidth')}
          labelExtra={allLabelExtra}
          labelKey="borderWidth"
          help={help}
          onChange={onChangeAll(name)}
          options={
            isMixed
              ? [{ value: 'mixed', label: t('mixed') }, ...options]
              : options
          }
          isMixed={isMixed}
          value={isMixed ? 'mixed' : value?.t}
          inheritFrom={isMixed ? null : inheritFrom?.t}
          inheritValue={isMixed ? null : inheritValue?.t}
        />
      )}
      renderSide={({ side }) => (
        <SideSelectField
          name={`${name}.${side}`}
          className="w-full"
          labelKey="borderWidth"
          onChange={onChange(`${name}.${side}`)}
          options={options}
          value={value?.[side]}
          inheritFrom={inheritFrom?.[side]}
          inheritValue={inheritValue?.[side]}
        />
      )}
    />
  )
}

BorderWidthSelectField.propTypes = {
  allFieldExtra: PropTypes.node,
  allLabelExtra: PropTypes.node,
  help: PropTypes.string,
  inheritFrom: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      t: PropTypes.string,
      r: PropTypes.string,
      b: PropTypes.string,
      l: PropTypes.string,
    }),
  ]),
  inheritValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      t: PropTypes.string,
      r: PropTypes.string,
      b: PropTypes.string,
      l: PropTypes.string,
    }),
  ]),
  isMixed: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onChangeAll: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.shape({
      t: PropTypes.string,
      r: PropTypes.string,
      b: PropTypes.string,
      l: PropTypes.string,
    }),
  ]),
}
