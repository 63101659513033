import { useQuery } from 'react-query'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { getFetch } from '@utils/fetcher'

export default function usePagesByResource({
  resourceName,
  resourceType,
  siteId,
  groupBySite = true,
  filterByEntity = true,
}) {
  const queryParams = { resourceName, resourceType, groupBySite, siteId }

  const { entity: currentEntity } = useCurrentEntity()

  // entity
  if (filterByEntity) {
    queryParams.entityId = currentEntity?.id
  }

  const { data, error, isLoading } = useQuery(
    ['pages', resourceName, resourceType, groupBySite, siteId],
    () => getFetch(`/api/web/pages`, queryParams)
  )

  return {
    items: data?.items,
    count: data?.count,
    error,
    loading: isLoading,
  }
}
