import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

export default function DisplayModeFields({ displayModes = [] }) {
  const displayModesArray = Object.keys(displayModes)
  const design = useDesignContext()
  const { t } = useTranslation(['publications/public', design.namespace])

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const displayModeOptions = useMemo(() => {
    if (displayModesArray.length > 0) {
      return displayModesArray.map(displayMode => ({
        value: displayMode,
        label: t(`${design.namespace}:publicationDisplayMode_${displayMode}`),
      }))
    }
    return ['cards', 'posters'].map(displayMode => ({
      value: displayMode,
      label: t(`displayMode_${displayMode}`),
    }))
  }, [design.namespace, displayModesArray, t])

  return (
    <Select
      label={t('displayMode')}
      help={t('displayModeHelp')}
      value={data.displayMode}
      onChange={e =>
        actions.setProp(props => (props.displayMode = e.target.value))
      }
    >
      {displayModeOptions.map(({ value, label }) => (
        <SelectOption
          value={value}
          label={label}
          key={`displayMode-${value}`}
        />
      ))}
    </Select>
  )
}
DisplayModeFields.propTypes = {
  displayModes: PropTypes.arrayOf(PropTypes.string),
}
