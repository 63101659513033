import React from 'react'

import { useEditor } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import BlockField from '../BlockField'
import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { gridColEnd, gridColSpan, gridColStart, gridOrder } from './hooks'

export default function GridItemOptions() {
  const { t } = useTranslation('web/content-editor')

  const { parent } = useEditor(state => {
    if (!state || typeof state !== 'object') return {}

    const [currentNodeId] = state?.events.selected || []
    const currentNode = state.nodes?.[currentNodeId]

    if (!currentNode?.data?.parent) return {}

    const parentNode = state.nodes?.[currentNode.data.parent]

    if (!parentNode) return {}

    return {
      parent: parentNode,
    }
  })

  if (parent?.data?.name !== 'Grid') {
    return null
  }

  return (
    <CollapsablePanel title={t('gridItemOptions')}>
      <BlockField defaultValue={null} name="order" responsive>
        {({
          fieldProps,
          value,
          valueAtBreakpoint,
          inheritFrom,
          inheritValue,
          onDataChange,
        }) => (
          <SelectField
            {...fieldProps}
            label={t('gridOrder')}
            help={t('gridOrderHelp')}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <InheritSelectPlaceholder
              customLabel={valueAtBreakpoint?.value}
              value={value}
              inheritValue={inheritValue}
              inheritFrom={inheritFrom}
            />
            {value && <SelectOption label={t('clearSelection')} value={null} />}
            <SelectPlaceholder label="-" />
            {Object.keys(gridOrder.xs).map(key => (
              <SelectOption
                label={key}
                value={key}
                key={`order-option-${key}`}
              />
            ))}
          </SelectField>
        )}
      </BlockField>

      <BlockField defaultValue={null} name="colSpan" responsive>
        {({
          fieldProps,
          value,
          valueAtBreakpoint,
          inheritFrom,
          inheritValue,
          onDataChange,
        }) => (
          <SelectField
            {...fieldProps}
            label={t('gridColSpan')}
            help={t('gridColSpanHelp')}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <InheritSelectPlaceholder
              customLabel={valueAtBreakpoint?.value}
              value={value}
              inheritValue={inheritValue}
              inheritFrom={inheritFrom}
            />
            {value && <SelectOption label={t('clearSelection')} value={null} />}
            <SelectPlaceholder label="-" />
            {Object.keys(gridColSpan.xs).map(key => (
              <SelectOption
                label={t(`colSpan_${key}`)}
                value={key}
                key={`col-span-option-${key}`}
              />
            ))}
          </SelectField>
        )}
      </BlockField>

      <BlockField defaultValue={null} name="colStart" responsive>
        {({
          fieldProps,
          value,
          valueAtBreakpoint,
          inheritFrom,
          inheritValue,
          onDataChange,
        }) => (
          <SelectField
            {...fieldProps}
            label={t('gridColStart')}
            help={t('gridColStartHelp')}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <InheritSelectPlaceholder
              customLabel={valueAtBreakpoint?.value}
              value={value}
              inheritValue={inheritValue}
              inheritFrom={inheritFrom}
            />
            {value && <SelectOption label={t('clearSelection')} value={null} />}
            <SelectPlaceholder label="-" />
            {Object.keys(gridColStart.xs).map(key => (
              <SelectOption
                label={t(`colStart_${key}`)}
                value={key}
                key={`col-start-option-${key}`}
              />
            ))}
          </SelectField>
        )}
      </BlockField>

      <BlockField defaultValue={null} name="colEnd" responsive>
        {({
          fieldProps,
          value,
          valueAtBreakpoint,
          inheritFrom,
          inheritValue,
          onDataChange,
        }) => (
          <SelectField
            {...fieldProps}
            label={t('gridColEnd')}
            help={t('gridColEndHelp')}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <InheritSelectPlaceholder
              customLabel={valueAtBreakpoint?.value}
              value={value}
              inheritValue={inheritValue}
              inheritFrom={inheritFrom}
            />
            {value && <SelectOption label={t('clearSelection')} value={null} />}
            <SelectPlaceholder label="-" />
            {Object.keys(gridColEnd.xs).map(key => (
              <SelectOption
                label={t(`colEnd_${key}`)}
                value={key}
                key={`col-end-option-${key}`}
              />
            ))}
          </SelectField>
        )}
      </BlockField>
    </CollapsablePanel>
  )
}
