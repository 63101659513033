import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'

import useCurrentEntity from './useCurrentEntity'

export default function useEntityPermissions(entity) {
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  const commonParams = {
    user,
    currentEntityId: currentEntity?._id,
  }
  return {
    // Subentities
    canReadSubentities: useIsUserAuthorized({
      module: 'subentities',
      permission: 'read',
      noEntityCheck: true, // We always show all entities we have access to.
    }),
    canCreateSubentities: useIsUserAuthorized({
      module: 'subentities',
      permission: 'create',
      entity,
    }),
    canUpdateSubentities: ({ entity }) => {
      return isUserAuthorized({
        module: 'subentities',
        permission: 'update',
        entity,
        ...commonParams,
      })
    },
    // canUpdateSubentities: useIsUserAuthorized({
    //   module: 'subentities',
    //   permission: 'update',
    //   entity,
    // }),
    canDeleteSubentities: ({ entity }) => {
      return isUserAuthorized({
        module: 'subentities',
        permission: 'delete',
        entity,
        ...commonParams,
      })
    },

    // Services
    canReadServices: useIsUserAuthorized({
      module: 'services',
      permission: 'read',
      noEntityCheck: true,
    }),
    canCreateServices: useIsUserAuthorized({
      module: 'services',
      permission: 'create',
      noEntityCheck: true,
    }),
    canUpdateServices: useIsUserAuthorized({
      module: 'services',
      permission: 'update',
      noEntityCheck: true,
    }),
    canDeleteServices: useIsUserAuthorized({
      module: 'services',
      permission: 'delete',
      noEntityCheck: true,
    }),
  }
}
