import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

export default function FieldsToggleFields({ showPreview }) {
  const { t } = useTranslation('events/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      <Checkbox
        label={t('showFilters')}
        name="showFilters"
        onChange={value =>
          actions.setProp(props => (props.showFilters = value))
        }
        value={data.showFilters}
      />
      {showPreview && (
        <Checkbox
          label={t('showPreview')}
          name="showPreview"
          onChange={value =>
            actions.setProp(props => (props.showPreview = value))
          }
          value={data.showPreview}
        />
      )}
    </>
  )
}

FieldsToggleFields.propTypes = {
  showPreview: PropTypes.bool,
}
