import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useProviders({
  search = '',
  limit = 10,
  page = 1,
  statuses = ['active', 'disabled'],
  filters = {},
  ignoreIds,
  sort = 'title',
  sortOrder = 'asc',
  skip,
  filterByEntity = true,
} = {}) {
  // entities
  const entities = Object.keys(filters?.entities || {})

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'courses-providers',
      {
        search,
        limit,
        page,
        sort,
        sortOrder,
        skip,
        statuses,
        entities,
        ignoreIds,
        filterByEntity,
      },
    ],
    () =>
      getFetch('/api/courses/providers', {
        sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
        limit: limit,
        skip: skip,
        page: page,
        ignoreIds: ignoreIds?.join(','),
        search: search,
        status: statuses?.join(','),
        entities: entities?.join(','),
        filterByEntity,
      })
  )

  return {
    providers: data?.items,
    count: data?.count,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
