import PropTypes from 'prop-types'
import React from 'react'

import Listbox from '../Listbox'
import ColorSelectOptions from './Options'
import ColorSelectedOption from './SelectedOption'
import { getSelectedOption } from './helpers'

export default function ColorSelectController({
  name,
  help,
  label,
  showClear,
  clearLabel,
  placeholder = '-',
  options = [],
  by,
}) {
  return (
    <Listbox
      by={by}
      name={name}
      label={label}
      help={help}
      options={options}
      renderSelectedLabel={ColorSelectedOption}
      getSelectedOption={getSelectedOption}
      placeholder={placeholder}
      // value={null}
    >
      <ColorSelectOptions
        showClear={showClear}
        clearLabel={clearLabel}
        options={options}
        valueAsObject={Boolean(by)}
      />
    </Listbox>
  )
}
ColorSelectController.propTypes = {
  by: PropTypes.oneOf(['value', 'color']),
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  clearLabel: PropTypes.string,
  showClear: PropTypes.bool,
  help: PropTypes.string,
  placeholder: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        color: PropTypes.string,
      }),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
        colors: PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
          color: PropTypes.string,
        }),
      }),
    ])
  ),
}
