import React from 'react'

const LanguagesRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/languages/*': { Component: LanguagesRouter },
      },
    },
    permissions: {
      languages: {
        name: 'languages',
        icon: 'flag',
        label: 'languages/public:languages',
        models: [
          {
            name: 'languages',
            // label: 'languages/public:languages',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
  }
}
