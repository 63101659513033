import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Select, { SelectOption, SelectPlaceholder } from '@ui/data-entry/Select'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import { HeaderIconField } from './HeaderIconField'

const headerVariants = [
  'transparent-dark',
  'transparent-light',
  'light',
  'primary',
]

export const PageForm = ({ page, onSubmit }) => {
  const design = useDesignContext()
  const { t } = useTranslation(design.namespace)

  return (
    <Form
      data={page}
      onSubmit={onSubmit}
      id={`design-header-${page.id}-${page.updatedAt}`}
    >
      <Section title={t('header')}>
        <Section.Body>
          <Select
            label={t('headerVariant')}
            help={t('headerVariantHelp')}
            name="design[header][variant]"
          >
            <SelectPlaceholder label="-" />
            {headerVariants.map(variant => (
              <SelectOption
                key={`variant-${variant}`}
                label={t(`headerVariant-${variant}`)}
                value={variant}
              />
            ))}
          </Select>
          <HeaderIconField />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}

PageForm.propTypes = {
  page: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
