import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useEpisodes({
  categories = [],
  channelId,
  fetchOnSearch = false,
  ids = [],
  ignoreIds = [],
  limit = 10,
  page = 1,
  search = '',
  seasons = [],
  showId,
  sort = 'createdAt',
  sortOrder = 'desc',
  statuses = ['active', 'disabled'],
  videoOnDemand = true,
  withoutVideoOnDemand = false,
}) {
  const url = channelId
    ? `/api/media-library/channels/${channelId}/episodes`
    : showId
      ? `/api/media-library/shows/${showId}/episodes`
      : null

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'episodes',
      {
        categories,
        channelId,
        ids,
        ignoreIds,
        limit,
        page,
        search,
        seasons,
        showId,
        sort,
        sortOrder,
        statuses,
        videoOnDemand,
        withoutVideoOnDemand,
      },
    ],
    () =>
      getFetch(url, {
        categories,
        ids,
        ignoreIds,
        limit,
        page,
        search,
        seasons,
        sort,
        sortOrder,
        statuses,
        videoOnDemand,
        withoutVideoOnDemand,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    episodes: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
