import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'

import { useTranslationSource, useTranslationTarget } from './Context'
import useAutomaticTranslation from './hooks/useAutomaticTranslation'

export default function AutomaticTranslationButton({
  className = '',
  text,
  onChange,
  type,
}) {
  const { t } = useTranslation(['web/pages', 'translations'])

  const { translate, isLoading = false } = useAutomaticTranslation()
  const targetLanguage = useTranslationTarget()
  const sourceLanguage = useTranslationSource()

  const onTranslate = useCallback(async () => {
    const translateParams = {
      text,
      type,
      source: sourceLanguage,
      target: targetLanguage,
    }

    const translatedText = await translate(translateParams)

    if (typeof onChange === 'function') {
      onChange(translatedText)
    }
  }, [translate, onChange, text, type, sourceLanguage, targetLanguage])

  if (!targetLanguage || !sourceLanguage) {
    // eslint-disable-next-line no-console
    console.warn(
      'AutomaticTranslationButton: missing target or source language in context. Check if TranslationContext is used in ancestors.'
    )
    return null
  }

  return (
    <Button
      className={className}
      icon="language"
      tooltip={t(
        isLoading ? 'translations:translating' : 'translations:translate'
      )}
      onClick={onTranslate}
      disabled={isLoading}
      loading={isLoading}
      variant="ai"
      size="xs"
    />
  )
}
AutomaticTranslationButton.propTypes = {
  className: PropTypes.string,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(['text', 'json']),
}
