import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { isUserAuthorized } from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import useQueryParams from '@ui/helpers/useQueryParams'
import SplitPanel from '@ui/layout/SplitPanel'

import { usePermissions } from '../PermissionsProvider'
import GroupModuleForm from './components/GroupModuleForm'

const Menu = React.lazy(() => import('@ui/navigation/Menu'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))

function useCurrentModule() {
  const { queryParams, setQueryParams } = useQueryParams()

  const currentModule = queryParams.get('module')

  const setModuleParam = React.useCallback(
    module => {
      if (module === currentModule) {
        setQueryParams('module', null)
        return
      }
      setQueryParams('module', module)
    },
    [setQueryParams, currentModule]
  )

  return { currentModule, setModuleParam }
}

export function GroupPermissions({ group }) {
  const { t } = useTranslation('users')

  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()
  const { currentModule, setModuleParam } = useCurrentModule()

  const allPermissions = usePermissions()
  const modules = Object.values(allPermissions)

  const authorizedModules = React.useMemo(() => {
    return modules.filter(({ models }) => {
      if (!models) return false

      return models.some(({ name }) => {
        return isUserAuthorized({
          user,
          currentEntityId: currentEntity?.id,
          entity: currentEntity,
          module: name,
          permission: 'read', // This won't be run for module entity (entity read does not exist)
        })
      })
    })
  }, [modules, user, currentEntity])

  const module = authorizedModules.find(({ name }) => name === currentModule)

  return (
    <SplitPanel
      title={t('permissions')}
      asideClass="p-6 bg-white"
      aside={
        <Menu>
          {authorizedModules.map(({ name: module, icon, label }) => (
            <MenuItem
              label={t(label)}
              icon={icon || 'block'}
              key={`module_${module}`}
              active={currentModule === module}
              onClick={() => setModuleParam(module)}
            />
          ))}
        </Menu>
      }
    >
      <div className="py-8 px-6">
        <GroupModuleForm module={module} group={group} />
      </div>
    </SplitPanel>
  )
}
GroupPermissions.propTypes = {
  group: PropTypes.object,
}
