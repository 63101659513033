import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { getArticleUrl } from '@modules/articles/helpers/urls'
import useArticles from '@modules/articles/services/hooks/useArticles'
import Empty from '@ui/data-display/Empty'
import TimeSince from '@ui/helpers/TimeSince'
import Link from '@ui/navigation/Link'

import ArticleStatusTag from '../StatusTag'

const DashboardWidgetWrapper = React.lazy(
  () => import('@modules/dashboard/components/DashboardWidgetWrapper')
)

export default function LatestArticlesWidget() {
  const { t } = useTranslation('articles/public')
  const { articles } = useArticles({ sort: `-createdAt`, limit: 6 })

  return (
    <DashboardWidgetWrapper title={t('latestArticles')} className="col-span-2">
      {articles?.length === 0 ? (
        <Empty
          label={t('articlesEmpty')}
          description={t('articlesEmptyMessage')}
        />
      ) : (
        <ul className="flex flex-col gap-1">
          {articles?.map(article => (
            <WidgetArticleItem article={article} key={article.id} />
          ))}
        </ul>
      )}
    </DashboardWidgetWrapper>
  )
}

function WidgetArticleItem({ article }) {
  return (
    <li className="group/item flex gap-4 items-center justify-between rounded hover:bg-primary-50 transition-all ease-in-out duration-300 px-2 py-1">
      <div className="flex truncate gap-4 items-center grow">
        <Link
          href={getArticleUrl(article, 'edit')}
          className="truncate group-hover/item:text-primary-500 transition-all ease-in-out duration-300"
          basic={false}
        >
          {article.title}
        </Link>
      </div>
      <span className="text-sm text-gray-500 shrink-0">
        <TimeSince date={article.createdAt} maxDays={10} format="P" />
      </span>
      <span className="text-sm text-gray-500 shrink-0 w-28 flex justify-end">
        <ArticleStatusTag article={article} />
      </span>
    </li>
  )
}
WidgetArticleItem.propTypes = {
  article: PropTypes.object,
}
