import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  enabled: true,
}

/**
 * Fetches an publications module's configuration
 *
 * @param {*} options - options for the query
 * @param {*} options.enabled - whether the query is enabled
 * @returns `object` with the following properties:
 * - `publicationsConfig` - the configuration object
 */
export default function usePublicationsConfig(options = defaultOptions) {
  const { enabled } = Object.assign({}, defaultOptions, options)

  const { data, error, isLoading } = useQuery(
    ['publications', 'configure', enabled],
    () => getFetch('/api/publications/configure'),
    { enabled }
  )

  const {
    hasPublicationTypes = false,
    hasPublicationCategories = false,
    hasPublicationReligions = false,
    hasPublishingHouses = false,
    publicationTypes = [],
    fields = {},
  } = data || {}

  return {
    publicationsConfig: data,
    hasPublicationTypes,
    hasPublicationCategories,
    hasPublicationReligions,
    hasPublishingHouses,
    publicationTypes,
    enabledFields: fields,
    error,
    loading: isLoading,
  }
}
