import { MutationCache, QueryCache } from 'react-query'

const unauthorizedStatuses = [403]

/**
 * Global errors for react-query
 *
 * @returns array
 */
export function useGlobalErrors({
  onAuthError = () => {},
  onServerError = () => {},
  onRecover = () => {},
}) {
  const triggerError = error => {
    const { status, data } = error

    if (unauthorizedStatuses.includes(status)) {
      onAuthError(data.code)
    } else if (status === 500) {
      // If it's an unexpected error (i.e. API is down)
      // execute the provided callback
      onServerError()
    }
  }

  const queryCache = new QueryCache({
    onError(error) {
      if (error) {
        triggerError(error)
      }
    },
    onSuccess() {
      onRecover()
    },
  })

  const mutationCache = new MutationCache({
    onError(error) {
      if (error) {
        triggerError(error)
      }
    },
    onSuccess() {
      onRecover()
    },
  })

  return [queryCache, mutationCache]
}

export default useGlobalErrors
