const articleStatusesMap = {
  draft: {
    label: 'status_draft',
    variant: 'basic',
    icon: 'edit',
  },
  done: {
    label: 'status_done',
    variant: 'info',
    icon: 'check',
  },
  approved: {
    label: 'status_approved',
    variant: 'primary',
    icon: 'badge-check', // TODO: icon as 'badge-check' or 'check-double'?
  },
  published: {
    variant: 'success',
    icon: 'globe',
    hideInFilter: true,
  },
}

export default articleStatusesMap
