import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import ImageUpload, { ImagePreview } from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import TranslationField, {
  TranslationFieldGroup,
} from '@ui/data-entry/Translation/Field'
import { useLocalStorage } from '@ui/helpers/useStorage'
import RichTextPreview from '@ui/typography/RichText'

const RichText = React.lazy(() => import('@ui/data-entry/RichText'))

/**
 * Get the fields of a block that have text
 * @param {object} fields - The fields of the block
 * @param {object} source - The source of the block
 * @returns {Array} The fields with text in key-value pairs (name and field respectively)
 */
function getFieldsWithText(fields, source) {
  if (!fields || !source) return []
  return Object.entries(fields).filter(([name]) => !!source[name])
}

/**
 * Hook to get the fields of a block translation that have text
 * @param {object} fields - The fields of the block
 * @param {object} source - The source of the block
 * @returns {Array} The fields with text in key-value pairs (name and field respectively)
 */
function useFieldsWithText(fields, source) {
  return useMemo(() => getFieldsWithText(fields, source), [fields, source])
}

/**
 * Get the name of a content field
 * @param {string} id - The id of the block
 * @param {string} name - The name of the field
 * @returns {string} The name of the content field
 */
function getContentFieldName(id, name) {
  return `content[${id}][${name}]`
}

// Map of field types for renderers.
// NOTE: Add here the fields that need a custom renderer.
const fieldPreviews = {
  text: props => <p>{props.value}</p>,
  image: props => <ImagePreview file={props.value} />,
  richText: props => <RichTextPreview doc={props.value} />,
}

// Map of field types for inputs.
// NOTE: Add here the fields that need a custom input.
const fieldTypes = {
  text: props => <Input {...props} />,
  image: props => <ImageUpload {...props} maxSize="50mb" />,
  richText: props => <RichText {...props} />,
}

/**
 * Component to render the fields of a block translation
 * @param {object} props - The component props
 * @param {object} props.fields - The fields of the block
 * @param {boolean} props.hidable - Whether the block is hidable
 * @param {boolean} props.hideSourceLabel - Whether to hide the source label
 * @param {string} props.id - The id of the block
 * @param {boolean} props.required - Whether the block is required
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export default function BlockTranslation({
  fields,
  hideSourceLabel,
  id,
  required,
  source,
  i18Namespace = 'web/content-editor',
}) {
  const { t } = useTranslation(i18Namespace)
  const fieldsWithText = useFieldsWithText(fields, source.props)

  const [showStructure] = useLocalStorage('showStructure', false)

  if (
    (!showStructure && fieldsWithText.length === 0) ||
    !source?.displayName ||
    fieldsWithText.length === 0
  ) {
    return null
  }

  const { displayName, props: sourceProps } = source

  const groupLabel = sourceProps.name
    ? `${sourceProps.name} (${t(displayName)})`
    : t(displayName)

  return (
    <TranslationFieldGroup
      hidden={source?.hidden}
      label={groupLabel}
      name={`content[${id}]`}
      required={required}
      showVisibilityToggle={showStructure}
    >
      {fieldsWithText.map(([name, field]) => {
        const fieldName = getContentFieldName(id, name)
        const {
          label,
          type,
          disableAutomatic,
          CustomFieldInput,
          onCustomChange,
        } = field
        const sourceText = sourceProps[name]

        const Preview = fieldPreviews[type] || fieldPreviews.text
        const Field = fieldTypes[type] || fieldTypes.text

        return (
          <TranslationField
            key={`field-${name}`}
            name={fieldName}
            sourcePreview={
              <div className="pt-2 first:pt-0 flex flex-col gap-2">
                {!hideSourceLabel && <h5 className="font-semibold">{label}</h5>}
                <div>
                  <Preview value={sourceText} />
                </div>
              </div>
            }
            sourceText={sourceText}
            automaticTranslation={!disableAutomatic}
            showLabel={!hideSourceLabel}
            onCustomChange={onCustomChange}
          >
            {CustomFieldInput ? (
              <CustomFieldInput
                fieldName={fieldName}
                sourceText={sourceText}
                {...(field.props || {})}
              />
            ) : (
              <Field
                label={field.label}
                name={fieldName}
                className="flex-grow"
                {...(field.props || {})}
              />
            )}
          </TranslationField>
        )
      })}
    </TranslationFieldGroup>
  )
}
BlockTranslation.propTypes = {
  fields: PropTypes.object,
  hideSourceLabel: PropTypes.bool,
  i18Namespace: PropTypes.string,
  id: PropTypes.string,
  required: PropTypes.bool,
  source: PropTypes.object,
}
