import { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import get from 'lodash/get'
import set from 'lodash/set'

import { isEmpty, removeEmpty } from '@utils/objects'

import useBreakpoint from '../shared/useBreakpoint'

export default function useSetDataAtBreakpoint(breakpointOverride) {
  const { breakpoint } = useBreakpoint()

  const currentBreakpoint = breakpointOverride || breakpoint

  const { actions } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return useCallback(
    (prop, newData, fallbackAtXS = null) => {
      const path = Array.isArray(prop) ? prop : prop.split('.')

      actions.setProp(data => {
        const value = get(data, path)
        if (!value || typeof value !== 'object') {
          set(data, path, {})
        }

        set(
          data,
          [...path, currentBreakpoint],
          newData || (currentBreakpoint === 'xs' ? fallbackAtXS : undefined)
        )

        set(data, path, removeEmpty(get(data, path)))

        if (isEmpty(get(data, path))) {
          set(data, path, null)
        }
      })
    },
    [actions, currentBreakpoint]
  )
}
