import React from 'react'

const GroupsRouter = React.lazy(() => import('./pages/groups/Router'))
const UsersRouter = React.lazy(() => import('./pages/users/Router'))
const UsersPermissions = React.lazy(
  () => import('./components/UsersPermissions')
)

export default function register() {
  return {
    routes: {
      private: {
        '/groups/*': { Component: GroupsRouter },
        '/users/*': { Component: UsersRouter },
      },
    },
    permissions: {
      users: {
        name: 'users',
        label: 'users/public:usersAndGroups',
        icon: 'users',
        PermissionsComponent: UsersPermissions,
        models: [
          {
            name: 'users',
          },
          {
            name: 'groups',
          },
        ],
      },
    },
  }
}
