import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

const borderWidthOptions = {
  none: 0, // border-0 border-t-0 border-r-0 border-b-0 border-l-0
  xs: 1, // border border-t border-r border-b border-l
  sm: 2, //  border-2 border-t-2 border-r-2 border-b-2 border-l-2
  md: 4, // border-4 border-t-4 border-r-4 border-b-4 border-l-4
  lg: 8, // border-8 border-t-8 border-r-8 border-b-8 border-l-8
}

/**
 * Returns the border width options
 * @returns {object[]} The border width options
 */
export function useBorderWidthOptions() {
  const { t } = useTranslation('web/content-editor')

  return useMemo(
    () =>
      Object.entries(borderWidthOptions).map(([name, value]) => ({
        label: `${t(`borderWidth_${name}`)} (${value}px)`,
        value: name,
      })),
    [t]
  )
}

/**
 * Returns the border width styles for the given width
 * @param {string} width - The width of the border
 * @returns {object} The border width styles
 * @example
 * const borderWidthStyles = useBorderWidthStyles('xs')
 * // => { className: 'border' }
 * @example
 * const borderWidthStyles = useBorderWidthStyles('none')
 * // => { className: 'border-0' }
 */
export function useBorderWidthStyles(width = { t: '', r: '', b: '', l: '' }) {
  const equalSides = useMemo(
    () =>
      Object.values(width).every(value => {
        return width.t === value
      }),
    [width]
  )

  if (equalSides && Object.keys(borderWidthOptions).includes(width.t)) {
    return {
      className:
        width.t === 'xs' ? 'border' : `border-${borderWidthOptions[width.t]}`,
    }
  }

  return {
    className: Object.entries(width).reduce((acc, [key, value]) => {
      if (Object.keys(borderWidthOptions).includes(value)) {
        return `${acc} ${
          value === 'xs'
            ? `border-${key}`
            : `border-${key}-${borderWidthOptions[value]}`
        }`
      }
      return acc
    }, ''),
  }
}
