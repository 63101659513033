import PublicationDetail from './Detail'
import PublicationDownloads from './Downloads'
import PublicationsList from './List'
import PublishingHouses from './PublishingHouses'
import PublicationsReligionsMenu from './ReligionsMenu'
import PublicationsSearch from './Search'
import PublicationStatistics from './Statistics'
import translatableBlocks from './translatableBlocks'

export default {
  resolvers: {
    PublicationsList,
    PublicationsSearch,
    PublicationDetail,
    PublicationsReligionsMenu,
    PublicationStatistics,
    PublicationDownloads,
    PublishingHouses,
  },
  toolbars: [
    {
      name: 'publications',
      label: 'publications/public:publications',
      items: [
        PublicationsList,
        PublicationDetail,
        PublicationsSearch,
        PublicationsReligionsMenu,
        PublicationStatistics,
        PublicationDownloads,
        PublishingHouses,
      ],
    },
  ],
  translatableBlocks,
}
