import PrivateAuthRouter from './pages/PrivateAuthRouter'
import PublicAuthRouter from './pages/PublicAuthRouter'

export default function register() {
  return {
    routes: {
      public: {
        '/auth/*': { Component: PublicAuthRouter },
      },
      private: {
        '/account/*': { Component: PrivateAuthRouter },
      },
    },
  }
}
