import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export function useFeatureFlagKeys() {
  return useQuery(
    ['feature-flags', 'keys'],
    () => getFetch('/api/feature-flags/keys'),
    {
      keepPreviousData: true,
    }
  )
}
