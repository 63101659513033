import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  enabled: true,
}

export default function useEntityTypes(options = defaultOptions) {
  const { enabled, ...queryOptions } = Object.assign(
    {},
    defaultOptions,
    options
  )

  const { data, error, isLoading } = useQuery(
    ['entities', 'types'],
    () => getFetch('/api/entities/types'),
    {
      enabled,
      ...queryOptions,
    }
  )

  const { items, count } = data || {}

  const sortedTypes =
    items?.sort((a, b) => a?.name.localeCompare(b?.name)) || []

  return {
    types: sortedTypes,
    count: count || 0,
    error,
    loading: isLoading,
  }
}
