import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  ModelPermissionsWrap,
  PermissionCheckbox,
} from '@modules/users/components/GroupPermissions'

import usePublicationsConfig from '../services/hooks/usePublicationsConfig'

export default function PublicationsPermissions() {
  const { t } = useTranslation('publications/public')
  const { watch } = useFormContext()

  const {
    hasPublicationTypes,
    hasPublicationCategories,
    hasPublicationReligions,
    hasPublishingHouses,
  } = usePublicationsConfig()

  const canReadPublications = watch('publications[read]')
  const canReadPublicationTypes = watch('publication-types[read]')
  const canReadCategories = watch('publication-categories[read]')
  const canReadReligions = watch('publication-religions[read]')
  const canReadPublishingHouses = watch('publishing-houses[read]')

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <ModelPermissionsWrap label={t('publications')}>
        <PermissionCheckbox model="publications" permission="read" />
        <PermissionCheckbox
          model="publications"
          permission="create"
          disabled={!canReadPublications}
        />
        <PermissionCheckbox
          model="publications"
          permission="update"
          disabled={!canReadPublications}
        />

        <PermissionCheckbox
          model="publications"
          permission="update-assets"
          label={t('updateAssetsPermission')}
          tooltip={t('updateAssetsPermissionHelp')}
          disabled={!canReadPublications}
        />
        <PermissionCheckbox
          model="publications"
          permission="delete"
          disabled={!canReadPublications}
        />
        <PermissionCheckbox
          model="publications"
          permission="translate"
          label={t('translatePermission')}
          tooltip={t('translatePermissionHelp')}
          disabled={!canReadPublications}
        />
        <PermissionCheckbox
          model="publications"
          permission="translate-assets"
          label={t('translateAssetsPermission')}
          tooltip={t('translateAssetsPermissionHelp')}
          disabled={!canReadPublications}
        />

        <PermissionCheckbox
          model="publications"
          permission="configure"
          label={t('configurePermission')}
          tooltip={t('configurePermissionHelp')}
          disabled={!canReadPublications}
        />
      </ModelPermissionsWrap>

      {hasPublicationCategories && (
        <ModelPermissionsWrap
          model="publication-categories"
          label={t('categories')}
        >
          <PermissionCheckbox
            model="publication-categories"
            permission="read"
          />
          <PermissionCheckbox
            model="publication-categories"
            permission="create"
            disabled={!canReadCategories}
          />
          <PermissionCheckbox
            model="publication-categories"
            permission="update"
            disabled={!canReadCategories}
          />
          <PermissionCheckbox
            model="publication-categories"
            permission="delete"
            disabled={!canReadCategories}
          />
        </ModelPermissionsWrap>
      )}

      {hasPublicationTypes && (
        <ModelPermissionsWrap
          model="publication-types"
          label={t('publicationTypes')}
        >
          <PermissionCheckbox model="publication-types" permission="read" />
          <PermissionCheckbox
            model="publication-types"
            permission="create"
            disabled={!canReadPublicationTypes}
          />
          <PermissionCheckbox
            model="publication-types"
            permission="update"
            disabled={!canReadPublicationTypes}
          />
          <PermissionCheckbox
            model="publication-types"
            permission="delete"
            disabled={!canReadPublicationTypes}
          />
        </ModelPermissionsWrap>
      )}

      {hasPublicationReligions && (
        <ModelPermissionsWrap
          model="publication-religions"
          label={t('religions')}
        >
          <PermissionCheckbox model="publication-religions" permission="read" />
          <PermissionCheckbox
            model="publication-religions"
            permission="create"
            disabled={!canReadReligions}
          />
          <PermissionCheckbox
            model="publication-religions"
            permission="update"
            disabled={!canReadReligions}
          />
          <PermissionCheckbox
            model="publication-religions"
            permission="delete"
            disabled={!canReadReligions}
          />
        </ModelPermissionsWrap>
      )}

      {hasPublishingHouses && (
        <ModelPermissionsWrap
          model="publishing-houses"
          label={t('PublishingHouses')}
        >
          <PermissionCheckbox model="publishing-houses" permission="read" />
          <PermissionCheckbox
            model="publishing-houses"
            permission="create"
            disabled={!canReadPublishingHouses}
          />
          <PermissionCheckbox
            model="publishing-houses"
            permission="update"
            disabled={!canReadPublishingHouses}
          />
          <PermissionCheckbox
            model="publishing-houses"
            permission="delete"
            disabled={!canReadPublishingHouses}
          />
        </ModelPermissionsWrap>
      )}
    </div>
  )
}
