import PropTypes from 'prop-types'
import React from 'react'

import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const buttonVariantStyles = {
  default: 'text-gray-700 hover:text-gray-900 hover:bg-white/50',
  solid:
    'text-gray-600 bg-gray-200 shadow drop-shadow-sm hover:shadow-md hover:drop-shadow-lg border border-gray-300 hover:border-gray-400',
  dark: 'text-white bg-gray-500 hover:bg-gray-600',
}

export default function CloseButton({ onClick, variant }) {
  const variantClasses =
    buttonVariantStyles[variant] || buttonVariantStyles.default

  return (
    <Clickable
      className={`flex items-center justify-center w-8 h-8 text-xl transition-all duration-300 ease-in-out rounded-full outline-none cursor-pointer focus:ring-2 shrink-0 ${variantClasses}`}
      onClick={onClick}
    >
      <Icon name="times" />
    </Clickable>
  )
}
CloseButton.propTypes = {
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['default', 'solid']),
}
