import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { useGlobalParams } from '@utils/urls'

import {
  getAdvisorUrl,
  getChatUrl,
  getCollectionUrl,
  getCountryUrl,
  getCourseStatusUrl,
  getCourseUrl,
  getProviderUrl,
  getStatisticsUrl,
  getStudentUrl,
} from '../helpers/usePageUrl'
import useCoursesPermissions from '../services/hooks/useCoursesPermissions'
import useProviders from '../services/hooks/useProviders'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

function ProvidersMenu({ permissions }) {
  const { t } = useTranslation('courses/public')
  const { providers, count } = useProviders({ limit: 20 })
  // const { providers, count } = useProviders({ limit: 20, search: 'Hope Kurse' })
  const { action, providerId } = useGlobalParams(
    '/courses/providers/:providerId/:action/*'
  )

  const {
    canReadCourses,
    canReadCollections,
    canReadAllStudents,
    canReadStudents,
    canReadAdvisors,
    canReadCountries,
    canReadAllQuestionnaires,
    canReadQuestionnaires,
    canReadAllMessages,
    canReadMessages,
    canUpdateProviders,
    canReadStatistics,
  } = permissions

  if (!providers) return null

  // eslint-disable-next-line react/prop-types
  const EmptyWrapper = ({ children }) => <>{children}</>
  const MenuWrapper = providers.length === 1 ? EmptyWrapper : MenuItem

  return (
    <>
      {providers.map((provider, key) => {
        const isCurrentProvider = provider.id === providerId
        return (
          <MenuWrapper label={provider.title} icon="books" key={key}>
            {canReadCourses({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'courses'}
                label={t('courses')}
                icon="book"
                href={getCourseUrl('list', provider.id)}
              />
            )}
            {canReadCollections({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'collections'}
                label={t('collections')}
                icon="layer-group"
                href={getCollectionUrl('list', provider.id)}
              />
            )}
            {(canReadStudents({ providerId: provider.id }) ||
              canReadAllStudents({ providerId: provider.id })) && (
              <MenuItem
                active={isCurrentProvider && action === 'students'}
                label={t('students')}
                icon="people-group"
                href={getStudentUrl('list', provider.id)}
              />
            )}
            {canReadAdvisors({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'advisors'}
                label={t('advisors')}
                icon="person-chalkboard"
                href={getAdvisorUrl('list', provider.id)}
              />
            )}
            {canReadCountries({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'countries'}
                label={t('countries')}
                icon="earth-americas"
                href={getCountryUrl('list', provider.id)}
              />
            )}
            {(canReadQuestionnaires({ providerId: provider.id }) ||
              canReadAllQuestionnaires({ providerId: provider.id })) && (
              <MenuItem
                active={isCurrentProvider && action === 'course-statuses'}
                label={t('questionnaires')}
                icon="clipboard-list"
                href={getCourseStatusUrl('list', provider.id)}
              />
            )}
            {(canReadMessages({ providerId: provider.id }) ||
              canReadAllMessages({ providerId })) && (
              <MenuItem
                active={isCurrentProvider && action === 'chats'}
                label={t('messages')}
                icon="messages"
                href={getChatUrl('list', provider.id)}
              />
            )}
            {canReadStatistics({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'statistics'}
                label={t('statistics')}
                href={getStatisticsUrl('list', provider.id)}
                icon="chart-mixed"
              />
            )}
            {canUpdateProviders({ providerId: provider.id }) && (
              <MenuItem
                active={isCurrentProvider && action === 'edit'}
                label={t('settings')}
                href={getProviderUrl('edit', provider.id)}
                icon="cog"
              />
            )}
          </MenuWrapper>
        )
      })}
      {providers && count > providers?.length && (
        <MenuItem label={t('moreItems', { count: count - providers.length })} />
      )}
    </>
  )
}
ProvidersMenu.propTypes = {
  permissions: PropTypes.object,
}

export default function CoursesMenuGroup() {
  const { t } = useTranslation('courses/public')
  const { pathname } = useLocation()
  const { action } = useGlobalParams('/courses/providers/:providerId/:action')
  const inCourses = pathname.startsWith('/courses')

  const permissions = useCoursesPermissions()
  const { canReadProviders } = permissions

  if (!canReadProviders) return null

  return (
    <MenuGroup
      name="courses"
      label={t('courses')}
      icon="graduation-cap"
      active={inCourses}
    >
      <ProvidersMenu permissions={permissions} />
      <MenuDivider />
      <MenuItem
        active={
          ['/courses/providers', '/courses/providers/add'].includes(
            location.pathname
          ) || action === 'providers'
        }
        label={t('manageProviders')}
        icon="books"
        href={getProviderUrl('list')}
      />
    </MenuGroup>
  )
}
