import React from 'react'

const CountriesRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/countries/*': { Component: CountriesRouter },
      },
    },
    permissions: {
      countries: {
        name: 'countries',
        icon: 'flag',
        label: 'countries/public:countries',
        models: [
          {
            name: 'countries',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
  }
}
