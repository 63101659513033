import React from 'react'
import PropTypes from 'prop-types'
import { Controller } from 'react-hook-form'
import { MultipleMenuPickerField } from './MultipleMenuPickerField'

export function MultipleMenuPickerController({
  name,
  className,
  help,
  label,
  required,
  shouldUnregister,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <MultipleMenuPickerField
          className={className}
          help={help}
          label={label}
          name={name}
          required={required}
          value={field.value}
          onChange={field.onChange}
        />
      )}
    />
  )
}
MultipleMenuPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
