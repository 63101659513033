import React from 'react'

import { useTranslation } from 'react-i18next'

import Checkbox from '@ui/data-entry/Checkbox'

import ChannelPicker from '../../components/ChannelPicker'

export default function JetstreamUpdaterTaskFields() {
  const { t } = useTranslation('media-library/tasks')

  return (
    <div className="flex flex-col gap-4">
      <ChannelPicker name="settings[channel]" />
      <Checkbox
        name="settings[updateMediaLinks]"
        label={t('jetstream-updater-updateMediaLinks')}
        help={t('jetstream-updater-updateMediaLinksHelp')}
      />
      <Checkbox
        name="settings[updateDuration]"
        label={t('jetstream-updater-updateDuration')}
        help={t('jetstream-updater-updateDurationHelp')}
      />
      <Checkbox
        name="settings[updateImages]"
        label={t('jetstream-updater-updateImages')}
        help={t('jetstream-updater-updateImagesHelp')}
      />
    </div>
  )
}
