import PropTypes from 'prop-types'
import React from 'react'

import { isObject } from 'lodash'
import { useTranslation } from 'react-i18next'

import Empty from '@ui/data-display/Empty'
import { ErrorMessage } from '@ui/data-display/Message'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'

import { useCreateOrUpdateGroup } from '../../../services/GroupServices'
import { ModelPermissions } from '../components/ModelPermissions'

export default function GroupModuleForm({ module, group }) {
  const { t } = useTranslation('users')
  const {
    mutate: saveGroup,
    isError,
    isLoading,
    error,
  } = useCreateOrUpdateGroup()

  const handleUpdate = data => {
    const permissions = group.permissions || {}

    for (const [module, value] of Object.entries(data)) {
      if (!isObject(value)) continue

      permissions[module] = {
        ...value,
        ...(value.records && { records: value.records.filter(r => r) }),
      }
    }

    saveGroup({ id: group.id, permissions })
  }

  if (!module)
    return (
      <Empty label={t('selectAModule')} description={t('selectAModuleHelp')} />
    )

  const { name, label, CustomFilter, models, PermissionsComponent } = module

  return (
    <Form
      data={group.permissions}
      onSubmit={handleUpdate}
      key={`group-permissions-${name}`}
    >
      <Section id={name} title={t(label)}>
        <Section.Body>
          {isError && <ErrorMessage error={error} />}
          {PermissionsComponent ? (
            <PermissionsComponent />
          ) : (
            <div className="space-y-6">
              {typeof CustomFilter?.Component === 'function' && (
                <CustomFilter.Component {...(CustomFilter.props || {})} />
              )}

              <div className="grid grid-cols-3 gap-x-6 gap-y-8">
                {models.map(model => (
                  <ModelPermissions model={model} key={model.name} />
                ))}
              </div>
            </div>
          )}
        </Section.Body>
        <Section.Footer>
          <Submit
            label={t(isLoading ? 'saving' : 'update')}
            submitting={isLoading}
            reverse
          />
        </Section.Footer>
      </Section>
    </Form>
  )
}
GroupModuleForm.propTypes = {
  group: PropTypes.object,
  module: PropTypes.object,
}
