import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

const rangesStyles = [
  { min: 0, max: 24, className: 'bg-danger-500' },
  { min: 25, max: 49, className: 'bg-warn-500' },
  { min: 50, max: 74, className: 'bg-info-500' },
  { min: 75, max: 99, className: 'bg-primary-500' },
  { min: 100, max: 100, className: 'bg-success-500' },
]

/**
 * ProgressBar component
 * @param {object} props - component props
 * @param {number} props.percentage - percentage value
 * @param {boolean} props.hidePercentage - hide percentage text
 * @param {boolean} props.ranged - use ranged colors
 * @returns {React.Component}
 */
export default function ProgressBar({ percentage, hidePercentage, ranged }) {
  // get a number from percentage
  const value = parseFloat(percentage ?? 0)

  // memoize width style
  const widthStyle = useMemo(() => ({ width: `${value}%` }), [value])

  // memoize background class
  const bgClass = useMemo(() => {
    // if ranged is false, return primary color
    if (!ranged) {
      return 'bg-primary-500'
    }
    // otherwise, find the range that the value belongs to
    return (
      rangesStyles.find(({ min, max }) => value >= min && value <= max)
        ?.className ?? 'bg-gray-500' // if no range is found, return gray
    )
  }, [ranged, value])

  return (
    <div className="flex gap-2 items-center">
      {!hidePercentage && (
        <span className="text-sm text-gray-600 font-semibold">
          {percentage}%
        </span>
      )}
      <div className="relative w-full h-2 rounded-full overflow-hidden border border-px border-gray-300">
        <div
          className={`absolute left-0 h-2 shadow-inner ${bgClass}`}
          style={widthStyle}
        />
      </div>
    </div>
  )
}
ProgressBar.propTypes = {
  hidePercentage: PropTypes.bool,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  ranged: PropTypes.bool,
}
ProgressBar.defaultProps = {
  percentage: 0,
  ranged: true,
}
