import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { Element, useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import Toolbar from '@modules/web/components/ContentEditor/shared/Toolbar'
import { useDynamicResource } from '@modules/web/components/DynamicField'
import usePage from '@modules/web/services/hooks/usePage'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

import { ArticlesFilterFields } from './shared/ArticlesFilterFields'

const config = {
  name: 'ArticleRepeater',
  label: 'articles/public:articleRepeater',
  type: 'plugin',
  icon: 'list',
  component: <Element is={ArticleRepeater} canvas />,
  allowed: [
    'Section',
    'Container',
    'Box',
    'Grid',
    'SimpleText',
    'Image',
    'Button',
    'Hero',
  ],
}

export default function ArticleRepeater({ children, filterByDynamicResource }) {
  const { t } = useTranslation('articles/public')

  const dynamicResource = useDynamicResource()

  const articleResourceFilter = useMemo(
    () =>
      dynamicResource
        ? t(dynamicResource.label)
        : t('noSupportedDynamicResourceFilters'),
    [dynamicResource, t]
  )

  return (
    <Content icon={config.icon} title={t('articleRepeater')} className="w-full">
      {filterByDynamicResource && (
        <div>
          Articles by:{' '}
          <span className="font-medium">
            {articleResourceFilter.toLowerCase()}
          </span>
        </div>
      )}
      <>{children}</>
    </Content>
  )
}
ArticleRepeater.propTypes = {
  children: PropTypes.PropTypes.node,
  filterByDynamicResource: PropTypes.bool,
}
ArticleRepeater.defaultProps = {}
ArticleRepeater.toolbarItem = config

function ArticleRepeaterSettings() {
  const { t } = useTranslation('articles/public')
  const { page } = usePage()

  const { dynamicRecord, dynamic, dynamicResource } = page ?? {}

  const hasResource =
    (dynamic && Boolean(dynamicResource)) || Boolean(dynamicRecord)

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <SettingsWrap title={t('articleRepeater')} help={t('articleRepeaterHelp')}>
      <div className="flex flex-col gap-4">
        <ArticlesFilterFields />
        {hasResource && (
          <>
            <Checkbox
              label={t('filterCurrentArticle')}
              help={t('filterCurrentArticleHelp')}
              name="filterCurrentArticle"
              onChange={value =>
                actions.setProp(props => (props.filterCurrentArticle = value))
              }
              value={data.filterCurrentArticle}
            />
            <Checkbox
              label={t('filterCurrentArticleFlags')}
              help={t('filterCurrentArticleFlagsHelp')}
              name="filterCurrentArticleFlags"
              onChange={value =>
                actions.setProp(
                  props => (props.filterCurrentArticleFlags = value)
                )
              }
              value={data.filterCurrentArticleFlags}
            />
          </>
        )}
        <LinkingFields
          detailPagehelp={t('detailPageIdHelp')}
          showListPagePicker={false}
          resourceIdentifier="Article"
        />
      </div>
    </SettingsWrap>
  )
}

ArticleRepeater.craft = {
  displayName: 'ArticleRepeater',
  props: {
    flag: null,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    limit: 10,
    skip: 0,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    allowedChildren: () => <Toolbar allowed={config.allowed} />,
    settings: ArticleRepeaterSettings,
  },
}
