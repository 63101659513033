import React from 'react'

import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'

export function ThemeVariantSelect() {
  const { t } = useTranslation('designs/adventist')

  return (
    <>
      <Select
        label={t('designVariant')}
        help={t('designVariantHelp')}
        name="variant"
        shouldUnregister
        required
      >
        <Select.Placeholder label="-" />
        <Select.Option label={t('designVariant-alps')} value="alps" />
        <Select.Option label={t('designVariant-news')} value="news" />
      </Select>
    </>
  )
}
