import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'

export default function PaginationFields({ fieldPrefix = '' }) {
  const { t } = useTranslation('articles/public')

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const onNumberChange = useCallback(
    field => e => {
      const { value } = e.target

      actions.setProp(
        props =>
          (props[field] = parseInt(isNaN(value) || value < 0 ? 0 : value))
      )
    },
    [actions]
  )

  return (
    <>
      <BlockField name={`${fieldPrefix}pagination`}>
        {({ fieldProps, value, onDataChange }) => (
          <CheckboxField
            {...fieldProps}
            name="pagination"
            label={t('pagination')}
            help={t('paginationHelp')}
            onChange={onDataChange}
            value={value}
          />
        )}
      </BlockField>
      <BlockField name={`${fieldPrefix}limit`}>
        {({ fieldProps, value }) => (
          <InputField
            {...fieldProps}
            label={t(data[`${fieldPrefix}pagination`] ? 'perPage' : 'limit')}
            help={t(
              data[`${fieldPrefix}pagination`] ? 'perPageHelp' : 'limitHelp'
            )}
            onChange={onNumberChange(`${fieldPrefix}limit`)}
            value={value}
            type="number"
          />
        )}
      </BlockField>
      {!data[`${fieldPrefix}pagination`] && (
        <BlockField name={`${fieldPrefix}skip`}>
          {({ fieldProps, value }) => (
            <InputField
              {...fieldProps}
              label={t('skip')}
              help={t('skipHelp')}
              onChange={onNumberChange(`${fieldPrefix}skip`)}
              value={value}
              type="number"
            />
          )}
        </BlockField>
      )}
    </>
  )
}

PaginationFields.propTypes = {
  fieldPrefix: PropTypes.string,
}
