import _differenceInDays from 'date-fns/differenceInDays'
import format from 'date-fns/format'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
import formatISO from 'date-fns/formatISO'
import isBeforeDate from 'date-fns/isBefore'
import isValid from 'date-fns/isValid'
import enUS from 'date-fns/locale/en-US'
import substractDate from 'date-fns/sub'

import { getLocale } from '@ui/helpers/datetime'

import { removeUndefined } from './objects'

/**
 * Format a date using the given format and locale.
 * @param {string|number|Date} dateValue - The date to format.
 * @param {string} dateFormat - The format to use. Defaults to `Pp`. See https://date-fns.org/v2.30.0/docs/format for more details.
 * @param {object} options - The options to use.
 * @param {string} options.locale - The locale to use.
 * @returns {string} The formatted date.
 */
export function formatDate(dateValue, dateFormat = 'Pp', options = {}) {
  if (!dateValue) return null
  const { locale, ...others } = { locale: enUS, ...removeUndefined(options) }

  const date = new Date(dateValue)
  return format(date, dateFormat, { locale: getLocale(locale), ...others })
}

export function formatDateISO(date, options = { representation: 'date' }) {
  return isValid(date) ? formatISO(date, options) : undefined
}

export function formatDateRange(
  dateFrom,
  dateTo,
  dateFormat = 'P',
  options = {}
) {
  if (dateFrom && dateTo) {
    return `${formatDate(dateFrom, dateFormat, options)} – ${formatDate(
      dateTo,
      dateFormat,
      options
    )}`
  }
  return formatDate(dateFrom ?? dateTo, dateFormat, options)
}

/**
 * Format a date using the given format and locale to a relative time.
 * @param {string|number|Date} dateValue - The date to format.
 * @param {string} dateFormat - The format to use. Defaults to `Pp`. See https://date-fns.org/v2.30.0/docs/format for more details.
 * @param {object} options - The options to use.
 * @param {string} options.locale - The locale to use.
 * @param {number} options.maxDays - The maximum number of days to show the date. Defaults to `3`.
 * @param {boolean} options.addSuffix - Whether to add a suffix to the date. Defaults to `true`.
 * @returns {string} The formatted date.
 */
export function timeSince(dateValue, dateFormat, options = {}) {
  if (!dateValue) return null
  const { maxDays, addSuffix, locale } = {
    maxDays: 3,
    addSuffix: true,
    locale: enUS,
    ...removeUndefined(options),
  }

  const date = new Date(dateValue)
  const now = new Date()
  const limitDate = substractDate(now, { days: maxDays })

  return isBeforeDate(date, limitDate)
    ? formatDate(date, dateFormat, { locale })
    : formatDistanceToNow(date, { addSuffix, locale: getLocale(locale) })
}

export function differenceInDays(dateValue) {
  if (!dateValue) return null

  const date = new Date(dateValue)
  const now = new Date()

  return isBeforeDate(date, now)
    ? _differenceInDays(now, date)
    : _differenceInDays(date, now)
}

export function messageTime(dateValue, options) {
  if (!dateValue) return null
  const { locale } = { locale: enUS, ...removeUndefined(options) }

  const date = new Date(dateValue)
  const now = new Date()
  const yesterday = substractDate(now, { days: 1 })
  const oneWeekAgo = substractDate(now, { days: 7 })

  const dateFormat = isBeforeDate(date, oneWeekAgo)
    ? 'P'
    : isBeforeDate(date, yesterday)
      ? 'EEE'
      : 'p'

  return formatDate(date, dateFormat, { locale })
}

/**
 * Converts hours into seconds
 * @param {number} h hours
 * @returns `number` seconds
 */
export function h2s(h) {
  return h * 60 * 60
}

/**
 * Converts minutes into seconds
 * @param {number} m minutes
 * @returns `number` seconds
 */
export function m2s(m) {
  return m * 60
}

/**
 * Converts hms time to seconds
 * @param {string} hms time with format `hh:mm:ss` or `mm:ss` or `ss`
 * @returns `number`
 */
export function hmsToSeconds(hms = '00:00:00') {
  if (!hms) return 0

  const a = hms.split(':').map(x => +x)

  if (a.length === 3) {
    return h2s(a[0]) + m2s(a[1]) + a[2]
  } else if (a.length === 2) {
    return m2s(a[0]) + a[1]
  } else if (a.length === 1) {
    return a[0]
  }
}

/**
 * Converts hms time to Vimeo Time
 * @param {string} hms time with format `hh:mm:ss` or `mm:ss` or `ss`
 * @returns `string`
 */
export function hmsToVimeoTime(hms = '00:00:00') {
  if (!hms) return 0

  const a = hms.split(':').map(x => +x)

  if (a.length === 3) {
    return `${a[0]}h${a[1]}m${a[2]}s`
  } else if (a.length === 2) {
    return `${a[0]}m${a[1]}s`
  } else if (a.length === 1) {
    return `${a[0]}s`
  }
}
