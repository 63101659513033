import StandaloneDonation from './components/blocks/StandaloneDonation'

export default function register() {
  return {
    blocksResolver: {
      StandaloneDonation,
    },
    blocksToolbars: [
      {
        name: 'payments',
        label: 'payments/public:payments',
        items: [StandaloneDonation],
      },
    ],
  }
}
