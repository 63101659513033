import { useCallback, useState } from 'react'

import { postFetch } from '@utils/fetcher'
import { isObject } from '@utils/types'

const baseUrl = '/api/translations'

export default function useAutomaticTranslation() {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const translate = useCallback(async ({ text, type, source, target } = {}) => {
    setIsLoading(true)

    const isTextObject = isObject(text)

    const data = await postFetch(`${baseUrl}/translate`, {
      text: isTextObject ? JSON.stringify(text) : text, // Objects need to be stringified
      type: isTextObject ? 'json' : type, // ensure type is json if text is an object
      source,
      target,
    })

    setIsLoading(false)
    setError(data?.error)

    const translatedText = data?.text

    if (!translatedText) return text

    return type === 'json' ? JSON.parse(translatedText) : translatedText
  }, [])

  return {
    translate,
    isLoading,
    error,
  }
}
