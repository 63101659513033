import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Tag from '@ui/data-display/Tag'

export default function Amenities({ amenities }) {
  const { t } = useTranslation('entities/public')

  if (!amenities) return null

  return (
    <div className="flex flex-row flex-wrap gap-2 text-sm text-gray-700">
      {amenities.accessibility.stepFreeEntrance && (
        <Tag icon="wheelchair" label={t('stepFreeEntrance')} />
      )}
      {amenities.accessibility.accessibleParkingSpots && (
        <Tag icon="parking-circle" label={t('accessibleParkingSpots')} />
      )}
      {amenities.services.parkingSpots && (
        <Tag icon="parking-circle" label={t('parkingSpots')} />
      )}
      {amenities.services.wifi && <Tag icon="wifi" label={t('wifi')} />}
      {amenities.services.nursingRoom && (
        <Tag icon="baby" label={t('nursingRoom')} />
      )}
    </div>
  )
}
Amenities.propTypes = {
  amenities: PropTypes.shape({
    accessibility: PropTypes.object,
    services: PropTypes.object,
  }),
}
Amenities.defaultProps = {}
