import { useCallback, useMemo } from 'react'

import { useUILanguage } from '@config/i18n'

import useCategories from '../services/hooks/useCategories'

const defaultOptions = {
  ids: null,
  enabled: true,
  limit: 9999,
}

export default function useCategoriesForTags(options = defaultOptions) {
  const { ids, enabled, limit } = Object.assign({}, defaultOptions, options)

  const { language } = useUILanguage()

  const { categories } = useCategories({ language, ids, enabled, limit })

  const getCategoryLabel = useCallback(
    id => {
      const category = categories?.find(c => c.id === id)
      return category?.title
    },
    [categories]
  )

  const categoryIds = useMemo(() => categories?.map(c => c.id), [categories])

  return { getCategoryLabel, categoryIds, categories }
}
