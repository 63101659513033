import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'

export function InlineResponsiveToggle({ hasValues, open, setOpen }) {
  return (
    <div className="relative">
      <Button
        icon="laptop-mobile"
        iconClassName={hasValues && !open ? 'text-primary-500' : undefined}
        size="xs"
        variant={open ? 'link' : 'flat'}
        onClick={() => setOpen(!open)}
      />
      {hasValues && !open && (
        <svg
          className="absolute w-1.5 h-1.5 fill-primary-500 top-0 right-0"
          viewBox="0 0 100 100"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="50" cy="50" r="50" />
        </svg>
      )}
    </div>
  )
}

InlineResponsiveToggle.propTypes = {
  hasValues: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
}

export default InlineResponsiveToggle
