import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

import { usePersonsPermissions } from '../helpers/permissions'
import { getPersonsUrl } from '../helpers/urls'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))

export default function PersonsMenuGroup() {
  const { t } = useTranslation('persons/public')
  const { pathname } = useLocation()
  const { entity } = useCurrentEntity()

  const { canReadPersons } = usePersonsPermissions()

  if (!entity || !canReadPersons) return null

  const inPersons = pathname.startsWith('/persons')

  return (
    <MenuGroup
      name="persons"
      label={t('persons')}
      icon="people-group"
      href={getPersonsUrl()}
      active={inPersons}
    />
  )
}
