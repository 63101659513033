import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  AutocompleteField,
  AutocompleteOption,
} from '@ui/data-entry/Autocomplete'

import useEntityTypes from '../../services/hooks/useEntityTypes'

export function TypeSelect({
  className,
  label,
  help,
  name,
  onChange,
  placeholder,
  required,
  value,
}) {
  const { t } = useTranslation('entities/public')
  const { types, loading } = useEntityTypes()
  const [search, setSearch] = React.useState('')

  const translatedTypes = React.useMemo(() => {
    if (loading || types?.length === 0) return []

    return types
      .map(type => ({
        label: t(`type_${type.name}`),
        value: type.name,
      }))
      .filter(type => type.label.includes(search))
      .sort((a, b) => a.label.localeCompare(b.label))
  }, [types, search, loading, t])

  const handleSearch = React.useCallback(value => setSearch(value), [])

  const handleChange = React.useCallback(
    value => {
      if (value) setSearch('')
      if (typeof onChange === 'function') onChange(value)
    },
    [onChange]
  )

  return (
    <AutocompleteField
      className={className}
      label={label}
      help={help}
      name={name}
      placeholder={placeholder || t('selectPlaceholder')}
      displayValue={value ? t(`type_${value}`) : ''}
      required={required}
      value={value}
      onChange={handleChange}
      searchText={search}
      onSearch={handleSearch}
    >
      {translatedTypes.map((type, key) => (
        <AutocompleteOption key={key} value={type.value} label={type.label} />
      ))}
    </AutocompleteField>
  )
}

TypeSelect.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
  allowsSubentitiesFilter: PropTypes.bool,
}
TypeSelect.defaultProps = {
  className: '',
}

export default function TypeSelectController({
  className,
  name,
  label,
  help,
  placeholder,
  shouldUnregister,
  allowsSubentitiesFilter,
}) {
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <TypeSelect
          className={className}
          label={label}
          help={help}
          name={name}
          placeholder={placeholder}
          value={field.value}
          onChange={field.onChange}
          allowsSubentitiesFilter={allowsSubentitiesFilter}
        />
      )}
    />
  )
}
TypeSelectController.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  shouldUnregister: PropTypes.bool,
  allowsSubentitiesFilter: PropTypes.bool,
}
TypeSelectController.defaultProps = {
  className: '',
}
