export const defaultGaps = [
  0, // gap-0
  0.5, // gap-0.5
  1, // gap-1
  2, // gap-2
  3, // gap-3
  4, // gap-4
  5, // gap-5
  6, // gap-6
  7, // gap-7
  8, // gap-8
  9, // gap-9
  10, // gap-10
  11, // gap-11
  12, // gap-12
  14, // gap-14
  16, // gap-16
  20, // gap-20
  24, // gap-24
  28, // gap-28
  32, // gap-32
]

export const aliasGaps = {
  none: 0,
  xs: 1,
  sm: 2,
  md: 4,
  lg: 6,
  xl: 12,
}

export const allGaps = {
  ...aliasGaps,
  ...defaultGaps.reduce((acc, spacing) => {
    acc[spacing] = spacing
    return acc
  }, {}),
}
