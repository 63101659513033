import { getSiteUrl } from './siteUrls'

export function getLayoutsUrl(site, url = '', params = '') {
  return getSiteUrl(site, `layouts${url ? `/${url}` : ''}`, params)
}

export function getAddLayoutUrl(site) {
  return getLayoutsUrl(site, 'add')
}

export function getLayoutUrl(layout, url = '', params = '') {
  if (!layout) return ''
  return getLayoutsUrl(
    { id: layout.site?.id || layout.site },
    `${layout.id}/${url}`,
    params
  )
}

export function getLayoutDetailsUrl(layout, params = '') {
  return getLayoutUrl(layout, 'details', params)
}

export function getLayoutEditUrl(layout, params) {
  return getLayoutUrl(layout, 'edit', params)
}

export function getLayoutEditDataUrl(layout, params) {
  return getLayoutUrl(layout, 'edit-data', params)
}

export function getLayoutTranslationsUrl(layout) {
  return getLayoutUrl(layout, 'edit-data', '?tab=translations')
}

export function getLayoutTranslateUrl(layout, transitionLayout) {
  return getLayoutUrl(layout, `translate/${transitionLayout.id}`)
}
