import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Header from '@ui/data-display/Header'
import Message from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'
import Link from '@ui/navigation/Link'

import AuthService from '../services/AuthService'

function useForgotPassword() {
  const [message, setMessage] = useState()
  const data = useMemo(() => ({ email: '' }), [])

  const onForgotPassword = useCallback(({ email }) => {
    const requestPasswordReset = async () => {
      const { error } = await AuthService.forgotPassword(email)
      setMessage(
        error
          ? { text: error.code, type: 'danger' }
          : { text: 'forgotPasswordEmailSent', type: 'success' }
      )
    }

    requestPasswordReset()
  }, [])

  return { data, message, onForgotPassword }
}

export default function ForgotPassword() {
  const { t } = useTranslation('auth')
  const { onForgotPassword, message, data } = useForgotPassword()

  return (
    <>
      <Header
        kicker={t('forgotPasswordKicker')}
        title={t('forgotPasswordTitle')}
        subtitle={t('forgotPasswordSubtitle')}
        headTitle={t('forgotPassword')}
      />
      {message && (
        <Message
          title={t(message.title)}
          text={t(message.text)}
          type={message.type}
        />
      )}
      <Form data={data} onSubmit={onForgotPassword}>
        <Box space="xl">
          <Input.Email name="email" label={t('email')} required />
          <Submit label={t('requestNewPassword')} />
        </Box>
      </Form>
      <p>
        <Link href="/auth/login">{t('backToLogin')}</Link>
      </p>
    </>
  )
}
