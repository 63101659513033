import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@ui/data-display/Content'
import Section from '@ui/data-display/Section'
import Tabs from '@ui/data-display/Tabs'

import AvatarUpload from '../components/AvatarUpload'
import ChangePasswordForm from '../components/ChangePasswordForm'
import DateTimeForm from '../components/DateTimeForm'
import LanguageForm from '../components/LanguageForm'
import TwoFactorAuth from '../components/TwoFactorAuth'
import UserForm from '../components/UserForm'
import useCurrentUser from '../helpers/useCurrentUser'

export default function Settings() {
  const { t } = useTranslation(['auth', 'users'])
  const { user } = useCurrentUser()

  return (
    <>
      {user && (
        <Content>
          <Content.Header
            title={user?.name}
            subtitle={user?.email}
            kicker={t('settings')}
            headTitle={t('settings')}
          />
          <Content.Tabs>
            <Tabs>
              <Tabs.Pane className="gap-10" name="profile" title={t('profile')}>
                <Section id="avatar" title={t('avatar')}>
                  <AvatarUpload user={user} />
                </Section>
                <Section id="personal-information" title={t('personalInfo')}>
                  <UserForm user={user} />
                </Section>
              </Tabs.Pane>

              <Tabs.Pane
                className="gap-10"
                name="security"
                title={t('security')}
              >
                <Section id="two-factor" title={t('2FA')}>
                  <TwoFactorAuth user={user} />
                </Section>

                <Section id="change-password" title={t('changePassword')}>
                  <ChangePasswordForm user={user} />
                </Section>
              </Tabs.Pane>

              <Tabs.Pane
                name="preferences"
                title={t('preferences')}
                className="gap-10"
              >
                <Section title={t('languages')}>
                  <LanguageForm user={user} />
                </Section>
                <Section title={t('dateTime')}>
                  <DateTimeForm user={user} />
                </Section>
              </Tabs.Pane>
            </Tabs>
          </Content.Tabs>
        </Content>
      )}
    </>
  )
}
