import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useSite from '@modules/web/services/hooks/useSite'
import Message from '@ui/data-display/Message'
import {
  CheckboxField as Checkbox,
  CheckboxField,
} from '@ui/data-entry/Checkbox'

const config = {
  name: 'CoursesStudentNotifications',
  label: 'courses/public:studentNotifications',
  help: 'courses/public:studentNotificationsHelp',
  type: 'plugin',
  icon: 'bell',
  component: <StudentNotifications />,
}

export default function StudentNotifications({
  newMessage,
  questionnaireCorrected,
  courseFinished,
}) {
  const { t } = useTranslation(['courses/students', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const errors = []

  if (!authEnabled) {
    errors.push({ title: t('web-auth/public:authDisabledNotification') })
  }

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      errors={errors}
    >
      {authEnabled ? (
        <div className="space-y-6 p-4">
          <h1 className="text-3xl font-bold">{t('notificationSettings')}</h1>
          <div className="space-y-4">
            {newMessage && (
              <div>
                <div>{t('newMessage')}</div>
                <CheckboxField label={t('pushNotifications')} />
                <CheckboxField label={t('email')} />
              </div>
            )}
            {questionnaireCorrected && (
              <div>
                <div>{t('questionnaireCorrected')}</div>
                <CheckboxField label={t('pushNotifications')} />
                <CheckboxField label={t('email')} />
              </div>
            )}
            {courseFinished && (
              <div>
                <div>{t('courseFinished')}</div>
                <CheckboxField label={t('pushNotifications')} />
                <CheckboxField label={t('email')} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="p-2">
          <Message
            type="warn"
            text={t('web-auth/public:authDisabledNotification')}
          />
        </div>
      )}
    </Content>
  )
}
StudentNotifications.propTypes = {
  newMessage: PropTypes.bool,
  questionnaireCorrected: PropTypes.bool,
  courseFinished: PropTypes.bool,
}
StudentNotifications.toolbarItem = config

function StudentNotificationsSettings() {
  const { t } = useTranslation(['courses/students', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Checkbox
        label={t('newMessage')}
        onChange={value => {
          actions.setProp(props => (props.newMessage = value))
        }}
        value={data.newMessage}
        name="newMessage"
        disabled={!authEnabled}
      />
      <Checkbox
        label={t('questionnaireCorrected')}
        onChange={value => {
          actions.setProp(props => (props.questionnaireCorrected = value))
        }}
        value={data.questionnaireCorrected}
        name="questionnaireCorrected"
        disabled={!authEnabled}
      />
      <Checkbox
        label={t('courseFinished')}
        onChange={value => {
          actions.setProp(props => (props.courseFinished = value))
        }}
        value={data.courseFinished}
        name="courseFinished"
        disabled={!authEnabled}
      />
    </SettingsWrap>
  )
}

StudentNotifications.craft = {
  displayName: config.name,
  props: {
    newMessage: true,
    questionnaireCorrected: true,
    courseFinished: true,
  },
  custom: {
    type: config.type,
    resources: ['coursesStudentNotifications'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: StudentNotificationsSettings,
  },
}
