import { useCallback } from 'react'

export function useSides(keys) {
  const onChange = useCallback(
    field => path => event => {
      const pathParts = path.split('.')
      const side = pathParts[pathParts.length - 1]
      const newValue = event.target.value

      const fieldValue =
        typeof field.value !== 'object'
          ? keys.reduce((acc, key) => ({ ...acc, [key]: field.value }), {})
          : field.value

      field.onChange({
        ...fieldValue,
        [side]: newValue || null,
      })
    },
    [keys]
  )

  const onChangeAll = useCallback(
    field => () => event => {
      const newValue = event.target.value
      if (!newValue) {
        field.onChange(keys.reduce((acc, key) => ({ ...acc, [key]: null }), {}))
      } else {
        field.onChange(
          keys.reduce((acc, key) => ({ ...acc, [key]: newValue }), {})
        )
      }
    },
    [keys]
  )

  const isMixed = useCallback(
    field => {
      const value = field.value
      if (typeof value !== 'object') {
        return false
      }
      const firstValue = value[keys[0]]
      return keys.some(key => value[key] !== firstValue)
    },
    [keys]
  )

  return {
    onChangeAll,
    onChange,
    isMixed,
  }
}

export default useSides
