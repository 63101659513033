import { useCallback, useMemo } from 'react'

import { useEditor } from '@craftjs/core'

import { useClipboardContext } from '../../ClipboardProvider'

function pasteNode(
  query,
  actions,
  node,
  parentId,
  indexToInsert,
  selectNodeAfterCreated = false
) {
  const freshNode = {
    data: {
      ...node.data,
      nodes: [],
    },
  }

  const nodeToAdd = query.parseFreshNode(freshNode).toNode()

  actions.add(nodeToAdd, parentId, indexToInsert)

  if (node.nodes.length === 0) {
    return
  }

  node.nodes.forEach((childNode, index) => {
    pasteNode(query, actions, childNode, nodeToAdd.id, index)
  })

  if (selectNodeAfterCreated) actions.selectNode(nodeToAdd.id)
}

export default function usePasteNode(targetId) {
  const { nodeToPaste } = useClipboardContext()
  const { actions, query } = useEditor()

  const targetNode = query.node(targetId).get()

  const canPasteIn = useMemo(() => {
    if (!targetNode || !nodeToPaste) return false
    const { canMoveIn } = targetNode.rules

    return nodeToPaste && typeof canMoveIn === 'function'
      ? canMoveIn(nodeToPaste.data.name)
      : false
  }, [targetNode, nodeToPaste])

  const indexToAdd = targetNode.data.nodes.length

  const onPasteNode = useCallback(() => {
    if (!canPasteIn) return

    pasteNode(query, actions, nodeToPaste, targetId, indexToAdd, true)
  }, [actions, query, targetId, indexToAdd, nodeToPaste, canPasteIn])

  return {
    onPasteNode,
    canPasteIn,
    nodeToPaste,
    nodeToPasteType: nodeToPaste?.data?.name,
    nodeToPasteName: nodeToPaste?.data?.props?.name,
  }
}
