import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

const sizeStyles = {
  sm: {
    wrap: 'h-6 w-12',
    toggle: 'h-6 w-6',
    toggleActive: 'ml-6',
    label: 'hidden', // dont show label on small
  },
  md: {
    wrap: 'h-8 w-16',
    toggle: 'h-8 w-8',
    toggleActive: 'ml-8 ',
    label: 'text-xs w-8 h-8',
    labelActive: 'left-px',
    labelInactive: 'right-px',
  },
  lg: {
    wrap: 'h-10 w-20',
    toggle: 'h-10 w-10',
    toggleActive: 'ml-10',
    label: 'text-sm w-10 h-10',
    labelActive: 'left-0.5',
    labelInactive: 'right-0.5',
  },
}

const colorStyles = {
  succces: 'bg-success-500',
  primary: 'bg-primary-500',
  danger: 'bg-danger-500',
  warn: 'bg-warn-500',
  info: 'bg-info-500',
  favorite: 'bg-favorite-500',
  ai: 'bg-ai-500',
  gray: 'bg-gray-500',
}

export default function ToggleButton({
  active,
  activeColor = 'success',
  className = '',
  disabled,
  labelActive = '',
  labelInactive = '',
  onChange,
  showLabel = false,
  size = 'md',
  tooltip,
}) {
  const { t } = useTranslation()
  const activeColorClass = colorStyles[activeColor] || colorStyles.succces
  const activeClass = active ? activeColorClass : 'bg-gray-200'
  const disabledClass = disabled
    ? 'opacity-50 cursor-not-allowed'
    : 'cursor-pointer'
  const sizeClasses = sizeStyles[size] || sizeStyles.md

  React.useEffect(() => {
    if (showLabel && size === 'sm') {
      // eslint-disable-next-line no-console
      console.warn(
        'ToggleButton: Label is not shown on size="sm". Please use "md" or "lg" for showLabel="true".'
      )
    }
  }, [showLabel, size])

  return (
    <button
      className={`relative cursor-pointer rounded-full border border-gray-300 transition-colors duration-300 ease-in-out ${activeClass} ${disabledClass} ${sizeClasses.wrap} ${className}`}
      onClick={disabled ? undefined : onChange}
      aria-label={tooltip || 'toggle'}
      role="switch"
      aria-checked={active}
    >
      <span
        className={`-mt-px block rounded-full border border-gray-300 bg-white shadow-md transition-all duration-300 ease-in-out ${
          sizeClasses.toggle
        } ${active ? sizeClasses.toggleActive : '-ms-px'} `}
      />
      {showLabel && (
        <span
          className={`absolute flex items-center justify-center top-0 -mt-px ${sizeClasses.label} ${active ? `text-white/80 ${sizeClasses.labelActive}` : `text-gray-400/80 ${sizeClasses.labelInactive}`}`}
        >
          {active ? labelInactive || t('yes') : labelActive || t('no')}
        </span>
      )}
    </button>
  )
}
ToggleButton.propTypes = {
  active: PropTypes.bool,
  activeColor: PropTypes.oneOf([
    'succces',
    'primary',
    'danger',
    'warn',
    'info',
    'favorite',
    'ai',
    'gray',
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  labelActive: PropTypes.node,
  labelInactive: PropTypes.node,
  onChange: PropTypes.func,
  showLabel: PropTypes.bool,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  tooltip: PropTypes.string,
}
