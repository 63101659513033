import { getFetch } from '@utils/fetcher'
import { useQuery } from 'react-query'

export default function useOrganizersByIds({
  ids = [],
  sort,
  includeDisabled = false,
  queryOptions = {},
} = {}) {
  const { data, ...rest } = useQuery(
    ['event-organizers', sort, ...ids, includeDisabled],
    () =>
      getFetch('/api/event-organizers', {
        filter: {
          enabled: !includeDisabled,
          _id: { $in: ids },
        },
        sort,
      }),
    { enabled: Array.isArray(ids), ...queryOptions }
  )
  return {
    organizers: data?.items ?? [],
    count: data?.count ?? 0,
    ...rest,
  }
}
