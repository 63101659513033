import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import {
  addDays,
  addMonths,
  eachDayOfInterval,
  endOfMonth,
  getDay,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  isValid,
  setHours,
  setMinutes,
  setMonth,
  setYear,
  startOfMonth,
  startOfWeek,
  subMonths,
} from 'date-fns'

import { Input } from '@ui/data-entry/Input'
import { Select, SelectOption } from '@ui/data-entry/Select'
import Clickable from '@ui/helpers/Clickable'
import { useDatetimeLocale } from '@ui/helpers/datetime'
import { range } from '@utils/arrays'

import { addDayToRange, formatDate, isDaySelected } from '../utils'
import { checkDateRange } from '../utils'
import { Day } from './Day'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const weekStarts = [
  'col-start-1',
  'col-start-2',
  'col-start-3',
  'col-start-4',
  'col-start-5',
  'col-start-6',
  'col-start-7',
]

const now = new Date()
const firstDOW = startOfWeek(now)

const getInitialDisplayedDate = (type, date) => {
  if (type === 'daterange') {
    return isValid(date.from ?? date.to) ? date.from ?? date.to : new Date()
  }
  return isValid(date) ? date : new Date()
}

export function DatePicker({ type = 'date', date, onChange, min, max }) {
  const locale = useDatetimeLocale()

  const [displayedMonth, setDisplayedMonth] = useState(
    getInitialDisplayedDate(type, date)
  )

  useEffect(() => {
    if (isValid(date)) {
      setDisplayedMonth(prevDisplayedDate =>
        setMonth(setYear(prevDisplayedDate, getYear(date)), getMonth(date))
      )
    }
  }, [date])

  const monthFirstDay = startOfMonth(displayedMonth)
  const monthLastDay = endOfMonth(displayedMonth)
  const monthFirstDayOfWeek = getDay(monthFirstDay)

  const shortWeekDaysArray = range(0, 6).map(i =>
    formatDate(addDays(firstDOW, i), 'EEEEEE', { locale })
  )

  const monthDays = eachDayOfInterval({
    start: monthFirstDay,
    end: monthLastDay,
  })

  const months = range(0, 11)

  const handleDayClick = day => {
    if (type === 'daterange') {
      const dateRange = addDayToRange(day, date)
      onChange({
        from: dateRange.from || undefined,
        to: dateRange.to || undefined,
      })
    } else {
      const time = date ?? new Date()
      onChange(setHours(setMinutes(day, getMinutes(time)), getHours(time)))
    }
  }

  return (
    <div className="flex select-none flex-col space-y-4 md:min-h-[318px]">
      <div className="flex items-center justify-between space-x-4">
        <Clickable
          className="p-1 text-xs"
          onClick={() =>
            setDisplayedMonth(prevDisplayedDate =>
              subMonths(prevDisplayedDate, 1)
            )
          }
        >
          <Icon name="chevron-left" />
        </Clickable>
        <div className="flex flex-grow flex-row items-center justify-center space-x-2 text-xl">
          <Select
            className="font-bold"
            value={getMonth(displayedMonth)}
            onChange={({ currentTarget }) => {
              setDisplayedMonth(prevDisplayedDate =>
                setMonth(prevDisplayedDate, currentTarget.value)
              )
            }}
          >
            {months.map(month => (
              <SelectOption
                key={`month-${month}`}
                value={month}
                label={formatDate(setMonth(displayedMonth, month), 'LLLL', {
                  locale,
                })}
              />
            ))}
          </Select>
          <Input
            className="w-20"
            fullWidth={false}
            onChange={({ currentTarget }) => {
              const newYear = currentTarget.value
              if (newYear >= -6000 && newYear <= 3000) {
                setDisplayedMonth(prevDisplayedDate =>
                  setYear(prevDisplayedDate, newYear)
                )
              }
            }}
            type="number"
            value={getYear(displayedMonth).toString()}
          />
        </div>
        <Clickable
          className="p-1 text-xs"
          onClick={() =>
            setDisplayedMonth(prevDisplayedDate =>
              addMonths(prevDisplayedDate, 1)
            )
          }
        >
          <Icon name="chevron-right" />
        </Clickable>
      </div>
      <div className="grid min-w-full grid-cols-7 gap-1">
        {shortWeekDaysArray.map(d => (
          <div
            className="flex items-center justify-center p-1 font-semibold"
            key={`weekday-${d}`}
          >
            {d}
          </div>
        ))}
        {monthDays.map((day, i) => (
          <Day
            className={`${i === 0 ? weekStarts[monthFirstDayOfWeek] : ''}`}
            selected={isDaySelected(type, day, date)}
            disabled={!checkDateRange(day, min, max, type)}
            date={day}
            key={`day-${i + 1}`}
            onClick={() => handleDayClick(day)}
          />
        ))}
      </div>
    </div>
  )
}

DatePicker.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
    PropTypes.shape({
      from: PropTypes.string,
      to: PropTypes.string,
    }),
  ]),
  type: PropTypes.oneOf(['date', 'datetime', 'daterange']),
  min: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  max: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  onChange: PropTypes.func,
}
