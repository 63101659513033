import React from 'react'

import config from './config'

const RelatedArticlesSettings = React.lazy(() => import('./Settings'))
const RelatedArticlesBlock = React.lazy(() => import('./Block'))

export default function RelatedArticles(props) {
  return <RelatedArticlesBlock {...props} />
}

RelatedArticles.toolbarItem = {
  ...config,
  component: <RelatedArticles />,
}

RelatedArticles.craft = {
  displayName: 'RelatedArticles',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: RelatedArticlesSettings,
  },
}
