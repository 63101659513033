import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'
import useEntityPermissions from '@modules/entities/services/hooks/useEntityPermissions'
import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import useWebAuthPermissions from '@modules/web-auth/services/hooks/useWebAuthPermissions'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

export default function AdminMenuGroup() {
  const { t } = useTranslation([
    'entities/public',
    'users/public',
    'languages/public',
    'clients/public',
    'instances/public',
    'logs/public',
    'tasks/public',
    'data-import/public',
    'feature-flags/public',
    'web-auth/public',
    'search',
  ])
  const { pathname } = useLocation()

  const { canReadSubentities } = useEntityPermissions()
  const canReadNetworks = useIsUserAuthorized({
    module: 'networks',
    permission: 'read',
  })
  const canReadUsers = useIsUserAuthorized({
    module: 'users',
    permission: 'read',
    // noEntityCheck: true, // If they can read users, they can read all users of entities they can navigate to.
  })
  const canReadGroups = useIsUserAuthorized({
    module: 'groups',
    permission: 'read',
    // noEntityCheck: true, // If they can read groups, they can read all groups of entities they can navigate to.
    // // This way if they need to add a user to a group below, they can manage that.
  })

  const canReadLanguages = useIsUserAuthorized({
    module: 'languages',
    permission: 'read',
  })
  const canReadCountries = useIsUserAuthorized({
    module: 'countries',
    permission: 'read',
  })

  const { canReadWebUsers, canReadWebUserCollections } = useWebAuthPermissions()

  const inCurrentEntity = pathname.startsWith('/entities/current')
  const inEntities = pathname.startsWith('/entities')
  const inNetworks = pathname.startsWith('/entities/networks')

  const inUsers = pathname.startsWith('/users')
  const inGroups = pathname.startsWith('/groups')

  const inWebAuth = location.pathname.startsWith('/web-auth')

  const isAdmin = useIsUserAuthorized({ admin: true })
  const inClients = pathname.startsWith('/clients')
  const inInstances = pathname.startsWith('/instances')
  const inSearch = pathname.startsWith('/search')
  const inLanguages = pathname.startsWith('/languages')
  const inCountries = pathname.startsWith('/countries')
  const inLogs = pathname.startsWith('/logs')
  const inTasks = pathname.startsWith('/tasks')
  // const inTasksGroups = pathname.startsWith('/tasks/groups')
  const inFeatureFlags = pathname.startsWith('/feature-flags')
  const inSyncs = pathname.startsWith('/syncs')

  const isUserLoginEnabled = useFeatureFlagEnabled('user-login', {
    strategyOverride: { design: true },
  })

  // If the user can't read subentities, users, groups or is not admin, we only display the current entity
  if (
    !canReadSubentities &&
    !canReadNetworks &&
    !canReadUsers &&
    !canReadGroups &&
    !isAdmin &&
    !canReadLanguages &&
    !canReadCountries &&
    !canReadWebUsers &&
    !canReadWebUserCollections
  ) {
    return null
  }

  return (
    <MenuGroup
      name="admin"
      label={t('adminArea')}
      icon="screwdriver-wrench"
      active={
        inEntities ||
        inNetworks ||
        inUsers ||
        inGroups ||
        inClients ||
        inLogs ||
        inSyncs ||
        inTasks ||
        inWebAuth ||
        inSearch
      }
    >
      {canReadSubentities && (
        <MenuItem
          active={inEntities && !inCurrentEntity && !inNetworks}
          label={t('entities/public:entities')}
          href="/entities"
          icon="university"
        />
      )}
      {canReadNetworks && (
        <MenuItem
          active={inNetworks}
          label={t('entities/public:networks')}
          href="/entities/networks"
          icon="chart-network"
        />
      )}
      {canReadUsers && (
        <>
          {canReadSubentities && <MenuDivider />}
          <MenuItem
            active={inUsers}
            label={t('users/public:users')}
            href="/users"
            icon="users"
          />
          {canReadGroups && (
            <MenuItem
              active={inGroups}
              label={t('users/public:groups')}
              href="/groups"
              icon="users-class"
            />
          )}
        </>
      )}
      {canReadLanguages && (
        <>
          {(canReadSubentities || canReadUsers) && <MenuDivider />}
          <MenuItem
            active={inLanguages}
            label={t('languages/public:languages')}
            href="/languages"
            icon="flag"
          />
        </>
      )}
      {canReadCountries && (
        <>
          <MenuItem
            active={inCountries}
            label={t('countries/public:countries')}
            href="/countries"
            icon="earth-americas"
          />
        </>
      )}

      {isUserLoginEnabled && (canReadWebUserCollections || canReadWebUsers) && (
        <MenuItem
          label={t('web-auth/public:authentication')}
          expanded={inWebAuth}
          icon="lock"
        >
          {canReadWebUserCollections && (
            <MenuItem
              active={location.pathname.startsWith('/web-auth/collections')}
              label={t('web-auth/public:userCollections')}
              href="/web-auth/collections"
              icon="users-between-lines"
            />
          )}
          {canReadWebUsers && (
            <MenuItem
              active={location.pathname.startsWith('/web-auth/users')}
              label={t('web-auth/public:users')}
              href="/web-auth/users"
              icon="users"
            />
          )}
        </MenuItem>
      )}
      {/* Only when the user is a global admin, the following menu items are displayed */}
      {isAdmin && (
        <>
          <MenuDivider />
          <MenuItem
            expanded={inClients || inInstances}
            label={t('apis')}
            icon="network-wired"
          >
            <MenuItem
              active={inClients}
              label={t('clients/public:clients')}
              help={t('clients/public:clientsHelp')}
              href="/clients"
              icon="network-wired"
            />
            <MenuItem
              active={inInstances}
              label={t('instances/public:instances')}
              help={t('instances/public:instancesHelp')}
              href="/instances"
              icon="diagram-project"
            />
          </MenuItem>
          <MenuItem
            active={inLogs}
            label={t('logs/public:logs')}
            href="/logs"
            icon="list"
          />
          <MenuItem
            active={inTasks}
            label={t('tasks/public:tasks')}
            href="/tasks/tasks"
            icon="indent"
          >
            {/* <MenuItem
              active={inTasksGroups}
              label={t('tasks/public:groups')}
              href="/tasks/groups"
              icon="indent"
            /> */}
          </MenuItem>
          <MenuItem
            active={inFeatureFlags}
            label={t('feature-flags/public:featureFlags')}
            href="/feature-flags"
            icon="flag-pennant"
          />
          <MenuItem
            active={inSyncs}
            label={t('data-import/public:syncs')}
            href="/syncs"
            icon="sync-alt"
          />
        </>
      )}
    </MenuGroup>
  )
}
