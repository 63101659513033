import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { CheckboxField } from '@ui/data-entry/Checkbox'

export function ToggleAllCheckbox({ disabled, onChange, value }) {
  const { t } = useTranslation()
  return (
    <CheckboxField
      className="border-b border-dashed pb-2"
      disabled={disabled}
      label={t('selectAll')}
      onChange={onChange}
      value={value}
    />
  )
}
ToggleAllCheckbox.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.bool,
}
