import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useValueAtBreakpoint from '../useValueAtBreakpoint'

// Relative line heights
const relativeLineHeights = [
  'none', // leading-none
  'tight', // leading-tight
  'snug', // leading-snug
  'normal', // leading-normal
  'relaxed', // leading-relaxed
  'loose', // leading-loose
]

// Fixed line heights
const fixedLineHeights = [
  '3', // leading-3
  '4', // leading-4
  '5', // leading-5
  '6', // leading-6
  '7', // leading-7
  '8', // leading-8
  '9', // leading-9
  '10', // leading-10
]

const lineHeightsOptions = [
  {
    name: 'relative',
    options: relativeLineHeights,
  },
  {
    name: 'fixed',
    options: fixedLineHeights,
  },
]

const lineHeights = [...relativeLineHeights, ...fixedLineHeights]

/**
 * Hook to get the line height options.
 *
 * @returns {object[]} - The options
 */
export function useLineHeightOptions() {
  const { t } = useTranslation('web/content-editor')

  // return the options (array of objects with label and value)
  return useMemo(() => {
    return lineHeightsOptions.map(({ name, options }) => {
      return {
        label: t(`lineHeight_${name}`),
        options: options.map(value => ({
          label:
            name === 'relative'
              ? t(`lineHeight_relative_${value}`)
              : `${parseInt(value) * 4}px`,
          value,
        })),
      }
    })
  }, [t])
}

/**
 * Hook to get the line height class name for a given value.
 * @param {string} value - The line height value
 * @param {string} [defaultValue] - The default size
 * @returns {string} - The class name
 * @example
 * const lineHeightClass = useBlockLineHeightClass('normal')
 * // -> 'leading-normal'
 */
export function useLineHeightStyles(value, defaultValue = 'none') {
  return {
    className: `leading-${lineHeights.includes(value) ? value : defaultValue}`,
    style: {},
  }
}

/**
 * Hook to get the line height class name for a given responsive value.
 *
 * @param {string} value - The line height value
 * @param {string} [defaultValue] - The default value
 * @returns {string} - The class name
 */
export function useBlockLineHeightClass(value, defaultValue = 'none') {
  const { value: valueAtBreakpoint } = useValueAtBreakpoint(value, defaultValue)

  return useLineHeightStyles(valueAtBreakpoint, defaultValue).className
}
