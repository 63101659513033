import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import PaginationFields from '@modules/web/components/ContentEditor/blocks/shared/PaginationFields'
import SortFields from '@modules/web/components/ContentEditor/blocks/shared/SortFields'
import ColumnsSelect from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'
import usePage from '@modules/web/services/hooks/usePage'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'

import DisplayModeFields from '../shared/DisplayModeFields'
import FieldsToggleFields from '../shared/FieldsToggleFields'
import FilterFields from '../shared/FilterFields'
import ReligionSelect from '../shared/ReligionSelect'
import VariantFields from '../shared/VariantFields'
import config from './config'

export default function PublicationsListSettings() {
  const { t } = useTranslation('publications/public')
  const { page } = usePage()
  const { useCardPadding, variants } = useBlock(config.name)

  const { dynamic, dynamicResource } = page ?? {}

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { displayModes, showLabel } =
    variants?.[data.variant] || variants?.list || {}

  // Ensures that the selected flag is removed from flagExceptions
  React.useEffect(() => {
    if (data.flagExceptions?.includes(data.flag)) {
      actions.setProp(
        data =>
          (data.flagExceptions = data.flagExceptions.filter(
            id => id !== data.flag
          ))
      )
    }
  }, [actions, data])

  return (
    <SettingsWrap
      title={t('PublicationsList')}
      help={t('PublicationsListHelp')}
    >
      <div className="space-y-4">
        {dynamic && dynamicResource.name === 'PublicationReligion' && (
          <Message
            title={t('dynamicReligionFilter')}
            text={t('dynamicReligionFilterMessage')}
            variant="info"
          />
        )}

        <ReligionSelect />

        <Checkbox
          name="featured"
          value={data.featured}
          label={t('featured')}
          help={t('featuredHelp')}
          onChange={value => actions.setProp(props => (props.featured = value))}
        />

        <SortFields />
        <PaginationFields />

        <VariantFields variants={variants} />

        {displayModes && <DisplayModeFields displayModes={displayModes} />}

        {showLabel && (
          <InputField
            label={t('label')}
            name="label"
            onChange={e =>
              actions.setProp(props => (props.label = e.target.value))
            }
            value={data.label}
          />
        )}
        <ColumnsSelect />

        {data.displayMode === 'cards' && useCardPadding && (
          <Checkbox
            name="useCardPadding"
            value={data.useCardPadding}
            label={t('useCardPadding')}
            help={t('useCardPaddingHelp')}
            onChange={value =>
              actions.setProp(props => (props.useCardPadding = value))
            }
          />
        )}
        <FieldsToggleFields />
        <LinkingFields
          showListPagePicker={false}
          resourceIdentifier="Publication"
        />
        <CollapsablePanel title={t('filters')}>
          <FilterFields />
          <InputField
            label={t('publications/public:allLabel')}
            help={t('publications/public:allLabelHelp')}
            name="allLabel"
            onChange={e =>
              actions.setProp(props => (props.allLabel = e.target.value))
            }
            value={data.allLabel}
          />
        </CollapsablePanel>
      </div>
    </SettingsWrap>
  )
}
