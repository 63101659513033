import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import Box from '@ui/layout/Box'

const config = {
  name: 'CoursesMessages',
  label: 'courses/public:chatMessages',
  help: 'courses/public:chatMessagesHelp',
  type: 'plugin',
  icon: 'messages',
  component: <CoursesMessages />,
}

export default function CoursesMessages({ dynamic }) {
  const { t } = useTranslation('courses/public')
  const { breakpoint } = useBreakpoint()

  const messages = [
    'OK, thank you',
    `That's a great choice! Having a dedicated advisor will ensure you receive tailored support. We can start anytime you want, just let me know when you're ready.`,
    `Thank you! I'm excited to start learning. I've chosen the option with a personal advisor to guide me through the course.`,
    'Hello and welcome to the Hope Courses. Nice that you signed up!',
  ]

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="flex divide-x">
        <div
          className={`flex w-full justify-center ${
            breakpoint === 'xs' ? 'p-2' : 'p-2'
          }`}
        >
          <div className="max-w-md text-sm divide-y">
            {messages.map((message, key) => (
              <div className="py-3 flex gap-2" key={key}>
                <div>
                  <div className="font-semibold">
                    {key % 2 === 0 ? t('student') : t('advisor')}
                  </div>
                  <div>{message}</div>
                </div>
              </div>
            ))}
          </div>
        </div>
        {breakpoint !== 'xs' && (
          <div
            className={`divide-y ${breakpoint === 'xs' ? 'w-full' : 'w-1/3'}`}
          >
            {Array.from({ length: 5 }).map((chat, key) => (
              <div className="px-2 py-2 text-sm hover:bg-gray-200" key={key}>
                [Advisor {key + 1}]
              </div>
            ))}
          </div>
        )}
      </div>
    </Content>
  )
}
CoursesMessages.propTypes = {
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
}
CoursesMessages.toolbarItem = config

function CoursesMessagesSettings() {
  const { t } = useTranslation('courses/public')

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
      </Box>
    </SettingsWrap>
  )
}

CoursesMessages.craft = {
  displayName: config.name,
  props: {},
  custom: {
    type: config.type,
    resources: ['coursesChats'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CoursesMessagesSettings,
  },
}
