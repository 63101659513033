import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockField from '../BlockField'
import TextSizeSelectField from './Field'

/**
 * Select field for font weight to be used in the content editor exclusively.
 * @param {object} props - Component props
 * @param {string} [props.name] - Name of the field
 * @param {string} [props.label] - Optional label of the field
 * @param {boolean} [props.lightLabel] - Whether the label should be light
 * @param {string[]} [props.availableSizes] - Optional list of available sizes
 * @param {string} [props.defaultTextSize] - Default text size
 * @returns {ReactElement} - The component
 */
export default function BlockTextSizeSelect({
  allowCustom,
  availableSizes,
  defaultTextSize = 'md',
  label,
  lightLabel,
  name = 'textSize',
  help,
}) {
  const { t } = useTranslation('web/content-editor')

  return (
    <BlockField defaultValue={defaultTextSize} name={name} responsive>
      {({ fieldProps, inheritFrom, inheritValue, onDataChange, value }) => (
        <TextSizeSelectField
          {...fieldProps}
          allowCustom={allowCustom}
          availableSizes={availableSizes}
          help={help}
          inheritFrom={inheritFrom}
          inheritValue={inheritValue}
          label={label || t('textSize')}
          lightLabel={lightLabel}
          onChange={event => onDataChange(event.currentTarget.value)}
          value={value}
        />
      )}
    </BlockField>
  )
}
BlockTextSizeSelect.propTypes = {
  allowCustom: PropTypes.bool,
  availableSizes: PropTypes.arrayOf(PropTypes.string),
  defaultTextSize: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  lightLabel: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}
