import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { escapeSpecialChars } from '@utils/strings'

/**
 * Get Publications Religions list
 * @param {String} params.search - Search term
 * @param {Array} params.statuses - Statuses to filter by
 * @param {String} params.type - Type to filter by
 * @param {Boolean} params.fetchOnSearch - Fetch on search
 * @param {Number} params.limit - Limit results to this number
 * @param {Number} params.page - Page number
 * @param {Boolean} params.pagination - Whether to use pagination
 * @param {String} params.sort - Sort by field
 * @param {Number} params.skip - Skip this number of results
 * @returns {Object} Query object
 * @returns {Array} Query.religions - Religions list
 * @returns {Number} Query.count - Total number of religions
 */
export default function usePublicationReligions({
  search = '',
  statuses = ['active'],
  type = '',
  fetchOnSearch = false,
  limit = 10,
  page = 1,
  pagination = false,
  sort = '',
  skip = 0,
} = {}) {
  const { data, error, isLoading, isFetching } = useQuery(
    [
      'publications-religions',
      {
        type,
        search,
        statuses,
        limit,
        sort,
        skip,
        pagination,
        page,
      },
    ],
    () =>
      getFetch('/api/publications-religions', {
        type,
        statuses,
        search: escapeSpecialChars(search),
        sort,
        limit,
        skip: pagination ? null : skip,
        page,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    religions: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    isLoading,
    isFetching,
  }
}
