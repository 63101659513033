import React from 'react'
import PropTypes from 'prop-types'

const styles = {
  p: '',
  blockquote: 'pl-4 py-2 border-l-2 font-serif text-xl italic',
}

export default function Text({ as, children, text, className }) {
  return React.createElement(
    as,
    { className: `${styles[as]} ${className}` },
    children || text
  )
}

Text.propTypes = {
  as: PropTypes.oneOf(['p', 'blockquote']),
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
}
Text.defaultProps = {
  as: 'p',
  className: '',
}
