import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'

import useBreakpoint from './useBreakpoint'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function InlineResponsiveControls({
  breakpoint,
  dataValue,
  icons,
  open,
  setBreakpoint,
}) {
  const { breakpointKeys } = useBreakpoint()

  if (!open) return null

  const responsiveDataValue =
    typeof dataValue === 'object' ? dataValue : { xs: dataValue }

  return (
    <div className="border-t py-3 bg-gray-50 -mb-1">
      <div className="flex justify-around">
        {breakpointKeys.map(bp => (
          <Button
            key={`breakpoint_${bp}`}
            icon={icons[bp]}
            size="sm"
            variant={breakpoint === bp ? 'primary' : 'basic'}
            className="w-8"
            onClick={() => setBreakpoint(bp)}
          />
        ))}
      </div>
      <div className="flex justify-around">
        {breakpointKeys.map(bp => (
          <span
            key={`breakpoint-value-indicator-${bp}`}
            className="flex justify-center"
          >
            <Icon
              name="period"
              className={
                responsiveDataValue?.[bp] ? 'text-primary-500' : 'text-gray-300'
              }
            />
          </span>
        ))}
      </div>
    </div>
  )
}

InlineResponsiveControls.propTypes = {
  breakpoint: PropTypes.string,
  dataValue: PropTypes.object,
  icons: PropTypes.object,
  open: PropTypes.bool,
  setBreakpoint: PropTypes.func.isRequired,
}

export default InlineResponsiveControls
