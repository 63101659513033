import { useCallback, useRef, useState } from 'react'

import { useMutation } from 'react-query'

import { fileUpload } from '@utils/fetcher'

const baseUrl = '/api/documents'

export function useDocumentUpload({ folder = '', privateUpload = false } = {}) {
  const audioBuffer = useRef({})
  const [progress, setProgress] = useState()
  const [uploading, setUploading] = useState(false)

  const { mutate, error, isLoading } = useMutation(async file =>
    fileUpload(`${baseUrl}/upload`, file, {
      onStart: () => setUploading(true),
      onComplete: () => setUploading(false),
      onProgress: percentage => setProgress(percentage),
      folder,
      privateUpload,
    })
  )

  const onDocumentUpload = useCallback(
    (file, onChange, onError) => {
      return mutate(file, {
        onSuccess: data => onChange(data),
        onError,
      })
    },
    [mutate]
  )

  const onAudioUpload = useCallback(
    (file, onChange, onError) => {
      const reader = new FileReader()

      reader.onload = async function (event) {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)()

        audioBuffer.current = await audioContext.decodeAudioData(
          event.target.result
        )

        return mutate(file, {
          onSuccess: data => {
            onChange({ ...data, duration: audioBuffer.current?.duration })
          },
          onError,
        })
      }

      if (file) {
        reader.readAsArrayBuffer(file)
      }
    },
    [mutate]
  )

  return {
    onDocumentUpload,
    onAudioUpload,
    error,
    isLoading,
    progress,
    uploading,
  }
}

// Uploads multiple documents. Current limit is 20 unless you change the limit in the env variable of the API
export function useDocumentsUpload() {
  const [progress, setProgress] = useState()

  const { mutate, error, isLoading } = useMutation(async files =>
    fileUpload(`${baseUrl}/upload-multiple`, files, {
      multiple: true,
      onProgress: percentage => setProgress(percentage),
    })
  )

  function onDocumentsUpload(files, onChange) {
    return mutate(files, {
      onSuccess: data => onChange(data),
    })
  }

  return { onDocumentsUpload, error, isLoading, progress }
}
