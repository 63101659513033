import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockField from '../BlockField'
import WidthSelect from './Field'

export default function BlockWidthSelect({ name = 'width', label, help }) {
  const { t } = useTranslation('web/content-editor')

  return (
    <BlockField name={name} responsive>
      {({ fieldProps, inheritFrom, inheritValue, onDataChange, value }) => (
        <WidthSelect
          {...fieldProps}
          help={help ?? t('widthHelp')}
          inheritForm={inheritFrom}
          inheritValue={inheritValue}
          label={label ?? t('width')}
          onChange={e => onDataChange(e.target.value)}
          value={value}
        />
      )}
    </BlockField>
  )
}

BlockWidthSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
}
