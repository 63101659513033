import PropTypes from 'prop-types'
import React from 'react'

import { Combobox } from '@headlessui/react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * Autocomplete's Option
 */
export default function AutocompleteOption({
  value,
  label,
  labelExtra,
  icon,
  disabled,
  siblingHasValue,
  sublabel,
}) {
  return (
    <Combobox.Option value={value} disabled={disabled}>
      {({ active, selected }) => (
        <span
          className={`flex items-center justify-between gap-4 py-1 px-4 transition-all ease-in-out duration-200 ${
            active ? 'bg-gray-100' : ''
          } ${selected ? 'bg-primary-50 font-semibold' : 'font-normal'} ${
            disabled ? 'cursor-not-allowed opacity-50' : 'cursor-pointer'
          }`}
        >
          {icon && (
            <span className="flex shrink-0 items-center justify-center text-primary-500 min-w-5">
              {icon}
            </span>
          )}
          <span className="flex-grow flex flex-col gap-0">
            <div className="flex items-center justify-between gap-2 text-base truncate">
              <span className="truncate">{label}</span>
              {labelExtra && (
                <span className="text-gray-400 text-end text-sm">
                  {labelExtra}
                </span>
              )}
            </div>
            {sublabel && (
              <span className="text-gray-400 text-sm truncate">{sublabel}</span>
            )}
          </span>
          <span
            className={`transition-all ease-in-out duration-150 overflow-hidden ${
              siblingHasValue ? 'w-4' : 'w-0 -ms-4'
            }`}
          >
            {selected ? (
              <Icon name="check" className="text-primary-400" />
            ) : (
              <div className="w-4" />
            )}
          </span>
        </span>
      )}
    </Combobox.Option>
  )
}
AutocompleteOption.propTypes = {
  disabled: PropTypes.bool,
  icon: PropTypes.node,
  label: PropTypes.node,
  labelExtra: PropTypes.node,
  selected: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  siblingHasValue: PropTypes.bool,
  sublabel: PropTypes.node,
}
