export function getArticlesUrl(path = '', params = '') {
  return `/articles${path ? `/${path}` : ''}${params}`
}

export function getAddArticleUrl(params = '') {
  return getArticlesUrl('add', params)
}

export function getArticleUrl(article, path = '', params = '') {
  if (!article) return undefined
  return getArticlesUrl(`${article.id}${path ? `/${path}` : ''}`, params)
}

export function getArticleEditUrl(article, params = '') {
  return getArticleUrl(article, 'edit', params)
}

export function getArticleTranslationsUrl(articleSource) {
  return getArticleEditUrl(
    {
      id: articleSource?.id,
    },
    '?tab=translations'
  )
}

export function getArticlesConfigureUrl(params = '') {
  return getArticlesUrl('configure', params)
}

export function getTranslateArticleUrl(article, path = '') {
  return getArticleUrl(article, 'translate', path)
}
