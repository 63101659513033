/**
 *  Fit a map to a boundingBox
 * @param {object} map
 * @param {array} boundingBox
 * @returns
 */
export function fitMapToBounds(map, boundingBox) {
  if (!map || !boundingBox) return

  // Fit map to boundingBox
  const [west, south, east, north] = boundingBox
  map.fitBounds(
    [
      [west, south], // south-west corner
      [east, north], // north-east corner
    ],
    {
      padding: {
        top: 40,
        bottom: 10,
        left: 20,
        right: 20,
      },
      duration: 100, // do a quick animation
    }
  )
}
