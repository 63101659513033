import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { SelectField, SelectOption } from '@ui/data-entry/Select'

import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { useTextAlignOptions } from './hooks'

export default function TextAlignSelectField({
  name = 'textAlign',
  fieldExtra,
  label,
  labelExtra,
  help,
  onChange,
  value,
  inheritFrom,
  inheritValue,
  required,
}) {
  const { t } = useTranslation('web/content-editor')
  const textAlignOptions = useTextAlignOptions()

  return (
    <SelectField
      name={name}
      fieldExtra={fieldExtra}
      label={label || t('textAlign')}
      labelExtra={labelExtra}
      help={help}
      onChange={onChange}
      value={value}
      required={required}
    >
      <InheritSelectPlaceholder
        labelKey="textAlign"
        inheritValue={inheritValue}
        inheritFrom={inheritFrom}
        value={value}
      />
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      {textAlignOptions.map(({ label, value, key }) => (
        <SelectOption value={value} label={label} key={key} />
      ))}
    </SelectField>
  )
}
TextAlignSelectField.propTypes = {
  name: PropTypes.string,
  fieldExtra: PropTypes.node,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  help: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  required: PropTypes.bool,
}
