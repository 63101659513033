import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import get from 'lodash/get'

import useValueAtBreakpoint from '../useValueAtBreakpoint'
import { SideSelectField } from './Field'

export function BlockSideSelect({
  className,
  extra,
  help,
  isMixed,
  label,
  labelKey,
  name,
  onChange,
  options,
}) {
  const { data } = useNode(node => ({ data: node.data.props }))
  const value = useValueAtBreakpoint(get(data, name))

  let displayedValue = value?.value

  if (isMixed) {
    displayedValue = 'mixed'
  } else if (value?.inherit) {
    displayedValue = null
  }

  return (
    <SideSelectField
      className={className}
      extra={extra}
      help={help}
      inheritFrom={value?.breakpoint}
      inheritValue={value?.inherit ? value?.value : undefined}
      label={label}
      labelKey={labelKey}
      onChange={onChange}
      options={options}
      value={displayedValue}
    />
  )
}
BlockSideSelect.propTypes = {
  className: PropTypes.string,
  extra: PropTypes.node,
  help: PropTypes.string,
  isMixed: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.string),
}
