import { useMemo } from 'react'

export default function usePageIcon(page) {
  const authRequired = useMemo(() => {
    if (!page) {
      return false
    }
    return (
      page.auth?.required ||
      page.ancestors?.some?.(ancestor => ancestor.auth?.required)
    )
  }, [page])

  const passwordProtected = useMemo(() => {
    if (!page) {
      return false
    }

    return (
      Boolean(page.password) ||
      page.ancestors?.some?.(ancestor => Boolean(ancestor.password))
    )
  }, [page])

  if (!page) {
    return 'file'
  }

  return page.xml?.enabled || page.json?.enabled || page.text?.enabled
    ? 'code'
    : page.isHome
      ? 'home'
      : page.enabled
        ? authRequired
          ? 'lock'
          : passwordProtected
            ? 'key'
            : page.dynamic
              ? 'code-simple'
              : 'file'
        : 'eye-slash'
}
