import PropTypes from 'prop-types'
import React from 'react'

import useSite from '@modules/web/services/hooks/useSite'
import Pagination from '@ui/navigation/Pagination'
import { isEmpty } from '@utils/arrays'

import useEvents from '../../../services/hooks/useEvents'
import EventItem from './EventItem'

export default function EventsPreview({
  organizers,
  regions,
  categories,
  limit,
  months,
  pagination,
  showPreview,
  showFilters,
}) {
  const { site } = useSite()

  const { events = [], count } = useEvents({
    statuses: ['active'],
    filters: {
      organizers: !isEmpty(organizers || [])
        ? organizers.reduce((acc, organizer) => {
            acc[organizer] = ''
            return acc
          }, {})
        : null,
      regions: !isEmpty(regions || [])
        ? regions.reduce((acc, region) => {
            acc[region] = ''
            return acc
          }, {})
        : null,
      categories: !isEmpty(categories || [])
        ? categories.reduce((acc, cateagory) => {
            acc[cateagory] = ''
            return acc
          }, {})
        : null,
    },
    limit,
    months,
    siteId: site?.id,
  })

  return (
    <div className="space-y-6">
      <div className={'flex flex-col space-y-6'}>
        <div className="space-y-6 p-2">
          {showFilters && <p>[Filters]</p>}
          {events.map(event =>
            React.createElement(EventItem, {
              event,
              showPreview,
              site,
              key: event.id,
            })
          )}
        </div>
      </div>
      {pagination && <Pagination page={1} pageSize={months} total={count} />}
    </div>
  )
}
EventsPreview.propTypes = {
  organizers: PropTypes.array,
  regions: PropTypes.array,
  categories: PropTypes.array,
  limit: PropTypes.number,
  months: PropTypes.number,
  pagination: PropTypes.bool,
  showPreview: PropTypes.bool,
  showFilters: PropTypes.bool,
}
