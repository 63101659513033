import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useWebAuthPermissions() {
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  const commonParams = {
    user,
    entity: currentEntity,
    currentEntityId: currentEntity?.id,
  }

  return {
    // Web users
    canReadWebUsers: isUserAuthorized({
      ...commonParams,
      module: 'web-users',
      permission: 'read',
    }),
    canCreateWebUsers: isUserAuthorized({
      ...commonParams,
      module: 'web-users',
      permission: 'create',
    }),
    canUpdateWebUsers: isUserAuthorized({
      ...commonParams,
      module: 'web-users',
      permission: 'update',
    }),
    canDeleteWebUsers: isUserAuthorized({
      ...commonParams,
      module: 'web-users',
      permission: 'delete',
    }),

    // Web collections
    canReadWebUserCollections: useIsUserAuthorized({
      ...commonParams,
      module: 'web-user-collections',
      permission: 'read',
    }),
    canCreateWebUserCollections: useIsUserAuthorized({
      ...commonParams,
      module: 'web-user-collections',
      permission: 'create',
    }),
    canUpdateWebUserCollections: useIsUserAuthorized({
      ...commonParams,
      module: 'web-user-collections',
      permission: 'update',
    }),
    canDeleteWebUserCollections: useIsUserAuthorized({
      ...commonParams,
      module: 'web-user-collections',
      permission: 'delete',
    }),

    // Web groups
    canReadWebGroups: useIsUserAuthorized({
      ...commonParams,
      module: 'web-groups',
      permission: 'read',
    }),
    canCreateWebGroups: useIsUserAuthorized({
      ...commonParams,
      module: 'web-groups',
      permission: 'create',
    }),
    canUpdateWebGroups: useIsUserAuthorized({
      ...commonParams,
      module: 'web-groups',
      permission: 'update',
    }),
    canDeleteWebGroups: useIsUserAuthorized({
      ...commonParams,
      module: 'web-groups',
      permission: 'delete',
    }),
  }
}
