import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import Image from '@ui/data-display/Image'

import useEntity from '../../../services/hooks/useEntity'
import Address from './shared/Address'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'ChurchDirections',
  label: 'entities/public:ChurchDirections',
  type: 'plugin',
  icon: 'church',
  component: <ChurchDirections />,
}

function getPlaceholder() {
  return {
    image: {},
    name: '[Church name]',
    location: {
      coordinates: [0, 0],
    },
    description: '[Church description.]',
  }
}

export default function ChurchDirections({ churchId, dynamic }) {
  const { t } = useTranslation('entities/public')
  const { entity } = useEntity({ churchId: dynamic ? null : churchId })

  const { name, image, address, location } = entity || getPlaceholder()

  const [viewport, setViewport] = useState()

  useEffect(() => {
    if (!location || viewport) return
    const { coordinates } = location
    setViewport({
      longitude: coordinates[0],
      latitude: coordinates[1],
      zoom: 16,
    })
  }, [location, viewport])

  const locationMarker = useMemo(
    () => [{ coordinates: location.coordinates }],
    [location]
  )

  return (
    <Content icon={config.icon} title={t('ChurchDirections')} locked={dynamic}>
      <div className="flex flex-col">
        <div className="flex flex-col gap-6">
          <div className="flex flex-row">
            <Map
              className={'w-2/3'}
              markers={locationMarker}
              viewport={viewport}
              markerIcon="church"
              onMove={setViewport}
              showControls
            />

            {image && (
              <Image file={image} alt={name} width={600} className={'w-1/3'} />
            )}
          </div>
          <div className="flex flex-col gap-6 px-4 pb-4">
            <div className="flex flex-row justify-between gap-6">
              <div className="flex flex-col gap-2">
                <h1 className="text-3xl font-bold">{name}</h1>
              </div>
            </div>
            <Address address={address} />
          </div>
        </div>
      </div>
    </Content>
  )
}
ChurchDirections.propTypes = {
  dynamic: PropTypes.bool,
  churchId: PropTypes.string,
}
ChurchDirections.defaultProps = {}
ChurchDirections.toolbarItem = config

function ChurchDirectionsSettings() {
  const { t } = useTranslation('entities/public')

  // const { actions, data } = useNode(node => {
  //   return {
  //     data: node.data.props,
  //   }
  // })
  // We've had to disable the manual church picking due to it not having worked before the conferences started using them in November 2023.
  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.churchId = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap
      title={t('ChurchDirections')}
      help={t('ChurchDirectionsHelp')}
    >
      <div className="space-y-2">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.churchId}
            types={['church']}
          />
        )} */}
      </div>
    </SettingsWrap>
  )
}

ChurchDirections.craft = {
  displayName: 'ChurchDirections',
  props: {
    churchId: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity', 'sermons', 'entityServices'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchDirectionsSettings,
  },
}
