import PropTypes from 'prop-types'
import React, { useEffect, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

// import PaginationFields from '@modules/web/components/ContentEditor/blocks/shared/PaginationFields'
// import SortFields from '@modules/web/components/ContentEditor/blocks/shared/SortFields'
import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { InputField } from '@ui/data-entry/Input'

const config = {
  name: 'PublishingHouses',
  label: 'publications/public:PublishingHouses',
  type: 'plugin',
  icon: 'university',
  component: <PublishingHouses />,
}

export default function PublishingHouses() {
  const { t } = useTranslation('publications/public')

  return (
    <Content icon={config.icon} title={t('PublishingHouses')}>
      <p>List of publishing houes</p>
    </Content>
  )
}
PublishingHouses.propTypes = {}
PublishingHouses.defaultProps = {}
PublishingHouses.toolbarItem = config

function PublishingHousesSettings() {
  const { t } = useTranslation('publications/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  // Ensures that the selected flag is removed from flagExceptions
  useEffect(() => {
    if (data.flagExceptions?.includes(data.flag)) {
      actions.setProp(
        data =>
          (data.flagExceptions = data.flagExceptions.filter(
            id => id !== data.flag
          ))
      )
    }
  }, [actions, data])

  return (
    <SettingsWrap
      title={t('PublishingHouses')}
      help={t('PublishingHousesHelp')}
    >
      <div className="space-y-4">
        <InputField
          name="filterPlaceholder"
          label={t('filterPlaceholder')}
          help={t('filterPlaceholderHelp')}
          onChange={e =>
            actions.setProp(props => (props.filterPlaceholder = e.target.value))
          }
          value={data.filterPlaceholder}
        />
        <InputField
          name="languagesLabel"
          label={t('languagesLabel')}
          help={t('publishingLanguagesLabelHelp')}
          onChange={e =>
            actions.setProp(props => (props.languagesLabel = e.target.value))
          }
          value={data.languagesLabel}
        />
        <InputField
          name="locationLabel"
          label={t('locationLabel')}
          help={t('publishingLocationLabelHelp')}
          onChange={e =>
            actions.setProp(props => (props.locationLabel = e.target.value))
          }
          value={data.locationLabel}
        />
        <InputField
          name="emailLabel"
          label={t('emailLabel')}
          help={t('publishingEmailLabelHelp')}
          onChange={e =>
            actions.setProp(props => (props.emailLabel = e.target.value))
          }
          value={data.emailLabel}
        />
        <InputField
          name="websiteLabel"
          label={t('websiteLabel')}
          help={t('publishingWebsiteLabelHelp')}
          onChange={e =>
            actions.setProp(props => (props.websiteLabel = e.target.value))
          }
          value={data.websiteLabel}
        />
        <InputField
          name="noResultsLabel"
          label={t('noResultsLabel')}
          help={t('noResultsLabelHelp')}
          onChange={e =>
            actions.setProp(props => (props.noResultsLabel = e.target.value))
          }
          value={data.noResultsLabel}
        />
        {/* <SortFields />
        <PaginationFields /> */}
      </div>
    </SettingsWrap>
  )
}
PublishingHouses.craft = {
  displayName: 'PublishingHouses',
  props: {
    sortField: 'title',
    sortOrder: 'asc',
    variant: 'list',

    label: '',

    skip: 0,
  },
  custom: {
    type: config.type,
    resources: ['publishingHouses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublishingHousesSettings,
  },
}

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function PublishingHousesTranslation({ id, source }) {
  const { t } = useTranslation(['publications/public'])
  const fields = useMemo(
    () => ({
      filterPlaceholder: { label: t('filterPlaceholder') },
      languagesLabel: { label: t('languagesLabel') },
      locationLabel: { label: t('locationLabel') },
      emailLabel: { label: t('emailLabel') },
      websiteLabel: { label: t('websiteLabel') },
      noResultsLabel: { label: t('noResultsLabel') },
    }),
    [t]
  )
  return (
    <BlockTranslation
      id={id}
      fields={fields}
      source={source}
      i18Namespace="publications/public"
    />
  )
}
PublishingHousesTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
