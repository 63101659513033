import { useMemo } from 'react'

export function useArticleFlags(article, site, showFlags) {
  return useMemo(() => {
    return showFlags
      ? Object.entries(article?.sites?.[site?.id]?.flags ?? {})
          .reduce((acc, [flagId, enabled]) => {
            if (enabled) {
              const flagName = site?.flags?.find(
                flag => flag.id === flagId
              )?.name
              acc.push(flagName)
            }
            return acc
          }, [])
          .sort()
          .join(', ')
      : undefined
  }, [article?.sites, showFlags, site])
}
