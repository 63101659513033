import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from './Button'

export default function ClearButton({
  onClick,
  showLabel = false,
  size = 'sm',
}) {
  const { t } = useTranslation()

  return (
    <Button
      icon="times-circle"
      size={size || 'sm'}
      variant="flat-danger"
      onClick={e => {
        e.stopPropagation()
        if (typeof onClick === 'function') {
          onClick()
        }
      }}
      label={showLabel ? t('clearSelection') : undefined}
    />
  )
}
ClearButton.propTypes = {
  onClick: PropTypes.func,
  showLabel: PropTypes.bool,
  size: PropTypes.string,
}
