import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import Section from '@ui/data-display/Section'
import Checkbox from '@ui/data-entry/Checkbox'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import TextArea from '@ui/data-entry/TextArea'

export default function HopeFooterForm({ site, onSubmit }) {
  const design = useDesignContext()
  const [, setEnabled] = React.useState(design?.footer?.hideFooter)
  const { t } = useTranslation(['designs/hope', 'web/appearance'])
  const { user } = useCurrentUser()

  const { onImageUpload } = useImageUpload()

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <ImageUpload
            name="design[footer][logo]"
            label={t('footerLogo')}
            help={t('footerLogoHelp')}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
          />
          <TextArea
            name="design[footer][description]"
            label={t('web/appearance:footerDescription')}
            countWords
          />
          <Input
            name="design[footer][copyright]"
            label={t('web/appearance:footerCopyright')}
          />
          <MenuPicker
            name="design[footer][footerMenu]"
            label={t('web/appearance:footerMenu')}
            help={t('web/appearance:footerMenuHelp')}
          />
          <Checkbox
            name="design[footer][hideFooter]"
            label={t('web/appearance:hideFooter')}
            help={t('web/appearance:hideFooterHelp')}
            disabled={!user?.isAdmin}
            onChange={value => setEnabled(value)}
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('web/appearance:update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
HopeFooterForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
