import PropTypes from 'prop-types'
import React, { useCallback, useEffect } from 'react'

import { Select, SelectOption } from '@ui/data-entry/Select'
import Clickable from '@ui/helpers/Clickable'
import usePagination from '@ui/helpers/usePagination'
import usePaginationQuery, {
  DEFAULT_PAGE_SIZE,
  perPageOptions,
} from '@ui/helpers/usePaginationQuery'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function Pagination({
  page, // overrides query params
  pageSize, // overrides query params
  perPageLabel,
  showPageSize,
  total,
}) {
  const { onPageChange, onPageSizeChange, ...params } = usePaginationQuery()

  const pageNumber = page || params.pageNumber || 1
  const perPage = pageSize || params.pageSize || DEFAULT_PAGE_SIZE

  const totalPages = parseInt(total / perPage) + (total % perPage > 0 ? 1 : 0)

  const pages = usePagination({
    page: pageNumber <= totalPages ? pageNumber : 1,
    total: totalPages,
    surrounding: 2,
    onPageClick: onPageChange,
  })

  const onPageSizeSelect = useCallback(
    ({ target }) => {
      const value = parseInt(target.value)
      if (typeof onPageSizeChange === 'function') onPageSizeChange(value)
    },
    [onPageSizeChange]
  )

  // If the page number is greater than the total pages, set the page number to the last page
  useEffect(() => {
    if (pageNumber > totalPages) {
      onPageChange(totalPages)
    }
  }, [onPageChange, pageNumber, totalPages])

  return (
    <div className="flex flex-row justify-between">
      <div className="flex flex-row items-center justify-center space-x-1">
        {totalPages > 1 &&
          pages.map(
            ({ isCurrent, number, icon, label, isArrow, isDivider }, key) =>
              isDivider ? (
                <span
                  className="flex h-8 cursor-default items-center px-4"
                  key={`divider-${key}`}
                >
                  <Icon name="ellipsis-h" />
                </span>
              ) : (
                <Clickable
                  className={`flex h-10 min-w-[2.5rem] items-center justify-center py-1 px-3 ${
                    isArrow ? 'rounded-full' : 'rounded-md font-medium'
                  } ${
                    isCurrent
                      ? 'bg-primary-500 text-white'
                      : 'hover:bg-gray-200'
                  }`}
                  onClick={isCurrent ? null : () => onPageChange(number)}
                  key={`page-${key}`}
                >
                  {icon ? <Icon name={icon} /> : label || number}
                </Clickable>
              )
          )}
      </div>
      {showPageSize && (
        <div className="flex flex-row items-center justify-center space-x-2">
          {perPageLabel && (
            <span className="shrink-0 text-sm">{perPageLabel}:</span>
          )}
          <Select onChange={onPageSizeSelect} value={perPage}>
            {perPageOptions.map(option => (
              <SelectOption
                value={option}
                label={`${option}`}
                key={`perPage_${option}`}
              />
            ))}
          </Select>
        </div>
      )}
    </div>
  )
}
Pagination.propTypes = {
  page: PropTypes.number,
  pageSize: PropTypes.number,
  perPageLabel: PropTypes.string,
  showPageSize: PropTypes.bool,
  total: PropTypes.number.isRequired,
}
Pagination.defaultProps = {
  total: 0,
}
