import PropTypes from 'prop-types'
import React, { Suspense, useCallback, useEffect, useMemo } from 'react'

import { useNavigate } from 'react-router-dom'

import Loading from '@ui/feedback/Loading'
import Clickable from '@ui/helpers/Clickable'
import useQueryParams from '@ui/helpers/useQueryParams'

import Badge from './Badge'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const tabsSizeStyles = {
  xs: { x: 'px-2', y: 'py-2' },
  sm: { x: 'px-5', y: 'py-5' },
  md: { x: 'px-10', y: 'py-10' },
}

const tabsBackgrounds = {
  white: 'bg-white',
  transparent: '',
}

// Get all children tabs names
function useTabsNames(children) {
  return useMemo(
    () =>
      children
        ? React.Children.map(
            children,
            child => child?.props?.name || ''
          ).filter(tab => tab !== '')
        : [],
    [children]
  )
}

/**
 * Tabs make it easy to switch between different views.
 */
export default function Tabs({
  activeTab,
  children,
  className,
  padding,
  size,
  preventNavigation,
  name,
  tabsBackground,
  loading,
}) {
  const sizeStyles = tabsSizeStyles[size] ?? tabsSizeStyles.md

  const { queryParams, setQueryParams } = useQueryParams()
  const tabParam = name ? `tab-${name}` : 'tab'
  const tab = queryParams.get(tabParam)

  const tabsNames = useTabsNames(children)

  useEffect(() => {
    if (activeTab && !tab) {
      setQueryParams(tabParam, activeTab, { replace: true })
    }
  }, [activeTab, setQueryParams, tab, tabParam])

  // Ensures that a tab is selected
  useEffect(() => {
    if (loading) return
    if (tabsNames.length > 0 && !tabsNames.includes(tab)) {
      setQueryParams(tabParam, tabsNames[0], { replace: true })
    }
  }, [tabParam, tabsNames, tab, setQueryParams, loading])

  if (loading) return <Loading />

  return (
    <div className={`flex h-full flex-col ${className}`}>
      <div
        className={`flex flex-row border-b overflow-x-auto overflow-y-visible hide-scrollbar ${
          padding ? sizeStyles.x : ''
        } ${tabsBackgrounds[tabsBackground]}`}
      >
        {React.Children.map(children, (child, key) => {
          if (!child) return null

          const { badgeCount, name, disabled, title, icon, variant } =
            child.props

          return (
            <TabLabel
              active={tab === name}
              badgeCount={badgeCount}
              title={title}
              disabled={disabled}
              name={name}
              icon={icon}
              onClick={disabled ? null : () => setQueryParams(tabParam, name)}
              key={`tab-${name}-${key}`}
              size={size}
              variant={variant}
              preventNavigation={preventNavigation}
            />
          )
        })}
      </div>
      <div className="flex-1">
        <Suspense fallback={<Loading />}>
          {React.Children.map(children, (child, key) => {
            if (!child) return null
            const { name, noPadding } = child.props || {}

            if (tab !== name) return null

            const sizeClasses = noPadding
              ? ''
              : `${sizeStyles.y} ${padding ? sizeStyles.x : ''}`

            return (
              <div className={`h-full ${sizeClasses}`} key={key}>
                {child}
              </div>
            )
          })}
        </Suspense>
      </div>
    </div>
  )
}
Tabs.propTypes = {
  activeTab: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  padding: PropTypes.bool,
  tabsBackground: PropTypes.oneOf(['white', 'transparent']),
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  preventNavigation: PropTypes.bool,
}
Tabs.defaultProps = {
  className: '',
  padding: true,
  tabsBackground: 'white',
}

/**
 * Wraps and displays the content part of a Tab
 */
export function TabPane({ aside, children, className, disabled, fullWidth }) {
  if (disabled) return null
  return (
    <div className="relative flex flex-col lg:flex-row-reverse lg:justify-end gap-10 h-full">
      {aside && <div className="lg:sticky lg:top-10 self-start">{aside}</div>}
      <div
        className={`flex flex-col ease-in-out duration-300 transition-all ${
          fullWidth
            ? 'w-full'
            : 'max-w-full flex-1 lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl'
        } ${className}`}
      >
        {children}
      </div>
    </div>
  )
}
TabPane.propTypes = {
  aside: PropTypes.node,
  badgeCount: PropTypes.number,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  name: PropTypes.string,
  noPadding: PropTypes.bool,
  title: PropTypes.string,
  variant: PropTypes.oneOf(['default', 'danger']),
}
TabPane.defaultProps = {
  className: '',
}
Tabs.Pane = TabPane
Tabs.Pane.displayName = 'Tabs.Pane'

const labelSizeStyle = {
  xs: 'px-1 md:px-1 lg:px-2 py-1 gap-1 text-xs',
  sm: 'px-2 md:px-3 lg:px-4 py-2 gap-2 text-sm',
  md: 'px-4 md:px-6 lg:px-8 py-3 gap-3 text-base',
}

const tabLabelStyles = {
  default: {
    active: 'text-primary-500  border-primary-600',
    inactive: 'text-gray-700',
    disabled: 'text-gray-400',
  },
  danger: {
    active: 'text-danger-600 border-danger-600',
    inactive: 'text-danger-700',
    disabled: 'text-danger-400',
  },
}

/**
 * Displays the label part of a Tab
 */
function TabLabel({
  active,
  badgeCount,
  disabled,
  icon,
  name,
  onClick,
  title,
  size,
  variant,
  preventNavigation,
}) {
  const variantClasses = tabLabelStyles[variant] ?? tabLabelStyles.default
  const activeClass = disabled
    ? `cursor-not-allowed ${variantClasses.disabled}`
    : active
      ? variantClasses.active
      : variantClasses.inactive

  const navigate = useNavigate()

  const sizeClass = labelSizeStyle[size] || labelSizeStyle.md

  const handleClick = useCallback(
    e => {
      if (!preventNavigation) {
        navigate({ search: `?tab=${name}` })
      }

      if (typeof onClick === 'function') onClick(e)
    },
    [name, navigate, onClick, preventNavigation]
  )

  return (
    <Clickable
      className={`flex items-center gap-1 cursor-pointer whitespace-nowrap rounded-t font-medium tracking-wider outline-none transition-all ease-in-out duration-300 border-b-2 ${sizeClass} ${activeClass}`}
      onClick={handleClick}
    >
      {icon && <Icon name={icon} />}
      <span className="font-semibold">{title}</span>
      {badgeCount > 0 && <Badge count={badgeCount} />}
    </Clickable>
  )
}
TabLabel.propTypes = {
  active: PropTypes.bool,
  badgeCount: PropTypes.number,
  disabled: PropTypes.bool,
  icon: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md']),
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'danger']),
  preventNavigation: PropTypes.bool,
}
TabLabel.defaultProps = {
  badgeCount: 0,
  size: 'md',
}
