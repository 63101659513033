import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useDocumentUpload } from '@modules/documents/services/DocumentServices'
import Button from '@ui/buttons/Button'
import MetaItem from '@ui/data-display/MetaItem'
import Dialog, { DialogContent } from '@ui/feedback/FloatingDialog'
import Loading from '@ui/feedback/Loading'
import Popover from '@ui/feedback/Popover'
import { DownloadLink } from '@ui/navigation/Link'
import bytes from '@utils/bytes'
import { generateDownloadUrl } from '@utils/documents'

import Field from './Field'
import { FileDragger } from './FileDragger'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const defaultAccept = [
  'pdf',
  'doc',
  'xls',
  'ppt',
  'zip',
  'rar',
  '7z',
  'jpg',
  'png',
  'gif',
  'ai',
  'epub',
  'mp3',
  'ttf',
  'otf',
  'woff',
  'woff2',
]

export function FileUpload({
  accept = defaultAccept,
  className = '',
  disabled,
  id,
  inputRef,
  maxSize,
  multiple, // TODO: add support for multiple files
  name,
  onChange,
  onUpload,
  uploading,
  value,
  openModal, // Indicates if the modal is open by default
  downloadType, // one of 'direct' or 'signed'
  dialogBoxClass = '', // Custom class for the dialog box
  disabledDownload = false,
  uploadLabel,
}) {
  const { t } = useTranslation('ui/document-upload')
  const hasValue = !!value
  const maxFileSize = maxSize ? bytes(maxSize) : undefined
  const [dialogOpen, setDialogOpen] = React.useState(openModal)
  const [downloadUrl, setDownloadUrl] = React.useState(null)

  const { onDocumentUpload } = useDocumentUpload()

  // Gets the download url depending on the downloadType, if downloadType is not provided, it will use getDocumentUrl
  React.useEffect(() => {
    async function getDownloadUrl() {
      if (value) {
        const url = await generateDownloadUrl(value, downloadType) // depending on the download gets the correct url
        setDownloadUrl(url)
      }
    }

    getDownloadUrl()
  }, [value, downloadType])

  const onFileUpload = React.useCallback(
    (data, onChange) => {
      // Close the dialog
      setDialogOpen(false)

      onChange(data)

      if (typeof onUpload === 'function') {
        onUpload(data, onChange)
      } else {
        onDocumentUpload(data, onChange)
      }
    },
    [onUpload, onDocumentUpload]
  )

  const onFileChange = React.useCallback(
    file => {
      onChange?.(file)
    },
    [onChange]
  )

  if (uploading) {
    return <Loading label={t('uploading')} />
  }

  return (
    <div className={`space-y-4 ${className}`}>
      {hasValue && (
        <div className="flex flex-row space-x-4">
          <div className="flex flex-row items-center space-x-2">
            <span className="space-x-2 font-mono text-sm">
              {value.originalFilename || value.name}
              <span className="text-gray-500">{value.extension}</span>
            </span>
            <Popover>
              <Popover.Trigger asChild>
                <Icon
                  name="info-circle"
                  className="text-primary-500 focus:outline-none"
                />
              </Popover.Trigger>
              <Popover.Content>
                <div className="divide-y">
                  <MetaItem
                    label={t('format')}
                    value={value.type || value.mime}
                  />
                  <MetaItem label={t('size')} value={bytes(value.size)} />

                  {downloadUrl && !disabledDownload && (
                    <div className="flex items-center justify-center p-2 pb-0">
                      <DownloadLink href={downloadUrl} />
                    </div>
                  )}
                </div>
              </Popover.Content>
            </Popover>
          </div>
        </div>
      )}
      <div>
        {hasValue ? (
          <div className="flex gap-4">
            <Button
              label={t('replace')}
              icon="file"
              size="sm"
              disabled={disabled}
              onClick={() => setDialogOpen(true)}
            />
            <Button
              label={t('remove')}
              variant="danger-light"
              icon="trash-alt"
              onClick={() => onChange(null)}
              size="sm"
              disabled={disabled}
            />
            {downloadUrl && !disabledDownload && (
              <DownloadLink href={downloadUrl} className="text-sm" />
            )}
          </div>
        ) : (
          <Button
            label={uploadLabel || t(multiple ? 'selectFiles' : 'selectFile')}
            icon="file"
            size="sm"
            disabled={disabled}
            onClick={() => setDialogOpen(true)}
          />
        )}

        <Dialog open={dialogOpen} onOpenChange={setDialogOpen}>
          <DialogContent
            title={t(multiple ? 'selectFiles' : 'selectFile')}
            boxClass={dialogBoxClass}
          >
            {dialogOpen && (
              <FileDragger
                accept={accept}
                acceptLabel={t('accept')}
                dropHint={t(multiple ? 'dropHintMultiple' : 'dropHint')}
                disabled={disabled}
                id={id}
                inputRef={inputRef}
                maxSize={maxFileSize}
                multiple={multiple}
                name={name}
                onChange={onFileChange}
                onUpload={onFileUpload}
                selectFilesLabel={t(multiple ? 'selectFiles' : 'selectFile')}
              />
            )}
          </DialogContent>
        </Dialog>
      </div>
    </div>
  )
}
FileUpload.propTypes = {
  accept: PropTypes.array,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  openModal: PropTypes.bool,
  uploading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadType: PropTypes.string, // TODO: downloadType could be one of 'direct' or 'signed' or nothing
  dialogBoxClass: PropTypes.string,
  disabledDownload: PropTypes.bool,
  uploadLabel: PropTypes.string,
}

export function FileUploadField({
  accept,
  className,
  disabled,
  error,
  help,
  inputRef,
  label,
  maxSize,
  multiple,
  name,
  onChange,
  onUpload,
  required,
  uploading,
  value,
  downloadType,
  disabledDownload,
  dialogBoxClass,
  uploadLabel,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={error}
      help={uploading ? undefined : help}
      required={required}
    >
      <FileUpload
        accept={accept}
        disabled={disabled}
        id={name}
        inputRef={inputRef}
        maxSize={maxSize}
        multiple={multiple}
        name={name}
        onChange={onChange}
        onUpload={onUpload}
        value={value}
        uploading={uploading}
        downloadType={downloadType}
        disabledDownload={disabledDownload}
        dialogBoxClass={dialogBoxClass}
        uploadLabel={uploadLabel}
      />
    </Field>
  )
}
FileUploadField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  help: PropTypes.string,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  required: PropTypes.bool,
  uploading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadType: PropTypes.string,
  disabledDownload: PropTypes.bool,
  uploadLabel: PropTypes.string,
  dialogBoxClass: PropTypes.string,
}

export default function FileUploadController({
  accept,
  className,
  disabled,
  help,
  label,
  maxSize,
  multiple,
  name,
  onChange,
  onUpload,
  required,
  uploading,
  value,
  shouldUnregister,
  downloadType,
  disabledDownload,
  dialogBoxClass,
  uploadLabel,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = React.useCallback(
    field => newValue => {
      field.onChange(newValue)
      onChange?.(newValue)
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FileUploadField
          accept={accept}
          className={className}
          disabled={disabled}
          error={fieldState.error}
          help={help}
          id={name}
          inputRef={field.ref}
          label={label}
          maxSize={maxSize}
          multiple={multiple}
          name={name}
          onBlur={field.onBlur}
          onChange={onFieldChange(field)}
          onUpload={onUpload}
          required={rules?.required?.value}
          uploading={uploading}
          value={field.value}
          downloadType={downloadType}
          disabledDownload={disabledDownload}
          dialogBoxClass={dialogBoxClass}
          uploadLabel={uploadLabel}
        />
      )}
    />
  )
}

FileUploadController.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  maxSize: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  shouldUnregister: PropTypes.bool,
  uploading: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  downloadType: PropTypes.string,
  disabledDownload: PropTypes.bool,
  dialogBoxClass: PropTypes.string,
  uploadLabel: PropTypes.string,
}
FileUploadController.displayName = 'FileUpload'
