import PropTypes from 'prop-types'
import React, { Suspense, useCallback, useRef, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useEditable } from 'use-editable'

import Dropdown from '@ui/buttons/FloatingDropdown'
import Message from '@ui/data-display/Message'
import Popover from '@ui/feedback/Popover'
import Clickable from '@ui/helpers/Clickable'
import ErrorBoundary from '@utils/ErrorBoundary'

import { useEditorEnabled } from '../hooks'
import ContentIcon from './components/ContentIcon'
import useContentClasses from './hooks/useContentClasses'
import useContentNode from './hooks/useContentNode'
import useContentTypeStyles from './hooks/useContentTypeStyles'
import useCopyNode from './hooks/useCopyNode'
import usePasteNode from './hooks/usePasteNode'

export default function Content({
  allowCopy,
  allowHide,
  allowPaste,
  allowRemove,
  children,
  className,
  collapsible,
  dir,
  draggable,
  errors,
  icon,
  innerClass,
  innerStyle,
  lang,
  locked,
  selectable,
  title,
}) {
  const { t } = useTranslation('web/content-editor')
  const hasErrors = errors?.length > 0
  const { id, actions, data, parent, selected, ref, type } = useContentNode({
    draggable,
    selectable,
  })
  const [collapsed, setCollapsed] = useState(false)
  const renameRef = useRef(null)
  const [renaming, setRenaming] = useState()

  const onCopyNode = useCopyNode()
  const { onPasteNode, canPasteIn, nodeToPasteName, nodeToPasteType } =
    usePasteNode(id)

  const typeClasses = useContentTypeStyles(hasErrors ? 'withErrors' : type)
  const contentClasses = useContentClasses(data, parent)

  const onCollapseToggle = useCallback(() => {
    setCollapsed(!collapsed)
  }, [collapsed])

  const onRename = useCallback(
    text => {
      actions.setProp(data => (data.name = text.slice(0, -1)))
    },
    [actions]
  )

  const toggleRename = useCallback(() => {
    setRenaming(!renaming)
  }, [renaming])

  useEditable(renameRef, onRename, { disabled: !renaming })

  const isEditorEnabled = useEditorEnabled()

  if (!data) return null

  let { publishStartsAt, publishEndsAt } = data

  // We get the DateTime as a string from the DB, but when we're doing something live in the ContentEditor
  // we get the DateTime as an Object that needs to be converted to a string first
  if (publishStartsAt) {
    publishStartsAt =
      typeof publishStartsAt === 'string'
        ? publishStartsAt
        : publishStartsAt.toISOString()
  }
  if (publishEndsAt) {
    publishEndsAt =
      typeof publishEndsAt === 'string'
        ? publishEndsAt
        : publishEndsAt.toISOString()
  }

  const now = new Date().toISOString()

  const willBePublished = publishStartsAt && publishStartsAt > now
  const wasPublished = publishEndsAt && publishEndsAt < now
  const isPublished = !willBePublished & !wasPublished

  return (
    <div
      className={`group relative flex max-w-full flex-col rounded border ring-inset transition-all duration-300 ease-in-out focus-within:border-gray-300 hover:border-gray-300 ${
        selected
          ? `border-transparent ring-1 ${typeClasses.border}`
          : 'border-gray-200 ring-0 ring-white'
      } ${contentClasses} ${className}`}
      dir={dir}
      lang={lang}
      onKeyDown={event => {
        event.stopPropagation()
        if (event.ctrlKey || event.metaKey) {
          switch (event.key) {
            case 'c': {
              onCopyNode(id)
              break
            }
            case 'v': {
              onPasteNode(id)
              break
            }
          }
        }
      }}
      role="link"
      tabIndex="-1"
      ref={ref}
    >
      {title && (
        <div
          className={`flex h-6 flex-row items-center justify-between rounded rounded-bl-none rounded-br-none p-2 transition-colors ${
            typeClasses.bg
          } ${
            selected
              ? 'bg-opacity-80 text-white'
              : `bg-opacity-10 ${typeClasses.text} text-opacity-70`
          } ${draggable && isEditorEnabled ? 'hover:cursor-grab' : 'select-none'}`}
        >
          <div className="flex flex-grow truncate flex-row items-center gap-1">
            {icon && (
              <ContentIcon
                name={icon}
                title={`${t(title)} ${data.name ? `(${data.name})` : ''}`}
                className={selected ? 'text-white' : ''}
              />
            )}
            <h5 className="truncate text-sm font-semibold w-full flex items-center justify-between gap-2">
              <Clickable
                onDoubleClick={toggleRename}
                className="flex items-baseline gap-1"
              >
                <span
                  ref={renameRef}
                  className={`focus:outline-none ${typeClasses.selection}`}
                  placeholder="test"
                >
                  {data.name || t(title)}
                </span>

                {data.name && (
                  <span className="text-xs opacity-60">({t(title)})</span>
                )}
              </Clickable>
              {(publishStartsAt || publishEndsAt) && (
                <span className="text-xs opacity-60">
                  ({t('scheduledBlock')}
                  {': '}
                  {isPublished
                    ? t('scheduledBlockActive')
                    : wasPublished
                      ? t('scheduledBlockExpired')
                      : t('scheduledBlockNotReleased')}
                  )
                </span>
              )}
            </h5>
            {hasErrors && (
              <div>
                <Popover placement="bottom">
                  <Popover.Trigger
                    icon="exclamation-circle"
                    variant={selected ? 'flat-light' : 'flat'}
                    size="xs"
                    title={t('blockHasErrors')}
                  />
                  <Popover.Content>
                    {errors.map(error => (
                      <p key={error.title} className="text-danger-600">
                        {error.title}
                      </p>
                    ))}
                  </Popover.Content>
                </Popover>
              </div>
            )}
          </div>

          <div className="flex flex-row items-center gap-1 pl-2">
            {(publishStartsAt || publishEndsAt) && (
              <ContentIcon
                name={
                  isPublished
                    ? 'calendar-check'
                    : wasPublished
                      ? 'calendar-xmark'
                      : 'calendar-clock'
                }
                className={selected ? 'text-white' : ''}
              />
            )}
            {locked && (
              <ContentIcon
                name="lock"
                title={t('locked')}
                className={selected ? 'text-white' : ''}
              />
            )}
            {collapsible && (
              <ContentIcon
                name={collapsed ? 'chevron-down' : 'chevron-up'}
                title={`${title} ${data.name ? `(${data.name})` : ''}`}
                className={selected ? 'text-white' : ''}
                onClick={onCollapseToggle}
              />
            )}
            {isEditorEnabled &&
              (allowHide ||
                allowCopy ||
                (allowPaste && canPasteIn) ||
                allowRemove) && (
                <Dropdown
                  icon="ellipsis-v"
                  variant="flat"
                  className={`text-sm !px-2 ${selected ? 'text-white' : ''}`}
                >
                  <Suspense fallback={<div>Loading...</div>}>
                    {allowCopy && (
                      <Dropdown.Item
                        label={t('copyBlock', {
                          name: data.name
                            ? `${data.name} (${t(title)})`
                            : t(title),
                        })}
                        icon="copy"
                        onClick={() => onCopyNode(id)}
                      />
                    )}
                    {allowPaste && canPasteIn && (
                      <Dropdown.Item
                        label={t('pasteBlock', {
                          name: nodeToPasteName
                            ? `${nodeToPasteName} (${t(nodeToPasteType)})`
                            : t(nodeToPasteType),
                        })}
                        icon="paste"
                        onClick={() => onPasteNode(id)}
                      />
                    )}
                    {(allowHide || allowRemove) && <Dropdown.Divider />}
                    {allowHide && (
                      <Dropdown.Item
                        label={t('hideBlock', { name: t(title) })}
                        icon="eye-slash"
                        onClick={() => actions.setHidden(true)}
                        variant="warn"
                      />
                    )}
                    {allowRemove && (
                      <Dropdown.Item
                        label={t('removeBlock', { name: t(title) })}
                        icon="trash-alt"
                        variant="danger"
                        onClick={() => actions.delete(id)}
                      />
                    )}
                  </Suspense>
                </Dropdown>
              )}
          </div>
        </div>
      )}
      <div
        className={`px-1 transition-all duration-300 ease-in-out lg:px-2 ${
          collapsed
            ? 'max-h-0 overflow-hidden'
            : 'max-h-auto min-h-[3rem] py-1 lg:py-2'
        } ${(publishStartsAt || publishEndsAt) && !isPublished ? 'opacity-50' : ''} ${innerClass}`}
        style={innerStyle}
      >
        <ErrorBoundary
          fallback={
            <Message
              title={t('blockErrorTitle')}
              text={t('blockErrorText')}
              icon="bug"
              type="danger"
            />
          }
        >
          {children || null}
        </ErrorBoundary>
      </div>
    </div>
  )
}
Content.propTypes = {
  allowCopy: PropTypes.bool,
  allowHide: PropTypes.bool,
  allowPaste: PropTypes.bool,
  allowRemove: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  collapsible: PropTypes.bool,
  dir: PropTypes.oneOf(['ltr', 'rtl']),
  draggable: PropTypes.bool,
  errors: PropTypes.array,
  icon: PropTypes.string,
  innerClass: PropTypes.string,
  innerStyle: PropTypes.object,
  lang: PropTypes.string,
  locked: PropTypes.bool,
  selectable: PropTypes.bool,
  title: PropTypes.string,
}
Content.defaultProps = {
  allowCopy: true,
  allowHide: true,
  allowPaste: true,
  allowRemove: true,
  className: '',
  collapsible: true,
  draggable: true,
  errors: [],
  innerClass: '',
  selectable: true,
}
