import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CoursePagination',
  label: 'courses/public:coursePagination',
  help: 'courses/public:coursePaginationHelp',
  type: 'plugin',
  icon: 'arrow-right-arrow-left',
  component: <CoursePagination />,
}

export default function CoursePagination({ dynamic }) {
  const { t } = useTranslation('courses/public')
  const { breakpoint } = useBreakpoint()

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="flex w-full items-center justify-between p-2">
        <div className="flex items-center space-x-2 text-sm">
          <Icon name="chevron-left" />
          <div>{t('back')}</div>
        </div>
        {breakpoint !== 'xs' && (
          <div className="scal flex scale-75 space-x-2 text-xs">
            <Icon name="circle" />
            <Icon className="scale-125" name="circle-dot" />
            <Icon name="circle" />
            <Icon name="circle" />
            <Icon name="circle" />
            <Icon name="circle" />
          </div>
        )}
        <div className="flex items-center space-x-2 text-sm">
          <div>{t('next')}</div>
          <Icon name="chevron-right" />
        </div>
      </div>
    </Content>
  )
}
CoursePagination.propTypes = {
  dynamic: PropTypes.bool,
}
CoursePagination.toolbarItem = config

function CoursePaginationSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course ? (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        ) : (
          <>
            <PagePicker
              label={t('lessonDetailPageId')}
              help={t('lessonDetailPageIdHelp')}
              value={data.lessonDetailPageId}
              onChange={pageId =>
                actions.setProp(props => (props.lessonDetailPageId = pageId))
              }
              condition={page =>
                page.dynamic &&
                getDynamicResourceIdentifier(page) === 'CourseLesson'
              }
            />
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CoursePagination.craft = {
  displayName: config.name,
  props: {
    lessonDetailPageId: undefined,
  },
  custom: {
    type: config.type,
    resources: ['coursePagination'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CoursePaginationSettings,
  },
}
