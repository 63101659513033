import { createContext, useContext, useEffect } from 'react'

export const ResourcesContext = createContext()

export function usePageResources() {
  return useContext(ResourcesContext) || {}
}

export function useLoadPageResources(page) {
  const { setResources } = usePageResources()
  useEffect(() => {
    setResources(page ? page.resources : {})
  }, [page, setResources])
}
