import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

import { ResourcesContext } from './hooks'

export default function PageResourcesProvider({ children }) {
  const [resources, setResources] = useState({})

  const value = useMemo(() => ({ resources, setResources }), [resources])

  return (
    <ResourcesContext.Provider value={value}>
      {children}
    </ResourcesContext.Provider>
  )
}
PageResourcesProvider.propTypes = {
  children: PropTypes.node,
  resources: PropTypes.object,
}
