export default [
  // Asia
  { code: 'zh', native: '汉语' }, // Chinese (Simplified) 🇨🇳
  { code: 'ja', native: '日本語,' }, // Japanese 🇯🇵
  { code: 'ko', native: '한국어' }, // Korean 🇰🇷
  { code: 'hi', native: 'हिन्दी' }, // Hindi (India 🇮🇳)
  { code: 'ml', native: 'മലയാളം' }, // Malayalam (India 🇮🇳)
  { code: 'kn', native: 'ಕನ್ನಡ' }, // Kaanada (India 🇮🇳)
  { code: 'te', native: 'తెలుగు' }, // Telugu (India 🇮🇳)
  { code: 'ta', native: 'தமிழ்' }, // Tamil (India 🇮🇳)
  { code: 'si', native: 'සිංහල' }, // Sinhala (Sri Lanka 🇱🇰)
  { code: 'tl', native: 'ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' }, // Tagalog (Philippines) 🇵🇭
  { code: 'fil', native: 'Wikang Filipino' }, // Filipino (Philippines) 🇵🇭
  { code: 'id', native: 'Bahasa Indonesia' }, // Indonesian 🇮🇩
  { code: 'ms', native: 'Bahasa Melayu' }, // Malay 🇲🇾
  { code: 'th', native: 'ภาษาไทย' }, // Thai 🇹🇭

  // Africa
  { code: 'sw', native: 'Kiswahili' }, // Swahili (🇷🇼 🇹🇿 🇰🇪 🇺🇬)
  { code: 'om', native: 'Afaan Oromoo' }, // Oromo (Ethiopia 🇪🇹)
  { code: 'am', native: 'አማርኛ (Amarəñña)' }, // Amharic (Ethiopia 🇪🇹)

  // Middle-East
  { code: 'he', native: 'עִבְרִית' }, // Hebrew 🇮🇱
  { code: 'ar', native: 'العربية' }, // Arabic 🇸🇦

  // Europe
  { code: 'en', native: 'English' }, // 🇺🇸 🇬🇧
  { code: 'es', native: 'Español' }, // Spanish 🇦🇷 🇪🇸
  { code: 'pt', native: 'Português' }, // Portuguese 🇧🇷 🇵🇹
  { code: 'fr', native: 'Français' }, // French 🇫🇷
  { code: 'de', native: 'Deutsch' }, // German 🇩🇪
  { code: 'cs', native: 'Čeština' }, // Czech 🇨🇿
  { code: 'da', native: 'Dansk' }, // Danish 🇩🇰
  { code: 'is', native: 'Islenska' }, // Islandian 🇮🇸
  { code: 'no', native: 'Norwegian' }, // Norwegian 🇳🇴
  { code: 'nb', native: 'Norsk Bokmål' }, // Norwegian Bokmål 🇳🇴
  { code: 'fi', native: 'Suomi' }, // Finnish 🇫🇮
  { code: 'sv', native: 'Svenska' }, // Swedish 🇸🇪
  { code: 'it', native: 'Italiano' }, // Italian 🇮🇹
  { code: 'pl', native: 'Polski' }, // Polish 🇵🇱
  { code: 'ro', native: 'Limba română' }, // Romanian 🇷🇴
  { code: 'hu', native: 'Magyar nyelv' }, // Hungarian 🇭🇺
  { code: 'bg', native: 'български' }, // Bulgarian 🇧🇬
  { code: 'uk', native: 'украї́нська мо́ва' }, // Ukranian 🇺🇦
  { code: 'ru', native: 'русский язык' }, // Russian 🇷🇺
  { code: 'hy', native: 'հայերէն/հայերեն' }, // Armenian 🇦🇲
  { code: 'tr', native: 'Türkçe' }, // Turkish 🇹🇷
  { code: 'ky', native: 'Кыргыз тили, قیرغیزچا' }, // Kyrgyz 🇰🇬
  { code: 'kk', native: 'қазақша, قازاقشا' }, // Kazakh 🇰🇿
  { code: 'uz', native: 'Ўзбекча' }, // Uzbek 🇺🇿
  { code: 'fa', native: 'فارسی' }, // Persian 🇮🇷

  // Oceania
  { code: 'fj', native: 'Vosa vaka-Viti' }, // Fijian 🇫🇯
  { code: 'mi', native: 'Māori' }, // Maori 🇳🇿
  { code: 'sm', native: 'Gagana Sāmoa' }, // Samoan 🇼🇸
  { code: 'to', native: 'Lea fakatonga' }, // Tongan 🇹🇴
]
