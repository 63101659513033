import PropTypes from 'prop-types'
import React from 'react'

import Field from '../Field'
import LocationPickerInput from './Input'

/**
 * LocationPicker field component
 */
export default function LocationPickerField({
  className,
  defaultZoom,
  error,
  height,
  help,
  id,
  label,
  mapDefaultOpen,
  name,
  onChange,
  onResult,
  required,
  types,
  value,
  width,
}) {
  return (
    <Field
      id={id}
      className={className}
      name={name}
      label={label}
      help={help}
      error={error}
      required={required}
    >
      <LocationPickerInput
        id={name}
        name={name}
        onChange={onChange}
        onResult={onResult}
        value={value}
        height={height}
        width={width}
        defaultZoom={defaultZoom}
        mapDefaultOpen={mapDefaultOpen}
        types={types}
      />
    </Field>
  )
}
LocationPickerField.propTypes = {
  className: PropTypes.string,
  defaultZoom: PropTypes.number,
  id: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mapDefaultOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  error: PropTypes.string,
  onChange: PropTypes.func,
  onResult: PropTypes.func,
  required: PropTypes.bool,
  rules: PropTypes.object,
  types: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
