import React from 'react'

import config from './config'

const RegistrationFormBlock = React.lazy(() => import('./Block'))
const RegistrationFormSettings = React.lazy(() => import('./Settings'))

export default function RegistrationForm(props) {
  return <RegistrationFormBlock {...props} />
}

RegistrationForm.toolbarItem = {
  ...config,
  component: <RegistrationForm />,
}

RegistrationForm.craft = {
  displayName: 'RegistrationForm',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: RegistrationFormSettings,
  },
}
