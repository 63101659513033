import { getFontCDNUrl } from '@utils/documents'

// Font formats map (key: file extension, value: font format)
const formatMap = {
  ttf: 'truetype',
  woff: 'woff',
  woff2: 'woff2',
  otf: 'opentype',
}

/**
 * Returns an array of font-face rules
 *
 * @param {Object} fonts - fonts object ( format: { [fontKey]: { family, file, format, fallbackFile, fallbackFormat, isVariable, variants } } )
 * @returns {Array} fontFaceRules - array of font-face rules
 */
export function getFontFaceRules(fonts = {}) {
  const fontFaceRules = Object.entries(fonts).reduce((rules, [, font]) => {
    const fontFace = fontFaceRule(font)

    if (fontFace) rules.push(fontFace)

    return rules
  }, [])

  return fontFaceRules?.length > 0 ? fontFaceRules.join('\n') : ''
}

/**
 * Returns one or more font-face rules for a font family (one rule for variable fonts, one rule for each variant on non-variable fonts).
 *
 * @param {Object} font - font object ( format: { family, file, format, fallbackFile, fallbackFormat, isVariable, variants } )
 * @returns {String} fontFaceRules - font-face rules
 */
function fontFaceRule(font) {
  const {
    family,
    file,
    format,
    fallbackFile,
    fallbackFormat,
    isVariable,
    variants,
  } = font
  // If the font has no family, return an empty string
  if (!family) return ''

  // If the font is a variable font, return a single font-face rule for the font (if it has a file)
  if (isVariable) {
    return getFontFace({
      family,
      file,
      format,
      fallbackFile,
      fallbackFormat,
      isVariable,
    })
  }

  // If the font has no variants, return an empty string
  if (!variants) return ''

  // Get font-face rules for each variant of the font
  const variantRules = variants?.reduce((acc, variant) => {
    const {
      file,
      weight = 'normal',
      style = 'normal',
      format,
      fallbackFile,
      fallbackFormat,
    } = variant

    // If the variant has no file, return the accumulator
    if (!file) return acc

    acc.push(
      getFontFace({
        family,
        weight,
        style,
        file,
        format,
        fallbackFile,
        fallbackFormat,
      })
    )

    return acc
  }, [])

  return variantRules?.join('\n')
}

/**
 * Returns a single font-face rule for a font file.
 *
 * @param {Object} font - font object ( format: { family, weight, style, file, format, fallbackFile, fallbackFormat, isVariable } )
 * @returns {String} fontFaceRule - font-face rule
 */
function getFontFace({
  family,
  weight,
  style,
  file,
  format,
  fallbackFile,
  fallbackFormat,
  isVariable,
}) {
  // Initialize font-face rule sources array with the main font file
  const sources = [getFontUrl(file, format)]

  // Add fallback font file if it exists
  if (fallbackFile) {
    sources.push(getFontUrl(fallbackFile, fallbackFormat))
  }

  // Join font-face rule sources array into a string with a comma separator
  const src = sources.join(', ')

  // Return font-face rule string
  return `@font-face { font-family: '${family}'; src: ${src}; ${
    isVariable ? '' : `font-weight: ${weight}; font-style: ${style};`
  } font-display: swap; }`
}

/**
 * Returns a font url for a font file.
 * @param {String} file - font file path
 * @param {String} format - font file format
 * @returns {String} fontUrl - font url
 * @example
 * getFontUrl('/fonts/Inter-roman.var.woff2', 'woff2')
 * // returns "url('/fonts/Inter-roman.var.woff2') format('woff2')"
 */
function getFontUrl(file) {
  if (!file) return ''
  const fileUrl = getFontCDNUrl(file)
  const format = file.name?.split('.')?.pop() || ''

  return `url('${fileUrl}') format('${formatMap[format]}')`
}
