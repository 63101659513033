import React from 'react'
import PropTypes from 'prop-types'

import Image from './Image'
import Link from '@ui/navigation/Link'

const sizes = {
  sm: 'w-48',
  md: 'w-72',
  lg: 'w-96',
  full: 'w-full',
}

export default function Card({ children, className, size }) {
  return (
    <div
      className={`space-y-3 rounded bg-white pb-6 shadow ${sizes[size]} ${className}`}
    >
      {children}
    </div>
  )
}
Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'full']),
}
Card.defaultProps = {
  className: '',
  size: 'md',
}

function CardHeader({ subtitle, title }) {
  return (
    <div className="px-6 pt-6">
      <div className="text-sm font-bold uppercase tracking-wider text-gray-600">
        {title}
      </div>
      <div className="text-sm text-gray-400">{subtitle}</div>
    </div>
  )
}
CardHeader.propTypes = {
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
Card.Header = CardHeader
Card.Header.displayName = 'Card.Header'

function CardCover({ href, src, title }) {
  const image = <Image className="rounded-t" src={src} alt={title} />
  return href ? <Link href={href}>{image}</Link> : image
}
CardCover.propTypes = {
  href: PropTypes.string,
  src: PropTypes.string,
  title: PropTypes.string,
}
Card.Cover = CardCover
Card.Cover.displayName = 'Card.Cover'

function CardTitle({ children, kicker, title }) {
  return (
    <div className="px-6">
      {kicker && <div className="text-sm text-primary-500">{kicker}</div>}
      <div className="font-bold text-gray-600">{children || title}</div>
    </div>
  )
}
CardTitle.propTypes = {
  children: PropTypes.node,
  kicker: PropTypes.string,
  title: PropTypes.string,
}
Card.Title = CardTitle
Card.Title.displayName = 'Card.Title'

function CardBody({ children }) {
  return <div className="px-6">{children}</div>
}
CardBody.propTypes = {
  children: PropTypes.node,
}
Card.Body = CardBody
Card.Body.displayName = 'Card.Body'

function CardFooter({ children }) {
  return <div className="px-6">{children}</div>
}
CardFooter.propTypes = {
  children: PropTypes.node,
}
Card.Footer = CardFooter
Card.Footer.displayName = 'Card.Footer'
