import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { useSides } from '../SideSelect'
import BorderRadiusSelectField from './Field'
import { keys } from './constants'

export default function BorderRadiusController({
  defaultValue,
  name = 'borderRadius',
  label,
  help,
}) {
  const { isMixed, onChange, onChangeAll } = useSides(keys)

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      render={({ field }) => (
        <BorderRadiusSelectField
          name={name}
          label={label}
          help={help}
          isMixed={isMixed(field)}
          onChange={onChange(field)}
          onChangeAll={onChangeAll(field)}
          value={field.value}
        />
      )}
    />
  )
}

BorderRadiusController.propTypes = {
  defaultValue: PropTypes.object,
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
}
