import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useArticle from '@modules/articles/services/hooks/useArticle'
import useArticles from '@modules/articles/services/hooks/useArticles'
import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import SortFields from '@modules/web/components/ContentEditor/blocks/shared/SortFields'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import SubFields from '@modules/web/components/ContentEditor/shared/SubFields'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { useBlock } from '@modules/web/helpers/useBlock'
import useSite from '@modules/web/services/hooks/useSite'
import Image from '@ui/data-display/Image'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'
import FormatDate from '@ui/helpers/FormatDate'

import { ArticlePicker } from '../Picker'
import CategoriesFields from './shared/CategoriesFields'
import FlagsFields from './shared/FlagsFields'
import LocationContextSelect from './shared/LocationContextSelect'

const config = {
  name: 'ArticleHeader',
  label: 'articles/public:articleHeader',
  type: 'plugin',
  icon: 'star',
  component: <ArticleHeader />,
}

function getPlaceholder() {
  return {
    image: {},
    title: '[Placeholder title]',
    subtitle: '[Placeholder subtitle]',
    categories: '[Categories]',
    createdAt: new Date(),
  }
}

function getWrapperClasses(breakpoint = 'xs') {
  return {
    xs: 'flex-col',
    sm: 'flex-col',
    md: 'flex-row',
    lg: 'flex-row',
    xl: 'flex-row',
  }[breakpoint]
}

function getImageClasses(breakpoint = 'xs') {
  return {
    xs: '',
    sm: '',
    md: 'w-1/2',
    lg: 'w-2/3',
    xl: 'w-3/4',
  }[breakpoint]
}

function getArticleClasses(breakpoint = 'xs') {
  return {
    xs: '',
    sm: '',
    md: 'w-1/2',
    lg: 'w-1/3',
    xl: 'w-1/4',
  }[breakpoint]
}

export default function ArticleHeader({
  id,
  dynamic,
  filtered,
  flag = null,
  flagExceptions = [],
  categories: queryCategories = [],
  sortField = 'publishedAt',
  sortOrder = 'desc',
}) {
  const { t } = useTranslation('articles/public')
  const { site } = useSite()

  const { article } = useArticle(dynamic || filtered ? null : id)
  const { articles = [] } = useArticles({
    categories: queryCategories,
    limit: 1,
    flag,
    flagExceptions,
    sort: sortField,
    order: sortOrder,
    siteId: site?.id,
  })
  const { breakpoint } = useBreakpoint()

  const {
    image,
    title,
    subtitle,
    categories = [],
    createdAt,
    sites,
  } = (filtered ? articles[0] : article) || getPlaceholder()

  return (
    <Content
      icon={config.icon}
      title={t('articleHeader')}
      locked={dynamic}
      className="w-full"
    >
      <div className={`flex ${getWrapperClasses(breakpoint)}`}>
        {image?.file && (
          <Image
            file={image.file}
            alt={title}
            width={600}
            className={getImageClasses(breakpoint)}
          />
        )}
        <div
          className={`flex flex-col gap-2 p-2 ${getArticleClasses(breakpoint)}`}
        >
          <h2 className="font-bold">{title}</h2>
          {subtitle && <h3 className="italic">{subtitle}</h3>}
          {categories.length > 0 && <p>{categories}</p>}
          <p>
            <FormatDate
              format="P"
              date={sites?.[site?.id]?.startsAt || createdAt}
            />
          </p>
        </div>
      </div>
    </Content>
  )
}

ArticleHeader.propTypes = {
  dynamic: PropTypes.bool,
  filtered: PropTypes.bool,
  id: PropTypes.string,
  flag: PropTypes.string,
  flagExceptions: PropTypes.arrayOf(PropTypes.string),
  categories: PropTypes.arrayOf(PropTypes.string),
  sortField: PropTypes.string,
  sortOrder: PropTypes.string,
}
ArticleHeader.toolbarItem = config

function ArticleHeaderSettings() {
  const design = useDesignContext()
  const { t } = useTranslation([
    'articles/public',
    'web/content-editor',
    design.namespace,
  ])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const {
    variants = [],
    showOrganization,
    showFlag,
    locationContext,
  } = useBlock(config.name)

  const onArticleIdChange = useCallback(
    value => {
      actions.setProp(props => (props.id = value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('articleHeader')} help={t('articleHeaderHelp')}>
      <div className="flex flex-col gap-4">
        <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        <Checkbox
          label={t('filtered')}
          help={t('filteredHelp')}
          onChange={value => {
            actions.setProp(props => (props.filtered = value))
          }}
          value={data.filtered}
          name="filtered"
        />
        {!data.dynamic && !data.filtered && (
          <ArticlePicker
            onChange={onArticleIdChange}
            value={data.id}
            currentSiteOnly
          />
        )}
        {data.filtered && (
          <>
            <SortFields singular />
            <CategoriesFields singular />
            <FlagsFields singular />
          </>
        )}
        {variants.length > 0 && (
          <Select
            label={t('web/content-editor:variant')}
            onChange={e =>
              actions.setProp(value => (value.variant = e.target.value))
            }
            value={data.variant}
          >
            {data.variant && (
              <SelectOption label={t('clearSelection')} value={null} />
            )}
            <SelectPlaceholder label="-" />
            {variants.map(key => (
              <SelectOption
                value={key}
                label={t(`${design.namespace}:headerVariant-${key}`)}
                key={key}
              />
            ))}
          </Select>
        )}
        <Checkbox
          name="showSubtitle"
          label={t('showSubtitle')}
          onChange={value => {
            actions.setProp(props => (props.showSubtitle = value))
          }}
          value={data.showSubtitle}
        />
        <Checkbox
          name="showAuthor"
          label={t('showAuthor')}
          onChange={value => {
            actions.setProp(props => (props.showAuthor = value))
          }}
          value={data.showAuthor}
        />
        <Checkbox
          name="showLocation"
          label={t('showLocation')}
          onChange={value => {
            actions.setProp(props => (props.showLocation = value))
          }}
          value={data.showLocation}
        />
        {locationContext && data.showLocation && (
          <SubFields indentSize="lg">
            <LocationContextSelect />
          </SubFields>
        )}
        <Checkbox
          name="showDate"
          label={t('showDate')}
          onChange={value => {
            actions.setProp(props => (props.showDate = value))
          }}
          value={data.showDate}
        />
        <Checkbox
          name="showCategories"
          label={t('showCategories')}
          onChange={value => {
            actions.setProp(props => (props.showCategories = value))
          }}
          value={data.showCategories}
        />
        {showFlag && (
          <Checkbox
            name="showFlag"
            label={t('showFlag')}
            onChange={value => {
              actions.setProp(props => (props.showFlag = value))
            }}
            value={data.showFlag}
          />
        )}
        {showOrganization && (
          <Checkbox
            name="showOrganization"
            label={t('showOrganization')}
            onChange={value => {
              actions.setProp(props => (props.showOrganization = value))
            }}
            value={data.showOrganization}
          />
        )}
        <LinkingFields
          detailPagehelp={t('detailPageIdHelpHighlight')}
          resourceIdentifier="Article"
          showListPagePicker={false}
        />
      </div>
    </SettingsWrap>
  )
}

ArticleHeader.craft = {
  displayName: 'ArticleHeader',
  props: {
    id: undefined,
    dynamic: false,
    filtered: false,
    sortField: 'publishedAt',
    sortOrder: 'desc',
    showAuthor: true,
    showLocation: true,
    showDate: true,
    showSubtitle: true,
    showCategories: true,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleHeaderSettings,
  },
}
