export default [
  {
    value: 'divineService',
    label: 'serviceType_divineService',
  },
  {
    value: 'youthGroup',
    label: 'serviceType_youthGroup',
  },
  {
    value: 'pathfinderGroup',
    label: 'serviceType_pathfinderGroup',
  },
  // {
  //   value: '',
  //   label: '-',
  // },
  // {
  //   value: 'toddlerGroup',
  //   label: 'serviceType_toddlerGroup',
  // },
  // {
  //   value: 'songService',
  //   label: 'serviceType_songService',
  // },
  // {
  //   value: 'creativeChurch',
  //   label: 'serviceType_creativeChurch',
  // },
  // {
  //   value: 'fellowshipLunch',
  //   label: 'serviceType_fellowshipLunch',
  // },
  // {
  //   value: 'prayerMeeting',
  //   label: 'serviceType_prayerMeeting',
  // },
  // {
  //   value: 'firstService',
  //   label: 'serviceType_firstService',
  // },
  // {
  //   value: 'evangelisticMeeting',
  //   label: 'serviceType_evangelisticMeeting',
  // },
  // {
  //   value: 'fridaynightworship',
  //   label: 'serviceType_fridaynightworship',
  // },
  // {
  //   value: 'sabbathmorningdevotional',
  //   label: 'serviceType_sabbathmorningdevotional',
  // },
  // {
  //   value: 'smallGroup',
  //   label: 'serviceType_smallGroup',
  // },
  // {
  //   value: 'expressChurch',
  //   label: 'serviceType_expressChurch',
  // },
  // {
  //   value: 'afternoonService',
  //   label: 'serviceType_afternoonService',
  // },
  // {
  //   value: 'youthBiblestudy',
  //   label: 'serviceType_youthBiblestudy',
  // },
  // {
  //   value: 'bibleStudy',
  //   label: 'serviceType_bibleStudy',
  // },
  // {
  //   value: 'familyService',
  //   label: 'serviceType_familyService',
  // },
  // {
  //   value: 'prayerService',
  //   label: 'serviceType_prayerService',
  // },
  // {
  //   value: 'fridayVespers',
  //   label: 'serviceType_fridayVespers',
  // },
  // {
  //   value: 'fridayEveningVesper',
  //   label: 'serviceType_fridayEveningVesper',
  // },
  // {
  //   value: 'worshipService',
  //   label: 'serviceType_worshipService',
  // },
  // {
  //   value: 'testimoniesServices',
  //   label: 'serviceType_testimoniesServices',
  // },
  // {
  //   value: 'prehenLifestyleCentreExerciseClass',
  //   label: 'serviceType_prehenLifestyleCentreExerciseClass',
  // },
  // {
  //   value: 'prehenLifestyleExerciseClass',
  //   label: 'serviceType_prehenLifestyleExerciseClass',
  // },
  // {
  //   value: 'cookingDemo',
  //   label: 'serviceType_cookingDemo',
  // },
  // {
  //   value: 'fridayNightPrayerMeeting',
  //   label: 'serviceType_fridayNightPrayerMeeting',
  // },
  // {
  //   value: 'bibleStudyZoom',
  //   label: 'serviceType_bibleStudyZoom',
  // },
  // {
  //   value: 'prayerMeeting',
  //   label: 'serviceType_prayerMeeting',
  // },
  // {
  //   value: 'prayerMeetingZoom',
  //   label: 'serviceType_prayerMeetingZoom',
  // },
  // {
  //   value: 'prayerGarden',
  //   label: 'serviceType_prayerGarden',
  // },
  {
    value: 'sabbathSchool',
    label: 'serviceType_sabbathSchool',
  },
]
