import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import Header from '@ui/data-display/Header'
import Message from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import Password from '@ui/data-entry/Password'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'
import Link from '@ui/navigation/Link'

import { useLogin } from '../services/AuthService'

// import useLogin from '../helpers/useLogin'

export default function Login() {
  const { t } = useTranslation('auth')
  const navigate = useNavigate()
  const location = useLocation()

  const [message, setMessage] = React.useState()
  const [showTwoFactor, setShowTwoFactor] = React.useState(false)

  const { mutate: login, isLoading } = useLogin()

  const data = React.useMemo(() => ({ email: '', password: '', token: '' }), [])

  const redirect = new URLSearchParams(location.search).get('redirect')

  // console.log('redirect', redirect)

  const onLogin = React.useCallback(
    async ({ email, password, token }) => {
      login(
        { email, password, token },
        {
          onError: error => {
            console.error(error) // eslint-disable-line no-console
            // Set the error message to be displayed
            setMessage({
              title: 'authenticationError',
              text: 'authenticationErrorMessage',
              type: 'danger',
            })
          },
          onSuccess: ({ loggedIn, twoFactorRequired }) => {
            if (loggedIn) {
              setMessage({ text: 'loginSuccess', type: 'success' })
              // Redirect to the previous page or the dashboard
              navigate(redirect || '/')
              return // Stops the execution of the success function here
            }

            if (twoFactorRequired) {
              // Show the 2FA input
              setShowTwoFactor(true)
              setMessage({ text: '2FARequired', type: 'info' })
            }
          },
        }
      )
    },
    [login, navigate, redirect]
  )

  return (
    <>
      <Header
        title={t('provideCredentials')}
        kicker={t('welcome')}
        headTitle={t('login')}
      />
      {message && (
        <Message
          title={t(message.title)}
          text={t(message.text)}
          type={message.type}
        />
      )}
      <Form
        data={data}
        onSubmit={onLogin}
        validationMode="onSubmit"
        // focusedField={showTwoFactor ? 'token' : 'email'}
      >
        <Box
          space="md"
          className={`transition-all duration-300 ease-in-out ${
            !showTwoFactor
              ? 'max-h-screen overflow-visible opacity-100'
              : 'max-h-0 overflow-hidden opacity-0'
          }`}
        >
          <Input.Email name="email" label={t('email')} required />
          <Password
            name="password"
            label={t('password')}
            required
            autocomplete="current-password"
            validatePattern={false}
          />
        </Box>

        <div
          className={`transition-all duration-100 ease-in-out ${
            showTwoFactor
              ? 'max-h-screen overflow-visible opacity-100'
              : 'max-h-0 overflow-hidden opacity-0'
          }`}
        >
          <Input
            name="token"
            label={t('token')}
            help={t('tokenHelp')}
            required={showTwoFactor}
          />
        </div>
        <Submit
          label={t('access')}
          icon="sign-in"
          className="mt-8"
          loading={isLoading}
        />
      </Form>
      <p>
        <Link href="/auth/forgot-password">{t('forgotPassword')}</Link>
      </p>
    </>
  )
}
