import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  id: null,
  enabled: true,
  translationsSort: '',
}

export default function usePublication(options = defaultOptions) {
  const { id, enabled, translationsSort } = Object.assign(
    {},
    defaultOptions,
    options
  )

  const params = useParams()

  const publicationId = id || params?.publicationId

  const { data, error, isLoading } = useQuery(
    ['publications', publicationId, translationsSort, enabled],
    () =>
      getFetch(`/api/publications/${publicationId}`, {
        translationsSort,
      }),
    { enabled: enabled && !!publicationId }
  )

  return { publication: data, publicationId, error, loading: isLoading }
}
