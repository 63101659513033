import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import ImageUpload from '@ui/data-entry/ImageUpload'

export default function HeaderCentreLogoImageUpload() {
  const design = useDesignContext()
  const { onImageUpload } = useImageUpload()
  const { t } = useTranslation(design.namespace)

  const { watch } = useFormContext()

  const headerAppearance = watch('design.header.appearance')

  if (headerAppearance !== 'collapsedLinks') {
    return null
  }

  return (
    <ImageUpload
      name="design[header][centreLogo]"
      label={t('headerCentreLogo')}
      help={t('headerCentreLogoHelp')}
      onUpload={onImageUpload}
      maxSize="10mb"
      accept={design.logoFormats}
    />
  )
}
