import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import { isFunction } from '@utils/types'

import Field from './Field'
import { Input } from './Input'

export function Search({
  className,
  onChange,
  onSearch,
  fullWidth,
  showButton,
  placeholder,
  ...inputProps
}) {
  const fullWidthClass = fullWidth ? 'w-full' : ''

  const { t } = useTranslation()

  return (
    <div className={`flex space-x-2 ${className} ${fullWidthClass}`}>
      <Input
        placeholder={placeholder || t('searchPlaceholder')}
        {...inputProps}
        onChange={onChange}
      />
      {showButton && (
        <Button icon="search" onClick={onSearch} variant="primary" />
      )}
    </div>
  )
}
Search.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.string,
  showButton: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'number', 'tel', 'password']),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
Search.defaultProps = {
  className: '',
  type: 'text',
  fullWidth: true,
  showButton: true,
}

export default function SearchField({
  className,
  disabled,
  error,
  help,
  label,
  max,
  maxLength,
  min,
  minLength,
  name,
  onChange,
  pattern,
  required,
  shouldUnregister,
  type,
  validate,
  value,
}) {
  const { control, formState } = useFormContext()
  const validationError = formState.errors[name]

  const rules = useMemo(() => {
    return {
      max,
      maxLength,
      min,
      minLength,
      pattern,
      required,
      validate,
    }
  }, [max, maxLength, min, minLength, pattern, required, validate])

  const onFieldChange = useCallback(
    field => event => {
      field.onChange(event)

      if (isFunction(onChange)) {
        onChange(event.currentTarget.value)
      }
    },
    [onChange]
  )

  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={validationError ? validationError.message : error}
      help={help}
      required={rules?.required}
    >
      <Controller
        name={name}
        control={control}
        defaultValue={value}
        rules={rules}
        shouldUnregister={shouldUnregister}
        render={({ field }) => (
          <Search
            disabled={disabled}
            name={name}
            id={name}
            type={type}
            value={field.value}
            onChange={onFieldChange(field)}
            onBlur={field.onBlur}
          />
        )}
      />
    </Field>
  )
}

SearchField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  max: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  min: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  pattern: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.shape({ value: PropTypes.object, message: PropTypes.string }),
  ]),
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  shouldUnregister: PropTypes.bool,
  type: PropTypes.oneOf(['text', 'email', 'number', 'tel']),
  validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
SearchField.defaultProps = {
  fullWidth: true,
  type: 'text',
  value: '',
}
SearchField.displayName = 'Search'
