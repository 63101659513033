import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useCourses({
  providerId,
  search = '',
  limit = 10,
  page = 1,
  statuses = ['active', 'disabled'],
  ids,
  sort = 'title',
  sortOrder = 'asc',
  skip,
}) {
  const { data, error, isLoading, isFetching } = useQuery(
    [
      'courses',
      {
        providerId,
        search,
        limit,
        page,
        sort,
        sortOrder,
        skip,
        statuses,
        ids,
      },
    ],
    () =>
      getFetch(
        providerId ? `/api/courses/providers/${providerId}/courses` : null,
        {
          sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
          limit: limit,
          skip: skip,
          page: page,
          search: search,
          ids: ids?.join(','),
          status: statuses?.join(','),
        }
      )
  )

  return {
    courses: data?.items,
    count: data?.count,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
