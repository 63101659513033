import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Details from '@ui/data-display/Details'
import { BooleanTag } from '@ui/data-display/Tag'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function WordPressImportTaskDetails({ task }) {
  const { t } = useTranslation('articles/tasks')

  const { meta } = task ?? {}
  const { context } = meta ?? {}

  const categories =
    context?.categories
      ?.filter(c => task.settings.categories?.includes(c.id))
      ?.map(({ name, id }) => ({ name, id })) ?? []

  const excludedCategories =
    context?.categories
      ?.filter(c => task.settings.categoriesExclude?.includes(c.id))
      ?.map(({ name, id }) => ({ name, id })) ?? []

  return (
    <Box dividers>
      <Box>
        <Details.Item
          label={t('wordpress-import-restApiUrl')}
          value={<p>{task.settings.restApiUrl}</p>}
        />
        <Details.Item
          label={t('wordpress-import-restApiUrl')}
          value={<p>{task.settings.restApiUrl}</p>}
        />
        <Details.Item
          label={t('wordpress-import-categories')}
          value={
            categories.length > 0 ? (
              <ul className="list-disc pl-6">
                {categories.map(({ name, id }) => (
                  <li key={id}>
                    <p>{name}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('wordpress-import-none')}</p>
            )
          }
          vertical={categories.length > 0}
        />
        <Details.Item
          label={t('wordpress-import-categoriesExclude')}
          value={
            excludedCategories.length > 0 ? (
              <ul className="list-disc pl-6">
                {excludedCategories.map(({ name, id }) => (
                  <li key={id}>
                    <p>{name}</p>
                  </li>
                ))}
              </ul>
            ) : (
              <p>{t('wordpress-import-none')}</p>
            )
          }
          vertical={excludedCategories.length > 0}
        />
        <Details.Item
          label={t('wordpress-import-updateSitesOnly')}
          value={<BooleanTag value={task.settings.updateSitesOnly} />}
        />
      </Box>
      {task.settings.fieldMappings?.length && (
        <Details.Item
          label={t('wordpress-import-fieldMappings')}
          value={
            <ul className="flex flex-col gap-4">
              {task.settings.fieldMappings.map(({ target, source }) => {
                return (
                  <li key={target} className="flex flex-col">
                    <span className="w-full flex-1 font-semibold">
                      {target}
                    </span>
                    <code className="flex items-center gap-2 text-sm">
                      <Icon name="arrow-right" className="shrink-0" />
                      {source}
                    </code>
                  </li>
                )
              })}
            </ul>
          }
        />
      )}
    </Box>
  )
}
WordPressImportTaskDetails.propTypes = {
  task: PropTypes.object.isRequired,
}
