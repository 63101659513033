export const publicationsUrl = '/publications'
export const publicationsListUrl = `${publicationsUrl}/list`
export const categoriesUrl = `${publicationsUrl}/categories`
export const typesUrl = `${publicationsUrl}/types`

// Publications URLs

export function getPublicationsUrl(path = '') {
  return `${publicationsListUrl}${path ? `/${path}` : ''}`
}

export function getAddPublicationUrl(path = '') {
  return `${publicationsListUrl}/add${path ? `/${path}` : ''}`
}

export function getPublicationUrl(publication, path = '') {
  return `${publicationsListUrl}/${publication?.id}${path ? `/${path}` : ''}`
}

export function getEditPublicationUrl(publication, path = '') {
  return `${publicationsListUrl}/${publication?.id}/edit${
    path ? `/${path}` : ''
  }`
}
export function getTranslatePublicationUrl(publication, path = '') {
  return `${publicationsListUrl}/${publication?.id}/translate${
    path ? `/${path}` : ''
  }`
}

export function getConfigurePublicationsUrl(path = '') {
  return `${publicationsUrl}/configure${path ? `/${path}` : ''}`
}

// Categories URLs

export function getCategoriesUrl(path = '') {
  return `${categoriesUrl}${path ? `/${path}` : ''}`
}

export function getAddCategoryUrl(path = '') {
  return `${categoriesUrl}/add${path ? `/${path}` : ''}`
}

export function getCategoryUrl(category, path = '') {
  return `${categoriesUrl}/${category?.id}${path ? `/${path}` : ''}`
}

export function getEditCategoryUrl(category, path = '') {
  return `${categoriesUrl}/${category?.id}/edit${path ? `/${path}` : ''}`
}

// Types URLs

export function getTypesUrl(path = '') {
  return `${typesUrl}${path ? `/${path}` : ''}`
}

export function getAddTypeUrl(path = '') {
  return `${typesUrl}/add${path ? `/${path}` : ''}`
}

export function getTypeUrl(type, path = '') {
  return `${typesUrl}/${type?.id}${path ? `/${path}` : ''}`
}

export function getEditTypeUrl(type, path = '') {
  return `${typesUrl}/${type?.id}/edit${path ? `/${path}` : ''}`
}

// Religions URLs

export function getReligionsUrl(path = '') {
  return `${publicationsUrl}/religions${path ? `/${path}` : ''}`
}

export function getAddReligionUrl(path = '') {
  return `${publicationsUrl}/religions/add${path ? `/${path}` : ''}`
}

export function getReligionUrl(religion, path = '') {
  return `${publicationsUrl}/religions/${religion?.id}${path ? `/${path}` : ''}`
}

export function getEditReligionUrl(religion, path = '') {
  return `${publicationsUrl}/religions/${religion?.id}/edit${
    path ? `/${path}` : ''
  }`
}

// PublishingHouses URLs

export function getPublishingHousesUrl(path = '') {
  return `${publicationsUrl}/publishing-houses${path ? `/${path}` : ''}`
}

export function getAddPublishingHouseUrl(path = '') {
  return `${publicationsUrl}/publishing-houses/add${path ? `/${path}` : ''}`
}

export function getPublishingHouseUrl(publishingHouse, path = '') {
  return `${publicationsUrl}/publishing-houses/${publishingHouse?.id}${
    path ? `/${path}` : ''
  }`
}

export function getEditPublishingHouseUrl(publishingHouse, path = '') {
  return `${publicationsUrl}/publishing-houses/${publishingHouse?.id}/edit${
    path ? `/${path}` : ''
  }`
}
