import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { DetailsItem } from '@ui/data-display/Details'
import { BooleanTag } from '@ui/data-display/Tag'

export default function JetstreamUpdaterTaskDetails({ task }) {
  const { t } = useTranslation('media-library/tasks')
  return (
    <div>
      <DetailsItem
        label={t('jetstream-updater-updateMediaLinks')}
        value={<BooleanTag value={task.settings.updateMediaLinks} />}
      />
      <DetailsItem
        label={t('jetstream-updater-updateDuration')}
        value={<BooleanTag value={task.settings.updateDuration} />}
      />
      <DetailsItem
        label={t('jetstream-updater-updateImages')}
        value={<BooleanTag value={task.settings.updateImages} />}
      />
    </div>
  )
}
JetstreamUpdaterTaskDetails.propTypes = {
  task: PropTypes.object.isRequired,
}
