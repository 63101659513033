import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import TextAlignSelectField from './Field'

export default function TextAlignSelectController({
  className,
  help,
  label,
  name = 'textAlign',
  required,
}) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <TextAlignSelectField
          className={className}
          help={help}
          label={label}
          name={name}
          onChange={field.onChange}
          required={required}
          value={field.value}
        />
      )}
    />
  )
}
TextAlignSelectController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
}
