import PropTypes from 'prop-types'
import React from 'react'

import { TasksContext } from './tasksContext'

export default function TaskProvider({ children, tasks }) {
  return <TasksContext.Provider value={tasks}>{children}</TasksContext.Provider>
}
TaskProvider.propTypes = {
  children: PropTypes.node,
  tasks: PropTypes.object,
}
