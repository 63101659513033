import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import ColorsForm from './ColorsForm'

export default function useDesignSettingsTabs() {
  const { t } = useTranslation('designs/hope')

  return useMemo(
    () => [
      {
        name: 'colors',
        title: t('colors'),
        component: ColorsForm,
      },
    ],
    [t]
  )
}
