import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'
import Link from '@ui/navigation/Link'

export default function PublicationListItem({
  publication,
  showDescription,
  showImage,
}) {
  return (
    <div className="flex flex-row-reverse space-x-4 space-x-reverse">
      {showImage && (
        <div className="shrink-0">
          <div className="aspect-w-16 aspect-h-9 w-32">
            <Image
              file={publication.cover?.file}
              width={120}
              className="rounded object-cover"
              alt={publication.title}
            />
          </div>
        </div>
      )}
      <div className="flex-grow space-y-0">
        <div>
          <h3 className="text-lg font-bold">
            <Link href={publication.url}>{publication.title}</Link>
          </h3>
        </div>
        {showDescription && (
          <p className="text-sm">{publication.description}</p>
        )}
      </div>
    </div>
  )
}
PublicationListItem.propTypes = {
  publication: PropTypes.object.isRequired,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  site: PropTypes.object,
}
