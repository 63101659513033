import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Field from '@ui/data-entry/Field'

import { SitePickerInput } from './Input'

/**
 * This is the field component for the SitePicker component.
 * It wraps the SitePickerInput component in a Field (with label, help, etc.), and adds the logic to fetch the list of sites.
 *
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.name - The name of the component.
 * @param {string} props.help - The help text to display below the component.
 * @param {function} props.onChange - The callback to be called when the user selects an site.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {string} props.value - The value of the component.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @returns {React.Component} - The component.
 */
export function SitePickerField({
  className = '',
  disabled,
  help,
  label,
  includeIds,
  maxItems,
  multiple,
  name,
  noLabel,
  onChange,
  placeholder,
  required,
  showValueInline,
  value,
}) {
  const { t } = useTranslation('web/public')

  return (
    <Field
      className={className}
      disabled={disabled}
      help={help}
      label={noLabel ? undefined : label || t('site')}
      name={name}
      placeholder={t('searchPlaceholder')}
      required={required}
    >
      <SitePickerInput
        disabled={disabled}
        includeIds={includeIds}
        maxItems={maxItems}
        multiple={multiple}
        onChange={onChange}
        placeholder={placeholder}
        required={required}
        showValueInline={showValueInline}
        value={value}
      />
    </Field>
  )
}
SitePickerField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  includeIds: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
