import React from 'react'

const AccordionItemTranslation = React.lazy(
  () => import('./Accordion/Item/Translation')
)
const AvatarTranslation = React.lazy(() => import('./Avatar/Translation'))
const ButtonTranslation = React.lazy(() => import('./Button/Translation'))
const CardTranslation = React.lazy(() => import('./Card/Translation'))
const CookieBlockedContentTranslation = React.lazy(
  () => import('./CookieBlockedContent/Translation')
)
const DisqusCommentsTranslation = React.lazy(
  () => import('./DisqusComments/Translation')
)
const FeaturedContentTranslation = React.lazy(
  () => import('./FeaturedContent/Translation')
)
const FormCaptchaTranslation = React.lazy(
  () => import('./Form/Captcha/Translation')
)
const FormCheckboxTranslation = React.lazy(
  () => import('./Form/Checkbox/Translation')
)
const FormInputTranslation = React.lazy(
  () => import('./Form/Input/Translation')
)
const FormTextAreaTranslation = React.lazy(
  () => import('./Form/TextArea/Translation')
)
const FormTranslation = React.lazy(() => import('./Form/Translation'))
const GooglePSEResultsTranslation = React.lazy(
  () => import('./GooglePSEResults/Translation')
)
const HeaderTranslation = React.lazy(() => import('./Header/Translation'))
const HeroTranslation = React.lazy(() => import('./Hero/Translation'))
const ImageTranslation = React.lazy(() => import('./Image/Translation'))
const LabelIconTranslation = React.lazy(() => import('./LabelIcon/Translation'))
const LanguageMenuTranslation = React.lazy(
  () => import('./LanguageMenu/Translation')
)
const LinkListTranslation = React.lazy(() => import('./LinkList/Translation'))
const MenuTranslation = React.lazy(() => import('./Menu/Translation'))
const ModalTranslation = React.lazy(() => import('./Modal/Translation'))
const NewsletterConfirmationTranslation = React.lazy(
  () => import('./NewsletterConfirmation/Translation')
)
const NewsletterSubscriptionTranslation = React.lazy(
  () => import('./NewsletterSubscription/Translation')
)
const RichTextTranslation = React.lazy(() => import('./RichText/Translation'))
const SimpleTextTranslation = React.lazy(
  () => import('./SimpleText/Translation')
)
const PlayerTranslation = React.lazy(() => import('./Player/Translation'))
const QuoteCarouselTranslation = React.lazy(
  () => import('./QuoteCarousel/Translation')
)

// Map of translatable blocks
// NOTE: Add fields and source for each translatable block here
export default {
  Accordion: AccordionItemTranslation,
  Avatar: AvatarTranslation,
  Button: ButtonTranslation,
  Card: CardTranslation,
  CookieBlockedContent: CookieBlockedContentTranslation,
  DisqusComments: DisqusCommentsTranslation,
  FeaturedContent: FeaturedContentTranslation,
  Form: FormTranslation,
  FormCaptcha: FormCaptchaTranslation,
  FormCheckbox: FormCheckboxTranslation,
  FormInput: FormInputTranslation,
  FormTextArea: FormTextAreaTranslation,
  GooglePSEResults: GooglePSEResultsTranslation,
  Header: HeaderTranslation,
  Hero: HeroTranslation,
  Image: ImageTranslation,
  LabelIcon: LabelIconTranslation,
  LanguageMenu: LanguageMenuTranslation,
  LinkList: LinkListTranslation,
  Menu: MenuTranslation,
  Modal: ModalTranslation,
  NewsletterConfirmation: NewsletterConfirmationTranslation,
  NewsletterSubscription: NewsletterSubscriptionTranslation,
  RichText: RichTextTranslation,
  SimpleText: SimpleTextTranslation,
  Player: PlayerTranslation,
  QuoteCarousel: QuoteCarouselTranslation,
}
