import { isEmpty as isEmptyObject } from './objects'
import {
  isArray,
  isNumber,
  isFunction,
  isNil,
  isString,
  isObject,
} from './types'

/**
 * Checks if an array is empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isEmpty(array) {
  return isArray(array) && array.length === 0
}

/**
 * Checks if an array is not empty
 *
 * @param {array} array
 *
 * @return Boolean
 */
export function isNotEmpty(array) {
  return !isEmpty(array)
}

/**
 * Returns an array of numbers given a start and an end value
 * @param {number} start
 * @param {number} end
 * @returns `number[]`
 */
export function range(start, end) {
  if (!isNumber(start) || !isNumber(end)) {
    return []
  }

  if (start === end) {
    return [start]
  }

  return Array.from({ length: end + 1 - start }, (v, k) => k + start)
}

/**
 * Given an array of items, returs another with every item as an instance of Model.
 * @param {function} Model Schema class/function used to create the instances.
 * @param {array} items Array with the origial items.
 * @param {object} extra Any other attributes that will be provided to every item.
 */
export function arrayOf(Model, items, extra = {}) {
  if (!isArray(items) || !isFunction(Model)) return null
  return items.filter(Boolean).map(item => new Model({ ...item, ...extra }))
}

/**
 * Removes empty items in an array. Items considered empty are `null`, `undefined`, `''`, `[]` and `{}`
 *
 * @param {Array} items array of items
 */
export function removeEmpty(items) {
  return items.filter(item => {
    if (isNil(item)) {
      return false
    }
    if (isString(item)) {
      return item.length > 0
    }
    if (isArray(item)) {
      return isNotEmpty(item)
    }
    if (isObject(item)) {
      return !isEmptyObject(item)
    }
    return true
  })
}

/**
 * Conditionally reverses an array
 *
 * @param {Boolean} condition
 * @param {Array} items
 */
export function reverseIf(condition = true, items = []) {
  return condition ? items.reverse() : items
}

/**
 * Converts a two level array into a one level array
 *
 * @param {Array} items
 */
export function flatten(items) {
  return items.reduce((acc, val) => acc.concat(val), [])
}

/**
 * Returns an array with a separator inserted between each element in an Array.
 * @param {array} array items to intersperse
 * @param {any} separator separator item (supports a function: i.e. `(index, current) => "separator" )
 */
export function intersperse(array, separator) {
  const lastIndex = array.length - 1

  return array.reduce((acc, cur, index) => {
    acc.push(cur)
    if (lastIndex !== index) {
      acc.push(isFunction(separator) ? separator(index, cur) : separator)
    }
    return acc
  }, [])
}

/**
 * Checks if a value exists in an array
 *
 * @param {any} needle The searched value (single value or array of values)
 * @param {array} haystack The array
 * @param {boolean} matchAll If set to true, all values of the needle need to be in the haystack
 */
export function inArray(needle, haystack, matchAll = false) {
  return !isArray(needle)
    ? haystack.includes(needle)
    : matchAll
    ? needle.every(i => haystack.includes(i))
    : needle.some(i => haystack.includes(i))
}

/**
 * Reorders the items in an array
 *
 * @param {array} array The searched value (single value or array of values)
 * @param {number} startIndex The index of the item to move
 * @param {number} endIndex The index where the item should be moved to
 */
export function reorder(array, startIndex, endIndex) {
  const result = Array.from(array)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)
  return result
}
