import PropTypes from 'prop-types'
import React from 'react'

/**
 * Select's Group
 */
export default function SelectGroup({ label, children }) {
  return <optgroup label={label}>{children}</optgroup>
}
SelectGroup.propTypes = {
  children: PropTypes.node,
  label: PropTypes.string,
}
