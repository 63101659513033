import React from 'react'
import PropTypes from 'prop-types'
import { addHours, setHours, setMinutes, subHours } from 'date-fns'

import { formatDate } from '../utils'
import { AmPmSelect } from './AmPmSelect'
import { TimeInput } from './TimeInput'
import { useDatetimeLocale } from '@ui/helpers/datetime'

export function TimePicker({ date, onChange }) {
  const locale = useDatetimeLocale()

  if (!date) return null

  const isTimeAMPM =
    formatDate(date, 'p', { locale }).match(/([ap][m])/i) !== null
  const maxHour = isTimeAMPM ? 12 : 23
  const minHour = isTimeAMPM ? 1 : 0

  return (
    <div className="flex flex-row items-center justify-between space-x-2 py-2">
      <TimeInput
        value={formatDate(date, isTimeAMPM ? 'hh' : 'HH', { locale })}
        onChange={({ target }) => {
          const hours = target.valueAsNumber
          onChange(
            setHours(
              date,
              hours > maxHour ? minHour : hours < minHour ? maxHour : hours
            )
          )
        }}
      />
      <span className="pb-1 text-xl font-bold text-gray-500">:</span>
      <TimeInput
        value={formatDate(date, 'mm', { locale })}
        onChange={({ target }) => {
          const minutes = target.valueAsNumber
          onChange(
            setMinutes(date, minutes > 59 ? 0 : minutes < 0 ? 59 : minutes)
          )
        }}
      />
      {isTimeAMPM && (
        <>
          <span className="pb-1 text-xl font-bold text-gray-500">-</span>
          <AmPmSelect
            value={formatDate(date, 'aaa', { locale })}
            onChange={e => {
              e.preventDefault()
              onChange(
                e.currentTarget.value == 'am'
                  ? subHours(date, 12)
                  : addHours(date, 12)
              )
            }}
          />
        </>
      )}
    </div>
  )
}
TimePicker.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.object,
    PropTypes.string,
  ]),
  onChange: PropTypes.func.isRequired,
}
