import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useRef } from 'react'

import {
  FloatingPortal,
  autoUpdate,
  flip,
  offset,
  shift,
  size,
  useFloating,
  useId,
} from '@floating-ui/react'
import { Transition, Listbox as UIListbox } from '@headlessui/react'

import { getSelectedOption as defaultGetSelectedOption } from './utils'

export default function Listbox({
  id,
  name,
  value,
  renderSelectedLabel,
  options = [],
  onChange,
  children,
  fullWidth = true,
  className = '',
  placeholder,
  getSelectedOption,
  by,
  ...rest
}) {
  const containerRef = useRef(null)
  const uid = useId()
  const floatingId = id ?? uid

  const fullWidthClass = fullWidth ? 'w-full' : ''
  const placeholderClass =
    value === null || value === undefined ? 'text-gray-400' : ''

  const { refs, floatingStyles } = useFloating({
    placement: 'bottom-start',
    transform: false,
    whileElementsMounted: autoUpdate,
    middleware: [
      flip({
        fallbackAxisSideDirections: ['top', 'bottom'],
      }),
      shift(),
      offset(4),
      size({
        apply({ availableWidth, elements, rects }) {
          Object.assign(elements.floating.style, {
            maxWidth: `${Math.min(availableWidth, rects.reference.width)}px`,
          })
        },
      }),
    ],
  })

  const selectedOption = useMemo(() => {
    if (value === null || value === undefined) {
      return null
    }

    if (typeof value === 'object' && Boolean(by)) {
      return value
    }

    if (typeof getSelectedOption === 'function') {
      return getSelectedOption(value, options)
    }

    return defaultGetSelectedOption(value, options)
  }, [value, by, options, getSelectedOption])

  const renderLabel = useCallback(() => {
    if (!selectedOption) return placeholder

    const renderedLabel = renderSelectedLabel?.(selectedOption)

    if (renderedLabel) {
      return renderedLabel
    }

    if (selectedOption?.label) {
      return selectedOption.label
    }

    if (by && typeof value === 'object') {
      return value?.label ?? value?.[by] ?? placeholder
    }

    return value ?? placeholder
  }, [by, placeholder, renderSelectedLabel, selectedOption, value])

  return (
    <UIListbox
      {...rest}
      as="div"
      className={`${fullWidthClass}`}
      name={name}
      value={value}
      onChange={onChange}
      ref={containerRef}
    >
      {({ open }) => (
        <>
          <UIListbox.Button
            className={`form-select rounded border-gray-300 text-start focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200 ${fullWidthClass} ${placeholderClass} ${className}`}
            ref={refs.setReference}
          >
            {renderLabel()}
          </UIListbox.Button>
          <FloatingPortal id={floatingId}>
            <Transition
              show={open}
              enter="transition duration-100 ease-out"
              enterFrom="-translate-y-4 opacity-0"
              enterTo="translate-y-0 opacity-100"
              leave="transition duration-75 ease-out"
              leaveFrom="translate-y-0 opacity-100"
              leaveTo="-translate-y-4 opacity-0"
              className="z-max w-full"
              ref={refs.setFloating}
              style={floatingStyles}
            >
              {children}
            </Transition>
          </FloatingPortal>
        </>
      )}
    </UIListbox>
  )
}
Listbox.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  by: PropTypes.string,
  value: PropTypes.any,
  renderSelectedLabel: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  getSelectedOption: PropTypes.func,
  horizontal: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  multiple: PropTypes.bool,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  placeholder: PropTypes.node,
}
