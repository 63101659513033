import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

import { getArticlesConfigureUrl, getArticlesUrl } from '../helpers/urls'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))

export default function ArticlesMenuGroup() {
  const { t } = useTranslation('articles/public')
  const { pathname } = useLocation()
  const canReadArticles = useIsUserAuthorized({
    module: 'articles',
    permission: 'read',
  })

  const canConfigureArticles = useIsUserAuthorized({
    module: 'articles',
    permission: 'configure',
  })

  if (!canReadArticles) return null

  const inArticles = pathname?.startsWith('/articles')
  const inArticlesConfigure = pathname?.startsWith('/articles/configure')

  return (
    <MenuGroup
      name="articles"
      label={t('articles')}
      icon="file-invoice"
      active={inArticles}
      href={!canConfigureArticles ? getArticlesUrl() : undefined}
    >
      {canConfigureArticles && (
        <>
          <MenuItem
            name="articles.list"
            label={t('manageArticles')}
            icon="file-invoice"
            href={getArticlesUrl()}
            active={inArticles && !inArticlesConfigure}
          />

          <MenuDivider />
          <MenuItem
            name="articles.configure"
            label={t('configure')}
            icon="wrench"
            href={getArticlesConfigureUrl()}
            active={inArticlesConfigure}
          />
        </>
      )}
    </MenuGroup>
  )
}
