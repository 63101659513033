import React from 'react'

import EventsMenuGroup from './components/EventsMenuGroup'
import EventDetail from './components/blocks/Detail'
import EventsTeaser from './components/blocks/EventsTeaser'
import EventHeader from './components/blocks/Header'
import EventList from './components/blocks/List'
import EventLocationMap from './components/blocks/LocationMap'
import EventRegistration from './components/blocks/Register'
import EventTerms from './components/blocks/Terms'
import EventPicker from './components/event/EventPicker'
import OrganizerSelect from './components/organizer/OrganizerSelect'
import eventsWavewareImportTask from './tasks/events-waveware-import'

const EventsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    menu: EventsMenuGroup,
    routes: {
      private: {
        '/events/*': { Component: EventsRouter },
      },
    },
    permissions: {
      events: {
        name: 'events',
        icon: 'calendar',
        label: 'events/public:events',
        CustomFilter: {
          component: OrganizerSelect,
          props: {
            label: 'Organizer',
            name: 'events-organizers.records.0',
            controlled: true,
          },
        },
        models: [
          {
            name: 'events',
            label: 'events/public:events',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'events-organizers',
            label: 'events/public:organizers',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'events-regions',
            label: 'events/public:regions',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'events-categories',
            label: 'events/public:categories',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
    blocksResolver: {
      EventHeader,
      EventDetail,
      EventLocationMap,
      EventRegistration,
      EventList,
      EventsTeaser,
      EventTerms,
    },
    blocksToolbars: [
      {
        name: 'events',
        label: 'events/public:events',
        items: [
          EventList,
          EventsTeaser,
          EventHeader,
          EventDetail,
          EventLocationMap,
          EventTerms,
          EventRegistration,
        ],
      },
    ],
    resources: {
      Event: {
        type: 'model',
        name: 'Event',
        label: 'events/public:events',
        labelSingular: 'events/public:event',
        ResourcePicker: EventPicker,
        fields: {
          'title': {
            label: 'events/public:title',
            type: 'string',
          },
          'description': {
            label: 'events/public:description',
            type: 'string',
          },
          'body': {
            label: 'events/public:body',
            type: 'richtext',
          },
          'image.caption': {
            label: 'events/public:imageCaption',
            type: 'string',
          },
          'image.file': {
            label: 'events/public:image',
            type: 'image',
          },
          'location': {
            label: 'events/public:location',
            type: 'string',
          },
        },
      },
    },

    tasks: {
      'events-waveware-import': eventsWavewareImportTask,
    },
  }
}
