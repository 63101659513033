import { useQuery } from 'react-query'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

export default function useSites(
  {
    filterByEntity = true,
    ids,
    limit = 10,
    page,
    search = '',
    skip,
    sort = 'name',
    statuses = ['active', 'disabled'],
  } = {},
  options = {}
) {
  const queryFilter = {}
  const orFilters = []

  const { entity: currentEntity } = useCurrentEntity()
  const currentEntityId = currentEntity?._id || ''

  // search
  if (search) {
    orFilters.push([
      { name: { $regex: escapeSpecialChars(search), $options: 'i' } },
      { domain: { $regex: escapeSpecialChars(search), $options: 'i' } },
    ])
  }

  // status
  if (!isEmpty(statuses)) {
    const statusFilter = []
    if (statuses.includes('active')) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      statusFilter.push({ deleted: true })
    }
    orFilters.push(statusFilter)
  } else {
    queryFilter.deleted = false
  }

  // enable multiple 'or' filters
  if (orFilters.length > 0) {
    queryFilter['$and'] = orFilters.map(filter => ({ $or: filter }))
  }

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'sites',
      {
        ids,
        currentEntityId,
        search,
        sort,
        statuses,
        limit,
        page,
        skip,
        filterByEntity,
      },
    ],
    () =>
      getFetch('/api/web/sites', {
        ids,
        filter: queryFilter,
        sort,
        limit,
        skip,
        page,
      }),
    options
  )

  return {
    sites: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
