import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

import { servicesApiUrl } from '../ServicesServices'

export default function useServices({ entityId }) {
  const { data, error, isLoading, isFetching } = useQuery(
    ['entities', { entityId }],
    () => getFetch(servicesApiUrl, { entityId })
  )

  return {
    services: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
