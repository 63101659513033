import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { BlockAspectRatioSelect } from '@modules/web/components/ContentEditor/shared/AspectRatioSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import {
  BlockWidthSelect,
  useWidthClass,
} from '@modules/web/components/ContentEditor/shared/WidthSelect'
import Image from '@ui/data-display/Image'

import useEntity from '../../../services/hooks/useEntity'

const config = {
  name: 'ChurchImage',
  label: 'entities/public:ChurchImage',
  type: 'plugin',
  icon: 'image',
  component: <ChurchImage />,
}

function getPlaceholder() {
  return {
    name: '[Church name]',
  }
}

export default function ChurchImage({ churchId, dynamic, aspectRatio, width }) {
  const { t } = useTranslation('entities/public')
  const { entity } = useEntity({ id: dynamic ? null : churchId })
  const widthClass = useWidthClass(width)

  const { image, name } = entity || getPlaceholder()

  return (
    <Content icon={config.icon} title={t('ChurchImage')} locked={dynamic}>
      {dynamic && (
        <div className="italic text-gray-500 bg-gray-100 w-full aspect-w-4 aspect-h-3 flex justify-center items-center">
          [Church image]
        </div>
      )}
      {image && (
        <Image
          file={image}
          alt={name}
          width={600}
          className={widthClass || 'w-full'}
          aspectRatio={aspectRatio}
        />
      )}
    </Content>
  )
}
ChurchImage.propTypes = {
  dynamic: PropTypes.bool,
  churchId: PropTypes.string,
  aspectRatio: PropTypes.oneOf(['auto', '1/1', '3/4', '4/3', '16/9']),
  width: PropTypes.object,
}
ChurchImage.toolbarItem = config

function ChurchImageSettings() {
  const { t } = useTranslation('entities/public')

  // const { actions, data } = useNode(node => {
  //   return {
  //     data: node.data.props,
  //   }
  // })

  // We've had to disable the manual church picking due to it not having worked before the conferences started using them in November 2023.
  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.churchId = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('ChurchImage')} help={t('ChurchImageHelp')}>
      <div className="space-y-4">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.churchId}
            types={['church']}
          />
        )} */}
        <BlockAspectRatioSelect />
        <BlockWidthSelect />
      </div>
    </SettingsWrap>
  )
}

ChurchImage.craft = {
  displayName: 'ChurchImage',
  props: {
    churchId: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchImageSettings,
  },
}
