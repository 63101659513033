import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useTranslation } from 'react-i18next'

import Dropdown from '@ui/buttons/FloatingDropdown'
import {
  useTranslationSource,
  useTranslationSourceText,
  useTranslationTarget,
} from '@ui/data-entry/Translation/Context'
import useAutomaticTranslation from '@ui/data-entry/Translation/hooks/useAutomaticTranslation'
import Popover from '@ui/feedback/Popover'

import useImproveText from '../hooks/useImproveText'

export default function ImproveText({
  text,
  context,
  visible,
  selectedText,
  type,
  onSuccess,
  size,
}) {
  const { t } = useTranslation()
  const { aiEnabled, improveText, isLoadingImprovement, error } =
    useImproveText()
  const { translate, isLoadingTranslation = false } = useAutomaticTranslation()
  const targetLanguage = useTranslationTarget()
  const sourceLanguage = useTranslationSource()
  const sourceText = useTranslationSourceText()

  const handleImprovement = useCallback(
    modifiers => async () => {
      const improvedText = await improveText({
        text: selectedText ? selectedText : text, // if selectedText is set, text is the full text
        context: selectedText ? `${text} ${context}` : context, // if selectedText is set, context is the full text
        modifiers, // is an object
        type, // is either 'json' or 'text'
      })

      if (onSuccess) onSuccess(improvedText, text)
    },
    [improveText, text, selectedText, type, context, onSuccess]
  )

  const handleTranslation = useCallback(async () => {
    const translateParams = {
      text: selectedText ? selectedText : sourceText ? sourceText : text,
      type,
      source: sourceLanguage,
      target: targetLanguage,
    }

    const translatedText = await translate(translateParams)

    if (onSuccess) onSuccess(translatedText)
  }, [
    translate,
    onSuccess,
    sourceText,
    selectedText,
    text,
    type,
    sourceLanguage,
    targetLanguage,
  ])

  if (!aiEnabled) return null

  return (
    <div
      className={`flex gap-2 items-center transition-all ease-in-out duration-300  ${
        sourceText || visible ? 'max-h-screen opacity-100' : 'max-h-0 opacity-0'
      }`}
    >
      {error && (
        <Popover placement="bottom" initialOpen>
          <Popover.Trigger
            icon="exclamation-circle"
            variant="danger-light"
            size="xs"
            title={t('blockHasErrors')}
          />
          <Popover.Content>
            <p className="text-danger-600">{error}</p>
          </Popover.Content>
        </Popover>
      )}
      {visible && (
        <Dropdown
          icon="sparkles"
          size={size}
          loading={isLoadingImprovement || isLoadingTranslation}
          variant="ai"
        >
          {sourceText && (
            <Dropdown.Item
              label={t('translateText')}
              icon="language"
              onClick={handleTranslation}
            />
          )}
          <Dropdown.Item
            label={t('improveText')}
            icon="sparkles"
            onClick={handleImprovement({ clarity: {}, consistency: {} })}
          />
          <Dropdown.Item
            label={t('fixGrammar')}
            icon="sparkles"
            onClick={handleImprovement({ grammar: {} })}
          />
          <Dropdown.Item
            label={t('formalizeText')}
            icon="sparkles"
            onClick={handleImprovement({
              formality: {
                level: 'formal',
              },
            })}
          />
          <Dropdown.Item
            label={t('relaxText')}
            icon="sparkles"
            onClick={handleImprovement({
              formality: {
                level: 'relaxed',
                audience: 'youth',
              },
            })}
          />
        </Dropdown>
      )}
    </div>
  )
}
ImproveText.propTypes = {
  text: PropTypes.string, // even when type is json, text needs to be a string
  selectedText: PropTypes.string,
  context: PropTypes.string, // (and the same for context)
  type: PropTypes.oneOf(['json', 'text']),
  onSuccess: PropTypes.func,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
  visible: PropTypes.bool,
}
ImproveText.defaultProps = {
  type: 'text',
  size: 'sm',
  visible: true,
}
