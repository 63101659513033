import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import useSite from '@modules/web/services/hooks/useSite'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'

export default function FieldsToggleFields({
  fieldPrefix = '',
  showCategories,
  showDate,
  showDescription,
  showFlags,
  showImage,
  showLink,
}) {
  const { t } = useTranslation('articles/public')

  const { site } = useSite()

  const isArticleCategoriesEnabled = useFeatureFlagEnabled(
    'article-categories',
    {
      site,
    }
  )

  const { data } = useNode(node => ({
    data: node.data.props,
  }))

  return (
    <>
      {showDate && (
        <BlockField name={`${fieldPrefix}showDate`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showDate')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {!isArticleCategoriesEnabled && showFlags && (
        <BlockField name={`${fieldPrefix}showFlags`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showFlags')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {isArticleCategoriesEnabled && showCategories && (
        <BlockField name={`${fieldPrefix}showCategories`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showCategories')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {showDescription && (
        <BlockField name={`${fieldPrefix}showDescription`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showDescription')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {showImage && (
        <BlockField name={`${fieldPrefix}showImage`}>
          {({ fieldProps, value, onDataChange }) => (
            <CheckboxField
              {...fieldProps}
              label={t('showImage')}
              onChange={onDataChange}
              value={value}
            />
          )}
        </BlockField>
      )}
      {showLink && (
        <>
          <BlockField name={`${fieldPrefix}showLink`}>
            {({ fieldProps, value, onDataChange }) => (
              <CheckboxField
                {...fieldProps}
                label={t('showLink')}
                onChange={onDataChange}
                value={value}
              />
            )}
          </BlockField>
          {data[`${fieldPrefix}linkLabel`] && (
            <BlockField name={`${fieldPrefix}linkLabel`}>
              {({ fieldProps, value, onDataChange }) => (
                <InputField
                  {...fieldProps}
                  label={t('linkLabel')}
                  help={t('linkLabelHelp')}
                  onChange={e => onDataChange(e.target.value)}
                  value={value}
                />
              )}
            </BlockField>
          )}
        </>
      )}
    </>
  )
}

FieldsToggleFields.propTypes = {
  fieldPrefix: PropTypes.string,
  showCategories: PropTypes.bool,
  showDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showFlags: PropTypes.bool,
  showImage: PropTypes.bool,
  showLink: PropTypes.bool,
}
