import React from 'react'

import config from './config'

const EventsTeaserSettings = React.lazy(() => import('./Settings'))
const EventsTeaserBlock = React.lazy(() => import('./Block'))

export default function EventsTeaser(props) {
  return <EventsTeaserBlock {...props} />
}

EventsTeaser.toolbarItem = {
  ...config,
  component: <EventsTeaser />,
}

EventsTeaser.craft = {
  displayName: 'EventsTeaser',
  props: {
    limit: 3,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EventsTeaserSettings,
  },
}
