import PropTypes from 'prop-types'
import React from 'react'

import BlockField from '../BlockField'
import TextAlignSelectField from './Field'

export default function BlockTextAlignSelect({ name }) {
  return (
    <BlockField defaultValue="left" name={name} responsive>
      {({ fieldProps, inheritFrom, inheritValue, onDataChange, value }) => (
        <TextAlignSelectField
          {...fieldProps}
          inheritFrom={inheritFrom}
          inheritValue={inheritValue}
          onChange={e => onDataChange(e.target.value)}
          value={value}
        />
      )}
    </BlockField>
  )
}
BlockTextAlignSelect.propTypes = {
  name: PropTypes.string,
}
