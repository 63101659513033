import PropTypes from 'prop-types'
import React, { useMemo, useState } from 'react'

import Image from '@ui/data-display/Image'

import SvgInline from './SvgInline'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const providers = {
  'jetstream': {
    type: 'video',
    baseUrl: 'https://jstre.am/embed/',
    baseUrlStaging: 'https://stage.jstre.am/embed/',
    label: 'Jetstream',
  },
  'jetstream-audio': {
    type: 'audio',
    baseUrl: 'https://jstre.am/embed/',
    baseUrlStaging: 'https://stage.jstre.am/embed/',
    label: 'Jetstream',
  },
  'jetstream-live': {
    type: 'video',
    baseUrl: 'https://jstre.am/embed/',
    baseUrlStaging: 'https://stage.jstre.am/embed/',
    label: 'Jetstream',
  },
  'vimeo': {
    type: 'video',
    baseUrl: 'https://player.vimeo.com/video/',
    label: 'Vimeo',
  },
  'youtube': {
    type: 'video',
    baseUrl: 'https://www.youtube-nocookie.com/embed/',
    label: 'YouTube',
  },
  'soundcloud': {
    type: 'audio',
    baseUrl:
      'https://w.soundcloud.com/player/?url=https%3A//api.soundcloud.com/tracks/',
    label: 'SoundCloud',
  },
  'hopeMedia': {
    type: 'video',
    baseUrl: 'https://localhost:3005/video?path=',
    label: 'Hope Media',
  },
  'hopeMedia-live': {
    type: 'video',
    baseUrl: 'https://localhost:3005/video?path=',
    label: 'Hope Media',
  },
}

const playIconSizes = {
  sm: 'h-12 w-12',
  md: 'h-16 w-16',
  lg: 'h-24 w-24',
}

export default function Player({
  className,
  fullWidth,
  id,
  provider,
  title,
  poster,
  playIcon,
  playIconSize,
  playIconColor,
  playIconText,
}) {
  const [showPoster, setShowPoster] = useState(Boolean(poster))
  const playIconSizeClasses = playIconSizes[playIconSize]

  const providerConf = providers[provider]

  let baseUrl = providerConf?.baseUrl
  if (provider === 'jetstream' && id) {
    id = !id.startsWith('jsv:') ? `jsv:${id}` : id
    baseUrl = id.startsWith('jsv:stage:')
      ? providerConf?.baseUrlStaging
      : providerConf?.baseUrl
  }

  if (provider === 'jetstream-live' && id) {
    id = !id.startsWith('jsl:') ? `jsl:${id}` : id
    baseUrl = id.startsWith('jsl:stage:')
      ? providerConf?.baseUrlStaging
      : providerConf?.baseUrl
  }

  const url = useMemo(
    () => (providerConf && id ? `${baseUrl}${id}` : null),
    [baseUrl, id, providerConf]
  )

  if (!url) return null

  return (
    <div
      className={`aspect-h-9 aspect-w-16 bg-gray-200 ${
        fullWidth ? 'w-full' : ''
      }
      ${poster ? 'relative' : ''} ${className}`}
    >
      <iframe
        allowFullScreen
        className="absolute left-0 top-0 h-full w-full border-0"
        src={url}
        title={title ? title : `${providerConf.label} ${providerConf.type}`}
        key={`${provider}-${id}`}
      />
      {showPoster && (
        <button
          className="absolute left-0 top-0 h-full w-full border-none bg-transparent"
          onClick={() => setShowPoster(false)}
        >
          <Image
            className="h-full w-full object-cover object-center"
            file={poster}
            alt={title ?? ''}
          />
          <div
            className="absolute left-0 top-0 flex h-full w-full flex-col items-center justify-center"
            style={{ color: playIconColor ?? 'white' }}
          >
            {playIcon ? (
              <SvgInline className={playIconSizeClasses} file={playIcon} />
            ) : (
              <Icon name="play" className={playIconSizeClasses} />
            )}
            <p className="text-white">{playIconText}</p>
          </div>
        </button>
      )}
    </div>
  )
}

Player.propTypes = {
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  provider: PropTypes.oneOf([
    'jetstream',
    'jetstream-live',
    'jetstream-audio',
    'vimeo',
    'youtube',
    'soundcloud',
    'hopeMedia',
    'hopeMedia-live',
  ]),
  title: PropTypes.string,
  poster: PropTypes.object,
  playIcon: PropTypes.object,
  playIconSize: PropTypes.string,
  playIconColor: PropTypes.string,
  playIconText: PropTypes.string,
}
Player.defaultProps = {
  className: '',
}
