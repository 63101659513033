import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { useRules } from '@ui/data-entry/validationHooks'

import { SitePickerField } from './Field'

/**
 * This is is the SitePicker controller component, to use within a Form.
 * It wraps the SitePickerField component in a Controller (with validation, etc.).
 * @param {*} param0
 * @returns
 */
export function SitePickerController({
  className,
  disabled,
  help,
  includeIds,
  label,
  maxItems,
  multiple,
  name,
  noLabel,
  onChange,
  placeholder,
  required,
  shouldUnregister,
  showValueInline,
}) {
  const rules = useRules({ required })

  const handleOnChange = React.useCallback(
    field => value => {
      field.onChange(value)
      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      disabled={disabled}
      render={({ field }) => (
        <SitePickerField
          className={className}
          disabled={disabled}
          help={help}
          includeIds={includeIds}
          label={label}
          maxItems={maxItems}
          multiple={multiple}
          name={name}
          noLabel={noLabel}
          onChange={handleOnChange(field)}
          placeholder={placeholder}
          required={required}
          showValueInline={showValueInline}
          value={field.value}
        />
      )}
    />
  )
}
SitePickerController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  help: PropTypes.string,
  includeIds: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  noLabel: PropTypes.bool,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  showValueInline: PropTypes.bool,
}
