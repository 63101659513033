import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Message, { ErrorMessage } from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import Password from '@ui/data-entry/Password'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'
import Text from '@ui/typography/Text'

import { useDisable2FA } from '../services/AuthService'

export default function Disable2FA({ setFormVisible, user }) {
  const { t } = useTranslation('auth')

  const [showForm, setShowForm] = useState(false)
  const { mutate, isError, isLoading, error, reset } = useDisable2FA()
  const formData = useMemo(() => ({ password: '', token: '' }), [])

  const { cantDisable2FA } = user

  const _onShowForm = useCallback(() => {
    setShowForm(true)
    setFormVisible(true)
  }, [setFormVisible])

  const _onDisable = useCallback(
    data => {
      mutate(data)
    },
    [mutate]
  )

  const _onCancelDisable = useCallback(() => {
    setShowForm(false)
    setFormVisible(false)
    reset()
  }, [reset, setFormVisible])

  return (
    <div className="flex flex-col gap-6">
      {showForm ? (
        <>
          <Heading as="h4" className="text-center" text={t('2FADisable')} />

          <Text className="text-center">{t('2FADisableHelp')}</Text>

          <Form data={formData} onSubmit={_onDisable}>
            <Box space="md">
              {isError && <ErrorMessage error={error} />}

              <Password
                name="password"
                label={t('password')}
                help={t('currentPasswordHelp')}
                required
              />
              <Input
                name="token"
                label={t('token')}
                help={t('tokenHelp')}
                required
              />

              <div className="flex justify-end gap-2">
                <Button
                  label={t('cancel')}
                  disabled={isLoading}
                  onClick={_onCancelDisable}
                />
                <Submit
                  variant="danger"
                  label={t(isLoading ? 'disabling' : 'disable')}
                  submitting={isLoading}
                  reverse
                />
              </div>
            </Box>
          </Form>
        </>
      ) : (
        <div className="flex flex-col gap-6 items-center">
          <Text className="text-center" text={t('2FADisableWarning')} />

          {cantDisable2FA && (
            <Message
              type="info"
              className="text-center"
              text={t('2FACantDisable')}
            />
          )}

          <Button
            label={t('2FADisable')}
            icon="lock-open"
            variant="danger"
            onClick={_onShowForm}
            disabled={cantDisable2FA}
          />
        </div>
      )}
    </div>
  )
}

Disable2FA.propTypes = {
  setFormVisible: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}
