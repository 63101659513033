import { useEditor } from '@craftjs/core'

import { get } from '@utils/objects'

import { defaultColors, useDesignColors } from './ColorSelect/hooks'
import { useBlockTextSizeClass } from './TextSizeSelect/hooks'

export function useEditorEnabled() {
  const { enabled } = useEditor(state => {
    return {
      enabled: state.options.enabled,
    }
  })

  return enabled
}

/**
 * A hook that returns the font weight class for a given font weight key
 * @param {string} fontWeightKey - The font weight key
 * @returns {string} - The font weight class
 */
export function useDesignColor(colorKey, fallbackColor = 'transparent') {
  const colors = useDesignColors()

  // If the color key is not defined, return the fallback color
  if (!colorKey) return fallbackColor

  // If the color key is a system color, just return it as it should be already a valid color.
  if (Object.keys(defaultColors.system).includes(colorKey)) {
    return colorKey
  }

  // If the color key is a custom color, return the color value
  return get(colors, colorKey.split('-'))
}

/**
 * A hook that returns the font weight class for a given font weight key
 * @param {string} fontWeightKey - The font weight key
 * @returns {string} - The font weight class
 */
export function useTextFieldWithModifiers(field = {}, modifiers = {}) {
  const syles = {}
  const classNames = []

  const color = useDesignColor(modifiers.color ? field?.color : null)
  const textSizeClass = useBlockTextSizeClass(field?.textSize)

  if (color) {
    syles.color = color
  }

  if (modifiers.textCase && field?.textCase) {
    classNames.push(field.textCase)
  }

  if (modifiers.textSize && field?.textSize) {
    classNames.push(textSizeClass)
  }

  return [field?.value, syles, classNames.join(' ')]
}
