import { useCallback } from 'react'

import { useQueryClient } from 'react-query'

import { setEntityHeaders } from '@utils/fetcher'

import { useEntityContext } from '../providers/context'

/**
 * Hook to switch to an entity.
 * @returns {object} An object with the current entity id and a function to switch to a new entity.
 */
export default function useSwitchToEntity() {
  const queryClient = useQueryClient()

  const { entityId, setEntityId } = useEntityContext()

  /**
   * Update the entity id in header and invalidate the current entity query.
   */
  const switchToEntity = useCallback(
    id => {
      // Set the new entity id in the headers (or clear it if null)
      setEntityHeaders(id)
      // Set the new entity id in the localStorage (or clear it if null)
      setEntityId(id)

      // Invalidate the current entity query
      queryClient.invalidateQueries(['entities', 'current'])
    },
    [queryClient, setEntityId]
  )

  return { entityId, switchToEntity }
}
