import { useState } from 'react'
import { useMutation } from 'react-query'

import { fileUpload } from '@utils/fetcher'

const baseUrl = '/api/images'

export function useImageUpload() {
  const [progress, setProgress] = useState()

  const { mutate, error, isLoading } = useMutation(async file =>
    fileUpload(`${baseUrl}/upload`, file, {
      onProgress: percentage => setProgress(percentage),
    })
  )

  function onImageUpload(file, onChange) {
    return mutate(file, {
      onSuccess: data => onChange(data),
    })
  }
  return { onImageUpload, error, isLoading, progress }
}

export function useImagesUpload() {
  const [progress, setProgress] = useState()

  const { mutate, error, isLoading } = useMutation(async files =>
    fileUpload(`${baseUrl}/upload-multiple`, files, {
      multiple: true,
      onProgress: percentage => setProgress(percentage),
    })
  )

  function onImagesUpload(files, onChange) {
    return mutate(files, {
      onSuccess: data => onChange(data),
    })
  }
  return { onImagesUpload, error, isLoading, progress }
}
