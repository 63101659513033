import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  Autocomplete,
  AutocompleteOption,
  AutocompleteSelectedItem,
} from '@ui/data-entry/Autocomplete'
import useDebounce from '@utils/useDebounce'

import useSite from '../../../services/hooks/useSite'
import useSites from '../../../services/hooks/useSites'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * This is controlled component used to select a site. It requires a list of sites to be passed as a prop (`sites`), along with a callback to be called when the user selects a site (`onChange`).
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.name - The name of the component.
 * @param {string} props.help - The help text to display below the component.
 * @param {function} props.onChange - The callback to be called when the user selects a site.
 * @param {function} props.onSearch - The callback to be called when the user types in the search field.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {string} props.searchTerm - The search term to display in the search field.
 * @param {string} props.value - The value of the component.
 * @param {object[]} props.sites - The list of sites to display in the list.
 * @returns {React.Component} - The component.
 */
export function SitePickerInput({
  className,
  disabled,
  includeIds,
  maxItems = 25,
  multiple,
  onChange,
  placeholder,
  required,
  showValueInline,
  value,
}) {
  const { t } = useTranslation('web/public')

  const [searchTerm, setSearchTerm] = React.useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const onSearch = React.useCallback(text => {
    setSearchTerm(text)
  }, [])

  const { sites, isLoading: loadingSites } = useSites({
    search: debouncedSearchTerm || '',
    fields: 'name',
    limit: maxItems,
    ids: includeIds,
  })

  const siteId = typeof value === 'object' ? value?.id : value

  const { site, loading: loadingSelectedSingle } = useSite(siteId, {})

  const siteIds = Array.isArray(value)
    ? value.map(v => (typeof v === 'object' ? v?.id : v))
    : undefined

  const { sites: selectedSites, loading: loadingSelectedMultiple } = useSites(
    {
      ids: siteIds,
    },
    {
      enabled: multiple,
      keepPreviousData: true,
    }
  )

  const loading = loadingSelectedSingle || loadingSelectedMultiple

  // Call the onChange callback when the value changes, and clear the search term
  const handleOnChange = React.useCallback(
    newValue => {
      // Check if there is a value
      const hasValue = multiple
        ? Array.isArray(newValue) && newValue.length > 0
        : Boolean(newValue)

      // Clear the search term if there is a value
      if (hasValue) {
        setSearchTerm('')
      }

      // Call the onChange callback, if it exists
      if (typeof onChange === 'function') {
        onChange(newValue)
      }
    },
    [onChange, multiple]
  )

  const getSiteLabel = React.useCallback(site => `${site.name} `, [])

  // Display multiple entities
  const displayMultiple = React.useCallback(
    (value, onItemRemove) => {
      if (!Array.isArray(value) || !Array.isArray(selectedSites)) return ''
      return selectedSites.map(selectedSite => {
        return (
          <AutocompleteSelectedItem
            key={selectedSite.id}
            label={getSiteLabel(selectedSite)}
            onRemove={() => onItemRemove(selectedSite.id)}
          />
        )
      })
    },
    [getSiteLabel, selectedSites]
  )

  return (
    <Autocomplete
      className={className}
      disabled={disabled}
      displayIcon={<Icon name="globe" />}
      displayMultiple={multiple ? displayMultiple : undefined}
      displayValue={
        multiple ? undefined : () => (site ? getSiteLabel(site) : searchTerm)
      }
      loading={loading || loadingSites}
      loadingText={t('searching')}
      multiple={multiple}
      onChange={handleOnChange}
      onSearch={onSearch}
      placeholder={placeholder || t('searchPlaceholder')}
      required={required}
      showValueInline={showValueInline}
      value={value}
    >
      {sites?.map(s => (
        <AutocompleteOption
          icon={<Icon name="globe" />}
          key={s.id}
          label={getSiteLabel(s)}
          value={s.id}
        />
      ))}
    </Autocomplete>
  )
}
SitePickerInput.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  includeIds: PropTypes.arrayOf(PropTypes.string),
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.array,
  ]),
}
