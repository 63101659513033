import PropTypes from 'prop-types'
import React from 'react'

import { reportError } from '@app/services/ClientService'
import Loading from '@ui/feedback/Loading'

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props)
    this.state = { hasError: false, reload: false }
  }

  // eslint-disable-next-line no-unused-vars
  static getDerivedStateFromError(error) {
    // If the error is a fail to fetch error, we don't want to show the error boundary fallback UI
    if (isFailToFetchError(error)) {
      return { hasError: true, reload: true }
    }

    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error, info) {
    if (isFailToFetchError(error)) {
      window.location.reload()
    } else {
      // Send the error to the server to be reported (email + telegram)
      reportError(error, info, error?.stack, window.location.href)
    }

    console.error(error, info) // eslint-disable-line no-console
  }

  render() {
    // Render custom fallback UI if there was an error and it's not a fail to fetch error
    if (this.state.hasError && !this.state.reload) {
      return this.props.fallback
    }

    if (this.state.reload) {
      console.log('Reloading...') // eslint-disable-line no-console
      window.location.reload() // An extra reload just in case the error wasn't caught
      return <Loading size="lg" />
    }

    return this.props?.children
  }
}
ErrorBoundary.propTypes = {
  children: PropTypes.node,
  fallback: PropTypes.node,
  reportCallback: PropTypes.func,
}

function isFailToFetchError(error) {
  return (
    error.name &&
    error.name === 'TypeError' &&
    error.message.startsWith('Failed to fetch')
  )
}
