import PropTypes from 'prop-types'
import React from 'react'

export default function CategoryWithAncestors({
  category,
  isCompact = false,
  variant,
}) {
  const { title, description, ancestors } = category || {}

  const reversedAncestors = React.useMemo(() => {
    if (!ancestors) return null
    return [...ancestors]?.reverse()
  }, [ancestors])

  if (!category) return null

  return (
    <div
      className={`flex flex-row ${isCompact ? 'gap-1' : 'gap-2'} items-center`}
    >
      <div
        className={`flex empty:hidden ${isCompact ? 'gap-1' : 'gap-2'} ${variant === 'primary' ? 'text-primary-100' : 'text-gray-500'} group-[.row-active]:text-primary-200`}
      >
        {reversedAncestors?.map(ancestor => (
          <span key={ancestor.id} className="flex gap-1 items-center">
            <span
              className={isCompact ? 'text-sm' : 'font-semibold'}
              title={ancestor.description}
            >
              {ancestor.title}
            </span>
            <span className="opacity-50">/</span>
          </span>
        ))}
      </div>
      <span
        title={description}
        className={isCompact ? 'truncate text-sm' : 'font-bold'}
      >
        {title}
      </span>
    </div>
  )
}
CategoryWithAncestors.propTypes = {
  isCompact: PropTypes.bool,
  variant: PropTypes.oneOf(['base', 'primary']),
  category: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    ancestors: PropTypes.array,
  }),
}
