import React from 'react'

import translatableBlocks from './components/ContentEditor/blocks/translatableBlocks'
import WebMenuGroup from './components/Menu'
import mailjetNewsletterTask from './tasks/mailjet-newsletter'

const WebPermissions = React.lazy(
  () => import('./components/Permissions/index')
)

const Router = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'web',
    menu: WebMenuGroup,
    routes: {
      private: {
        '/web/*': { Component: Router },
      },
    },
    // dashboard: {},
    permissions: {
      web: {
        name: 'web',
        label: 'web/public:webTitle',
        icon: 'globe',
        PermissionsComponent: WebPermissions,
        models: [
          {
            name: 'sites',
            // label: 'web/public:sites',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'pages',
            // label: 'web/public:pages',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'redirects',
            // label: 'web/public:redirects',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'menus',
            // label: 'web/public:menus',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'analytics',
            // label: 'web/public:analytics',
            // permissions: ['read'],
          },
          {
            name: 'pagePresets',
            // label: 'web/public:pagePresets',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'automatedSites',
            // label: 'web/public:automatedSites',
            // permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
    search: {
      indexes: {
        pages: {
          icon: 'file',
          label: 'web/pages:pages',
        },
      },
    },
    translatableBlocks,
    tasks: {
      'mailjet-newsletter': mailjetNewsletterTask,
    },
  }
}
