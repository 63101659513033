import PropTypes from 'prop-types'
import React from 'react'

import { LocaleContext } from './hooks'

export default function LocaleProvider({ children, dateTimeLocale, locale }) {
  return (
    <LocaleContext.Provider value={{ locale, dateTimeLocale }}>
      {children}
    </LocaleContext.Provider>
  )
}

LocaleProvider.propTypes = {
  children: PropTypes.node,
  dateTimeLocale: PropTypes.string,
  locale: PropTypes.string,
}
