import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useArticleArchive from '@modules/articles/services/hooks/useArticleArchive'
import GridColumnsSelect from '@modules/web/components/ContentEditor/blocks/Grid/components/ColumnSelect'
import { useGridColumnClass } from '@modules/web/components/ContentEditor/blocks/Grid/hooks'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useSiteId } from '@modules/web/services/hooks/useSite'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

const config = {
  name: 'ArticleArchive',
  label: 'articles/public:articleArchive',
  type: 'plugin',
  icon: 'inbox',
  component: <ArticleArchive />,
}

export default function ArticleArchive({ showMonths, columns }) {
  const { t } = useTranslation('articles/public')
  const siteId = useSiteId()

  const { data: archive } = useArticleArchive(siteId)
  const columnClass = useGridColumnClass(columns)

  const archiveByYear = useMemo(
    () =>
      archive?.reduce((acc, { year, month }) => {
        if (!acc.get(year)) {
          acc.set(year, [])
        }
        acc.set(year, [...acc.get(year), month].sort())
        return acc
      }, new Map()),
    [archive]
  )

  if (!archive) return null

  return (
    <Content icon={config.icon} title={t('articleArchive')}>
      {archiveByYear?.size > 0 ? (
        <ul className={`grid w-full gap-4 ${columnClass}`}>
          {Array.from(archiveByYear.entries()).map(([year, months]) => (
            <li key={year}>
              <div className="mb-2 font-bold">{year}</div>
              {showMonths && (
                <ul className="flex flex-wrap gap-2">
                  {months.map(month => (
                    <li key={month} className="border border-gray-100 p-2">
                      {month}
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p>{t('noArticles')}</p>
      )}
    </Content>
  )
}
ArticleArchive.propTypes = {
  showMonths: PropTypes.bool,
  columns: PropTypes.object,
}

ArticleArchive.toolbarItem = config

function ArticleArchiveSettings() {
  const { t } = useTranslation('articles/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return (
    <SettingsWrap title={t('articleArchive')} help={t('articleArchiveHelp')}>
      <div className="flex flex-col gap-4">
        <PagePicker
          label={t('yearPageId')}
          help={t('yearPageIdHelp')}
          value={data.yearPageId}
          onChange={pageId =>
            actions.setProp(props => (props.yearPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'year'
          }
        />
        <Checkbox
          name="showMonths"
          label={t('showMonths')}
          onChange={value =>
            actions.setProp(props => (props.showMonths = value))
          }
          value={data.showMonths}
        />
        {data.showMonths && (
          <PagePicker
            label={t('monthPageId')}
            help={t('monthPageIdHelp')}
            value={data.monthPageId}
            onChange={pageId =>
              actions.setProp(props => (props.monthPageId = pageId))
            }
            condition={page =>
              page.dynamic && getDynamicResourceIdentifier(page) === 'month'
            }
          />
        )}

        <GridColumnsSelect data={data} />
      </div>
    </SettingsWrap>
  )
}

ArticleArchive.craft = {
  displayName: 'ArticleArchive',
  props: {
    showMonths: true,
  },
  custom: {
    type: config.type,
    resources: ['articleArchive'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ArticleArchiveSettings,
  },
}
