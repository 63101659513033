import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useArticlesStats() {
  const { data, error, isLoading, isFetching } = useQuery(
    ['articles-stats'],
    () => getFetch('/api/articles/stats'),
    { enabled: true }
  )

  return {
    stats: data ?? {
      total: 0,
      drafts: 0,
      done: 0,
      approved: 0,
      published: 0,
    },
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
