import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export default function PublicationsListTranslation({ id, source }) {
  const { t } = useTranslation('publications/public')
  const fields = React.useMemo(
    () => ({
      title: { label: t('title') },
      label: { label: t('label') },
      allLabel: { label: t('allLabel') },
      religionFilterLabel: { label: t('religionFilterLabel') },
      feltNeedFilterLabel: { label: t('feltNeedFilterLabel') },
      doctrineFilterLabel: { label: t('doctrineFilterLabel') },
      languageFilterLabel: { label: t('languageFilterLabel') },
      filtersLabel: { label: t('filtersLabel') },
      noResultsLabel: { label: t('noResultsLabel') },
    }),
    [t]
  )
  return (
    <BlockTranslation
      id={id}
      fields={fields}
      source={source}
      i18Namespace="publications/public"
    />
  )
}
PublicationsListTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
