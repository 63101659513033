import { isFeatureEnabled } from '../helpers/isFeatureEnabled'
import { useFeatureFlagContext } from './useFeatureFlagContext'

function useFeatureFlagEnabled(key, { site, strategyOverride } = {}) {
  const context = useFeatureFlagContext()

  if (!context) {
    return false
  }

  return isFeatureEnabled(context?.[key], { site, strategyOverride })
}

export default useFeatureFlagEnabled
