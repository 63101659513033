import PropTypes from 'prop-types'
import React from 'react'

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

import { useUILanguage } from '@config/i18n'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function HtmlHeader({ title, description, ...props }) {
  const { t } = useTranslation('site')
  const { language } = useUILanguage()

  const { entity } = useCurrentEntity({ includeNetwork: true })
  const { network } = entity || {}
  const { backendName } = network || {}
  const backendTitle = backendName ? backendName : t('title')

  return (
    <Helmet {...props}>
      <html lang={language} />
      <title>{`${title ? `${title} | ` : ''}${backendTitle}`}</title>
      <meta name="description" content={description || t('description')} />
    </Helmet>
  )
}
HtmlHeader.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
