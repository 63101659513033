import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'

export function useSermonsPermissions() {
  const canReadSermons = useIsUserAuthorized({
    module: 'sermons',
    permission: 'read',
    noEntityCheck: true,
  })
  const canCreateSermons = useIsUserAuthorized({
    module: 'sermons',
    permission: 'create',
    noEntityCheck: true,
  })
  const canUpdateSermons = useIsUserAuthorized({
    module: 'sermons',
    permission: 'update',
    noEntityCheck: true,
  })
  const canDeleteSermons = useIsUserAuthorized({
    module: 'sermons',
    permission: 'delete',
    noEntityCheck: true,
  })

  return {
    canReadSermons,
    canCreateSermons,
    canUpdateSermons,
    canDeleteSermons,
  }
}
