import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import usePublicationReligions from '../../../services/hooks/usePublicationReligions'

export default function ReligionSelect() {
  const { t } = useTranslation('publications/public')

  const { religions } = usePublicationReligions()

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return (
    <Select
      label={t('religion')}
      help={t('religionHelp')}
      value={data.religion}
      onChange={e =>
        actions.setProp(props => (props.religion = e.target.value))
      }
    >
      <SelectPlaceholder label={t('selectPlaceholder')} />
      <SelectOption label="-" />
      {religions.map(({ name, title }) => (
        <SelectOption value={name} label={title.en} key={`religion-${name}`} />
      ))}
    </Select>
  )
}

ReligionSelect.propTypes = {
  singular: PropTypes.bool,
}
