import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useDocumentUpload } from '@modules/documents/services/DocumentServices'
import { useImageUpload } from '@modules/images/services/ImageServices'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import Button from '@ui/buttons/Button'
import Dropdown from '@ui/buttons/Dropdown'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import UIImage from '@ui/data-display/Image'
import SortableList from '@ui/data-display/SortableList'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { FileUploadField as FileUpload } from '@ui/data-entry/FileUpload'
import { ImageUploadField as ImageUpload } from '@ui/data-entry/ImageUpload'
import { InputField as Input } from '@ui/data-entry/Input'
import { TextAreaField as TextArea } from '@ui/data-entry/TextArea'
import Modal, { ModalProvider } from '@ui/feedback/Modal'
import Box from '@ui/layout/Box'

import AudioPlayerVariant from '../shared/AudioPlayerVariant'

const RichTextField = React.lazy(() => import('@ui/data-entry/RichText/Field'))

const config = {
  name: 'AudioPlaylist',
  label: 'media-library/public:audioPlaylist',
  type: 'plugin',
  icon: 'bars',
  component: <AudioPlaylist />,
}

export default function AudioPlaylist({
  items = [],
  image,
  title,
  showImage,
  showKicker,
  showTitle,
  showSubtitle,
  showDescription,
}) {
  return (
    <Content icon={config.icon} title={config.name}>
      <div className="max-w-xl space-y-2 p-2">
        {showImage && image && <UIImage alt={title} file={image} width="640" />}
        {showKicker && (
          <p className="text-sm font-bold uppercase">[Current track kicker]</p>
        )}
        {showTitle && Boolean(title) && (
          <h3 className="font-bold">[Current track title]</h3>
        )}
        {showSubtitle && Boolean(title) && <p>{title}</p>}
        <div className="aspect-w-16 aspect-h-9 w-full border bg-gray-100">
          <div className="flex h-full w-full items-center justify-center text-gray-500">
            Audio player
          </div>
        </div>
        {showDescription && <p>[Current track description]</p>}
        {items.length > 0 && (
          <ol className="divide-y">
            {items.map(({ file, title: itemTitle }, index) => (
              <li key={`${file}-${index}`} className="py-2">
                <span className="font-bold">{index + 1}.</span> {itemTitle}
              </li>
            ))}
          </ol>
        )}
      </div>
    </Content>
  )
}
AudioPlaylist.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      hoverIcon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      url: PropTypes.string,
      description: PropTypes.object,
    })
  ),
  showImage: PropTypes.bool,
  showTitle: PropTypes.bool,
  showKicker: PropTypes.bool,
  showSubtitle: PropTypes.bool,
  showDescription: PropTypes.bool,
}
AudioPlaylist.toolbarItem = config

function AudioPlaylistSettings() {
  const { t } = useTranslation('media-library/public')

  const [uploading, setUploading] = useState(false)
  const [uploadError, setUploadError] = useState(false)
  const [currentItem, setCurrentItem] = useState(null)
  const [modal, setModal] = useState(null)

  const { onAudioUpload } = useDocumentUpload()
  const { onImageUpload } = useImageUpload()

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onAddItem = useCallback(
    item => {
      actions.setProp(data => data.items.push(item))
      setCurrentItem(null)
    },
    [actions]
  )

  const onMoveItem = useCallback(
    ({ source, destination }) => {
      actions.setProp(data => {
        var element = data.items[source.index]
        data.items.splice(source.index, 1)
        data.items.splice(destination.index, 0, element)
      })
    },
    [actions]
  )

  const onItemUpdate = useCallback(
    index => item => {
      actions.setProp(data => (data.items[index] = item))
    },
    [actions]
  )

  const onRemoveItem = useCallback(
    index => {
      actions.setProp(data => data.items.splice(index, 1))
    },
    [actions]
  )

  const onUploadError = useCallback(() => {
    setUploading(false)
    setUploadError(true)
  }, [])

  const onFileUpload = useCallback(file => {
    // File uploaded to CDN contains containerId
    if (file?.containerId) {
      setCurrentItem(item => ({ ...item, file }))
      setUploading(false)
    }

    if (!file) {
      setCurrentItem(item => ({
        ...item,
        file: undefined,
        duration: undefined,
      }))
    }
  }, [])

  const onTextChange = useCallback(
    field => e => {
      actions.setProp(props => (props[field] = e.target.value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t('audioPlaylist')} help={t('audioPlaylistHelp')}>
      <Box space="xl">
        <ImageUpload
          value={data.image}
          className="justify-between"
          name="image"
          label={t('image')}
          onChange={file => actions.setProp(data => (data.image = file))}
          onUpload={onImageUpload}
          maxSize="10mb"
        />

        <Input
          name="title"
          value={data.title}
          label={t('title')}
          onChange={onTextChange('title')}
        />

        <AudioPlayerVariant />

        <Box space="lg">
          <h3 className="font font-semibold">{t('playlist')}</h3>
          <div className="w-full">
            {data.items.length > 0 ? (
              <SortableList
                name="playlist-items"
                items={data.items}
                renderItem={(item, i) => (
                  <SortableList.Item
                    actions={
                      <Dropdown
                        alignment="bottom-end"
                        button={
                          <Button icon="ellipsis-v" size="xs" variant="flat" />
                        }
                      >
                        <Dropdown.Item
                          label={t('edit')}
                          icon="edit"
                          onClick={() => {
                            setCurrentItem(item)
                            setModal({
                              title: t('editItem'),
                              acceptLabel: t('update'),
                              onAccept: onItemUpdate(i),
                            })
                          }}
                        />
                        <Dropdown.Item
                          label={t('delete')}
                          icon="trash-alt"
                          onClick={() => onRemoveItem(i)}
                          type="danger"
                        />
                      </Dropdown>
                    }
                    key={`item-${i}`}
                    title={item.title ?? item.file?.name}
                    subtitle={item.kicker}
                    imageSize="sm"
                  />
                )}
                onDragEnd={onMoveItem}
              />
            ) : (
              <p>{t('noItems')}</p>
            )}
          </div>
          <Button
            label={t('addItem')}
            onClick={() => {
              setCurrentItem({
                title: '',
                kicker: '',
                description: '',
              })
              setModal({
                title: t('addItem'),
                acceptLabel: t('add'),
                onAccept: onAddItem,
              })
            }}
            icon="plus"
            variant="primary"
            size="sm"
          />
        </Box>

        <Checkbox
          label={t('showPlaylistAbstract')}
          onChange={value => {
            actions.setProp(props => (props.showPlaylistAbstract = value))
          }}
          value={data.showPlaylistAbstract}
          name="showPlaylistAbstract"
        />

        <CollapsablePanel title={t('playerOptions')}>
          <Checkbox
            label={t('showImage')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
            name="showImage"
          />
          <Checkbox
            label={t('showTitle')}
            onChange={value => {
              actions.setProp(props => (props.showTitle = value))
            }}
            value={data.showTitle}
            name="showTitle"
          />
          <Checkbox
            label={t('showSubtitle')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showSubtitle}
            name="showSubtitle"
          />
          <Checkbox
            label={t('showKicker')}
            onChange={value => {
              actions.setProp(props => (props.showKicker = value))
            }}
            value={data.showKicker}
            name="showKicker"
          />
          <Checkbox
            label={t('showAbstract')}
            onChange={value => {
              actions.setProp(props => (props.showAbstract = value))
            }}
            value={data.showAbstract}
            name="showAbstract"
          />
          <Checkbox
            label={t('showDescription')}
            onChange={value => {
              actions.setProp(props => (props.showDescription = value))
            }}
            value={data.showDescription}
            name="showDescription"
          />
        </CollapsablePanel>
      </Box>

      {modal && (
        <ModalProvider initialOpen>
          <Modal
            canClose={false}
            title={modal.title}
            acceptLabel={modal.acceptLabel}
            acceptVariant={modal.variant}
            acceptDisabled={uploading}
            onAccept={() => {
              modal.onAccept(currentItem)
              setModal(null)
            }}
            onCancel={() => setModal(null)}
            size="xl"
          >
            <div className="space-y-4">
              <FileUpload
                value={currentItem.file}
                name="file"
                label={t('file')}
                error={uploadError ? { message: t('fileUploadError') } : null}
                uploading={uploading}
                onChange={onFileUpload}
                onUpload={(file, onChange) => {
                  setUploading(true)
                  setUploadError(false)
                  onAudioUpload(file, onChange, onUploadError)
                }}
                maxSize="100mb"
                accept={['mp3']}
                required
              />
              <Input
                name="title"
                value={currentItem.title}
                label={t('title')}
                onChange={e =>
                  setCurrentItem({ ...currentItem, title: e.target.value })
                }
                required
              />
              <Input
                name="kicker"
                value={currentItem.kicker}
                label={t('kicker')}
                onChange={e =>
                  setCurrentItem({ ...currentItem, kicker: e.target.value })
                }
              />
              <TextArea
                name="abstract"
                value={currentItem.abstract}
                label={t('abstract')}
                onChange={e =>
                  setCurrentItem({ ...currentItem, abstract: e.target.value })
                }
              />
              <RichTextField
                name="description"
                value={currentItem.description}
                label={t('description')}
                onChange={value =>
                  setCurrentItem({ ...currentItem, description: value })
                }
              />
            </div>
          </Modal>
        </ModalProvider>
      )}
    </SettingsWrap>
  )
}

AudioPlaylist.craft = {
  displayName: 'AudioPlaylist',
  props: {
    items: [],
    showImage: true,
    showKicker: true,
    showTitle: true,
    showSubtitle: true,
    showAbstract: true,
    showDescription: true,
    showPlaylistAbstract: true,
  },
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: AudioPlaylistSettings,
  },
}
