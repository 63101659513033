import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import {
  BlockWidthSelect as WidthSelect,
  useWidthClass,
} from '@modules/web/components/ContentEditor/shared/WidthSelect'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select } from '@ui/data-entry/Select'
import { TextAreaField as TextArea } from '@ui/data-entry/TextArea'
import Box from '@ui/layout/Box'

const config = {
  name: 'StandaloneDonation',
  label: 'payments/public:standaloneDonation',
  type: 'plugin',
  icon: 'donate',
  component: <StandaloneDonation />,
}

export default function StandaloneDonation({
  paymentMethod,
  project,
  successMessage,
  width,
}) {
  const { t } = useTranslation('payments/public')
  const widthClass = useWidthClass(width)

  const { entity } = useCurrentEntity()

  const selectedPaymentMethod = useMemo(
    () => entity?.paymentMethods.find(pm => pm._id === paymentMethod),
    [paymentMethod, entity]
  )

  return (
    <Content
      icon={config.icon}
      title={t('standaloneDonation')}
      className={`${widthClass}`}
    >
      <div className="flex flex-col">
        <span>
          <b>Payment Method:</b> {selectedPaymentMethod?.name}
        </span>
        <span>
          <b>Project: </b> {project}
        </span>
        <span>
          <b>Success Message: </b> {successMessage}
        </span>
      </div>
    </Content>
  )
}
StandaloneDonation.propTypes = {
  paymentMethod: PropTypes.string,
  project: PropTypes.string,
  successMessage: PropTypes.string,
  width: PropTypes.object,
}
StandaloneDonation.toolbarItem = config

function DonationStandaloneSettings() {
  const { t } = useTranslation('payments/public')
  const { entity } = useCurrentEntity()

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('standaloneDonation')}
      help={t('standaloneDonationHelp')}
    >
      <Select
        label={t('standaloneDonationPaymentMethods')}
        onChange={onFieldUpdate('paymentMethod')}
        value={data.paymentMethod}
      >
        <Select.Placeholder label="-" />
        {entity?.paymentMethods.map(pm => (
          <Select.Option
            label={`${t(pm.type)} - ${pm.name}`}
            key={pm._id}
            value={pm._id}
          />
        ))}
      </Select>
      <Box space="xl">
        <Input
          label={t('project')}
          onChange={onFieldUpdate('project')}
          placeholder={t('project')}
          value={data.project}
        />
        <Input
          label={t('initialAmount')}
          help={t('initialAmountHelp')}
          onChange={onFieldUpdate('initialAmount')}
          placeholder={1000}
          value={data.initialAmount}
          type="number"
        />
      </Box>
      <TextArea
        label={t('successMessage')}
        help={t('successMessageHelp')}
        onChange={onFieldUpdate('successMessage')}
        value={data.successMessage}
      />
      <WidthSelect />
    </SettingsWrap>
  )
}

StandaloneDonation.craft = {
  displayName: 'StandaloneDonation',
  props: {
    project: '',
    paymentMethod: '',
    successMessage: '',
  },
  custom: {
    type: config.type,
    resources: ['publicPaymentMethod'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: DonationStandaloneSettings,
  },
}
