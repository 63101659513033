import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useFeatureFlagEnabled from '@modules/feature-flags/hooks/useFeatureFlagEnabled'
import { useColumnsClass } from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import useValueAtBreakpoint from '@modules/web/components/ContentEditor/shared/useValueAtBreakpoint'
import useSite from '@modules/web/services/hooks/useSite'
import useSiteOrAncestorSite from '@modules/web/services/hooks/useSiteOrAncestorSite'
import Tag from '@ui/data-display/Tag'
import Pagination from '@ui/navigation/Pagination'

import useArticles from '../../../services/hooks/useArticles'
import ArticleCardItem from './ArticleCardItem'
import ArticleFeaturedItem from './ArticleFeaturedItem'
import ArticleItem from './ArticleItem'
import ArticleListItem from './ArticleListItem'
import ArticlePosterItem from './ArticlePosterItem'

const featuredItemDisplayModes = {
  'cards-with-list': ArticleCardItem,
  'featured-with-list': ArticleFeaturedItem,
}

const itemDisplayModes = {
  'cards': ArticleCardItem,
  'cards-with-list': ArticleItem,
  'featured': ArticleFeaturedItem,
  'featured-with-list': ArticleItem,
  'list': ArticleListItem,
  'posters': ArticlePosterItem,
}

export default function ArticlesPreview({
  articlesSiteId,
  categories,
  columns,
  direction,
  displayMode,
  featuredItemsCount,
  flag,
  flagExceptions,
  limit,
  organizations,
  pagination,
  showDate,
  showFlags,
  showDescription,
  showImage,
  showLink,
  linkLabel,
  skip,
  sortField,
  sortOrder,
}) {
  const { t } = useTranslation('articles/public')
  const { site: siteForFeature } = useSite()
  const isArticlesBySiteEnabled = useFeatureFlagEnabled('articles-by-site', {
    site: siteForFeature,
  })
  const { site } = useSiteOrAncestorSite(
    isArticlesBySiteEnabled && articlesSiteId ? articlesSiteId : undefined,
    { includeAncestors: isArticlesBySiteEnabled }
  )

  const directionAtBreakpoint = useValueAtBreakpoint(direction)
  const columnsAtBreakpoint = useValueAtBreakpoint(columns)

  const columnsClass = useColumnsClass(columnsAtBreakpoint?.value)
  // const featuredColumnsClass = useColumnsClass(featuredItemsCount)

  const { articles, count } = useArticles({
    categories,
    flag,
    flagExceptions,
    sort: sortField,
    order: sortOrder,
    limit,
    organizations,
    skip,
    siteId: site?.id,
    useSiteEntity: isArticlesBySiteEnabled && Boolean(articlesSiteId),
  })

  const previewDisplayMode = [
    'cards',
    'cards-with-list',
    'featured',
    'featured-with-list',
    'list',
    'posters',
  ].includes(displayMode)
    ? displayMode
    : 'list'

  const asList = ['list'].includes(previewDisplayMode)

  let featuredItems = null
  let items = articles || []

  if (['cards-with-list', 'featured-with-list'].includes(previewDisplayMode)) {
    featuredItems = items.slice(0, featuredItemsCount)
    items = items.slice(featuredItemsCount, items.length)
  }

  const siteFlag =
    flag && Array.isArray(site?.flags)
      ? site.flags.find(f => f.id === flag)
      : null

  return (
    <div className="flex flex-col gap-6">
      {siteFlag && (
        <div className="flex flex-row items-center gap-2">
          <p className="font-semibold">{t('flag')}:</p>
          <div className="flex gap-2">
            <Tag key={siteFlag.id} label={siteFlag.name} />
          </div>
        </div>
      )}
      <div
        className={`flex gap-6 ${
          directionAtBreakpoint.value === 'x'
            ? 'flex-row items-stretch [&>*]:w-1/2'
            : 'flex-col'
        }`}
      >
        {featuredItems && (
          <div className={`grid grid-cols-1 gap-4 ${'featuredColumnsClass'}`}>
            {featuredItems.map(article =>
              React.createElement(
                featuredItemDisplayModes[previewDisplayMode],
                {
                  article,
                  showDate,
                  showFlags,
                  showImage,
                  showDescription,
                  showLink,
                  linkLabel,
                  site,
                  key: article.id,
                }
              )
            )}
          </div>
        )}
        <div
          className={
            asList
              ? 'flex flex-col gap-6 p-2'
              : `grid grid-cols-1 gap-4 ${columnsClass}`
          }
        >
          {items.map(article =>
            React.createElement(itemDisplayModes[previewDisplayMode], {
              article,
              showDate,
              showFlags,
              showImage,
              showDescription,
              showLink,
              linkLabel,
              site,
              key: article.id,
            })
          )}
        </div>
      </div>
      {pagination && <Pagination page={1} pageSize={limit} total={count} />}
    </div>
  )
}
ArticlesPreview.propTypes = {
  articlesSiteId: PropTypes.string,
  categories: PropTypes.array,
  columns: PropTypes.object,
  direction: PropTypes.object,
  displayMode: PropTypes.string, // Can be provided by design, so can't set a definite value
  featuredItemsCount: PropTypes.number,
  flag: PropTypes.string,
  flagExceptions: PropTypes.arrayOf(PropTypes.string),
  limit: PropTypes.number,
  organizations: PropTypes.arrayOf(PropTypes.string),
  pagination: PropTypes.bool,
  showDate: PropTypes.bool,
  showFlags: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  site: PropTypes.object,
  skip: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
}
