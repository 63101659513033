import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useEventCategoriesByIds({
  ids = [],
  sort,
  includeDisabled = false,
  queryOptions = {},
} = {}) {
  const { data, ...rest } = useQuery(
    ['event-categories', sort, ...ids, includeDisabled],
    () =>
      getFetch('/api/event-categories', {
        filter: {
          enabled: !includeDisabled,
          _id: { $in: ids },
        },
        sort,
      }),
    { enabled: Array.isArray(ids), ...queryOptions }
  )
  return {
    categories: data?.items ?? [],
    count: data?.count ?? 0,
    ...rest,
  }
}
