import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { useGlobalParams } from '@utils/urls'

import useChannels from '../services/hooks/useChannels'
import useMediaLibraryPermissions from '../services/hooks/useMediaLibraryPermissions'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))
const MenuDivider = React.lazy(() => import('@ui/navigation/Menu/MenuDivider'))

function ChannelsMenu({ permissions }) {
  const { t } = useTranslation('media-library/public')
  const { channels, count } = useChannels()
  const { action, channelId } = useGlobalParams(
    '/media-library/channels/:channelId/:action/*'
  )

  const {
    canUpdateChannels,
    canReadShows,
    canReadCollections,
    canReadPersons,
    canReadBroadcasts,
  } = permissions

  if (!channels) return null

  return (
    <>
      {channels.map(channel => (
        <MenuItem
          active={channelId === channel.id && action === 'shows'}
          label={channel.title}
          href={
            canReadShows({ channelId: channel.id })
              ? `/media-library/channels/${channel.id}/shows`
              : ''
          }
          key={channel.id}
          icon="clapperboard-play"
        >
          {canUpdateChannels({ channelId: channel.id }) && (
            <MenuItem
              active={channelId === channel.id && action === 'livestream'}
              label={t('livestream')}
              href={`/media-library/channels/${channel.id}/livestream`}
              icon="router"
            />
          )}
          {canReadBroadcasts({ channelId: channel.id }) && (
            <MenuItem
              active={channelId === channel.id && action === 'schedule'}
              label={t('schedule')}
              href={`/media-library/channels/${channel.id}/schedule`}
              icon="calendar-alt"
            />
          )}
          {canReadCollections({ channelId: channel.id }) && (
            <MenuItem
              active={channelId === channel.id && action === 'collections'}
              label={t('collections')}
              href={`/media-library/channels/${channel.id}/collections`}
              icon="layer-group"
            />
          )}
          {canReadPersons({ channelId: channel.id }) && (
            <MenuItem
              active={channelId === channel.id && action === 'persons'}
              label={t('hostsAndGuests')}
              href={`/media-library/channels/${channel.id}/persons`}
              icon="user"
            />
          )}
          {canUpdateChannels({ channelId: channel.id }) && (
            <MenuItem
              active={channelId === channel.id && action === 'edit'}
              label={t('settings')}
              href={`/media-library/channels/${channel.id}/edit`}
              icon="cog"
            />
          )}
          {/* <MenuItem
            active={channelId === channel.id && action === 'dashboard'}
            label={t('overview')}
            href={`/media-library/channels/${channel.id}/dashboard`}
            icon="tv"
          />
          <MenuItem
            label={t('statistics')}
            href={`/media-library/channels/${channel.id}/schedule`}
            icon="chart-bar"
          /> */}
        </MenuItem>
      ))}
      {channels && count > channels?.length && (
        <MenuItem label={t('moreItems', { count: count - channels.length })} />
      )}
    </>
  )
}
ChannelsMenu.propTypes = {
  permissions: PropTypes.object,
}

export default function MediaMenuGroup() {
  const { t } = useTranslation('media-library/public')
  const location = useLocation()
  const { user } = useCurrentUser()
  const { action } = useGlobalParams(
    '/media-library/channels/:channelId/:action'
  )

  const inMediaLibrary = location.pathname.startsWith('/media-library')

  const mediaLibraryPermissions = useMediaLibraryPermissions()
  const { canReadChannels } = mediaLibraryPermissions

  if (!canReadChannels) return null

  return (
    <MenuGroup
      name="mediaLibrary"
      label={t('mediaLibrary')}
      icon="tv"
      active={inMediaLibrary}
    >
      <ChannelsMenu permissions={mediaLibraryPermissions} />
      <MenuDivider />
      <MenuItem
        active={
          ['/media-library/channels', '/media-library/channels/add'].includes(
            location.pathname
          ) || action === 'details'
        }
        label={t('manageChannels')}
        href="/media-library/channels"
        icon="stream"
      />
      {user.isAdmin && (
        <>
          <MenuItem
            active={
              ['/media-library/countries'].includes(location.pathname) ||
              action === 'details'
            }
            label={t('manageCountries')}
            href="/media-library/countries"
            icon="flag"
          />
        </>
      )}
    </MenuGroup>
  )
}
