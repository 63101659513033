import React from 'react'

import { Route, Routes } from 'react-router-dom'

import Profile from './Profile'
import Settings from './Settings'

export default function PrivateAuthRouter() {
  return (
    <Routes>
      <Route path="profile" element={<Profile />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  )
}
