import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { useDocumentUpload } from '@modules/documents/services/DocumentServices'
import Button from '@ui/buttons/Button'
import { ErrorMessage } from '@ui/data-display/Message'
import Section from '@ui/data-display/Section'
import CheckboxController from '@ui/data-entry/Checkbox'
import FileUpload from '@ui/data-entry/FileUpload'
import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import Select, { SelectOption, SelectPlaceholder } from '@ui/data-entry/Select'
import Submit from '@ui/data-entry/Submit'
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from '@ui/feedback/FloatingDialog'
import Loading from '@ui/feedback/Loading'
import Heading from '@ui/typography/Heading'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const fonts = ['body', 'heading', 'display', 'mono']

/**
 * Sorts variants first by weight and style (alphabetically).
 *
 * @param {object} a  - Variant a
 * @param {object} b  - Variant b
 * @param {number} a.weight - The weight of variant a
 * @param {string} a.style - The style of variant a
 * @param {number} b.weight - The weight of variant b
 * @param {string} b.style - The style of variant b
 * @returns {number} - 1 if a is greater than b, -1 if a is less than b, 0 if a is equal to b
 */
function sortVariants(a, b) {
  // if a or b doesn't exist, don't sort
  if (!a || !b) return 0

  // if the same weight, sort by style (alphabetically)
  if (a.weight === b.weight) {
    // Descending order as italic is after normal. Also if they can't be compared, it will be at the end (-1).
    return b.style?.localeCompare(a.style) || -1
  }

  // sort by weight (ascending)
  return a.weight - b.weight
}

/**
 * Component that displays a form to edit the fonts of a site.
 * The form allows the user to upload font files, and select the default variant of each font.
 * The form also allows the user to select a font as the same as another font, which will copy the settings of the other font.
 *
 * The site.design.fonts object structure:
 *  {
 *    [fontName]: {
 *      family: string,  // the name of the font family (Arial, Roboto, etc.)
 *      isVariable: boolean, // defines if the font is a variable font or not
 *      file: object, // main file for variable font (only if isVariable is true)
 *      fallbackFile: object, // fallback file for variable font (optional)
 *      variants:[ // array of variants for the font (only if isVariable is false)
 *        {
 *          id: string, // unique id for the variant
 *          file: object, // main file for the variant
 *          fallbackFile: object, // fallback file for the variant (optional)
 *          style: string, // the style of the variant (normal, italic)
 *          weight: string, // the weight of the variant (100, 200, 300, 400, 500, 600, 700, 800, 900)
 *        }
 *      ]
 *    }
 *  }
 *
 * @param {object} props
 * @param {object} props.site - The site to edit
 * @param {function} props.onSubmit - The function to call when the form is submitted
 * @returns {React.Component} - A component that displays a form to edit the fonts of a site
 */
export default function FontsForm({ site, onSubmit }) {
  const { t } = useTranslation('designs/base')

  const handleSubmit = useCallback(
    data => {
      // if onSubmit is not a function, don't submit!
      if (typeof onSubmit !== 'function') return

      // sort fonts variants by weight and style.
      data.design.fonts = Object.entries(data.design.fonts).reduce(
        (acc, [fontName, fontSettings]) => {
          const { variants, isVariable } = fontSettings

          // if the font is a variable font or doesn't have variants, don't sort
          if (isVariable || !variants || variants.length === 0) {
            acc[fontName] = fontSettings
            return acc
          }

          // sort variants by weight and style
          const sortedVariants = variants.sort(sortVariants)
          // set the updated variants to the font
          acc[fontName] = { ...fontSettings, variants: sortedVariants }
          return acc
        },
        {}
      )

      // submit the form data
      onSubmit(data)
    },
    [onSubmit]
  )

  return (
    <Form
      data={site}
      onSubmit={handleSubmit}
      id={`${site.id}-${site.updatedAt}`}
    >
      <div className="relative gap-8 flex flex-col">
        <div className="relative gap-12 flex flex-col max-w-full flex-1 lg:max-w-3xl xl:max-w-4xl 2xl:max-w-5xl">
          {fonts.map(font => (
            <Section title={t(`font_${font}`)} key={font}>
              <Section.Body>
                <FontVariants name={font} />
              </Section.Body>
            </Section>
          ))}
        </div>
        <div className="sticky bottom-0 z-30 -mx-10 -mb-10 px-10 py-4 bg-white/60 border-t backdrop-blur-lg">
          <Submit label={t('update')} icon="check" />
        </div>
      </div>
    </Form>
  )
}
FontsForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func,
}

/**
 * Component that display the variants of a font, and allow the user to upload new variants.
 * We also allow the user to select a variant as the default variant.
 * @param {object} props
 * @param {string} props.name - The name of the font (body, heading, display, mono)
 * @returns {React.Component} - A component that displays the variants of a font
 */
function FontVariants({ name }) {
  const { t } = useTranslation('designs/base')

  const { watch, setValue } = useFormContext()

  const {
    onDocumentUpload: onFontUpload,
    loading,
    error,
    uploading: fontUploading,
  } = useDocumentUpload({
    folder: 'fonts',
  })

  const sameAs = watch(`design[fonts][${name}][sameAs]`)
  const isVariable = watch(`design[fonts][${name}][isVariable]`)
  const variants = watch(`design[fonts][${name}][variants]`)
  const isFontSet = !!(
    watch(`design[fonts][${name}][file]`) || variants?.length > 0
  )

  // get all other fonts that are set with a file or variants with files
  const alreadySetFonts = fonts.filter(font => {
    // if the font is the same as the current font, return false
    if (font === name) return false

    // get settings of the font, and if it doesn't exist, return false
    const fontSettings = watch(`design[fonts][${font}]`)
    if (!fontSettings) return false

    // check if font is using other font settings, and if it is, return false
    const hasSameAs = watch(`design[fonts][${font}][sameAs]`)
    if (hasSameAs) return false

    // get font file and variants, and check if any of them has a file
    const file = watch(`design[fonts][${font}][file]`)
    const variants = watch(`design[fonts][${font}][variants]`)
    return file || variants?.some(variant => variant.file)
  })

  const onAddVariant = useCallback(() => {
    setValue(`design[fonts][${name}][variants]`, [...(variants || []), {}])
  }, [variants, setValue, name])

  const onRemoveVariant = useCallback(
    index => {
      const newVariants = [...variants]
      newVariants.splice(index, 1)
      setValue(`design[fonts][${name}][variants]`, newVariants)
    },
    [variants, setValue, name]
  )

  if (loading) return <Loading />
  if (error) return <ErrorMessage error={error} />

  return (
    <div className="flex flex-col gap-6 font-th">
      {alreadySetFonts.length > 0 && (
        <Select
          name={`design[fonts][${name}][sameAs]`}
          label={t('fontSameAs')}
          placeholder={t('fontSameAsPlaceholder')}
          help={t('fontSameAsHelp')}
        >
          {sameAs && <SelectOption value={null} label={t('clearSelection')} />}
          <SelectPlaceholder />
          {alreadySetFonts.map(font => (
            <SelectOption key={font} value={font} label={t(`font_${font}`)} />
          ))}
        </Select>
      )}

      {!sameAs && (
        <div className="flex flex-col gap-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 items-center">
            <Input
              name={`design[fonts][${name}][family]`}
              label={t('fontFamily')}
              placeholder={t('fontFamilyPlaceholder')}
              help={t('fontFamilyHelp')}
              required={isFontSet}
            />

            <CheckboxController
              name={`design[fonts][${name}][isVariable]`}
              label={t('isVariableFont')}
              help={t('isVariableFontHelp')}
            />
          </div>

          {!isVariable ? (
            <div className="flex flex-col gap-6">
              <div>
                <Heading text={t('fontVariants')} as="h5" />
                <p className="text-sm text-gray-400">
                  {t('fontVariantsDescription')}
                </p>
              </div>
              <div className="flex flex-col gap-4">
                {variants?.length > 0 ? (
                  variants.map((variant, index) => (
                    <FontVariantItem
                      key={`font-${name}-${variant.id}-${index}`}
                      index={index}
                      fontName={name}
                      onRemove={onRemoveVariant}
                      onFontUpload={onFontUpload}
                    />
                  ))
                ) : (
                  <p className="text-warn-400 bg-warn-50 flex items-center gap-2 justify-center rounded-lg border p-4 border-warn-200">
                    <Icon name="exclamation-triangle" size="lg" />
                    <span>{t('fontVariantsEmpty')}</span>
                  </p>
                )}
              </div>
              <div>
                <Button
                  type="button"
                  label={
                    variants?.length
                      ? t('fontAddAnotherVariant')
                      : t('fontAddVariant')
                  }
                  onClick={onAddVariant}
                  variant="success-light"
                  icon="plus"
                />
              </div>
            </div>
          ) : (
            <div className="grid grid-cols-1 gap-6 md:grid-cols-2 md:gap-12">
              <FileUpload
                label={t('uploadFont')}
                name={`design[fonts][${name}][file]`}
                maxSize="2mb"
                onUpload={(file, onChange) => {
                  onFontUpload(file, onChange)
                }}
                uploading={fontUploading}
                accept={['ttf', 'otf', 'woff', 'woff2']}
                disabledDownload={true}
              />
              <FileUpload
                label={t('uploadFallbackFont')}
                name={`design[fonts][${name}][fallbackFile]`}
                maxSize="2mb"
                onUpload={(file, onChange) => {
                  onFontUpload(file, onChange)
                }}
                uploading={fontUploading}
                disabledDownload={true}
                accept={['ttf', 'otf', 'woff', 'woff2']}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
FontVariants.propTypes = {
  name: PropTypes.string,
}

const fontWeights = [
  { value: '100', label: 'Thin' },
  { value: '200', label: 'Extra light' },
  { value: '300', label: 'Light' },
  { value: '400', label: 'Regular' },
  { value: '500', label: 'Medium' },
  { value: '600', label: 'Semibold' },
  { value: '700', label: 'Bold' },
  { value: '800', label: 'Extra bold' },
  { value: '900', label: 'Black' },
]

const fontStyles = [
  { value: 'normal', label: 'Normal' },
  { value: 'italic', label: 'Italic' },
]

function FontVariantItem({ index, fontName, onRemove, onFontUpload }) {
  const { t } = useTranslation('designs/base')
  const { watch } = useFormContext()

  const fontConfig = `design[fonts][${fontName}]`
  const family = watch(`${fontConfig}[family]`) || t(`font_${fontName}`)
  const variantsConfig = `${fontConfig}[variants]`
  const variants = watch(variantsConfig)
  const variantConfig = `${variantsConfig}[${index}]`
  const id = watch(`${variantConfig}[id]`)
  const file = watch(`${variantConfig}[file]`)
  const fallbackFile = watch(`${variantConfig}[fallbackFile]`)
  const style = watch(`${variantConfig}[style]`)
  const weight = watch(`${variantConfig}[weight]`)

  const styleLabel = fontStyles.find(s => s.value === style)?.label
  const weightLabel = fontWeights.find(w => w.value === weight)?.label

  // get filtered fontStyles and fontWeights based on other variants in the same font so there are no duplicates combinations of style and weight
  const availableFontStyles = useMemo(
    () =>
      fontStyles
        .filter(
          style =>
            !variants.some(
              variant =>
                variant.style === style.value && variant.weight === weight
            )
        )
        .map(style => style.value),
    [variants, weight]
  )

  const availableFontWeights = useMemo(
    () =>
      fontWeights
        .filter(
          weight =>
            !variants.some(
              variant =>
                variant.weight === weight.value && variant.style === style
            )
        )
        .map(weight => weight.value),
    [variants, style]
  )

  const variantName =
    styleLabel && weightLabel
      ? `${weightLabel} - ${styleLabel}`
      : t('fontVariant', { number: index + 1 })

  const itemClasses = file
    ? 'border-gray-300 bg-gray-50 hover:border-primary-500 hover:bg-primary-50'
    : 'border-warn-300 bg-warn-50 hover:border-warn-500 hover:bg-warn-50'

  return (
    <div
      className={`flex flex-col gap-4 rounded-lg px-4 py-2 border transition-all ease-in-out duration-100 ${itemClasses}`}
      key={id}
    >
      <Dialog>
        <div className="flex justify-between gap-4">
          <DialogTrigger asChild>
            <div className="flex flex-col justify-between gap-px cursor-pointer">
              <Heading text={variantName} as="h6" />
              {file ? (
                <p className="font-mono text-gray-600">
                  {file.originalFilename}
                  {file.extension}
                </p>
              ) : (
                <p className="flex gap-2 text-gray-400 text-sm">
                  <Icon
                    name="exclamation-triangle"
                    size="lg"
                    className="text-warn-500"
                  />
                  <span className="text-warn-600">{t('fontNotUploaded')}</span>
                </p>
              )}
            </div>
          </DialogTrigger>
          <div className="flex items-center gap-4">
            <DialogTrigger asChild>
              <Button label={t('edit')} variant="primary-light" icon="edit" />
            </DialogTrigger>

            <Button
              type="button"
              variant="danger-light"
              tooltip={t('remove')}
              icon="trash-alt"
              onClick={() => onRemove(index)}
            />
          </div>
        </div>
        <DialogContent
          title={t('fontVariantEditTitle', { name: variantName })}
          description={t('fontVariantEditDescription', { family })}
          showOkButton
        >
          <div className="flex flex-col justify-between gap-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <FileUpload
                label={t('uploadFont')}
                name={`design[fonts][${fontName}][variants][${index}][file]`}
                maxSize="2mb"
                onUpload={onFontUpload}
                accept={['ttf', 'otf', 'woff', 'woff2']}
                required
              />
              {file && (
                <FileUpload
                  label={t('uploadFallbackFont')}
                  name={`design[fonts][${fontName}][variants][${index}][fallbackFile]`}
                  maxSize="2mb"
                  onUpload={onFontUpload}
                  accept={['ttf', 'otf', 'woff', 'woff2']}
                  value={fallbackFile}
                />
              )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-6">
              <Select
                name={`design[fonts][${fontName}][variants][${index}][weight]`}
                label={t('fontWeight')}
                placeholder={t('fontWeightPlaceholder')}
                required
              >
                <SelectPlaceholder />
                {fontWeights.map(weight => (
                  <SelectOption
                    key={weight.value}
                    value={weight.value}
                    label={`${weight.label} (${weight.value})`}
                    disabled={!availableFontWeights.includes(weight.value)}
                  />
                ))}
              </Select>
              <Select
                name={`design[fonts][${fontName}][variants][${index}][style]`}
                label={t('fontStyle')}
                placeholder={t('fontStylePlaceholder')}
                required
              >
                <SelectPlaceholder />
                {fontStyles.map(style => (
                  <SelectOption
                    key={style.value}
                    value={style.value}
                    label={style.label}
                    disabled={!availableFontStyles.includes(style.value)}
                  />
                ))}
              </Select>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  )
}
FontVariantItem.propTypes = {
  index: PropTypes.number,
  fontName: PropTypes.string,
  onFontUpload: PropTypes.func,
  onRemove: PropTypes.func,
}
