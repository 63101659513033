import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useWebPermissions() {
  const { user } = useCurrentUser()
  const { entity } = useCurrentEntity()
  // Note that isUserAuthorized needs entity for the check against ancestors.

  const commonParams = {
    user,
    currentEntityId: entity?._id,
  }

  return {
    // Sites
    canReadSites: useIsUserAuthorized({
      module: 'sites',
      permission: 'read',
      delegateFunction: ({ module, permission }) => {
        if (module === 'sites' && permission === 'read') {
          return true
        }
      },
    }),
    canCreateSites: useIsUserAuthorized({
      module: 'sites',
      permission: 'create',
    }),

    canUpdateSites: ({ siteId }) => {
      return isUserAuthorized({
        module: 'sites',
        entity,
        permission: 'update',
        recordId: siteId,
        ...commonParams,
      })
    },
    canTranslateSites: ({ siteId }) => {
      return isUserAuthorized({
        module: 'sites',
        entity,
        permission: 'translate',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteSites: ({ siteId }) => {
      return isUserAuthorized({
        module: 'sites',
        entity,
        permission: 'delete',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCloneSites: useIsUserAuthorized({
      module: 'sites',
      permission: 'clone',
    }),
    canTransferSites: useIsUserAuthorized({
      module: 'sites',
      permission: 'transfer',
    }),

    // Pages
    canReadPages: ({ siteId }) => {
      return isUserAuthorized({
        module: 'pages',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreatePages: ({ siteId }) => {
      return isUserAuthorized({
        module: 'pages',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdatePages: ({ siteId }) => {
      return isUserAuthorized({
        module: 'pages',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeletePages: ({ siteId }) => {
      return isUserAuthorized({
        module: 'pages',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canTranslatePages: ({ siteId }) => {
      return isUserAuthorized({
        module: 'pages',
        entity,
        permission: 'translate',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Menus
    canReadMenus: ({ siteId }) => {
      return isUserAuthorized({
        module: 'menus',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateMenus: ({ siteId }) => {
      return isUserAuthorized({
        module: 'menus',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateMenus: ({ siteId }) => {
      return isUserAuthorized({
        module: 'menus',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteMenus: ({ siteId }) => {
      return isUserAuthorized({
        module: 'menus',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Layouts
    canReadLayouts: ({ siteId }) => {
      return isUserAuthorized({
        module: 'layouts',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateLayouts: ({ siteId }) => {
      return isUserAuthorized({
        module: 'layouts',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateLayouts: ({ siteId }) => {
      return isUserAuthorized({
        module: 'layouts',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteLayouts: ({ siteId }) => {
      return isUserAuthorized({
        module: 'layouts',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canTranslateLayouts: ({ siteId }) => {
      return isUserAuthorized({
        module: 'layouts',
        entity,
        permission: 'translate',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Page Presets
    canReadPresets: useIsUserAuthorized({
      module: 'pagePresets',
      permission: 'read',
      entityRequired: true,
      currentEntityId: entity?._id,
    }),
    canCreatePresets: useIsUserAuthorized({
      module: 'pagePresets',
      permission: 'create',
      entityRequired: true,
      currentEntityId: entity?._id,
    }),
    canUpdatePresets: useIsUserAuthorized({
      module: 'pagePresets',
      permission: 'update',
      entityRequired: true,
      currentEntityId: entity?._id,
    }),
    canDeletePresets: useIsUserAuthorized({
      module: 'pagePresets',
      permission: 'delete',
      entityRequired: true,
      currentEntityId: entity?._id,
    }),

    // Redirects
    canReadRedirects: ({ siteId }) => {
      return isUserAuthorized({
        module: 'redirects',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateRedirects: ({ siteId }) => {
      return isUserAuthorized({
        module: 'redirects',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateRedirects: ({ siteId }) => {
      return isUserAuthorized({
        module: 'redirects',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteRedirects: ({ siteId }) => {
      return isUserAuthorized({
        module: 'redirects',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Analytics
    canReadAnalytics: ({ siteId }) => {
      return isUserAuthorized({
        module: 'analytics',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Automated Sites
    canReadAutomatedSites: useIsUserAuthorized({
      module: 'automatedSites',
      permission: 'read',
    }),
    canCreateAutomatedSites: useIsUserAuthorized({
      module: 'automatedSites',
      permission: 'create',
    }),
    canUpdateAutomatedSites: useIsUserAuthorized({
      module: 'automatedSites',
      permission: 'update',
    }),
    canDeleteAutomatedSites: useIsUserAuthorized({
      module: 'automatedSites',
      permission: 'delete',
    }),

    // Reports
    canReadReports: useIsUserAuthorized({
      module: 'reports',
      permission: 'currentEntitySites',
    }),
    canReadGlobalReports: useIsUserAuthorized({
      module: 'reports',
      permission: 'globalSites',
    }),
    canExportReports: useIsUserAuthorized({
      module: 'reports',
      permission: 'export',
    }),

    // Authentication
    canReadAuth: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteAuth',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateAuth: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteAuth',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateAuth: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteAuth',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteAuth: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteAuth',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Search
    canReadSearch: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteSearch',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateSearch: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteSearch',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateSearch: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteSearch',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteSearch: ({ siteId }) => {
      return isUserAuthorized({
        module: 'siteSearch',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },

    // Data sources
    canReadDataSources: ({ siteId }) => {
      return isUserAuthorized({
        module: 'dataSources',
        entity,
        permission: 'read',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canCreateDataSources: ({ siteId }) => {
      return isUserAuthorized({
        module: 'dataSources',
        entity,
        permission: 'create',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canUpdateDataSources: ({ siteId }) => {
      return isUserAuthorized({
        module: 'dataSources',
        entity,
        permission: 'update',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
    canDeleteDataSources: ({ siteId }) => {
      return isUserAuthorized({
        module: 'dataSources',
        entity,
        permission: 'delete',
        recordModule: 'sites',
        recordId: siteId,
        ...commonParams,
      })
    },
  }
}
