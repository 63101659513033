import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { BlockColorSelect as ColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'

const config = {
  name: 'ChurchSermons',
  label: 'entities/public:ChurchSermons',
  type: 'plugin',
  icon: 'person-chalkboard',
  component: <ChurchSermons />,
}

export default function ChurchSermons({ dynamic }) {
  const { t } = useTranslation('entities/public')

  return (
    <Content
      className="w-full"
      icon={config.icon}
      locked={dynamic}
      title={t('ChurchSermons')}
    >
      [Sermons placeholder]
    </Content>
  )
}
ChurchSermons.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}

ChurchSermons.toolbarItem = config

function ChurchSermonsSettings() {
  const { t } = useTranslation(['entities/public', 'web/content-editor'])

  // const { actions, data } = useNode(node => {
  //   return {
  //     data: node.data.props,
  //   }
  // })

  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.id = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('ChurchSermons')} help={t('ChurchSermonsHelp')}>
      <div className="space-y-4">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.id}
            types={['church']}
          />
        )} */}
        <ColorSelect
          name="backgroundColor"
          label={t('web/content-editor:backgroundColor')}
        />
      </div>
    </SettingsWrap>
  )
}

ChurchSermons.craft = {
  displayName: 'ChurchSermons',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity', 'sermons'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchSermonsSettings,
  },
}
