import { format, isToday } from 'date-fns'
import PropTypes from 'prop-types'
import React from 'react'

export function Day({ date, onClick, className, selected, disabled }) {
  return (
    <button
      disabled={disabled}
      className={`flex items-center justify-center rounded border py-1 outline-none ring-primary-400 focus:ring-1 ${
        selected
          ? 'border-transparent bg-primary-500 font-semibold text-primary-100'
          : isToday(date)
          ? 'border-primary-300 bg-primary-200 text-primary-800'
          : 'border-transparent bg-gray-100 text-gray-800'
      } ${disabled ? 'cursor-not-allowed opacity-50' : ''} ${className}`}
      onClick={e => {
        e.preventDefault()
        onClick(date)
      }}
    >
      {date ? format(date, 'd') : ''}
    </button>
  )
}
Day.propTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.number, PropTypes.object]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
Day.defaultProps = {
  className: '',
}
