import PublicationDetailTranslation from './Detail/Translation'
import { PublicationDownloadsTranslation } from './Downloads'
import PublicationsListTranslation from './List/Translation'
import { PublishingHousesTranslation } from './PublishingHouses'
import { ReligionsMenuTranslation } from './ReligionsMenu'
import { PublicationsSearchTranslation } from './Search'
import { PublicationStatisticsTranslation } from './Statistics'

export default {
  PublicationDetail: PublicationDetailTranslation,
  PublicationsList: PublicationsListTranslation,
  PublicationsSearch: PublicationsSearchTranslation,
  PublicationsReligionsMenu: ReligionsMenuTranslation,
  PublicationStatistics: PublicationStatisticsTranslation,
  PublishingHouses: PublishingHousesTranslation,
  PublicationDownloads: PublicationDownloadsTranslation,
}
