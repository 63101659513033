import { useBorderRadiusStyles } from '@modules/web/components/ContentEditor/shared/BorderRadius'
import { useBorderStyleClasses } from '@modules/web/components/ContentEditor/shared/BorderStyle'
import { useBorderWidthStyles } from '@modules/web/components/ContentEditor/shared/BorderWidthSelect'
import { useDesignColor } from '@modules/web/components/ContentEditor/shared/hooks'

/**
 * Get button border styles from variant settings
 * @param {object} borderSettings - variant border settings
 * @returns {object} - border styles object (with className: string, style: object)
 */
export function useBorderStyles(
  borderSettings,
  { topRadiusOnly = false } = {}
) {
  const { color, style, radius, width } = borderSettings || {}

  const borderColor = useDesignColor(color, '')
  const borderStyle = useBorderStyleClasses(style)
  const borderWidth = useBorderWidthStyles(width)
  const borderRadius = useBorderRadiusStyles(
    topRadiusOnly ? { ...radius, br: 0, bl: 0 } : radius
  )

  if (!borderSettings) return {}

  const classNames = []
  const styles = {}

  if (borderWidth.className) {
    classNames.push(borderWidth.className)
  } else if (borderColor || borderStyle.className) {
    classNames.push('border')
  }

  if (borderColor) {
    styles.borderColor = borderColor
  }

  if (borderStyle) {
    classNames.push(borderStyle.className)
  }

  if (borderRadius) {
    classNames.push(borderRadius.className)
  }

  return {
    className: classNames.join(' '),
    style: styles,
    borderColor,
    borderRadius,
    borderWidth,
    borderStyle,
  }
}
