import PropTypes from 'prop-types'
import React from 'react'

import Loading from '@ui/feedback/Loading'

import { useFeatureFlagKeys } from '../services/useFeatureFlagKeys'

export const FeatureFlagContext = React.createContext()

export function FeatureFlagProvider({ children }) {
  const { data: featureFlags, isLoading } = useFeatureFlagKeys()

  if (isLoading) {
    return <Loading />
  }

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  )
}

FeatureFlagProvider.propTypes = {
  children: PropTypes.node,
}

export default FeatureFlagProvider
