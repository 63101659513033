import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { useRules } from '@ui/data-entry/validationHooks'

import { CategoryPickerField } from './Field'

/**
 * This is is the CategoryPicker controller component, to use within a Form.
 * It wraps the CategoryPickerField component in a Controller (with validation, etc.).
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the field is disabled or not.
 * @param {boolean} props.enableCategoryPrioritisation - Whether to enable category prioritisation.
 * @param {string} props.help - The help text to display below the component.
 * @param {string} props.label - The label to display above the component.
 * @param {string} props.maxItems - The maximum number of items to display in the list.
 * @param {string} props.multiple - Whether the field is multiple or not.
 * @param {string} props.name - The name of the component.
 * @param {string} props.placeholder -
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.shouldUnregister - Whether the field should unregister or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @returns {React.Component} - The component.
 */
export function CategoryPickerController({
  className,
  disabled,
  enableCategoryPrioritisation,
  help,
  label,
  maxItems,
  multiple,
  name,
  placeholder,
  required,
  shouldUnregister,
  showValueInline,
}) {
  const rules = useRules({ required })
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      disabled={disabled}
      render={({ field }) => (
        <CategoryPickerField
          className={className}
          disabled={disabled}
          enableCategoryPrioritisation={enableCategoryPrioritisation}
          help={help}
          label={label}
          maxItems={maxItems}
          multiple={multiple}
          name={name}
          onChange={field.onChange}
          placeholder={placeholder}
          required={required}
          showValueInline={showValueInline}
          value={field.value}
        />
      )}
    />
  )
}
CategoryPickerController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  enableCategoryPrioritisation: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  showValueInline: PropTypes.bool,
}
