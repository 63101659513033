import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Input from '@ui/data-entry/Input'
import TextArea from '@ui/data-entry/TextArea'
import TranslationField from '@ui/data-entry/Translation/Field'
import Box from '@ui/layout/Box'

export default function ArticlesFeedFiltersTranslation({
  site,
  source,
  translation,
}) {
  const { t } = useTranslation('articles/public')

  return (
    <Box space="xl">
      <TranslationField
        name="xml[settings][title]"
        label={t('title')}
        sourceText={source?.xml?.settings?.title}
        missing={!translation?.xml?.settings?.title}
      >
        <Input
          name="xml[settings][title]"
          label={t('title')}
          placeholder={site.rss?.title}
        />
      </TranslationField>
      <TranslationField
        name="xml[settings][description]"
        label={t('description')}
        sourceText={source?.xml?.settings?.description}
        missing={!translation?.xml?.settings?.description}
      >
        <TextArea
          name="xml[settings][description]"
          label={t('description')}
          placeholder={site.rss?.description}
        />
      </TranslationField>
      <TranslationField
        name="xml[settings][copyright]"
        label={t('copyright')}
        sourceText={source?.xml?.settings?.copyright}
        missing={!translation?.xml?.settings?.copyright}
      >
        <Input
          name="xml[settings][copyright]"
          label={t('copyright')}
          placeholder={site.rss?.copyright}
        />
      </TranslationField>

      <TranslationField
        name="xml[settings][author]"
        label={t('author')}
        sourceText={source?.xml?.settings?.author}
        missing={!translation?.xml?.settings?.author}
      >
        <Input
          name="xml[settings][author]"
          label={t('author')}
          placeholder={site.rss?.author}
        />
      </TranslationField>

      <TranslationField
        name="xml[settings][authorEmail]"
        label={t('authorEmail')}
        sourceText={source?.xml?.settings?.authorEmail}
        missing={!translation?.xml?.settings?.authorEmail}
      >
        <Input
          name="xml[settings][authorEmail]"
          label={t('authorEmail')}
          placeholder={site.rss?.authorEmail}
        />
      </TranslationField>
    </Box>
  )
}
ArticlesFeedFiltersTranslation.propTypes = {
  site: PropTypes.object.isRequired,
  source: PropTypes.object.isRequired,
  translation: PropTypes.object,
}
