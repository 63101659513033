import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import Section from '@ui/data-display/Section'
import Checkbox from '@ui/data-entry/Checkbox'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'

export default function HopeHeaderForm({ site, onSubmit }) {
  const design = useDesignContext()
  const [, setEnabled] = React.useState(design?.header?.hideHeader)
  const { t } = useTranslation(['designs/hope', 'web/appearance'])
  const { user } = useCurrentUser()
  const { onImageUpload } = useImageUpload()

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <ImageUpload
            name="design[header][logo]"
            label={t('web/appearance:headerLogo')}
            help={t('web/appearance:headerLogoHelp')}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
            required
          />
          <ImageUpload
            name="design[header][logoDarkMode]"
            label={t('web/appearance:headerLogoDarkMode')}
            help={t('web/appearance:headerLogoDarkModeHelp')}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
          />
          <Input
            name="design[header][title]"
            label={t('headerTitle')}
            help={t('headerTitleHelp')}
          />
          <MenuPicker
            name="design[header][mainMenu]"
            label={t('web/appearance:mainMenu')}
            help={t('web/appearance:mainMenuHelp')}
            required
          />
          <MenuPicker
            name="design[header][topMenu]"
            label={t('topMenu')}
            help={t('topMenuHelp')}
          />
          <Input
            name="design[header][topMenuTitle]"
            label={t('topMenuTitle')}
            help={t('topMenuTitleHelp')}
          />
          <Box horizontal space="xl">
            <Input
              name="design[header][ctaLabel]"
              label={t('ctaLabel')}
              help={t('ctaLabelHelp')}
              className="w-full md:w-1/2"
            />
            <Input
              name="design[header][ctaUrl]"
              label={t('ctaUrl')}
              help={t('ctaUrlHelp')}
              className="w-full md:w-1/2"
            />
          </Box>
          <Box horizontal space="xl">
            <Input
              name="design[header][mapUrl]"
              label={t('mapUrl')}
              help={t('mapUrlHelp')}
              className="w-full md:w-1/2"
            />
            <Input
              name="design[header][searchUrl]"
              label={t('web/appearance:searchUrl')}
              help={t('web/appearance:searchUrlHelp')}
              className="w-full md:w-1/2"
            />
          </Box>
          <Checkbox
            name="design[header][hideHeader]"
            label={t('web/appearance:hideHeader')}
            help={t('web/appearance:hideHeaderHelp')}
            disabled={!user?.isAdmin}
            onChange={value => setEnabled(value)}
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
HopeHeaderForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
