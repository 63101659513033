import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import ColorPreview from '@ui/data-display/ColorPreview'

import Listbox from '../Listbox'

const customGroupColors = [
  'color1',
  'color2',
  'color3',
  'color4',
  'color5',
  'color6',
  'gray',
]

export default function ColorSelectOptions({
  showClear,
  clearLabel,
  options = [],
  valueAsObject = false,
}) {
  const { t } = useTranslation()

  return (
    <Listbox.Options className="flex flex-col gap-3">
      {Boolean(showClear) && (
        <>
          <Listbox.Option
            as="li"
            value={null}
            className="flex items-center gap-4"
          >
            <ColorPreview hideLabel size="sm" />
            {clearLabel}
          </Listbox.Option>
          <Listbox.Divider />
        </>
      )}
      {options.map(group => {
        const isCustom = customGroupColors.includes(group.value)

        return (
          <li key={group.value} className="flex flex-col gap-2">
            <span className="font-semibold">
              {isCustom
                ? t(group.label)
                : group.label || t(`color_${group.label}`)}
            </span>

            <ul className="flex flex-wrap -mx-1">
              {group.colors?.map(option => (
                <Listbox.Option
                  as="li"
                  value={valueAsObject ? option : option.value}
                  disabled={option.disabled}
                  key={option.value}
                >
                  <span
                    className="flex items-center gap-2 -mx-2 -my-1"
                    title={option.label}
                  >
                    <ColorPreview
                      color={isCustom ? option.color : t(option.color)}
                      hideLabel
                    />
                  </span>
                </Listbox.Option>
              ))}
            </ul>
          </li>
        )
      })}
    </Listbox.Options>
  )
}
ColorSelectOptions.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  clearLabel: PropTypes.string,
  showClear: PropTypes.bool,
  valueAsObject: PropTypes.bool,
}
