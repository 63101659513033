import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import ColumnsSelect from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { CheckboxField } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'

import ArticlesPreview from './shared/ArticlesPreview'
import CategoriesFields from './shared/CategoriesFields'
import FieldsToggleFields from './shared/FieldsToggleFields'
import FlagsFields from './shared/FlagsFields'

const config = {
  name: 'LatestArticles',
  label: 'articles/public:latestArticles',
  type: 'plugin',
  icon: 'bars',
  component: <LatestArticles />,
}

export default function LatestArticles({
  categories,
  columns,
  displayMode,
  flag,
  limit,
  listPageId,
  organizations,
  showDate,
  showDescription,
  showImage,
  title,
}) {
  const { t } = useTranslation('articles/public')

  return (
    <Content icon={config.icon} title={t('latestArticles')}>
      <div className="flex flex-col gap-2">
        <div className="flex flex-row items-baseline justify-between">
          {title ? (
            <h3 className="p-1 text-lg font-semibold">{title}</h3>
          ) : (
            <div />
          )}
          {listPageId && (
            <a href="#readmore" className="text-sm text-primary-500">
              {t('readMore')}
            </a>
          )}
        </div>
        <ArticlesPreview
          categories={categories}
          displayMode={displayMode}
          limit={limit}
          flag={flag}
          organizations={organizations}
          showDate={showDate}
          showDescription={showDescription}
          showImage={showImage}
          columns={columns}
          sortField="publishedAt"
          sortOrder="desc"
        />
      </div>
    </Content>
  )
}
LatestArticles.propTypes = {
  categories: PropTypes.array,
  columns: PropTypes.object,
  displayMode: PropTypes.oneOf(['cards', 'list']),
  flag: PropTypes.string,
  limit: PropTypes.number,
  listPageId: PropTypes.string,
  organizations: PropTypes.arrayOf(PropTypes.string),
  showDate: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  title: PropTypes.string,
}
LatestArticles.toolbarItem = config

function LatestArticlesSettings() {
  const { t } = useTranslation('articles/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t('latestArticles')} help={t('latestArticlesHelp')}>
      <div className="flex flex-col gap-4">
        <Input
          label={t('title')}
          help={t('titleHelp')}
          onChange={onFieldUpdate('title')}
          value={data.title}
        />
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.limit}
          type="number"
        />

        <CategoriesFields />
        <FlagsFields />

        {data.displayMode === 'cards' && <ColumnsSelect />}

        <FieldsToggleFields showDescription showLink showDate />
        <CheckboxField
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
        <LinkingFields
          detailPagehelp={t('detailPageIdHelp')}
          resourceIdentifier="Article"
        />
      </div>
    </SettingsWrap>
  )
}

LatestArticles.craft = {
  displayName: 'LatestArticles',
  props: {
    displayMode: 'cards', // 'cards' or 'list'
    flag: null,
    limit: 5,
    detailPageId: null,
    showDate: true,
    showDescription: true,
    showImage: true,
  },
  custom: {
    type: config.type,
    resources: ['articles'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: LatestArticlesSettings,
  },
}

/**
 * Hook to get the fields of the Latest Articles block
 * @returns
 */
function useLatestArticlesBlockFields() {
  const { t } = useTranslation(['articles/public'])

  return useMemo(
    () => ({
      title: { label: t('title') },
    }),
    [t]
  )
}

/**
 * Component to render the fields of a Latest Articles block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function LatestArticlesTranslation({ id, source }) {
  const fields = useLatestArticlesBlockFields()
  return <BlockTranslation id={id} fields={fields} source={source} />
}
LatestArticlesTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
