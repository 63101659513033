import { useTranslation } from 'react-i18next'

// Letter spacing options (tracking)
export const letterSpacings = [
  'tighter', // tracking-tighter
  'tight', // tracking-tight
  'normal', // tracking-normal
  'wide', // tracking-wide
  'wider', // tracking-wider
  'widest', // tracking-widest
]

export function useLetterSpacingOptions() {
  const { t } = useTranslation('web/content-editor')

  return letterSpacings.map(value => ({
    label: t(`letterSpacing_${value}`),
    value,
    key: `letterSpacing_${value}`,
  }))
}

export function useLetterSpacingClass(letterSpacing = 'normal') {
  return `tracking-${
    letterSpacings.includes(letterSpacing) ? letterSpacing : 'normal'
  }`
}
