import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { useRules } from '@ui/data-entry/validationHooks'

import EntityPickerField from './Field'

/**
 * This is is the EntityPicker controller component, to use within a Form.
 * It wraps the EntityPickerField component in a Controller (with validation, etc.).
 * @param {object} props - The component props.
 * @param {string} props.className - The class name to apply to the component.
 * @param {boolean} props.disabled - Whether the field is disabled or not.
 * @param {string} props.help - The help text to display below the component.
 * @param {string} props.label - The label to display above the component.
 * @param {number} props.maxItems - The maximum number of items to display in the list.
 * @param {boolean} props.multiple - Whether the field is multiple or not.
 * @param {string} props.name - The name of the component.
 * @param {string} props.placeholder - The placeholder to display in the input.
 * @param {boolean} props.publicMode - When true, it will retutn all available entities, otherwise only the allowed for the current user.
 * @param {boolean} props.required - Whether the field is required or not.
 * @param {boolean} props.shouldUnregister - Whether the field should unregister or not.
 * @param {boolean} props.showValueInline - Whether to show the value tag/s inline or under the field.
 * @param {string[]} props.types - The types of entities to display in the list.
 * @returns {React.Component} - The component.
 */
export default function EntityPickerController({
  className,
  disabled,
  excludedIds,
  help,
  label,
  maxItems,
  multiple,
  name,
  placeholder,
  publicMode,
  required,
  shouldUnregister,
  showValueInline,
  types,
}) {
  const rules = useRules({ required })
  return (
    <Controller
      name={name}
      shouldUnregister={shouldUnregister}
      rules={rules}
      disabled={disabled}
      render={({ field }) => (
        <EntityPickerField
          className={className}
          disabled={disabled}
          label={label}
          help={help}
          maxItems={maxItems}
          multiple={multiple}
          name={name}
          value={field.value}
          onChange={field.onChange}
          placeholder={placeholder}
          required={required}
          showValueInline={showValueInline}
          types={types}
          excludedIds={excludedIds}
          publicMode={publicMode}
        />
      )}
    />
  )
}
EntityPickerController.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  excludedIds: PropTypes.array,
  help: PropTypes.string,
  label: PropTypes.string,
  maxItems: PropTypes.number,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  publicMode: PropTypes.bool,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  showValueInline: PropTypes.bool,
  types: PropTypes.array,
}
