import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

export default function useEvents({
  search = '',
  statuses = ['active', 'disabled'],
  fetchOnSearch = false,
  filters = {},
  limit = 10,
  page = 1,
  pagination = false,
  sort = '',
  skip = 0,
}) {
  const queryFilter = {}

  // search
  if (search) {
    queryFilter.title = {
      $regex: escapeSpecialChars(search),
      $options: 'i',
    }
  }

  // status
  if (!isEmpty(statuses)) {
    const statusFilter = []
    if (statuses.includes('active')) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      statusFilter.push({ deleted: true })
    }
    queryFilter['$or'] = statusFilter
  } else {
    queryFilter.deleted = false
  }

  // organizers
  if (!isEmpty(filters['organizers'])) {
    queryFilter.organizer = { $in: Object.keys(filters['organizers']) }
  }

  // regions
  if (!isEmpty(filters['regions'])) {
    queryFilter.regions = { $in: Object.keys(filters['regions']) }
  }

  // categories
  if (!isEmpty(filters['categories'])) {
    queryFilter.category = { $in: Object.keys(filters['categories']) }
  }

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'events',
      {
        search,
        statuses,
        filters,
        limit,
        sort,
        skip,
        pagination,
        page,
      },
    ],
    () =>
      getFetch('/api/events', {
        filter: queryFilter,
        sort,
        limit,
        skip: pagination ? null : skip,
        page,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    events: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
