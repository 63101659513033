import React from 'react'

const LogsRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    routes: {
      private: {
        '/logs/*': { Component: LogsRouter },
      },
    },
  }
}
