import React from 'react'

import { Route, Routes } from 'react-router-dom'

import ForgotPassword from './ForgotPassword'
import Login from './Login'
import ResetPassword from './ResetPassword'

export default function PublicAuthRouter() {
  return (
    <Routes>
      <Route path="forgot-password" element={<ForgotPassword />} />
      <Route path="reset-password/:token" element={<ResetPassword />} />
      <Route path="*" element={<Login />} />
    </Routes>
  )
}
