import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function HeaderSubmit({
  controls,
  extra,
  language,
  sourceLanguage,
}) {
  const { t } = useTranslation('translations')
  return (
    <div className="sticky items-center top-0 z-30 border-b bg-white/50 px-10 py-2 backdrop-blur-lg lg:py-4 grid grid-cols-3">
      <div className="justify-self-start">{controls}</div>

      <div className="justify-self-center grid place-items-center gap-4 grid-cols-[1fr_auto_1fr]">
        <div className="text-end justify-self-end">
          <h3 className="text-lg font-bold">
            {sourceLanguage?.name?.en || sourceLanguage.name}
          </h3>
          <code className="text-sm text-gray-400">{t('source')}</code>
        </div>
        <Icon
          name="arrow-right"
          className="text-primary-300 place-self-center"
        />
        <div className="text-start justify-self-start">
          <h3 className="text-lg font-bold">
            {language?.name?.en || language.name} ({language.locale})
          </h3>
          <code className="text-sm text-gray-400">{t('translation')}</code>
        </div>
      </div>

      <div className="justify-self-end">{extra}</div>
    </div>
  )
}
HeaderSubmit.propTypes = {
  controls: PropTypes.node,
  extra: PropTypes.node,
  language: PropTypes.object,
  sourceLanguage: PropTypes.object,
}
