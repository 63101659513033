import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { useUILanguage } from '@config/i18n'
import { getFetch } from '@utils/fetcher'

export default function useCollection(id) {
  const params = useParams()

  const collectionId = id || params?.collectionId

  // Add language to query key to force refetching the show categories in current language
  const { language } = useUILanguage()

  const { data, error, isLoading } = useQuery(
    ['collections', collectionId, { language }],
    () =>
      getFetch(
        collectionId ? `/api/media-library/collections/${collectionId}` : null
      )
  )

  return { collection: data, error, loading: isLoading }
}
