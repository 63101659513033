import PropTypes from 'prop-types'
import React from 'react'

import ColorPreview from '@ui/data-display/ColorPreview'

export default function ColorSelectedOption({ value, label, color } = {}) {
  if (!value && !label && !color) return null

  return (
    <div className="flex items-center gap-2">
      <ColorPreview color={color} hideLabel size="sm" />
      <span>{label ?? value}</span>
    </div>
  )
}
ColorSelectedOption.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.string,
}
