import { createContext, useContext, useState } from 'react'

export const ResourcesContext = createContext()

export function useResources() {
  return useContext(ResourcesContext)
}

export function useResource(dynamic = false, defaultValue = null) {
  const resources = useResources()
  const [resourceKey, setResourceKey] = useState(defaultValue)

  const resource = dynamic ? resources[resourceKey] : null
  return { resources, resource, setResourceKey }
}
