import PropTypes from 'prop-types'
import React from 'react'

import { useDesign } from '@app/designs/hooks/useDesign'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

import useSite from '../../services/hooks/useSite'
import { ResourcesContext } from './useDesignContext'

export default function DesignProvider({ children, design }) {
  const { entity } = useCurrentEntity({ includeNetwork: true })
  const { site } = useSite()

  const designValue = useDesign({
    design:
      design ||
      site?.design?.name ||
      entity?.config?.defaultDesign ||
      entity?.network?.defaultDesign ||
      'base',
    site,
  })

  return (
    <ResourcesContext.Provider value={designValue}>
      {children}
    </ResourcesContext.Provider>
  )
}
DesignProvider.propTypes = {
  children: PropTypes.node,
  design: PropTypes.string,
}
