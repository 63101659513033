import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'

const config = {
  name: 'CourseActions',
  label: 'courses/public:courseActions',
  help: 'courses/public:courseActionsHelp',
  type: 'plugin',
  icon: 'input-pipe',
  component: <CourseActions />,
}

export default function CourseActions({
  showStartCourse,
  showPreviewCourse,
  showSaveForLater,
}) {
  const { t } = useTranslation('courses/public')
  const { breakpoint } = useBreakpoint()

  return (
    <Content className="w-full" icon={config.icon} title={config.label}>
      <div
        className={`p-2 flex gap-2 ${breakpoint === 'xs' ? 'flex-col items-center' : 'flex-row justify-center'}`}
      >
        {showStartCourse && <Button label={t('startCourse')} />}
        {showPreviewCourse && <Button label={t('previewCourse')} />}
        {showSaveForLater && (
          <Button
            className="-ml-4"
            icon="heart"
            label={t('saveForLater')}
            variant="flat"
          />
        )}
      </div>
    </Content>
  )
}
CourseActions.propTypes = {
  showStartCourse: PropTypes.bool,
  showPreviewCourse: PropTypes.bool,
  showSaveForLater: PropTypes.bool,
}
CourseActions.toolbarItem = config

function CourseActionsSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Box space="xs">
          <Checkbox
            label={t('startCourse')}
            onChange={value => {
              actions.setProp(props => (props.showStartCourse = value))
            }}
            value={data.showStartCourse}
            name="showStartCourse"
          />
          <Checkbox
            label={t('previewCourse')}
            onChange={value => {
              actions.setProp(props => (props.showPreviewCourse = value))
            }}
            value={data.showPreviewCourse}
            name="showPreviewCourse"
          />
          <Checkbox
            label={t('saveForLater')}
            onChange={value => {
              actions.setProp(props => (props.showSaveForLater = value))
            }}
            value={data.showSaveForLater}
            name="showSaveForLater"
          />
        </Box>
        <PagePicker
          label={t('courseDetailPageId')}
          help={t('courseDetailPageIdHelp')}
          value={data.courseDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.courseDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Course'
          }
        />
        <PagePicker
          label={t('lessonDetailPageId')}
          help={t('lessonDetailPageIdHelp')}
          value={data.lessonDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.lessonDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic &&
            getDynamicResourceIdentifier(page) === 'CourseLesson'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

CourseActions.craft = {
  displayName: config.name,
  props: {
    courseDetailPageId: undefined,
    lessonDetailPageId: undefined,
    showStartCourse: true,
    showPreviewCourse: true,
    showSaveForLater: false,
  },
  custom: {
    type: config.type,
    resources: ['courseActions', 'coursesProviderCountries'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseActionsSettings,
  },
}
