import PropTypes from 'prop-types'
import React from 'react'

const sizesStyles = {
  sm: {
    wrap: 'gap-2 p-2 text-xl',
    icon: 'text-xl',
    label: 'text-sm',
  },
  md: {
    wrap: 'gap-4 p-4 text-4xl',
    icon: 'text-4xl',
    label: 'text-sm',
  },
  lg: {
    wrap: 'gap-6 p-6 text-4xl',
    icon: 'text-4xl',
    label: 'text-lg',
  },
}

const alignStyles = {
  left: 'justify-start',
  center: 'justify-center',
  right: 'justify-end',
}

export default function Loading({ align, className, label, size }) {
  const alignClass = alignStyles[align] || alignStyles.center
  const sizeClasses = sizesStyles[size] || sizesStyles.lg

  return (
    <div
      className={`flex items-center ${alignClass} ${sizeClasses.wrap} ${className}`}
    >
      <span className={`flex ${sizeClasses.icon} animate-pulse`}>
        {/* Emulate the Font Awesome's Spinner icon to prevent importing ui/icons/Icon */}
        <svg
          className="fa-spinner svg-inline--fa fill-current animate-spin text-gray-600"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
        >
          <path d="M288 32a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zm0 448a32 32 0 1 0 -64 0 32 32 0 1 0 64 0zM448 256a32 32 0 1 0 64 0 32 32 0 1 0 -64 0zM32 288a32 32 0 1 0 0-64 32 32 0 1 0 0 64zM75 437a32 32 0 1 0 45.3-45.3A32 32 0 1 0 75 437zm316.8 0A32 32 0 1 0 437 391.8 32 32 0 1 0 391.8 437zM75 75a32 32 0 1 0 45.3 45.3A32 32 0 1 0 75 75z" />
        </svg>
      </span>
      {label && (
        <span className={`text-gray-400 ${sizeClasses.label}`}>{label}</span>
      )}
    </div>
  )
}
Loading.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  className: PropTypes.string,
  label: PropTypes.node,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
}
Loading.defaultProps = {
  align: 'center',
  className: '',
  size: 'lg',
}
