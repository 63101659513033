import React from 'react'

import config from './config'

const PublicationDetailSettings = React.lazy(() => import('./Settings'))
const PublicationDetailBlock = React.lazy(() => import('./Block'))

export default function PublicationDetail(props) {
  return <PublicationDetailBlock {...props} />
}

PublicationDetail.toolbarItem = {
  ...config,
  component: <PublicationDetail />,
}

PublicationDetail.craft = {
  displayName: 'PublicationDetail',
  props: {
    id: undefined,
    dynamic: false,
    typeTract: 'Tract',
    typeMagazine: 'Magazine',
    typeBook: 'Book',
    pagesLabel: 'Pages',
    showTitle: true,
    showDescription: true,
    showBody: true,
    variant: 'sharingHope',
  },
  custom: {
    type: config.type,
    resources: ['Publication', 'publicationDetail'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationDetailSettings,
  },
}
