import PropTypes from 'prop-types'
import React from 'react'

import DashboardContext from './context'

export default function DashboardProvider({ children, dashboards }) {
  return (
    <DashboardContext.Provider value={dashboards}>
      {children}
    </DashboardContext.Provider>
  )
}
DashboardProvider.propTypes = {
  children: PropTypes.node.isRequired,
  dashboards: PropTypes.array.isRequired,
}
