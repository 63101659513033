import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Image from '@ui/data-display/Image'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import useCourse from '../../services/hooks/useCourse'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const RichText = React.lazy(() => import('@ui/data-entry/RichText/Render'))

const config = {
  name: 'CourseDetail',
  label: 'courses/public:courseDetail',
  help: 'courses/public:courseDetailHelp',
  type: 'plugin',
  icon: 'file-invoice',
  component: <CourseDetail />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    abstract: '[Placeholder for abstract.]',
    images: {
      default: '[ IMAGE ]',
    },
    body: {
      type: 'doc',
      content: [
        {
          type: 'paragraph',
          content: [
            {
              type: 'text',
              text: "[This is a placeholder for course's body]",
            },
          ],
        },
      ],
    },
  }
}

export default function CourseDetail({
  dynamic,
  courseId,
  showAbstract,
  showBody,
  showImage,
  showTitle,
}) {
  const { resources } = usePageResources()
  const { course } = useCourse(dynamic ? null : courseId)

  const _course = resources?.Course
    ? resources.Course
    : course || getPlaceholder()

  const { abstract, images, body, title } = _course || {}

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div className="flex flex-col p-2">
        <div className="space-y-6">
          {showImage && images?.default && (
            <div>
              {typeof images.default === 'object' ? (
                <Image
                  className="w-full"
                  file={images.default}
                  alt={title}
                  width={600}
                />
              ) : typeof images.default === 'string' ? (
                images.default
              ) : (
                ''
              )}
            </div>
          )}
          {showTitle && <h1 className="text-3xl font-bold">{title}</h1>}
          {showAbstract && abstract && (
            <p className="font-serif italic text-gray-600">{abstract}</p>
          )}
          {showBody && body && <RichText doc={body} />}
        </div>
      </div>
    </Content>
  )
}
CourseDetail.propTypes = {
  providerId: PropTypes.string,
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  showAbstract: PropTypes.bool,
  showBody: PropTypes.bool,
  showImage: PropTypes.bool,
  showTitle: PropTypes.bool,
}
CourseDetail.toolbarItem = config

function CourseDetailSettings() {
  const { t } = useTranslation('courses/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.CourseProvider && !resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.CourseProvider.title}
          </Message>
        )}
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Box space="xs">
          <Checkbox
            label={t('image')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
            name="showImage"
          />
          <Checkbox
            label={t('title')}
            onChange={value => {
              actions.setProp(props => (props.showTitle = value))
            }}
            value={data.showTitle}
            name="showTitle"
          />
          <Checkbox
            label={t('abstract')}
            onChange={value => {
              actions.setProp(props => (props.showAbstract = value))
            }}
            value={data.showAbstract}
            name="showAbstract"
          />
          <Checkbox
            label={t('body')}
            onChange={value => {
              actions.setProp(props => (props.showBody = value))
            }}
            value={data.showBody}
            name="showBody"
          />
        </Box>
        {!resources?.Course && (
          <>
            <Divider />
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {resources?.CourseProvider ? (
                  <Message className="text-sm" type="warn">
                    {resources.CourseProvider.title}
                  </Message>
                ) : (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseDetail.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
    showAbstract: true,
    showBody: true,
    showImage: true,
    showTitle: true,
  },
  custom: {
    type: config.type,
    resources: ['courseDetail'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseDetailSettings,
  },
}
