import React from 'react'

import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import ProgressBar from '@ui/feedback/ProgressBar'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CourseStatus',
  label: 'courses/public:courseStatus',
  help: 'courses/public:courseStatusHelp',
  type: 'plugin',
  icon: 'file-invoice',
  component: <CourseStatus />,
}

export default function CourseStatus() {
  const { t } = useTranslation(['courses/public', 'courses'])
  const { breakpoint } = useBreakpoint()

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={false}
    >
      <div
        className={`flex justify-between gap-4 ${['xs', 'sm'].includes(breakpoint) ? 'flex-col' : 'flex-row'}`}
      >
        <div className="border rounded-lg px-6 py-4 flex-1">
          <div className="space-y-1">
            <div className="text-right text-xs">{t('lesson')}: 7 / 9</div>
            <ProgressBar percentage={(7 / 9) * 100} hidePercentage />
          </div>
        </div>
        <div className="border rounded-lg px-6 py-4 flex-1">
          <div
            className={`flex items-center gap-2 ${['xs', 'sm'].includes(breakpoint) ? 'justify-center' : 'justify-end'}`}
          >
            <Icon className="text-sm" name="user" />
            <div className="text-sm">{t('courses:mode_autonomous')}</div>
            <Button label={t('change')} size="xs" variant="basic" />
          </div>
        </div>
      </div>
    </Content>
  )
}
CourseStatus.propTypes = {}
CourseStatus.toolbarItem = config

function CourseStatusSettings() {
  const { t } = useTranslation('courses/public')

  const { resources } = usePageResources()

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.CourseProvider && !resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.CourseProvider.title}
          </Message>
        )}
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseStatus.craft = {
  displayName: config.name,
  props: {},
  custom: {
    type: config.type,
    resources: ['courseStatus'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseStatusSettings,
  },
}
