import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

import { publicEntitiesApiUrl } from '../EntityServices'

const defaultOptions = {
  id: null,
  enabled: true,
  includeAncestors: false,
  fromParams: true,
}

export default function useEntityPublicData(options = defaultOptions) {
  const { id, enabled, fromParams } = Object.assign({}, defaultOptions, options)

  const params = useParams()

  const entityId = id || (fromParams ? params.entityId : null)

  const { data, error, isLoading } = useQuery(
    ['entities-public', entityId, enabled],
    () => getFetch(`${publicEntitiesApiUrl}/${entityId}`),
    { enabled: enabled && !!entityId }
  )

  return { entity: data, error, loading: isLoading }
}
