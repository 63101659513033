import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { useTextSizeOptions } from './hooks'

/**
 * Select field for font weight.
 *
 * @param {object} props - Component props
 * @param {string} [props.name] - Name of the field
 * @param {string} [props.label] - Optional label of the field
 * @param {boolean} [props.lightLabel] - Whether the label should be light
 * @param {string[]} [props.availableSizes] - Optional list of available sizes
 * @param {string} [props.defaultTextSize] - Default text size
 * @returns {ReactElement} - The component
 */
export default function TextSizeSelectField({
  allowCustom,
  availableSizes,
  fieldExtra,
  help,
  inheritFrom,
  inheritValue,
  label,
  labelExtra,
  lightLabel,
  name = 'textSize',
  onChange,
  placeholderValue,
  value,
}) {
  const { t } = useTranslation('web/content-editor')
  const textSizeOptions = useTextSizeOptions(availableSizes)

  return (
    <SelectField
      fieldExtra={fieldExtra}
      help={help}
      label={label || t('textSize')}
      labelExtra={labelExtra}
      lightLabel={lightLabel}
      name={name}
      onChange={onChange}
      placeholderValue={placeholderValue}
      value={value}
    >
      <InheritSelectPlaceholder
        labelKey="textSize"
        value={value}
        inheritValue={inheritValue}
        inheritFrom={inheritFrom}
      />
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      {textSizeOptions.map(({ label, value }) => (
        <SelectOption value={value} label={label} key={value} />
      ))}
      {allowCustom && <SelectOption value="custom" label={t('custom')} />}
    </SelectField>
  )
}
TextSizeSelectField.propTypes = {
  allowCustom: PropTypes.bool,
  availableSizes: PropTypes.arrayOf(PropTypes.string),
  defaultTextSize: PropTypes.string,
  fieldExtra: PropTypes.node,
  help: PropTypes.string,
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  label: PropTypes.string,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  placeholderValue: PropTypes.string,
  value: PropTypes.string,
}
