import React from 'react'

import { useTranslation } from 'react-i18next'

import { componentsList } from './componentsList'
import useCurrentComponent from './useCurrentComponent'

const Menu = React.lazy(() => import('@ui/navigation/Menu'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))

export default function ComponentsMenu() {
  const { t } = useTranslation('designs/base')

  const { currentComponent, setComponentParam } = useCurrentComponent()

  return (
    <Menu>
      {componentsList.map(({ name: component, icon }) => (
        <MenuItem
          label={t(`component_${component}`)}
          icon={icon || 'block'}
          key={`component_${component}`}
          active={currentComponent === component}
          onClick={() => setComponentParam(component)}
        />
      ))}
    </Menu>
  )
}
