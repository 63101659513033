import { isObject } from '@utils/objects'
import { isArray } from '@utils/types'

export function getSelectedOption(value, options) {
  for (let option of options) {
    if (isObject(value)) {
      if (option.value === value.value) {
        return option
      }
    }
    if (isArray(option.value)) {
      const found = getSelectedOption(value, option.value)
      if (found) return found
    }
    if (option.value === value) {
      return option
    }
  }
}
