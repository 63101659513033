import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useCollections({ channelId }) {
  const { data, error, isLoading, isFetching } = useQuery(
    ['collections', { channelId }],
    () =>
      getFetch(
        channelId
          ? `/api/media-library/channels/${channelId}/collections`
          : null
      )
  )

  return {
    collections: data?.items || [],
    count: data?.count || 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
