const themeColors = {
  treefrog: {
    100: '#9fe180', // lighter
    300: '#7cd500', // light
    500: '#2b8500', // primary
    700: '#355724', // dark
    900: '#1b2d00', // darker
  },
  ming: {
    100: '#9ae1f0', // lighter
    300: '#00d5fe', // light
    500: '#007f98', // primary
    700: '#255760', // dark
    900: '#002938', // darker
  },
  bluejay: {
    100: '#84adfc', // lighter
    300: '#84adfc', // light
    500: '#2e6de7', // primary
    700: '#2f557f', // dark
    900: '#052252', // darker
  },
  iris: {
    100: '#b971f7', // lighter
    300: '#b971f7', // light
    500: '#9013fe', // primary
    700: '#4b207f', // dark
    900: '#37034c', // darker
  },
  lily: {
    100: '#ff61bc', // lighter
    300: '#ff61bc', // light
    500: '#d41583', // primary
    700: '#712551', // dark
    900: '#4e053f', // darker
  },
  scarlett: {
    100: '#e17077', // lighter
    300: '#fd2641', // light
    500: '#d0021b', // primary
    700: '#782832', // dark
    900: '#440000', // darker
  },
  campfire: {
    100: '#361300', // lighter
    300: '#6f2727', // light
    500: '#cd4900', // primary
    700: '#6f2727', // dark
    900: '#361300', // darker
  },
  winter: {
    100: '#f9f9f9', // lighter
    300: '#f9f9f9', // light
    500: '#717171', // primary
    700: '#4a4a4a', // dark
    900: '#222222', // darker
  },
  forest: {
    100: '#9fe180', // lighter
    300: '#7cd500', // light
    500: '#355724', // primary
    700: '#2b8500', // dark
    900: '#101a00', // darker
  },
  cave: {
    100: '#00d5fe', // lighter
    300: '#00d5fe', // light
    500: '#255760', // primary
    700: '#007f98', // dark
    900: '#01161e', // darker
  },
  denim: {
    alps: {
      100: '#84adfc', // lighter
      300: '#2e6de7', // light
      500: '#2f557f', // primary
      700: '#04132b', // dark
      900: '#04132b', // darker
    },
    news: {
      100: '#86d4ff', // lighter
      300: '#51befb', // light
      500: '#003b5c', // primary
      700: '#002d46', // dark
      900: '#001724', // darker
    },
  },
  emperor: {
    100: '#b971f7', // lighter
    300: '#b971f7', // light
    500: '#4b207f', // primary
    700: '#9013fe', // dark
    900: '#240431', // darker
  },
  grapevine: {
    100: '#ff61bc', // lighter
    300: '#ff61bc', // light
    500: '#712551', // primary
    700: '#d41583', // dark
    900: '#2a0d24', // darker
  },
  velvet: {
    100: '#e17077', // lighter
    300: '#fd2641', // light
    500: '#782832', // primary
    700: '#d0021b', // dark
    900: '#2d0505', // darker
  },
  earth: {
    100: '#eab57e', // lighter
    300: '#eab57e', // light
    500: '#5e3929', // primary
    700: '#974e02', // dark
    900: '#210b00', // darker
  },
  night: {
    100: '#f9f9f9', // lighter
    300: '#f9f9f9', // light
    500: '#4a4a4a', // primary
    700: '#717171', // dark
    900: '#000000', // darker
  },
}

/**
 * Get the theme colors based on the theme and design variant
 * @param {Object} options
 * @param {string} options.theme - The theme
 * @param {string} options.designVariant - The design variant
 */
export function getThemeColors({ theme = 'denim', designVariant }) {
  const colors = themeColors[theme]

  return colors[designVariant] || colors || themeColors['denim']
}
