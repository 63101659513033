import React from 'react'

import config from './config'

const UserProfileBlock = React.lazy(() => import('./Block'))
const UserProfileSettings = React.lazy(() => import('./Settings'))

export default function UserProfile(props) {
  return <UserProfileBlock {...props} />
}

UserProfile.toolbarItem = {
  ...config,
  component: <UserProfile />,
}

UserProfile.craft = {
  displayName: 'UserProfile',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: UserProfileSettings,
  },
}
