import PropTypes from 'prop-types'
import React, { useState } from 'react'

import { ClipboardContext } from './hooks'

export default function ClipboardProvider({ children }) {
  const [nodeToPaste, setNodeToPaste] = useState(false)

  return (
    <ClipboardContext.Provider value={{ nodeToPaste, setNodeToPaste }}>
      {children}
    </ClipboardContext.Provider>
  )
}
ClipboardProvider.propTypes = {
  children: PropTypes.node,
}
