import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

import { useSiteId } from './useSite'

const defaultOptions = {
  includeAncestors: false,
}

export default function useSiteOrAncestorSite(
  siteId,
  options = defaultOptions
) {
  const { includeAncestors, ...configOptions } = Object.assign(
    {},
    defaultOptions,
    options
  )

  const paramId = useSiteId()
  const id = siteId || paramId

  const isIdValid = !!id

  const { data, ...rest } = useQuery(
    ['site-or-ancestor', id, includeAncestors],
    () => {
      if (!isIdValid) return
      return getFetch(`/api/web/sites/${id}/site-or-ancestor`, {
        includeAncestors,
      })
    },
    {
      enabled: isIdValid,
      ...configOptions,
    }
  )

  return { ...rest, site: data, siteId: id }
}
