import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useEditor } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import Popover from '@ui/feedback/Popover'
import Box from '@ui/layout/Box'

import { useDesignContext } from '../../DesignProvider'
import { useBlocksToolbars } from '../providers/blocksContext'
import { ContentIcon } from './Content'
import useContentTypeStyles from './Content/hooks/useContentTypeStyles'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function Toolbar({ allowed, defaultOpen }) {
  const design = useDesignContext()
  const blocksToolbars = useBlocksToolbars()

  const allowedGroups = useMemo(() => {
    const validToolbars = [...(blocksToolbars || [])].reduce((acc, toolbar) => {
      const availableItems = toolbar.items.filter(({ toolbarItem: { name } }) =>
        Boolean(design.blocks[name])
      )
      if (availableItems.length > 0) {
        return [...acc, { ...toolbar, items: availableItems }]
      }
      return acc
    }, [])

    return validToolbars
      .map(group => {
        if (!group.items) return null

        const groupAllowedItems = group.items?.filter(
          ({ toolbarItem: { name } }) => allowed.includes(name)
        )

        if (groupAllowedItems.length === 0) return null

        return {
          name: group.name,
          label: group.label,
          items: groupAllowedItems,
        }
      })
      .filter(group => group !== null)
  }, [allowed, blocksToolbars, design.blocks])

  if (allowedGroups.length === 0) return null

  return (
    <Box space="lg">
      {allowedGroups.map(({ label, name, items }, i) => (
        <ToolbarGroup
          name={label || name}
          items={items}
          key={`toolbar-group-${name}`}
          defaultOpen={defaultOpen ? defaultOpen === name : i === 0}
        />
      ))}
    </Box>
  )
}
Toolbar.propTypes = {
  allowed: PropTypes.array,
  defaultOpen: PropTypes.string,
}

function ToolbarGroup({ name, items, defaultOpen }) {
  const { t } = useTranslation('web/content-editor')

  if (items.length === 0) return null

  return (
    <CollapsablePanel title={t(name)} defaultOpen={defaultOpen}>
      {items.map(
        ({ toolbarItem: { label, help, icon, component, name, type } }, i) => (
          <ToolbarItem
            label={label || name}
            icon={icon}
            help={help}
            type={type}
            component={component}
            key={`toolbar-${name}-item-${i}`}
          />
        )
      )}
    </CollapsablePanel>
  )
}
ToolbarGroup.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  defaultOpen: PropTypes.bool,
}

function ToolbarItem({ label, icon, help, component: Component, type }) {
  const { t } = useTranslation('web/content-editor')

  const typeClasses = useContentTypeStyles(type)

  const component = useMemo(
    () => (typeof Component === 'function' ? <Component /> : Component),
    [Component]
  )

  const { actions, connectors, query, currentNodeId } = useEditor(state => {
    const [currentNodeId] = state?.events.selected || []
    return { currentNodeId: currentNodeId || 'ROOT' }
  })

  const onPlusClick = useCallback(() => {
    const nodeTree = query.parseReactElement(component).toNodeTree()
    actions.addNodeTree(nodeTree, currentNodeId)
  }, [actions, component, currentNodeId, query])

  return (
    <Box
      className={`rounded-lg border border-dashed border-gray-300 bg-opacity-10 p-1 text-gray-600 text-opacity-75 transition-all duration-300 ease-in-out hover:cursor-grab hover:bg-opacity-20 hover:text-opacity-100 ${typeClasses.bg} ${typeClasses.text} `}
      horizontal
      innerRef={ref => connectors.create(ref, component)}
      space="xs"
      align="center"
    >
      <ContentIcon name={icon} className="px-2" />
      <div className="flex flex-grow flex-row items-center gap-2">
        <span className="font-semibold">{t(label)} </span>
        {help && (
          <Popover>
            <Popover.Trigger asChild>
              <Icon
                name="question-circle"
                className="opacity-50 hover:opacity-90"
              />
            </Popover.Trigger>
            <Popover.Content>
              <p>{t(help)}</p>
            </Popover.Content>
          </Popover>
        )}
      </div>
      <Button
        onClick={onPlusClick}
        icon="plus"
        size="xs"
        variant="flat-success"
        tooltip={t('addBlock')}
      />
    </Box>
  )
}
ToolbarItem.propTypes = {
  component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  help: PropTypes.string,
  icon: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
}
