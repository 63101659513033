import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

import { currentEntityApiUrl } from '../EntityServices'

export default function useCurrentEntity({
  includeAncestors = false,
  includeNetwork = false,
} = {}) {
  const { data, error, isLoading } = useQuery(
    ['entities', 'current', includeAncestors, includeNetwork],
    () => getFetch(currentEntityApiUrl, { includeAncestors, includeNetwork })
  )

  return { entity: data, error, loading: isLoading }
}
