import { isEmpty } from '@utils/objects'
import { isFunction } from '@utils/types'

export function checkHasAccessToEntity({
  group,
  entity,
  permission = 'read',
  groupPermissionsEntityId,
}) {
  const ancestors = entity?.ancestors || []

  // Check if the group has the permission for an ancestor
  const canReadAncestor = ancestors.some(ancestor => {
    const ancestorEntityId =
      typeof ancestor === 'string' ? ancestor : ancestor?.id

    return (
      groupPermissionsEntityId === ancestorEntityId &&
      group.permissions['subentities']?.[permission]
    )
  })

  // If the group has the permission for an ancestor, we grant access to the module
  if (canReadAncestor) return true

  // If the group doesn't allow read for an ancestor, we check if the group has the entity record
  return (
    group.permissions['entity']?.records?.includes(entity?._id) ||
    entity?._id === groupPermissionsEntityId
  )
}

export function checkHasAccessToModuleSubentities({
  currentEntityId,
  delegateFunction,
  entity,
  group,
  groupPermissionsEntityId,
  module,
  permission,
  recordId,
  records,
  subentitiesModule,
  user,
}) {
  // 1. We check for access to that entity

  // Check all groups for one with access to the entity
  const hasAccessToEntity = user.groups.find(userGroup => {
    const hasAccessToAncestor = checkHasAccessToEntity({
      group: userGroup,
      entity,
      groupPermissionsEntityId,
    })
    // It does not matter in which group
    // This check is just to confirm that the entity is reachable.
    if (hasAccessToAncestor) return true
  })

  if (hasAccessToEntity) {
    const directPermission =
      currentEntityId === groupPermissionsEntityId &&
      (isEmpty(records) ||
        records?.includes(recordId) ||
        (isFunction(delegateFunction) &&
          delegateFunction({ module, permission, recordId, records })))

    if (directPermission) return true

    // Must check if the permission for web subentities is enabled in the group, then skip the group entity check
    const subentitiesPermission =
      group.permissions[subentitiesModule ?? module]?.['subentities']

    // Check if the group has the permission for the entity or an ancestor
    if (
      groupPermissionsEntityId !== currentEntityId &&
      !entity.ancestors?.includes(groupPermissionsEntityId)
    ) {
      return false
    }

    if (subentitiesPermission) {
      return (
        isEmpty(records) ||
        records?.includes(recordId) ||
        (isFunction(delegateFunction) &&
          delegateFunction({ module, permission, recordId, records }))
      )
    }
  } else return false
}
