import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import { isUserAuthorized } from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { isArray } from '@utils/types'

import { ModelPermissionsWrap } from './ModelPermissionsWrap'
import { PermissionCheckbox } from './PermissionCheckbox'

export function ModelPermissions({ model, label, className }) {
  const { t } = useTranslation()
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  if (!model || !model?.name || !isArray(model?.permissions)) {
    return null
  }

  const { help, name, permissions } = model

  const allowedPermissions = permissions.filter(permission => {
    return isUserAuthorized({
      user,
      entity: currentEntity,
      currentEntityId: currentEntity?.id,
      module: name,
      permission,
    })
  })

  if (!allowedPermissions.length) return null

  return (
    <ModelPermissionsWrap
      label={label || t(model.label)}
      key={name}
      className={className}
      help={help}
    >
      {allowedPermissions.map(permission => (
        <PermissionCheckbox
          model={name}
          permission={permission}
          key={`permission-${name}-${permission}`}
        />
      ))}
    </ModelPermissionsWrap>
  )
}
ModelPermissions.propTypes = {
  className: PropTypes.string,
  model: PropTypes.shape({
    help: PropTypes.string,
    name: PropTypes.string,
    permissions: PropTypes.array,
    label: PropTypes.string,
  }),
  label: PropTypes.node,
}
