import base from '../base'
import FooterForm from './appearance/FooterForm'
import HeaderForm from './appearance/HeaderForm'
import useDesignSettingsTabs from './appearance/useDesignSettingsTabs'
import { PageForm } from './page/PageForm'

/**
 * Represents the Hope design configuration.
 * @param {Object} options - The options for the Hope design.
 * @param {Object} options.site - The site.
 * @param {Object} options.featureFlags - The feature flags
 * @returns {Object} - The Hope design configuration.
 */
export default function () {
  const baseConfig = base()

  /**
   * IMPORTANT: keep this updated as the values in the frontend changes
   */
  return {
    ...baseConfig,
    title: 'Hope design',

    namespace: 'designs/hope',

    layouts: true,
    notifications: true,

    appearance: {
      HeaderForm,
      FooterForm,
      useSettingsTabs: useDesignSettingsTabs, // This is a hook that returns an array of tabs
    },

    pageForm: PageForm,

    fonts: {
      ...baseConfig.fonts,
      weights: ['extralight', 'light', 'normal', 'semibold', 'bold', 'black'],
      sizes: ['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl', '4xl', '6xl', '8xl'],
    },

    padding: {
      type: 'design', // options: 'all', 'design' or 'defaults'
      options: ['zero', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
    },

    columns: [1, 2, 3, 4, 5, 6, 7],

    colors: {
      primary: {
        50: '#ecf4fe',
        100: '#ccdcf1',
        200: '#acc4e4',
        300: '#8cacd7',
        400: '#6b95c9',
        500: '#4b7dbc',
        600: '#2b65af',
        700: '#0b4da2', // Brand color
        800: '#08336b',
        900: '#041934',
        DEFAULT: '#0b4da2',
        name: 'Hope Blue',
      },
      secondary: {
        50: '#fefdf5',
        100: '#fefbeb',
        200: '#fefcbf',
        300: '#faf089',
        400: '#f6e05e',
        500: '#f2d434',
        600: '#d69e2e',
        700: '#b7791f',
        800: '#975a16',
        900: '#744210',
        DEFAULT: '#f2d434',
        name: 'Hope Yellow',
      },
      tertiary: {
        50: '#ecfeff',
        100: '#cffafe',
        200: '#a5f3fc',
        300: '#67e8f9',
        400: '#22d3ee',
        500: '#06b6d4',
        600: '#0891b2',
        700: '#0e7490',
        800: '#155e75',
        900: '#164e63',
        DEFAULT: '#06b6d4',
        name: 'Hope Cyan',
      },
      gray: {
        50: '#f9fafb',
        100: '#f3f4f6',
        200: '#e5e7eb',
        300: '#d1d5db',
        400: '#9ca3af',
        500: '#6b7280',
        600: '#4b5563',
        700: '#374151',
        800: '#1f2937',
        900: '#111827',
        DEFAULT: '#6b7280',
        name: 'Hope Gray',
      },
      system: baseConfig.colors.system,
    },

    buttons: {
      ...baseConfig.buttons,
      colors: ['primary', 'secondary', 'tertiary', 'none'],
      variants: ['primary', 'secondary', 'tertiary', 'hollow', 'alt', 'link'],
    },

    icons: [
      'app-store',
      'article',
      'asterisk',
      'at',
      'audio',
      'bell',
      'check',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'download',
      'envelope',
      'expand-arrows',
      'external-link',
      'facebook',
      'file-invoice',
      'globe',
      'google-play',
      'instagram',
      'language',
      'link',
      'map-marker',
      'page',
      'play',
      'play-circle',
      'podcast',
      'right-from-bracket', // used mainly as logout icon
      'sda-logo-circular',
      'search',
      'sound-cloud',
      'spotify',
      'stack',
      'telegram',
      'telephone',
      'tiktok',
      'times',
      'trash',
      'tv',
      'twitter',
      'user',
      'video',
      'vimeo',
      'youtube',
      'whatsapp',
    ],

    blocks: {
      ...baseConfig.blocks,

      Section: {
        align: true,
        aspectRatio: true,
        bgVideo: true,
        dark: true,
        justify: true,
        padding: 'all',
      },
      Container: {
        align: true,
        dark: true,
        justify: true,
        bgVideo: true,
        aspectRatio: true,
      },
      Box: {
        simple: true,
        dark: true,
        backgroundColor: true,
        bgVideo: true,
        aspectRatio: true,
      },
      Card: {
        imageAspectRatio: true,
        enableAnimation: true,
      },
      Menu: false,
      Hero: false,
      SimpleText: {
        textAlign: true,
      },
      Image: true,
      PageList: false,
      Testimonies: false,
      FeaturedContent: false,
      BibleVerse: true,
      CookieBlockedContent: true,
      Map: false,
      Avatar: false,
      ImageGallery: {
        useThumbAspectRatio: '16/9',
      },
      LinkList: {
        title: true,
        color: true,
      },
      Button: {
        icon: true,
        buttonColor: true,
      },
      ShareButton: {
        title: true,
      },
      Player: {
        variant: true,
        poster: true,
        fullSize: true,
      },
      QuoteCarousel: {
        fontSerif: true,
      },
      RichText: true,
      FormInput: {
        files: true,
      },

      // Articles
      ArticleTeaser: true,
      ArticleDetail: {
        showTitle: true,
        showImage: true,
        showDate: true,
        showSubtitle: true,
        showAuthor: true,
        showLocation: true,
        showBody: true,
        showFiles: true,
      },
      ArticleList: {
        displayModes: [
          'cards',
          'cards-with-list',
          'featured',
          'featured-with-list',
          'list',
          'posters',
        ],
        enableAnimations: true,
      },
      ArticleRepeater: false,
      LatestArticles: true,

      // Courses
      CoursesCollection: true,
      CoursesList: true,
      CoursesEnrolledCourses: true,
      CourseContent: true,
      CourseHero: true,
      CourseDetail: true,
      CourseImage: true,
      CourseActions: true,
      CourseStatus: true,
      CourseTrailer: true,
      CourseAdvisor: true,
      CourseTableOfContents: true,
      CourseNavigation: true,
      CoursePagination: true,
      CourseMetaData: true,
      CourseRegistration: true,
      CoursesStudentAdvisors: true,
      CoursesStudentProfile: true,
      CoursesDeleteStudentAccount: true,
      CoursesStudentNotifications: true,
      CoursesMessages: true,

      // Mobile app notifications (hidden)
      MobileAppNotification: true,
      DemoReviewLink: true,

      // Media Library
      ChannelsMap: true,
      Collection: true,
      CollectionList: true,
      CollectionTeaser: true,
      EpisodesTeaser: true,
      EpisodesList: {
        enableAnimations: true,
      },
      ResourceEpisodes: false,
      EpisodeDetail: true,
      EpisodePlayer: {
        fullSize: true,
      },
      CarouselEpisodeRepeater: false,
      EpisodeRepeater: false,
      EpisodeAudioPlayer: true,
      EpisodeGuests: true,
      EpisodeHosts: true,
      EpisodeLinks: true,
      EpisodeDocuments: true,
      EpisodeDownloads: true,
      EpisodeMeta: true,
      RelatedEpisodes: true,
      LivestreamPlayer: true,
      ScheduleDay: true,
      ScheduleLive: true,
      ScheduleWeek: true,
      ShowDetail: true,
      ShowImage: true,
      ShowHosts: true,
      ShowParticipants: true,
      ShowLinks: true,
      ShowDocuments: true,
      ShowPodcasts: true,
      ShowAudioPlaylist: true,
      ShowsFeedList: true,
      ShowVideoCategories: true,
      ShowMeta: true,
      ShowList: true,
      RelatedShows: true,
      PersonDetail: true,
      StandaloneDonation: true,
      CategoryVideos: true,
      EpisodeVideos: true,
      VideoDetail: true,
      VideoCategories: true,
      VideoCategoryDetail: true,
      VideoPlayer: true,
      VideoParent: true,
      RelatedVideos: true,
      AudioPlayer: true,
      AudioPlaylist: true,

      // User Login
      LoginError: true,
      LoginForm: true,
      RegistrationForm: true,
      VerifyLogin: true,
      UserProfile: true,
      UserAccountOverview: true,
    },

    menus: {
      itemVariants: {
        isLive: 'designs/hope:menuItemIsLive',
      },
    },
  }
}
