import PropTypes from 'prop-types'
import React from 'react'
import { cloneElement, forwardRef, isValidElement } from 'react'

import { useMergeRefs } from '@floating-ui/react'

import Button from '@ui/buttons/Button'

import { usePopoverContext } from '../context'

export const PopoverTrigger = forwardRef(function PopoverTrigger(
  { children, asChild = false, ...props },
  propRef
) {
  const context = usePopoverContext()
  const childrenRef = children?.ref
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef])

  // `asChild` allows the user to pass any element as the anchor
  if (asChild && isValidElement(children)) {
    return cloneElement(
      typeof children === 'function' ? children(context) : children,
      context.getReferenceProps({
        ref,
        ...props,
        ...children.props,
      })
    )
  }

  if (asChild && typeof children === 'function') {
    return children({ ref, ...context })
  }

  return (
    <Button ref={ref} {...props} {...context.getReferenceProps(props)}>
      {typeof children === 'function' ? children(context) : children}
    </Button>
  )
})

PopoverTrigger.propTypes = {
  ...Button.propTypes,
  asChild: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}
