import PropTypes from 'prop-types'
import React from 'react'

import Tag from '@ui/data-display/Tag'

import CategoryWithAncestors from './CategoryWithAncestors'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function CategoryTag({ category, onDelete }) {
  if (!category) return null

  return (
    <Tag
      label={<CategoryWithAncestors category={category} variant="compact" />}
      onDelete={onDelete}
      icon={<Icon name="tag" />}
    />
  )
}
CategoryTag.propTypes = {
  category: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
}
