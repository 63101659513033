import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'

export default function CollectionItem({ collection, hideTitle, layout }) {
  if (!collection) return null
  const { _id, courses, title } = collection

  // Render courses
  return (
    <div className="flex flex-row space-x-4" key={_id}>
      <div className="space-y-2">
        {title && !hideTitle && (
          <h3 className="text-lg font-semibold">{title}</h3>
        )}
        <div className="-mx-2 flex flex-wrap">
          {courses?.map((course, key) => {
            const { images, title } = course
            const image =
              layout === 'card'
                ? images?.default
                : images?.poster || images?.default

            return (
              <div
                className="mx-2 mb-4 min-h-36 w-32 rounded border bg-gray-100"
                key={key}
              >
                {image && (
                  <div
                    className={
                      layout === 'card'
                        ? 'aspect-w-16 aspect-h-9'
                        : 'aspect-h-4 aspect-w-3'
                    }
                  >
                    <Image
                      file={image}
                      className={layout === 'card' ? 'rounded-t' : 'rounded'}
                      alt={title}
                      width="200"
                      aspectRatio="16/9"
                    />
                  </div>
                )}
                {layout === 'card' && (
                  <div className="space-y-1 p-2 text-xs">
                    <div className="truncate font-semibold">{title}</div>
                  </div>
                )}
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

CollectionItem.propTypes = {
  collection: PropTypes.object,
  hideTitle: PropTypes.bool,
  layout: PropTypes.oneOf(['card', 'poster']),
}
