import React from 'react'

export const TasksContext = React.createContext()

export function useTasksContext() {
  const tasks = React.useContext(TasksContext)
  if (!tasks) {
    throw new Error('useTaskContext must be used within a TaskProvider')
  }

  return tasks
}
