import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useCoursesPermissions() {
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  const commonParams = {
    user,
    entity: currentEntity,
    currentEntityId: currentEntity?.id,
  }

  return {
    // Providers
    canReadProviders: useIsUserAuthorized({
      module: 'courses-providers',
      permission: 'read',
      delegateFunction: ({ module, permission }) => {
        if (module === 'courses-providers' && permission === 'read') {
          return true
        }
      },
    }),
    canCreateProviders: useIsUserAuthorized({
      module: 'courses-providers',
      permission: 'create',
      ...commonParams,
    }),
    canUpdateProviders: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-providers',
        permission: 'update',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteProviders: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-providers',
        permission: 'delete',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Courses
    canReadCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCloneCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'clone',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canShareCourses: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses',
        permission: 'share',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Collections
    canReadCollections: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-collections',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateCollections: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-collections',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateCollections: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-collections',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteCollections: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-collections',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Countries
    canReadCountries: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-countries',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateCountries: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-countries',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateCountries: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-countries',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteCountries: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-countries',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Students
    canReadAllStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'readAll',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canReadStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateAllStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'updateAll',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteAllStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'deleteAll',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteStudents: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-students',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Advisors
    canReadAdvisors: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-advisors',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateAdvisors: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-advisors',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateAdvisors: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-advisors',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteAdvisors: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-advisors',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Messages
    canReadAllMessages: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-messages',
        permission: 'readAll',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canReadMessages: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-messages',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateMessages: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-messages',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateMessages: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-messages',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Questionnaires
    canReadAllQuestionnaires: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-questionnaires',
        permission: 'readAll',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canReadQuestionnaires: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-questionnaires',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canCreateQuestionnaires: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-questionnaires',
        permission: 'create',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canUpdateQuestionnaires: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-questionnaires',
        permission: 'update',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
    canDeleteQuestionnaires: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-questionnaires',
        permission: 'delete',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },

    // Statistics
    canReadStatistics: ({ providerId }) => {
      return isUserAuthorized({
        module: 'courses-statistics',
        permission: 'read',
        recordModule: 'courses-providers',
        recordId: providerId,
        ...commonParams,
      })
    },
  }
}
