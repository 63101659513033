import React from 'react'

import Cookies from 'js-cookie'
import { useQuery, useQueryClient } from 'react-query'

// Uselles storage that matches the localStorage API
const fallbackStorage = {
  getItem: () => null,
  setItem: () => null,
  removeItem: () => null,
}

/**
 * Provides a hook to use localStorage
 * @param {String} key - The key to read/store the value from/under
 * @param {*} defaultValue - The default value to use if the key is not set
 * @returns `array` - [value, setValue, remove]
 */
export function useLocalStorage(key, defaultValue) {
  const storage = navigator?.cookieEnabled
    ? window.localStorage
    : fallbackStorage
  return useStorage(key, defaultValue, storage)
}

/**
 * Provides a hook to use sessionStorage
 * @param {String} key - The key to read/store the value from/under
 * @param {*} defaultValue - The default value to use if the key is not set
 * @returns `array` - [value, setValue, remove]
 */
export function useSessionStorage(key, defaultValue) {
  const storage = navigator?.cookieEnabled
    ? window.sessionStorage
    : fallbackStorage
  return useStorage(key, defaultValue, storage)
}

/**
 * Provides a hook to use cookies
 * @param {String} key - The key to read/store the value from/under
 * @param {*} defaultValue - The default value to use if the key is not set
 * @returns `array` - [value, setValue, remove]
 */
export function useCookie(key, defaultValue) {
  const cookieFunctions = {
    getItem: key => {
      return Cookies.get(key) || defaultValue
    },
    setItem: (key, value) => {
      // Remove from value the apostrophes
      value = value.replace(/['"]+/g, '')
      Cookies.set(key, value, { sameSite: 'strict', path: '/' })
    },
    removeItem: key => {
      Cookies.remove(key)
    },
  }
  return useStorage(key, defaultValue, cookieFunctions)
}

/**
 * Provides a hook to use localStorage or sessionStorage
 * @param {String} key - The key to read/store the value from/under
 * @param {*} defaultValue - The default value to use if the key is not set
 * @param {Storage} storageObject - The storage object to use
 * @returns `array` - [value, setValue, remove]
 */
function useStorage(key, defaultValue, storageObject) {
  // If the storage object is not available, return the default value
  const [value, setValue] = React.useState(() => {
    // Get the value from the storage object
    const jsonValue = storageObject.getItem(key)

    // If the value is set, return it parsed
    if (jsonValue !== null && jsonValue !== undefined) {
      try {
        // Parse stored json if is an object,
        return JSON.parse(jsonValue)
      } catch (e) {
        // or  return stored value as it is
        return jsonValue
      }
    }

    // If the default value is a function, call it and return the result
    if (typeof initialValue === 'function') {
      return defaultValue()
    } else {
      // Otherwise return the default value
      return defaultValue
    }
  })

  const saveValue = React.useCallback(
    newValue => {
      // If the key is not set, do nothing
      if (!key) return

      // Update state optimistically with the new value
      setValue(newValue)

      // If the value is undefined,
      if (newValue === undefined) {
        // remove the key from the storage object
        storageObject.removeItem(key)
      } else {
        // Otherwise set the value in the storage object
        storageObject.setItem(key, JSON.stringify(newValue))
      }
    },
    [key, storageObject]
  )

  // callback to remove the value from the storage object
  const remove = React.useCallback(() => {
    saveValue(undefined)
  }, [saveValue])

  return [value, saveValue, remove]
}

/**
 * Provides a hook to use localStorage with react-query
 * @param {String} key - The key to read/store the value from/under
 * @param {*} defaultValue - The default value to use if the key is not set
 * @returns `array` - [value, setValue, remove]
 */
export function useLocalStorageQuery(key, defaultValue) {
  const queryClient = useQueryClient()

  const { data } = useQuery(
    ['storage', key],
    () => Promise.resolve(localStorage.getItem(key) || defaultValue),
    { enabled: !!key }
  )

  const saveValue = React.useCallback(
    newValue => {
      // If the value is undefined, remove the key from the storage
      if (newValue === undefined) {
        localStorage.removeItem(key)
      } else {
        // Otherwise set the value in the storage
        localStorage.setItem(key, newValue)
      }

      // Invalidate the query to refetch the data
      queryClient.invalidateQueries(['storage', key])
    },
    [key, queryClient]
  )

  const remove = React.useCallback(() => {
    saveValue(undefined)
  }, [saveValue])

  return [data, saveValue, remove]
}
