import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

export const aspectRatios = ['auto', '1/1', '3/4', '4/3', '16/9']

export function useAspectOptions({ includeOptions = [] } = {}) {
  const { t } = useTranslation('web/content-editor')

  return useMemo(() => {
    return aspectRatios.reduce((acc, key) => {
      if (includeOptions.length && !includeOptions.includes(key)) {
        return acc
      }
      return [
        ...acc,
        {
          value: key,
          label: t(`imageAspectRatio-${key}`),
        },
      ]
    }, [])
  }, [includeOptions, t])
}
