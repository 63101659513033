import React from 'react'

import { useTranslation } from 'react-i18next'

import { getArticlesUrl } from '@modules/articles/helpers/urls'
import useArticlesStats from '@modules/articles/services/hooks/useArticlesStats'

const DashboardWidgetWrapper = React.lazy(
  () => import('@modules/dashboard/components/DashboardWidgetWrapper')
)
const Stat = React.lazy(() => import('@modules/dashboard/components/Stat'))

export default function ArticlesStatsWidget() {
  const { t } = useTranslation('articles/public')
  const { stats, loading } = useArticlesStats()

  return (
    <DashboardWidgetWrapper title={t('stats')}>
      <div className="grid grid-cols-2 lg:grid-cols-2 gap-2 grow place-content-center">
        <Stat
          label={t('total')}
          value={stats?.total}
          variant="warn"
          loading={loading}
          url={getArticlesUrl()}
        />
        {/* <Stat
          label={t('published')}
          value={stats?.published}
          variant="success"
          loading={loading}
        /> */}
        <Stat
          label={t('approved')}
          value={stats?.approved}
          variant="primary"
          loading={loading}
          url={getArticlesUrl('', `?status=approved`)}
        />
        <Stat
          label={t('done')}
          value={stats?.done}
          variant="info"
          loading={loading}
          url={getArticlesUrl('', `?status=done`)}
        />
        <Stat
          label={t('draft', { count: stats?.draft })}
          value={stats?.draft}
          loading={loading}
          url={getArticlesUrl('', `?status=draft`)}
        />
      </div>
    </DashboardWidgetWrapper>
  )
}
