import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useState } from 'react'

import isEqual from 'lodash/isEqual'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'

import useEntity from '../../../services/hooks/useEntity'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const config = {
  name: 'ChurchLocation',
  label: 'entities/public:ChurchLocation',
  type: 'plugin',
  icon: 'map-location-dot',
  component: <ChurchLocation />,
}

function getPlaceholder() {
  return {
    location: {
      coordinates: [0, 0],
    },
  }
}

export default function ChurchLocation({ id, dynamic }) {
  const { t } = useTranslation('entities/public')
  const { entity } = useEntity({ id: dynamic ? null : id })

  const { location } = entity || getPlaceholder()

  const [viewport, setViewport] = useState()

  useEffect(() => {
    if (!location?.coordinates) {
      return
    }

    const newViewport = {
      longitude: location?.coordinates[0],
      latitude: location?.coordinates[1],
      zoom: 16,
    }

    if (!isEqual(viewport, newViewport)) {
      setViewport(newViewport)
    }
  }, [location, viewport])

  const locationMarker = useMemo(
    () => [{ coordinates: location.coordinates }],
    [location]
  )

  return (
    <Content icon={config.icon} title={t('ChurchLocation')} locked={dynamic}>
      <Map
        className="h-48"
        markers={locationMarker}
        viewport={viewport}
        markerIcon="church"
        onMove={setViewport}
        showControls
      />
    </Content>
  )
}
ChurchLocation.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
}
ChurchLocation.toolbarItem = config

function ChurchLocationSettings() {
  const { t } = useTranslation('entities/public')

  // const { showMap, showAddress, showAmenities, titleColor } = useBlock(
  //   config.name
  // )

  // const { actions, data } = useNode(node => {
  //   return {
  //     data: node.data.props,
  //   }
  // })

  // const onEntityIdChange = useCallback(
  //   value => {
  //     actions.setProp(props => (props.id = value))
  //   },
  //   [actions]
  // )

  return (
    <SettingsWrap title={t('ChurchLocation')} help={t('ChurchLocationHelp')}>
      <div className="space-y-4">
        {/* <Checkbox
          label={t('dynamic')}
          help={t('dynamicHelp')}
          onChange={value => {
            actions.setProp(props => (props.dynamic = value))
          }}
          value={data.dynamic}
          name="dynamic"
        />
        {!data.dynamic && (
          <EntityPickerField
            label={t('church')}
            onChange={onEntityIdChange}
            value={data.id}
            types={['church']}
          />
        )} */}
      </div>
    </SettingsWrap>
  )
}

ChurchLocation.craft = {
  displayName: 'ChurchLocation',
  props: {
    id: undefined,
    dynamic: false,
  },
  custom: {
    type: config.type,
    resources: ['Entity'],
    i18nNamespaces: ['church-finder'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChurchLocationSettings,
  },
}
