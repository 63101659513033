import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function Empty({
  className = '',
  description,
  extra,
  icon = 'inbox',
  id,
  label = 'Empty',
  secondaryIcon,
}) {
  return (
    <div className={`flex flex-col items-center gap-3 px-10 py-6 ${className}`}>
      <div className="relative text-6xl text-gray-300">
        <Icon name={icon} />

        {icon === 'inbox' && secondaryIcon && (
          <div className="absolute left-1/2 -top-0.5 -translate-x-1/2 rounded-full bg-white p-1 text-2xl text-gray-300">
            <Icon name={secondaryIcon} />
          </div>
        )}
      </div>
      <div className="flex flex-col gap-1 text-center text-gray-400">
        <p className="font-medium ">{label}</p>
        {description && <p className="text-xs">{description}</p>}
        {id && (
          <p className="text-xs font-mono">
            <span className="font-semibold">ID:</span> {id}
          </p>
        )}
        {extra}
      </div>
    </div>
  )
}
Empty.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  extra: PropTypes.node,
  icon: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  secondaryIcon: PropTypes.string,
}
