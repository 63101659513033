import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import useCollection from '../../services/hooks/useCollection'
import ChannelSelect from '../ChannelSelect'
import CollectionSelect from '../CollectionSelect'
import CollectionItem from '../shared/CollectionItem'

const config = {
  name: 'CollectionTeaser',
  label: 'media-library/public:collectionTeaser',
  type: 'plugin',
  icon: 'bars',
  component: <CollectionTeaser />,
}

export default function CollectionTeaser({ channelId, collectionId }) {
  const { t } = useTranslation('media-library/public')
  const showContent = false

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { collection } = useCollection(collectionId)

  const _channel = resources?.Channel ?? channel

  return (
    <Content icon={config.icon} title={t('collectionTeaser')}>
      {showContent ? (
        <CollectionItem collection={collection} />
      ) : (
        <Box space="xs">
          <Heading as="h6" className="ml-2" text={collection?.title} />
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('collection')}: {collection?.title || 'n/a'}
            </li>
          </ul>
        </Box>
      )}
    </Content>
  )
}
CollectionTeaser.propTypes = {
  channelId: PropTypes.string,
  collectionId: PropTypes.string,
}
CollectionTeaser.defaultProps = {}
CollectionTeaser.toolbarItem = config

function CollectionTeaserSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { collection } = useCollection(data.collectionId)

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  const onCollectionUpdate = useCallback(
    (fieldName, parser = x => x) =>
      collection => {
        actions.setProp(props => (props[fieldName] = parser(collection.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('collectionTeaser')}
      help={t('collectionTeaserHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Collection && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        <Box space="lg">
          {!resources?.Channel && (
            <ChannelSelect
              label={t('channel')}
              onChange={onChannelUpdate('channelId')}
              value={data.channelId}
              controlled={false}
            />
          )}
          <CollectionSelect
            channelId={data.channelId || resources?.Channel?.id}
            label={t('collection')}
            onChange={onCollectionUpdate('collectionId')}
            value={data.collectionId}
            controlled={false}
          />
        </Box>
        <Input
          label={t('maxItems')}
          help={t('maxItemsHelp')}
          onChange={onFieldUpdate('limit', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.limit}
          type="number"
        />
        <Checkbox
          label={t('hideTitle')}
          onChange={value => {
            actions.setProp(props => (props.hideTitle = value))
          }}
          value={data.hideTitle}
          name="hideTitle"
        />
        <Checkbox
          name="enableAnimations"
          label={t('enableAnimations')}
          help={t('enableAnimationsCardsPostersHelp')}
          value={data.enableAnimations}
          onChange={checked =>
            actions.setProp(p => (p.enableAnimations = checked))
          }
        />
        <PagePicker
          label={t('collectionDetailPageId')}
          help={t('collectionDetailPageIdHelp')}
          value={data.collectionDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.collectionDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Collection'
          }
        />
        {collection?.contentType === 'shows' && (
          <PagePicker
            label={t('showDetailPageId')}
            help={t('showDetailPageIdHelp')}
            value={data.showDetailPageId}
            onChange={pageId =>
              actions.setProp(props => (props.showDetailPageId = pageId))
            }
            condition={page =>
              page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
            }
          />
        )}
        {collection?.contentType === 'episodes' && (
          <PagePicker
            label={t('episodeDetailPageId')}
            help={t('episodeDetailPageIdHelp')}
            value={data.episodeDetailPageId}
            onChange={pageId =>
              actions.setProp(props => (props.episodeDetailPageId = pageId))
            }
            condition={page =>
              page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
            }
          />
        )}
      </Box>
    </SettingsWrap>
  )
}

CollectionTeaser.craft = {
  displayName: 'CollectionTeaser',
  props: {
    channelId: undefined,
    collectionId: undefined,
    collectionDetailPageId: undefined,
    showDetailPageId: undefined,
    episodeDetailPageId: undefined,
    hideTitle: false,
    limit: 4,
  },
  custom: {
    type: config.type,
    resources: ['collectionTeaser'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CollectionTeaserSettings,
  },
}
