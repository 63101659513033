import PropTypes from 'prop-types'
import React from 'react'

import { FloatingTree, useFloatingParentNodeId } from '@floating-ui/react'

import DropdownDivider from './Divider'
import DropdownItem from './Item'
import DropdownMenu from './Menu'

const Dropdown = React.forwardRef(({ className = '', ...props }, ref) => {
  const parentId = useFloatingParentNodeId()

  if (parentId === null) {
    return (
      <FloatingTree>
        <DropdownMenu className={className} {...props} ref={ref} />
      </FloatingTree>
    )
  }

  return <DropdownMenu {...props} ref={ref} />
})
Dropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.node,
  icon: PropTypes.string,
  children: PropTypes.node,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  pressed: PropTypes.bool,
  variant: PropTypes.string,
}

Dropdown.Item = DropdownItem
Dropdown.Item.displayName = 'Dropdown.Item'

Dropdown.Divider = DropdownDivider
Dropdown.Divider.displayName = 'Dropdown.Divider'

export { DropdownItem, DropdownDivider }

export default Dropdown
