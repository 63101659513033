import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'
import { dateTimelocales } from '@ui/helpers/datetime'
import { formatDate } from '@utils/datetime'

import useSetUserLanguage from '../helpers/useSetUserLanguage'

const dateTimeLocaleKeys = Object.keys(dateTimelocales)

export default function DateTimeLocaleSelect({ name = 'dateTimeLocale' }) {
  const { t } = useTranslation('auth')
  const { language } = useSetUserLanguage()

  const options = useMemo(
    () =>
      dateTimeLocaleKeys.map(locale => {
        return {
          value: locale,
          label: `${formatDate('2024-07-15T18:15:00.000', 'Pp', { locale })} (${locale})`,
        }
      }),
    []
  )

  const defaultValue =
    dateTimeLocaleKeys.find(locale => locale === language) ?? 'en'

  return (
    <Select label={t('dateTimeLocale')} name={name} value={defaultValue}>
      {options.map(({ value, label }) => (
        <Select.Option key={value} label={label} value={value} />
      ))}
    </Select>
  )
}
DateTimeLocaleSelect.propTypes = {
  name: PropTypes.string,
}
