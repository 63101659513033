import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { useUILanguage } from '@config/i18n'
import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  enabled: true,
  fromParams: true,
  translate: false,
}

function translateField(field, language) {
  if (!field) return ''
  return field[language] || field.en || ''
}

export default function useCategory(categoryId, options = defaultOptions) {
  const { enabled, fromParams, translate } = Object.assign(
    {},
    defaultOptions,
    options
  )
  const { language } = useUILanguage()
  const params = useParams()
  const id = categoryId || (fromParams ? params.categoryId : null)

  const { data, error, isLoading } = useQuery(
    ['categories', id],
    () => getFetch(`/api/categories/${id}`),
    { enabled: !!id && enabled }
  )

  const category = data
    ? translate
      ? {
          ...data,
          title: translateField(data.title, language),
          description: translateField(data.description, language),
          ancestors: data.ancestors?.map?.(ancestor => ({
            ...ancestor,
            title: translateField(ancestor.title, language),
            description: translateField(ancestor.description, language),
          })),
        }
      : data
    : null

  return { category, error, loading: isLoading }
}
