import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import Tooltip from '@ui/feedback/Tooltip'
import Link from '@ui/navigation/Link'

import { useButtonClasses } from './hooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

/**
 * Button component
 * @param {object} props
 * @param {string} [props.as] - Render as a different element (default: button)
 * @param {boolean} [props.asLink] - Render as a link
 * @param {React.ReactNode} [props.children] - Button content
 * @param {string} [props.className] - Additional classes
 * @param {boolean} [props.disabled] - Disable button
 * @param {string} [props.href] - Link href
 * @param {string} [props.icon] - Icon name
 * @param {string} [props.iconClassName] - Icon classes
 * @param {string} [props.iconStyle] - Icon style (default: far, options: fas, fab)
 * @param {object} [props.innerRef] - Inner ref
 * @param {React.ReactNode} [props.label] - Button label
 * @param {boolean} [props.loading] - Show loading spinner
 * @param {string} [props.name] - Button name
 * @param {function} [props.onClick] - Click handler
 * @param {function} [props.onMouseDown] - Mouse down handler
 * @param {boolean} [props.pressed] - Button pressed
 * @param {string} [props.size] - Button size
 * @param {string} [props.secondaryIcon] - Secondary icon name
 * @param {string} [props.target] - Link target
 * @param {string} [props.tooltip] - Button tooltip
 * @param {string} [props.tooltipSize] - Tooltip size
 * @param {string} [props.type] - Button type
 * @param {any} [props.value] - Button value
 * @param {ai|basic|primary|primary-light|success|success-light|info|favorite|favorite-light|warn|danger|danger-light|flat|flat-primary|flat-success|flat-warn|flat-danger|flat-info|flat-favorite|flat-light|light} [props.variant] - Button variant
 */
const Button = forwardRef(function Button(
  {
    as = 'button',
    asLink,
    children,
    className = '',
    disabled,
    href,
    icon,
    iconClassName,
    iconStyle,
    innerRef,
    label,
    linkClass = '',
    loading,
    name,
    onClick,
    onMouseDown,
    pressed,
    secondaryIcon,
    size = 'md',
    target,
    tooltip,
    tooltipClass = '',
    tooltipPlacement,
    tooltipSize,
    type = 'button',
    value,
    variant,
    ...rest
  },
  ref
) {
  const buttonClasses = useButtonClasses({
    asLink,
    disabled,
    pressed,
    size,
    variant,
  })

  return (
    <Link.Conditional
      href={href}
      basic={false}
      target={target}
      className={linkClass}
    >
      {React.createElement(
        asLink ? 'span' : as,
        {
          className,
          disabled,
          onClick,
          onMouseDown,
          name,
          type,
          ref: ref ?? innerRef,
          // title: tooltip,
          value,
          ...rest,
        },
        <Tooltip
          content={tooltip}
          size={tooltipSize || size || 'sm'}
          placement={tooltipPlacement}
          className={tooltipClass}
        >
          <div
            className={`inline-flex select-none flex-row items-center justify-center whitespace-nowrap transition-all duration-300 ease-in-out ${buttonClasses}`}
          >
            {typeof icon === 'string' || Array.isArray(icon) ? (
              <Icon
                name={loading ? 'spinner' : icon}
                className={iconClassName}
                spin={loading}
                style={iconStyle}
              />
            ) : (
              icon
            )}
            {(label || children) && (
              <span className="font-semibold">{label ?? children}</span>
            )}
            {secondaryIcon && (
              <span className="scale-75 transform opacity-70">
                <Icon name={secondaryIcon} className={iconClassName} />
              </span>
            )}
          </div>
        </Tooltip>
      )}
    </Link.Conditional>
  )
})
Button.propTypes = {
  asLink: PropTypes.bool,
  as: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  icon: PropTypes.node,
  iconStyle: PropTypes.oneOf(['fab', 'far', 'fas']),
  iconClassName: PropTypes.string,
  innerRef: PropTypes.object,
  label: PropTypes.node,
  linkClass: PropTypes.string,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
  pressed: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  secondaryIcon: PropTypes.string,
  target: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipClass: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  tooltipSize: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  value: PropTypes.any,
  variant: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.oneOf([
      'ai',
      'basic',
      'primary',
      'primary-light',
      'success',
      'success-light',
      'info',
      'favorite',
      'favorite-light',
      'warn',
      'danger',
      'danger-light',
      'flat',
      'flat-primary',
      'flat-success',
      'flat-warn',
      'flat-danger',
      'flat-info',
      'flat-favorite',
      'flat-light',
      'light',
    ]),
  ]),
}

export default Button
