import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Message from '@ui/data-display/Message'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'

import useChannels from '../../services/hooks/useChannels'
import ChannelSelect from '../ChannelSelect'

const Map = React.lazy(() => import('@ui/data-display/Map'))

const MAP_TOKEN = import.meta.env.VITE_MAP_TOKEN

const config = {
  name: 'ChannelsMap',
  label: 'media-library/public:channelsMap',
  help: 'media-library/public:channelsMapHelp',
  type: 'plugin',
  icon: 'map-marked-alt',
  component: <ChannelsMap />,
}

export default function ChannelsMap() {
  const { channels } = useChannels()

  return (
    <Content icon={config.icon} title={config.label}>
      <Map
        token={MAP_TOKEN}
        markers={channels.map(({ mapPoint }) => mapPoint)}
        showControls
        zoom={0.5}
        height={440}
      />
    </Content>
  )
}
ChannelsMap.propTypes = {}
ChannelsMap.toolbarItem = config

function ChannelsMapSettings() {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onTextUpdate = useCallback(
    fieldName => e => {
      actions.setProp(props => (props[fieldName] = e.target.value))
    },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.Channel ? (
          <Message className="text-sm" type="warn">
            {resources.Channel.title}
          </Message>
        ) : (
          <ChannelSelect
            label={t('channel')}
            onChange={channel => {
              actions.setProp(props => (props.channelId = channel.id))
            }}
            value={data.channelId}
            controlled={false}
          />
        )}
        <Input
          label={t('accessToken')}
          help={t('accessTokenHelp')}
          onChange={onTextUpdate('accessToken')}
          value={data.accessToken}
        />
      </Box>
    </SettingsWrap>
  )
}

ChannelsMap.craft = {
  displayName: 'ChannelsMap',
  props: {
    channelId: null,
    accessToken: null,
  },
  custom: {
    type: config.type,
    resources: ['channels', 'channel'],
    i18nNamespaces: ['channels-map'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ChannelsMapSettings,
  },
}
