import { createContext, useContext } from 'react'

export const TranslationSourceTextContext = createContext()
export const TranslationSourceContext = createContext()
export const TranslationTargetContext = createContext()

export function useTranslationSource() {
  return useContext(TranslationSourceContext)
}

export function useTranslationTarget() {
  return useContext(TranslationTargetContext)
}

export function useTranslationSourceText() {
  return useContext(TranslationSourceTextContext)
}
