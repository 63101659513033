import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'
import Clickable from '@ui/helpers/Clickable'

const Icon = React.lazy(() => import('@ui/icons/Icon'))
export function SelectOption({
  disabled,
  extra,
  image,
  label,
  onClick,
  selected,
}) {
  return (
    <Clickable
      className={`flex items-center gap-4 relative px-3 py-1 ${
        selected ? 'bg-primary-50' : 'hover:bg-gray-100'
      } ${disabled ? '' : 'cursor-pointer'} flex items-center`}
      onClick={disabled ? undefined : onClick}
    >
      {image && (
        <div className="pr-2 text-sm shrink-0">
          <Image alt={label} src={image} />
        </div>
      )}
      <div className="flex-1">{label}</div>
      {extra && (
        <div className="absolute top-0 right-0 mr-10 flex h-full w-16 items-center font-mono text-sm text-gray-400">
          {extra}
        </div>
      )}
      {selected && (
        <div className="text-sm text-primary-500 shrink-0">
          <Icon name="check" />
        </div>
      )}
    </Clickable>
  )
}
SelectOption.propTypes = {
  disabled: PropTypes.bool,
  extra: PropTypes.string,
  image: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  selected: PropTypes.bool,
}
