import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'

/**
 * Component to render the fields of a header block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function CategoriesListTranslation({ id, source }) {
  const { t } = useTranslation('categories')

  const fields = useMemo(
    () => ({
      title: { label: t('title') },
    }),
    [t]
  )

  return <BlockTranslation id={id} fields={fields} source={source} />
}
CategoriesListTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
