import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Input from '@ui/data-entry/Input'
import Form from '@ui/data-entry/Form'
import Message, { ErrorMessage } from '@ui/data-display/Message'
import Password from '@ui/data-entry/Password'
import Section from '@ui/data-display/Section'
import Submit from '@ui/data-entry/Submit'

import { useUpdatePassword } from '../services/AuthService'

export default function ChangePasswordForm({ user }) {
  const { t } = useTranslation('auth')
  const data = useMemo(
    () => ({ currentPassword: '', newPassword: '', token: '' }),
    []
  )
  const [isSuccess, setIsSuccess] = useState(false)
  const { mutate, isError, isLoading, error } = useUpdatePassword()
  const { twoFactorEnabled } = user

  const handleUpdate = data => {
    mutate(data, {
      onSuccess: () => {
        setIsSuccess(true)
        setTimeout(() => {
          setIsSuccess(false)
        }, 3000)
      },
    })
  }

  return (
    <Form data={data} resetOnSubmit onSubmit={handleUpdate}>
      <Section.Body>
        {isSuccess && (
          <Message text={t('changePasswordSuccess')} type="success" />
        )}
        {isError && <ErrorMessage error={error} />}

        <Password
          name="currentPassword"
          label={t('currentPassword')}
          help={t('currentPasswordHelp')}
          required
          validatePattern={false}
        />
        <Password name="newPassword" label={t('newPassword')} required />
        {twoFactorEnabled && (
          <Input
            name="token"
            label={t('token')}
            help={t('tokenHelp')}
            required
          />
        )}
      </Section.Body>
      <Section.Footer>
        <Submit
          label={t(isLoading ? 'changing' : 'change')}
          submitting={isLoading}
          reverse
        />
      </Section.Footer>
    </Form>
  )
}
ChangePasswordForm.propTypes = {
  user: PropTypes.object.isRequired,
}
