import { useLocation, useParams } from 'react-router-dom'

const baseUrl = '/persons'

export function getPersonsUrl(params = '') {
  return `${baseUrl}${params}`
}

export function getAddPersonUrl(params = '') {
  return `${baseUrl}/add${params}`
}

export function getPersonUrl(personId, params = '') {
  return `${baseUrl}/${personId}/details${params}`
}

export function getEditPersonUrl(personId, params = '') {
  return `${baseUrl}/${personId}/edit${params}`
}

export function usePersonsUrls({ id = null } = {}) {
  const { search: searchQuery } = useLocation()

  const params = useParams()

  const personId = id || params?.personId

  const personsUrls = {
    personsUrl: getPersonsUrl(searchQuery),
    addPersonUrl: getAddPersonUrl(searchQuery),
    personUrl: null,
    editPersonUrl: null,
  }

  if (personId) {
    personsUrls.personUrl = getPersonUrl(personId, searchQuery)
    personsUrls.editPersonUrl = getEditPersonUrl(personId, searchQuery)
  }

  return personsUrls
}
