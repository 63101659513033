import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'
import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import useChannel from '../../services/hooks/useChannel'
import useShow from '../../services/hooks/useShow'
import ChannelSelect from '../ChannelSelect'
import { ShowPicker } from '../ShowPicker'
import AudioPlayerVariant from '../shared/AudioPlayerVariant'

const config = {
  name: 'ShowAudioPlaylist',
  label: 'media-library/public:showAudioPlaylist',
  type: 'plugin',
  icon: 'headphones',
  component: <ShowAudioPlaylist />,
}

export default function ShowAudioPlaylist({ channelId, id }) {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)
  const { show } = useShow(id)

  const _channel = resources?.Channel ? resources.Channel : channel
  const _show = resources?.Show ? resources.Show : show

  return (
    <Content icon={config.icon} title={t('showAudioPlaylist')}>
      <Box space="xs">
        <ul className="ml-8 list-disc text-sm">
          <li>
            {t('channel')}: {_channel?.title}
          </li>
          <li>
            {t('show')}: {_show?.title || t('dynamic')}
          </li>
        </ul>
        <div className="aspect-w-16 aspect-h-9 w-full border bg-gray-100">
          <div className="flex h-full w-full items-center justify-center text-gray-500">
            Audio player
          </div>
        </div>
        [Show audio playlist]
      </Box>
    </Content>
  )
}
ShowAudioPlaylist.propTypes = {
  channelId: PropTypes.string,
  id: PropTypes.string,
}
ShowAudioPlaylist.defaultProps = {}
ShowAudioPlaylist.toolbarItem = config

function ShowAudioPlaylistSettings() {
  const { t } = useTranslation(['media-library/public', 'media-library/shows'])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.channelId || data.id)

  const { resources } = usePageResources()

  const onShowIdChange = useCallback(
    value => {
      actions.setProp(props => (props.showId = value))
    },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('showAudioPlaylist')}
      help={t('showAudioPlaylistHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        {resources?.Show && (
          <Message className="text-sm" type="warn">
            {resources?.Show?.title}
          </Message>
        )}
        <AudioPlayerVariant />

        <Checkbox
          label={t('showPlaylistAbstract')}
          onChange={value => {
            actions.setProp(props => (props.showPlaylistAbstract = value))
          }}
          value={data.showPlaylistAbstract}
          name="showPlaylistAbstract"
        />
        <Checkbox
          label={t('scrollPlaylist')}
          help={t('scrollPlaylistHelp')}
          onChange={value => {
            actions.setProp(props => (props.scrollPlaylist = value))
          }}
          value={data.scrollPlaylist}
          name="scrollPlaylist"
        />
        {data.scrollPlaylist && (
          <Select
            name="scrollAreaSize"
            label={t('scrollAreaSize')}
            onChange={e => {
              actions.setProp(props => (props.scrollAreaSize = e.target.value))
            }}
            value={data.scrollAreaSize}
          >
            {data.scrollAreaSize && (
              <SelectOption label={t('clearSelection')} value={null} />
            )}
            <SelectPlaceholder label="-" />
            <SelectOption label={t(`scrollAreaSize-sm`)} value="sm" />
            <SelectOption label={t(`scrollAreaSize-md`)} value="md" />
            <SelectOption label={t(`scrollAreaSize-lg`)} value="lg" />
          </Select>
        )}

        <Select
          label={t('sortOrder')}
          value={data.sortOrder}
          onChange={e => {
            actions.setProp(props => (props.sortOrder = e.target.value))
          }}
        >
          {!data.sortOrder && (
            <SelectPlaceholder
              label={
                resources?.Show?.episodeSorting === 'asc'
                  ? t('media-library/shows:episodeSortingAscending')
                  : t('media-library/shows:episodeSortingDescending')
              }
            />
          )}
          {data.sortOrder && (
            <SelectOption label={t('clearSelection')} value={null} />
          )}
          <SelectPlaceholder label="-" />
          <SelectOption
            value="desc"
            label={t('media-library/shows:episodeSortingDescending')}
          />
          <SelectOption
            value="asc"
            label={t('media-library/shows:episodeSortingAscending')}
          />
        </Select>
        <InputField
          label={t('limit')}
          help={t('limitHelp')}
          onChange={e => {
            const { value } = e.target
            actions.setProp(
              props =>
                (props.limit = parseInt(isNaN(value) || value < 0 ? 0 : value))
            )
          }}
          value={data.limit}
          type="number"
        />

        <CollapsablePanel title={t('playerOptions')}>
          <Checkbox
            label={t('showImage')}
            onChange={value => {
              actions.setProp(props => (props.showImage = value))
            }}
            value={data.showImage}
            name="showImage"
          />
          <Checkbox
            label={t('showTitle')}
            onChange={value => {
              actions.setProp(props => (props.showTitle = value))
            }}
            value={data.showTitle}
            name="showTitle"
          />
          <Checkbox
            label={t('showSubtitle')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showSubtitle}
            name="showSubtitle"
          />
          <Checkbox
            label={t('showKicker')}
            onChange={value => {
              actions.setProp(props => (props.showKicker = value))
            }}
            value={data.showKicker}
            name="showKicker"
          />
          <Checkbox
            label={t('showAbstract')}
            onChange={value => {
              actions.setProp(props => (props.showAbstract = value))
            }}
            value={data.showAbstract}
            name="showAbstract"
          />
          <Checkbox
            label={t('showDescription')}
            onChange={value => {
              actions.setProp(props => (props.showDescription = value))
            }}
            value={data.showDescription}
            name="showDescription"
          />
          <Checkbox
            label={t('showEpisodeLink')}
            help={t('showEpisodeLinkHelp')}
            onChange={value => {
              actions.setProp(props => (props.showEpisodeLink = value))
            }}
            value={data.showEpisodeLink}
            name="showEpisodeLink"
          />
        </CollapsablePanel>
        {!resources?.Show && (
          <>
            <Divider />
            <Checkbox
              label={t('pickShowManually')}
              help={t('pickShowManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.Channel && (
                  <ChannelSelect
                    label={t('channel')}
                    onChange={onChannelUpdate('channelId')}
                    value={data.channelId}
                    controlled={false}
                  />
                )}
                <ShowPicker
                  channelId={data.channelId || resources?.Channel?.id}
                  onChange={onShowIdChange}
                  value={data.showId}
                />
              </Box>
            )}
          </>
        )}
        <Divider />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

ShowAudioPlaylist.craft = {
  displayName: 'ShowAudioPlaylist',
  props: {
    channelId: undefined,
    id: undefined,
    showImage: true,
    showKicker: true,
    showTitle: true,
    showSubtitle: true,
    showAbstract: true,
    showDescription: true,
    showEpisodeLink: true,
    showPlaylistAbstract: true,
    scrollPlaylist: true,
    scrollAreaSize: undefined,
  },
  custom: {
    type: config.type,
    resources: ['showAudioPlaylist', 'show'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ShowAudioPlaylistSettings,
  },
}
