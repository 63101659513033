import React from 'react'

import { useTranslation } from 'react-i18next'

import PagePicker from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Input from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'

export default function ShowsFeedFilters() {
  const { t } = useTranslation('media-library/public')

  return (
    <Box space="xl">
      <PagePicker
        name="xml[settings][showDetailPageId]"
        label={t('showDetailPageId')}
        help={t('showDetailPageIdHelp')}
        condition={page =>
          page.dynamic && getDynamicResourceIdentifier(page) === 'Show'
        }
      />
      <PagePicker
        name="xml[settings][episodeDetailPageId]"
        label={t('episodeDetailPageId')}
        help={t('episodeDetailPageIdHelp')}
        condition={page =>
          page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
        }
      />
      <Input
        className="w-full md:w-1/2"
        name="xml[settings][limit]"
        label={t('limit')}
        help={t('limitHelp')}
        type="number"
      />
    </Box>
  )
}
