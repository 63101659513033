import PropTypes from 'prop-types'
import React from 'react'

import Link from '@ui/navigation/Link'

export default function PublicationListItem({ publication }) {
  return (
    <div className="flex flex-row-reverse space-x-4 space-x-reverse">
      <div className="flex-grow space-y-0">
        <Link href={publication.url}>{publication.title}</Link>
      </div>
    </div>
  )
}
PublicationListItem.propTypes = {
  publication: PropTypes.object.isRequired,
}
