import PropTypes from 'prop-types'
import React, { Fragment, useCallback } from 'react'

import { Dialog, Transition } from '@headlessui/react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CloseButton from '@ui/buttons/CloseButton'
import Heading from '@ui/typography/Heading'

import ModalActions from './components/Actions'
import { useModal } from './hooks'

const modalSizes = {
  sm: 'max-w-xs',
  md: 'max-w-lg',
  lg: 'max-w-2xl',
  xl: 'max-w-6xl',
}

export default function Modal({
  acceptDisabled,
  acceptLabel,
  acceptVariant,
  actions,
  cancelLabel,
  canClose,
  children,
  childrenWrapAs,
  className,
  onAccept,
  onCancel,
  showAccept,
  showCancel,
  size,
  title,
}) {
  const { t } = useTranslation()
  const { open, onClose, focusRef } = useModal()

  const sizeClasses = modalSizes[size] ?? ''

  const onModalAccept = useCallback(() => {
    if (canClose) onClose()
    if (typeof onAccept === 'function') onAccept()
  }, [canClose, onAccept, onClose])

  const onModalClose = useCallback(() => {
    if (canClose) onClose()
    if (typeof onCancel === 'function') onCancel()
  }, [canClose, onCancel, onClose])

  return (
    <Transition appear as={Fragment} show={open}>
      <Dialog onClose={onModalClose} initialFocus={focusRef}>
        <div className="fixed inset-0 z-50 overflow-y-auto">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black/30" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="z-20 flex min-h-screen min-w-full items-center justify-center">
              <Dialog.Panel
                className={`z-30 flex min-h-screen min-w-full transform flex-col justify-between rounded bg-white shadow-lg md:min-h-full md:min-w-[320px] ${sizeClasses} ${className}`}
              >
                {title && (
                  <Dialog.Title
                    as="div"
                    className="flex flex-row items-center justify-between border-b px-6 py-5"
                  >
                    <Heading as="h4">{title}</Heading>
                    <CloseButton onClick={onModalClose} />
                  </Dialog.Title>
                )}
                <Dialog.Description
                  className="overflow-y-auto px-6 py-5"
                  as={childrenWrapAs}
                >
                  {typeof children === 'function'
                    ? children({ open, onClose: onModalClose })
                    : children}
                </Dialog.Description>
                {(typeof actions === 'function' ||
                  showAccept ||
                  showCancel) && (
                  <ModalActions>
                    {typeof actions === 'function' ? (
                      actions({ onClose: onModalClose, focusRef })
                    ) : (
                      <>
                        {(showCancel || onCancel) && (
                          <Button
                            label={cancelLabel || t('cancel')}
                            onClick={onModalClose}
                          />
                        )}

                        {showAccept && (
                          <Button
                            label={acceptLabel || t('ok')}
                            onClick={onModalAccept}
                            variant={acceptVariant}
                            disabled={acceptDisabled}
                            innerRef={focusRef}
                          />
                        )}
                      </>
                    )}
                  </ModalActions>
                )}
              </Dialog.Panel>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}
Modal.propTypes = {
  /** Render function to provide a xustom set of actions */
  actions: PropTypes.func,
  /** When `true`, disables the "Accept" button */
  acceptDisabled: PropTypes.bool,
  /** Label for the default "Accept" button */
  acceptLabel: PropTypes.string,
  /** Variant for the default "Accept" button */
  acceptVariant: PropTypes.oneOf(['primary', 'warn', 'danger', 'default']),
  /** Label for the default "Cancel" button */
  cancelLabel: PropTypes.string,
  /** Defines if modal can close */
  canClose: PropTypes.bool,
  /** Modal's main contents */
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  /** Sets how children's wrapper will render */
  childrenWrapAs: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  /** Modal box's extra classes (not for the overlay) */
  className: PropTypes.string,
  /** Function to be called when the default "Accept" button is pressed */
  onAccept: PropTypes.func,
  /** Function to be called when the default "Cancel" button is pressed */
  onCancel: PropTypes.func,
  /** Indicates if the "Accept" button must be displayed */
  showAccept: PropTypes.bool,
  /** Indicates if the "Cancel" button must be displayed (same effect as settting `onCancel` prop) */
  showCancel: PropTypes.bool,
  /** Contols Modal box's max witdh */
  size: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
  /** Modal's title */
  title: PropTypes.string,
}
Modal.defaultProps = {
  acceptVariant: 'primary',
  canClose: true,
  childrenWrapAs: 'div',
  showAccept: true,
}
