import PropTypes from 'prop-types'
import React, { useCallback, useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useBlock } from '@modules/web/helpers/useBlock'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'

import { PublicationPicker } from '../PublicationPicker'
import VariantSelect from './shared/VariantSelect'

const config = {
  name: 'PublicationStatistics',
  label: 'publications/public:PublicationStatistics',
  type: 'plugin',
  icon: 'file-invoice',
  component: <PublicationStatistics />,
}

export default function PublicationStatistics({
  dynamic,
  showLanguages,
  showDownloadCount,
  publicationLabel,
  languagesLabel,
  downloadsLabel,
}) {
  const { t } = useTranslation('publications/public')

  return (
    <Content
      icon={config.icon}
      title={t('PublicationStatistics')}
      locked={dynamic}
    >
      <div className="flex flex-col p-4 gap-4">
        <p>{`[#] ${publicationLabel}`}</p>
        {showLanguages && <p>{`[#] ${languagesLabel}`}</p>}
        {showDownloadCount && <p>{`[#] ${downloadsLabel}`}</p>}
      </div>
    </Content>
  )
}
PublicationStatistics.propTypes = {
  dynamic: PropTypes.bool,
  id: PropTypes.string,
  publicationLabel: PropTypes.string,
  languagesLabel: PropTypes.string,
  downloadsLabel: PropTypes.string,
  showLanguages: PropTypes.bool,
  showDownloadCount: PropTypes.bool,
}
PublicationStatistics.defaultProps = {}
PublicationStatistics.toolbarItem = config

function PublicationStatisticsSettings() {
  const { t } = useTranslation('publications/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  // Checks if the desing is set to show languages and download count
  const { showLanguages, showDownloadCount, showPublications, showPicker } =
    useBlock(config.name)

  const onPublicationIdChange = useCallback(
    value => {
      actions.setProp(props => (props.publicationId = value))
    },
    [actions]
  )
  return (
    <SettingsWrap
      title={t('PublicationStatistics')}
      help={t('PublicationStatisticsHelp')}
    >
      <div className="space-y-4">
        {/* <CollapsablePanel defaultOpen title={t('general')}>
          <p>Placeholder for general</p>
        </CollapsablePanel> */}

        <CollapsablePanel title={t('labels')}>
          <Input
            name="publicationLabel"
            label={t('publicationLabel')}
            help={t('publicationLabelHelp')}
            placeholder={t('publicationLabel')}
            onChange={e => {
              actions.setProp(
                props => (props.publicationLabel = e.target.value)
              )
            }}
            value={data.publicationLabel}
          />
          <Input
            name="languagesLabel"
            label={t('languagesLabel')}
            help={t('languagesLabelHelp')}
            placeholder={t('languagesLabel')}
            onChange={e => {
              actions.setProp(props => (props.languagesLabel = e.target.value))
            }}
            value={data.languagesLabel}
          />
          <Input
            name="downloadsLabel"
            label={t('downloadsLabel')}
            help={t('downloadsLabelHelp')}
            placeholder={t('downloadsLabel')}
            onChange={e => {
              actions.setProp(props => (props.downloadsLabel = e.target.value))
            }}
            value={data.downloadsLabel}
          />
        </CollapsablePanel>

        <CollapsablePanel title={t('displayOptions')}>
          {showPicker && (
            <PublicationPicker
              onChange={onPublicationIdChange}
              help={t('publicationHelp')}
              value={data.publicationId}
            />
          )}
          {showPublications && (
            <Checkbox
              name="showPublications"
              label={t('showPublications')}
              onChange={value => {
                actions.setProp(props => (props.showPublications = value))
              }}
              value={data.showPublications}
            />
          )}
          {showLanguages && (
            <Checkbox
              name="showLanguages"
              label={t('showLanguages')}
              onChange={value => {
                actions.setProp(props => (props.showLanguages = value))
              }}
              value={data.showLanguages}
            />
          )}
          {showDownloadCount && (
            <Checkbox
              name="showDownloadCount"
              label={t('showDownloadCount')}
              onChange={value => {
                actions.setProp(props => (props.showDownloadCount = value))
              }}
              value={data.showDownloadCount}
            />
          )}

          <VariantSelect />
        </CollapsablePanel>
      </div>
    </SettingsWrap>
  )
}

PublicationStatistics.craft = {
  displayName: 'PublicationStatistics',
  props: {
    id: undefined,
    dynamic: false,
    showPublications: true,
    showLanguages: true,
    showDownloadCount: true,
  },
  custom: {
    type: config.type,
    resources: ['publicationStatistics'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationStatisticsSettings,
  },
}

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function PublicationStatisticsTranslation({ id, source }) {
  const { t } = useTranslation('publications/public')

  const fields = useMemo(
    () => ({
      publicationLabel: { label: t('publicationLabel') },
      languagesLabel: { label: t('languagesLabel') },
      downloadsLabel: { label: t('downloadsLabel') },
    }),
    [t]
  )

  return (
    <BlockTranslation
      id={id}
      source={source}
      fields={fields}
      i18Namespace="publications/public"
    />
  )
}
PublicationStatisticsTranslation.propTypes = {
  id: PropTypes.string.isRequired,
  source: PropTypes.object.isRequired,
}
