import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'

import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
} from '@floating-ui/react'
import { Transition } from '@headlessui/react'

import { usePopoverContext } from '../context'
import { PopoverArrow } from './PopoverArrow'

export const PopoverContent = forwardRef(
  function PopoverContent(props, propRef) {
    const popover = usePopoverContext()

    const {
      refs,
      open,
      context,
      modal,
      floatingStyles,
      getFloatingProps,
      hideArrow,
    } = popover

    const ref = useMergeRefs([refs.setFloating, propRef])

    if (!open) return null

    return (
      <FloatingPortal>
        <FloatingFocusManager context={context} modal={modal}>
          <Transition
            show={open}
            enter="transition-opacity"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div
              className="z-max block min-w-[20rem] max-w-lg cursor-default rounded-lg border bg-white p-4 shadow-lg focus-visible:outline-none"
              ref={ref}
              style={{ ...floatingStyles }}
              {...getFloatingProps(props)}
            >
              {typeof props.children === 'function'
                ? props.children(popover)
                : props.children}
              {!hideArrow && <PopoverArrow />}
            </div>
          </Transition>
        </FloatingFocusManager>
      </FloatingPortal>
    )
  }
)

PopoverContent.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}
