import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { DateTimeField as DateTime } from '@ui/data-entry/DateTime'
import { InputField as Input } from '@ui/data-entry/Input'

import { useDesignContext } from '../../DesignProvider'
import FlexOptions from './FlexOptions'
import GridItemOptions from './GridItemOptions'

export default function SharedSettings() {
  const design = useDesignContext()
  const { t } = useTranslation('web/content-editor')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  return (
    <>
      {design?.blocks?.Grid?.showGridOptions && <GridItemOptions />}
      {design?.blocks?.Box?.showFlexOptions && <FlexOptions />}
      <CollapsablePanel title={t('advancedSettings')}>
        <Input
          label={t('name')}
          help={t('nameHelp')}
          value={data.name}
          onChange={e =>
            actions.setProp(props => (props.name = e.target.value))
          }
        />
        <Input
          label={t('id')}
          help={t('idHelp')}
          value={data.id}
          onChange={e => actions.setProp(props => (props.id = e.target.value))}
        />
        <Input
          label={t('className')}
          help={t('classNameHelp')}
          value={data.className}
          onChange={e =>
            actions.setProp(props => (props.className = e.target.value))
          }
        />
        <DateTime
          type="datetime"
          label={t('publishStartsAt')}
          help={t('publishStartsAtHelp')}
          value={data.publishStartsAt}
          onChange={date =>
            actions.setProp(props => (props.publishStartsAt = date))
          }
          maxValue={data.publishEndsAt}
          fullWidth={true}
        />
        <DateTime
          type="datetime"
          label={t('publishEndsAt')}
          help={t('publishEndsAtHelp')}
          value={data.publishEndsAt}
          onChange={date =>
            actions.setProp(props => (props.publishEndsAt = date))
          }
          minValue={data.publishStartsAt}
          fullWidth={true}
        />
      </CollapsablePanel>
    </>
  )
}
