import React, { useMemo } from 'react'

import { useFieldArray, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import EntityPicker from '@modules/entities/components/entities/EntityPicker/Controller'
import { SitePageSelect } from '@modules/web/components/shared/SitePageSelect'
import SitePicker from '@modules/web/components/sites/SitePicker'
import usePagesByResource from '@modules/web/services/hooks/usePagesByResource'
import Button from '@ui/buttons/Button'
import Collapse from '@ui/data-display/Collapse'
import Tag from '@ui/data-display/Tag'
import Input from '@ui/data-entry/Input'
import Password from '@ui/data-entry/Password'

export default function AclImportTaskFields() {
  const { t } = useTranslation('articles/tasks')

  const { items: sites } = usePagesByResource({
    resourceName: 'Article',
    resourceType: 'model',
  })

  const {
    fields: flagMappings,
    append: appendFlagMapping,
    remove: removeFlagMapping,
  } = useFieldArray({
    name: 'settings[flagMappings]',
  })

  const { settings } = useWatch()
  const { site: selectedSite = {} } = settings || {}

  const siteOptions = useMemo(() => {
    return sites
      ?.filter(site => selectedSite === site.id)
      ?.flatMap(site =>
        site.pages.map(page => ({
          label: page.name,
          value: page._id,
          page,
        }))
      )
  }, [sites, selectedSite])

  return (
    <div className="flex flex-col gap-8 pt-8">
      <div className="flex flex-col gap-4">
        <h3 className="font-semibold text-lg">{t('apiConnection')}</h3>
        <Input
          name="settings.apiUrl" // ACL API URL
          label={t('apiUrl')}
          help={t('apiUrlHelp')}
          required
        />
        <Input
          name="settings.apiToken" // ACL API Token
          label={t('apiToken')}
          help={t('apiTokenHelp')}
          required
        />

        <Input
          name="settings.entityId" // ACL entityId
          label={t('entityId')}
          help={t('entityIdHelp')}
          required
        />
        <Input
          name="settings.remoteSiteId" // ACL siteId
          label={t('remoteSiteId')}
          help={t('remoteSiteIdHelp')}
          required
        />
        <Input
          name="settings.articleContentTypeId" // ACL article's contentTypeId
          label={t('articleContentTypeId')}
          help={t('articleContentTypeIdHelp')}
          required
        />
        <Input
          name="settings.username" // ACL authentication user email
          label={t('username')}
          help={t('usernameHelp')}
          required
        />
        <Password
          name="settings.password" // ACL user password
          label={t('password')}
          help={t('passwordHelp')}
          required
        />
      </div>

      <div className="flex flex-col gap-8 pt-8">
        <h3 className="font-semibold text-lg">{t('targetEntity')}</h3>
        <EntityPicker
          name="settings.entity" // Adventist Web Engine entity
          label={t('entity')}
          help={t('targetEntityHelp')}
          required
          className="max-w-80" // TODO: remove this once the EntityPicker grow issue is fixed
        />
      </div>

      <div className="flex flex-col gap-8 pt-8">
        <h3 className="font-semibold text-lg">{t('targetSite')}</h3>
        <SitePicker
          name="settings.localSiteId" // Adventist Web Engine site
          label={t('localSiteId')}
          help={t('localSiteIdHelp')}
          required
        />
        <SitePageSelect
          name="settings.canonicalSitePage" // Adventist Web Engine canonical site page
          label={t('canonicalSitePage')}
          options={siteOptions}
          required={!!selectedSite}
        />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="font-semibold">{t('flagMappings')}</h3>
        <p className="text-sm">{t('flagMappingsDescription')}</p>
        <div className="flex flex-col gap-1">
          {flagMappings.map((field, index) => {
            const { id, remote, local } = field
            const isSet = remote && local

            return (
              <Collapse.Panel
                title={
                  isSet ? (
                    <span className="font-mono text-gray-600">{remote}</span>
                  ) : (
                    <Tag
                      variant="danger-light"
                      label={t('notSet')}
                      icon="exclamation-triangle"
                    />
                  )
                }
                defaultOpen={!isSet}
                key={id}
                innerClass="space-y-4"
                extra={
                  <Button
                    onClick={() => removeFlagMapping(index)}
                    icon="trash-alt"
                    variant="danger-light"
                    size="sm"
                  />
                }
              >
                <Input
                  name={`settings.flagMappings.${index}.remote`}
                  label={t('remoteFlagName')}
                />
                <Input
                  name={`settings.flagMappings.${index}.local`}
                  label={t('localFlagId')}
                />
              </Collapse.Panel>
            )
          })}
        </div>
        <Button
          label={t('addFlagMapping')}
          onClick={() => appendFlagMapping({ target: '', source: '' })}
          icon="plus"
          variant="success"
        />
      </div>

      <div className="flex flex-col gap-4">
        <h3 className="font-semibold">{t('dateRange')}</h3>
        <p className="text-sm">{t('dateRangeDescription')}</p>
        <Input
          name="settings.fromDate"
          label={t('fromDate')}
          help={t('fromDateHelp')}
        />
        <Input
          name="settings.toDate"
          label={t('toDate')}
          help={t('toDateHelp')}
        />
      </div>
    </div>
  )
}
