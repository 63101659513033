import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import usePublicationsConfig from '@modules/publications/services/hooks/usePublicationsConfig'
import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import BorderRadiusSelect from '@modules/web/components/ContentEditor/shared/BorderRadius/BlockSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

const config = {
  name: 'PublicationsReligionsMenu',
  label: 'publications/public:PublicationsReligionsMenu',
  type: 'plugin',
  icon: 'bars',
  component: <PublicationsReligionsMenu />,
}

export default function PublicationsReligionsMenu() {
  const { t } = useTranslation('publications/public')
  const { hasPublicationReligions } = usePublicationsConfig()

  return (
    <Content
      icon={config.icon}
      title={t('PublicationsReligionsMenu')}
      errors={
        hasPublicationReligions ? undefined : [{ title: t('noReligions') }]
      }
    >
      {!hasPublicationReligions && (
        <Message
          type="danger"
          title={t('publications/public:noReligions')}
          text={t('publications/public:noReligionsText')}
          size="xs"
        />
      )}
    </Content>
  )
}
PublicationsReligionsMenu.toolbarItem = config

function PublicationsReligionsMenuSettings() {
  const design = useDesignContext()
  const { t } = useTranslation([design.namespace, 'publications/public'])

  const { hasPublicationReligions } = usePublicationsConfig()

  const { variants } = useBlock(config.name)

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  if (!hasPublicationReligions) {
    return (
      <SettingsWrap title={t('publications/public:PublicationsReligionsMenu')}>
        <Message
          type="danger"
          title={t('publications/public:noReligions')}
          text={t('publications/public:noReligionsText')}
        />
      </SettingsWrap>
    )
  }

  const { showIcons, showAllLink } = variants[data.variant] || variants.simple

  return (
    <SettingsWrap
      title={t('publications/public:PublicationsReligionsMenu')}
      help={t('publications/public:PublicationsReligionsMenuHelp')}
    >
      <div className="flex flex-col gap-4">
        <InputField
          label={t('label')}
          name="label"
          onChange={e =>
            actions.setProp(props => (props.label = e.target.value))
          }
          value={data.label}
        />

        <Select
          label={t('publications/public:PublicationsReligionsMenuVariant')}
          name="variant"
          value={data.variant}
          onChange={e =>
            actions.setProp(props => (props.variant = e.target.value))
          }
        >
          {Object.keys(variants).map(key => (
            <SelectOption
              key={key}
              value={key}
              label={t(`PublicationsReligionsMenuVariant_${key}`)}
            />
          ))}
        </Select>

        {data.variant === 'dropdown' && <BorderRadiusSelect />}

        {showIcons && (
          <Checkbox
            label={t('publications/public:PublicationsReligionsMenuIcons')}
            name="showIcons"
            value={data.showIcons}
            onChange={value =>
              actions.setProp(props => (props.showIcons = value))
            }
          />
        )}

        {showAllLink && (
          <>
            <Checkbox
              label={t(
                'publications/public:PublicationsReligionsMenuShowAllLink'
              )}
              help={t(
                'publications/public:PublicationsReligionsMenuShowAllLinkHelp'
              )}
              name="showAllLink"
              value={data.showAllLink}
              onChange={value =>
                actions.setProp(props => (props.showAllLink = value))
              }
            />
            {data.showAllLink && (
              <InputField
                label={t(
                  'publications/public:PublicationsReligionsMenuShowAllLabel'
                )}
                name="showAllLabel"
                value={data.showAllLabel}
                onChange={e =>
                  actions.setProp(
                    props => (props.showAllLabel = e.target.value)
                  )
                }
              />
            )}
          </>
        )}

        <LinkingFields
          showListPagePicker={false}
          resourceIdentifier="PublicationReligion"
        />

        {showAllLink && (
          <PagePicker
            label={t('publications/public:publicationsListPageId')}
            help={t('publications/public:publicationsListPageIdHelp')}
            value={data.publicationsListPageId}
            onChange={pageId =>
              actions.setProp(props => (props.publicationsListPageId = pageId))
            }
          />
        )}
      </div>
    </SettingsWrap>
  )
}
PublicationsReligionsMenu.craft = {
  displayName: 'PublicationsReligionsMenu',
  props: {},
  custom: {
    type: config.type,
    resources: ['publicationsReligions'],
    variant: 'simple',
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationsReligionsMenuSettings,
  },
}

/**
 * Component to render the fields of a Latest Publications block translation
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function ReligionsMenuTranslation({ id, source }) {
  const { t } = useTranslation('publications/public')
  const fields = useMemo(
    () => ({
      label: { label: t('label') },
      showAllLabel: { label: t('PublicationsShowAllLabel') },
    }),
    [t]
  )
  return (
    <BlockTranslation
      id={id}
      fields={fields}
      source={source}
      i18Namespace="publications/public"
    />
  )
}
ReligionsMenuTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
