import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import LinkingFields from '@modules/web/components/ContentEditor/blocks/shared/LinkingFields'
import BlockTranslation from '@modules/web/components/ContentEditor/shared/BlockTranslation'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import CollapsablePanel from '@ui/data-display/CollapsablePanel'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

import FilterFields from './shared/FilterFields'

const config = {
  name: 'PublicationsSearch',
  label: 'publications/public:PublicationsSearch',
  type: 'plugin',
  icon: 'magnifying-glass',
  component: <PublicationsSearch />,
}

export default function PublicationsSearch() {
  const { t } = useTranslation('publications/public')

  return <Content icon={config.icon} title={t('PublicationsSearch')}></Content>
}
PublicationsSearch.propTypes = {}
PublicationsSearch.defaultProps = {}
PublicationsSearch.toolbarItem = config

function PublicationsSearchSettings() {
  const design = useDesignContext()
  const { t } = useTranslation([design.namespace, 'publications/public'])

  const { variants } = useBlock(config.name)

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const { showAllLink } = variants[data.variant] || variants.simple

  return (
    <SettingsWrap
      title={t('publications/public:PublicationsSearch')}
      help={t('publications/public:PublicationsSearchHelp')}
    >
      <div className="space-y-4">
        <InputField
          label={t('publications/public:PublicationsSearchPlaceholder')}
          help={t('publications/public:PublicationsSearchPlaceholderHelp')}
          name="placeholder"
          onChange={e =>
            actions.setProp(props => (props.placeholder = e.target.value))
          }
          value={data.placeholder}
        />

        <Select
          label={t('variant')}
          help={t('variantHelp')}
          name="variant"
          value={data.variant}
          onChange={e =>
            actions.setProp(props => (props.variant = e.target.value))
          }
        >
          {Object.keys(variants).map(key => (
            <SelectOption
              key={key}
              value={key}
              label={t(`PublicationsSearchVariant_${key}`)}
            />
          ))}
        </Select>

        {showAllLink && (
          <>
            <Checkbox
              label={t('publications/public:PublicationsShowAllLink')}
              help={t('publications/public:PublicationsShowAllLinkHelp')}
              name="showAllLink"
              value={data.showAllLink}
              onChange={value =>
                actions.setProp(props => (props.showAllLink = value))
              }
            />

            <InputField
              label={t('publications/public:PublicationsShowAllLabel')}
              name="showAllLabel"
              value={data.showAllLabel}
              onChange={e =>
                actions.setProp(props => (props.showAllLabel = e.target.value))
              }
            />
          </>
        )}

        <LinkingFields resourceIdentifier="Publication" />

        {showAllLink && (
          <PagePicker
            label={t('publications/public:publicationsSearchPageId')}
            help={t('publications/public:publicationsSearchPageIdHelp')}
            value={data.publicationsSearchPageId}
            onChange={pageId =>
              actions.setProp(
                props => (props.publicationsSearchPageId = pageId)
              )
            }
          />
        )}

        <CollapsablePanel title={t('publications/public:filters')}>
          <FilterFields />
        </CollapsablePanel>
      </div>
    </SettingsWrap>
  )
}
PublicationsSearch.craft = {
  displayName: 'PublicationsSearch',
  props: {},
  custom: {
    type: config.type,
    resources: ['publicationsSearch'],
    variant: 'simple',
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: PublicationsSearchSettings,
  },
}

/**
 * Component to render the translation fields of PublicationsSearch block
 * @param {object} props - The component props
 * @param {string} props.id - The id of the block
 * @param {object} props.source - The source of the block
 * @returns {JSX.Element} The component
 */
export function PublicationsSearchTranslation({ id, source }) {
  const { t } = useTranslation('publications/public')
  const fields = useMemo(
    () => ({
      label: { label: t('label') },
      showAllLabel: { label: t('PublicationsShowAllLabel') },
      filterAllLabel: { label: t('filterAllLabel') },
      noResultsLabel: { label: t('noResultsLabel') },
      religionFilterLabel: { label: t('religionFilterLabel') },
      feltNeedFilterLabel: { label: t('feltNeedFilterLabel') },
      doctrineFilterLabel: { label: t('doctrineFilterLabel') },
      languageFilterLabel: { label: t('languageFilterLabel') },
      filtersLabel: { label: t('filtersLabel') },
      placeholder: { label: t('PublicationsSearchPlaceholder') },
    }),
    [t]
  )
  return (
    <BlockTranslation
      id={id}
      fields={fields}
      source={source}
      i18Namespace="publications/public"
    />
  )
}
PublicationsSearchTranslation.propTypes = {
  id: PropTypes.string,
  source: PropTypes.object,
}
