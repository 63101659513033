import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'

import Clickable from '@ui/helpers/Clickable'
import Link from '@ui/navigation/Link'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const itemsSpacing = {
  none: { tree: 'gap-0', children: 'py-0 gap-0' },
  sm: { tree: 'gap-1', children: 'py-1 gap-1' },
  md: { tree: 'gap-2', children: 'py-2 gap-2' },
  lg: { tree: 'gap-3', children: 'py-3 gap-3' },
}

export default function Tree({
  children,
  className,
  filter,
  getItems,
  itemChildren,
  items,
  renderItem,
  spacing,
}) {
  const _children =
    renderTree(items, getItems, renderItem, itemChildren, filter, spacing) ||
    children

  return (
    <div
      className={`flex flex-col ${className} ${itemsSpacing[spacing]?.tree}`}
    >
      {_children}
    </div>
  )
}
Tree.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  filter: PropTypes.func,
  getItems: PropTypes.func,
  items: PropTypes.array,
  itemChildren: PropTypes.string,
  renderItem: PropTypes.func,
  spacing: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
}
Tree.defaultProps = {
  className: '',
  itemChildren: 'children',
  spacing: 'md',
}

export function TreeItem({
  actions,
  active,
  ancestor,
  children,
  className = '',
  defaultExpanded,
  disabled,
  expanding,
  extra,
  hasChildren,
  href,
  icon,
  label,
  onClick,
  onExpand,
  spacing = 'md',
  variant,
}) {
  const [expanded, setExpanded] = useState()

  useEffect(() => {
    if (defaultExpanded && expanded === undefined) {
      setExpanded(defaultExpanded)

      if (typeof onExpand === 'function') {
        onExpand(defaultExpanded)
      }
    }
  }, [defaultExpanded, expanded, onExpand])

  const onToggleExpand = useCallback(() => {
    setExpanded(!expanded)

    if (typeof onExpand === 'function') {
      onExpand(!expanded)
    }
  }, [expanded, onExpand])

  const showExpand = hasChildren !== undefined ? hasChildren : children

  return (
    <div className={`flex flex-col ${className}`}>
      <div className="flex flex-row items-center justify-between gap-1">
        <Clickable
          className="ml-1 inline-flex h-4 w-4 shrink-0 select-none items-center justify-center text-xs"
          clickableClass="text-primary-600 outline-none"
          onClick={showExpand ? onToggleExpand : undefined}
        >
          {showExpand && (
            <Icon
              className={`transform duration-300 ease-in-out ${
                expanded ? 'rotate-90' : ''
              }`}
              name={expanding ? 'spinner' : 'chevron-right'}
              spin={expanding}
            />
          )}
        </Clickable>
        <Clickable
          className={`flex flex-grow flex-row items-center justify-between gap-1 truncate rounded-lg border p-2 text-sm transition-colors duration-300 ease-in-out ${
            active
              ? 'bg-primary-500 hover:bg-primary-600'
              : ancestor
                ? 'border-primary-300 hover:border-primary-500'
                : 'border-transparent bg-white bg-opacity-75 hover:border-primary-200'
          }`}
          onClick={disabled ? undefined : onClick}
        >
          <Link
            basic={false}
            draggable={false}
            className={`flex flex-row items-start gap-2 truncate ${
              variant === 'deleted'
                ? 'text-danger-600 opacity-50'
                : (disabled || variant === 'disabled') && !active
                  ? 'opacity-30'
                  : active
                    ? 'text-primary-50 hover:text-primary-100'
                    : ancestor
                      ? 'border-primary-400 hover:border-primary-600'
                      : 'text-gray-600'
            } ${
              disabled
                ? 'cursor-not-allowed opacity-50'
                : href && !active
                  ? 'text-gray-500 hover:text-primary-500 hover:underline'
                  : 'cursor-default'
            }`}
            href={disabled ? undefined : href}
          >
            {icon && (
              <span
                className={`flex h-4 w-4 items-center justify-center shrink-0 ${
                  active ? 'text-primary-300' : 'text-gray-400'
                }`}
              >
                <Icon name={icon} />
              </span>
            )}
            <span
              className={`truncate leading-4 ${active ? 'font-semibold' : ''}`}
            >
              {label}
            </span>
          </Link>

          {extra && <div className="flex flex-row gap-1">{extra}</div>}
        </Clickable>
        {actions && (
          <div className="tree-item-actions flex flex-row gap-1">{actions}</div>
        )}
      </div>
      {expanded && children && (
        <div
          className={`flex flex-col pl-6 ${itemsSpacing[spacing]?.children || ''}`}
        >
          {children}
        </div>
      )}
    </div>
  )
}
TreeItem.propTypes = {
  actions: PropTypes.node,
  active: PropTypes.bool,
  ancestor: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
  expanding: PropTypes.bool,
  extra: PropTypes.node,
  hasChildren: PropTypes.bool,
  href: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  icon: PropTypes.string,
  label: PropTypes.node.isRequired,
  onExpand: PropTypes.func,
  onClick: PropTypes.func,
  spacing: PropTypes.oneOf(['none', 'sm', 'md', 'lg']),
  variant: PropTypes.oneOf(['basic', 'deleted', 'disabled']),
}
Tree.Item = TreeItem
Tree.Item.displayName = 'Tree.Item'

const actionVariants = {
  active: 'text-primary-500 hover:text-primary-600 hover:bg-primary-100',
  basic: 'text-gray-400 hover:text-gray-500 hover:bg-gray-100',
  danger: 'text-danger-400 hover:text-danger-500 hover:bg-danger-100',
}

export function TreeItemAction({
  active,
  disabled,
  title,
  icon,
  href,
  onClick,
  variant,
}) {
  const variantClass = active
    ? actionVariants.active
    : actionVariants[variant] ?? actionVariants.basic
  const disabledClass = disabled ? 'opacity-50 cursor-not-allowed' : ''

  return (
    <Link
      basic={false}
      className={`flex cursor-pointer items-center justify-center rounded p-2 text-sm ${variantClass} ${disabledClass}`}
      href={disabled ? undefined : href}
      onClick={disabled ? undefined : onClick}
      title={title}
    >
      <Icon name={icon} />
    </Link>
  )
}
TreeItemAction.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  title: PropTypes.string,
  icon: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf(['basic', 'danger']),
}
TreeItemAction.defaultProps = {
  variant: 'basic',
}
Tree.ItemAction = TreeItemAction
Tree.ItemAction.displayName = 'Tree.ItemAction'

/**
 * Returns a rendered tree given a (nested) array of items and a render function
 * @param {array} items items to render
 * @param {function} getItems helper used to parse the tree items.
 * @param {function} renderItem function that will render an item. Provides the current item and its rendered children (if item has any). `(item, children) => <do-something />`.
 * @param {string} childrenAttribute field that will be used to check if an item has subitems
 * @param {function} filter function that filters items (and children)
 * @param {string} spacing space beween items
 * @returns `array`
 */
function renderTree(
  items,
  getItems,
  renderItem,
  childrenAttribute = 'children',
  filter = x => x,
  spacing = 'md'
) {
  if (!Array.isArray(items) || typeof renderItem !== 'function') return null

  if (typeof getItems === 'function') {
    items = getItems(items)
  }

  if (typeof filter === 'function') {
    items = items.filter(filter)
  }

  return items.map((item, index) =>
    renderItem(
      item,
      renderTree(
        item[childrenAttribute],
        getItems,
        renderItem,
        childrenAttribute,
        filter,
        spacing
      ),
      index
    )
  )
}
