import PropTypes from 'prop-types'
import React from 'react'

import { ResourcesContext } from './hooks'

export default function ResourcesProvider({ children, resources }) {
  return (
    <ResourcesContext.Provider value={resources}>
      {children}
    </ResourcesContext.Provider>
  )
}
ResourcesProvider.propTypes = {
  children: PropTypes.node,
  resources: PropTypes.object,
}
