import PropTypes from 'prop-types'
import React from 'react'

import { useColumnsClass } from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import useValueAtBreakpoint from '@modules/web/components/ContentEditor/shared/useValueAtBreakpoint'
import useSite from '@modules/web/services/hooks/useSite'
import Pagination from '@ui/navigation/Pagination'

import usePublications from '../../../services/hooks/usePublications'
import PublicationCardItem from './CardItem'
import PublicationFeaturedItem from './FeaturedItem'
import PublicationItem from './Item'
import PublicationListItem from './ListItem'
import PublicationMenuItem from './MenuItem'
import PublicationPosterItem from './PosterItem'

const itemDisplayModes = {
  'cards': PublicationCardItem,
  'cards-with-list': PublicationItem,
  'featured': PublicationFeaturedItem,
  'featured-with-list': PublicationItem,
  'list': PublicationListItem,
  'menu': PublicationMenuItem,
  'posters': PublicationPosterItem,
}

export default function Publications({
  religion,
  featured,
  columns,
  direction,
  displayMode,
  variant,
  limit,
  pagination,
  showDescription,
  showImage,
  showLink,
  linkLabel,
  skip,
  sortField,
  sortOrder,
}) {
  const { site } = useSite()

  const directionAtBreakpoint = useValueAtBreakpoint(direction)
  const columnsAtBreakpoint = useValueAtBreakpoint(columns)

  const columnsClass = useColumnsClass(columnsAtBreakpoint)

  const sort = `${sortOrder === 'desc' ? '-' : ''}${
    site && sortField === 'publishedAt'
      ? `sites.${site?.id}.startsAt`
      : sortField
  }`

  const { publications, count } = usePublications({
    religion,
    featured,
    sort,
    limit,
    skip,
    siteId: site?.id,
  })

  const asMenu = variant === 'menu'

  const previewDisplayMode = asMenu
    ? 'menu'
    : ['cards', 'featured', 'list', 'posters'].includes(displayMode)
    ? displayMode
    : 'list'

  let items = publications || []

  return (
    <div className="space-y-6">
      <div
        className={`flex ${
          directionAtBreakpoint.value === 'x'
            ? 'flex-row items-stretch space-x-6 [&>*]:w-1/2'
            : 'flex-col space-y-6'
        }`}
      >
        <div
          className={asMenu ? 'space-y-1 px-2' : `grid gap-4 ${columnsClass}`}
        >
          {items.map(publication =>
            React.createElement(itemDisplayModes[previewDisplayMode], {
              publication,
              showImage,
              showDescription,
              showLink,
              linkLabel,
              site,
              key: publication.id,
            })
          )}
        </div>
      </div>
      {pagination && <Pagination page={1} pageSize={limit} total={count} />}
    </div>
  )
}
Publications.propTypes = {
  religion: PropTypes.string,
  featured: PropTypes.bool,
  columns: PropTypes.object,
  direction: PropTypes.object,
  displayMode: PropTypes.string, // Can be provided by design, so can't set a definite value
  variant: PropTypes.string,
  limit: PropTypes.number,
  pagination: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  site: PropTypes.object,
  skip: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
}
