import { useMemo } from 'react'

import merge from 'lodash/merge'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import useSite from '@modules/web/services/hooks/useSite'

// The default colors for the system, which are always available, and must provide a valid color as value
export const defaultColors = {
  system: {
    name: 'System',
    black: 'black',
    white: 'white',
    transparent: 'transparent',
    current: 'currentColor',
  },
}

/**
 * A helper hook that merges the design's `colors` object with the site's `design.colors` object
 * @returns {Object} - The merged colors object
 */
export function useDesignColors() {
  const design = useDesignContext()
  const { site } = useSite()

  const colors = useMemo(
    () => merge(design.colors, site?.design?.colors || {}),
    [design.colors, site?.design?.colors]
  )

  return { ...colors, ...defaultColors }
}

/**
 * A helper hook that converts the design's `colors` object into a list
 * of objects
 *
 * @param {Object} options An options object
 * @param {string[]} options.excludedColors A list of color keys to exclude from the design colors
 * @param {string[]} options.includedColors A list of color keys to include from the design colors. Will only include these. Overrides excludedColors
 * @returns {{ label: string, value: string, color: string }[]}
 */
export function useColorOptions(options = {}) {
  const { excludedColors = [], includedColors } = options
  const colors = useDesignColors()

  const colorOptions = useMemo(
    () =>
      Object.entries(colors).reduce((groups, [groupKey, value]) => {
        if (typeof value !== 'object' || Object.entries(value).length === 0) {
          return groups
        }

        // Initialize the group object
        const group = {
          label: value.name || groupKey,
          value: groupKey,
          colors: [],
        }

        // Add the default color to the top of the list if it exists
        if (value['DEFAULT']) {
          group.colors.push({
            label: groupKey,
            value: groupKey,
            color: value['DEFAULT'],
          })
        }

        // Add the rest of the colors
        group.colors.push(
          ...Object.entries(value).reduce(
            (groupColors, [colorKey, subValue]) => {
              // Skip the name key if present, as it's not a color value
              if (colorKey === 'name') {
                return groupColors
              }

              // If includedColors is present, skip the color if it's **not** in the list
              if (
                includedColors &&
                !includedColors.includes(`${groupKey}-${colorKey}`)
              ) {
                return groupColors
              }

              // If excludedColors is present, skip the color if it's in the list
              if (excludedColors.includes(`${groupKey}-${colorKey}`)) {
                return groupColors
              }

              // Skip the default color as it's already been added
              if (colorKey === 'DEFAULT') {
                return groupColors
              }

              const colorValue =
                groupKey === 'system' ? colorKey : `${groupKey}-${colorKey}`

              return [
                ...groupColors,
                {
                  label: colorValue,
                  value: colorValue,
                  color: subValue,
                },
              ]
            },
            []
          )
        )

        return [...groups, group]
      }, []),
    [colors, excludedColors, includedColors]
  )

  return colorOptions
}
