import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useEventPermissions() {
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  const commonParams = {
    user,
    entity: currentEntity,
    currentEntityId: currentEntity?.id,
  }

  return {
    canReadEvents: isUserAuthorized({
      module: 'events',
      permission: 'read',
      ...commonParams,
    }),
    canCreateEvents: isUserAuthorized({
      module: 'events',
      permission: 'create',
      ...commonParams,
    }),
    canUpdateEvents: ({ organizerId }) => {
      return isUserAuthorized({
        module: 'events',
        permission: 'update',
        recordId: organizerId,
        ...commonParams,
      })
    },
    canDeleteEvents: ({ organizerId }) => {
      return isUserAuthorized({
        module: 'events',
        permission: 'delete',
        recordId: organizerId,
        ...commonParams,
      })
    },

    // Organizers
    canReadOrganizers: useIsUserAuthorized({
      module: 'events-organizers',
      permission: 'read',
    }),
    canCreateOrganizers: useIsUserAuthorized({
      module: 'events-organizers',
      permission: 'create',
    }),
    canUpdateOrganizers: useIsUserAuthorized({
      module: 'events-organizers',
      permission: 'update',
    }),
    canDeleteOrganizers: useIsUserAuthorized({
      module: 'events-organizers',
      permission: 'delete',
    }),

    // Regions
    canReadRegions: useIsUserAuthorized({
      module: 'events-regions',
      permission: 'read',
    }),
    canCreateRegions: useIsUserAuthorized({
      module: 'events-regions',
      permission: 'create',
    }),
    canUpdateRegions: useIsUserAuthorized({
      module: 'events-regions',
      permission: 'update',
    }),
    canDeleteRegions: useIsUserAuthorized({
      module: 'events-regions',
      permission: 'delete',
    }),

    // Categories
    canReadCategories: useIsUserAuthorized({
      module: 'events-categories',
      permission: 'read',
    }),
    canCreateCategories: useIsUserAuthorized({
      module: 'events-categories',
      permission: 'create',
    }),
    canUpdateCategories: useIsUserAuthorized({
      module: 'events-categories',
      permission: 'update',
    }),
    canDeleteCategories: useIsUserAuthorized({
      module: 'events-categories',
      permission: 'delete',
    }),
  }
}
