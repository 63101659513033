import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import {
  SelectField as Select,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

export default function AudioPlayerVariant({ name = 'variant' }) {
  const { t } = useTranslation('media-library/public')

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const onTextChange = useCallback(
    field => e => {
      actions.setProp(props => (props[field] = e.target.value))
    },
    [actions]
  )

  return (
    <Select
      name={name}
      label={t('variant')}
      help={t('variantPlaylistHelp')}
      onChange={onTextChange(name)}
      value={data[name]}
    >
      {data[name] && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      <SelectOption label={t(`audioPlayerVariant-sm`)} value="sm" />
      <SelectOption label={t(`audioPlayerVariant-md`)} value="md" />
      <SelectOption label={t(`audioPlayerVariant-lg`)} value="lg" />
    </Select>
  )
}

AudioPlayerVariant.propTypes = {
  name: PropTypes.string,
}
