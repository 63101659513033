import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { FieldHelp } from './components/FieldHelp'
import { FieldLabel } from './components/FieldLabel'

const labelPositions = {
  top: 'flex-col items-stretch justify-start',
  left: 'flex-row items-center',
  right: 'flex-row-reverse space-x-reverse items-center justify-end',
}

export default function Field({
  children,
  className,
  disabled,
  error,
  help,
  helpPosition,
  indentHelp,
  label,
  labelExtra,
  labelPosition,
  lightLabel,
  missing,
  name,
  popover,
  required,
  tooltip,
  tooltipMaxWidth,
  tooltipPlacement,
  fieldExtra,
}) {
  const { t } = useTranslation('common')

  const labelPositionClass = labelPositions[labelPosition ?? 'top']

  const disabledClass = disabled ? 'opacity-50' : 'opacity-100'

  const fieldLabelProps = {
    disabled,
    label,
    lightLabel,
    missing,
    name,
    tooltip,
    tooltipMaxWidth,
    tooltipPlacement,
    required,
    extra: labelExtra,
  }

  const getErrorMessage = errorMessage => {
    if (errorMessage?.startsWith('validation.')) {
      return t(errorMessage.split('validation.')[1])
    }
    return errorMessage
  }

  return (
    <div className={`flex flex-col ${className}`}>
      <div
        className={`flex gap-3 transition-opacity duration-300 ease-in-out ${labelPositionClass}`}
      >
        {(label || labelExtra) && !popover && (
          <FieldLabel {...fieldLabelProps} />
        )}

        {popover && popover(fieldLabelProps)}

        {help && helpPosition === 'top' && (
          <FieldHelp
            className={`mt-2 pb-2 ${indentHelp ? 'ml-6' : ''}`}
            text={help}
            required={required && !label}
          />
        )}
        <div className={`flex flex-col justify-center ${disabledClass}`}>
          {typeof fieldExtra === 'function' ? fieldExtra() : fieldExtra}
          {children}
        </div>
      </div>
      {help && helpPosition === 'bottom' && (
        <FieldHelp
          className={`${indentHelp ? 'ml-7 mt-1' : 'mt-2'} ${disabledClass}`}
          text={help}
          required={required && !label}
        />
      )}
      {error && (
        <div className="mt-2 flex flex-row text-sm font-semibold text-danger-500">
          {getErrorMessage(error.message) || error.type}
        </div>
      )}
    </div>
  )
}
Field.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.shape({
    type: PropTypes.string,
    message: PropTypes.string,
    ref: PropTypes.object,
  }),
  fieldExtra: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  help: PropTypes.string,
  helpPosition: PropTypes.oneOf(['top', 'bottom']),
  indentHelp: PropTypes.bool,
  label: PropTypes.node,
  labelExtra: PropTypes.node,
  lightLabel: PropTypes.bool,
  labelPosition: PropTypes.oneOf(['top', 'left', 'right']),
  missing: PropTypes.bool,
  name: PropTypes.string,
  popover: PropTypes.func,
  required: PropTypes.bool,
  tooltip: PropTypes.node,
  tooltipMaxWidth: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf(['top', 'left', 'right']),
}
Field.defaultProps = {
  className: '',
  helpPosition: 'bottom',
  labelPosition: 'top',
  popoverPlacement: 'left',
  tooltipPlacement: 'top',
}
