import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'

export default function CardItem({ className = '', image, subtitle, title }) {
  return (
    <div className={`overflow-hidden rounded border bg-gray-100 ${className}`}>
      <div className="aspect-h-9 aspect-w-16 bg-gray-200">
        {image && <Image file={image} alt={title} width="200" />}
      </div>
      <div className="space-y-1 p-2 text-xs">
        <div className="truncate font-semibold">{title}</div>
        {subtitle && <div className="truncate">{subtitle}</div>}
      </div>
    </div>
  )
}

CardItem.propTypes = {
  className: PropTypes.string,
  image: PropTypes.object,
  subtitle: PropTypes.string,
  title: PropTypes.string,
}
