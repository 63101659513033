// NOTE: Do not remove the comments after each option, they are needed so TailwindCSS purge can include them in the generated CSS styles.
const options = [
  'solid', // border-solid
  'dashed', // border-dashed
  'dotted', // border-dotted
  'double', // border-double
  'hidden', // border-hidden
  'none', // border-none
]

export function useBorderStyleOptions() {
  return options
}

/**
 * Get border styles from variant settings
 *
 * @param {string} style - border style
 * @returns {object} border styles object (with className: string, style: object)
 * @example
 * const borderStyles = useBorderStyleClasses('solid')
 * // -> { className: 'border-solid', style: {} }
 * @example
 * const borderStyles = useBorderStyleClasses('invalid')
 * // -> { className: '', style: {} }
 * @example
 * const borderStyles = useBorderStyleClasses()
 * // -> { className: '', style: {} }
 */
export function useBorderStyleClasses(value) {
  const classNames = []
  const style = {}

  // Only add the border style if it is a valid option
  if (options.includes(value)) {
    classNames.push(`border-${value}`)
  }

  return {
    className: classNames.join(' '),
    style,
  }
}
