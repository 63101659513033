import { useMemo } from 'react'

import { useDesignContext } from '@modules/web/components/DesignProvider'

import { gridColSpan, gridOrder } from '../../GridItemOptions'
import useBreakpoint from '../../useBreakpoint'

export default function useGridItemClasses(props = {}, parent) {
  const { breakpoint } = useBreakpoint()
  const design = useDesignContext()

  return useMemo(() => {
    if (parent?.data?.name !== 'Grid') {
      return ''
    }

    const breakpointKeys = Object.keys(design.breakpoints || {})
    const breakpointIndex = breakpointKeys.indexOf(breakpoint)
    const { order, colSpan } = props

    const displayedOrder = !order || typeof order !== 'object' ? {} : order

    const orderClasses = Object.keys(displayedOrder).reduce((acc, bp) => {
      const bpiIndex = breakpointKeys.indexOf(bp)
      const value = order[bp]
      if (bpiIndex <= breakpointIndex) {
        return [...acc, gridOrder[bp][value]]
      }
      return acc
    }, [])

    const displayedColSpan =
      !colSpan || typeof colSpan !== 'object' ? {} : colSpan

    const colSpanClasses = Object.keys(displayedColSpan).reduce((acc, bp) => {
      const bpiIndex = breakpointKeys.indexOf(bp)
      const value = colSpan[bp]
      if (bpiIndex <= breakpointIndex) {
        return [...acc, gridColSpan[bp][value]]
      }
      return acc
    }, [])

    return [...orderClasses, ...colSpanClasses].join(' ')
  }, [props, parent, breakpoint, design.breakpoints])
}
