import PropTypes from 'prop-types'
import React from 'react'

import ChildrenWithProps from '@ui/helpers/ChildrenWithProps'

const alignOptions = {
  start: 'items-start',
  end: 'items-end',
  center: 'items-center',
  baseline: 'items-baseline',
  stretch: 'items-stretch',
}
const justifyOptions = {
  start: 'justify-start',
  end: 'justify-end',
  center: 'justify-center',
  between: 'justify-between',
  around: 'justify-around',
  evenly: 'justify-evenly',
}

const spaces = {
  'none': 'gap-0',
  'xs': 'gap-1',
  'sm': 'gap-2',
  'md': 'gap-3',
  'lg': 'gap-4',
  'xl': 'gap-6',
  '2xl': 'gap-12',
  '3xl': 'gap-20',
}

const childPaddings = {
  'none': { x: '', y: '' },
  'xs': { x: 'pl-1', y: 'pt-1' },
  'sm': { x: 'pl-2', y: 'pt-2' },
  'md': { x: 'pl-3', y: 'pt-3' },
  'lg': { x: 'pl-4', y: 'pt-4' },
  'xl': { x: 'pl-6', y: 'pt-6' },
  '2xl': { x: 'pl-12', y: 'pt-12' },
  '3xl': { x: 'pl-20', y: 'pt-20' },
}

export default function Box({
  align,
  as,
  children,
  className,
  dividers,
  grow,
  horizontal,
  inline,
  innerRef,
  justify,
  reverse,
  space,
  ...props
}) {
  const inlineClass = inline ? 'inline-flex' : 'flex'
  const directionClass = horizontal
    ? reverse
      ? 'flex-row-reverse'
      : 'flex-row'
    : reverse
    ? 'flex-col-reverse'
    : 'flex-col'
  const alignClass = alignOptions[align] || ''
  const justifyClass = justifyOptions[justify] || ''
  const spaceClass = spaces[space] || ''
  const spaceReverseClass = horizontal ? 'rtl:space-x-reverse' : ''
  const dividerClass = dividers ? (horizontal ? `divide-x ` : 'divide-y') : ''
  const growClass = grow ? 'flex-grow' : ''

  return React.createElement(
    as,
    {
      className: `${inlineClass} ${directionClass} ${alignClass} ${justifyClass} ${spaceClass} ${spaceReverseClass} ${dividerClass} ${growClass} ${className}`,
      ref: innerRef,
      ...props,
    },
    <ChildrenWithProps
      condition={!!(dividers && childPaddings[space])}
      setProps={(props, index) => ({
        className: `${props?.className || ''} ${
          (index > 0 && childPaddings[space]?.[horizontal ? 'x' : 'y']) || ''
        }`,
      })}
    >
      {children}
    </ChildrenWithProps>
  )
}

Box.propTypes = {
  as: PropTypes.oneOf(['button', 'div', 'span']),
  align: PropTypes.oneOf(['start', 'end', 'center', 'baseline', 'stretch']),
  justify: PropTypes.oneOf([
    'start',
    'end',
    'center',
    'between',
    'around',
    'evenly',
  ]),
  children: PropTypes.node,
  className: PropTypes.string,
  dividers: PropTypes.bool,
  grow: PropTypes.bool,
  horizontal: PropTypes.bool,
  inline: PropTypes.bool,
  innerRef: PropTypes.func,
  reverse: PropTypes.bool,
  space: PropTypes.oneOf(['none', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
}
Box.defaultProps = {
  align: 'stretch',
  as: 'div',
  className: '',
  justify: 'start',
  space: 'none',
}
