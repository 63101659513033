import { useParams } from 'react-router'
import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useCourse(id) {
  const params = useParams()

  const courseId = id || params?.courseId

  const { data, error, isLoading } = useQuery(['courses', courseId], () =>
    getFetch(courseId ? `/api/courses/${courseId}` : null)
  )

  return { course: data, error, loading: isLoading }
}
