import React from 'react'

import PersonsMenuGroup from './components/PersonsMenuGroup'

const Router = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    name: 'persons',
    menu: PersonsMenuGroup,
    routes: {
      private: {
        '/persons/*': { Component: Router },
      },
    },
    permissions: {
      persons: {
        name: 'persons',
        label: 'persons/public:persons',
        icon: 'people-group',
        models: [
          {
            name: 'persons',
            label: 'persons/public:persons',
            permissions: ['read', 'create', 'update', 'delete'],
          },
        ],
      },
    },
  }
}
