import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useAIEnabled() {
  const { entity } = useCurrentEntity()
  const { ai } = entity?.config || {}
  const { enabled, providers } = ai || {}

  const hasEnabledProviders = Object.values(providers || {}).some(
    ({ disabled, apiKey }) => !disabled && apiKey
  )

  return (enabled && hasEnabledProviders) || false
}
