import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useCollections({
  providerId,
  statuses = ['active', 'disabled'],
}) {
  const { data, error, isLoading, isFetching } = useQuery(
    ['courses-collections', { providerId, statuses }],
    () =>
      getFetch(`/api/courses/providers/${providerId}/collections`, {
        status: statuses?.join(','),
      })
  )

  return {
    collections: data?.items,
    count: data?.count,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
