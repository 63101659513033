import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import BlockField from '../BlockField'
import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { useFlexDirectionsOptions } from './hooks'

export default function FlexDirectionSelect() {
  const { t } = useTranslation('web/content-editor')

  const directionOptions = useFlexDirectionsOptions()

  return (
    <BlockField name="direction" responsive>
      {({ fieldProps, value, inheritFrom, inheritValue, onDataChange }) => (
        <SelectField
          {...fieldProps}
          label={t('direction')}
          help={t('directionHelp')}
          onChange={e => onDataChange(e.target.value, 'y')}
          value={value}
        >
          {' '}
          <InheritSelectPlaceholder
            labelKey="direction"
            value={value}
            inheritValue={inheritValue}
            inheritFrom={inheritFrom}
          />
          {value && <SelectOption label={t('clearSelection')} value={null} />}
          <SelectPlaceholder label="-" />
          {directionOptions.map(({ label, value }) => (
            <SelectOption label={label} value={value} key={value} />
          ))}
        </SelectField>
      )}
    </BlockField>
  )
}
FlexDirectionSelect.propTypes = {
  direction: PropTypes.object,
}
