import React from 'react'

export const componentsList = [
  {
    name: 'text',
    icon: 'text',
    form: React.lazy(() => import('./components/text/Settings')),
  },
  {
    name: 'image',
    icon: 'image',
    form: React.lazy(() => import('./components/image/Settings')),
  },
  {
    name: 'button',
    icon: 'rectangle-wide',
    form: React.lazy(() => import('./components/button/Settings')),
  },
  // {
  //   name: 'card',
  //   icon: 'image-polaroid',
  //   form: CardSettings,
  // },
]

export const componentNames = componentsList.map(component => component.name)
