import { useResponsiveField } from '@ui/data-entry/shared/hooks/useResponsiveField'

import useBreakpoint from './useBreakpoint'

export function useFieldBreakpoint() {
  const { breakpoint: previewBreakpoint } = useBreakpoint()
  const { showControls, setShowControls, breakpoint, setBreakpoint } =
    useResponsiveField(false, previewBreakpoint ?? 'xs')

  return {
    showControls,
    setShowControls,
    breakpoint: showControls ? breakpoint : previewBreakpoint,
    setBreakpoint,
  }
}

export default useFieldBreakpoint
