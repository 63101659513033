import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

export default function FormVariantSelect({ name = 'variant', label, help }) {
  const design = useDesignContext()
  const { t } = useTranslation(['web/content-editor', design.namespace])

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const variants = design?.form?.variants ?? []

  if (variants.length === 0) {
    return null
  }

  return (
    <Select
      name={name}
      label={label ?? t('formVariant')}
      help={help ?? t('formVariantHelp')}
      onChange={e => actions.setProp(props => (props[name] = e.target.value))}
      value={data[name]}
    >
      {variants.map(variant => (
        <SelectOption
          label={t(`${design.namespace}:formVariant-${variant}`)}
          value={variant}
          key={`formVariant-${variant}`}
        />
      ))}
    </Select>
  )
}

FormVariantSelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
}
