/**
 * Checks if provided value is a tiptap doc
 * @param {Object} doc
 * @returns boolean
 */
export function isDoc(doc) {
  return typeof doc === 'object' && Array.isArray(doc.content)
}

/**
 * Checks if provided value is a valid tiptap doc
 * @param {Object} doc
 * @returns boolean
 */
export function isValidRichText(doc) {
  return doc && (typeof doc === 'object' || Array.isArray(doc.content))
}

/**
 * Checks if provided doc is empty
 * @param {Object} doc
 * @returns boolean
 */
export function isDocEmpty(doc) {
  return (
    doc && isDoc(doc) && doc.content.length === 1 && !doc.content[0].content
  )
}

/**
 * Serializes a tiptap node
 */
export function serializeFlat(node, key) {
  if (node.type === 'text' && typeof node.text === 'string') {
    return node.text
  } else {
    const { content } = node

    return content
      ?.map((node, i) => serializeFlat(node, `${key}-${i}`))
      .join('')
  }
}

/**
 * Gets the flat text from a tiptap doc
 */
export function getFlatText(doc, modifier) {
  if (!isValidRichText(doc)) return null

  return doc.content.map((n, i) => serializeFlat(n, `node-${i}`, modifier))
}
