import React from 'react'

import useSite from '@modules/web/services/hooks/useSite'

import { getFontFaceRules } from './getFontFaceRules'

export default function FontFaceLoader() {
  const { site } = useSite()
  const fontFaceRules = getFontFaceRules(site?.design?.fonts)

  if (!fontFaceRules) {
    return null
  }

  return <style dangerouslySetInnerHTML={{ __html: fontFaceRules }} />
}
