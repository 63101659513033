import { useCallback } from 'react'

import useQueryParams from '@ui/helpers/useQueryParams'

export default function useCurrentComponent() {
  const { queryParams, setQueryParams } = useQueryParams()

  const currentComponent = queryParams.get('component')

  const setComponentParam = useCallback(
    component => {
      if (component === currentComponent) {
        setQueryParams('component', null)
        return
      }
      setQueryParams('component', component)
    },
    [setQueryParams, currentComponent]
  )

  return { currentComponent, setComponentParam }
}
