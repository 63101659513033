import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'

import InheritSelectPlaceholder from '../InheritSelectPlaceholder'
import { aspectRatios, useAspectOptions } from './hooks'

export function AspectRatioSelectField({
  help,
  includeOptions,
  inheritFrom,
  inheritValue,
  label,
  name = 'aspectRatio',
  onChange,
  value,
  ...rest
}) {
  const { t } = useTranslation('web/content-editor')

  const aspectRatioOptions = useAspectOptions({ includeOptions })

  return (
    <SelectField
      {...rest}
      name={name}
      label={label || t('imageAspectRatio')}
      help={help}
      onChange={onChange}
      value={value}
    >
      <InheritSelectPlaceholder
        labelKey="imageAspectRatio"
        value={value}
        inheritValue={inheritValue}
        inheritFrom={inheritFrom}
      />
      {value && <SelectOption label={t('clearSelection')} value={null} />}
      <SelectPlaceholder label="-" />
      {aspectRatioOptions.map(({ label, value }) => (
        <SelectOption label={label} value={value} key={label} />
      ))}
    </SelectField>
  )
}
AspectRatioSelectField.propTypes = {
  help: PropTypes.string,
  includeOptions: PropTypes.arrayOf(PropTypes.oneOf(aspectRatios)),
  inheritFrom: PropTypes.string,
  inheritValue: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
}
