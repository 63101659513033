import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  onlineCoursesOnly: false,
}

export default function useCollection(id, options = defaultOptions) {
  const { onlineCoursesOnly } = { ...defaultOptions, ...options }

  const params = useParams()

  const collectionId = id || params?.collectionId

  const { data, error, isLoading } = useQuery(
    ['courses-collections', collectionId, options],
    () =>
      getFetch(
        collectionId ? `/api/courses/collections/${collectionId}` : null,
        { onlineCoursesOnly }
      )
  )

  return { collection: data, error, loading: isLoading }
}
