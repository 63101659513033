import PropTypes from 'prop-types'
import React, { Suspense, useEffect, useState } from 'react'

import { HelmetProvider } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'

import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useGlobalErrors from '@modules/auth/helpers/useGlobalErrors'
import useSetUserLanguage from '@modules/auth/helpers/useSetUserLanguage'
import FeatureFlagProvider from '@modules/feature-flags/context/FeatureFlagsProvider'
import ChatwootProvider from '@modules/support/components/ChatwootProvider'
import ErrorScreen from '@ui/feedback/ErrorScreen'
import Loading from '@ui/feedback/Loading'
import UnsupportedBrowserAlert from '@ui/feedback/UnsupportedBrowserAlert'
import LocaleProvider from '@ui/providers/LocaleProvider'
import ErrorBoundary from '@utils/ErrorBoundary'

/**
 * NOTE: these components are sepatated as some requirements need to be set beforehand in a specific order:
 * - In AppProviders, useSetUserLanguage() requires <Suspense />
 */

/**
 * App Root componnet that wraps everything else in a Suspense component
 * @param {object} props
 * @param {React.Children} props.children
 * @returns
 */
export default function AppSuspense({ children }) {
  return (
    <Suspense fallback={<div />}>
      <QueryProvider>
        <UnsupportedBrowserAlert />
        <AppProviders>{children}</AppProviders>
      </QueryProvider>
    </Suspense>
  )
}
AppSuspense.propTypes = { children: PropTypes.node }

/**
 * Apps main providers
 * @param {object} props
 * @param {React.Children} props.children
 * @returns
 */
function AppProviders({ children }) {
  const { language } = useSetUserLanguage()
  const { user } = useCurrentUser()
  const dateTimeLocale = user?.preferences?.dateTimeLocale ?? 'en'

  return (
    <HelmetProvider>
      <LocaleProvider locale={language} dateTimeLocale={dateTimeLocale}>
        <AppErrorProvider>
          <ChatwootProvider>
            <FeatureFlagProvider>{children}</FeatureFlagProvider>
          </ChatwootProvider>
        </AppErrorProvider>
      </LocaleProvider>
    </HelmetProvider>
  )
}
AppProviders.propTypes = { children: PropTypes.node }

/**
 * App error provider
 * @param {object} props
 * @param {React.Children} props.children
 * @returns
 */
function AppErrorProvider({ children }) {
  const { t, i18n } = useTranslation()
  const [i18nLoaded, setI18nLoaded] = useState(false)

  // Wait for i18n to be initialized
  useEffect(() => {
    if (!i18nLoaded && i18n?.isInitialized) {
      setI18nLoaded(true)
    }
  }, [i18n, i18nLoaded])

  if (!i18nLoaded) {
    return <Loading />
  }

  // Check if cookies are disabled by the browser
  if (!navigator.cookieEnabled) {
    // and if so, show the cookies blocked screen
    return (
      <ErrorScreen
        kicker={t('cookiesBlockedKicker')}
        title={t('cookiesBlockedTitle')}
        message={t('cookiesBlockedMessage')}
        type="cookiesBlocked"
        refreshLabel={t('errorTryAgain')}
      />
    )
  }

  const { VITE_MAINTENANCE } = import.meta.env

  // Check if maintenance mode is enabled
  if (VITE_MAINTENANCE?.toLowerCase() === 'true') {
    // And if so, show the maintenance screen
    return (
      <ErrorScreen
        kicker={t('maintenanceKicker')}
        title={t('maintenanceTitle')}
        message={t('maintenanceMessage')}
        type="maintenance"
      />
    )
  }

  return (
    <ErrorBoundary
      fallback={
        <ErrorScreen
          backLabel={t('errorGoHome')}
          kicker={t('error')}
          message={t('errorMessage')}
          refreshLabel={t('errorTryAgain')}
          title={t('errorTitle')}
          showBack
        />
      }
    >
      {children}
    </ErrorBoundary>
  )
}
AppErrorProvider.propTypes = { children: PropTypes.node }

function QueryProvider({ children }) {
  const { t } = useTranslation()
  const [serverError, setServerError] = useState(false)
  const [entityError, setEntityError] = useState(false)

  const [queryCache, mutationCache] = useGlobalErrors({
    onAuthError: (errorCode = '') => {
      switch (errorCode) {
        case 'ENTITY_NOT_FOUND': {
          setEntityError(true)
          break
        }

        case 'USER_NOT_LOGGED_IN': {
          // Just ignore this error here: it's handled in the auth module. Otherwise this will cause a redirect loop.
          break
        }

        default:
          window.location.reload()
          break
      }
    },
    onServerError: () => setServerError(true),
    onRecover: () => {
      if (serverError) setServerError(false)
    },
  })

  // Create a client
  const queryClient = new QueryClient({
    queryCache,
    mutationCache,
    defaultOptions: {
      queries: {
        retry: false,
      },
    },
  })

  return (
    <QueryClientProvider client={queryClient}>
      {entityError ? (
        <ErrorScreen
          kicker={t('error')}
          message={t('noEntityMessage')}
          refreshLabel={t('noEntityTryAgain')}
          title={t('noEntityTitle')}
        />
      ) : serverError ? (
        <ErrorScreen
          backLabel={t('errorGoHome')}
          kicker={t('error')}
          message={t('errorMessage')}
          refreshLabel={t('errorTryAgain')}
          title={t('errorTitle')}
          showBack
        />
      ) : (
        children
      )}
    </QueryClientProvider>
  )
}
QueryProvider.propTypes = { children: PropTypes.node }
