import PropTypes from 'prop-types'
import React from 'react'

import useEntityIcons from './hooks/useEntityIcons'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function EntityIcon({ type }) {
  const icons = useEntityIcons()
  return <Icon name={icons[type] || 'university'} />
}
EntityIcon.propTypes = {
  type: PropTypes.string,
}
