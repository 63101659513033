export function getProviderUrl(type, providerId, tab) {
  let baseUrl = '/courses'
  const paths = {
    list: '/providers',
    add: '/providers/add',
    details: `/providers/${providerId}/details`,
    edit: `/providers/${providerId}/edit`,
    selected: `/providers/${providerId}`,
  }

  let url = baseUrl + paths[type]

  if (tab) url += `?tab=${tab}`

  return url
}

export function getCourseUrl(type, providerId, courseId, tab, params) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/courses',
    add: '/courses/add',
    edit: `/courses/${courseId}/edit`,
    clone: `/courses/${courseId}/clone`,
    details: `/courses/${courseId}/details`,
    selected: `/courses/${courseId}`,
  }

  let url = baseUrl + paths[type]

  if (params) url += params
  if (tab) url += `${params ? '&' : '?'}tab=${tab}`

  return url
}

export function getLessonUrl(type, providerId, courseId, lessonId, tab) {
  let baseUrl = getCourseUrl('selected', providerId, courseId)
  const paths = {
    list: '/lessons',
    add: '/lessons/add',
    edit: `/lessons/${lessonId}/edit`,
    content: `/lessons/${lessonId}/content`,
    selected: `/lessons/${lessonId}`,
  }

  let url = baseUrl + paths[type]

  if (tab) url += `?tab=${tab}`

  return url
}

export function getSlideUrl(
  type,
  providerId,
  courseId,
  lessonId,
  slideId,
  tab
) {
  let baseUrl = getLessonUrl('selected', providerId, courseId, lessonId)
  const paths = {
    list: '/slides',
    add: '/slides/add',
    edit: `/slides/${slideId}/edit`,
    content: `/slides/${slideId}/content`,
    selected: `/slides/${slideId}`,
  }

  let url = baseUrl + paths[type]

  if (tab) url += `?tab=${tab}`

  return url
}

export function getCollectionUrl(type, providerId, collectionId) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/collections',
    add: '/collections/add',
    details: `/collections/${collectionId}/details`,
    edit: `/collections/${collectionId}/edit`,
  }
  return (baseUrl += paths[type])
}

export function getCountryUrl(type, providerId, countryId, tab) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/countries',
    add: '/countries/add',
    edit: `/countries/${countryId}/edit`,
  }

  let url = baseUrl + paths[type]

  if (tab) url += `?tab=${tab}`

  return url
}

export function getStudentUrl(type, providerId, studentId, tab, params) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/students',
    add: '/students/add',
    details: `/students/${studentId}/details`,
    edit: `/students/${studentId}/edit`,
  }

  let url = baseUrl + paths[type]

  if (params) url += params
  if (tab) url += `${params ? '&' : '?'}tab=${tab}`

  return url
}

export function getAdvisorUrl(type, providerId, advisorId, tab, params) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/advisors',
    add: '/advisors/add',
    details: `/advisors/${advisorId}/details`,
    edit: `/advisors/${advisorId}/edit`,
  }

  let url = baseUrl + paths[type]

  if (params) url += params
  if (tab) url += `${params ? '&' : '?'}tab=${tab}`

  return url
}

export function getCourseStatusUrl(
  type,
  providerId,
  courseStatusId,
  params = ''
) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/course-statuses',
    archive: '/course-statuses/archive',
    // details: `/${courseStatusId}/details`,
    edit: `/course-statuses/${courseStatusId}/edit`,
    selected: `/course-statuses/${courseStatusId}`,
  }
  return `${baseUrl}${paths[type]}${params}`
}

export function getQuestionnaireStatusUrl(
  type,
  providerId,
  courseStatusId,
  questionnaireStatusId
) {
  let baseUrl = getCourseStatusUrl('selected', providerId, courseStatusId)
  const paths = {
    list: 'TODO:',
    details: `/questionnaire-statuses/${questionnaireStatusId}/details`,
    edit: `/questionnaire-statuses/${questionnaireStatusId}/edit`,
  }
  return (baseUrl += paths[type])
}

export function getChatUrl(type, providerId, chatId, params) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/chats',
    messages: `/chats/${chatId}/messages`,
  }

  let url = baseUrl + paths[type]
  if (params) url += params

  return url
}

export function getStatisticsUrl(type, providerId, tab) {
  let baseUrl = getProviderUrl('selected', providerId)
  const paths = {
    list: '/statistics',
  }

  let url = baseUrl + paths[type]

  if (tab) url += `?tab=${tab}`

  return url
}
