import PropTypes from 'prop-types'
import React, { useLayoutEffect } from 'react'

import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useId,
  useMergeRefs,
  useTransitionStatus,
} from '@floating-ui/react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import CloseButton from '@ui/buttons/CloseButton'

import { useDialogContext } from './hooks'

/**
 * DialogContent is a component that renders the content of a Dialog.
 * It can be used as a wrapper for any element.
 * @param {React.ReactNode} children - The content of the Dialog.
 * @param {string} title - The title of the Dialog.
 * @param {string} description - The description of the Dialog.
 * @param {string} boxClass - The class name of the Dialog content.
 * @param {string} overlayClass - The class name of the Dialog overlay.
 * @param {function} getBoxClass - A function that returns the class name of the Dialog content.
 * @param {function} getOverlayClass - A function that returns the class name of the Dialog overlay.
 * @param {React.Ref} ref - A ref to the Dialog content.
 */
const DialogContent = React.forwardRef(function DialogContent(props, propRef) {
  const { t } = useTranslation()
  const {
    title,
    kicker,
    description,
    children,
    childrenClass,
    buttons,
    buttonsClass,
    boxClass,
    getBoxClass,
    overlayClass,
    getOverlayClass,
    showOkButton,
    okButtonVariant,
    ...rest
  } = props

  const {
    context: floatingContext,
    setLabelId,
    setDescriptionId,
    open,
    setOpen,
    ...context
  } = useDialogContext()

  const ref = useMergeRefs([context.refs.setFloating, propRef])

  const { isMounted, status } = useTransitionStatus(floatingContext)

  const labelId = useId()
  const descriptionId = useId()

  // Only sets `aria-labelledby` on the Dialog root element if this component is mounted inside it.
  useLayoutEffect(() => {
    if (title) setLabelId(labelId)
    if (description) setDescriptionId(descriptionId)
    return () => setLabelId(undefined)
  }, [title, labelId, setLabelId, description, descriptionId, setDescriptionId])

  const overlayClasses =
    typeof getOverlayClass === 'function'
      ? getOverlayClass(isMounted)
      : overlayClass
  const boxClasses =
    typeof getBoxClass === 'function' ? getBoxClass(isMounted) : boxClass

  if (!isMounted) return null

  return (
    <FloatingPortal>
      <FloatingOverlay
        className={`fixed inset-0 flex items-center justify-center bg-black/50 transition-all duration-500 ease-in-out ${
          status === 'open'
            ? 'z-max opacity-100 backdrop-blur-sm'
            : 'pointer-events-none z-0 opacity-0 backdrop-blur-0'
        } ${overlayClasses}`}
        lockScroll={status === 'open'}
      >
        <FloatingFocusManager
          context={floatingContext}
          modal={status === 'open'}
        >
          <div
            className={`flex max-h-[90vh] transform-gpu flex-col rounded-lg bg-white transition-all duration-500 ease-in-out ${
              status === 'open'
                ? 'translate-y-0 opacity-100 shadow-xl drop-shadow-2xl'
                : 'translate-y-12 opacity-0 shadow-none drop-shadow-none'
            } ${boxClasses}`}
            ref={ref}
            aria-labelledby={context.labelId}
            aria-describedby={context.descriptionId}
            {...context.getFloatingProps(rest)}
          >
            <div className="relative shrink-0 border-b p-6 pb-4">
              <div className="flex flex-col items-stretch gap-1">
                {kicker && (
                  <strong className="text-sm font-bold uppercase text-gray-500">
                    {kicker}
                  </strong>
                )}
                {title && (
                  <h2 className="text-xl font-semibold leading-5" id={labelId}>
                    {title}
                  </h2>
                )}
                {description && (
                  <div className="text-sm text-gray-400" id={descriptionId}>
                    {description}
                  </div>
                )}
              </div>

              <div className="absolute -right-3 -top-3">
                <CloseButton onClick={() => setOpen(false)} variant="solid" />
              </div>
            </div>
            <div
              className={`max-h-[80vh] overflow-y-scroll p-6 shadow-inner hide-scrollbar ${childrenClass}`}
            >
              {typeof children === 'function'
                ? children({ setOpen, open })
                : children}
            </div>
            {(buttons !== undefined || showOkButton) && (
              <div className="flex shrink-0 items-center justify-between gap-4 border-t px-6 py-4">
                <div
                  className={`flex flex-grow items-center gap-4 ${buttonsClass} ${showOkButton ? '' : 'justify-end'}`}
                >
                  {typeof buttons === 'function'
                    ? buttons({ setOpen, open })
                    : buttons}
                </div>

                {showOkButton && (
                  <Button
                    label={t('ok')}
                    onClick={() => setOpen(false)}
                    icon="check"
                    variant={okButtonVariant || 'primary'}
                  />
                )}
              </div>
            )}
          </div>
        </FloatingFocusManager>
      </FloatingOverlay>
    </FloatingPortal>
  )
})
DialogContent.propTypes = {
  boxClass: PropTypes.string,
  buttons: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  buttonsClass: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  childrenClass: PropTypes.string,
  description: PropTypes.node,
  getBoxClass: PropTypes.func,
  getOverlayClass: PropTypes.func,
  kicker: PropTypes.node,
  okButtonVariant: PropTypes.string,
  overlayClass: PropTypes.string,
  showOkButton: PropTypes.bool,
  title: PropTypes.node,
}
DialogContent.defaultProps = {
  boxClass: '',
  childrenClass: '',
  buttonsClass: '',
  overlayClass: '',
}

export default DialogContent
