import PropTypes from 'prop-types'
import React from 'react'

import Button from '@ui/buttons/Button'

import { useModal } from '../hooks'

export default function ModalTrigger({ render, ...btnProps }) {
  const { onOpen } = useModal()

  if (typeof render === 'object')
    return React.cloneElement(render, { onClick: onOpen })

  return (
    <Button
      onClick={() => {
        onOpen()
      }}
      {...btnProps}
    />
  )
}
ModalTrigger.propTypes = {
  /** Element to provide a custom trigger (like a Clickable, Link, etc.). If not set, it will render as fallback a `Button`. */
  'render': PropTypes.object,
  /** Label for the fallback `Button` */
  'label': PropTypes.string,
  /** You can also provide for the fallback all props that `Button` accepts, like `icon`, `variant`, etc. */
  '...btnProps': PropTypes.string,
}
ModalTrigger.defaultProps = {
  label: 'Open modal',
}
