import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import isSameDay from 'date-fns/isSameDay'
import isValid from 'date-fns/isValid'
import startOfDay from 'date-fns/startOfDay'

export function checkDateRange(date, min, max, type) {
  // If type is 'date' we need to set the min as start of the day, otherwise we keep it as is
  min = min && type === 'date' ? startOfDay(min) : min

  // If both min and max are set, check if the date is between them
  if (min && max) {
    return date >= min && date <= max
  }

  // If only min is set, check if the date is after min
  if (min) {
    return date >= min
  }

  // If only max is set, check if the date is before maxDate
  if (max) {
    return date <= max
  }

  // If no min or max is set, return true
  return true
}

export function formatDate(date, dateFormat, options = {}) {
  return isValid(date) ? format(date, dateFormat, options) : ''
}

export function formatDateRange({ from, to }, dateFormat, options = {}) {
  if (!from && !to) {
    return null
  }
  if (from && to) {
    if (isSameDay(from, to)) {
      return formatDate(from, dateFormat, options)
    }
    return `${formatDate(from, dateFormat, options)} – ${formatDate(
      to,
      dateFormat,
      options
    )}`
  }
  return formatDate(from ?? to, dateFormat, options)
}

export function toValidRange({ from, to }) {
  return {
    from: isValid(from) ? from : undefined,
    to: isValid(to) ? to : undefined,
  }
}

export const isDaySelected = (type, day, date) => {
  if (type === 'daterange') {
    const { from, to } = date
    if (from && to) {
      return (
        (isSameDay(day, from) || isAfter(day, from)) &&
        (isSameDay(day, to) || isBefore(day, to))
      )
    }
    return false
  }
  return isSameDay(day, date)
}

export function addDayToRange(day, range) {
  const { from, to } = range || {}
  if (!from) {
    return { from: day, to: undefined }
  }
  if (!to && isSameDay(from, day)) {
    return { from: from, to: day }
  }
  if (!to && isBefore(day, from)) {
    return { from: day, to: from }
  }
  if (!to) {
    return { from, to: day }
  }
  if (isSameDay(to, day) && isSameDay(from, day)) {
    return { from: undefined, to: undefined }
  }
  if (isSameDay(to, day)) {
    return { from: to, to: undefined }
  }
  if (isSameDay(from, day)) {
    return { from: undefined, to: undefined }
  }
  if (isAfter(from, day)) {
    return { from: day, to }
  }
  return { from, to: day }
}
