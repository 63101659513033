import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { useUILanguage } from '@config/i18n'
import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  enabled: true,
}

export default function useShow(id, options = defaultOptions) {
  const { enabled } = { ...defaultOptions, ...options }

  const params = useParams()

  const showId = id || params?.showId

  // Add language to query key to force refetching the show categories in current language
  const { language } = useUILanguage()

  const { data, error, isLoading } = useQuery(
    ['shows', showId, { language }, enabled],
    () => getFetch(showId ? `/api/media-library/shows/${showId}` : null),
    { enabled: !!showId && enabled }
  )

  return { show: data, error, loading: isLoading }
}
