import { useMemo } from 'react'

import { useDesignContext } from '@modules/web/components/DesignProvider'

const shadows = [
  'none', // shadow-none
  'xs', // shadow-xs
  'sm', // shadow-sm
  'default', // shadow
  'md', // shadow-md
  'lg', // shadow-lg
  'xl', // shadow-xl
  '2xl', // shadow-2xl
]

/**
 * Returns the list of available shadows for the current design.
 * @returns {string[]}
 */
export function useAvailableShadows() {
  const design = useDesignContext()
  const { shadows: designShadows = [] } = design || {}

  return useMemo(() => {
    return shadows.filter(shadow => designShadows.includes(shadow))
  }, [designShadows])
}

/**
 * Returns the list of shadow options for the current design.
 * @returns {object[]}
 */
export function useShadowOptions() {
  const availableShadows = useAvailableShadows()

  return useMemo(() => {
    return availableShadows.map(shadow => ({
      value: shadow,
      label: `shadow_${shadow}`,
    }))
  }, [availableShadows])
}

/**
 * Hook to get the shadow class name for a given shadow.
 * @param {string} shadow - The shadow
 * @param {string} [defaultShadow] - The default shadow
 * @returns {object} - The class name and style object
 * @example
 * const { className, style } = useShadowStyles('md')
 * // -> { className: 'shadow-md', style: {} }
 */
export function useShadowStyles(shadow, defaultShadow = 'default') {
  const availableShadows = useAvailableShadows()

  return useMemo(() => {
    const className =
      shadow !== 'default' && availableShadows.includes(shadow)
        ? `shadow-${shadow}`
        : defaultShadow !== 'default' &&
          availableShadows.includes(defaultShadow)
        ? `shadow-${defaultShadow}`
        : 'shadow'

    return {
      className,
      style: {},
    }
  }, [availableShadows, shadow, defaultShadow])
}
