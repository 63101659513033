import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import useSite from '@modules/web/services/hooks/useSite'

function useFontFamilies() {
  const { site } = useSite()

  return Object.entries(site?.design?.fonts ?? {}).reduce(
    (acc, [value, { family }]) => {
      return {
        ...acc,
        [value]: family,
      }
    },
    {}
  )
}

export function useFontFamilyOptions() {
  const { t } = useTranslation('web/content-editor')

  const fontFamilies = useFontFamilies()

  return useMemo(
    () =>
      Object.entries(fontFamilies).map(([value, family]) => ({
        value,
        label: `${t(`fontFamily_${value}`)} ${family ? `(${family})` : ''}`,
      })),

    [fontFamilies, t]
  )
}

export function useFontFamilyStyles(fontFamily = 'body') {
  const fontFamilies = useFontFamilies()

  const family = fontFamilies[fontFamily]

  if (!family) {
    return {}
  }

  return {
    style: {
      fontFamily: family,
    },
  }
}
