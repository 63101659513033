import React from 'react'
import PropTypes from 'prop-types'

export default function MetaItem({ label, value }) {
  return (
    <div className="flex flex-row justify-between px-2 py-1 space-x-2 hover:bg-primary-50">
      <span>{label}</span>
      <span className="font-mono font-semibold">{value}</span>
    </div>
  )
}
MetaItem.propTypes = {
  label: PropTypes.string,
  value: PropTypes.node,
}
