import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'

export default function VariantFields({ variants = {} }) {
  const design = useDesignContext()
  const { t } = useTranslation([design.namespace, 'publications/public'])

  const { actions, data } = useNode(node => ({
    data: node.data.props,
  }))

  const variantOptions = useMemo(() => {
    return Object.keys(variants).map(variant => ({
      value: variant,
      label: t(`PublicationListVariant_${variant}`),
    }))
  }, [variants, t])

  return (
    <Select
      label={t('common:variant')}
      help={t('variantHelp')}
      value={data.variant}
      onChange={e => actions.setProp(props => (props.variant = e.target.value))}
    >
      {variantOptions.map(({ value, label }) => (
        <SelectOption value={value} label={label} key={`variant-${value}`} />
      ))}
    </Select>
  )
}
VariantFields.propTypes = {
  variants: PropTypes.arrayOf(PropTypes.string),
}
