import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import { SelectField, SelectOption } from '@ui/data-entry/Select'

export default function SortFields({ fieldPrefix = '', singular }) {
  const { t } = useTranslation('articles/public')

  return (
    <>
      <BlockField name={`${fieldPrefix}sortField`}>
        {({ fieldProps, value, onDataChange }) => (
          <SelectField
            {...fieldProps}
            label={t('sortField')}
            help={t('sortFieldHelp')}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <SelectOption value="title" label={t('title')} />
            <SelectOption value="publishedAt" label={t('publishedDate')} />
            <SelectOption value="createdAt" label={t('createdDate')} />
            <SelectOption value="updatedAt" label={t('updatedDate')} />
          </SelectField>
        )}
      </BlockField>
      <BlockField name={`${fieldPrefix}sortOrder`}>
        {({ fieldProps, value, onDataChange }) => (
          <SelectField
            {...fieldProps}
            label={t('sortOrder')}
            help={t('sortOrderHelp', { count: singular ? 1 : 0 })}
            value={value}
            onChange={e => onDataChange(e.target.value)}
          >
            <SelectOption value="asc" label={t('ascending')} />
            <SelectOption value="desc" label={t('descending')} />
          </SelectField>
        )}
      </BlockField>
    </>
  )
}

SortFields.propTypes = {
  fieldPrefix: PropTypes.string,
  singular: PropTypes.bool,
}
