import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'

import useChannel from '../../services/hooks/useChannel'
import ChannelSelect from '../ChannelSelect'

const config = {
  name: 'ResourceEpisodes',
  label: 'media-library/public:resourceEpisodes',
  type: 'plugin',
  icon: 'layer-group',
  component: <ResourceEpisodes />,
}

export default function ResourceEpisodes({ channelId, resource, title }) {
  const { t } = useTranslation('media-library/public')

  const { resources } = usePageResources()
  const { channel } = useChannel(channelId)

  const _channel = resources?.Channel ?? channel

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={t('resourceEpisodes')}
    >
      <Box space="xs">
        <Heading as="h6" className="ml-2" text={title} />
        <ul className="ml-8 list-disc text-sm">
          <li>
            {t('channel')}: {_channel?.title}
          </li>
          <li>
            {t('resource')}: {resource}
          </li>
        </ul>
      </Box>
    </Content>
  )
}
ResourceEpisodes.propTypes = {
  channelId: PropTypes.string,
  resource: PropTypes.string,
  title: PropTypes.string,
}
ResourceEpisodes.toolbarItem = config

function ResourceEpisodesSettings() {
  const { t } = useTranslation('media-library/public')
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onChannelUpdate = useCallback(
    (fieldName, parser = x => x) =>
      channel => {
        actions.setProp(props => (props[fieldName] = parser(channel.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap
      title={t('resourceEpisodes')}
      help={t('resourceEpisodesHelp')}
    >
      <Box space="xl">
        {resources?.Channel && !resources?.Collection && (
          <Message className="text-sm" type="warn">
            {resources?.Channel?.title}
          </Message>
        )}
        <Box space="lg">
          <Input
            label={t('title')}
            onChange={onFieldUpdate('title')}
            value={data.title}
          />
          {!resources?.Channel && (
            <ChannelSelect
              label={t('channel')}
              onChange={onChannelUpdate('channelId')}
              value={data.channelId}
              controlled={false}
            />
          )}
          <Select
            label={t('resource')}
            showClear
            onChange={onFieldUpdate('resource')}
            value={data.resource}
          >
            <Select.Placeholder label="-" />
            <Select.Option value="Article" label="Article" />
            <Select.Option value="Course" label="Course" />
            <Select.Option value="Event" label="Event" />
          </Select>
        </Box>
        <Input
          label={t('maxItems')}
          onChange={onFieldUpdate('limit', value => {
            return value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          })}
          value={data.limit}
          type="number"
        />
        <Checkbox
          label={t('openVideoInModal')}
          help={t('openVideoInModalHelp')}
          onChange={value => {
            actions.setProp(props => (props.openInModal = value))
          }}
          value={data.openInModal}
          name="openInModal"
        />
        <PagePicker
          label={t('episodeDetailPageId')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

ResourceEpisodes.craft = {
  displayName: 'ResourceEpisodes',
  props: {
    channelId: undefined,
    episodeDetailPageId: undefined,
    limit: 4,
    openInModal: true,
    resource: '',
    title: '',
  },
  custom: {
    type: config.type,
    resources: ['resourceEpisodes'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: ResourceEpisodesSettings,
  },
}
