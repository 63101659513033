import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useBreakpoint from '@modules/web/components/ContentEditor/shared/useBreakpoint'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'

import useCourse from '../../services/hooks/useCourse'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const config = {
  name: 'CourseHero',
  label: 'courses/public:courseHero',
  help: 'courses/public:courseHeroHelp',
  type: 'plugin',
  icon: 'file-invoice',
  component: <CourseHero />,
}

function getPlaceholder() {
  return {
    title: '[Placeholder title]',
    subtitle: '[Placeholder subtitle]',
    abstract: '[Placeholder for abstract]',
    images: {
      hero: '[ IMAGE ]',
    },
  }
}

export default function CourseHero({
  dynamic,
  courseId,
  variant,
  showPreviewCourse,
  showSaveForLater,
  showStartCourse,
  showSubtitle,
}) {
  const { t } = useTranslation('courses/public')
  const { resources } = usePageResources()
  const { course } = useCourse(dynamic ? null : courseId)
  const { breakpoint } = useBreakpoint()

  const _course = resources?.Course
    ? resources.Course
    : course || getPlaceholder()

  const { abstract, subtitle, title } = _course || {}
  const _subtitle = subtitle || abstract

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
    >
      <div
        className={`space-y-6 flex flex-col items-center px-10 ${
          variant === 'section' ? 'py-32' : 'py-10'
        }`}
      >
        <h1 className="text-3xl font-bold text-center">{title}</h1>
        {showSubtitle && _subtitle && (
          <h2 className="text-xl font-medium text-center">{_subtitle}</h2>
        )}
        <div
          className={`flex gap-2 ${breakpoint === 'xs' ? 'flex-col items-center' : 'flex-row'}`}
        >
          {showStartCourse && (
            <Button label={t('startCourse')} variant="primary" />
          )}
          {showPreviewCourse && (
            <Button label={t('previewCourse')} variant="basic" />
          )}
        </div>
        <div className="self-stretch text-end">
          {showSaveForLater && (
            <Button
              className="-ml-4"
              icon="heart"
              label={t('saveForLater')}
              variant="flat"
            />
          )}
        </div>
      </div>
    </Content>
  )
}
CourseHero.propTypes = {
  providerId: PropTypes.string,
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  variant: PropTypes.string,
  showPreviewCourse: PropTypes.bool,
  showSaveForLater: PropTypes.bool,
  showStartCourse: PropTypes.bool,
  showSubtitle: PropTypes.bool,
}
CourseHero.toolbarItem = config

function CourseHeroSettings() {
  const { t } = useTranslation(['courses/public', 'web/content-editor'])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.CourseProvider && !resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.CourseProvider.title}
          </Message>
        )}
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Select
          label={t('variant')}
          help={t('variantHelp')}
          value={data.variant}
          onChange={onFieldUpdate('variant')}
        >
          <SelectOption
            value="section"
            label={t('web/content-editor:Section')}
          />
          <SelectOption
            value="container"
            label={t('web/content-editor:Container')}
          />
        </Select>
        <Box space="xs">
          <Checkbox
            label={t('subtitle')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showSubtitle}
            name="showSubtitle"
          />
          <Checkbox
            label={t('startCourse')}
            onChange={value => {
              actions.setProp(props => (props.showStartCourse = value))
            }}
            value={data.showStartCourse}
            name="showStart"
          />
          <Checkbox
            label={t('previewCourse')}
            onChange={value => {
              actions.setProp(props => (props.showPreviewCourse = value))
            }}
            value={data.showPreviewCourse}
            name="showPreview"
          />
          {/* <Checkbox
            label={t('saveForLater')}
            onChange={value => {
              actions.setProp(props => (props.showSaveForLater = value))
            }}
            value={data.showSaveForLater}
            name="showSave"
          /> */}
        </Box>
        <PagePicker
          label={t('courseDetailPageId')}
          help={t('courseDetailPageIdHelp')}
          value={data.courseDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.courseDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Course'
          }
        />
        <PagePicker
          label={t('lessonDetailPageId')}
          help={t('lessonDetailPageIdHelp')}
          value={data.lessonDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.lessonDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic &&
            getDynamicResourceIdentifier(page) === 'CourseLesson'
          }
        />
        {!resources?.Course && (
          <>
            <Divider />
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {resources?.CourseProvider ? (
                  <Message className="text-sm" type="warn">
                    {resources.CourseProvider.title}
                  </Message>
                ) : (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseHero.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
    variant: 'section',
    courseDetailPageId: undefined,
    lessonDetailPageId: undefined,
    showPreviewCourse: true,
    showSaveForLater: false,
    showStartCourse: true,
    showSubtitle: true,
  },
  custom: {
    type: config.type,
    resources: ['courseDetail', 'courseActions', 'coursesProviderCountries'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseHeroSettings,
  },
}
