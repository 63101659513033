import { useQuery } from 'react-query'

import { getSitesUrl } from '@modules/web/helpers/siteUrls'
import { getFetch } from '@utils/fetcher'
import { useGlobalParams } from '@utils/urls'

export default function useSite(siteId, options = {}) {
  const paramId = useSiteId()
  const id = siteId || paramId

  const isIdValid = !!id

  const { data, error, isLoading } = useQuery(
    ['sites', id],
    () => {
      if (!isIdValid) return
      return getFetch(`/api/web/sites/${id}`)
    },
    {
      enabled: isIdValid,
      ...options,
    }
  )

  return { site: data, siteId: id, error, loading: isLoading }
}

// These are the paths that should not be considered as site ids
// Add more paths here when more pages are created
const excludeArray = ['add', 'clone', 'list', 'import']

export function useSiteId() {
  const { siteId } = useGlobalParams(getSitesUrl('/:siteId/*'))

  if (excludeArray.includes(siteId)) return undefined

  return siteId
}
