import { useCallback, useState } from 'react'

import { postFetch } from '@utils/fetcher'
import { isObject } from '@utils/types'

import useAIEnabled from './useAIEnabled'

const baseUrl = '/api/ai'

export default function useImproveText() {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState()

  const aiEnabled = useAIEnabled()

  const improveText = useCallback(
    async ({ text, context, modifiers, type, language = 'en' } = {}) => {
      setIsLoading(true)

      const isTextObject = isObject(text)

      const data = await postFetch(`${baseUrl}/improveText`, {
        text: isTextObject ? JSON.stringify(text) : text, // Objects need to be stringified
        context: isTextObject ? JSON.stringify(context) : context, // Objects need to be stringified
        type,
        modifiers,
        language,
      })

      setIsLoading(false)
      setError(data?.error)

      const newText = data?.text

      if (!newText) return text

      return type === 'json' ? JSON.parse(newText) : newText
    },
    []
  )

  return {
    aiEnabled,
    improveText,
    isLoading,
    error,
  }
}
