import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Popover from '@ui/feedback/Popover'
import Box from '@ui/layout/Box'

import { FieldLabel } from './FieldLabel'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function FieldPopover({
  children,
  dismissOptions = {},
  label,
  lightLabel,
  name,
  placement = 'left',
  required,
  tooltip,
  tooltipPlacement,
  trigger,
  triggerIcon = 'gear',
}) {
  const { t } = useTranslation('web/content-editor')

  return (
    <Popover
      placement={placement}
      dismissOptions={{ outsidePress: false, ...dismissOptions }}
      offset={20}
    >
      <Popover.Trigger asChild>
        {context => {
          const { open, getReferenceProps, refs } = context
          return (
            <div className="flex justify-between gap-2" ref={refs.setReference}>
              {label && (
                <FieldLabel
                  label={label}
                  lightLabel={lightLabel}
                  name={name}
                  required={required}
                  tooltip={tooltip}
                  tooltipPlacement={tooltipPlacement}
                />
              )}
              <div className="flex gap-2">
                {!trigger && (
                  <Button
                    icon={triggerIcon}
                    variant={open ? 'link' : 'flat'}
                    size="xs"
                    {...getReferenceProps()}
                  />
                )}
                {trigger && trigger(context)}
              </div>
            </div>
          )
        }}
      </Popover.Trigger>
      <Popover.Content>
        {({ close }) => (
          <Box space="lg" className="max-w-xs">
            <h3 className="flex gap-2 items-center font-semibold border-b pb-2">
              <Icon name="gear" />
              {t('fieldSettings')}
            </h3>
            {children}
            <div className="flex justify-end mt-4 border-t pt-4">
              <Button
                label="Done"
                size="sm"
                variant="primary"
                onClick={close}
              />
            </div>
          </Box>
        )}
      </Popover.Content>
    </Popover>
  )
}

FieldPopover.propTypes = {
  children: PropTypes.node,
  dismissOptions: Popover.propTypes.dismissOptions,
  extra: PropTypes.node,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  label: PropTypes.node,
  lightLabel: PropTypes.bool,
  name: PropTypes.string,
  required: PropTypes.bool,
  trigger: PropTypes.func,
  triggerIcon: PropTypes.string,
  tooltip: PropTypes.string,
  tooltipPlacement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
}
