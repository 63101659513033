import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'

export default function useShows({
  channelId,
  search = '',
  limit = 10,
  page = 1,
  filters = {},
  statuses = ['active', 'disabled'],
  videoOnDemand = true,
  withoutVideoOnDemand = false,
  fetchOnSearch = false,
  sort = 'title',
  sortOrder = 'asc',
  skip,
}) {
  const queryFilter = {}

  // search
  if (search) {
    queryFilter.title = {
      $regex: escapeSpecialChars(search),
      $options: 'i',
    }
  }

  // status
  if (!isEmpty(statuses)) {
    const statusFilter = []
    if (statuses.includes('active')) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (statuses.includes('disabled')) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (statuses.includes('deleted')) {
      statusFilter.push({ deleted: true })
    }
    queryFilter['$or'] = statusFilter
  } else {
    queryFilter.deleted = false
  }

  // categories
  if (!isEmpty(filters['categories'])) {
    queryFilter.categories = { $in: Object.keys(filters['categories']) }
  }

  // ids
  if (!isEmpty(filters['ids'])) {
    queryFilter._id = { $in: filters['ids'] }
  }

  // ignore ids
  if (!isEmpty(filters['ignore-ids'])) {
    queryFilter._id = { $nin: filters['ignore-ids'] }
  }

  // video on demand
  if (videoOnDemand && !withoutVideoOnDemand) {
    queryFilter.videoOnDemand = true
  } else if (!videoOnDemand && withoutVideoOnDemand) {
    queryFilter.videoOnDemand = false
  } else if (!videoOnDemand && !withoutVideoOnDemand) {
    queryFilter.slug = 'xxx-force-no-results'
  }

  const { data, error, isLoading, isFetching } = useQuery(
    [
      'shows',
      {
        channelId,
        search,
        limit,
        page,
        filters,
        sort,
        sortOrder,
        skip,
        videoOnDemand,
        withoutVideoOnDemand,
        statuses,
      },
    ],
    () =>
      getFetch(
        channelId ? `/api/media-library/channels/${channelId}/shows` : null,
        {
          filter: queryFilter,
          sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
          limit: limit,
          skip: skip,
          page: page,
        }
      ),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    shows: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
