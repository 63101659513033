import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  ModelPermissionsWrap,
  PermissionCheckbox,
} from '@modules/users/components/GroupPermissions'

import useCurrentEntity from '../services/hooks/useCurrentEntity'
import useEntity from '../services/hooks/useEntity'
import EntityPicker from './entities/EntityPicker'
import EntityTypePicker from './entities/EntityTypePicker'

export default function EntitiesPermissions() {
  const { t } = useTranslation('entities/public')
  const { watch } = useFormContext()

  const selectedEntityId = watch(`entity.records.0`)
  const canReadSubentities = watch(`subentities[read]`)

  const { entity: currentEntity } = useCurrentEntity()
  const { entity: selectedEntity } = useEntity({ id: selectedEntityId })

  const entity = selectedEntity || currentEntity

  const entityType = selectedEntity ? t('selectedEntity') : t('currentEntity')

  return (
    <div className="space-y-6">
      <EntityPicker
        name="entity.records.0"
        placeholder={t('entityGroupSelectPlaceholder', {
          entity: currentEntity?.name,
        })}
        help={t('entityHelp', { entity: entity?.name })}
      />
      <PermissionCheckbox
        model="entity"
        permission="update"
        label={
          <span className="space-x-2">
            <span>
              {t('canUpdateEntity', {
                entityType,
              })}
              :
            </span>
            <span className="inline-flex gap-1 font-bold text-success-600">
              {entity.name}
              <span className="text-gray-500 font-normal">
                ({t(`type_${entity.type}`)})
              </span>
            </span>
          </span>
        }
        help={t('canUpdateEntityHelp', { entityType })}
      />

      <ModelPermissionsWrap
        model="subentities"
        label={t('subentities')}
        help={t('subentitiesHelp', { entity: entity.name })}
      >
        <div className="flex flex-row justify-between space-x-16">
          <div className="flex flex-col  space-y-3">
            <PermissionCheckbox model="subentities" permission="read" />
            <PermissionCheckbox
              model="subentities"
              permission="create"
              disabled={!canReadSubentities}
            />
            <PermissionCheckbox
              model="subentities"
              permission="update"
              disabled={!canReadSubentities}
            />
            <PermissionCheckbox
              model="subentities"
              permission="delete"
              disabled={!canReadSubentities}
            />
          </div>
          <EntityTypePicker
            name="subentities[type]"
            label={t('subentitiesType')}
            help={t('subentitiesTypeHelp')}
          />
        </div>
      </ModelPermissionsWrap>

      <ModelPermissionsWrap
        model="services"
        label={t('services')}
        help={t('servicesHelp', { entity: entity.name })}
      >
        <div className="flex flex-row justify-between space-x-16">
          <div className="flex flex-col  space-y-3">
            <PermissionCheckbox model="services" permission="read" />
            <PermissionCheckbox model="services" permission="create" />
            <PermissionCheckbox model="services" permission="update" />
            <PermissionCheckbox model="services" permission="delete" />
          </div>
        </div>
      </ModelPermissionsWrap>
    </div>
  )
}
