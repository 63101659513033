import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { useRules } from '../validationHooks'
import { ListboxDivider } from './ListboxDivider'
import { ListboxField } from './ListboxField'
import { ListboxOption } from './ListboxOption'
import { ListboxOptions } from './ListboxOptions'

export default function ListboxController({
  by,
  children,
  className,
  disabled,
  fullWidth = true,
  help,
  label,
  name,
  onChange,
  options,
  placeholder = 'none',
  renderSelectedLabel,
  getSelectedOption,
  required,
  shouldUnregister,
  value,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = useCallback(
    field => newValue => {
      field.onChange(newValue)

      if (typeof onChange === 'function') {
        onChange(newValue)
      }
    },
    [onChange]
  )

  return (
    <Controller
      control={control}
      defaultValue={value || ''}
      name={name}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <ListboxField
          by={by}
          className={className}
          name={name}
          label={label}
          help={help}
          error={fieldState.error}
          required={rules?.required?.value}
          disabled={disabled}
          fullWidth={fullWidth}
          onChange={onFieldChange(field)}
          placeholder={placeholder}
          value={field.value}
          renderSelectedLabel={renderSelectedLabel}
          getSelectedOption={getSelectedOption}
          options={options}
        >
          {children}
        </ListboxField>
      )}
    />
  )
}

ListboxController.propTypes = {
  by: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  rules: PropTypes.object,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  placeholder: PropTypes.string,
  renderSelectedLabel: PropTypes.func,
  getSelectedOption: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

ListboxController.displayName = 'Listbox'

ListboxController.Options = ListboxOptions
ListboxController.Options.displayName = 'Listbox.Options'
ListboxController.Option = ListboxOption
ListboxController.Option.displayName = 'Listbox.Option'
ListboxController.Divider = ListboxDivider
ListboxController.Divider.displayName = 'Listbox.Divider'
