import React from 'react'
import PropTypes from 'prop-types'

import Label from '@ui/data-display/Label'

export default function ContactInfo({ email, phone, siteURL }) {
  return (
    <div className="flex flex-col">
      {siteURL && (
        <a href={siteURL}>
          <Label icon="globe" label={siteURL} />
        </a>
      )}
      {phone && (
        <a href={`tel:${phone}`}>
          <Label icon="phone" label={phone} />
        </a>
      )}
      {email && (
        <a href={`mailto:${email}`}>
          <Label icon="at" label={email} />
        </a>
      )}
    </div>
  )
}
ContactInfo.propTypes = {
  email: PropTypes.string,
  phone: PropTypes.string,
  siteURL: PropTypes.string,
}
