import PropTypes from 'prop-types'
import React from 'react'

export default function Footnote({ children, className, text }) {
  return React.createElement(
    'div',
    { className: `text-xs ${className}` },
    children || text
  )
}

Footnote.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  text: PropTypes.string,
}
Footnote.defaultProps = {
  className: '',
}
