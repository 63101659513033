import PropTypes from 'prop-types'
import React from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import useSite from '@modules/web/services/hooks/useSite'
import Button from '@ui/buttons/Button'
import Label from '@ui/data-display/Label'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const config = {
  name: 'CoursesStudentProfile',
  label: 'courses/public:courseStudentProfile',
  help: 'courses/public:courseStudentProfileHelp',
  type: 'plugin',
  icon: 'clipboard-user',
  component: <StudentProfile />,
}

function getPlaceholder() {
  return {
    username: '[Placeholder username]',
    createdAt: '[Placeholder created at]',
    email: '[Placeholder email]',
    birthday: '[Placeholder birthday]',
    address: {
      name: '[Placeholder name]',
      street: '[Placeholder street]',
      additionalAddress: '[Placeholder additional address]',
      zip: '[Placeholder zip]',
      city: '[Placeholder city]',
      country: '[Placeholder country]',
      phone: '[Placeholder phone]',
    },
  }
}

export default function StudentProfile({ allowEdit }) {
  const { t } = useTranslation(['courses/public', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const student = getPlaceholder()

  const { username, createdAt, email, address, birthday } = student
  const { name, street, additionalAddress, zip, city, country, phone } = address

  const errors = []

  if (!authEnabled) {
    errors.push({ title: t('web-auth/public:authDisabledNotification') })
  }

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      errors={errors}
    >
      {authEnabled ? (
        <div className="space-y-6 p-4">
          <h1 className="text-3xl font-bold">{name}</h1>
          <div className="space-y-2">
            <div className="flex flex-col gap-px">
              <Label icon="user" label={username} />
              <Label icon="clock" label={createdAt} />
              {birthday && <Label icon="circle" label={birthday} />}
            </div>
          </div>
          <div className="space-y-4">
            <h2 className="text-xl font-bold">{t('address')}</h2>
            <div className="flex gap-2">
              <div className="-mt-px">
                <Icon name="map-marker-alt" />
              </div>
              <div>
                <div>{street}</div>
                <div>{additionalAddress}</div>
                <div className="flex gap-2">
                  <div>{zip}</div>
                  <div>{city}</div>
                </div>
                <div>{country}</div>
              </div>
            </div>
            <div className="flex flex-col gap-px">
              <Label icon="envelope" label={email} />
              <Label icon="phone" label={phone} />
            </div>
            {allowEdit && (
              <div>
                <Button label={t('web-auth/public:editProfile')} />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="p-2">
          <Message
            type="warn"
            text={t('web-auth/public:authDisabledNotification')}
          />
        </div>
      )}
    </Content>
  )
}
StudentProfile.propTypes = {
  allowEdit: PropTypes.bool,
}
StudentProfile.toolbarItem = config

function StudentProfileSettings() {
  const { t } = useTranslation(['courses/public', 'web-auth/public'])
  const { site } = useSite()

  const authEnabled = site?.auth?.enabled

  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Checkbox
        label={t('web-auth/public:allowEdit')}
        onChange={value => {
          actions.setProp(props => (props.allowEdit = value))
        }}
        value={data.allowEdit}
        name="allowEdit"
        disabled={!authEnabled}
      />
    </SettingsWrap>
  )
}

StudentProfile.craft = {
  displayName: config.name,
  props: {
    allowEdit: true,
  },
  custom: {
    type: config.type,
    resources: ['coursesStudentProfile'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: StudentProfileSettings,
  },
}
