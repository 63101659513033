import PropTypes from 'prop-types'
import React from 'react'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

const previewSizesStyles = {
  xs: 'w-4 h-4 text-[.5rem]',
  sm: 'w-6 h-6 text-[.625rem]',
  md: 'w-8 h-8 text-xs',
  lg: 'w-10 h-10 text-sm',
  xl: 'w-12 h-12 text-base',
}

export default function ColorPreview({
  animate,
  className,
  number,
  color,
  hideLabel,
  hideNull,
  size,
}) {
  if (!color && hideNull) return null

  const isTransparent = color === 'transparent'
  const isCurrentColor = color === 'currentColor'
  const hasColor = color && !isTransparent && !isCurrentColor

  const sizeStyle = previewSizesStyles[size] || previewSizesStyles.sm

  const animateStyle = animate
    ? 'transform group-hover/color:scale-125  transition-all ease-in-out duration-150'
    : ''

  const backgroundStyle =
    hasColor || isCurrentColor ? '' : 'bg-checkered-sm bg-gray-300'

  return (
    <div className={`group/color flex gap-2 items-center ${className}`}>
      <div
        className={`isolate relative flex items-center justify-center border-white border-2 shadow-sm rounded drop-shadow-md ring-1 ring-black ring-opacity-25  text-transparent group-hover/color:text-white ${animateStyle} ${sizeStyle} ${backgroundStyle}`}
        style={hasColor ? { backgroundColor: color } : undefined}
      >
        {isCurrentColor ? (
          <Icon
            name="text"
            className="text-primary-500 group-[.isSelected]:text-primary-100 text-xl"
          />
        ) : (
          (!hasColor || isTransparent) && (
            <div className="absolute w-full origin-center -rotate-45 border-t border-t-danger-700 z-0 scale-125" />
          )
        )}

        <span className="z-10 drop-shadow-[0_0_2px_rgba(0,0,0,.8)] scale-90">
          {number}
        </span>
      </div>
      {!hideLabel && <div className="font-mono text-gray-600">{color}</div>}
    </div>
  )
}
ColorPreview.propTypes = {
  animate: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  hideNull: PropTypes.bool,
  number: PropTypes.string,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
}
ColorPreview.defaultProps = {
  className: '',
  animate: true,
  size: 'md',
}
