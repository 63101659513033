import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import useChannel from '@modules/media-library/services/hooks/useChannel'
import useShow from '@modules/media-library/services/hooks/useShow'
import isFieldEnabled from '@modules/web/components/ContentEditor/helpers/isFieldEnabled'
import BlockField from '@modules/web/components/ContentEditor/shared/BlockField'
import { BlockColorSelect } from '@modules/web/components/ContentEditor/shared/ColorSelect'
import ColumnsSelect from '@modules/web/components/ContentEditor/shared/ColumnsSelect'
import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { useBlock } from '@modules/web/helpers/useBlock'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import {
  CheckboxField as Checkbox,
  CheckboxField,
} from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import {
  SelectField as Select,
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'
import Divider from '@ui/layout/Divider'
import Pagination from '@ui/navigation/Pagination'
import Heading from '@ui/typography/Heading'

import { ChannelPicker } from '../ChannelPicker'
import { ShowPicker } from '../ShowPicker'

const config = {
  name: 'EpisodesList',
  label: 'media-library/public:episodesList',
  type: 'plugin',
  icon: 'bars',
  component: <EpisodesList />,
}

export default function EpisodesList({
  dynamic,
  limit,
  skip,
  sortField = 'publishedAt',
  sortOrder = 'desc',
  title,
  channelId,
  showId,
  showPagination,
}) {
  const { dynamic: isDynamicSupported } = useBlock(config.name)
  const { t } = useTranslation('media-library/public')
  const sort = `${sortOrder === 'desc' ? '-' : ''}${sortField}`
  const showContent = false

  const { resources } = usePageResources()

  const { channel: staticChannel } = useChannel(channelId)
  const { show: staticShow } = useShow(showId)

  const _channel =
    !isDynamicSupported || dynamic ? resources?.Channel : staticChannel
  const _show = !isDynamicSupported || dynamic ? resources?.Show : staticShow

  return (
    <Content icon={config.icon} title={t('episodesList')}>
      {showContent ? (
        <div>TODO</div>
      ) : (
        <Box space="xs">
          {title && <Heading as="h6" className="ml-2" text={title} />}
          <ul className="ml-8 list-disc text-sm">
            <li>
              {t('channel')}: {_channel?.title}
            </li>
            <li>
              {t('show')}: {_show?.title || t('dynamic')}
            </li>
            <li>
              {t('sort')}: {sort}
            </li>
            <li>
              {t('limit')}: {limit}
            </li>
            <li>
              {t('skip')}: {skip}
            </li>
          </ul>
          {showPagination && (
            <Pagination page={1} pageSize={limit} total={100} />
          )}
        </Box>
      )}
    </Content>
  )
}
EpisodesList.propTypes = {
  dynamic: PropTypes.bool,
  limit: PropTypes.number,
  skip: PropTypes.number,
  sortField: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  title: PropTypes.string,
  channelId: PropTypes.string,
  showId: PropTypes.string,
  showPagination: PropTypes.bool,
}
EpisodesList.defaultProps = {}
EpisodesList.toolbarItem = config

function EpisodesListSettings() {
  const design = useDesignContext()
  const { t } = useTranslation(['media-library/public', design.namespace])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(!data.dynamic)
  const [showSortOrder, setShowSortOrder] = useState(!!data.sortField)
  const { resources } = usePageResources()
  const { channel: staticChannel } = useChannel(data.channelId)
  const { show: staticShow } = useShow(data.showId)
  const {
    columns,
    displayModes = [],
    displayModeOptions: displayModeConfig,
    dynamic,
    enableAnimations,
    horizontal,
    itemBackgroundColor,
    playInline,
    showPagination,
    showShow,
    useCardPadding,
  } = useBlock(config.name)

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const channel = !dynamic || data.dynamic ? resources?.Channel : staticChannel
  const show = !dynamic || data.dynamic ? resources?.Show : staticShow

  const displayModeOptions = useMemo(() => {
    if (displayModes.length > 0) {
      return displayModes.map(displayMode => ({
        value: displayMode,
        label: t(`${design.namespace}:episodeListDisplayMode_${displayMode}`),
      }))
    }
    return []
  }, [design.namespace, displayModes, t])

  return (
    <SettingsWrap title={t('episodesList')} help={t('episodesListHelp')}>
      <Box space="xl">
        {dynamic && (
          <Checkbox
            label={t('dynamicEpisode')}
            help={t('dynamicEpisodeHelp')}
            onChange={value => {
              setShowPicker(!value)
              actions.setProp(props => (props.dynamic = value))
            }}
            value={data.dynamic}
            name="dynamic"
          />
        )}
        {dynamic && showPicker && (
          <>
            <ChannelPicker
              name="channelId"
              value={data.channelId}
              onChange={value => {
                actions.setProp(props => (props.channelId = value))
              }}
            />
            {data.channelId && (
              <ShowPicker
                name="showId"
                channelId={data.channelId}
                value={data.showId}
                onChange={value => {
                  actions.setProp(props => (props.showId = value))
                }}
              />
            )}
          </>
        )}
        {channel && !show && (
          <Message className="text-sm" type="warn">
            {channel.title}
          </Message>
        )}
        {show && (
          <Message className="text-sm" type="warn">
            {show.title}
          </Message>
        )}
        <Input
          label={t('title')}
          onChange={onFieldUpdate('title')}
          value={data.title}
        />
        <Select
          label={t('sortField')}
          help={t('episodesListSortFieldHelp')}
          value={data.sortField}
          onChange={e => {
            const sortField = e.target.value
            setShowSortOrder(!!sortField)
            actions.setProp(props => (props.sortField = sortField))
          }}
          showClear
        >
          <Select.Placeholder label="-" />
          <SelectOption
            value="publishedAt"
            label={t('sortByPublicationDate')}
          />
          <SelectOption value="playsPerDay" label={t('sortByPlaysPerDay')} />
          <SelectOption value="plays" label={t('sortByPlays')} />
          <SelectOption value="rating" label={t('sortByRating')} />
        </Select>
        {showSortOrder && (
          <Select
            label={t('sortOrder')}
            help={t('sortOrderHelp')}
            value={data.sortOrder}
            onChange={onFieldUpdate('sortOrder')}
          >
            <SelectOption value="asc" label={t('ascending')} />
            <SelectOption value="desc" label={t('descending')} />
          </Select>
        )}
        <Input
          label={t('limit')}
          help={t('limitHelp')}
          onChange={onFieldUpdate('limit', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.limit}
          type="number"
        />
        <Input
          label={t('skip')}
          help={t('skipHelp')}
          onChange={onFieldUpdate('skip', value =>
            value ? parseInt(isNaN(value) || value < 0 ? 0 : value) : 0
          )}
          value={data.skip}
          type="number"
        />
        {showPagination && (
          <Checkbox
            name="showPagination"
            label={t('pagination')}
            help={t('paginationHelp')}
            value={data.showPagination}
            onChange={checked =>
              actions.setProp(p => (p.showPagination = checked))
            }
          />
        )}
        {displayModes.length > 0 && (
          <>
            <Divider />
            {displayModes.length > 0 && (
              <Select
                name="displayMode"
                label={t('displayMode')}
                help={t('displayModeHelp')}
                value={data.displayMode}
                onChange={onFieldUpdate('displayMode')}
              >
                {displayModeOptions.map(({ value, label }) => (
                  <SelectOption
                    value={value}
                    label={label}
                    key={`displayMode-${value}`}
                  />
                ))}
              </Select>
            )}
          </>
        )}

        {displayModeConfig?.[data.displayMode] && (
          <>
            {displayModeConfig[data.displayMode].itemVariants && (
              <BlockField name="listItemVariant">
                {({ fieldProps, value, onDataChange }) => (
                  <SelectField
                    {...fieldProps}
                    label={t(`${design.namespace}:articleItemVariant`)}
                    help={t(`${design.namespace}:articleItemVariantHelp`)}
                    onChange={e => onDataChange(e.target.value)}
                    value={value}
                  >
                    {value && (
                      <SelectOption label={t('clearSelection')} value={null} />
                    )}
                    <SelectPlaceholder label="-" />
                    {displayModeConfig[data.displayMode].itemVariants.map(
                      key => (
                        <SelectOption
                          value={key}
                          label={t(`${design.namespace}:size-${key}`)}
                          key={key}
                        />
                      )
                    )}
                  </SelectField>
                )}
              </BlockField>
            )}
          </>
        )}

        {isFieldEnabled(columns, displayModes, data.displayMode) && (
          <ColumnsSelect name="columns" />
        )}

        {isFieldEnabled(
          itemBackgroundColor,
          displayModes,
          data.displayMode
        ) && (
          <BlockColorSelect
            name="itemBackgroundColor"
            label={t('itemBackgroundColor')}
          />
        )}
        {isFieldEnabled(useCardPadding, displayModes, data.displayMode) && (
          <CheckboxField
            name="useCardPadding"
            value={data.useCardPadding}
            label={t('useCardPadding')}
            help={t('useCardPaddingHelp')}
            onChange={value =>
              actions.setProp(props => (props.useCardPadding = value))
            }
          />
        )}
        {isFieldEnabled(horizontal, displayModes, data.displayMode) && (
          <CheckboxField
            name="horizontal"
            value={data.horizontal}
            label={t('horizontal')}
            help={t('cardHorizontalHelp')}
            onChange={value =>
              actions.setProp(props => (props.horizontal = value))
            }
          />
        )}
        {isFieldEnabled(playInline, displayModes, data.displayMode) && (
          <CheckboxField
            name="playInline"
            value={data.playInline}
            label={t('playInline')}
            help={t('playInlineHelp')}
            onChange={value =>
              actions.setProp(props => (props.playInline = value))
            }
          />
        )}
        <Checkbox
          name="showDescription"
          label={t('showDescriptionInCards')}
          help={t('showDescriptionOfEpisodesInCardsHelp')}
          value={data.showDescription}
          onChange={checked =>
            actions.setProp(p => (p.showDescription = checked))
          }
        />
        {showShow && (
          <Checkbox
            name="showShow"
            label={t('showShowInCards')}
            help={t('showShowInCardsHelp')}
            value={data.showShow}
            onChange={checked => actions.setProp(p => (p.showShow = checked))}
          />
        )}
        {enableAnimations && (
          <Checkbox
            name="enableAnimations"
            label={t('enableAnimations')}
            help={t('enableAnimationsCardsHelp')}
            value={data.enableAnimations}
            onChange={checked =>
              actions.setProp(p => (p.enableAnimations = checked))
            }
          />
        )}
        <Divider />
        <PagePicker
          label={t('episodeDetailPageId')}
          help={t('episodeDetailPageIdHelp')}
          value={data.episodeDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.episodeDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Episode'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

EpisodesList.craft = {
  displayName: 'EpisodesList',
  props: {
    dynamic: true,
    title: '',
    sortField: 'publishedAt',
    sortOrder: 'desc',
    limit: 8,
    skip: 0,
    episodeDetailPageId: undefined,
    showDescription: true,
  },
  custom: {
    type: config.type,
    resources: ['episodes', 'show'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: EpisodesListSettings,
  },
}
