import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import { SideSelectField } from './Field'

export function SideSelectController({
  className,
  extra,
  help,
  isMixed,
  label,
  labelKey,
  name,
  options,
}) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <SideSelectField
          className={className}
          extra={extra}
          help={help}
          isMixed={isMixed}
          label={label}
          labelKey={labelKey}
          name={name}
          onChange={field.onChange}
          options={options}
          value={field.value}
        />
      )}
    />
  )
}

SideSelectController.propTypes = {
  className: PropTypes.string,
  extra: PropTypes.node,
  help: PropTypes.string,
  isMixed: PropTypes.bool,
  label: PropTypes.string,
  labelKey: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.string),
}

export default SideSelectController
