import React, { useCallback, useMemo, useState } from 'react'

import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router'

import Header from '@ui/data-display/Header'
import Message from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import Password from '@ui/data-entry/Password'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'
import Link from '@ui/navigation/Link'

import AuthService from '../services/AuthService'

function useResetPassword() {
  const [message, setMessage] = useState()
  const data = useMemo(() => ({ password: '' }), [])

  const navigate = useNavigate()
  const { token } = useParams()

  const onResetPassword = useCallback(
    async ({ password }) => {
      try {
        const { error, loggedIn } = await AuthService.resetPassword(
          password,
          token
        )
        setMessage(
          error
            ? { text: error.code, type: 'danger' }
            : { text: 'resetPasswordUpdated', type: 'success' }
        )

        if (loggedIn) {
          navigate('/auth/profile')
        }
      } catch (error) {
        setMessage({ text: 'resetPasswordError', type: 'danger' })
      }
    },
    [navigate, token]
  )

  return { data, message, onResetPassword }
}

export default function ResetPassword() {
  const { t } = useTranslation('auth')
  const { onResetPassword, message, data } = useResetPassword()

  return (
    <>
      <Header
        kicker={t('resetPassword')}
        title={t('resetPasswordTitle')}
        subtitle={t('resetPasswordSubtitle')}
        headTitle={t('resetPassword')}
      />
      {message && (
        <Message
          title={t(message.title)}
          text={t(message.text)}
          type={message.type}
        />
      )}
      <Form data={data} onSubmit={onResetPassword}>
        <Box space="xl">
          <Password name="password" label={t('newPassword')} required />
          <Submit label={t('setNewPassword')} icon="key" />
        </Box>
      </Form>
      <p>
        <Link href="/auth/login">{t('backToLogin')}</Link>
      </p>
    </>
  )
}
