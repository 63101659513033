import PropTypes from 'prop-types'
import React from 'react'

/**
 * A clickable div with some basic a11y.
 */
export default function Clickable({
  as,
  children,
  className,
  clickableClass,
  disabled,
  innerRef,
  keyCode,
  onClick,
  onDoubleClick,
  role,
  ...props
}) {
  if (disabled || typeof (onDoubleClick ?? onClick) !== 'function') {
    return React.createElement(as, { ...props, className }, children)
  }

  return React.createElement(
    as,
    {
      ...props,
      className: `cursor-pointer ${className} ${clickableClass}`,
      onClick,
      onDoubleClick,
      onKeyDown: event => {
        if (event.key === keyCode) {
          onClick(event)
        }
      },
      role,
      tabIndex: 0,
      ref: innerRef,
    },
    children
  )
}

Clickable.propTypes = {
  /**
   * Component's tag name
   */
  as: PropTypes.oneOf(['div', 'span', 'td', 'th']),
  /**
   * Component's children
   */
  children: PropTypes.node,
  /**
   * Component's extra classes
   */
  className: PropTypes.string,
  /**
   * Component's extra classes, but only if is clickable
   */
  clickableClass: PropTypes.string,
  /**
   * Defindes if the click should be disabled
   */
  disabled: PropTypes.bool,
  /**
   * DOM reference provided to the clickable element
   */
  innerRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /**
   * Pressed key code expected to trigger the onClick event
   */
  keyCode: PropTypes.string,
  /**
   * Provided onClick event
   */
  onClick: PropTypes.func,
  onDoubleClick: PropTypes.func,
  role: PropTypes.oneOf(['link']),
}
Clickable.defaultProps = {
  as: 'div',
  className: '',
  clickableClass: '',
  keyCode: 'Enter',
  role: 'link',
}
