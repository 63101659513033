import { useMutation, useQueryClient } from 'react-query'

import { supportedLanguages } from '@config/i18n'
import { deleteFetch, patchFetch, postFetch } from '@utils/fetcher'

const baseUrl = '/api/groups'

export function useCreateOrUpdateGroup() {
  const queryClient = useQueryClient()

  return useMutation(
    group =>
      group.id
        ? patchFetch(`${baseUrl}/${group.id}`, group)
        : postFetch(baseUrl, group),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups')
      },
    }
  )
}

export function useCreateUsersToGroup(group) {
  const queryClient = useQueryClient()
  const url = `${baseUrl}/${group.id}/create-users`

  return useMutation(
    user => patchFetch(url, { users: [user], supportedLanguages }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['groups', group.id])
      },
    }
  )
}

export function useAddUsersToGroup(group) {
  const queryClient = useQueryClient()
  const url = `${baseUrl}/${group.id}/add-users`

  return useMutation(userId => patchFetch(url, { userIds: [userId] }), {
    onSuccess: () => {
      queryClient.invalidateQueries(['groups', group.id])
    },
  })
}

export function useRemoveUsersFromGroup(group) {
  const queryClient = useQueryClient()
  const url = `${baseUrl}/${group.id}/remove-users`

  return useMutation(userId => patchFetch(url, { userIds: [userId] }), {
    onSuccess: () => {
      queryClient.invalidateQueries('groups')
    },
  })
}

export function useDeleteGroup(group) {
  const queryClient = useQueryClient()
  const url = `${baseUrl}/${group.id}`

  return useMutation(() => deleteFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('groups')
    },
  })
}

export function useRestoreGroup(group) {
  const queryClient = useQueryClient()
  const url = group ? `${baseUrl}/${group.id}/restore` : null

  return useMutation(() => patchFetch(url), {
    onSuccess: () => {
      queryClient.invalidateQueries('groups')
    },
  })
}

export function useToggleGroup(group) {
  const queryClient = useQueryClient()

  return useMutation(
    () =>
      patchFetch(
        `${baseUrl}/${group.id}/${group.enabled ? 'disable' : 'enable'}`
      ),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('groups')
      },
    }
  )
}
