import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'

const defaultOptions = {
  enabled: true,
}
export default function useEpisode(id, options = defaultOptions) {
  const { enabled } = { ...defaultOptions, ...options }
  const params = useParams()

  const episodeId = id || params?.episodeId

  const { data, error, isLoading } = useQuery(
    ['episodes', episodeId],
    () =>
      getFetch(episodeId ? `/api/media-library/episodes/${episodeId}` : null),
    { enabled: !!episodeId && enabled }
  )

  return { episode: data, error, loading: isLoading }
}
