import PropTypes from 'prop-types'
import React from 'react'

import Field from '@ui/data-entry/Field'

import Autocomplete from './Autocomplete'

export default function AutocompleteField({
  children,
  className,
  disabled,
  displayIcon,
  displayMultiple,
  displayValue,
  error,
  help,
  label,
  loading,
  loadingText,
  multiple,
  name,
  nullable,
  onChange,
  onSearch,
  placeholder,
  required,
  value,
  by,
  searchText,
  showValueInline,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      help={help}
      error={error}
      required={required}
      disabled={disabled}
    >
      <Autocomplete
        disabled={disabled}
        displayIcon={displayIcon}
        displayMultiple={displayMultiple}
        displayValue={displayValue}
        multiple={multiple}
        nullable={nullable}
        loading={loading}
        loadingText={loadingText}
        onChange={onChange}
        onSearch={onSearch}
        placeholder={placeholder}
        value={value}
        name={name}
        by={by}
        searchText={searchText}
        showValueInline={showValueInline}
      >
        {children}
      </Autocomplete>
    </Field>
  )
}
AutocompleteField.propTypes = {
  children: PropTypes.node,
  by: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  displayIcon: PropTypes.node,
  displayMultiple: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  displayValue: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  error: PropTypes.object,
  help: PropTypes.string,
  label: PropTypes.string,
  loading: PropTypes.bool,
  loadingText: PropTypes.node,
  name: PropTypes.string,
  multiple: PropTypes.bool,
  nullable: PropTypes.bool,
  onChange: PropTypes.func,
  onSearch: PropTypes.func,
  placeholder: PropTypes.node,
  required: PropTypes.bool,
  searchText: PropTypes.string,
  showValueInline: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),
}
