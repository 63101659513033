import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import unset from 'lodash/unset'
import { useTranslation } from 'react-i18next'

import BlockField from '../BlockField'
import BorderStyleSelect from './Field'

export default function BlockBorderStyleSelect({ name = 'borderStyle' }) {
  const { t } = useTranslation('web/content-editor')

  const onChange = useCallback(
    (onDataChange, actions, breakpoint) => event => {
      const newValue = event.target.value
      // Clear this breakpoint when clearing the 'all' field
      if (!newValue) {
        actions.setProp(data => {
          unset(data, `${name}.${breakpoint}`)
        })
      } else {
        onDataChange(newValue)
      }
    },
    [name]
  )

  return (
    <BlockField name={name} responsive>
      {({
        fieldProps,
        value,
        inheritFrom,
        inheritValue,
        onDataChange,
        actions,
        breakpoint,
      }) => (
        <BorderStyleSelect
          {...fieldProps}
          label={t('borderStyle')}
          onChange={onChange(onDataChange, actions, breakpoint)}
          value={value}
          inheritValue={inheritValue}
          inheritFrom={inheritFrom}
        />
      )}
    </BlockField>
  )
}
BlockBorderStyleSelect.propTypes = {
  name: PropTypes.string,
}
