import { useEffect } from 'react'

import { useQuery } from 'react-query'
import { useNavigate, useParams } from 'react-router-dom'

import { getFetch } from '@utils/fetcher'
import { useGlobalParams } from '@utils/urls'

import getSiteUrl from '../../helpers/siteUrls'
import useSite, { useSiteId } from './useSite'

export default function usePage(id, enabled = true, siteId = null) {
  const paramsSiteId = useSiteId()
  const _siteId = siteId ?? paramsSiteId

  const params = useGlobalParams('/web/sites/:siteId/pages/:pageId/*')
  const pageId = id ?? params.pageId

  const { data, error, isLoading } = useQuery(
    ['pages', pageId],
    () =>
      getFetch(_siteId ? `/api/web/sites/${_siteId}/pages/${pageId}` : null),
    {
      enabled: enabled && !!(_siteId && pageId),
    }
  )

  return { page: data, pageId, error, loading: isLoading }
}

export function useRedirectWhenPageIsDeleted(
  siteAction = 'pages',
  pageId = null
) {
  const { site } = useSite()
  const { page } = usePage(pageId)
  const navigate = useNavigate()

  const { deleted } = page || {}

  useEffect(() => {
    if (deleted) {
      navigate(getSiteUrl(site, siteAction))
    }
  }, [deleted, site, siteAction, navigate])
}

export const usePageId = () => {
  const { pageId } = useParams()
  return pageId
}
