import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { escapeSpecialChars } from '@utils/strings'

export default function useArticles({
  categories = [],
  flag = null,
  flagExceptions = [],
  search = '',
  statuses = ['active'],
  fetchOnSearch = false,
  siteId,
  limit = 10,
  notPublished = false,
  organizations = [],
  page = 1,
  pagination = false,
  sort = '',
  order = null,
  skip = 0,
  useSiteEntity,
}) {
  const { data, error, isLoading, isFetching } = useQuery(
    [
      'articles',
      {
        search,
        statuses,
        limit,
        sort,
        order,
        skip,
        organizations,
        pagination,
        page,
        categories,
        flag,
        flagExceptions,
        siteId,
        useSiteEntity,
      },
    ],
    () =>
      getFetch('/api/articles', {
        categories,
        flag,
        flagExceptions,
        siteId,
        notPublished,
        statuses: typeof statuses === 'string' ? [statuses] : statuses,
        search: escapeSpecialChars(search),
        sort,
        order,
        organizations,
        limit,
        skip: pagination ? null : skip,
        page,
        useSiteEntity,
      }),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    articles: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
