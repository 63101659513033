import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import Box from '@ui/layout/Box'

import useProvider from '../../services/hooks/useProvider'
import { CoursePicker } from '../CoursePicker'
import ProviderSelect from '../ProviderSelect'

const config = {
  name: 'CourseRegistration',
  label: 'courses/public:courseRegistration',
  help: 'courses/public:courseRegistrationHelp',
  type: 'plugin',
  icon: 'file-invoice',
  component: <CourseRegistration />,
}

export default function CourseRegistration({ dynamic, providerId, email }) {
  const { t } = useTranslation('courses/public')
  const { resources } = usePageResources()

  const { provider } = useProvider(dynamic ? null : providerId)

  const _provider = resources?.CourseProvider
    ? resources.CourseProvider
    : provider

  const contactEmail = email || _provider?.contactEmail

  const errors = []
  if (!contactEmail) {
    errors.push({ title: t('contactEmailMissing') })
  }

  return (
    <Content
      className="w-full"
      icon={config.icon}
      title={config.label}
      locked={dynamic}
      errors={errors}
    >
      <div className="space-y-4 p-2">
        <h2 className="text-xl font-bold">
          {t('courseRegsitrationRegisterTitle')}
        </h2>
        <p>{t('courseRegsitrationRegisterDescription')}</p>
        <div className="space-y-2">
          <Input name="firstName" label={t('firstName')} />
          <Input name="lastName" label={t('lastName')} />
          <Input name="street" label={t('streetAndNumber')} />
          <div className="flex gap-4">
            <Input className="w-1/3" name="zipcode" label={t('zip')} />
            <Input className="w-2/3" name="city" label={t('city')} />
          </div>
          <Input name="street" label={t('country')} />
          <div className="pt-4">
            <Button label={t('submit')} />
          </div>
        </div>
      </div>
    </Content>
  )
}
CourseRegistration.propTypes = {
  providerId: PropTypes.string,
  dynamic: PropTypes.bool,
  courseId: PropTypes.string,
  email: PropTypes.string,
}
CourseRegistration.toolbarItem = config

function CourseRegistrationSettings() {
  const { t } = useTranslation(['courses/public', 'web/content-editor'])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })
  const [showPicker, setShowPicker] = useState(data.providerId || data.courseId)

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onCourseIdChange = useCallback(
    value => {
      actions.setProp(props => (props.courseId = value))
    },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        {resources?.CourseProvider && !resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.CourseProvider.title}
          </Message>
        )}
        {resources?.Course && (
          <Message className="text-sm" type="warn">
            {resources.Course.title}
          </Message>
        )}
        <Input
          label={t('email')}
          onChange={onFieldUpdate('email')}
          value={data.email}
          placeholder={resources?.CourseProvider?.contactEmail}
        />
        {/* <Box space="xs">
          <Checkbox
            label={t('email')}
            onChange={value => {
              actions.setProp(props => (props.showSubtitle = value))
            }}
            value={data.showEmail}
            name="showEmail"
          />
        </Box> */}
        {!resources?.Course && (
          <>
            <Checkbox
              label={t('pickCourseManually')}
              help={t('pickCourseManuallyHelp')}
              onChange={value => setShowPicker(value)}
              value={showPicker}
            />
            {showPicker && (
              <Box space="lg">
                {!resources?.CourseProvider && (
                  <ProviderSelect
                    label={t('provider')}
                    onChange={onProviderUpdate('providerId')}
                    value={data.providerId}
                    controlled={false}
                  />
                )}
                <CoursePicker
                  providerId={data.providerId || resources?.CourseProvider?.id}
                  onChange={onCourseIdChange}
                  value={data.courseId}
                />
              </Box>
            )}
          </>
        )}
      </Box>
    </SettingsWrap>
  )
}

CourseRegistration.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    courseId: undefined,
    email: undefined,
  },
  custom: {
    type: config.type,
    resources: ['courseRegistration'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CourseRegistrationSettings,
  },
}
