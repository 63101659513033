import PropTypes from 'prop-types'
import React from 'react'

import {
  BlocksResolverContext,
  BlocksToolbarsContext,
  TranslatableBlocksContext,
} from './blocksContext'

export default function BlocksProvider({
  children,
  resolver,
  toolbars,
  translatable,
}) {
  return (
    <BlocksResolverContext.Provider value={resolver}>
      <BlocksToolbarsContext.Provider value={toolbars}>
        <TranslatableBlocksContext.Provider value={translatable}>
          {children}
        </TranslatableBlocksContext.Provider>
      </BlocksToolbarsContext.Provider>
    </BlocksResolverContext.Provider>
  )
}
BlocksProvider.propTypes = {
  children: PropTypes.node,
  resolver: PropTypes.object,
  toolbars: PropTypes.array,
  translatable: PropTypes.object,
}
