import PropTypes from 'prop-types'
import React from 'react'

import { useDropzone } from 'react-dropzone'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Message from '@ui/data-display/Message'
import bytes from '@utils/bytes'

import Field from './Field'
import { useRules } from './validationHooks'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export function FileDragger({
  accept = [],
  acceptLabel,
  className = '',
  disabled,
  dropHint,
  id,
  inputRef,
  maxSize = 0,
  maxSizeLabel,
  multiple,
  name,
  onChange,
  onUpload,
  selectFilesLabel,
}) {
  const { t } = useTranslation('ui/file-dragger')
  const accepted = getAcceptedFiles(accept)
  const [isHover, setIsHover] = React.useState(false)

  const { acceptedFiles, fileRejections, getRootProps, getInputProps } =
    useDropzone({
      noClick: true, // Prevents the default behavior of opening the file dialog when clicking that may trigger twice
      accept: accepted.types,
      disabled,
      maxSize,
      multiple,
      onDrop: acceptedFiles => {
        if (typeof onUpload === 'function') {
          if (acceptedFiles.length) {
            onUpload(multiple ? acceptedFiles : acceptedFiles[0], onChange)
          }
        } else {
          onChange(acceptedFiles)
        }

        setIsHover(false)
      },
      onDragEnter: () => setIsHover(true),
      onDragLeave: () => setIsHover(false),
    })

  const hasFileTypeFilter = accepted.extensions.length > 0
  const hasSizeLimit = maxSize > 0

  return (
    <section className={`flex w-full flex-col space-y-2 ${className}`}>
      <div
        {...getRootProps({
          className: `flex flex-col items-stretch justify-center border-2 rounded focus:border-primary-500 focus:ring-primary-200 focus:ring focus:outline-none transition-all duration-300 ${
            isHover
              ? 'bg-primary-50 border-solid border-primary-300'
              : 'bg-gray-50 border-dashed border-gray-200'
          }`,
        })}
      >
        <input {...getInputProps({ id, name, ref: inputRef })} />
        <div className="flex flex-row items-stretch justify-around p-4">
          <div className="flex w-1/2 flex-col items-center justify-center space-y-4 p-4">
            <span className="text-4xl text-gray-400">
              <Icon name="file-upload" />
            </span>
            <p className="text-md text-center font-semibold text-gray-500">
              {dropHint || (multiple ? t('dropHint') : t('dropHintMultiple'))}
            </p>
          </div>
          <div className="border-l" />
          <div className="flex w-1/2 flex-col items-center justify-center p-4">
            <Button
              as="label"
              htmlFor={id}
              label={
                selectFilesLabel ?? t(multiple ? 'selectFiles' : 'selectFile')
              }
              icon="file-search"
              size="sm"
            />
          </div>
        </div>
        {(hasFileTypeFilter || hasSizeLimit) && (
          <div className="flex flex-row gap-4 border-t border-gray-200 bg-white p-4">
            {hasFileTypeFilter && (
              <div className="flex flex-col items-baseline gap-1">
                <p className="text-sm text-gray-600">
                  {acceptLabel ?? t('accept')}:
                </p>
                <div className="flex max-w-64 flex-row flex-wrap gap-1 text-sm font-semibold">
                  {accepted.extensions.map(fileExt => `.${fileExt}`).join(', ')}
                </div>
              </div>
            )}
            {hasSizeLimit && (
              <>
                <div className="border-l" />
                <div className="flex shrink-0 flex-col items-center gap-1">
                  <p className="text-sm text-gray-600">
                    {maxSizeLabel ?? t('maxSize')}:
                  </p>
                  <p>
                    <span className="rounded bg-primary-400 px-2 py-1 font-mono text-xs text-white">
                      {bytes(maxSize)}
                    </span>
                  </p>
                </div>
              </>
            )}
          </div>
        )}
      </div>

      {fileRejections?.length > 0 && (
        <Message
          title={t('rejectError')}
          text={t('fileRejectionsMessage', { count: fileRejections.length })}
          type="danger"
        />
      )}
      {acceptedFiles?.length > 0 && (
        <div className="rounded border border-primary-500 p-4 text-sm">
          <ul className="flex flex-col gap-4">
            <li className="flex flex-row justify-between border-b pb-2 text-gray-700">
              <span>{t('fileToUpload')}</span>
              <span>{t('size')}</span>
            </li>
            {acceptedFiles.map(file => (
              <li key={file.path} className="flex flex-row justify-between">
                <span className="flex items-center justify-center gap-2 font-mono font-semibold">
                  <Icon name="file" className="text-gray-400" />
                  {file.path}
                </span>
                <span className="font-semibold">{bytes(file.size)}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </section>
  )
}
FileDragger.propTypes = {
  accept: PropTypes.array,
  acceptLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropHint: PropTypes.string,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  maxSize: PropTypes.number.isRequired,
  maxSizeLabel: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  selectFilesLabel: PropTypes.string,
}

export function FileDraggerField({
  accept,
  acceptLabel,
  className,
  disabled,
  dropHint,
  error,
  help,
  inputRef,
  label,
  maxSize,
  maxSizeLabel,
  multiple,
  name,
  onChange,
  onUpload,
  required,
  selectFilesLabel,
  value,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={error}
      help={help}
      required={required}
    >
      <FileDragger
        accept={accept}
        acceptLabel={acceptLabel}
        disabled={disabled}
        dropHint={dropHint}
        id={name}
        inputRef={inputRef}
        maxSize={maxSize}
        maxSizeLabel={maxSizeLabel}
        multiple={multiple}
        name={name}
        onChange={onChange}
        onUpload={onUpload}
        value={value}
        selectFilesLabel={selectFilesLabel}
      />
    </Field>
  )
}
FileDraggerField.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  acceptLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropHint: PropTypes.string,
  error: PropTypes.object,
  help: PropTypes.string,
  inputRef: PropTypes.any,
  label: PropTypes.string,
  maxSize: PropTypes.string,
  maxSizeLabel: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  required: PropTypes.bool,
  selectFilesLabel: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

export default function FileDraggerController({
  accept,
  acceptLabel,
  className,
  disabled,
  dropHint,
  help,
  label,
  maxSize,
  maxSizeLabel,
  multiple,
  name,
  onChange,
  onUpload,
  required,
  value,
  shouldUnregister,
  selectFilesLabel,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  const onFieldChange = React.useCallback(
    field => newValue => {
      field.onChange(newValue)
      onChange?.(newValue)
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <FileDraggerField
          accept={accept}
          acceptLabel={acceptLabel}
          className={className}
          disabled={disabled}
          dropHint={dropHint}
          error={fieldState.error}
          help={help}
          id={name}
          inputRef={field.ref}
          label={label}
          maxSize={maxSize}
          maxSizeLabel={maxSizeLabel}
          multiple={multiple}
          name={name}
          onBlur={field.onBlur}
          onChange={onFieldChange(field)}
          onUpload={onUpload}
          required={rules?.required?.value}
          selectFilesLabel={selectFilesLabel}
          value={field.value}
        />
      )}
    />
  )
}

FileDraggerController.propTypes = {
  accept: PropTypes.arrayOf(PropTypes.string),
  acceptLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  dropHint: PropTypes.string,
  help: PropTypes.string,
  label: PropTypes.string,
  maxSize: PropTypes.string,
  maxSizeLabel: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onUpload: PropTypes.func,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  selectFilesLabel: PropTypes.string,
  shouldUnregister: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
}

/**
 *
 * @param {array} accept
 * @returns `object`
 */
function getAcceptedFiles(accept = []) {
  return accept.reduce(
    (acc, cur) => {
      const format = acceptMap[cur]
      acc.extensions.push(...format.extensions)

      for (const typeKey of format.types) {
        acc.types[typeKey] = []
      }

      return acc
    },
    { extensions: [], types: {} }
  )
}

// See more here: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const acceptMap = {
  // Images:
  'gif': { extensions: ['gif'], types: ['image/gif'] },
  'jpg': { extensions: ['jpg', 'jpeg'], types: ['image/jpeg'] },
  'png': { extensions: ['png'], types: ['image/png'] },
  'svg': { extensions: ['svg'], types: ['image/svg+xml'] },
  'webp': { extensions: ['webp'], types: ['image/webp'] },

  // Documents:
  'csv': { extensions: ['csv'], types: ['text/csv'] },
  'doc': {
    extensions: ['doc', 'docx'],
    types: [
      'application/msword',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    ],
  },
  'pdf': { extensions: ['pdf'], types: ['application/pdf'] },
  'ai': { extensions: ['ai'], types: ['application/pdf'] },
  'ppt': {
    extensions: ['ppt', 'pptx'],
    types: [
      'application/vnd.ms-powerpoint',
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    ],
  },
  'xls': {
    extensions: ['xls', 'xlsx'],
    types: [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ],
  },
  'epub': { extensions: ['epub'], types: ['application/epub+zip'] },

  // Audio/Video:
  'mp3': { extensions: ['mp3'], types: ['audio/mpeg'] },
  'aac': { extensions: ['aac'], types: ['audio/aac'] },
  'mp4': { extensions: ['mp4'], types: ['video/mp4'] },
  'mov': { extensions: ['mov'], types: ['video/quicktime'] },
  'mpeg': { extensions: ['mpeg'], types: ['video/mpeg'] },

  // Compressed files:
  '7z': { extensions: ['7z'], types: ['application/x-7z-compressed'] },
  'rar': { extensions: ['rar'], types: ['application/vnd.rar'] },
  'zip': { extensions: ['zip'], types: ['application/zip'] },

  // Fonts
  'otf': { extensions: ['otf'], types: ['font/otf'] },
  'ttf': { extensions: ['ttf'], types: ['font/ttf'] },
  'woff': { extensions: ['woff'], types: ['font/woff'] },
  'woff2': { extensions: ['woff2'], types: ['font/woff2'] },

  // Others
  'xml': { extensions: ['xml'], types: ['text/xml'] },
  'json': {
    extensions: ['json'],
    types: ['application/json'],
  },
}
