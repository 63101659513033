import PropTypes from 'prop-types'
import React, { useMemo } from 'react'

import InlineResponsiveControls from '../InlineResponsiveControls'
import InlineResponsiveToggle from '../InlineResponsiveToggle'
import { useBlockData } from '../useBlockData'
import useFieldBreakpoint from '../useFieldBreakpoint'
import { breakpointIcons } from './config'

export function BlockField({
  children,
  defaultValue,
  name,
  onChange,
  responsive,
  value: overrideValue,
}) {
  const { breakpoint, setBreakpoint, showControls, setShowControls } =
    useFieldBreakpoint()

  const {
    actions,
    data,
    dataValue,
    inheritFrom,
    inheritValue,
    value,
    valueAtBreakpoint,
    onDataChange,
  } = useBlockData(name, {
    breakpointOverride: breakpoint,
    defaultValue,
    onChange,
    responsive,
    value: overrideValue,
  })

  const hasResponsiveValues = useMemo(
    () => Object.keys(dataValue ?? {}).length > 1,
    [dataValue]
  )

  const fieldProps = {
    fieldExtra: responsive ? (
      <InlineResponsiveControls
        breakpoint={breakpoint}
        dataValue={dataValue}
        icons={breakpointIcons}
        open={showControls}
        setBreakpoint={setBreakpoint}
      />
    ) : undefined,
    name,
    labelExtra: responsive ? (
      <InlineResponsiveToggle
        hasValues={hasResponsiveValues}
        open={showControls}
        setOpen={setShowControls}
      />
    ) : undefined,
  }

  if (typeof children === 'function') {
    return children({
      actions,
      breakpoint,
      data,
      dataValue,
      fieldProps,
      inheritFrom,
      inheritValue,
      onDataChange,
      value,
      valueAtBreakpoint,
    })
  }

  return null
}

BlockField.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  defaultValue: PropTypes.any,
  help: PropTypes.string,
  label: PropTypes.string,
  lightLabel: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  responsive: PropTypes.bool,
  value: PropTypes.string,
}

export default BlockField
