import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'
import { useGlobalParams } from '@utils/urls'

export default function useProvider(id) {
  const params = useParams()

  const providerId = id || params?.providerId

  const { data, error, isLoading } = useQuery(
    ['courses-providers', providerId],
    () => getFetch(providerId ? `/api/courses/providers/${providerId}` : null)
  )

  return { provider: data, error, loading: isLoading }
}

export function useProviderId() {
  const { providerId } = useGlobalParams('/courses/providers/:providerId/*')
  return providerId
}
