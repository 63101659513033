import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'
import Checkbox from '@ui/data-entry/Checkbox'

export function PermissionCheckbox({
  model,
  permission,
  label,
  help,
  tooltip,
  disabled,
  defaultValue,
  onChange,
  shouldUnregister,
}) {
  const { t } = useTranslation('users')

  const isAuthorized = useIsUserAuthorized({
    module: model,
    permission,
  })

  if (!isAuthorized) return null

  return (
    <Checkbox
      className="text-base"
      label={label || t(`${permission}Permission`)}
      help={help}
      tooltip={
        disabled
          ? null
          : tooltip || t(`${permission}PermissionHelp`, { model: t(model) })
      }
      name={`${model}[${permission}]`}
      disabled={disabled}
      value={defaultValue}
      shouldUnregister={shouldUnregister}
      onChange={value =>
        typeof onChange === 'function' ? onChange(value) : null
      }
    />
  )
}
PermissionCheckbox.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.node,
  help: PropTypes.string,
  model: PropTypes.string,
  tooltip: PropTypes.string,
  permission: PropTypes.string,
  defaultValue: PropTypes.bool,
  onChange: PropTypes.func,
  shouldUnregister: PropTypes.bool,
}
