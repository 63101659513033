import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import Section from '@ui/data-display/Section'
import Checkbox from '@ui/data-entry/Checkbox'
import Form from '@ui/data-entry/Form'
import Submit from '@ui/data-entry/Submit'
import Box from '@ui/layout/Box'

export const PageForm = ({ page, onSubmit }) => {
  const design = useDesignContext()
  const { t } = useTranslation(design.namespace)

  return (
    <Form
      data={page}
      onSubmit={onSubmit}
      id={`design-header-${page.id}-${page.updatedAt}`}
    >
      <Section title={t('settings')}>
        <Section.Body>
          <Box space="lg">
            <Checkbox
              name="design.darkMode"
              label={t('darkMode')}
              help={t('darkModeHelp')}
            />
            <Checkbox
              name="design.fullWidth"
              label={t('fullWidth')}
              help={t('fullWidthHelp')}
            />
            {/* <Checkbox
              name="design.header.transparent"
              label={t('transparentHeader')}
              help={t('transparentHeaderHelp')}
            /> */}
          </Box>
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}

PageForm.propTypes = {
  page: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
