import React from 'react'
import PropTypes from 'prop-types'

import AutocompleteOption from './Option'

/**
 * Autocomplete's Placeholder
 */
export default function AutocompletePlaceholder({ label, value }) {
  return (
    <AutocompleteOption
      // className="text-gray-400"
      label={label}
      disabled
      value={value}
    />
  )
}
AutocompletePlaceholder.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
}
AutocompletePlaceholder.defaultProps = {
  value: 'none',
}
