import useCurrentUser from '@modules/auth/helpers/useCurrentUser'
import useIsUserAuthorized, {
  isUserAuthorized,
} from '@modules/auth/helpers/useIsUserAuthorized'
import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'

export default function useMediaLibraryPermissions() {
  const { user } = useCurrentUser()
  const { entity: currentEntity } = useCurrentEntity()

  const commonParams = {
    user,
    entity: currentEntity,
    currentEntityId: currentEntity?.id,
  }

  const canReadChannels = useIsUserAuthorized({
    module: 'channels',
    permission: 'read',
  })

  const canCreateChannels = useIsUserAuthorized({
    module: 'channels',
    permission: 'create',
  })

  return {
    // Channels
    canReadChannels,
    canCreateChannels,
    canUpdateChannels: ({ channelId }) => {
      return isUserAuthorized({
        module: 'channels',
        permission: 'update',
        recordId: channelId,
        ...commonParams,
      })
    },
    canDeleteChannels: ({ channelId }) => {
      return isUserAuthorized({
        module: 'channels',
        permission: 'delete',
        recordId: channelId,
        ...commonParams,
      })
    },

    // Shows
    canReadShows: ({ channelId }) => {
      return isUserAuthorized({
        module: 'shows',
        permission: 'read',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canCreateShows: ({ channelId }) => {
      return isUserAuthorized({
        module: 'shows',
        permission: 'create',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canUpdateShows: ({ channelId }) => {
      return isUserAuthorized({
        module: 'shows',
        permission: 'update',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canDeleteShows: ({ channelId }) => {
      return isUserAuthorized({
        module: 'shows',
        permission: 'delete',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },

    // Episodes
    canReadEpisodes: useIsUserAuthorized({
      module: 'episodes',
      permission: 'read',
    }),
    canCreateEpisodes: useIsUserAuthorized({
      module: 'episodes',
      permission: 'create',
    }),
    canUpdateEpisodes: useIsUserAuthorized({
      module: 'episodes',
      permission: 'update',
    }),
    canDeleteEpisodes: useIsUserAuthorized({
      module: 'episodes',
      permission: 'delete',
    }),

    // Collections
    canReadCollections: ({ channelId }) => {
      return isUserAuthorized({
        module: 'collections',
        permission: 'read',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canCreateCollections: ({ channelId }) => {
      return isUserAuthorized({
        module: 'collections',
        permission: 'create',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canUpdateCollections: ({ channelId }) => {
      return isUserAuthorized({
        module: 'collections',
        permission: 'update',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canDeleteCollections: ({ channelId }) => {
      return isUserAuthorized({
        module: 'collections',
        permission: 'delete',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },

    // Persons
    canReadPersons: ({ channelId }) => {
      return isUserAuthorized({
        module: 'persons',
        permission: 'read',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canCreatePersons: ({ channelId }) => {
      return isUserAuthorized({
        module: 'persons',
        permission: 'create',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canUpdatePersons: ({ channelId }) => {
      return isUserAuthorized({
        module: 'persons',
        permission: 'update',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canDeletePersons: ({ channelId }) => {
      return isUserAuthorized({
        module: 'persons',
        permission: 'delete',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },

    // Broadcasts
    canReadBroadcasts: ({ channelId }) => {
      return isUserAuthorized({
        module: 'broadcasts',
        permission: 'read',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canCreateBroadcasts: ({ channelId }) => {
      return isUserAuthorized({
        module: 'broadcasts',
        permission: 'create',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canUpdateBroadcasts: ({ channelId }) => {
      return isUserAuthorized({
        module: 'broadcasts',
        permission: 'update',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
    canDeleteBroadcasts: ({ channelId }) => {
      return isUserAuthorized({
        module: 'broadcasts',
        permission: 'delete',
        recordModule: 'channels',
        recordId: channelId,
        ...commonParams,
      })
    },
  }
}
