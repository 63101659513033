import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'

import useValueAtBreakpoint from '../useValueAtBreakpoint'

const textSizes = [
  'xs', // text-xs
  'sm', // text-sm
  'md', // text-base
  'lg', // text-lg
  'xl', // text-xl
  '2xl', // text-2xl
  '3xl', // text-3xl
  '4xl', // text-4xl
  '5xl', // text-5xl
  '6xl', // text-6xl
  '7xl', // text-7xl
  '8xl', // text-8xl
  '9xl', // text-9xl
]

/**
 * Hook to get the text size options.
 *
 * @param {string[]} [overrideSizes] - Optional list of available sizes
 * @returns {object[]} - The options
 * @example
 * const textSizeOptions = useTextSizeOptions()
 * // -> [{ label: 'Small', value: 'sm' }, ...]
 * @example
 * const textSizeOptions = useTextSizeOptions(['sm', 'md'])
 * // -> [{ label: 'Small', value: 'sm' }, { label: 'Medium', value: 'md' }
 */
export function useTextSizeOptions(overrideSizes = []) {
  const { t } = useTranslation('web/content-editor')
  const design = useDesignContext()

  // if there are custom sizes, from the design or from the override, we want to use them
  const customSizes = useMemo(() => {
    // if there are override sizes, use them
    if (overrideSizes?.length > 0) {
      return overrideSizes
    }

    // otherwise, use the design sizes if they exist
    return design?.fonts?.sizes ?? []
  }, [overrideSizes, design])

  // check if there are custom sizes
  const hasCustomSizes = customSizes.length > 0

  // return the options (array of objects with label and value)
  return useMemo(() => {
    // Filter out font sizes that are not defined in the design or override
    const options = textSizes.filter(key =>
      // If there are no design font sizes, we want to show all options
      hasCustomSizes ? customSizes.includes(key) : true
    )

    return options.map(key => ({
      label: t(`textSize_${key}`),
      value: key,
    }))
  }, [t, customSizes, hasCustomSizes])
}

/**
 * Hook to get the text size class name for a given size.
 * @param {string} size - The size
 * @param {string} [defaultSize] - The default size
 * @returns {string} - The class name
 * @example
 * const textSizeClass = useTextSizeStyles('md')
 * // -> 'text-md'
 */
export function useTextSizeStyles(size, defaultSize = 'md') {
  if (!textSizes.includes(size)) {
    return {
      className: `text-${defaultSize}`,
      style: {},
    }
  }

  return {
    className: `text-${size}`,
    style: {},
  }
}

/**
 * Hook to get the text size class name for a given size.
 *
 * @param {string} size - The size
 * @param {string} [defaultSize] - The default size
 * @returns {string} - The class name
 * @example
 * const textSizeClass = useBlockTextSizeClass('md')
 * // -> 'text-md'
 */
export function useBlockTextSizeClass(size, defaultSize = 'md') {
  const { value: sizeAtBreakpoint } = useValueAtBreakpoint(size, defaultSize)

  return useTextSizeStyles(sizeAtBreakpoint, defaultSize).className ?? ''
}
