import React from 'react'

import { Input } from '@ui/data-entry/Input'

export function TimeInput(props) {
  return (
    <Input
      className="w-20 rounded border-gray-300 text-center focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200"
      type="number"
      fullWidth={false}
      {...props}
    />
  )
}
