import { isString } from './types'

/**
 * Calculate a HEX code based on the given value
 * @param {string} value
 */
export function stringToHEX(value) {
  if (!isString(value)) return ''

  // Create hash from string
  let hash = 0
  for (let i = 0; i < value.length; i++) {
    hash = value.charCodeAt(i) + ((hash << 5) - hash)
  }

  // Convert hash to RGB
  const c = (hash & 0x00ffffff).toString(16).toUpperCase()
  const rgb = '00000'.substring(0, 6 - c.length) + c

  return `#${rgb}`
}

/**
 * Calculate an RGB code based on the given HEX value
 * @param {string} hex
 * @returns {string}
 *
 * @example
 * hexToRGB('#FF0000') // 'rgb(255, 0, 0)'
 *
 * @example
 * hexToRGB('#FF0000', 0.5) // 'rgba(255, 0, 0, 0.5)'
 */
export function hexToRGB(hex, alpha = 1) {
  if (typeof hex !== 'string') return ''

  // Validate HEX
  hex = hex.replace('#', '')
  if (hex.length === 3)
    hex = hex
      .split('')
      .map(c => c + c)
      .join('')

  // Convert HEX to RGB
  const r = parseInt(hex.substring(0, 2), 16)
  const g = parseInt(hex.substring(2, 4), 16)
  const b = parseInt(hex.substring(4, 6), 16)

  // Return RGB
  return `rgba(${r},${g},${b},${alpha})`
}
