import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'

import Field from './Field'
import { Input } from './Input'
import { useRules } from './validationHooks'

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/

export function Password({
  autocomplete,
  className,
  disabled,
  fullWidth,
  id,
  inputRef,
  name,
  onChange,
  onBlur,
  onVisibilityToggle,
  placeholder,
  value,
}) {
  const [visible, setVisible] = useState(false)

  const onToggle = useCallback(() => {
    const isVisible = !visible
    setVisible(isVisible)

    if (typeof onVisibilityToggle === 'function') {
      onVisibilityToggle(isVisible)
    }
  }, [onVisibilityToggle, visible])

  return (
    <div className="relative flex flex-grow flex-row space-x-2">
      <Input
        autoComplete={autocomplete}
        className={`font-mono ${className}`}
        disabled={disabled}
        fullWidth={fullWidth}
        id={id}
        inputRef={inputRef}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={visible ? 'text' : 'password'}
        value={value}
        sugge
      />

      <Button
        onClick={onToggle}
        className={`absolute top-0 bottom-0 right-0 ${
          visible ? 'text-warn-600' : ''
        }`}
        icon={visible ? 'eye-slash' : 'eye'}
        variant="flat"
      />
    </div>
  )
}
Password.propTypes = {
  autocomplete: PropTypes.oneOf([
    'new-password',
    'current-password',
    'one-time-code',
    'off',
  ]),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  id: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onVisibilityToggle: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string,
}

export function PasswordField({
  autocomplete,
  error,
  className,
  disabled,
  fullWidth,
  help,
  inputRef,
  label,
  name,
  required,
  placeholder,
  onChange,
  onBlur,
  onVisibilityToggle,
  value,
}) {
  return (
    <Field
      className={className}
      name={name}
      label={label}
      error={error}
      help={help}
      required={required}
    >
      <Password
        autocomplete={autocomplete}
        disabled={disabled}
        fullWidth={fullWidth}
        id={name}
        inputRef={inputRef}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onVisibilityToggle={onVisibilityToggle}
        placeholder={placeholder}
        value={value}
      />
    </Field>
  )
}
PasswordField.propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.object,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  inputRef: PropTypes.func,
  label: PropTypes.string,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onVisibilityToggle: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  validate: PropTypes.func,
  validatePattern: PropTypes.bool,
  value: PropTypes.string,
}

export default function PasswordController({
  autocomplete,
  className,
  disabled,
  fullWidth,
  help,
  label,
  maxLength,
  minLength,
  name,
  onChange,
  onBlur,
  onVisibilityToggle,
  placeholder,
  required,
  shouldUnregister,
  validate,
  validatePattern,
  value,
}) {
  const { t } = useTranslation('ui')
  const { control } = useFormContext()

  const rules = useRules({
    maxLength,
    minLength,
    pattern: validatePattern
      ? { message: t('errorPasswordPattern'), value: passwordRegex }
      : null,
    required,
    validate,
  })

  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)
      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  const onFieldBlur = useCallback(
    field => value => {
      field.onBlur(value)
      if (typeof onBlur === 'function') {
        onBlur(value)
      }
    },
    [onBlur]
  )

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <PasswordField
          autocomplete={autocomplete}
          className={className}
          disabled={disabled}
          name={name}
          label={label}
          error={fieldState.error}
          help={help ? help : validatePattern ? t('patternPassword') : ''}
          required={rules?.required?.value}
          value={field.value}
          inputRef={field.ref}
          onChange={onFieldChange(field)}
          onVisibilityToggle={onVisibilityToggle}
          onBlur={onFieldBlur(field)}
          placeholder={placeholder}
          fullWidth={fullWidth}
        />
      )}
    />
  )
}
PasswordController.propTypes = {
  autocomplete: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  help: PropTypes.string,
  label: PropTypes.string,
  maxLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  minLength: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({ value: PropTypes.number, message: PropTypes.string }),
  ]),
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onVisibilityToggle: PropTypes.func,
  placeholder: PropTypes.string,
  required: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.shape({ value: PropTypes.bool, message: PropTypes.string }),
  ]),
  shouldUnregister: PropTypes.bool,
  validate: PropTypes.func,
  validatePattern: PropTypes.bool,
  value: PropTypes.string,
}
PasswordController.defaultProps = {
  validatePattern: true,
}
PasswordController.displayName = 'Password'
