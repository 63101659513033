import React from 'react'

import config from './config'

const CategoriesListSettings = React.lazy(() => import('./Settings'))
const CategoriesListBlock = React.lazy(() => import('./Block'))

export default function CategoriesList(props) {
  return <CategoriesListBlock {...props} />
}

CategoriesList.toolbarItem = {
  ...config,
  component: <CategoriesList />,
}

CategoriesList.craft = {
  displayName: 'CategoriesList',
  props: {},
  custom: {
    type: config.type,
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CategoriesListSettings,
  },
}
