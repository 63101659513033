import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import {
  SelectField,
  SelectOption,
  SelectPlaceholder,
} from '@ui/data-entry/Select'
import { range } from '@utils/arrays'

import BlockField from '../BlockField'
import InheritSelectPlaceholder from '../InheritSelectPlaceholder'

const MAX_COLUMNS = 7

/**
 * Columns select field
 * @param {Object} props
 * @param {string} [props.name='columns'] - Field name
 * @returns {React.ReactElement}
 */
export default function ColumnsSelect({ name = 'columns' }) {
  const { t } = useTranslation('web/content-editor')

  return (
    <BlockField name={name} responsive>
      {({ fieldProps, inheritFrom, inheritValue, onDataChange, value }) => (
        <SelectField
          {...fieldProps}
          help={t('columnsHelp')}
          label={t('columns')}
          onChange={e => onDataChange(e.target.value)}
          value={value}
        >
          <InheritSelectPlaceholder
            labelKey="columns"
            value={value}
            inheritValue={inheritValue}
            inheritFrom={inheritFrom}
          />
          {value && <SelectOption label={t('clearSelection')} value={null} />}
          <SelectPlaceholder label="-" />
          {range(1, MAX_COLUMNS).map(key => (
            <SelectOption label={t(`columns_${key}`)} value={key} key={key} />
          ))}
        </SelectField>
      )}
    </BlockField>
  )
}

ColumnsSelect.propTypes = {
  name: PropTypes.string,
}
