import { useMemo } from 'react'

import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'

const fontWeights = [
  'thin', // 100 - font-thin
  'extralight', // 200 - font-extralight
  'light', // 300 - font-light
  'normal', // 400 - font-normal
  'medium', // 500 - font-medium
  'semibold', // 600 - font-semibold
  'bold', // 700 - font-bold
  'extrabold', // 800 - font-extrabold
  'black', // 900 - font-black
]

/**
 * Hook to get the font weights for the current design.
 * @returns {array} - The font weights
 * @example
 * const fontWeights = useFontWeights()
 * // -> ['thin', 'extralight', 'light', 'normal', 'medium', 'semibold', 'bold', 'extrabold', 'black']
 */
export function useFontWeights() {
  const design = useDesignContext()
  const { weights: designWeights } = design.fonts || {}

  return useMemo(() => {
    const hasDesignFontWeights = designWeights.length > 0

    return fontWeights.filter(weight =>
      hasDesignFontWeights ? designWeights.includes(weight) : true
    )
  }, [designWeights])
}

/**
 * Hook to get the font weight class name for a given weight.
 * @param {string} weight - The weight
 * @param {string} [defaultWeight] - The default weights
 * @returns {object} - The class name and style object
 * @example
 * const { className, style } = useFontWeightStyles('bold')
 * // -> { className: 'font-bold', style: {} }
 * @example
 * const { className, style } = useFontWeightStyles('bold', 'normal')
 * // -> { className: 'font-bold', style: {} }
 * @example
 * const { className, style } = useFontWeightStyles('foo', 'normal')
 * // -> { className: 'font-normal', style: {} }
 */
export function useFontWeightStyles(weight, defaultWeight = 'normal') {
  const fontWeights = useFontWeights()

  return useMemo(() => {
    const className = fontWeights.includes(weight)
      ? `font-${weight}`
      : fontWeights.includes(defaultWeight)
      ? `font-${defaultWeight}`
      : 'font-normal'

    return {
      className,
      style: {},
    }
  }, [fontWeights, weight, defaultWeight])
}

/**
 * A hook that returns an array of available font weight options for a given design
 * @returns {array} - The font weight options array
 * @example
 * const fontWeightOptions = useFontWeightOptions()
 * fontWeightOptions // => [{ label: 'Thin', value: 'thin' }, ...]
 */
export function useFontWeightOptions(labelKey = 'fontWeight') {
  const { t } = useTranslation('web/content-editor')

  const fontWeights = useFontWeights()

  return useMemo(
    () =>
      fontWeights.map(weight => ({
        label: t(`${labelKey}_${weight}`),
        value: weight,
      })),
    [fontWeights, labelKey, t]
  )
}
