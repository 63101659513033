import React from 'react'
import PropTypes from 'prop-types'

import { pick } from '@utils/objects'

import SelectController, { SelectField } from '@ui/data-entry/Select'

import useOrganizers from '../../services/hooks/useOrganizers'

export default function OrganizerSelect({
  controlled,
  onChange,
  ...selectProps
}) {
  const { organizers } = useOrganizers({ limit: 9999 })

  const Select = controlled ? SelectController : SelectField

  const handleOnChange = e => {
    const value = controlled ? e : e.target.value
    const organizer = organizers.find(o => o.id === value)
    onChange && onChange(pick(organizer, ['title', 'id']))
  }

  return (
    <Select {...selectProps} onChange={handleOnChange} showClear>
      <Select.Placeholder label="-" />
      {organizers?.map(organizer => (
        <Select.Option
          key={organizer.id}
          value={organizer.id}
          label={organizer.title}
        />
      ))}
    </Select>
  )
}
OrganizerSelect.propTypes = {
  controlled: PropTypes.bool,
  onChange: PropTypes.func,
}
OrganizerSelect.defaultProps = {
  controlled: true,
}
