import { useQuery } from 'react-query'
import { useParams } from 'react-router'

import { getFetch } from '@utils/fetcher'
import { validateMongoId } from '@utils/strings'

const defaultOptions = {
  enabled: true,
  getOriginal: false,
  translationsSort: undefined, // TODO: implement sorting
}

/**
 * Fetches an article by id
 *
 * @param {*} id  - article id
 * @param {*} options - options for the query
 * @param {*} options.enabled - whether the query is enabled
 * @param {*} options.getOriginal - whether to only get the original article
 * @returns
 */
export default function useArticle(id, options = defaultOptions) {
  const { enabled, getOriginal } = Object.assign({}, defaultOptions, options)
  const params = useParams()

  const articleId = id || params.articleId

  const { data, error, isLoading } = useQuery(
    ['articles', articleId, enabled],
    () => getFetch(`/api/articles/${articleId}`, { getOriginal }),
    { enabled: enabled && validateMongoId(articleId) }
  )

  return { article: data, articleId, error, loading: isLoading }
}
