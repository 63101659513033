import base from '../base'
import FooterForm from './appearance/FooterForm'
import HeaderForm from './appearance/HeaderForm'
import { PageForm } from './page/PageForm'

/**
 * Represents the Adventisten.de (DACH) design configuration.
 * @param {Object} options - The options for the design.
 * @param {Object} options.site - The site.
 * @param {Object} options.featureFlags - The feature flags
 * @returns {Object} - The Adventisten.de (DACH) design configuration.
 */
export default function () {
  const baseConfig = base()

  /**
   * IMPORTANT: keep this updated as the values in the frontend changes
   */
  return {
    ...baseConfig,
    title: 'Adventisten.de design',

    namespace: 'designs/adventisten-de',
    layouts: false,
    notifications: true,

    appearance: {
      HeaderForm,
      FooterForm,
    },

    pageForm: PageForm,

    fonts: {
      ...baseConfig.fonts,
      weights: ['light', 'normal', 'semibold', 'bold', 'black'],
      sizes: [
        'xs',
        'sm',
        'md',
        'lg',
        'xl',
        '2xl',
        '3xl',
        '4xl',
        '5xl',
        '6xl',
        '7xl',
        '8xl',
        '9xl',
      ],
    },

    padding: {
      type: 'design', // options: 'all', 'design' or 'defaults'
      options: ['zero', 'xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl'],
    },

    columns: [1, 2, 3, 4, 5, 6, 7],

    blocks: {
      Root: true,
      Section: {
        padding: true,
        dark: true,
      },
      Container: {
        align: true,
        bgColor: true,
        dark: true,
        justify: true,
      },
      Grid: {
        showGridOptions: true,
      },
      Box: {
        simple: true,
        dark: true,
        backgroundColor: true,
      },
      Header: {
        dynamicTitle: true,
        titleColor: true,
        subtitleColor: true,
        kickerColor: true,
      },
      Hero: {
        description: true,
        titleTextSize: ['2xl', '3xl', '4xl', '5xl', '6xl', '7xl', '8xl', '9xl'],
        titleHyphenated: true,
        capitaliseTitle: true,
        textPosition: true,
        variants: ['large', 'medium'],
        textColor: true,
        textColors: ['system-white', 'neutral-600'],
      },

      SimpleText: {
        textAlign: true,
      },
      LabelIcon: {
        labelColor: true,
        iconColor: true,
        iconPositions: ['top', 'right', 'bottom', 'left'],
      },
      RichText: true,
      Image: true,
      Accordion: true,
      AccordionItem: {
        bgColor: true,
      },
      AccordionItemContent: true,
      Card: {
        imageAlign: { enabled: ['imageBackground'] },
        imageAspectRatio: { enabled: ['default'] },
        padding: { enabled: ['imageBackground'] },
        textColor: true,
        textColors: ['system-white', 'neutral-600'],
        textPosition: { enabled: ['imageBackground'] },
        useCardPadding: { enabled: ['default'] },
        useLargeTitle: { enabled: ['default'] },
        variant: true,
        variants: ['default', 'imageBackground'],
      },
      Button: {
        icon: true,
        iconOnly: true,
      },
      LinkList: {
        title: true,
        color: 'all',
        backgroundColor: true,
        listType: true,
        items: {
          description: { enabled: ['descriptive'] },
          image: { enabled: ['resource'] },
          imageFit: { enabled: ['resource'] },
          imageAlign: { enabled: ['resource'] },
          icon: { enabled: ['descriptive'], userIcon: ['descriptive'] },
          hoverIcon: { enabled: ['descriptive'], userIcon: ['descriptive'] },
        },
        variants: ['simple', 'resource', 'descriptive'],
        itemLayout: true,
      },
      Breadcrumbs: true,
      HtmlEmbed: true,
      Player: true,
      ShareButton: {
        title: true,
      },
      Testimonies: true,
      FeaturedContent: {
        variants: ['leftBalanced', 'rightBalanced'],
      },
      Map: true,
      Avatar: {
        personSelect: true,
      },
      NewsletterSubscription: {
        appearance: true,
        emailPlaceholderLabel: true,
        requiredMessage: true,
        size: true,
        successButtonSize: true,
        successButtonVariant: true,
        successColor: true,
        successIconColor: true,
        termsColor: true,
        termsLinkColor: true,
        termsPage: true,
        title: true,
        titleColor: true,
      },
      NewsletterConfirmation: true,
      GooglePSEResults: true,
      Form: true,
      FormCheckbox: true,
      FormInput: {
        files: false,
      },
      FormSelect: true,
      FormTextArea: true,
      ImageGallery: true,
      CookieBlockedContent: true,

      ChurchFinder: {
        showAmenities: true,
      },
      ChurchDetail: {
        showMap: true,
        showAddress: true,
        showAmenities: true,
        titleColor: true,
      },
      ChurchContactPersons: {
        bgColor: true,
      },
      ChurchSermons: true,
      ChurchServices: true,
      ChurchDirections: true,
      ChurchLocation: true,
      ChurchImage: true,

      // Articles
      ArticleList: {
        articlesSiteId: true,
        columnOverflow: true,
        displayModes: ['cards'],
        filterCurrentArticle: true,
        showDescription: true,
        showImage: true,
        showLink: true,
        useCardPadding: ['cards'],
      },
      ArticleDetail: {
        showImage: true,
        showDate: true,
        showSubtitle: true,
        showAuthor: true,
        showLocation: true,
        showBody: true,
        showPullQuote: true,
        showCategories: true,
        shareOptions: true,
      },

      // Events
      EventList: {
        bgColor: true,
        showPreview: true,
      },
      EventDetail: {
        headerBgColor: true,
        bodyBgColor: true,
        pricesBgColor: true,
        workshopsBgColor: true,
        organizerBgColor: true,
        showImage: true,
        registration: true,
        terms: true,
      },
      EventTerms: true,
      EventRegistration: true,
    },

    colors: {
      primary: {
        50: '#e2fdfd',
        100: '#cefdfd', // Prev. primary-50
        200: '#b3f9f9',
        300: '#9bf8f8',
        400: '#84f6f6',
        500: '#6cf4f4', // Prev. primary-300
        600: '#74dfe8', // Prev. primary-600
        700: '#34cdcd',
        800: '#1ac2d4', // Prev. primary-default
        900: '#01a0a0',
        DEFAULT: '#6cf4f4',
        name: 'Adventisten.de Cyan',
      },
      secondary: {
        50: '#fcefe2',
        100: '#f8dbbf',
        200: '#f3c79b',
        300: '#efb478',
        400: '#eaa054',
        500: '#e68c31',
        600: '#c77624',
        700: '#a65f18',
        800: '#84490e',
        900: '#603205',
        DEFAULT: '#e68c31',
        name: 'Adventisten.de Orange',
      },
      neutral: {
        0: '#f9f9f9',
        50: '#f3f3f3',
        100: '#f0f0f0',
        200: '#c6c6c6',
        300: '#a0a0a0',
        400: '#909090',
        500: '#797979',
        600: '#696969',
        700: '#535353',
        800: '#494949',
        900: '#393939',
        1000: '#292929',
        DEFAULT: '#797979',
        name: 'Adventisten.de Grau',
      },

      // Further design colours
      green: {
        DEFAULT: '#00694B',
        sad: '#00593E',
        dark: '#9EC64C',
        light: '#B5CF48',
        name: 'Adventisten.de Green',
      },
      magenta: {
        light: '#F3A5C8',
        dark: '#F08EBA',
        name: 'Adventisten.de Magenta',
      },
      lilac: {
        light: '#C89ECA',
        dark: '#B98BBE',
        name: 'Adventisten.de Lilac',
      },
      salmon: {
        light: '#F5A797',
        dark: '#F39890',
        name: 'Adventisten.de Salmon',
      },
      blue: {
        DEFAULT: '#00678B',
        dark: '#005877',
        name: 'Adventisten.de Blue',
      },

      system: baseConfig.colors.system,
    },

    buttons: {
      ...baseConfig.buttons,
      variants: [
        'primary',
        'primary-outline',
        'secondary',
        'secondary-outline',
        'rounded-dark',
        'rounded-primary',
        'rounded-primary-outline',
        'rounded-secondary',
        'rounded-secondary-outline',
        'plain',
        'body',
      ],
    },
    form: {
      variants: [
        'white',
        'light',
        'dark',
        'rounded-white',
        'rounded-light',
        'rounded-dark',
      ],
      sizes: ['sm', 'md', 'lg'],
    },

    icons: [
      'address-card',
      'arrow-left-long-light',
      'arrow-left-long',
      'arrow-left',
      'arrow-right-long-light',
      'arrow-right-long',
      'arrow-right',
      'asterisk',
      'at',
      'baby',
      'bars',
      'bed',
      'book-bible',
      'brazilian-real-sign',
      'calendar-day',
      'calendar-days',
      'calendar-thin',
      'calendar',
      'campfire',
      'caret-up',
      'check',
      'chevron-down',
      'chevron-left',
      'chevron-right',
      'chevron-up',
      'children',
      'circle-check',
      'circle-exclamation',
      'circle-info',
      'circle-small',
      'circle-x',
      'clock',
      'close',
      'coins',
      'communion',
      'dollar-sign',
      'download',
      'euro-sign',
      'external-link-square',
      'face-awesome',
      'file-arrow-down',
      'file-lines',
      'flatbread',
      'franc-sign',
      'globe',
      'location-dot',
      'logo-facebook',
      'logo-instagram',
      'logo-youtube',
      'magnifying-glass',
      'map-marker-alt',
      'message',
      'parking-circle',
      'people-dress',
      'people-group',
      'person-chalkboard',
      'peso-sign',
      'phone',
      'plate-utensils',
      'quote',
      'route',
      'rupee-sign',
      'share-nodes',
      'shopping-basket',
      'spinner-third',
      'sterling-sign',
      'tag',
      'ticket',
      'trash',
      'trees',
      'undo',
      'user-group',
      'user',
      'water',
      'wheat',
      'wheelchair',
      'wifi',
      'xmark',
      'yen-sign',
    ],
  }
}
