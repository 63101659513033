import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import copyToClipboard from '@utils/copyToClipboard'

export default function CopyToClipboardButton({ label, value, ...rest }) {
  const { t } = useTranslation()
  const [copied, setCopied] = useState(false)

  const handleClick = useCallback(() => {
    copyToClipboard(value)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }, [value])

  return (
    <Button
      icon={copied ? 'clipboard-check' : 'clipboard'}
      variant={copied ? 'success' : null}
      label={t(copied ? 'copied' : label)}
      onClick={copied ? null : handleClick}
      {...rest}
    />
  )
}

CopyToClipboardButton.propTypes = {
  label: PropTypes.node,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
CopyToClipboardButton.defaultProps = {
  label: 'copy',
}
