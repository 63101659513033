import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import {
  FloatingPortal,
  arrow,
  autoUpdate,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

const staticSides = {
  top: 'bottom',
  right: 'left',
  bottom: 'top',
  left: 'right',
}

const arrowStyles = {
  top: 'border-r border-b',
  right: 'border-l border-b',
  bottom: 'border-l border-t',
  left: 'border-r border-t',
}

const hiddenFromStyles = {
  'xs': 'xs:hidden',
  'sm': 'sm:hidden',
  'md': 'md:hidden',
  'lg': 'lg:hidden',
  'xl': 'xl:hidden',
  '2xl': '2xl:hidden',
  '3xl': '3xl:hidden',
}

const sizeStyles = {
  xs: 'text-xs px-2 py-1.5 rounded-md',
  sm: 'text-sm px-3 py-2 rounded-lg',
  md: 'text-base px-4 py-2.5 rounded-xl',
  lg: 'text-lg px-5 py-3 rounded-2xl',
  xl: 'text-xl px-6 py-4 rounded-3xl',
}

export default function Tooltip({
  arrowOffset,
  children,
  className,
  content,
  contentClass,
  disabled,
  hiddenFrom,
  hideArrow,
  placement,
  size,
  tooltipClass,
}) {
  const arrowRef = useRef(null)
  const [isOpen, setIsOpen] = useState(false)

  // Set floating menu
  const {
    floatingStyles,
    refs,
    middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
    context,
  } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    placement,
    middleware: [
      shift(),
      offset((hideArrow ? 4 : 12) + arrowOffset),
      arrow({ element: arrowRef }),
    ],
  })

  // Set hover
  const hover = useHover(context)

  // Get hover interactions
  const { getReferenceProps, getFloatingProps } = useInteractions([hover])

  // If disabled, just render the children
  if (disabled || !content) return <div className={className}>{children}</div>

  const placementSide = placement.split('-')[0]
  const staticSide = staticSides[placementSide]
  const arrowClass = arrowStyles[placementSide] ?? ''
  const sizeClass = sizeStyles[size] ?? sizeStyles.md
  const hiddenFromClass = hiddenFromStyles[hiddenFrom] ?? ''

  return (
    <>
      <div
        ref={refs.setReference}
        {...getReferenceProps()}
        className={className}
      >
        {typeof children === 'function' ? children(isOpen) : children}
      </div>

      {isOpen && (
        <FloatingPortal>
          <div
            className={`z-max border whitespace-normal border-gray-500/50 bg-gray-700 shadow-lg opacity-90 ${hiddenFromClass} ${sizeClass} ${tooltipClass}`}
            ref={refs.setFloating}
            style={floatingStyles}
            {...getFloatingProps()}
          >
            <div className={`font-semibold text-gray-200 ${contentClass}`}>
              {content}
            </div>
            {!hideArrow && (
              <div
                ref={arrowRef}
                className={`absolute z-10 h-2.5 w-2.5 rotate-45 border-gray-500/50 bg-gray-700 ${arrowClass}`}
                style={{
                  top: arrowY ?? '',
                  left: arrowX ?? '',
                  [staticSide]: '-6px',
                }}
              />
            )}
          </div>
        </FloatingPortal>
      )}
    </>
  )
}
Tooltip.propTypes = {
  arrowOffset: PropTypes.number,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  content: PropTypes.node,
  contentClass: PropTypes.string,
  disabled: PropTypes.bool,
  hideArrow: PropTypes.bool,
  hiddenFrom: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', '2xl', '3xl']),
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  tooltipClass: PropTypes.string,
}
Tooltip.defaultProps = {
  arrowOffset: 0,
  className: '',
  placement: 'bottom',
  tooltipClass: '',
}
