import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useCourseContent({ courseId }) {
  const { data, error, isLoading, isFetching } = useQuery(
    ['courses', courseId, 'content'],
    () => getFetch(courseId ? `/api/courses/${courseId}/content` : null)
  )

  return {
    lessons: data,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
