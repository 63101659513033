import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { SearchDropdown } from '@ui/data-entry/SearchDropdown'
import { isEmpty } from '@utils/arrays'
import useDebounce from '@utils/useDebounce'

import useProvider from '../services/hooks/useProvider'
import useProviders from '../services/hooks/useProviders'

export function ProviderPicker({
  className,
  help,
  hideLabel,
  ignoreIds,
  label,
  name,
  onChange,
  required,
  value,
}) {
  const { t } = useTranslation('courses/public')
  const [searchTerm, setSearchTerm] = useState('')
  const debouncedSearchTerm = useDebounce(searchTerm, 300)

  const { providers } = useProviders({
    search: debouncedSearchTerm,
    fetchOnSearch: true,
    ignoreIds: !isEmpty(ignoreIds) ? ignoreIds : null,
  })
  const id = typeof value === 'object' ? value?.id : value
  const { provider } = useProvider(id)

  const onSearch = useCallback(text => {
    setSearchTerm(text)
  }, [])

  return (
    <div className={`space-y-4 ${className}`}>
      <SearchDropdown
        label={hideLabel ? '' : label || t('courseProvider')}
        placeholder={t('typeToSearch')}
        name={name}
        value={id}
        selectedLabel={provider?.title}
        onChange={onChange}
        onSearch={onSearch}
        help={help}
        required={required}
      >
        {providers?.map(provider => (
          <SearchDropdown.Option
            key={provider.id}
            label={provider.title}
            name={name}
            value={provider.id}
          />
        ))}
      </SearchDropdown>
    </div>
  )
}
ProviderPicker.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}
ProviderPicker.defaultProps = {
  className: '',
}

export default function ProviderPickerController({
  className,
  help,
  hideLabel,
  ignoreIds,
  label,
  name,
  onChange,
  required,
  shouldUnregister,
}) {
  const { control } = useFormContext()

  const onFieldChange = useCallback(
    field => value => {
      field.onChange(value)

      if (typeof onChange === 'function') {
        onChange(value)
      }
    },
    [onChange]
  )

  return (
    <Controller
      name={name}
      control={control}
      shouldUnregister={shouldUnregister}
      render={({ field }) => (
        <ProviderPicker
          className={className}
          help={help}
          hideLabel={hideLabel}
          ignoreIds={ignoreIds}
          label={label}
          name={name}
          value={field.value}
          onChange={onFieldChange(field)}
          required={required}
        />
      )}
    />
  )
}
ProviderPickerController.propTypes = {
  className: PropTypes.string,
  help: PropTypes.string,
  hideLabel: PropTypes.bool,
  ignoreIds: PropTypes.array,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
}
ProviderPickerController.defaultProps = {
  className: '',
}
