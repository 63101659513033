import React from 'react'

import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'

const themeOptions = [
  { name: 'treefrog', label: 'Treefrog' },
  { name: 'ming', label: 'Ming' },
  { name: 'bluejay', label: 'Bluejay' },
  { name: 'iris', label: 'Iris' },
  { name: 'lily', label: 'Lily' },
  { name: 'scarlett', label: 'Scarlett' },
  { name: 'campfire', label: 'Campfire' },
  { name: 'winter', label: 'Winter' },
  { name: 'forest', label: 'Forest' },
  { name: 'cave', label: 'Cave' },
  { name: 'denim', label: 'Denim' },
  { name: 'emperor', label: 'Emperor' },
  { name: 'grapevine', label: 'Grapevine' },
  { name: 'velvet', label: 'Velvet' },
  { name: 'earth', label: 'Earth' },
  { name: 'night', label: 'Night' },
]

export function ColorThemeSelect() {
  const { t } = useTranslation('designs/adventist')

  return (
    <Select
      label={t('colorTheme')}
      help={t('colorThemeHelp')}
      name="colorTheme"
      required
      shouldUnregister
    >
      <Select.Placeholder label="-" />
      {themeOptions.map(({ name, label }) => (
        <Select.Option label={label} value={name} key={name} />
      ))}
    </Select>
  )
}
