import PropTypes from 'prop-types'
import React from 'react'

import { PopoverArrow } from './components/PopoverArrow'
import { PopoverContent } from './components/PopoverContent'
import { PopoverTrigger } from './components/PopoverTrigger'
import { PopoverContext } from './context'
import { usePopover } from './hooks/usePopover'

export function Popover({
  children,
  content,
  dismissOptions,
  modal,
  hideArrow,
  placement,
  offset,
  initialOpen,
  open,
  onOpenChange,
  trigger,
}) {
  const popover = usePopover({
    dismissOptions,
    hideArrow,
    initialOpen,
    modal,
    offset,
    onOpenChange,
    open,
    placement,
  })

  return (
    <PopoverContext.Provider value={popover}>
      {trigger &&
        (typeof trigger === 'function' ? (
          trigger(popover)
        ) : (
          <PopoverTrigger asChild>{trigger}</PopoverTrigger>
        ))}
      {content && (typeof content === 'function' ? content(popover) : content)}
      {children}
    </PopoverContext.Provider>
  )
}

Popover.propTypes = {
  as: PropTypes.oneOf(['div', 'span']),
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  className: PropTypes.string,
  content: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
  dismissOptions: PropTypes.shape({
    enabled: PropTypes.bool,
    escapeKey: PropTypes.bool,
    referencePress: PropTypes.bool,
    referencePressEvent: PropTypes.oneOf(['pointerdown', 'mousedown', 'click']),
    outsidePress: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    outsidePressEvent: PropTypes.oneOf(['pointerdown', 'mousedown', 'click']),
    ancestorScroll: PropTypes.bool,
    bubbles: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({
        escapeKey: PropTypes.bool,
        outsidePress: PropTypes.bool,
      }),
    ]),
  }),
  hideArrow: PropTypes.bool,
  modal: PropTypes.bool,
  offset: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      mainAxis: PropTypes.number,
      crossAxis: PropTypes.number,
      alignmentAxis: PropTypes.number,
    }),
  ]),
  open: PropTypes.bool,
  onOpenChange: PropTypes.func,
  initialOpen: PropTypes.bool,
  passTriggerRef: PropTypes.bool,
  placement: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'right',
    'right-start',
    'right-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'left',
    'left-start',
    'left-end',
  ]),
  trigger: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

Popover.Trigger = PopoverTrigger
Popover.Content = PopoverContent
Popover.Arrow = PopoverArrow
