import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import useIsUserAuthorized from '@modules/auth/helpers/useIsUserAuthorized'
import { getUserUrl } from '@modules/users/helpers/urls'
import Avatar from '@ui/data-display/Avatar'
import { ConditionalLink } from '@ui/navigation/Link'
import { getAvatarUrl } from '@utils/images'

const sizeFormats = {
  xs: 'w:40',
  sm: 'w:64',
  md: 'w:128',
  lg: 'w:192',
  xl: 'w:256',
}

const sizeStyles = {
  xs: { wrap: 'gap-1', name: 'text-xs' },
  sm: { wrap: 'gap-2', name: 'text-sm' },
  md: { wrap: 'gap-4', name: 'text-base' },
  lg: { wrap: 'gap-6', name: 'text-lg' },
  xl: { wrap: 'gap-8', name: 'text-xl' },
}

export default function UserAvatar({
  className,
  isCurrentUser,
  linkToProfile = false,
  showName,
  size = 'md',
  user,
}) {
  const { t } = useTranslation('auth')
  const canManageUsers = useIsUserAuthorized({
    module: 'users',
    permission: 'read',
  })

  const sizeClasses = sizeStyles[size] || sizeStyles.md

  const profileUrl =
    canManageUsers && user && linkToProfile
      ? getUserUrl(user._id || user.id)
      : undefined

  return (
    <ConditionalLink
      basic={false}
      className={`flex items-center ${sizeClasses.wrap} ${className}`}
      href={profileUrl}
      target="_blank"
    >
      <Avatar
        className="shrink-0"
        imageUrl={user ? getAvatarUrl(user, sizeFormats[size]) : undefined}
        name={user?.name || '??'}
        size={size}
      />
      {showName && (
        <span className={`font-medium ${sizeClasses.name}`}>
          {user?.name || t('anonymous')}
          {isCurrentUser && (
            <span className="text-primary-400"> ({t('you')})</span>
          )}
        </span>
      )}
    </ConditionalLink>
  )
}
UserAvatar.propTypes = {
  className: PropTypes.string,
  user: PropTypes.shape({
    _id: PropTypes.string,
    avatar: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  isCurrentUser: PropTypes.bool,
  linkToProfile: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl']),
  showName: PropTypes.bool,
}
