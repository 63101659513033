import PropTypes from 'prop-types'
import React from 'react'

import { Controller } from 'react-hook-form'

import ColorSelectField from './Field'

export default function ColorSelectController({ name, label, help }) {
  return (
    <Controller
      name={name}
      render={({ field }) => (
        <ColorSelectField
          name={name}
          label={label}
          help={help}
          onChange={field.onChange}
          value={field.value}
        />
      )}
    />
  )
}
ColorSelectController.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  help: PropTypes.string,
}
