import React from 'react'

import CoursePicker from './components/CoursePicker'
import CoursesMenuGroup from './components/CoursesMenuGroup'
import ProviderPicker from './components/ProviderPicker'
import CoursesCollection from './components/blocks/Collection'
import CourseActions from './components/blocks/CourseActions'
import CourseContent from './components/blocks/CourseContent'
import CourseDetail from './components/blocks/CourseDetail'
import CourseHero from './components/blocks/CourseHero'
import CourseImage from './components/blocks/CourseImage'
import CourseMetaData from './components/blocks/CourseMetaData'
import CourseNavigation from './components/blocks/CourseNavigation'
import CoursePagination from './components/blocks/CoursePagination'
import CourseRegistration from './components/blocks/CourseRegistration'
import CourseStatus from './components/blocks/CourseStatus'
import CourseTableOfContents from './components/blocks/CourseTableOfContents'
import CoursesDeleteStudentAccount from './components/blocks/DeleteStudentAccount'
import CoursesEnrolledCourses from './components/blocks/EnrolledCourses'
import CoursesMessages from './components/blocks/Messages'
import CoursesStudentAdvisors from './components/blocks/StudentAdvisors'
import CoursesStudentNotifications from './components/blocks/StudentNotifications'
import CoursesStudentProfile from './components/blocks/StudentProfile'

// import CourseTrailer from './components/blocks/CourseTrailer'

const CoursesRouter = React.lazy(() => import('./pages/Router'))

export default function register() {
  return {
    menu: CoursesMenuGroup,
    routes: {
      private: {
        '/courses/*': { Component: CoursesRouter },
      },
    },
    permissions: {
      courses: {
        name: 'courses',
        label: 'courses/public:courses',
        icon: 'book',
        CustomFilter: {
          Component: ProviderPicker,
          props: {
            name: 'courses-providers.records.0',
          },
        },
        models: [
          {
            name: 'courses-providers',
            label: 'courses/public:providers',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'courses',
            label: 'courses/public:courses',
            permissions: [
              'read',
              'create',
              'update',
              'delete',
              'clone',
              'share',
            ],
          },
          {
            name: 'courses-collections',
            label: 'courses/public:collections',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'courses-countries',
            label: 'courses/public:countries',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'courses-advisors',
            label: 'courses/public:advisors',
            permissions: ['read', 'create', 'update', 'delete'],
          },
          {
            name: 'courses-messages',
            label: 'courses/public:messages',
            permissions: ['readAll', 'read', 'create', 'update'],
          },
          {
            name: 'courses-students',
            label: 'courses/public:students',
            help: 'Global settings',
            permissions: ['create'],
          },
          {
            name: 'courses-students',
            label: 'Students (all)',
            help: 'All students in the system',
            permissions: ['readAll', 'updateAll', 'deleteAll'],
          },
          {
            name: 'courses-students',
            label: 'Students (advisor)',
            help: 'Only students of advisor',
            permissions: ['read', 'update', 'delete'],
          },
          {
            name: 'courses-questionnaires',
            label: 'courses/public:questionnaires',
            permissions: ['create'],
          },
          {
            name: 'courses-questionnaires',
            label: 'Questionnaires (all)',
            permissions: [
              'readAll',
              'updateAll',
              // 'deleteAll'
            ],
          },
          {
            name: 'courses-questionnaires',
            label: 'Questionnaires (advisor)',
            permissions: [
              'read',
              'update',
              // 'delete'
            ],
          },
          {
            name: 'courses-statistics',
            label: 'courses/public:statistics',
            permissions: ['read'],
          },
        ],
      },
    },
    blocksResolver: {
      CoursesCollection,
      CoursesEnrolledCourses,
      CourseImage,
      CourseActions,
      CourseMetaData,
      CourseTableOfContents,
      CourseNavigation,
      CoursePagination,
      CourseHero,
      CourseDetail,
      CourseContent,
      CourseStatus,
      CoursesStudentAdvisors,
      CoursesStudentProfile,
      CoursesDeleteStudentAccount,
      CourseRegistration,
      CoursesStudentNotifications,
      CoursesMessages,
    },
    blocksToolbars: [
      {
        name: 'courses',
        label: 'courses/public:courses',
        items: [
          CoursesCollection,
          CoursesEnrolledCourses,
          CourseImage,
          CourseActions,
          CourseMetaData,
          CourseTableOfContents,
          CourseNavigation,
          CoursePagination,
          CourseHero,
          CourseDetail,
          CourseContent,
          CoursesStudentAdvisors,
          CoursesStudentProfile,
          CoursesDeleteStudentAccount,
          CourseRegistration,
          CoursesStudentNotifications,
          CourseStatus,
          CoursesMessages,
        ],
      },
    ],
    resources: {
      CourseProvider: {
        type: 'model',
        name: 'CourseProvider',
        label: 'courses/public:courseProviders',
        labelSingular: 'courses/public:courseProvider',
        ResourcePicker: ProviderPicker,
        fields: {
          title: {
            label: 'courses/public:title',
            type: 'string',
          },
        },
      },
      Course: {
        type: 'model',
        name: 'Course',
        label: 'courses/public:courses',
        labelSingular: 'courses/public:course',
        ResourcePicker: CoursePicker,
        fields: {
          'title': {
            label: 'courses/public:title',
            type: 'string',
          },
          'abstract': {
            label: 'courses/public:abstract',
            type: 'string',
          },
          'images.default': {
            label: 'courses/public:image',
            type: 'image',
          },
        },
      },
      CourseLesson: {
        type: 'model',
        name: 'CourseLesson',
        label: 'courses/public:lessons',
        labelSingular: 'courses/public:lesson',
        fields: {
          title: {
            label: 'courses/public:title',
            type: 'string',
          },
          abstract: {
            label: 'courses/public:abstract',
            type: 'string',
          },
        },
      },
      CourseSlide: {
        type: 'model',
        name: 'CourseSlide',
        label: 'courses/public:slides',
        labelSingular: 'courses/public:slide',
        fields: {
          title: {
            label: 'courses/public:title',
            type: 'string',
          },
          abstract: {
            label: 'courses/public:abstract',
            type: 'string',
          },
        },
      },
    },
  }
}
