import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'
import { isEmpty } from '@utils/objects'
import { escapeSpecialChars } from '@utils/strings'
import { isArray } from '@utils/types'

export default function usePersons({
  channelId,
  search = '',
  limit = 10,
  page = 1,
  filters = {},
  fetchOnSearch = false,
  sort = 'firstName,middleName,lastName',
  sortOrder = 'asc',
}) {
  let queryFilter = {}
  const orFilters = []

  // search
  if (search) {
    const searchFilter = [
      {
        firstName: {
          $regex: escapeSpecialChars(search),
          $options: 'i',
        },
      },
      {
        middleName: {
          $regex: escapeSpecialChars(search),
          $options: 'i',
        },
      },
      {
        lastName: {
          $regex: escapeSpecialChars(search),
          $options: 'i',
        },
      },
    ]
    orFilters.push(searchFilter)
  }

  // status
  if (!isEmpty(filters['status'])) {
    const statusFilter = []
    if (filters['status']['active']) {
      statusFilter.push({ enabled: true, deleted: false })
    }
    if (filters['status']['disabled']) {
      statusFilter.push({ enabled: false, deleted: false })
    }
    if (filters['status']['deleted']) {
      statusFilter.push({ deleted: true })
    }
    orFilters.push(statusFilter)
  } else {
    queryFilter.deleted = false
  }

  // roles
  if (!isEmpty(filters['roles'])) {
    queryFilter.roles = isArray(filters['roles'])
      ? { $in: filters['roles'] }
      : { $in: Object.keys(filters['roles']) }
  }

  // ids
  if (!isEmpty(filters['ids'])) {
    queryFilter._id = { $in: filters['ids'] }
  }

  // ignore ids
  if (!isEmpty(filters['ignore-ids'])) {
    queryFilter._id = { $nin: filters['ignore-ids'] }
  }

  // enable multiple 'or' filters
  if (orFilters.length > 0) {
    queryFilter['$and'] = orFilters.map(filter => ({ $or: filter }))
  }

  const { data, error, isLoading, isFetching } = useQuery(
    ['persons', { channelId, search, limit, page, filters, sort, sortOrder }],
    () =>
      getFetch(
        channelId ? `/api/media-library/channels/${channelId}/persons` : null,
        {
          filter: queryFilter,
          sort: `${sortOrder === 'desc' ? '-' : ''}${sort}`,
          limit: limit,
          page: page,
        }
      ),
    { enabled: fetchOnSearch ? search !== '' : true }
  )

  return {
    persons: data?.items ?? [],
    count: data?.count ?? 0,
    error,
    loading: isLoading,
    fetching: isFetching,
  }
}
