export function getValueAtBreakpoint(design, data, breakpoint, defaultValue) {
  const defaults = { value: defaultValue }
  if (!data || !breakpoint) return defaults
  if (typeof data !== 'object') return defaults // TODO: remove check once page's data is clear

  if (data[breakpoint]) return { ...defaults, value: data[breakpoint] } // when a value for the current breakpoint exists, just return it (and stop looking for values here).

  const breakpointKeys = Object.keys(design.breakpoints || {})
  const breakpointIndex = breakpointKeys.indexOf(breakpoint)
  const prevBreakpoints = breakpointKeys.slice(0, breakpointIndex).reverse() // fetching only the previous, smaller breakpoints, in reverse order to get the closests ones to the current one first.

  let value = defaults.value
  let atBreakpoint = 'xs'

  for (const breakpointKey of prevBreakpoints) {
    // if breakpoint has some value
    if (data[breakpointKey]) {
      // set the value at this breakpoint
      value = data[breakpointKey]
      // set and indicate this breakpoint
      atBreakpoint = breakpointKey
      break // just get the closes one and stop looking for values here
    }
  }

  // The "inherit" attribute will indicate that the value was not found in the current breakpoint
  return {
    value,
    inherit: !!value && value !== defaultValue,
    breakpoint: atBreakpoint,
  }
}
