import PropTypes from 'prop-types'
import React from 'react'

import Clickable from '@ui/helpers/Clickable'
import useToggle from '@ui/helpers/useToggle'
import Box from '@ui/layout/Box'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function CollapsablePanel({ children, defaultOpen, title }) {
  const [open, onToggle] = useToggle(defaultOpen)

  if (!children) return null

  return (
    <Box space="sm">
      <Clickable
        className={`flex flex-row items-center justify-between space-x-2 pb-2 pr-2 ${
          open ? 'border-b' : ''
        }`}
        onClick={onToggle}
      >
        <h4 className="font-semibold text-gray-500">{title}</h4>
        <Icon
          name={open ? 'chevron-up' : 'chevron-down'}
          className="text-sm text-gray-400"
        />
      </Clickable>
      <Box
        className={`transition-all duration-300 ease-in-out ${
          open ? 'py-2 opacity-100' : 'max-h-0 overflow-hidden py-0 opacity-0'
        }`}
        space="lg"
      >
        {children}
      </Box>
    </Box>
  )
}
CollapsablePanel.propTypes = {
  children: PropTypes.node,
  defaultOpen: PropTypes.bool,
  title: PropTypes.node,
}
