import { useState } from 'react'

const defaultOptions = {
  duration: 5000, // Default duration of the toast in milliseconds (5 seconds)
  resetOffset: 1000, // Default offset to reset data in milliseconds after the toast has disappeared (1 second).
}

export function useToast(options = defaultOptions) {
  const { duration, resetOffset } = Object.assign({}, defaultOptions, options)
  const [message, setMessage] = useState('')
  const [variant, setVariant] = useState(undefined)
  const [visible, setVisible] = useState(false)

  const showToast = ({ message, variant = 'default' }) => {
    // Set the message and variant
    setMessage(message)
    setVariant(variant)

    // Show the toast
    setVisible(true)

    setTimeout(() => {
      // Hide the toast
      setVisible(false)

      // Reset the message and variant after the toast has disappeared
      setTimeout(() => {
        setMessage('')
        setVariant(undefined)
      }, resetOffset)
    }, duration)
  }

  function hideToast() {
    setVisible(false),
      setTimeout(() => {
        setMessage('')
        setVariant(undefined)
      }, resetOffset)
  }

  return {
    isToastVisible: visible,
    message,
    variant,
    showToast,
    hideToast,
  }
}
