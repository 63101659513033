import PropTypes from 'prop-types'
import React from 'react'

const indentSizes = {
  md: 'pl-4',
  lg: 'pl-7',
}

/**
 * A container for grouping subfields
 * @param {Object} props
 * @param {React.ReactNode} props.children
 * @param {'md' | 'lg'} props.indentSize
 */
export function SubFields({ children, indentSize = 'md' }) {
  return (
    <div className={`${indentSizes[indentSize]} border-l flex flex-col gap-4`}>
      {children}
    </div>
  )
}

SubFields.propTypes = {
  children: PropTypes.node,
  indentSize: PropTypes.oneOf(['md', 'lg']),
}

export default SubFields
