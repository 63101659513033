import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'
import Link from '@ui/navigation/Link'

const Icon = React.lazy(() => import('@ui/icons/Icon'))

export default function PublicationPosterItem({
  publication,
  showDescription,
  showImage,
  showLink,
  linkLabel,
}) {
  return (
    <div className="flex flex-col space-y-4 rounded-md border border-gray-100 p-4 shadow-md">
      {showImage && (
        <div className="shrink-0">
          <div className="aspect-w-16 aspect-h-9 -mx-4 -mt-4 bg-gradient-to-br from-gray-50 to-gray-200">
            {publication.cover?.file ? (
              <Image
                file={publication.cover.file}
                width={500}
                className="rounded object-cover"
                alt={publication.title}
              />
            ) : (
              <div className="flex items-center justify-center h-full text-5xl">
                <Icon name="image-slash" className="text-gray-300" />
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex-grow space-y-0">
        <div>
          <h3 className="text-lg font-bold">
            <Link href={`/publications/${publication.id}`}>
              {publication.title}
            </Link>
          </h3>
        </div>
        {showDescription && (
          <p className="text-sm">
            {publication.abstract || publication.subtitle}
          </p>
        )}
        {showLink && (
          <Link href={`/publications/${publication.id}`}>
            {linkLabel} <Icon name="arrow-right" />
          </Link>
        )}
      </div>
    </div>
  )
}
PublicationPosterItem.propTypes = {
  publication: PropTypes.object.isRequired,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  showLink: PropTypes.bool,
  linkLabel: PropTypes.string,
  site: PropTypes.object,
}
