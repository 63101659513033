import PropTypes from 'prop-types'
import React from 'react'

import FormatDate from '@ui/helpers/FormatDate'
import { getImageUrl } from '@utils/images'

import { useArticleFlags } from '../hooks/useArticleFlags'

export default function ArticlePosterItem({
  article,
  showDate,
  showFlags,
  showImage,
  site,
}) {
  const { startsAt } = article.sites[site?.id] || {}

  const imageUrl = showImage ? getImageUrl(article.image?.file, 'w:800') : null

  const displayedFlags = useArticleFlags(article, site, showFlags)

  return (
    <div
      className="aspect-w-3 aspect-h-4 relative flex flex-col overflow-hidden rounded-md border border-gray-100 bg-primary-400 bg-cover bg-center shadow-md"
      style={showImage ? { backgroundImage: `url(${imageUrl})` } : undefined}
    >
      <div className="flex flex-grow flex-col justify-end rounded-md bg-gradient-to-b from-transparent via-gray-900/40 to-gray-900/90 p-6 pb-12 text-white">
        <div className="flex flex-col gap-2">
          {showDate && (
            <p className="text-sm font-semibold text-primary-300">
              <FormatDate date={startsAt || article.createdAt} format="PP" />
            </p>
          )}
          {showFlags && <i className="text-sm italic">{displayedFlags}</i>}
          <h3 className="text-2xl font-bold">
            <a href={`#article-${article.id}`}>{article.title}</a>
          </h3>
        </div>
      </div>
    </div>
  )
}
ArticlePosterItem.propTypes = {
  article: PropTypes.object.isRequired,
  showDate: PropTypes.bool,
  showFlags: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  site: PropTypes.object,
}
