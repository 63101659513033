import { useTranslation } from 'react-i18next'

import { useDesignContext } from '@modules/web/components/DesignProvider'

export function useButtonVariants() {}

export function useButtonVariantsOptions() {
  const design = useDesignContext()
  const { buttons, namespace } = design || {}
  const { variants } = buttons || {}

  const { t } = useTranslation(namespace || 'web/content-editor')

  if (!variants?.length > 0) return []

  return variants.map(variant => ({
    label: t(`buttonVariant_${variant}`),
    value: variant,
  }))
}
