import PropTypes from 'prop-types'
import React from 'react'

import { Controller, useFormContext } from 'react-hook-form'

import { useRules } from '../validationHooks'
import LocationPickerField from './Field'

/**
 * LocationPicker controller component
 */
export default function LocationPickerController({
  className,
  defaultZoom,
  height,
  help,
  label,
  mapDefaultOpen,
  name,
  onResult,
  required,
  shouldUnregister,
  types,
  value,
  width,
}) {
  const { control } = useFormContext()

  const rules = useRules({ required })

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={value}
      rules={rules}
      shouldUnregister={shouldUnregister}
      render={({ field, fieldState }) => (
        <LocationPickerField
          className={className}
          name={name}
          label={label}
          help={help}
          id={name}
          error={fieldState.error}
          required={rules?.required?.value}
          onChange={field.onChange}
          onResult={onResult}
          value={field.value}
          height={height}
          width={width}
          defaultZoom={defaultZoom}
          types={types}
          mapDefaultOpen={mapDefaultOpen}
        />
      )}
    />
  )
}
LocationPickerController.propTypes = {
  className: PropTypes.string,
  defaultZoom: PropTypes.number,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  help: PropTypes.string,
  label: PropTypes.string,
  mapDefaultOpen: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onResult: PropTypes.func,
  required: PropTypes.bool,
  shouldUnregister: PropTypes.bool,
  types: PropTypes.array,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}
LocationPickerController.displayName = 'LocationPicker'
