import React from 'react'

import { useTranslation } from 'react-i18next'

import Select from '@ui/data-entry/Select'

export function TypographySelect() {
  const { t } = useTranslation('designs/adventist')

  return (
    <>
      <Select
        label={t('typographyTheme')}
        help={t('typographyThemeHelp')}
        name="typography"
        shouldUnregister
        required
      >
        <Select.Placeholder label="-" />
        <Select.Option
          label="Sans: Noto Sans, Serif: Noto Serif"
          value="noto"
        />
        <Select.Option label="Sans: Inter, Serif: Noto Serif" value="inter" />
      </Select>
    </>
  )
}
