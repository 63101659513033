import React from 'react'

import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import {
  ModelPermissionsWrap,
  PermissionCheckbox,
} from '@modules/users/components/GroupPermissions'

export default function CategoriesPermissions() {
  const { t } = useTranslation('categories')
  const { watch } = useFormContext()

  const canReadCategories = watch('categories[read]')

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
      <ModelPermissionsWrap
        label={t('categories')}
        help={t('categoriesPermissionsHelp')}
      >
        <PermissionCheckbox model="categories" permission="read" />
        <PermissionCheckbox
          model="categories"
          permission="create"
          disabled={!canReadCategories}
        />
        <PermissionCheckbox
          model="categories"
          permission="update"
          disabled={!canReadCategories}
        />
        <PermissionCheckbox
          model="categories"
          permission="delete"
          disabled={!canReadCategories}
        />
        <PermissionCheckbox
          model="categories"
          permission="translate"
          label={t('users:translatePermission')}
          tooltip={t('users:translatePermissionHelp', {
            model: t('categories'),
          })}
          disabled={!canReadCategories}
        />
      </ModelPermissionsWrap>
    </div>
  )
}
