import PropTypes from 'prop-types'
import React from 'react'

import Image from '@ui/data-display/Image'
import Link from '@ui/navigation/Link'

export default function ArticleListItem({
  article,
  showDescription,
  showImage,
}) {
  return (
    <div className="flex space-x-4">
      {showImage && (
        <div className="shrink-0">
          <Image
            file={article.image?.file}
            width={192}
            className="aspect-1 w-24 rounded object-cover"
            alt={article.title}
          />
        </div>
      )}
      <div className="flex-grow space-y-0">
        <div>
          <h3 className="text-lg font-bold">
            <Link href={`/articles/${article.id}`}>{article.title}</Link>
          </h3>
        </div>
        {showDescription && (
          <p className="text-sm">{article.abstract || article.subtitle}</p>
        )}
      </div>
    </div>
  )
}
ArticleListItem.propTypes = {
  article: PropTypes.object.isRequired,
  showDate: PropTypes.bool,
  showFlags: PropTypes.bool,
  showDescription: PropTypes.bool,
  showImage: PropTypes.bool,
  site: PropTypes.object,
}
