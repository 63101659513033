import { useMemo } from 'react'

import { useDesignContext } from '../../DesignProvider'
import { getValueAtBreakpoint } from '../helpers/getValueAtBreakpoint'
import useBreakpoint from '../shared/useBreakpoint'

/**
 * Returns a value for a specific breakpoint, and fall back to a previous breakpoint if value is not set for the current one, or the default value when none of the previous attempts has yieled one.
 *
 * @param {object} data an object with breakpoints as keys where we want to fetch data from
 * @param {string} breakpoint the current breakpoint
 * @param {string|number|boolean} defaultValue the ultimate fall back value
 * @returns `object`
 */
export default function useValueAtBreakpoint(
  data,
  defaultValue,
  breakpointOverride
) {
  const design = useDesignContext()
  const { breakpoint } = useBreakpoint()

  const currentBreakpoint = breakpointOverride || breakpoint

  return useMemo(
    () => getValueAtBreakpoint(design, data, currentBreakpoint, defaultValue),
    [data, design, currentBreakpoint, defaultValue]
  )
}
