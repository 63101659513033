import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import MultipleMenuPicker from '@modules/web/components/menus/MultipleMenuPicker'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Submit from '@ui/data-entry/Submit'
import TextArea from '@ui/data-entry/TextArea'

export default function FooterForm({ site, onSubmit }) {
  const design = useDesignContext()
  const { t } = useTranslation(['designs/adventisten-de', 'web/appearance'])

  const { onImageUpload } = useImageUpload()

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <ImageUpload
            name="design[footer][logo]"
            label={t('footerLogo')}
            help={t('footerLogoHelp')}
            onUpload={onImageUpload}
            maxSize="10mb"
            accept={design.logoFormats}
            required
          />
          <TextArea
            name="design[footer][description]"
            label={t('web/appearance:footerDescription')}
            countWords
          />
          <Input
            name="design[footer][copyright]"
            label={t('web/appearance:footerCopyright')}
          />
          <MenuPicker
            name="design[footer][footerMenu]"
            label={t('web/appearance:footerMenu')}
            help={t('web/appearance:footerMenuHelp')}
          />
          <MenuPicker
            name="design[footer][copyrightMenu]"
            label={t('copyrightMenu')}
            help={t('copyrightMenuHelp')}
          />
          <MultipleMenuPicker
            label={t('additionalMenus')}
            name="design[footer][additionalMenus]"
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
FooterForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
