import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Tag from '@ui/data-display/Tag'

import articleStatusesMap from '../helpers/statusesMap'

export default function ArticleStatusTag({
  article,
  size,
  enabled,
  className,
}) {
  const { t } = useTranslation('articles')

  if (!article) return null

  const { status } = article || {}
  const statusVariant = articleStatusesMap[status] || {
    label: 'status_invalid',
    variant: 'danger',
    icon: 'times-circle',
  }

  return (
    <Tag
      variant={statusVariant.variant}
      label={t(`status_${status}`)}
      size={size}
      icon={statusVariant.icon}
      className={`${className} ${
        enabled || article?.enabled ? '' : 'opacity-50'
      }`}
    />
  )
}
ArticleStatusTag.propTypes = {
  article: PropTypes.object,
  className: PropTypes.string,
  enabled: PropTypes.bool,
  size: PropTypes.string,
}
ArticleStatusTag.defaultProps = {
  className: '',
  status: 'draft',
  size: 'md',
}
