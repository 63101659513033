import PropTypes from 'prop-types'
import React, { useCallback, useMemo, useState } from 'react'

import { QRCodeSVG } from 'qrcode.react'
import { useTranslation } from 'react-i18next'

import Button from '@ui/buttons/Button'
import Message, { ErrorMessage } from '@ui/data-display/Message'
import Form from '@ui/data-entry/Form'
import Input from '@ui/data-entry/Input'
import Password from '@ui/data-entry/Password'
import Submit from '@ui/data-entry/Submit'
import Modal, { ModalProvider } from '@ui/feedback/Modal'
import CopyToClipboardButton from '@ui/helpers/CopyToClipboardButton'
import Box from '@ui/layout/Box'
import Heading from '@ui/typography/Heading'
import Text from '@ui/typography/Text'

import { get2FASecretAndUrl, useEnable2FA } from '../services/AuthService'

const RenderCodes = (codes = []) =>
  codes.map(c => (
    <p key={c}>
      <code>{c}</code>
    </p>
  ))

export default function Enable2FA({ setFormVisible, user }) {
  const { t } = useTranslation('auth')

  const [loading, setLoading] = useState(false)
  const [loadError, setLoadError] = useState(false)
  const [url, setUrl] = useState(null)
  const [secret, setSecret] = useState(null)
  const [showModal, setShowModal] = useState(false)
  const [codes, setCodes] = useState([])

  const formData = useMemo(() => ({ password: '', token: '' }), [])

  const { mutate, isError, isLoading, error, reset } = useEnable2FA()

  const { twoFactorEnabled } = user

  const _onEnable = useCallback(() => {
    const load2FASecret = async () => {
      setLoadError(false)
      setLoading(true)

      const { url, secret, error } = await get2FASecretAndUrl()

      setLoading(false)

      if (error) {
        setLoadError(true)
      } else {
        setUrl(url)
        setSecret(secret)
        setFormVisible(true)
      }
    }

    load2FASecret()
  }, [setFormVisible])

  const _onConfirmEnable = useCallback(
    data => {
      mutate(
        { secret, ...data },
        {
          onSuccess: ({ twoFactorCodes }) => {
            setUrl(null)
            setSecret(null)
            setFormVisible(false)
            setCodes(twoFactorCodes)
            setShowModal(true)
          },
        }
      )
    },
    [mutate, secret, setFormVisible]
  )

  const _onCancelEnable = useCallback(() => {
    setUrl(null)
    setSecret(null)
    setFormVisible(false)
    reset()
  }, [reset, setFormVisible])

  const _onModalDone = useCallback(() => {
    setShowModal(false)
  }, [])

  return (
    <div className="flex flex-col gap-6">
      {loadError && (
        <Message type="danger" text={t('2FAEnableUnexpectedError')} />
      )}

      {url ? (
        <>
          <Heading
            as="h4"
            className="text-center"
            text={t(twoFactorEnabled ? '2FAReenable' : '2FAEnable')}
          />

          <Text className="text-center">{t('2FAEnableHelp')}</Text>

          <div className="m-4 flex justify-center">
            <QRCodeSVG size={200} value={url} />
          </div>

          <Text className="text-center">{t('2FAEnableHelpAlt')}</Text>

          <div className="m-4 flex justify-center">
            <CopyToClipboardButton label={t('2FACopySecret')} value={secret} />
          </div>

          <Form data={formData} onSubmit={_onConfirmEnable}>
            <Box space="md">
              {isError && <ErrorMessage error={error} />}

              <Password
                name="password"
                label={t('password')}
                help={t('currentPasswordHelp')}
                required
                validatePattern={false}
              />
              <Input
                name="token"
                label={t('token')}
                help={t('tokenHelp')}
                required
              />

              <div className="flex justify-end gap-2">
                <Button
                  label={t('cancel')}
                  disabled={isLoading}
                  onClick={_onCancelEnable}
                />
                <Submit
                  label={t(isLoading ? 'enabling' : 'enable')}
                  submitting={isLoading}
                  reverse
                />
              </div>
            </Box>
          </Form>
        </>
      ) : (
        <div className="flex flex-col items-center gap-6">
          {twoFactorEnabled ? (
            <Message
              type="warn"
              className="text-center"
              text={t('2FAReenableWarning')}
            />
          ) : (
            <Text className="text-center">{t('2FADisabledHelp')}</Text>
          )}

          <Button
            icon="lock"
            label={t(twoFactorEnabled ? '2FAReenable' : '2FAEnable')}
            disabled={loading}
            onClick={_onEnable}
            variant="primary"
          />
        </div>
      )}

      {showModal && (
        <ModalProvider initialOpen>
          <Modal
            title={t('2FACodes')}
            acceptLabel={t('done')}
            onAccept={_onModalDone}
            size="md"
          >
            <div className="flex flex-col gap-4">
              <Text>{t('2FACodesMessage1')}</Text>
              <Text>{t('2FACodesMessage2')}</Text>
              <Text>
                <strong>{t('2FACodesMessage3')}</strong>
              </Text>

              <div className="flex justify-evenly rounded border p-4">
                <div>{RenderCodes(codes.slice(0, 5))}</div>
                <div>{RenderCodes(codes.slice(5))}</div>
              </div>

              <div className="flex justify-center">
                <CopyToClipboardButton
                  label={t('2FACodesCopy')}
                  value={codes.join(',')}
                />
              </div>
            </div>
          </Modal>
        </ModalProvider>
      )}
    </div>
  )
}
Enable2FA.propTypes = {
  setFormVisible: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
}
