/* Users URLs */
import { useLocation } from 'react-router-dom'

export function getUsersUrl(params = '') {
  return `/users${params}`
}

export function getAddUserUrl(params = '') {
  return `/users/add${params}`
}

export function getUserUrl(id, params = '') {
  return `/users/${id}/detail${params}`
}

export function getEditUserUrl(id, params = '') {
  return `/users/${id}/edit${params}`
}

/**
 * Hook to get the users URLs
 * @param {Object} user The user object (optional)
 */
export function useUsersUrls(user = null) {
  const { search: searchQuery } = useLocation()

  const urls = {
    usersListUrl: getUsersUrl(searchQuery),
    addUserUrl: getAddUserUrl(searchQuery),
    detailUserUrl: null,
    editUserUrl: null,
  }

  if (!user) {
    return urls
  }

  urls.detailUserUrl = getUserUrl(user.id, searchQuery)
  urls.editUserUrl = getEditUserUrl(user.id, searchQuery)

  return urls
}

/* Groups URLs */

export function getGroupsUrl(params = '') {
  return `/groups${params}`
}

export function getAddGroupUrl(params = '') {
  return `/groups/add${params}`
}

export function getGroupUrl(id, params = '') {
  return `/groups/${id}/details${params}`
}

export function getEditGroupUrl(id, params = '') {
  return `/groups/${id}/edit${params}`
}

/**
 * Hook to get the groups URLs
 * @param {Object} group The group object (optional)
 */
export function useGroupsUrls(group = null) {
  const { search: searchQuery } = useLocation()

  const urls = {
    groupsListUrl: getGroupsUrl(searchQuery),
    addGroupUrl: getAddGroupUrl(searchQuery),
    detailGroupUrl: null,
    editGroupUrl: null,
  }

  if (!group) {
    return urls
  }

  urls.detailGroupUrl = getGroupUrl(group.id, searchQuery)
  urls.editGroupUrl = getEditGroupUrl(group.id, searchQuery)

  return urls
}
