import { isArray } from '@utils/types'

export function getSelectedOption(value, options = []) {
  if (!value || !isArray(options)) return null

  for (const group of options) {
    for (const option of group.colors) {
      if (option.value === value) {
        return option
      }
    }
  }

  return null
}
