import de from 'date-fns/locale/de'
import enGB from 'date-fns/locale/en-GB'
import enUS from 'date-fns/locale/en-US'
import es from 'date-fns/locale/es'
import fr from 'date-fns/locale/fr'
import it from 'date-fns/locale/it'
import ja from 'date-fns/locale/ja'
import ru from 'date-fns/locale/ru'
import zhCN from 'date-fns/locale/zh-CN'

import { useLocaleContext } from '../providers/LocaleProvider'

export const dateTimelocales = {
  'de': de,
  'en-GB': enGB,
  'en': enUS,
  'es': es,
  'fr': fr,
  'it': it,
  'ja': ja,
  'no': enUS,
  'ru': ru,
  'zh': zhCN,
}

export function getLocale(locale) {
  return dateTimelocales[locale] || dateTimelocales.en
}

export function useDatetimeLocale() {
  const { dateTimeLocale, locale } = useLocaleContext()
  return getLocale(dateTimeLocale || locale)
}
