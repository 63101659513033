import React from 'react'

import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import useEventPermissions from '../services/hooks/useEventPermissions'

const MenuGroup = React.lazy(() => import('@ui/navigation/Menu/MenuGroup'))
const MenuItem = React.lazy(() => import('@ui/navigation/Menu/MenuItem'))

export default function EventsMenuGroup() {
  const { t } = useTranslation('events/public')
  const location = useLocation()
  const inEvents = location.pathname.startsWith('/events')

  const {
    canReadEvents,
    canReadOrganizers,
    canReadCategories,
    canReadRegions,
  } = useEventPermissions()

  if (!canReadEvents) return null

  return (
    <MenuGroup
      name="events"
      label={t('events')}
      icon="calendar"
      active={inEvents}
    >
      {canReadEvents && (
        <MenuItem
          active={location.pathname.startsWith('/events/list')}
          label={t('events')}
          href="/events/list"
          icon="calendar"
        />
      )}
      {canReadOrganizers && (
        <MenuItem
          active={location.pathname.startsWith('/events/organizers')}
          label={t('organizers')}
          href="/events/organizers"
          icon="people-group"
        />
      )}
      {canReadRegions && (
        <MenuItem
          active={location.pathname.startsWith('/events/regions')}
          label={t('regions')}
          href="/events/regions"
          icon="map-marked-alt"
        />
      )}
      {canReadCategories && (
        <MenuItem
          active={location.pathname.startsWith('/events/categories')}
          label={t('categories')}
          href="/events/categories"
          icon="tags"
        />
      )}
    </MenuGroup>
  )
}
