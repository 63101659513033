import React from 'react'

import { useTranslation } from 'react-i18next'
import { Navigate } from 'react-router-dom'

import Card from '@ui/data-display/Card'
import Content from '@ui/data-display/Content'
import Empty from '@ui/data-display/Empty'
import Tag, { Tags } from '@ui/data-display/Tag'
import Heading from '@ui/typography/Heading'
import Text from '@ui/typography/Text'
import { timeSince } from '@utils/datetime'
import { getAvatarUrl } from '@utils/images'

import useCurrentUser from '../helpers/useCurrentUser'

export default function Profile() {
  const { t } = useTranslation(['users', 'auth'])
  const { user, loading } = useCurrentUser()

  if (!user && !loading) return <Navigate to="/auth/login" />

  if (!user) return <Empty label={t('404')} />

  const {
    name,
    email,
    groups,
    isAdmin,
    createdAt,
    lastLoginAt,
    twoFactorEnabled,
    entity,
  } = user

  return (
    <Content>
      <Content.Header
        title={t('auth:profile')}
        subtitle={t('auth:profileSubtitle')}
        headTitle={user.name}
      />
      <Content.Body className="flex flex-col gap-10">
        <div className="flex flex-row justify-start gap-4">
          <Card>
            <Card.Cover src={getAvatarUrl(user, 'w:400')} title={name} />
            <Card.Body>
              <Heading as="h3" text={name} />
              <Text>{email}</Text>
              <Text>{entity?.name}</Text>
            </Card.Body>
          </Card>

          <div className="flex flex-col justify-between gap-4">
            <Card>
              <Card.Header title={t('createdAt')} />
              <Card.Body>{timeSince(createdAt, 'PPP')}</Card.Body>
            </Card>

            <Card>
              <Card.Header title={t('lastLoginAt')} />
              <Card.Body>
                {lastLoginAt ? timeSince(lastLoginAt, 'PPP') : t('never')}
              </Card.Body>
            </Card>

            <Card>
              <Card.Header title={t('auth:2FA')} />
              <Card.Body>
                <Tags>
                  <Tag
                    icon={twoFactorEnabled ? 'lock' : 'lock-open'}
                    label={t(twoFactorEnabled ? 'enabled' : 'disabled')}
                    variant={twoFactorEnabled ? 'success-light' : 'warn-light'}
                  />
                </Tags>
              </Card.Body>
            </Card>
          </div>
        </div>

        <div className="flex flex-row justify-start gap-4">
          <Card>
            <Card.Header title={t('groups')} />
            <Card.Body>
              {groups?.length > 0 ? (
                <ul className="flex flex-col gap-2 divide-y">
                  {groups?.map(group => {
                    const { id, name, entity } = group
                    return (
                      <li key={id} className="flex flex-col pt-2 first:pt-0">
                        <span className="font-semibold">{name}</span>
                        <span className="text-sm text-gray-500">
                          {entity?.name}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <Empty
                  icon="users-class"
                  label={t('noGroups')}
                  description={t('noGroupsDescription')}
                />
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Header title={t('roles')} />
            <Card.Body>
              {isAdmin ? (
                <Tags>
                  {isAdmin && <Tag label={t('admin')} variant="danger-light" />}
                </Tags>
              ) : (
                <Empty
                  icon="user-shield"
                  label={t('noRoles')}
                  description={t('noRolesDescription')}
                />
              )}
            </Card.Body>
          </Card>
        </div>
      </Content.Body>
    </Content>
  )
}
