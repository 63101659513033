import useCurrentEntity from '@modules/entities/services/hooks/useCurrentEntity'
import { isEmpty } from '@utils/objects'
import { isFunction } from '@utils/types'

import {
  checkHasAccessToEntity,
  checkHasAccessToModuleSubentities,
} from './permissionChecks'
import useCurrentUser from './useCurrentUser'

// Add more as needed
// TODO: Move to webgrouppermissions and export from there
const webPermissionsMap = [
  'sites',
  'pages',
  'redirects',
  'menus',
  'layouts',
  'pagePresets',
  'analytics',
  'reports',
]

// NOTE: This file has a readme in the root folder of the auth module

/**
 * Get the user group with the permissions to access a module
 * @param {object} user - The user object
 * @param {string} module - The module name
 * @param {string} currentEntityId - The current entity ID is required when noEntityCheck is not specified
 * @param {string} permission - The permission name
 * @param {string} recordId - The record ID
 * @param {string} recordModule - The record module
 * @param {boolean} noEntityCheck - If true, the entity check is skipped (default: false)
 * @param {function} delegateFunction - A function to delegate the permission check
 * @returns {object} - The group object with the permissions, or false if no permissions
 */
export function getUserGroupWithPermissions({
  user,
  currentEntityId,
  entity,
  module = '',
  permission = 'read',
  recordId = '',
  recordModule = '',
  noEntityCheck = false,
  delegateFunction,
}) {
  // If user is a global admin, will have all permissions
  if (user?.isAdmin) return true

  // If user is not logged in, or has no groups, will have no permissions
  if (!module || !user || !user?.groups || user?.groups?.length === 0) {
    return false
  }

  // First, filter the groups that have the permission
  const groupsWithPermissions = user.groups.filter(
    group => group.permissions[module]?.[permission]
  )

  if (groupsWithPermissions.length === 0) {
    return false
  }

  // Next filter the groups that have the entity or have the entity as an ancestor
  const groupsWithEntity = groupsWithPermissions.filter(group => {
    const groupPermissionsEntityId =
      group.permissions?.entity?.records?.[0] ||
      group.entity?.id ||
      group.entity

    return checkHasAccessToEntity({
      group,
      entity,
      groupPermissionsEntityId,
    })
  })

  if (groupsWithEntity.length === 0) {
    return false
  }

  // Finally, check if the group has access to the record
  const groupWithPermissions = groupsWithEntity.find(group => {
    // This entity is the one specified in the group permissions, not the one the group is assigned to
    // If no entity is specified, we use the current entity per the rules agreed on
    const groupPermissionsEntityId =
      group.permissions?.entity?.records?.[0] ||
      group.entity?.id ||
      group.entity

    // If the record module is not specified, we use the module name to get the records permissions
    const records = group.permissions[recordModule || module]?.records || []

    // If we have records and a record ID, we check if the record is in the list of allowed records and the appropriate permission is set on the group
    if (
      !isEmpty(records) &&
      recordId &&
      records?.includes(recordId) &&
      group.permissions[module]?.[permission]
    ) {
      return true
    }

    // If no records or no recordId given and a permission is set on the group, we return true
    if (group.permissions[module]?.[permission] && module !== 'subentities') {
      return true
    }

    // For subentities we have to check if the ancestors have the permission
    if (module === 'subentities') {
      if (entity.id !== groupPermissionsEntityId) {
        return checkHasAccessToEntity({
          group,
          entity,
          permission,
          groupPermissionsEntityId,
        })
      }

      // If the entity in question is the group entity one when trying to edit, we check if the group has the update permission
      if (entity.id === groupPermissionsEntityId && permission === 'update') {
        return group.permissions['entity']?.[permission]
      }

      // Subentities has been handled at this point. If another module is passed, we continue with the normal flow
      if (!noEntityCheck) return false
    }

    // The web and user modules ara a special case. The web module doesn't have records. Both web and user modules can have the permissions apply when on a subentity.
    /* Check that the module is related to web, users or groups. If so, we proceed to check the following:
        - If it’s the same entity as the group defines where this permission is found, then allow.
        - Else, if permission is available on a group that the current entity is an ancestor of, and subentities in web or users is enabled, then allow.
        */
    if ([...webPermissionsMap, 'users', 'groups'].includes(module)) {
      const hasAccessToSubentityModule = checkHasAccessToModuleSubentities({
        currentEntityId,
        group,
        entity, // This can be sent to be a specific entity.
        module,
        subentitiesModule: ['users', 'groups'].includes(module)
          ? 'users'
          : 'web',
        permission,
        user,
        records,
        recordId,
        delegateFunction,
        groupPermissionsEntityId,
      })

      if (hasAccessToSubentityModule) return true

      // Subentity module access permissions have been handled at this point. If another module is passed, we continue with the normal flow
      if (!noEntityCheck) return false
    }

    if (!noEntityCheck) {
      // Note: if group didn't have an entity, the check is done against the user's entity
      if (currentEntityId === groupPermissionsEntityId) {
        // Grant access to all records if no records are specified, or if the record is in the list of allowed records
        return (
          isEmpty(records) ||
          records?.includes(recordId) ||
          (isFunction(delegateFunction) &&
            delegateFunction({ module, permission, recordId, records }))
        )
      } else {
        return false
      }
    }

    // If no record ID is specified, we grant access to the module
    if (isEmpty(recordId)) return true

    // Grant access to all records if no records are specified, or if the record is in the list of allowed records
    return isEmpty(records) || records?.includes(recordId)
  })

  return groupWithPermissions
}

/**
 * Hook to check if the user has the permission to access a module
 * @param {*} params - The parameters
 * @param {boolean} params.admin - Expect user to have admin permissions (default: false)
 * @param {string} params.module - The module name (required)
 * @param {string} params.permission - The permission name
 * @param {string} params.recordId - The record ID
 * @param {object} params.entity - Entity override object (default: null)
 * @param {boolean} params.noEntityCheck - If true, the entity check is skipped (default: false)
 * @returns
 */
export function useUserGroupPermissions({
  admin = false,
  module = '',
  permission = '',
  recordId = '',
  entity,
  noEntityCheck = false,
  disabled = false,
  delegateFunction,
} = {}) {
  const { user, loading: userLoading } = useCurrentUser()
  const { entity: currentEntity, loading: entityLoading } = useCurrentEntity()

  // if check is disabled, return true
  if (disabled) return true

  // if user is admin, return true
  if (admin) {
    return user?.isAdmin || false
  }

  // If some of the required data is not yet available, return null (loading)
  if (userLoading || entityLoading) return null

  // if there are no permissions set, return true
  if (!module || !permission) return true

  const groupWithPermissions = getUserGroupWithPermissions({
    user,
    currentEntityId: currentEntity?.id,
    entity: entity || currentEntity,
    module,
    permission,
    recordId,
    noEntityCheck,
    delegateFunction,
  })

  return groupWithPermissions?.permissions?.[module]
}

/**
 * Check if the user has the permission to access a module
 * @param {object} user - The user object
 * @param {string} module - The module name
 * @param {string} currentEntityId - The current entity ID is required when noEntityCheck is not specified
 * @param {string} permission - The permission name
 * @param {string} recordId - The record ID
 * @param {string} recordModule - The record module
 * @param {boolean} noEntityCheck - If true, the entity check is skipped (default: false)
 * @param {function} delegateFunction - A function to delegate the permission check
 * @returns {boolean} - True if the user has the permission, false otherwise
 */
export function isUserAuthorized({
  user,
  currentEntityId,
  entity,
  module = '',
  permission = 'read',
  recordId = '',
  recordModule = '',
  noEntityCheck = false,
  delegateFunction,
}) {
  // Get the group with the permissions, if any
  const groupWithPermissions = getUserGroupWithPermissions({
    user,
    currentEntityId,
    entity,
    module,
    permission,
    recordId,
    recordModule,
    noEntityCheck,
    delegateFunction,
  })

  // And return a boolean based on the result
  return !!groupWithPermissions
}

/**
 * Hook to check if the user has the permission to access a module
 * @param {*} params - The parameters
 * @param {boolean} params.admin - Expect user to have admin permissions (default: false)
 * @param {string} params.module - The module name (required)
 * @param {string} params.permission - The permission name
 * @param {string} params.recordId - The record ID
 * @param {object} params.entity - Entity override object (default: null)
 * @param {boolean} params.noEntityCheck - If true, the entity check is skipped (default: false)
 * @param {boolean} params.disabled - If true, the check is disabled (default: false)
 * @param {function} params.delegateFunction - A function to delegate the permission check
 * @return (`boolean | null`) Returns `true` if the user has the permission, `null` if is still loading, and `false` otherwise
 */
export default function useIsUserAuthorized({
  admin = false,
  module = '',
  permission = '',
  recordId = '',
  entity,
  noEntityCheck = false,
  disabled = false,
  delegateFunction,
} = {}) {
  const { user, loading: userLoading } = useCurrentUser()
  const { entity: currentEntity, loading: entityLoading } = useCurrentEntity()

  // if check is disabled, return true
  if (disabled) return true

  // if user is admin, return true
  if (admin) {
    return user?.isAdmin || false
  }

  // If some of the required data is not yet available, return null (loading)
  if (userLoading || entityLoading) return null

  // if there are no permissions set, return true
  if (!module || !permission) return true

  return isUserAuthorized({
    user,
    currentEntityId: currentEntity?.id,
    entity: entity || currentEntity,
    module,
    permission,
    recordId,
    noEntityCheck,
    delegateFunction,
  })
}

/**
 * Hook to check if the user has the permission to access a module
 * @param {*} params - The parameters
 * @param {string} params.module - The module name (required)
 * @param {string[]|object[]} params.permissions - The permissions names to check (required)
 * @param {object} params.entity - Entity override object (default: null)
 * @param {boolean} params.noEntityCheck - If true, the entity check is skipped (default: false)
 * @return {object} Returns an object with the permissions as keys and booleans as values, `null` if is still loading, and an object with a `loading` key if some of the required data is not yet available
 */
export function useCheckMultiplePermissions({
  module,
  permissions = [],
  entity,
  noEntityCheck = false,
}) {
  const { user, loading: userLoading } = useCurrentUser()
  const { entity: currentEntity, loading: entityLoading } = useCurrentEntity()

  if (!module) {
    throw new Error('Module is required')
  }

  if (!permissions?.length) {
    throw new Error('Permissions are required')
  }

  // If some of the required data is not yet available (loading), return an object with a loading key set to true
  if (userLoading || entityLoading) {
    return {
      loading: true,
    }
  }

  return permissions.reduce((acc, access) => {
    const { permission, recordId = undefined, recordModule = undefined } =
      // If the permission is a string, we use it as the permission name
      typeof access === 'string'
        ? { permission: access }
        : // If the permission is an object, we use the permission name and the recordId
          typeof access === 'object'
          ? access
          : {}

    // Throw an error if the permission is not provided
    if (!permission) {
      throw new Error('Permission is required')
    }

    const hasPermission = isUserAuthorized({
      module,
      permission,
      user,
      currentEntityId: currentEntity?.id,
      entity: entity || currentEntity, // Use the override entity if provided, or fallback to the current entity
      noEntityCheck,
      recordId, // It will be undefined unless the permission is passed as an object with a recordId key
      recordModule, // It will be undefined unless the permission is passed as an object with a recordModule key
    })
    acc[permission] = hasPermission

    return acc
  }, {})
}
