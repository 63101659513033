import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import { useImageUpload } from '@modules/images/services/ImageServices'
import { useDesignContext } from '@modules/web/components/DesignProvider'
import MenuPicker from '@modules/web/components/menus/MenuPicker'
import Section from '@ui/data-display/Section'
import Form from '@ui/data-entry/Form'
import ImageUpload from '@ui/data-entry/ImageUpload'
import Input from '@ui/data-entry/Input'
import Select, { SelectOption, SelectPlaceholder } from '@ui/data-entry/Select'
import Submit from '@ui/data-entry/Submit'

import HeaderCentreLogoImageUpload from './components/HeaderCentreLogoImageUpload'

const headerAppearances = ['visibleLinks', 'collapsedLinks']

export default function AdventistenDeHeaderForm({ site, onSubmit }) {
  const design = useDesignContext()
  const { onImageUpload } = useImageUpload()
  const { t } = useTranslation([design.namespace, 'web/appearance'])

  return (
    <Form data={site} onSubmit={onSubmit} id={`${site.id}-${site.updatedAt}`}>
      <Section>
        <Section.Body>
          <Select
            label={t('headerAppearance')}
            help={t('headerAppearanceHelp')}
            name="design[header][appearance]"
          >
            <SelectPlaceholder label="-" />
            {headerAppearances.map(appearance => (
              <SelectOption
                key={`appearance-${appearance}`}
                label={t(`headerAppearance-${appearance}`)}
                value={appearance}
              />
            ))}
          </Select>
          <HeaderCentreLogoImageUpload />
          <div className="grid grid-cols-2">
            <ImageUpload
              name="design[header][logo]"
              label={t('web/appearance:headerLogo')}
              help={t('web/appearance:headerLogoHelp')}
              onUpload={onImageUpload}
              maxSize="10mb"
              accept={design.logoFormats}
              required
            />
            <ImageUpload
              name="design[header][logoCollapsed]"
              label={t('headerLogoCollapsed')}
              help={t('headerLogoCollapsedHelp')}
              onUpload={onImageUpload}
              maxSize="10mb"
              accept={design.logoFormats}
            />
          </div>
          <Input
            name="design[header][logoIdentifier]"
            label={t('headerLogoIdentifier')}
            help={t('headerLogoIdentifierHelp')}
          />
          <MenuPicker
            name="design[header][primaryMenu]"
            label={t('primaryMenu')}
            help={t('primaryMenuHelp')}
            required
          />
          <MenuPicker
            name="design[header][secondaryMenu]"
            label={t('secondaryMenu')}
            help={t('secondaryMenuHelp')}
            required
          />
        </Section.Body>
        <Section.Footer>
          <Submit label={t('update')} reverse />
        </Section.Footer>
      </Section>
    </Form>
  )
}
AdventistenDeHeaderForm.propTypes = {
  site: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
}
