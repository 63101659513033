import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import SelectOption from './Option'
import SelectPlaceholder from './Placeholder'

/**
 * Basic Select component without Field
 */
export default function Select({
  className,
  disabled,
  children,
  fullWidth,
  extra,
  id,
  inputRef,
  name,
  onBlur,
  onChange,
  placeholder,
  placeholderValue,
  showClear,
  value,
}) {
  const { t } = useTranslation()
  const fullWidthClass = fullWidth ? 'w-full' : ''
  const placeholderClass = value ? '' : 'text-gray-400'

  return (
    <div className="flex grow items-center justify-between gap-x-2">
      <select
        className={`form-select rounded border-gray-300 focus:border-primary-500 focus:outline-none focus:ring focus:ring-primary-200 ${fullWidthClass} ${placeholderClass} ${className}`}
        disabled={disabled}
        id={id}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value || placeholderValue}
        ref={inputRef}
      >
        {placeholder && (
          <SelectPlaceholder label={placeholder} value={placeholderValue} />
        )}
        {showClear && <SelectOption value={null} label={t('clearSelection')} />}
        {children}
      </select>
      {extra}
    </div>
  )
}
Select.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
  extra: PropTypes.node,
  id: PropTypes.string,
  inputRef: PropTypes.any,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  placeholderValue: PropTypes.any,
  showClear: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
}
Select.defaultProps = {
  className: '',
  fullWidth: true,
  value: '',
  placeholderValue: 'none',
}
