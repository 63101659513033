import { useMemo } from 'react'

import { useDesignContext } from '@modules/web/components/DesignProvider'
import useSite from '@modules/web/services/hooks/useSite'

import useValueAtBreakpoint from '../useValueAtBreakpoint'

export function useDesignWidths(design) {
  const { site } = useSite()

  return useMemo(() => {
    if (typeof design.widths === 'function') {
      return design.widths(site?.design)
    }
    return design.widths
  }, [design, site?.design])
}

export function useWidthClass(width, defaultWidth = 'full') {
  const design = useDesignContext()
  const designWidths = useDesignWidths(design)
  const { value: widthAtBreakpoint } = useValueAtBreakpoint(width)

  if (!designWidths) return ''

  return (
    designWidths[widthAtBreakpoint] ||
    designWidths[defaultWidth] ||
    designWidths.full ||
    ''
  )
}

export const namedWidths = [
  'auto', // width_auto
  'full', // width_full
]

/**
 * Get width label key
 *
 * @param {string} width - width value
 * @returns {string} - width label key
 */
export function getWidthLabel(width) {
  if (namedWidths.includes(width)) {
    return `width_${width}`
  }
  return width
}
