import { useQuery } from 'react-query'

import { getFetch } from '@utils/fetcher'

export default function useArticleArchive(siteId) {
  return useQuery(
    [
      'articleArchive',
      {
        siteId,
      },
    ],
    () => getFetch(`/api/articles/archive?siteId=${siteId}`)
  )
}
