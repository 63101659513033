import { useCallback, useEffect } from 'react'

import { useQueryParams } from '@utils/urls'

import { useDesignContext } from '../../DesignProvider'

export default function useBreakpoint() {
  const [params, setParams] = useQueryParams()
  const { breakpoint } = params
  const { breakpoints } = useDesignContext()
  const breakpointKeys = Object.keys(breakpoints || {})
  const validBreakpoint = breakpointKeys.includes(breakpoint)

  const setBreakpoint = useCallback(
    breakpoint => {
      setParams({ breakpoint })
      localStorage.setItem('breakpoint', breakpoint)
    },
    [setParams]
  )

  useEffect(() => {
    if (!breakpoints) return // If breakpoints are not set, do nothing

    const savedBreakpoint = localStorage.getItem('breakpoint')
    const validSavedBreakpoint = breakpointKeys.includes(savedBreakpoint)

    if (!validBreakpoint) {
      if (validSavedBreakpoint) {
        // If the local storage breakpoint is valid, set it as the default
        setBreakpoint(savedBreakpoint)
      } else {
        // If the parameter breakpoint is invalid, set 'xs' as the default
        setBreakpoint('xs')
      }
    }
  }, [breakpoints, breakpoint, breakpointKeys, validBreakpoint, setBreakpoint])

  return {
    breakpoint,
    setBreakpoint,
    breakpoints,
    breakpointKeys,
    isLoading: !breakpoint,
  }
}
