import PropTypes from 'prop-types'
import React from 'react'

import { useTranslation } from 'react-i18next'

import Tag from '@ui/data-display/Tag'

import EntityIcon from './EntityIcon'

export default function EntityTag({ entity, onDelete }) {
  const { t } = useTranslation('entities/public')

  if (!entity) return null

  const { name, type } = entity

  return (
    <Tag
      className="entity-tag"
      label={
        <span className="flex gap-1">
          {name}
          {type && (
            <span className="text-gray-400">{`(${t(`type_${type}`)})`}</span>
          )}
        </span>
      }
      onDelete={onDelete}
      icon={<EntityIcon type={type} />}
    />
  )
}
EntityTag.propTypes = {
  entity: PropTypes.object.isRequired,
  onDelete: PropTypes.func,
}
