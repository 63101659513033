import { useMemo } from 'react'

export const variants = {
  'ai': 'bg-ai-50 text-ai-600 border-ai-600 focus:ring-ai-600/50',
  'basic': 'bg-gray-50 text-gray-600 border-gray-300 focus:ring-gray-300/80',
  'primary':
    'bg-primary-500 text-primary-50 border-primary-600 focus:ring-primary-600/50',
  'primary-light': 'bg-primary-50 text-primary-600 border-primary-600',
  'success':
    'bg-success-500 text-white border-success-600 focus:ring-success-600/50',
  'success-light':
    'bg-success-50 text-success-600 border-success-600 focus:ring-success-600/50',
  'info': 'bg-info-100 text-info-600 border-info-300 focus:ring-info-300/50',
  'favorite': 'bg-favorite-500 text-white border-favorite-600',
  'favorite-light': 'bg-favorite-50 text-favorite-600 border-favorite-600',
  'warn': 'bg-warn-500 text-white border-warn-600 focus:ring-warn-600/50',
  'warn-light':
    'bg-warn-50 text-warn-600 border-warn-600 focus:ring-warn-600/50',
  'danger':
    'bg-danger-500 text-white border-danger-600 focus:ring-danger-600/50',
  'danger-light':
    'bg-danger-50 text-danger-600 border-danger-600 focus:ring-danger-600/50',
  'flat': 'text-gray-600 hover:text-primary-600',
  'flat-primary': 'text-primary-600 hover:text-primary-700',
  'flat-success': 'text-success-600 hover:text-success-700',
  'flat-warn': 'text-warn-600 hover:text-warn-700',
  'flat-favorite': 'text-favorite-500 hover:text-favorite-600',
  'flat-danger': 'text-danger-600 hover:text-danger-700',
  'flat-info': 'text-info-600 hover:text-info-700',
  'flat-light': 'text-primary-50 hover:text-primary-100 ',
  'light':
    'border-primary-200 text-primary-100 hover:text-primary-50 bg-transparent focus:ring-primary-100/50',
}

const sizesStyles = {
  xs: 'h-6 py-0.5 px-2 gap-2 text-xs',
  sm: 'h-8 py-1 px-3 gap-2 text-sm',
  md: 'h-10 py-2 px-4 gap-3 text-base',
  lg: 'h-12 py-3 px-5 gap-4 text-lg',
  xl: 'h-16 py-4 px-6 gap-4 text-xl',
}

const roundedStyles = {
  xs: 'rounded',
  sm: 'rounded',
  md: 'rounded-md',
  lg: 'rounded-lg',
  xl: 'rounded-xl',
}

export function useButtonClasses({
  variant,
  size,
  disabled,
  pressed,
  rounded = true,
}) {
  return useMemo(() => {
    const sizeClass = sizesStyles[size] || sizesStyles.md
    const roundedClass = rounded ? roundedStyles[size] || roundedStyles.md : ''
    const variantClass = variants[variant] || variants.basic

    const disabledClass = disabled
      ? 'cursor-not-allowed opacity-50'
      : 'cursor-pointer'

    const pressedClass = pressed ? 'ring-1 ring-primary-500' : ''

    const shadowClass =
      disabled || variant?.includes('flat') || variant === 'light'
        ? ''
        : 'shadow hover:shadow-md'

    const buttonClass = variant?.includes('flat')
      ? ''
      : 'border focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white'

    return `flex items-center ${sizeClass} ${roundedClass} ${variantClass} ${disabledClass} ${pressedClass} ${shadowClass} ${buttonClass}`
  }, [variant, size, disabled, pressed, rounded])
}
