import { useFeatureFlagContext } from '@modules/feature-flags/hooks/useFeatureFlagContext'

import adventist from '../adventist'
import adventisten from '../adventisten-de'
import base from '../base'
import hope from '../hope'

export function useDesigns({ site } = {}) {
  const featureFlags = useFeatureFlagContext()

  return {
    base: base({ site, featureFlags }),
    hope: hope({ site, featureFlags }),
    adventisten: adventisten({ site, featureFlags }),
    adventist: adventist({ site, featureFlags }),
  }
}
