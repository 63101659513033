import PropTypes from 'prop-types'
import React, { useCallback } from 'react'

import { useNode } from '@craftjs/core'
import { useTranslation } from 'react-i18next'

import Content from '@modules/web/components/ContentEditor/shared/Content'
import { SettingsWrap } from '@modules/web/components/ContentEditor/shared/Settings'
import { usePageResources } from '@modules/web/components/PageResourcesProvider'
import { PagePickerField as PagePicker } from '@modules/web/components/pages/PagePicker'
import { getDynamicResourceIdentifier } from '@modules/web/utils/dynamicResource'
import Message from '@ui/data-display/Message'
import { CheckboxField as Checkbox } from '@ui/data-entry/Checkbox'
import { InputField as Input } from '@ui/data-entry/Input'
import { SelectField as Select, SelectOption } from '@ui/data-entry/Select'
import Box from '@ui/layout/Box'

import useCollection from '../../services/hooks/useCollection'
import ProviderSelect from '../ProviderSelect'
import CollectionItem from '../collections/CollectionItem'
import CollectionSelect from '../collections/CollectionSelect'

const config = {
  name: 'CoursesCollection',
  label: 'courses/public:coursesCollection',
  help: 'courses/public:coursesCollectionHelp',
  type: 'plugin',
  icon: 'bars',
  component: <CoursesCollection />,
}

export default function CoursesCollection({
  collectionId,
  hideTitle,
  onlineCoursesOnly,
  layout,
}) {
  const { collection } = useCollection(collectionId, { onlineCoursesOnly })
  return (
    <Content icon={config.icon} title={config.label}>
      <CollectionItem
        collection={collection}
        hideTitle={hideTitle}
        layout={layout}
      />
    </Content>
  )
}
CoursesCollection.propTypes = {
  collectionId: PropTypes.string,
  hideTitle: PropTypes.bool,
  onlineCoursesOnly: PropTypes.bool,
  layout: PropTypes.oneOf(['card', 'poster']),
}
CoursesCollection.toolbarItem = config

function CoursesCollectionSettings() {
  const { t } = useTranslation(['courses/public', 'courses/collections'])
  const { actions, data } = useNode(node => {
    return {
      data: node.data.props,
    }
  })

  const { resources } = usePageResources()

  const onFieldUpdate = useCallback(
    (fieldName, parser = x => x) =>
      e => {
        actions.setProp(props => (props[fieldName] = parser(e.target.value)))
      },
    [actions]
  )

  const onProviderUpdate = useCallback(
    (fieldName, parser = x => x) =>
      provider => {
        actions.setProp(props => (props[fieldName] = parser(provider.id)))
      },
    [actions]
  )

  const onCollectionUpdate = useCallback(
    (fieldName, parser = x => x) =>
      collection => {
        actions.setProp(props => (props[fieldName] = parser(collection.id)))
      },
    [actions]
  )

  return (
    <SettingsWrap title={t(config.label)} help={t(config.help)}>
      <Box space="xl">
        <Box space="lg">
          {resources?.CourseProvider ? (
            <Message className="text-sm" type="warn">
              {resources.CourseProvider.title}
            </Message>
          ) : (
            <ProviderSelect
              label={t('provider')}
              onChange={onProviderUpdate('providerId')}
              value={data.providerId}
              controlled={false}
            />
          )}
          <CollectionSelect
            providerId={data.providerId || resources?.CourseProvider?.id}
            label={t('collection')}
            onChange={onCollectionUpdate('collectionId')}
            value={data.collectionId}
            controlled={false}
          />
        </Box>
        <Checkbox
          label={t('hideTitle')}
          onChange={value => {
            actions.setProp(props => (props.hideTitle = value))
          }}
          value={data.hideTitle}
          name="hideTitle"
        />
        <Checkbox
          label={t('onlineCoursesOnly')}
          onChange={value => {
            actions.setProp(props => (props.onlineCoursesOnly = value))
          }}
          value={data.onlineCoursesOnly}
          name="hideTitle"
        />
        <Select
          label={t('layout')}
          help={t('layoutHelp')}
          value={data.layout}
          onChange={onFieldUpdate('layout')}
        >
          <SelectOption value="card" label={t('layoutCard')} />
          <SelectOption value="poster" label={t('layoutPoster')} />
        </Select>
        {data.layout === 'poster' && (
          <Box space="md">
            <Checkbox
              name="enableTitleInPosters"
              label={t('enableTitleInPosters')}
              help={t('enableTitleInPostersHelp')}
              value={data.enableTitleInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableTitleInPosters = checked))
              }
            />
            <Checkbox
              name="enableAbstractInPosters"
              label={t('enableAbstractInPosters')}
              help={t('enableAbstractInPostersHelp')}
              value={data.enableAbstractInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableAbstractInPosters = checked))
              }
            />
            <Checkbox
              name="enableCallToActionInPosters"
              label={t('enableCallToActionInPosters')}
              help={t('enableCallToActionInPostersHelp')}
              value={data.enableCallToActionInPosters}
              onChange={checked =>
                actions.setProp(p => (p.enableCallToActionInPosters = checked))
              }
            />
            {data.enableCallToActionInPosters && (
              <Input
                className="pl-7"
                onChange={onFieldUpdate('callToActionLabel')}
                placeholder={t('callToActionPlaceholder')}
                value={data.callToActionLabel}
              />
            )}
          </Box>
        )}
        {data.layout === 'card' && (
          <Checkbox
            name="enableAbstractInCards"
            label={t('enableAbstractInCards')}
            help={t('enableAbstractInCardsHelp')}
            value={data.enableAbstractInCards}
            onChange={checked =>
              actions.setProp(p => (p.enableAbstractInCards = checked))
            }
          />
        )}
        <PagePicker
          label={t('courseDetailPageId')}
          help={t('courseDetailPageIdHelp')}
          value={data.courseDetailPageId}
          onChange={pageId =>
            actions.setProp(props => (props.courseDetailPageId = pageId))
          }
          condition={page =>
            page.dynamic && getDynamicResourceIdentifier(page) === 'Course'
          }
        />
      </Box>
    </SettingsWrap>
  )
}

CoursesCollection.craft = {
  displayName: config.name,
  props: {
    providerId: undefined,
    collectionId: undefined,
    courseDetailPageId: undefined,
    hideTitle: false,
    onlineCoursesOnly: false,
    layout: 'card',
    enableTitleInPosters: false,
    enableAbstractInPosters: true,
    enableAbstractInCards: true,
    enableCallToActionInPosters: true,
    callToActionLabel: undefined,
  },
  custom: {
    type: config.type,
    resources: ['coursesCollection'],
    i18nNamespaces: ['courses'],
  },
  rules: {
    canMoveIn: () => false,
  },
  related: {
    settings: CoursesCollectionSettings,
  },
}
